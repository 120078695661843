import React from 'react';
import PropTypes from 'prop-types';
import TablePagination from '@material-ui/core/TablePagination';
import { useTranslation } from 'react-i18next';
import useTheme from '@material-ui/core/styles/useTheme';
import { useStyles } from './MTCTablePagination.style';

export const MTCTablePagination = (props) => {

  const {
    numberOfItems,
    rowsPerPage,
    label,
    page,
    onChangePage,
    onChangeRowsPerPage,
  } = props;

  const theme = useTheme();
  const classes = useStyles(theme);
  const { t } = useTranslation();

  return (
    <div className={classes.container}>
      <TablePagination
        component="div"
        count={numberOfItems}
        rowsPerPage={rowsPerPage}
        rowsPerPageOptions={[50, 100, 200]}
        labelRowsPerPage={label}
        page={page}
        backIconButtonProps={{
          'aria-label': `${t('previous_page')}`,
        }}
        nextIconButtonProps={{
          'aria-label': `${t('next_page')}`,
        }}
        onRowsPerPageChange={onChangeRowsPerPage}
        onPageChange={onChangePage}/>
    </div>
  );
}

MTCTablePagination.propTypes = {
  numberOfItems: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  label: PropTypes.string,
  page: PropTypes.number.isRequired,
  onChangePage: PropTypes.func.isRequired,
  onChangeRowsPerPage: PropTypes.func.isRequired,
};

export default React.memo(MTCTablePagination);
