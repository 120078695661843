import React from 'react';
import Paper from '@material-ui/core/Paper';
import { useStyles } from './KpiPaper.style';

const KpiPaper = ({ children }) => {
  const classes = useStyles();
  return (
    <Paper elevation={0} className={classes.paper}>
      {children}
    </Paper>
  );
};

export default KpiPaper;
