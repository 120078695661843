import {
  RECEIVER_MMC_RF_DOWNLOADED,
  RECEIVER_MMC_RF_FAILED,
  RECEIVER_MMC_RF_SENDING,
  RECEIVERS_MMC_RF_DOWNLOADED,
  RECEIVERS_MMC_RF_PENDING,
  RECEIVERS_MMC_RF_DELETED
} from './types';
import ApiService from '../../services/ApiService';
import { handleError } from '../infos/actions';

export const receiverMmcRfSendingAction = () => ({
  type: RECEIVER_MMC_RF_SENDING,
});

export const receiverMmcRfDownloadedAction = receiver => ({
  type: RECEIVER_MMC_RF_DOWNLOADED,
  payload: receiver,
});

export const receiversMmcRfDownloadedAction = receivers => ({
  type: RECEIVERS_MMC_RF_DOWNLOADED,
  payload: receivers,
});

export const receiverMmcRfDeletedAction = id => ({
  type: RECEIVERS_MMC_RF_DELETED,
  payload: id,
});

export const receiverMmcRfFailedAction = error => ({
  type: RECEIVER_MMC_RF_FAILED,
  payload: error,
});

export const receiversMmcRfPendingAction = () => ({
  type: RECEIVERS_MMC_RF_PENDING,
});

export function getReceiversMmcRf() {
  return (dispatch) => {
    dispatch(receiversMmcRfPendingAction());
    return ApiService
      .getMmcReceiversRf()
      .then((data) => {
        dispatch(receiversMmcRfDownloadedAction(data));
      })
      .catch((error) => {
        dispatch(receiverMmcRfFailedAction(error));
        dispatch(handleError(error));
      });
  };
}

export function postReceiverMmcRf(receiver) {
  return (dispatch) => {
    dispatch(receiverMmcRfSendingAction());
    return ApiService
      .postMmcReceiverRf(receiver)
      .then((data) => {
        dispatch(receiverMmcRfDownloadedAction(data));
        return data;
      })
      .catch((error) => {
        dispatch(receiverMmcRfFailedAction(error));
        if (error.response && error.response.status === 409) {
          error.response.statusText = 'Conflict: File already exists on server';
        }
        dispatch(handleError(error));
      });
  };
}

export function putReceiverMmcRf(receiver) {
  return (dispatch) => {
    dispatch(receiverMmcRfSendingAction());
    return ApiService
      .putMmcReceiverRf(receiver)
      .then((data) => {
        dispatch(receiverMmcRfDownloadedAction(data));
      })
      .catch((error) => {
        dispatch(receiverMmcRfFailedAction(error));
        dispatch(handleError(error));
      });
  };
}

export function deleteReceiverMmcRf(id) {
  return (dispatch) => {
    dispatch(receiverMmcRfSendingAction());
    return ApiService
      .deleteMmcReceiverRf(id)
      .then(() => {
        dispatch(receiverMmcRfDeletedAction(id));
      })
      .catch((error) => {
        dispatch(receiverMmcRfFailedAction(error));
        dispatch(handleError(error));
      });
  };
}
