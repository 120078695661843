import {
  AUTHORIZED_EXPORT_DELETED,
  AUTHORIZED_EXPORT_DOWNLOADED,
  AUTHORIZED_EXPORT_SENDING,
  AUTHORIZED_EXPORTS_DOWNLOADED,
  AUTHORIZED_EXPORTS_FAILED,
  AUTHORIZED_EXPORTS_PENDING,
  AUTHORIZED_EXPORT_EXPORTED, AUTHORIZED_EXPORT_STARTED,
} from './types';

export const INITIAL_STATE = {
  content: null,
  pending: false,
  sending: false,
  exportPending: false,
  error: null,
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case AUTHORIZED_EXPORTS_PENDING:
      return {
        ...state,
        pending: true,
        error: null,
      };
    case AUTHORIZED_EXPORTS_FAILED:
      return {
        ...state,
        error: action.payload,
        pending: false,
        sending: false,
      };
    case AUTHORIZED_EXPORTS_DOWNLOADED:
      return {
        content: action.payload,
        error: null,
        pending: false,
        sending: false,
      };
    case AUTHORIZED_EXPORT_DOWNLOADED: {
      let newContent = [];
      if (state.content != null) {
        newContent = [...state.content];
      }
      let index = newContent.findIndex(ae => ae.id === action.payload.id);
      if (index === -1) {
        newContent.push(action.payload);
      } else {
        newContent[index] = action.payload;
      }
      return {
        content: newContent,
        error: null,
        pending: false,
        sending: false,
      };
    }
    case AUTHORIZED_EXPORT_SENDING:
      return {
        ...state,
        sending: true,
      };
    case AUTHORIZED_EXPORT_DELETED: {
      let newContent = [];
      let index = -1;
      if (state.content) {
        newContent = state.content.slice();
        index = newContent.findIndex(ae => ae.id === action.payload);
      }
      if (index !== -1) {
        newContent.splice(index, 1);
      }
      return {
        content: newContent,
        error: null,
        pending: false,
        sending: false,
      };
    }
    case AUTHORIZED_EXPORT_EXPORTED: {
      let newContent = [];
      let index = -1;
      if (state.content) {
        newContent = state.content.slice();
        index = newContent.findIndex(ae => ae.id === action.payload.id);
      }
      if (index !== -1) {
        newContent[index] = { ...action.payload, export_count: (action.payload.export_count + 1) };
      }
      return {
        ...state,
        exportPending: false,
        content: newContent
      };
    }
    case AUTHORIZED_EXPORT_STARTED: {
      return {
        ...state,
        exportPending: true
      }
    }
    default:
      break;
  }
  return state;
}
