const CATALOGS_TAGS_PENDING = 'catalogs_tags_pending';
const CATALOGS_TAGS_SENDING = 'catalogs_tags_sending';
const CATALOGS_TAGS_FAILED = 'catalogs_tags_failed';
const CATALOGS_TAGS_DOWNLOADED = 'catalogs_tags_downloaded';
const CATALOGS_TAG_DOWNLOADED = 'catalogs_tag_downloaded';
const CATALOGS_TAG_DELETED = 'catalogs_tag_deleted';

export {
  CATALOGS_TAGS_PENDING,
  CATALOGS_TAGS_SENDING,
  CATALOGS_TAGS_FAILED,
  CATALOGS_TAGS_DOWNLOADED,
  CATALOGS_TAG_DOWNLOADED,
  CATALOGS_TAG_DELETED
};