import {
  FIRMWARE_EXCLUDED_VERSIONS_PENDING,
  FIRMWARE_EXCLUDED_VERSIONS_FAILED,
  FIRMWARE_EXCLUDED_VERSIONS_DOWNLOADED,
  FIRMWARE_EXCLUDED_VERSION_SENDING,
  FIRMWARE_EXCLUDED_VERSION_DELETED,
  FIRMWARE_EXCLUDED_VERSION_DOWNLOADED,
} from './types';

export const INITIAL_STATE = {
  error: null,
  content: null,
  pending: false,
  sending: false,
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case FIRMWARE_EXCLUDED_VERSIONS_PENDING:
      return {
        ...state,
        pending: true
      };
    case FIRMWARE_EXCLUDED_VERSIONS_FAILED:
      return {
        ...state,
        pending: false,
        sending: false,
        error: action.payload
      };
    case FIRMWARE_EXCLUDED_VERSIONS_DOWNLOADED:
      return {
        ...state,
        pending: false,
        content: action.payload
      };
    case FIRMWARE_EXCLUDED_VERSION_SENDING:
      return {
        ...state,
        sending: true
      };
    case FIRMWARE_EXCLUDED_VERSION_DELETED: {
      let newContent = [];
      if (state.content != null) {
        newContent = [...state.content];
      }
      let index = newContent.findIndex(ev => ev.id == action.payload);
      if (index !== -1) {
        newContent.splice(index, 1);
      }
      return {
        ...state,
        sending: false,
        content: newContent
      };
    }
    case FIRMWARE_EXCLUDED_VERSION_DOWNLOADED: {
      let newContent = [];
      if (state.content != null) {
        newContent = [...state.content];
      }
      newContent.push(action.payload);
      return {
        ...state,
        sending: false,
        content: newContent
      };
    }
    default:
      break;
  }
  return {...state};
}
