import {
  TRACK_COMMENT_DOWNLOADED,
  TRACK_COMMENT_SENDING,
  TRACK_COMMENTS_DOWNLOADED,
  TRACK_COMMENTS_FAILED,
  TRACK_COMMENTS_PENDING,
} from './types';
import ApiService from '../../services/ApiService';

export const trackCommentsPendingAction = () => (
  {
    type: TRACK_COMMENTS_PENDING,
  }
);

export const trackCommentsFailedAction = (error) => (
  {
    type: TRACK_COMMENTS_FAILED,
    payload: error,
  }
);

export const trackCommentsDownloadedAction = (circuitId, comments) => (
  {
    type: TRACK_COMMENTS_DOWNLOADED,
    payload: {
      circuitId,
      comments,
    },
  }
);

export const trackCommentSendingAction = () => (
  {
    type: TRACK_COMMENT_SENDING,
  }
);

export const trackCommentDownloadedAction = (data) => (
  {
    type: TRACK_COMMENT_DOWNLOADED,
    payload: data,
  }
);

export function getTrackComments(circuitId) {
  return (dispatch) => {
    dispatch(trackCommentsPendingAction());
    return ApiService.getTrackComments(circuitId)
      .then(data => {
        dispatch(trackCommentsDownloadedAction(circuitId, data));
      }).catch(error => {
        dispatch(trackCommentsFailedAction(error));
      });
  };
}

export function putTrackComments(id, state) {
  return (dispatch) => {
    dispatch(trackCommentSendingAction());
    return ApiService.putTrackComments(id, state)
      .then(data => {
        dispatch(trackCommentDownloadedAction(data));
      }).catch(error => {
        dispatch(trackCommentsFailedAction(error));
      });
  };
}
