import React, { useState } from 'react';
import PropTypes from 'prop-types';
import hash from 'object-hash';
import { useTranslation } from 'react-i18next';
import RequestCircuitRow from './RequestCircuitRow';
import MTCTable from '../../Layout/Table/MTCTable';
import MTCLoader from '../../Layout/MTCLoader';
import {
  columns,
  createDataFromRequestAndUser,
  defaultOrderBy,
  defaultOrder,
} from './RequestsCircuitTable.const';

const RequestsCircuitTable = ({ users, requests }) => {

  const { t } = useTranslation();

  const [requestsHash, setRequestsHash] = useState(null);
  const [data, setData] = useState(null);

  if (data === null || requestsHash !== hash(requests)) {
    setData(createDataFromRequestAndUser(requests, users));
    setRequestsHash(hash(requests));
  }

  if (data === null) {
    return <MTCLoader />;
  } else {
    return <MTCTable
      content={data}
      defaultOrderBy={defaultOrderBy}
      defaultOrder={defaultOrder}
      rowComponent={RequestCircuitRow}
      columns={columns}
      paginationLabel={t('requests_per_page')}
      emptyDataLabel={t('no_request')}
    />;
  }
};

RequestsCircuitTable.propTypes = {
  users: PropTypes.array.isRequired,
  requests: PropTypes.array.isRequired,
};

export default RequestsCircuitTable;
