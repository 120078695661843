import React from 'react';
import { useStyles } from './MTCSpinner.style';

const MTCSpinner = (props) => {
  const { secondary } = props;
  const classes = useStyles();
  return (
    <div className={classes.parent}>
      <div className={classes.child1 + (secondary === true ? (' ' + classes.secondary) : '')} />
      <div className={classes.child2 + (secondary === true ? (' ' + classes.secondary) : '')} />
      <div className={classes.child3 + (secondary === true ? (' ' + classes.secondary) : '')} />
    </div>
  );
};

export default React.memo(MTCSpinner);
