import React, { createRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import Dropzone from 'react-dropzone';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { useTheme } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import ArchiveIcon from '@material-ui/icons/Archive';
import MTCTextField from '../../../Form/MTCTextField';
import MTCButton from '../../../Layout/Button/MTCButton';
import {
  getFirmwareGroups,
  postFirmwareGroupFirmwares,
} from '../../../../store/firmwareGroups/actions';
import { FIRMWARE_GROUP } from '../../../../constants/routes';
import { ALL_FIRMWARE_TYPES, MMCBLE, MTCBLE } from '../../../../constants/firmwareTypes';
import { handleError } from '../../../../store/infos/actions';
import { useStyles } from './FirmwareGroupFirmwareForm.style';
import { supportedBinDropFiles, supportedZipDropFiles } from '../../../../constants/files';

export const FirmwareGroupItemForm = (props) => {
  const { id } = props;
  const {
    content,
    sending,
    pending,
    error,
  } = useSelector(state => state.firmwareGroups);
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const theme = useTheme();
  const classes = useStyles(theme);
  const [version, setVersion] = useState('');
  const [type, setType] = useState('');
  const [file, setFile] = useState('');
  const [fileToUpload, setFileToUpload] = useState(null);
  const dropzoneRef = createRef();

  if (!content && !pending && !error) {
    dispatch(getFirmwareGroups());
  }
  const isBle = type === MTCBLE || type === MMCBLE;

  const handleFileDrop = (accepted, rejected) => {
    if (rejected.length) {
      if (type === MTCBLE || type === MMCBLE) {
        dispatch(handleError({
          message: t('error.format.zip') + rejected[0].type,
        }));
      } else {
        dispatch(handleError({
          message: t('error.format.bin') + rejected[0].type,
        }));
      }

    } else {
      setFileToUpload(accepted[0]);
      setFile(accepted[0].name);
    }
  };

  const handleChangeType = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setType(event.target.value);
    setFile('');
    setFileToUpload(null);
  };

  const formSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();
    if (version !== '' && file !== '' && type !== '') {
      let formData = new FormData();
      formData.append('firmware', fileToUpload);
      formData.append('json', JSON.stringify({
        version,
        type,
      }));
      dispatch(postFirmwareGroupFirmwares(id, formData))
        .then(data => {
          if (data !== null) {
            history.push(FIRMWARE_GROUP.replace(':groupId', id));
          }
        });
    }
  };

  return (
    <form onSubmit={formSubmit}>
      <Grid
        container
        alignItems={'center'}
        direction={'column'}
        justifyContent={'center'}
        className={classes.grid}
      >
        <MTCTextField
          gridMD={12}
          id="version"
          onChange={event => setVersion(event.target.value)}
          value={version}
          required={true}
        />
        <Grid item>
          <FormControl margin={'normal'}>
            <FormLabel required={true}>
              {t('type.name')}
            </FormLabel>
            <Select
              id="select"
              value={type}
              onChange={handleChangeType}
              className={classes.input}
            >
              {ALL_FIRMWARE_TYPES.map(type => (
                <MenuItem
                  key={type}
                  value={type}
                >
                  {t(type)}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        {
          type !== '' &&
          <Dropzone
            ref={dropzoneRef}
            accept={
              isBle
                ? supportedZipDropFiles
                : supportedBinDropFiles
            }
            onDrop={handleFileDrop}
          >
            <div>
              <Typography variant="subtitle1">
                {isBle
                  ? t('software_drop')
                  : t('software_drop_rf')
                }
              </Typography>
              <br />
              {file || <ArchiveIcon />}
            </div>
          </Dropzone>
        }
        <MTCButton
          type={'submit'}
          sending={sending}
          text={t('create')}
        />
      </Grid>
    </form>
  );
};

FirmwareGroupItemForm.defaultProps = { id: null };

export default FirmwareGroupItemForm;
