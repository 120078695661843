import {
  FIRMWARE_GROUP_DELETED,
  FIRMWARE_GROUP_DOWNLOADED, FIRMWARE_GROUP_FIRMWARE_DELETED,
  FIRMWARE_GROUP_FIRMWARE_DOWNLOADED,
  FIRMWARE_GROUP_ITEM_DELETED,
  FIRMWARE_GROUP_ITEM_DOWNLOADED,
  FIRMWARE_GROUP_SENDING,
  FIRMWARE_GROUPS_DOWNLOADED,
  FIRMWARE_GROUPS_FAILED,
  FIRMWARE_GROUPS_PENDING,
} from './types';

export const INITIAL_STATE = {
  content: null,
  pending: false,
  sending: false,
  error: null,
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case FIRMWARE_GROUPS_PENDING:
      return {
        ...state,
        pending: true,
      };
    case FIRMWARE_GROUP_SENDING:
      return {
        ...state,
        sending: true,
      };
    case FIRMWARE_GROUP_DOWNLOADED: {
      let newContent = [];
      if (state.content != null) {
        newContent = [...state.content];
      }
      let index = newContent.findIndex(fg => fg.id == action.payload.id);
      if (index === -1) {
        newContent.push(action.payload);
      } else {
        newContent[index] = action.payload;
      }
      return {
        content: newContent,
        error: null,
        sending: false,
        pending: false,
      };
    }
    case FIRMWARE_GROUPS_DOWNLOADED:
      return {
        ...state,
        sending: false,
        pending: false,
        content: action.payload,
      };
    case FIRMWARE_GROUPS_FAILED:
      return {
        ...state,
        sending: false,
        pending: false,
        error: action.payload,
      };
    case FIRMWARE_GROUP_DELETED: {
      const groupId = action.payload;
      let newContent = [];
      if (state.content != null) {
        newContent = [...state.content];
      }
      let index = newContent.findIndex(fg => fg.id == groupId);
      if (index !== -1) {
        newContent.splice(index, 1);
      }
      return {
        content: newContent,
        error: null,
        sending: false,
        pending: false,
      };
    }
    case FIRMWARE_GROUP_ITEM_DOWNLOADED: {
      const groupId = action.payload.id;
      const item = action.payload.item;
      let newContent = [];
      if (state.content != null) {
        newContent = [...state.content];
      }
      let index = newContent.findIndex(fg => fg.id == groupId);
      if (index !== -1) {
        newContent[index].item_count = newContent[index].item_count + 1;
        newContent[index].items.push(item);
      }
      return {
        content: newContent,
        error: null,
        sending: false,
        pending: false,
      };
    }
    case FIRMWARE_GROUP_FIRMWARE_DOWNLOADED: {
      const groupId = action.payload.id;
      const firmware = action.payload.firmware;
      let newContent = [];
      if (state.content != null) {
        newContent = [...state.content];
      }
      let index = newContent.findIndex(fg => fg.id == groupId);
      if (index !== -1) {
        let fIndex = -1;
        if (newContent[index].firmwares && newContent[index].firmwares.length > 0) {
          fIndex = newContent[index].firmwares.findIndex(fgf => fgf.type == firmware.type);
        }
        if (fIndex !== -1) {
          newContent[index].firmwares.splice(fIndex, 1);
        }
        if (!newContent[index].firmwares) {
          newContent[index].firmwares = [firmware];
        } else {
          newContent[index].firmwares.push(firmware);
        }
      }
      return {
        content: newContent,
        error: null,
        sending: false,
        pending: false,
      };
    }
    case FIRMWARE_GROUP_ITEM_DELETED: {
      const groupId = action.payload.id;
      const itemId = action.payload.itemId;
      let newContent = [];
      if (state.content != null) {
        newContent = [...state.content];
      }
      let index = newContent.findIndex(fg => fg.id == groupId);
      if (index !== -1) {
        let itemIndex = newContent[index].items.findIndex(fgi => fgi.id == itemId);
        if (itemIndex !== -1) {
          newContent[index].item_count = newContent[index].item_count - 1;
          newContent[index].items.splice(itemIndex, 1);
        }
      }
      return {
        content: newContent,
        error: null,
        sending: false,
        pending: false,
      };
    }
    case FIRMWARE_GROUP_FIRMWARE_DELETED: {
      const groupId = action.payload.id;
      const firmwareId = action.payload.firmwareId;
      let newContent = [];
      if (state.content != null) {
        newContent = [...state.content];
      }
      let index = newContent.findIndex(fg => fg.id == groupId);
      if (index !== -1 && newContent[index].firmwares) {
        let fwIndex = newContent[index].firmwares.findIndex(fgf => fgf.id == firmwareId);
        if (fwIndex !== -1) {
          newContent[index].firmwares.splice(fwIndex, 1);
        }
      }
      return {
        content: newContent,
        error: null,
        sending: false,
        pending: false,
      };
    }
    default:
      break;
  }
  return state;
}
