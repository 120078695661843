import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import CreateNewFolderIcon from '@material-ui/icons/CreateNewFolder';
import useTheme from '@material-ui/core/styles/useTheme';
import DeleteIcon from '@material-ui/icons/Delete';
import CreateIcon from '@material-ui/icons/Create';
import NoteAddIcon from '@material-ui/icons/NoteAdd';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { useStyles } from './FaqElementsExplorerMenu.style';
import {
  ANY_CAR_FORM,
  ARTICLE, CIRCUIT_CAR_FORM,
  CIRCUIT_FORM,
  DIRECTORY, RALLY_CAR_FORM,
  ROOT,
  SUGGESTION_FORM,
} from '../FaqElementType.const';
import FaqElementExplorerButton from '../FaqElementExplorerButton/FaqElementExplorerButton';
import { FAQ_ARTICLES_EDIT, FAQ_ELEMENTS } from '../../../../constants/routes';
import i18next from '../../../../i18n';

const FaqElementsExplorerMenu = ({
                                   element,
                                   handleOpenElementDialog,
                                   handleOpenArticleDialog,
                                   handleOpenExternalLinkDialog,
                                   setOpenRemoveDialog
                                 }) => {

  const { t } = useTranslation();
  const history = useHistory();
  const theme = useTheme();
  const classes = useStyles(theme);
  const lang = i18next.languages[0].split('-')[0];
  const [anchorEl, setAnchorEl] = React.useState(null);

  const editArticle = () => {
    if (element.article_id) {
      history.push({
        pathname: FAQ_ARTICLES_EDIT.replace(':articleId', element.article_id),
        state: {
          from: FAQ_ELEMENTS,
          elementId: element.id,
        },
      });
    }
  };

  const handleClickOpenArticleDialog = () => {
    handleCloseMoreMenu();
    handleOpenArticleDialog();
  };

  const handleClickOpenExternalLinkDialog = () => {
    handleCloseMoreMenu();
    handleOpenExternalLinkDialog();
  };

  const handleOpenMoreMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMoreMenu = () => {
    setAnchorEl(null);
  };
  const removeArticle = () => {
    setOpenRemoveDialog({
      open: true,
      title: element.translations[lang]
        ? element.translations[lang].title
        : '',
      elementId: element.id,
      type: ARTICLE,
      directoryChoice: '',
      newParentId: null
    });
  };

  const handleClickFolder = (event) => {
    event.stopPropagation();
    event.preventDefault();
    handleCloseMoreMenu();
    handleOpenElementDialog(DIRECTORY);
  }
  const handleClickCircuitForm = (event) => {
    event.stopPropagation();
    event.preventDefault();
    handleCloseMoreMenu();
    handleOpenElementDialog(CIRCUIT_FORM);
  }
  const handleClickSuggestionForm = (event) => {
    event.stopPropagation();
    event.preventDefault();
    handleCloseMoreMenu();
    handleOpenElementDialog(SUGGESTION_FORM);
  }
  const handleClickAnyCarForm = (event) => {
    event.stopPropagation();
    event.preventDefault();
    handleCloseMoreMenu();
    handleOpenElementDialog(ANY_CAR_FORM);
  }
  const handleClickCircuitCarForm = (event) => {
    event.stopPropagation();
    event.preventDefault();
    handleCloseMoreMenu();
    handleOpenElementDialog(CIRCUIT_CAR_FORM);
  }
  const handleClickRallyCarForm = (event) => {
    event.stopPropagation();
    event.preventDefault();
    handleCloseMoreMenu();
    handleOpenElementDialog(RALLY_CAR_FORM);
  }

  return (
    <div className={classes.container}>
      {(
        element.type === ROOT || element.type === DIRECTORY
      ) && <>
        <FaqElementExplorerButton
          title={t('create_folder')}
          icon={<CreateNewFolderIcon className={classes.icon} />}
          action={handleClickFolder}
        />
        <FaqElementExplorerButton
          title={t('create_article')}
          icon={<NoteAddIcon className={classes.icon} />}
          action={handleOpenMoreMenu}
        />
      </>}
      {element.type === ARTICLE && <>
        <FaqElementExplorerButton
          title={t('edit')}
          icon={<CreateIcon className={classes.icon} />}
          action={editArticle}
        />
        <FaqElementExplorerButton
          title={t('remove')}
          icon={<DeleteIcon className={classes.icon} />}
          action={removeArticle}
          alert
        />
      </>}
      <Menu
        id="more-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleCloseMoreMenu}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <MenuItem onClick={handleClickOpenArticleDialog}>{t('create_article')}</MenuItem>
        <MenuItem onClick={handleClickOpenExternalLinkDialog}>{t('add_external_link')}</MenuItem>
        <MenuItem onClick={handleClickCircuitForm}>{t('add_circuit_form')}</MenuItem>
        <MenuItem onClick={handleClickSuggestionForm}>{t('add_suggestion_form')}</MenuItem>
        <MenuItem onClick={handleClickAnyCarForm}>{t('add_any_car_form')}</MenuItem>
        <MenuItem onClick={handleClickCircuitCarForm}>{t('add_circuit_car_form')}</MenuItem>
        <MenuItem onClick={handleClickRallyCarForm}>{t('add_rally_car_form')}</MenuItem>
      </Menu>
    </div>
  );
};

export default FaqElementsExplorerMenu;
