import React, { useState } from 'react';
import PropTypes from 'prop-types';
import hash from 'object-hash';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import Typography from '@material-ui/core/Typography';
import MTCTableHead from './MTCTableHead';
import MTCTablePagination from './MTCTablePagination';
import { useStyles } from './MTCTable.style';
import { sortData } from './MTCTable.func';

const MTCTable = props => {

  const {
    rowComponent,
    defaultOrder,
    defaultOrderBy,
    content,
    columns,
    paginationLabel,
    emptyDataLabel,
    identifier,
  } = props;

  const classes = useStyles();
  const [order, setOrder] = useState(defaultOrder || 'asc');
  const [orderBy, setOrderBy] = useState(defaultOrderBy || 'name');
  const [data, setData] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [contentHash, setContentHash] = useState(null);

  if (content && contentHash !== hash(content)) {
    setData(content.sort((a, b) => sortData(a, b, orderBy, order)).slice(
      page * rowsPerPage,
      (
        page * rowsPerPage
      ) + rowsPerPage,
    ));
    setContentHash(hash(content));
  }

  const handleRequestSort = (newOrderBy, newOrder = 'asc') => {
    if (orderBy === newOrderBy && order === newOrder) {
      newOrder = 'desc';
    }
    setOrder(newOrder);
    setOrderBy(newOrderBy);
    setData(
      content.sort((a, b) => sortData(a, b, newOrderBy, newOrder)).slice(
        page * rowsPerPage,
        (
          page * rowsPerPage
        ) + rowsPerPage,
      ),
    );
  };

  const handleChangePage = (event, page) => {
    setPage(page);
    setData(content.sort((a, b) => sortData(a, b, orderBy, order)).slice(
      page * rowsPerPage,
      (
        page * rowsPerPage
      ) + rowsPerPage,
    ));
  };

  const handleChangeRowsPerPage = (event) => {
    const newRowsPerPage = event.target.value;
    let newPage = page;
    if (newRowsPerPage > rowsPerPage) {
      newPage = Math.floor((page * rowsPerPage) / newRowsPerPage);
      setPage(newPage);
    }
    setRowsPerPage(newRowsPerPage);
    setData(content.sort((a, b) => sortData(a, b, orderBy, order)).slice(
      newPage * newRowsPerPage,
      (
        newPage * newRowsPerPage
      ) + newRowsPerPage,
    ));
  };

  return (
    <div className={classes.container}>
      <div className={classes.tableW}>
        <Table>
          <MTCTableHead
            onRequestSort={handleRequestSort}
            order={order}
            orderBy={orderBy}
            rowCount={data.length}
            columnData={columns}
          />
          <TableBody>
            {data.length > 0
            && data.map(value =>
              React.createElement(rowComponent,
                {
                  key: hash(value),
                  value,
                  identifier,
                },
              ),
            )}
            {data.length <= 0 &&
            <tr>
              <td colSpan={columns.length}>
                <Typography variant="subtitle1">
                  {emptyDataLabel}
                </Typography>
              </td>
            </tr>
            }
          </TableBody>
        </Table>
      </div>
      <MTCTablePagination
        numberOfItems={content
          ? content.length
          : 0}
        rowsPerPage={rowsPerPage}
        label={paginationLabel}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </div>
  );
};

MTCTable.propTypes = {
  content: PropTypes.array,
  defaultOrder: PropTypes.string,
  defaultOrderBy: PropTypes.string,
  rowComponent: PropTypes.any.isRequired,
  columns: PropTypes.array.isRequired,
  paginationLabel: PropTypes.string.isRequired,
  emptyDataLabel: PropTypes.string,
  identifier: PropTypes.string,
};

export default MTCTable;
