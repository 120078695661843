import React from 'react';
import ReceiverRow from './ReceiverRow';
import MTCTable from '../Layout/Table/MTCTable';
import { useTranslation } from 'react-i18next';
import MTCLoader from '../Layout/MTCLoader';
import { columns, defaultOrderBy } from './ReceiversTable.const';

const ReceiversTable = ({ pending, content, identifier }) => {

  const { t } = useTranslation();
  if (pending) {
    return <MTCLoader />;
  } else {
    return <MTCTable
      content={content}
      defaultOrderBy={defaultOrderBy}
      defaultOrder={'desc'}
      rowComponent={ReceiverRow}
      columns={columns}
      paginationLabel={t('receivers_per_page')}
      emptyDataLabel={t('no_receiver')}
      identifier={identifier}
    />;
  }
};

export default ReceiversTable;
