import React from 'react';
import withAuthentication from '../../../hoc/withAuthentication';
import { ROLE_ADMIN } from '../../../constants/roles';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { setTitle } from '../../../store/title/actions';
import FirmwareGroupForm from '../../../components/Receivers/FirmwareGroups/FirmwareGroupForm';
import { useParams } from 'react-router';

export const EditFirmwareGroupPage = () => {
  const { t } = useTranslation();
  const { title } = useSelector(state => state.title);
  const dispatch = useDispatch();
  const { groupId } = useParams();

  if (title !== (t('title:title.firmware_group_edit_page'))) {
    dispatch(setTitle(t('title:title.firmware_group_edit_page')));
  }

  return <FirmwareGroupForm id={groupId}/>;
}

export default withAuthentication(ROLE_ADMIN)(EditFirmwareGroupPage);
