import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import IconButton from '@material-ui/core/IconButton';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import DragIndicatorIcon from '@material-ui/icons/DragIndicator';
import { useTheme, withStyles } from '@material-ui/core/styles';
import {
  ARTICLE,
  DIRECTORY,
  EXTERNAL_LINK,
  getFaqElementTypeColor,
  getFaqElementTypeIconComponent,
  ROOT,
} from '../FaqElementType.const';
import i18next from '../../../../i18n';
import { useTranslation } from 'react-i18next';
import PinFill from '../../../icon/PinFill';
import PinOutline from '../../../icon/PinOutline';
import { michelin_black } from '../../../../constants/michelin_colors';
import { putFaqElements } from '../../../../store/faqElements/actions';
import { useStyles } from './FaqElementsExplorerItem.style';

const ListItemWithWiderSecondaryAction = withStyles({
  secondaryAction: {
    paddingRight: 96
  }
})(ListItem);

const FaqElementsExplorerItem = props => {
  const {
    element,
    changeCurrent,
    handleOpenElementDialog,
    handleOpenArticleDialog,
    handleOpenExternalLinkDialog,
    setOpenExternalLinkDialog,
    setOpenRemoveDialog,
    setOpenMoveDialog
  } = props;

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const theme = useTheme();
  const classes = useStyles(theme);
  const [anchorEl, setAnchorEl] = useState(false);


  const lang = i18next.languages[0].split('-')[0];
  let title = '';
  if (element.type === ROOT) {
    title = 'FAQ';
  } else {
    title = element.translations[lang]
      ? element.translations[lang].title
      : '';
  }

  const icon = getFaqElementTypeIconComponent(
    element.type,
    null,
    { color: getFaqElementTypeColor(element.type) }
  );

  const showMenu = (event) => {
    event.stopPropagation();
    event.preventDefault();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (event) => {
    event.stopPropagation();
    event.preventDefault();
    setAnchorEl(null);
  };

  const pinItem = (event) => {
    event.stopPropagation();
    event.preventDefault();
    const sendData = {
      ...element,
      most_popular: !element.most_popular,
    };
    dispatch(putFaqElements(sendData));
    handleClose(event);
  };

  const handleEdit = (event) => {
    event.stopPropagation();
    event.preventDefault();
    if (element.type === ARTICLE) {
      handleOpenArticleDialog(element.id);
    } else if (element.type === EXTERNAL_LINK) {
      handleOpenExternalLinkDialog(element.id);
    } else {
      handleOpenElementDialog(element.type, element.id);
    }
    handleClose(event);
  };


  const handleDelete = (event) => {
    event.stopPropagation();
    event.preventDefault();
    setOpenRemoveDialog({
      open: true,
      title: title,
      elementId: element.id,
      type: element.type,
      directoryChoice: '',
      newParentId: null
    });
    handleClose(event);
  };

  const handleMove = (event) => {
    event.stopPropagation();
    event.preventDefault();
    setOpenMoveDialog({open: true, title: title, elementId: element.id, parentId: null});
    handleClose(event);
  }

  const handleClick = (event) => {
    event.stopPropagation();
    event.preventDefault();
    if ([ROOT, DIRECTORY, ARTICLE].includes(element.type)) {
      changeCurrent(element.id);
    } else if (element.type === EXTERNAL_LINK) {
      setOpenExternalLinkDialog({
        open: true,
        title: title,
        link: element.translations[lang]
          ? element.translations[lang].external_link
          : ''
      });
    }
  };
  return (
      <ListItemWithWiderSecondaryAction
        button
        onClick={handleClick}
        disableRipple
      >
        <ListItemIcon
          onClick={(event) => {
            event.stopPropagation();
            event.preventDefault();
          }}
          className={classes.dragIcon}
        >
          <DragIndicatorIcon />
        </ListItemIcon>
        <ListItemIcon className={classes.typeIcon}>
          {icon}
        </ListItemIcon>
        <ListItemText primary={title} />
        <Menu
          id={`more-menu-element-${element.id}`}
          open={Boolean(anchorEl)}
          onClose={handleClose}
          anchorEl={anchorEl}
          getContentAnchorEl={null}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          <MenuItem onClick={handleEdit}>
            {t(element.type === ARTICLE
              ? 'edit'
              : 'rename')}
          </MenuItem>
          <MenuItem onClick={handleMove}>
            {t('move')}
          </MenuItem>
          <MenuItem onClick={pinItem}>
            {element.most_popular
              ? t('detach')
              : t('pin')}
          </MenuItem>
          <MenuItem onClick={handleDelete}>
            {t('delete')}
          </MenuItem>
        </Menu>
        <ListItemSecondaryAction>
          {element.most_popular && <IconButton onClick={pinItem}>
            <PinFill color={michelin_black} />
          </IconButton>}
          {!element.most_popular && <IconButton onClick={pinItem}>
            <PinOutline color={michelin_black} />
          </IconButton>}
          <IconButton onClick={showMenu}>
            <MoreHorizIcon />
          </IconButton>
        </ListItemSecondaryAction>
      </ListItemWithWiderSecondaryAction>
  );
};

export default FaqElementsExplorerItem;
