import React, { createRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import Dropzone from 'react-dropzone';
import Card from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';
import Image from '@material-ui/icons/Image';
import FileDownload from '@material-ui/icons/GetApp';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import MTCButton from '../Layout/Button/MTCButton';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import { dispatchInfo, handleError } from '../../store/infos/actions';
import { importTrack } from '../../store/tracks/actions';
import { useStyles } from './TrackCsvDropZone.style';
import { csvToJson, supportedDropFiles, trackToUploadToJson } from './TrackCsvDropZone.const';

const TrackCsvDropZone = () => {

  const classes = useStyles();
  const dropzoneRef = createRef();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handleAddFile = () => {
    dropzoneRef.current.open();
  };

  const handleFileDrop = (accepted, rejected) => {
    if (!accepted[0].name.endsWith('.csv')) {
      dispatch(handleError({
        message: t('error.format.csv') + rejected[0].type,
      }));
    } else {
      const reader = new FileReader();
      reader.onload = () => {
        const fileAsBinaryString = reader.result;
        //Make sure ";" separator are available
        if (!fileAsBinaryString.includes(';')) {
          dispatch(handleError({
            message: t('error.format.csv_separator'),
          }));
        } else {
          dispatch(dispatchInfo(t('csv_on_download')));
          // do whatever you want with the file content
          const tracksToUpload = csvToJson(fileAsBinaryString, ';');
          tracksToUpload.forEach(trackToUpload => {
            if (trackToUpload.country_code && trackToUpload.country_code.length === 2) {
              const formData = new FormData();
              formData.append('json', JSON.stringify(trackToUploadToJson(trackToUpload)));
              dispatch(importTrack(formData))
                .catch(error => {
                  console.error(error);
                  console.info('retry');
                  dispatch(importTrack(formData))
                    .catch(error => {
                      console.error(error);
                      console.info('retry');
                      dispatch(importTrack(formData))
                        .catch(error => {
                          console.error(error);
                        });
                    });
                });
            }
          });
        }
      };
      reader.onabort = () => console.error('file reading was aborted');
      reader.onerror = () => console.error('file reading has failed');
      reader.readAsText(accepted[0]);
    }
  };

  const handleDownloadCSVTemplate = () => {
    //const t = i18n.t.bind(i18n);
    const filename = 'csv_template.csv';
    const jsonTemplate =
      {
        'country_code': t('circuit_template.country_code'),
        'length': t('circuit_template.length'),
        'latitude': t('circuit_template.latitude'),
        'longitude': t('circuit_template.longitude'),
        'start_point_latitude': t('circuit_template.start_point_latitude'),
        'start_point_longitude': t('circuit_template.start_point_longitude'),
        'end_point_latitude': t('circuit_template.end_point_latitude'),
        'end_point_longitude': t('circuit_template.end_point_longitude'),
        'name_de': t('circuit_template.name_de'),
        'name_en': t('circuit_template.name_en'),
        'name_fr': t('circuit_template.name_fr'),
        'name_us': t('circuit_template.name_us'),
        'name_es': t('circuit_template.name_es'),
        'name_it': t('circuit_template.name_it'),
        'name_zh': t('circuit_template.name_zh'),
        'name_nl': t('circuit_template.name_nl'),
        'name_at': t('circuit_template.name_at'),
      };

    const headers = Object.keys(jsonTemplate);
    const values = Object.values(jsonTemplate);
    let csv = '\uFEFF';
    headers.forEach(header => {
      csv = csv.concat(JSON.stringify(header));
      csv = csv.concat(';');
    });
    csv = csv.concat('\r\n');
    values.forEach(value => {
      csv = csv.concat(JSON.stringify(value));
      csv = csv.concat(';');
    });
    csv = csv.replace(/"/gi, '');
    const blob = new Blob([csv], { type: 'application/csv;charset=UTF-8' });
    if (typeof window.navigator.msSaveBlob !== 'undefined') {
      // IE workaround for "HTML7007: One or more blob URLs were
      // revoked by closing the blob for which they were created.
      // These URLs will no longer resolve as the data backing
      // the URL has been freed."
      window.navigator.msSaveBlob(blob, filename);
    } else {
      const blobURL = window.URL.createObjectURL(blob);
      const tempLink = document.createElement('a');
      tempLink.style.display = 'none';
      tempLink.href = blobURL;
      tempLink.setAttribute('download', filename);

      // Safari thinks _blank anchor are pop ups. We only want to set _blank
      // target if the browser does not support the HTML5 download attribute.
      // This allows you to download files in desktop safari if pop up blocking
      // is enabled.
      if (typeof tempLink.download === 'undefined') {
        tempLink.setAttribute('target', '_blank');
      }

      document.body.appendChild(tempLink);
      tempLink.click();
      document.body.removeChild(tempLink);
      window.URL.revokeObjectURL(blobURL);
    }
  };

  return (
    <Card className={classes.card}>
      <CardContent>
        <Typography variant="h5" component="h2">
          {t('track_csv_import')}
        </Typography>
      </CardContent>
      <Dropzone
        className={classes.media}
        ref={dropzoneRef}
        onDrop={handleFileDrop}
        accept={supportedDropFiles}
      >
        <div>
          <Typography variant="subtitle1">{t('csv_drop')}</Typography>
          <br />
          <Image />
        </div>
      </Dropzone>
      <CardActions>
        <Grid container justifyContent={'center'} direction={'column'} alignItems={'center'}>
          <Grid item>
            <MTCButton
              type={'submit'}
              id={'add'}
              onClick={handleAddFile}
              text={t('csv_track')}
            />
          </Grid>
          <Grid item>
            <Link onClick={handleDownloadCSVTemplate} className={classes.link}>
              <FileDownload size={'20'} style={{
                verticalAlign: 'middle',
                marginRight: '5px',
              }} />
              {t('download_track_model')}
            </Link>
          </Grid>
        </Grid>
      </CardActions>
    </Card>
  );
};

export default React.memo(TrackCsvDropZone);
