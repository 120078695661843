import makeStyles from '@material-ui/core/styles/makeStyles';

export const useStyles = makeStyles((theme) => {
  return {
    container: {
      height: 'calc(100vh - 170px)',
      width: '25%',
      backgroundColor: 'rgba(0,0,0,0.1)',
      textAlign: 'left',
      padding: theme.spacing(0.5),
      overflow: 'auto',
      [theme.breakpoints.up('lg')]: {
        width: '15%',
      },
      [theme.breakpoints.down('sm')]: {
        width: 0,
        display: 'none',
      },
    },
    forDialog: {
      width: '100%',
      height: '100%',
      [theme.breakpoints.up('lg')]: {
        width: '100%',
      },
      [theme.breakpoints.down('sm')]: {
        width: '100%',
        display: 'block',
      },
    },
  };
});
