export const columns = [
  {
    id: 'name',
    numeric: false,
    disablePadding: false,
  },
  {
    id: 'item_count',
    numeric: false,
    disablePadding: false,
  },
  {
    id: 'MTC - BLE',
    numeric: false,
    disablePadding: false,
    disableSorting: true,
  },
  {
    id: 'MTC - RF',
    numeric: false,
    disablePadding: false,
    disableSorting: true,
  },
  {
    id: 'MMC - BLE',
    numeric: false,
    disablePadding: false,
    disableSorting: true,
  },
  {
    id: 'MMC - RF',
    numeric: false,
    disablePadding: false,
    disableSorting: true,
  },
];
export const defaultOrderBy = 'name';
