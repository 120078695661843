import makeStyles from '@material-ui/core/styles/makeStyles';
import { michelin_blue_main } from '../../constants/michelin_colors';

export const useStyles = makeStyles(theme => (
  {
    wrapper: {
      margin: theme.spacing(1),
      position: 'relative',
    },
    buttonProgress: {
      color: michelin_blue_main,
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: -12,
      marginLeft: -12,
    },
  }
));
