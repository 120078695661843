import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import { ROLE_ADMIN } from '../../../constants/roles';
import withAuthentication from '../../../hoc/withAuthentication';
import { setTitle } from '../../../store/title/actions';
import EditCarForm from '../../../components/Cars/Form/EditCarForm';

export const EditCarPage = () => {
  const { title } = useSelector(state => state.title);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  let { dimensionId } = useParams();

  if (title !== t('title:title.edit_car_page')) {
    dispatch(setTitle(t('title:title.edit_car_page')));
  }

  return <EditCarForm dimensionId={`${dimensionId}`}/>;
}

export default withAuthentication(ROLE_ADMIN)(EditCarPage);
