import React from 'react';
import Auth from '../services/Auth/Auth';
import { HOMEPAGE } from '../constants/routes';
import Error404 from '../containers/Error404';
import { ROLE_USER } from '../constants/roles';

const arrayOrder = {
  ROLE_USER: 0,
  ROLE_SUPPORT: 1,
  ROLE_ADMIN: 2,
}

const withAuthentication = role => Component => {
  function WithAuthentication(props) {
      const roleStored = localStorage.getItem('role');
      if (!Auth.isAuthenticated()) {
        Auth.logout();
        if (props.history) {
          props.history.replace(HOMEPAGE);
        }
      } else if (role !== roleStored && arrayOrder[role] > arrayOrder[roleStored]) {
        return <Error404 />;
      }
      return (
        <Component {...props} />
      );
  }

  return WithAuthentication;
};

export default withAuthentication;
