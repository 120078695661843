import { CONTENT_LANG_CHANGE } from './types';
import i18next from '../../i18n';

export const INITIAL_STATE = {
  lang: i18next.languages[0].split('-')[0]
};

export default function (state = INITIAL_STATE, action) {
  if (action.type === CONTENT_LANG_CHANGE) {
    return {
      lang: action.payload
    }
  }
  return state;
}

