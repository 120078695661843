import React, { useState } from 'react';
import { Fab, Menu, MenuItem } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { CALL_CENTERS_ADD, LANG_CALL_CENTERS_ADD } from '../../constants/routes';
import { useStyles } from './AddCallCenterButton.style';

const AddCallCenterButton = () => {

  const { t } = useTranslation();
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const history = useHistory();

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Fab
        className={classes.button}
        color="secondary"
        aria-owns={anchorEl ? 'simple-menu' : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        <AddIcon />
      </Fab>

      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={() => history.push(CALL_CENTERS_ADD)}>
          {t('call_centers_page.menu.call_center_add')}
        </MenuItem>
        <MenuItem onClick={() => history.push(LANG_CALL_CENTERS_ADD)}>
          {t('call_centers_page.menu.lang_call_centers_add')}
        </MenuItem>
      </Menu>
    </div>
  );
}
export default AddCallCenterButton;
