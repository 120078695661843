import {
  REQUEST_CAR_DOWNLOADED,
  REQUEST_MESSAGE_DOWNLOADED,
  REQUEST_RALLY_CAR_DOWNLOADED,
  REQUEST_SENDING,
  REQUEST_TRACK_DOWNLOADED,
  REQUESTS_DOWNLOADED,
  REQUESTS_FAILED,
  REQUESTS_PENDING,
} from './types';

export const INITIAL_STATE = {
  error: null,
  content: null,
  pending: false,
  sending: false,
};

export default function (state = INITIAL_STATE, action) {
  let requests = [];
  let requestIndex = -1;
  switch (action.type) {
    case REQUEST_MESSAGE_DOWNLOADED:
    case REQUEST_TRACK_DOWNLOADED:
    case REQUEST_CAR_DOWNLOADED:
    case REQUEST_RALLY_CAR_DOWNLOADED: {
      let subrequests = [];
      if (state.content) {
        requests = JSON.parse(JSON.stringify(state.content));
        switch (action.type) {
          case REQUEST_MESSAGE_DOWNLOADED :
            subrequests = requests.messages_requests;
            break;
          case REQUEST_TRACK_DOWNLOADED:
            subrequests = requests.circuit_requests;
            break;
          case REQUEST_CAR_DOWNLOADED:
            subrequests = requests.v4_car_requests;
            break;
          case REQUEST_RALLY_CAR_DOWNLOADED:
            subrequests = requests.rally_car_requests;
            break;
          default:
            break;
        }
        requestIndex = subrequests.findIndex(request => request.id === action.payload.id);
      }
      if (requestIndex === -1) {
        subrequests.push(action.payload);
      } else {
        subrequests[requestIndex] = action.payload;
      }
      return {
        error: null,
        pending: false,
        sending: false,
        content: requests,
      };
    }
    case REQUEST_SENDING:
      return {
        ...state,
        sending: true,
        error: null,
      };
    case REQUESTS_DOWNLOADED:
      return {
        content: action.payload,
        error: null,
        pending: false,
        sending: false,
      };
    case REQUESTS_FAILED:
      return {
        ...state,
        error: action.payload,
        pending: false,
        sending: false,
      };
    case REQUESTS_PENDING:
      return {
        ...state,
        pending: true,
        error: null,
      };
    default:
      break;
  }
  return state;
}
