import React from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import TesterRow from './TesterRow';
import { getTesters } from '../../store/testers/actions';
import MTCTable from '../Layout/Table/MTCTable';
import MTCLoader from '../Layout/MTCLoader';
import { columns, defaultOrderBy } from './TestersTable.const';

const TestersTable = () => {

  const {
    pending,
    content,
    error,
  } = useSelector(state => state.testers, shallowEqual);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  if (!content && !pending && !error) {
    dispatch(getTesters());
  }

  if (pending) {
    return <MTCLoader />;
  } else {
    return <MTCTable
      content={content}
      defaultOrderBy={defaultOrderBy}
      rowComponent={TesterRow}
      columns={columns}
      paginationLabel={t('testers_per_page')}
      emptyDataLabel={t('no_tester')}
    />;
  }
};

export default TestersTable;
