import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import MTCMenuItem from './MTCMenuItem';
import {
  ADMINS,
  CALL_CENTERS,
  CARS,
  CATALOGS,
  CHALLENGES,
  CONTACTS,
  FAQ_ARTICLES,
  FAQ_ELEMENTS,
  HOMEPAGE,
  IN_APP_NOTIFICATIONS,
  RECEIVER,
  REQUESTS, SUPPORT,
  TESTER,
  TIRES,
  TRACKS,
} from '../../../constants/routes';
import Auth from '../../../services/Auth/Auth';
import { Link } from 'react-router-dom';
import version from '../../../constants/version';
import { useSelector } from 'react-redux';
import { ROLE_ADMIN } from '../../../constants/roles';

export const MenuItems = (props) => {

  const {
    login,
    handleCloseDrawer,
  } = props;
  const history = useHistory();
  const { t } = useTranslation();
  const home = useSelector(state => state.home);
  const role = localStorage.getItem('role');

  if (Auth.isAuthenticated()) {
    return (
      <div>
        <MenuItem
          id={'versionItem'}
          component={Link}
          to={'#'}
          selected={true}
          disabled={true}
        >
          <Typography variant={'subtitle1'}>
            Version : V{version}
          </Typography>
        </MenuItem>
        {process.env.REACT_APP_ENV &&
          <MenuItem
            id={'devItem'}
            component={Link}
            to={'#'}
            selected={true}
            disabled={true}
          >
            <Typography variant={'subtitle1'}>
              DEV environnement
            </Typography>
          </MenuItem>}
        {role === ROLE_ADMIN && <>
          <MTCMenuItem
            path={HOMEPAGE}
            pathname={history.location.pathname}
            value={t('title:menu.home')}
            handleCloseDrawer={handleCloseDrawer}
          />
          <MTCMenuItem
            path={CARS}
            pathname={history.location.pathname}
            value={t('title:menu.cars')}
            handleCloseDrawer={handleCloseDrawer}
          />
        </>}
        <MTCMenuItem
          path={TRACKS}
          pathname={history.location.pathname}
          value={t('title:menu.circuits')}
          handleCloseDrawer={handleCloseDrawer}
          alertCount={role === ROLE_ADMIN
            ? home.reportedCircuitCommentTotalCount
            : 0}
        />
        {role === ROLE_ADMIN && <>
          <MTCMenuItem
            path={REQUESTS}
            subpath={CONTACTS}
            pathname={history.location.pathname}
            value={t('title:menu.requests')}
            handleCloseDrawer={handleCloseDrawer}
          />
          <MTCMenuItem
            path={RECEIVER}
            pathname={history.location.pathname}
            value={t('title:menu.receiver')}
            handleCloseDrawer={handleCloseDrawer}
          />
        </>}
        <MTCMenuItem
          path={ADMINS}
          pathname={history.location.pathname}
          value={t('title:menu.admins')}
          handleCloseDrawer={handleCloseDrawer}
        />
        {role === ROLE_ADMIN && <>
          <MTCMenuItem
            path={TESTER}
            pathname={history.location.pathname}
            value={t('title:menu.testers')}
            handleCloseDrawer={handleCloseDrawer}
          />
          <MTCMenuItem
            path={TIRES}
            pathname={history.location.pathname}
            value={t('title:menu.tires')}
            handleCloseDrawer={handleCloseDrawer}
          />
          <MTCMenuItem
            path={CALL_CENTERS}
            pathname={history.location.pathname}
            value={t('title:menu.callCenters')}
            handleCloseDrawer={handleCloseDrawer}
          />
          <MTCMenuItem
            path={CHALLENGES}
            pathname={history.location.pathname}
            value={t('title:menu.challenges')}
            handleCloseDrawer={handleCloseDrawer}
          />
          <MTCMenuItem
            path={CATALOGS}
            pathname={history.location.pathname}
            value={t('title:menu.catalogs')}
            handleCloseDrawer={handleCloseDrawer}
          />
          <MTCMenuItem
            path={IN_APP_NOTIFICATIONS}
            pathname={history.location.pathname}
            value={t('title:menu.inAppNotification')}
            handleCloseDrawer={handleCloseDrawer}
          />
          <MTCMenuItem
            path={FAQ_ARTICLES}
            pathname={history.location.pathname}
            value={t('title:menu.faqArticles')}
            handleCloseDrawer={handleCloseDrawer}
          />
          <MTCMenuItem
            path={FAQ_ELEMENTS}
            pathname={history.location.pathname}
            value={t('title:menu.faqElements')}
            handleCloseDrawer={handleCloseDrawer}
          />
        </>}
        <MTCMenuItem
          path={SUPPORT}
          pathname={history.location.pathname}
          value={t('title:menu.support')}
          handleCloseDrawer={handleCloseDrawer}
        />
        <Divider />
        <MenuItem
          id="logout"
          onClick={() => {
            Auth.logout();
            handleCloseDrawer();
            history.replace(HOMEPAGE);
          }}
          value={t('logout')}
        >
          <Typography variant={'subtitle1'}>
            {t('logout')}
          </Typography>
        </MenuItem>
      </div>
    );
  } else {
    return (
      <MenuItem
        id="login"
        onClick={login.bind(this)}
        value={t('login')}
      >
        <Typography variant={'subtitle1'}>
          {t('login')}
        </Typography>
      </MenuItem>
    );
  }
};

MenuItems.propTypes = {
  login: PropTypes.func.isRequired,
};

export default React.memo(MenuItems);
