import React from 'react';
import TireForm from '../../components/Tires/TireForm';
import { ROLE_ADMIN } from '../../constants/roles';
import withAuthentication from '../../hoc/withAuthentication';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { setTitle } from '../../store/title/actions';

export function AddTirePage() {

  const { t } = useTranslation();
  const { title } = useSelector(state => state.title);
  const dispatch = useDispatch();
  let history = useHistory();

  if (title !== t('title:title.add_tire_page')) {
    dispatch(setTitle(t('title:title.add_tire_page')));
  }

  return (
    <TireForm history={history}/>
  );
}

export default withAuthentication(ROLE_ADMIN)(AddTirePage);
