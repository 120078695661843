import React from 'react';
import Typography from '@material-ui/core/Typography';
import { useStyles } from './MTCDetailLabel.style';

const MTCDetailLabel = ({ label, value, }) => {
  const classes = useStyles();

  return (
    <p className={classes.text}>
      <Typography
        component="span"
        variant="subtitle1"
        color={'primary'}
        className={classes.textLabel}
      >
        {label}
      </Typography>
      <span className={classes.textValue}>
        {value}
      </span>
    </p>
  );
};

export default React.memo(MTCDetailLabel);
