const RECEIVERS_MMC_BLE_PENDING = 'receivers_mmc_ble_pending';
const RECEIVER_MMC_BLE_FAILED = 'receiver_mmc_ble_failed';
const RECEIVER_MMC_BLE_DOWNLOADED = 'receiver_mmc_ble_downloaded';
const RECEIVER_MMC_BLE_SENDING = 'receiver_mmc_ble_sending';
const RECEIVERS_MMC_BLE_DOWNLOADED = 'receivers_mmc_ble_downloaded';
const RECEIVERS_MMC_BLE_DELETED = 'receivers_mmc_ble_deleted';

export {
  RECEIVERS_MMC_BLE_PENDING,
  RECEIVER_MMC_BLE_FAILED,
  RECEIVER_MMC_BLE_DOWNLOADED,
  RECEIVER_MMC_BLE_SENDING,
  RECEIVERS_MMC_BLE_DOWNLOADED,
  RECEIVERS_MMC_BLE_DELETED
};
