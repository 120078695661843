import {
  IN_APP_NOTIFICATION_DELETED,
  IN_APP_NOTIFICATION_DOWNLOADED,
  IN_APP_NOTIFICATION_SENDING,
  IN_APP_NOTIFICATIONS_DOWNLOADED,
  IN_APP_NOTIFICATIONS_FAILED,
  IN_APP_NOTIFICATIONS_PENDING,
} from './types';
import ApiService from '../../services/ApiService';
import { handleError } from '../infos/actions';

export const inAppNotificationsPendingAction = () => (
  {
    type: IN_APP_NOTIFICATIONS_PENDING,
  }
);
export const inAppNotificationSendingAction = () => (
  {
    type: IN_APP_NOTIFICATION_SENDING,
  }
);
export const inAppNotificationDownloadedAction = (notification) => (
  {
    type: IN_APP_NOTIFICATION_DOWNLOADED,
    payload: notification,
  }
);
export const inAppNotificationsDownloadedAction = (notifications) => (
  {
    type: IN_APP_NOTIFICATIONS_DOWNLOADED,
    payload: notifications,
  }
);
export const inAppNotificationsFailedAction = (error) => (
  {
    type: IN_APP_NOTIFICATIONS_FAILED,
    payload: error,
  }
);
export const inAppNotificationDeletedAction = (id) => (
  {
    type: IN_APP_NOTIFICATION_DELETED,
    payload: id,
  }
);

export function getInAppNotifications() {
  return (dispatch) => {
    dispatch(inAppNotificationsPendingAction());
    return ApiService.getInAppNotification()
      .then(data => {
        dispatch(inAppNotificationsDownloadedAction(data));
        return data;
      }).catch(error => {
        dispatch(inAppNotificationsFailedAction(error));
        dispatch(handleError(error));
      });
  };
}

export function postInAppNotifications(data) {
  return (dispatch) => {
    dispatch(inAppNotificationSendingAction());
    return ApiService.postInAppNotification(data)
      .then(data => {
        dispatch(inAppNotificationDownloadedAction(data));
        return data;
      }).catch(error => {
        dispatch(inAppNotificationsFailedAction(error));
        dispatch(handleError(error));
      });
  };
}

export function putInAppNotifications(id, data) {
  return (dispatch) => {
    dispatch(inAppNotificationSendingAction());
    return ApiService.putInAppNotification(id, data)
      .then(data => {
        dispatch(inAppNotificationDownloadedAction(data));
        return data;
      }).catch(error => {
        dispatch(inAppNotificationsFailedAction(error));
        dispatch(handleError(error));
      });
  };
}

export function deleteInAppNotifications(id) {
  return (dispatch) => {
    dispatch(inAppNotificationSendingAction());
    return ApiService.deleteInAppNotification(id)
      .then(() => {
        dispatch(inAppNotificationDeletedAction(id));
      }).catch(error => {
        dispatch(inAppNotificationsFailedAction(error));
        dispatch(handleError(error));
      });
  };
}
