/* Black */
export const michelin_black = '#000000';

/* White */
export const michelin_white = '#F5F5F5';

/* Grey */
export const michelin_text_grey_extra_light = '#DFDFDF';
export const michelin_text_grey_light = '#9B9B9B';
export const michelin_text_grey_dark = '#4A4A4A';

/* Yellow */
export const michelin_yellow_light = '#fdef60';
export const michelin_yellow_main = '#FCE500';
//export const michelin_yellow_dark = '#ddc800';
export const michelin_yellow_dark = '#EFDA00';

/* Blue */
export const michelin_blue_light = '#26509B';
export const michelin_blue_main = '#003D7B';
export const michelin_blue_dark = '#003061';
export const michelin_dusk_blue = 'rgba(39,80,155,0.1)'

export const michelin_blue_opacity = '#003D7B33';//'rgba(0,61,123,0.2)';
/* Red */
export const michelin_red = '#F24E30';

/* Green */
export const michelin_green = '#27A282';

/* orange */
export const michelin_orange = '#FFA81C';
export const michelin_red_orange = '#EB6E10';


/* Grey */
//export const michelin_transparent_grey_shadow = '#AA787878';

/* green */
//export const  michelin_orange_green = exports.michelin_orange_green = "#828A30";

// blue
//export const michelin_blue_light_text = '#66B3FF';
//export const  michelin_blue_light = exports.michelin_blue_light = "#2F7CC8";
//export const  michelin_blue_detail_run_recos = exports.michelin_blue_detail_run_recos = "#1C6CBB";
//export const  michelin_separator_color_history = exports.michelin_separator_color_history = "#3264BD";
//export const michelin_blue_michelin = '#26509B';
//export const  michelin_separator_color = exports.michelin_separator_color = "#004F9E";
//export const  michelin_blue_item_selected = exports.michelin_blue_item_selected = "#234A8C";
//export const michelin_blue_background = '#003D7B';
//export const michelin_blue_shadow = '#003061';
