import React from 'react';
import { useTranslation } from 'react-i18next';
import MTCTable from '../../Layout/Table/MTCTable';
import MTCLoader from '../../Layout/MTCLoader';
import FirmwareGroupRow from './FirmwareGroupRow';
import { columns, defaultOrderBy } from './FirmwareGroupsTable.const';

const FirmwareGroupsTable = ({ pending, content }) => {

  const { t } = useTranslation();
  if (pending) {
    return <MTCLoader />;
  } else {
    return <MTCTable
      content={content}
      defaultOrderBy={defaultOrderBy}
      defaultOrder={'asc'}
      rowComponent={FirmwareGroupRow}
      columns={columns}
      paginationLabel={t('firmware_groups_per_page')}
      emptyDataLabel={t('no_firmware_group')}
    />;
  }
};

export default FirmwareGroupsTable;
