import React from 'react';
import Grid from '@material-ui/core/Grid';
import makeStyle from '@material-ui/core/styles/makeStyles';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import logo from '../../resources/pictures/logo_michelin_splash_blue.svg';
import pilote from '../../resources/pictures/pilote-mtc.png';
import iOSfr from '../../resources/pictures/Download_on_the_App_Store_Badge_FR_RGB_blk_100517.svg';
import iOSen
  from '../../resources/pictures/Download_on_the_App_Store_Badge_US-UK_RGB_blk_092917.svg';
import androidFr from '../../resources/pictures/fr_badge_web_generic.png';
import androidEn from '../../resources/pictures/en_badge_web_generic.png';
import { ANDROID_LINK, IOS_LINK } from '../../constants/env';
import { michelin_blue_light, michelin_white } from '../../constants/michelin_colors';
import { drawerWidth } from '../../components/Layout/Menu/Menu/Menu.const';

/* eslint  no-undef: "off" */
const navigatorLang = navigator.language.substr(0, 2).toLowerCase();

const useStyles = makeStyle({
  mtcImg: {
    background: `url(${pilote}) no-repeat top center #013e7f`,
    backgroundSize: 'auto 100%',
    padding: '100px 15px',
    borderTopLeftRadius: '10px',
    borderTopRightRadius: '10px',
    marginBottom: '40px',
    color: michelin_white
  },
  parent: {
    borderRadius: '10px',
    background: '#ffffff',
    boxShadow: '0 0 10px rgba(0, 0, 0, .1)',
    padding: '0 0 40px',
  },
  appLogo: {
    marginBottom: '30px',
    height: '100px',
    pointerEvents: 'none',
  },
  referralCode: {
    boxShadow: "0 0 10px rgb(0 0 0 / 10%)",
    borderRadius: "10px",
    backgroundColor: michelin_blue_light,
    fontSize: "1.5em",
    padding: '5px',
    width: '8em',
    color: michelin_white,
    margin: '1em auto'
  },
  text:  {
    padding: '10px'
  }
});

const getStyleBlock = () => {
  const styles = 'body {padding: 50px;background: #f7f7f7;}\n' +
    '@media screen and (max-width: 959px) {\n' +
    '   .store-img {height: 70px; margin-bottom: 30px;}\n' +
    '   body {padding:0;}\n' +
    '}';
  return (
    <style type="text/css">{styles}</style>
  );
};

const block = (link, img) =>
  <Grid item xs={12} sm={12} md={6}>
    <a href={link}>
      <img className="store-img" src={img} height={'70px'} alt={link} />
    </a>
  </Grid>;

const StorePage = () => {

  const { t } = useTranslation();
  const history = useHistory();
  const classes = useStyles();

  return (
    <div className="App" style={{
      padding: `50px ${drawerWidth / 12}px`,
      maxWidth: '1000px',
      margin: '0 auto',
    }}>
      {getStyleBlock()}
      <img src={logo} className={classes.appLogo} alt="logo" />
      <div className={classes.parent}>
        <div className={classes.mtcImg}>
          <h1><b><i>Michelin Track Connect</i></b></h1>
        </div>
        <div className={classes.text}>
          {history.location.search.includes('unvailable') &&
            <>
              <h2>{t('store:invitation_unavailable_title')}</h2>
              <p className={classes.text}>{t('store:invitation_unavailable_text')}</p>
            </>
          }
          {history.location.search.includes('referral_code') &&
            <>
              <h2>{t('store:sponsor_referral_code_title')}</h2>
              <p className={classes.text}>{t('store:sponsor_referral_code_text')}</p>
              <p className={classes.referralCode}>
                {history.location.search.split('referral_code=')[1].split('&')[0]}
              </p>
            </>
          }
        </div>
        <Grid container>
          {block(IOS_LINK,
            navigatorLang === 'fr'
              ? iOSfr
              : iOSen,
          )}
          {block(ANDROID_LINK,
            navigatorLang === 'fr'
              ? androidFr
              : androidEn,
          )}
        </Grid>
      </div>
    </div>
  );
};

export default StorePage;
