import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import useTheme from '@material-ui/core/styles/useTheme';
import { useStyles } from '../FaqElementsDialog.style';
import FaqElementsExplorerTree from '../../FaqElementsExplorerTree/FaqElementsExplorerTree';
import { useTranslation } from 'react-i18next';

const FaqElementsMoveDialog = ({
                                 fullScreen,
                                 openMoveDialog,
                                 handleCloseMoveDialog,
                                 selectParent,
                                 handleMove,
                                 sending,
                                 content
                               }) => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const { t } = useTranslation();
  return (
    <Dialog
      id="move-dialog"
      open={openMoveDialog.open}
      scroll="paper"
      fullScreen={fullScreen}
      onClose={handleCloseMoveDialog}
      fullWidth
    >
      <DialogTitle disableTypography>
        <Typography variant="h6">
          {t('move')}{' '}{openMoveDialog.title}
        </Typography>
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={handleCloseMoveDialog}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers={true}>
        <FaqElementsExplorerTree
          elements={content}
          changeCurrent={selectParent}
          setOpenExternalLinkDialog={() => {}}
          forDialog
          hideId={openMoveDialog.elementId}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCloseMoveDialog} backgroundcolor={'secondary'}>
          {t('cancel')}
        </Button>
        <div className={classes.wrapper}>
          <Button
            color={'primary'}
            disabled={openMoveDialog.parentId === null || sending}
            type={'button'}
            onClick={handleMove}
          >
            {t('validate')}
          </Button>
          {sending && <CircularProgress size={24} className={classes.buttonProgress} />}
        </div>
      </DialogActions>
    </Dialog>

  );
};

export default FaqElementsMoveDialog;
