import makeStyles from '@material-ui/core/styles/makeStyles';

export const useStyles = makeStyles({
  title: {
    marginTop: '2em',
  },
  input: {
    marginTop: 0,
  },
  button: {
    marginTop: '2em',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  container: {
    marginBottom: '2em',
  },
});
