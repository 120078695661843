import makeStyles from '@material-ui/core/styles/makeStyles';

export const useStyles = makeStyles(() => {
  return {
    card: {
      width: 'calc(100% - 2em)',
      paddingBottom: 0,
      paddingLeft: '1em',
      paddingRight: '1em',
      paddingTop: '1em',
      margin: '1em',
    },
    header: {
      textAlign: 'left',
    },
    media: {
      border: '1px dashed black',
      padding: '10px',
      marginLeft: '15px',
      marginRight: '15px',
      borderRadius: '10px',
    },
    center: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    },
    link: {
      padding: '5px 0',
    },
  };
});
