import {
  DEFAULT_A_LATITUDE,
  DEFAULT_A_LONGITUDE,
  DEFAULT_B_LATITUDE,
  DEFAULT_B_LONGITUDE,
  DEFAULT_C_LATITUDE,
  DEFAULT_C_LONGITUDE,
  DEFAULT_D_LATITUDE,
  DEFAULT_D_LONGITUDE,
  DEFAULT_LATITUDE,
  DEFAULT_LONGITUDE,
} from '../../../constants/map_default';
import { data_languages } from '../../../constants/lang';

export const GOOGLE_MAPS_HOST = 'https://maps.googleapis.com/maps/api/js?key=';
export const GOOGLE_MAPS_API_KEY = 'AIzaSyCfHzGGb8YvlGZt6AIGOITBagxFZ2zN30Y';
export const GOOGLE_MAPS_OPTIONS = '&v=3.exp&libraries=geometry,drawing,places';
export const INITIAL_STATE = {
  ...data_languages.reduce((o, key) => (
    {
      ...o,
      [`name_${key}`]: '',
    }
  ), {}),
  id: '',
  country_code: '',
  length: '',
  is_public: false,
  latitude: DEFAULT_LATITUDE,
  longitude: DEFAULT_LONGITUDE,
  picture: '',
  use_algorithm: false,
  geojson: '',
  start_point_latitude: DEFAULT_A_LATITUDE,
  start_point_longitude: DEFAULT_A_LONGITUDE,
  end_point_latitude: DEFAULT_B_LATITUDE,
  end_point_longitude: DEFAULT_B_LONGITUDE,
  pictureToUpload: null,
  geojsonToUpload: null,
  drawMarkerLabel: null,
  sending: false,
  nextTrackToUpdate: null,
  has_finish_line: false,
  finish_start_point_latitude: DEFAULT_C_LATITUDE,
  finish_start_point_longitude: DEFAULT_C_LONGITUDE,
  finish_end_point_latitude: DEFAULT_D_LATITUDE,
  finish_end_point_longitude: DEFAULT_D_LONGITUDE,
  sectors: [],
  create_sectors: false,
  confirmDialogOpen: false,
  duplicateFrom: null,
  lap_line_bearing: null,
  is_lap_line_bearing_reversed: false,
  finish_lap_line_bearing: null,
  is_finish_lap_line_bearing_reversed: false,
  canNotify: false,
  originalNotify: false,
  notify: false
};
export const createEmptySector = (number, state) => {
  return {
    number,
    line_start_point_latitude: state.has_finish_line
      ? parseFloat(state.finish_start_point_latitude)
      : parseFloat(state.start_point_latitude),
    line_start_point_longitude: (
      state.has_finish_line
        ? parseFloat(state.finish_start_point_longitude)
        : parseFloat(state.start_point_longitude)
    ) + (
      0.001 * number
    ),
    line_end_point_latitude: state.has_finish_line
      ? parseFloat(state.finish_end_point_latitude)
      : parseFloat(state.end_point_latitude),
    line_end_point_longitude: (
      state.has_finish_line
        ? parseFloat(state.finish_end_point_longitude)
        : parseFloat(state.end_point_longitude)
    ) + (
      0.001 * number
    ),
  };
};
export const createLastSector = (number, state) => {
  return {
    number,
    line_start_point_latitude: state.has_finish_line
      ? parseFloat(state.finish_start_point_latitude)
      : parseFloat(state.start_point_latitude),
    line_start_point_longitude: state.has_finish_line
      ? parseFloat(state.finish_start_point_longitude)
      : parseFloat(state.start_point_longitude),
    line_end_point_latitude: state.has_finish_line
      ? parseFloat(state.finish_end_point_latitude)
      : parseFloat(state.end_point_latitude),
    line_end_point_longitude: state.has_finish_line
      ? parseFloat(state.finish_end_point_longitude)
      : parseFloat(state.end_point_longitude),
  };
};
export const stateWithTrack = track => (
  {
    ...data_languages.reduce((o, key) => (
      {
        ...o,
        [`name_${key}`]: track.translations[key]
          ? track.translations[key].name
          : '',
      }
    ), {}),
    id: track.id,
    country_code: track.country_code.toUpperCase(),
    is_public: track.is_public,
    length: track.length,
    latitude: track.latitude,
    longitude: track.longitude,
    picture: track.picture,
    geojson: track.geojson,
    use_algorithm: track.use_algorithm,
    start_point_latitude: track.start_point_latitude,
    start_point_longitude: track.start_point_longitude,
    end_point_latitude: track.end_point_latitude,
    end_point_longitude: track.end_point_longitude,
    has_finish_line: track.has_finish_line,
    finish_start_point_latitude: track.finish_start_point_latitude
      ? track.finish_start_point_latitude
      : DEFAULT_LATITUDE,
    finish_start_point_longitude: track.finish_start_point_longitude
      ? track.finish_start_point_longitude
      : DEFAULT_LONGITUDE,
    finish_end_point_latitude: track.finish_end_point_latitude
      ? track.finish_end_point_latitude
      : DEFAULT_LATITUDE,
    finish_end_point_longitude: track.finish_end_point_longitude
      ? track.finish_end_point_longitude
      : DEFAULT_LONGITUDE,

    lap_line_bearing: track.lap_line_bearing,
    is_lap_line_bearing_reversed: track.is_lap_line_bearing_reversed,
    finish_lap_line_bearing: track.finish_lap_line_bearing,
    is_finish_lap_line_bearing_reversed: track.is_finish_lap_line_bearing_reversed,
    has_been_notify: track.has_been_notify,
    canNotify: track.is_public && !track.has_been_notify,
    originalNotify: track.has_been_notify,
    notify: track.has_been_notify,
    sectors: (
      track.last_sector_version && track.last_sector_version > 0
    )
      ? track.sectors
        .filter(e => e.version === track.last_sector_version)
        .sort((a, b) => {
          return a.number > b.number
            ? 1
            : -1;
        })
      : [],
  }
);
