import { data_languages } from '../../../../../constants/lang';

export const INITIAL_STATE = {
  id: null,
  ...data_languages.reduce((o, key) => (
    {
      ...o,
      [`title_${key}`]: '',
      [`tags_${key}`]: '',
    }
  ), {}),
}

export const INITIAL_ERROR_STATE = {
  ...data_languages.reduce((o, key) => (
    {
      ...o,
      [`title_${key}_error`]: null,
      [`tags_${key}_error`]: null,
    }
  ), {}),
};
