import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { data_languages, data_languages_no_fr } from '../../../../constants/lang';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import MTCTextField from '../../../Form/MTCTextField';
import MTCButton from '../../../Layout/Button/MTCButton';
import { useTheme } from '@material-ui/core';
import { postCatalogsTags, putCatalogsTags } from '../../../../store/catalogsTags/actions';
import { CATALOGS_TAGS } from '../../../../constants/routes';
import { INITIAL_ERROR_STATE, INITIAL_STATE, stateWithTag } from './CatalogsTagsForm.const';
import { useStyles } from './CatalogsTagsForm.style';

export const CatalogsTagsFrom = (props) => {
  const { tag } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const theme = useTheme();
  const classes = useStyles(theme);
  const { sending } = useSelector(state => state.catalogsTags);
  const [state, setState] = useState(INITIAL_STATE);
  const [errors, setErrors] = useState(INITIAL_ERROR_STATE);

  if (tag !== null && state.id === null) {
    setState(stateWithTag(tag));
  }

  const isEditing = tag !== null;

  const handleStatePropertyChange = (property, value) => {
    let newState = { ...state };
    newState[property] = value;
    let newErrors = { ...errors };
    newErrors[property + '_error'] = null;
    setState(newState);
    setErrors(newErrors);
  };

  const handleSubmit = event => {
    event.preventDefault();
    event.stopPropagation();
    if (!sending) {
      let errorCount = 0;
      let newErrorState = { ...INITIAL_ERROR_STATE };
      data_languages.forEach(dl => {
        const nameKey = `name_${dl}`;
        const name = state[nameKey];
        if (name === null || name.trim() === '') {
          errorCount++;
          newErrorState[nameKey + '_error'] = t('error.required_error');
        }
      });

      if (errorCount === 0) {

        const jsonToSend = {
          translations: data_languages.reduce((o, key) => (
            {
              ...o,
              [key]: {
                name: state[`name_${key}`]
              },
            }
          ), {}),
        };

        if (isEditing) {
          dispatch(putCatalogsTags(state.id, jsonToSend))
            .then(() => {
              history.push(CATALOGS_TAGS)
            });
        } else {
          dispatch(postCatalogsTags(jsonToSend))
            .then(() => {
              history.push(CATALOGS_TAGS)
            });
        }
      } else {
        setErrors(newErrorState);
      }
    }
  };
  return <form>
    <Grid
      container
      align="center"
      className={classes.grid}
      direction={'column'}
      justifyContent={'center'}
    >
      <Grid item xs={12}>
        <Paper className={classes.paper}>
          <Grid container>
            <Grid item xs={12}>
              <Typography
                align="center"
                variant="h5"
                component="h2"
                gutterBottom>
                {t('name')}
              </Typography>
            </Grid>
            <MTCTextField
              gridMD={12}
              id={`name_fr`}
              key={`name_fr`}
              onChange={event => handleStatePropertyChange(`name_fr`, event.target.value)}
              value={state[`name_fr`]}
              required={true}
              errorMessage={errors[`name_fr_error`]}
              onBlur={event => setState({
                ...state,
                ...data_languages.reduce((o, key) => (
                  {
                    ...o,
                    [`name_${key}`]: state[`name_${key}`] || event.target.value,
                  }
                ), {}),
              })}
            />
            {data_languages_no_fr.map(lg => (
              <MTCTextField
                gridMD={12}
                id={`name_${lg}`}
                key={`name_${lg}`}
                onChange={event => handleStatePropertyChange(`name_${lg}`,
                  event.target.value,
                )}
                value={state[`name_${lg}`]}
                required={true}
                errorMessage={errors[`name_${lg}_error`]}
              />
            ))}
          </Grid>
        </Paper>
      </Grid>
      <Grid item>
        <MTCButton
          disabled={false}
          onClick={handleSubmit}
          type={'button'}
          sending={sending}
          text={isEditing
            ? t('edit')
            : t('create')}
        />
      </Grid>
    </Grid>
  </form>;
};
CatalogsTagsFrom.defaultProps =
  {
    tag: null,
  }
;
export default CatalogsTagsFrom;
