import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import withAuthentication from '../../hoc/withAuthentication';
import { ROLE_ADMIN } from '../../constants/roles';
import { setTitle } from '../../store/title/actions';
import { getCatalogsTags } from '../../store/catalogsTags/actions';
import CatalogsTagsTable from '../../components/Catalogs/Tags/CatalogsTagsTable';
import AddButton from '../../components/Layout/Button/AddButton';
import { CATALOGS_TAGS_ADD } from '../../constants/routes';

export const CatalogsTagsPage = () => {

  const { t } = useTranslation();
  const { title } = useSelector(state => state.title);
  const {
    content,
    pending,
    error,
  } = useSelector(state => state.catalogsTags);
  const dispatch = useDispatch();

  if (title !== t('title:title.catalog_tags_page')) {
    dispatch(setTitle(t('title:title.catalog_tags_page')));
  }

  if (!content && !pending && !error) {
    dispatch(getCatalogsTags());
  }

  return <>
    <CatalogsTagsTable pending={pending} content={content} />
    <AddButton path={CATALOGS_TAGS_ADD} />
  </>;
};

export default withAuthentication(ROLE_ADMIN)(CatalogsTagsPage);