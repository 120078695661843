import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { setTitle } from '../../store/title/actions';
import AddButton from '../../components/Layout/Button/AddButton';
import { IN_APP_NOTIFICATIONS_ADD } from '../../constants/routes';
import InAppNotificationTable
  from '../../components/InAppNotifications/Table/InAppNotificationTable';
import withAuthentication from '../../hoc/withAuthentication';
import { ROLE_ADMIN } from '../../constants/roles';

export const InAppNotificationsPage = () => {
  const { t } = useTranslation();
  const { title } = useSelector(state => state.title);
  const dispatch = useDispatch();

  if (title !== t('title:title.in_app_notifications_page')) {
    dispatch(setTitle(t('title:title.in_app_notifications_page')));
  }

  return <>
    <InAppNotificationTable />
    <AddButton
      path={IN_APP_NOTIFICATIONS_ADD}
    />
  </>;
}

export default withAuthentication(ROLE_ADMIN)(InAppNotificationsPage);
