/* eslint-disable */
import React from 'react';
import {michelin_blue_main} from "../../constants/michelin_colors";

const CsvFile = props => {
  const color = props.color || michelin_blue_main;
  const size = props.size || "25";
  const style = props.style || {  };
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width={size}
      height={size}
      viewBox={`0 0 25 25`}
      style={style}
    >
      <defs>
        <path
          id="path-1"
          d="M14.12 2.083c.295 0 .575.124.772.342l5.67 6.25c.175.192.272.44.272.7v10.938c0 1.435-1.195 2.604-2.663 2.604H6.828c-1.467 0-2.661-1.17-2.661-2.605V4.688c0-1.435 1.194-2.604 2.661-2.604zM11.46 4.167h-4.63c-.32 0-.579.233-.579.52v15.625c0 .288.26.521.578.521h11.343c.319 0 .58-.233.58-.52v-8.855h-4.465c-1.56 0-2.827-1.33-2.827-2.968V4.167zM9.106 15c.47 0 .918.11 1.346.331l-.36 1.001-.16-.077a3.753 3.753 0 00-.321-.129 1.563 1.563 0 00-.517-.084c-.28 0-.497.124-.653.372-.157.248-.235.59-.235 1.025 0 .905.318 1.357.953 1.357.192 0 .379-.028.559-.086.18-.058.361-.128.543-.21v1.075l-.185.08a2.9 2.9 0 01-1.044.18c-.656 0-1.158-.205-1.508-.617-.35-.412-.524-1.007-.524-1.786 0-.487.085-.914.254-1.283.17-.37.413-.653.73-.851.318-.199.692-.298 1.122-.298zm3.293 0c.449 0 .907.113 1.375.338l-.36.981-.17-.08c-.335-.148-.628-.222-.881-.222-.153 0-.264.029-.333.087a.27.27 0 00-.104.215c0 .092.044.175.132.248.089.073.328.206.718.399.374.182.634.378.78.587.145.21.218.472.218.79 0 .292-.069.55-.205.777a1.373 1.373 0 01-.591.527c-.258.126-.56.189-.906.189-.29 0-.532-.022-.728-.066-.196-.044-.4-.12-.612-.23v-1.133l.227.115c.153.071.31.13.471.176.242.07.464.105.666.105.174 0 .302-.033.383-.098a.31.31 0 00.121-.253.294.294 0 00-.049-.169.587.587 0 00-.157-.146 6.336 6.336 0 00-.578-.302c-.283-.14-.495-.275-.637-.406a1.24 1.24 0 01-.315-.45 1.605 1.605 0 01-.102-.602c0-.433.145-.771.436-1.013.292-.243.692-.364 1.2-.364zm2.722.068l.59 2.384.044.19c.096.43.148.734.159.916a9.749 9.749 0 01.193-1.094l.603-2.396h1.313L16.6 19.77h-1.381l-1.417-4.703h1.319zM13.659 4.167h-.117V8.49c0 .488.333.885.744.885h4.099l-4.726-5.208z"
        ></path>
      </defs>
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <mask fill={color}>
          <use xlinkHref="#path-1"></use>
        </mask>
        <use fill={color} xlinkHref="#path-1"></use>
      </g>
    </svg>
  )
};

export default React.memo(CsvFile);



