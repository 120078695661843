import {
  USER_DOWNLOADED,
  USER_SENDING,
  USERS_DOWNLOADED,
  USERS_FAILED,
  USERS_PENDING,
} from './types';
import ApiService from '../../services/ApiService';

export const userSendingAction = () => ({
  type: USER_SENDING,
});

export const userDownloadedAction = user => ({
  type: USER_DOWNLOADED,
  payload: user,
});

export const usersDownloadedAction = users => ({
  type: USERS_DOWNLOADED,
  payload: users,
});

export const usersFailedAction = error => ({
  type: USERS_FAILED,
  payload: error,
});

export const usersPendingAction = () => ({
  type: USERS_PENDING,
});

export function getUser() {
  return (dispatch) => {
    dispatch(usersPendingAction());
    return ApiService
      .getUser()
      .then((data) => {
        localStorage.setItem('in_db', 'inDB');
        dispatch(userDownloadedAction(data));
      })
      .catch((error) => {
        dispatch(usersFailedAction(error));
      });
  };
}

export function getUsers() {
  return (dispatch) => {
    dispatch(usersPendingAction());
    return ApiService
      .getUsers()
      .then((data) => {
        dispatch(usersDownloadedAction(data));
      })
      .catch((error) => {
        dispatch(usersFailedAction(error));
      });
  };
}

export function postUser(user) {
  return (dispatch) => {
    dispatch(userSendingAction());
    return ApiService
      .postUser(user)
      .then((data) => {
        localStorage.setItem('in_db', 'inDB');
        dispatch(userDownloadedAction(data));
      })
      .catch((error) => {
        dispatch(usersFailedAction(error));
      });
  };
}
