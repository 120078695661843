export const columns = [
  {
    id: 'translations.fr.name',
    numeric: false,
    disablePadding: false,
  },
  {
    id: 'start_date',
    numeric: false,
    disablePadding: false,
  },
  {
    id: 'end_date',
    numeric: false,
    disablePadding: false,
  },
  {
    id: 'obtaining_type',
    numeric: false,
    disablePadding: false,
  },
  {
    id: 'obtaining_needed_point',
    numeric: false,
    disablePadding: false,
  },
  {
    id: 'obtaining_environments',
    numeric: false,
    disablePadding: false,
  },
  {
    numeric: false,
    disablePadding: false,
  },
];
export const defaultOrderBy = 'translations.fr.name';
