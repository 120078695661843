const TABLE_RALLY_CARS_PENDING = 'table_rally_cars_pending';
const TABLE_RALLY_CARS_FAILED = 'table_rally_cars_failed';
const TABLE_RALLY_CARS_DOWNLOADED = 'table_rally_cars_downloaded';
const TABLE_RALLY_CARS_RESET = 'table_rally_cars_reset';
const TABLE_RALLY_CARS_BRAND_DOWNLOADED = 'table_rally_cars_brand_downloaded';
const TABLE_RALLY_CARS_MODEL_DOWNLOADED = 'table_rally_cars_model_downloaded';
const TABLE_RALLY_CARS_GROUP_DOWNLOADED = 'table_rally_cars_group_downloaded';
const TABLE_RALLY_CARS_DIMENSION_DOWNLOADED = 'table_rally_cars_dimension_downloaded';

export {
  TABLE_RALLY_CARS_PENDING,
  TABLE_RALLY_CARS_FAILED,
  TABLE_RALLY_CARS_DOWNLOADED,
  TABLE_RALLY_CARS_RESET,
  TABLE_RALLY_CARS_BRAND_DOWNLOADED,
  TABLE_RALLY_CARS_MODEL_DOWNLOADED,
  TABLE_RALLY_CARS_GROUP_DOWNLOADED,
  TABLE_RALLY_CARS_DIMENSION_DOWNLOADED
};
