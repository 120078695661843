import React, { useState } from 'react';
import withAuthentication from '../../hoc/withAuthentication';
import { ROLE_ADMIN } from '../../constants/roles';
import { useTranslation } from 'react-i18next';
import { setTitle } from '../../store/title/actions';
import { useDispatch, useSelector } from 'react-redux';
import { getCatalogsTags } from '../../store/catalogsTags/actions';
import CatalogsItemsForm from '../../components/Catalogs/Items/CatalogsItemsForm/CatalogsItemsForm';
import { useParams } from 'react-router';
import { getCatalogsItems } from '../../store/catalogsItems/actions';
import MTCLoader from '../../components/Layout/MTCLoader';
import Error404 from '../Error404';

export const CatalogsEditPage = () => {
  const { t } = useTranslation();

  const tags = useSelector(state => state.catalogsTags);
  const { title } = useSelector(state => state.title);
  const {
    content,
    pending,
    error,
  } = useSelector(state => state.catalogsItems);
  let { itemId } = useParams();
  const [item, setItem] = useState(null);
  const dispatch = useDispatch();

  if (title !== t('title:title.edit_catalogs_page')) {
    dispatch(setTitle(t('title:title.edit_catalogs_page')));
  }

  if (!content && !pending && !error) {
    dispatch(getCatalogsItems());
  }

  if (!tags.content && !tags.pending && !tags.error) {
    dispatch(getCatalogsTags());
  }

  if (content && item === null) {
    const result = content.find(it => it.id == itemId);
    if (result) {
      setItem(result);
    } else {
      setItem(false);
    }
  }
  if (pending) {
    return <MTCLoader />;
  } else if (!pending && content && !item) {
    return <Error404 />;
  } else {
    return <CatalogsItemsForm tags={tags.content || []} item={item} />;
  }
};

export default withAuthentication(ROLE_ADMIN)(CatalogsEditPage);
