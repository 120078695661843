import React from 'react';
import withAuthentication from '../../hoc/withAuthentication';
import { ROLE_ADMIN, ROLE_SUPPORT } from '../../constants/roles';
import { useDispatch, useSelector } from 'react-redux';
import { setTitle } from '../../store/title/actions';
import { useTranslation } from 'react-i18next';
import AuthorizedExportTabs
  from '../../components/Support/AuthorizedExportTabs/AuthorizedExportTabs';
import AddButton from '../../components/Layout/Button/AddButton';
import { AUTHORIZED_EXPORT_ADD } from '../../constants/routes';

const SupportPage = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { title } = useSelector(state => state.title);
  const role = localStorage.getItem('role');

  if (title !== t('title:title.support_page')) {
    dispatch(setTitle(t('title:title.support_page')));
  }
  return (
    <>
      <AuthorizedExportTabs />
      {role === ROLE_ADMIN && <AddButton path={AUTHORIZED_EXPORT_ADD} />}
    </>
  );
};
export default withAuthentication(ROLE_SUPPORT)(SupportPage);
