import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import Dropzone from 'react-dropzone';
import { useDispatch, useSelector } from 'react-redux';
import DateFnsUtils from '@date-io/date-fns';
import frLocale from 'date-fns/locale/fr';
import enLocale from 'date-fns/locale/en-GB';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CardMedia from '@material-ui/core/CardMedia';
import Image from '@material-ui/icons/Image';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputLabel from '@material-ui/core/InputLabel';
import { TextField, useTheme } from '@material-ui/core';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import Chip from '@material-ui/core/Chip';
import i18next from '../../../i18n';
import { data_languages, data_languages_no_fr } from '../../../constants/lang';
import { supportedPictureDropFiles } from '../../../constants/files';
import MTCTextField from '../../Form/MTCTextField';
import { dispatchInfo, handleError } from '../../../store/infos/actions';
import { postChallenge, putChallenge } from '../../../store/challenges/actions';
import MTCButton from '../../Layout/Button/MTCButton';
import { CHALLENGE } from '../../../constants/routes';
import MTCSwitch from '../../Form/MTCSwitch';
import { OBTAINING_TYPES, TYPES } from '../../../constants/challengeInformation';
import { getTracks } from '../../../store/tracks/actions';
import useStyles from './ChallengeForm.style';
import { INITIAL_STATE, INITIAL_ERROR_STATE, stateWithChallenge } from './ChallengeForm.const';

const countries = require('country-list')();

const localeMap = {
  en: enLocale,
  fr: frLocale,
};

const ChallengeForm = ({ challenge }) => {

  const { t } = useTranslation();
  const theme = useTheme();
  const classes = useStyles(theme);
  const dispatch = useDispatch();
  const history = useHistory();
  const { sending } = useSelector(state => state.challenges);
  const {
    content,
    pending,
    error,
  } = useSelector(state => state.tracks);
  const lang = i18next.languages[0].split('-')[0];
  const [state, setState] = useState(INITIAL_STATE);
  const [errors, setErrors] = useState(INITIAL_ERROR_STATE);
  const [circuits, setCircuits] = useState([]);
  const [picture, setPicture] = useState('');

  if (challenge !== null && state.id === null) {
    setState(stateWithChallenge(challenge));
    setPicture(challenge.picture || '');
  }

  if (state.obtaining_type === 3) {
    if (!content && !pending && !error) {
      dispatch(dispatchInfo(t('tracks_downloading')));
      dispatch(getTracks()).then(() => {
        dispatch(dispatchInfo(t('tracks_downloaded')));
      });
    }
    if (
      circuits
      && circuits.length === 0
      && content
      && content.length !== 0
      && !pending
      && !error
    ) {
      let newCircuitsList = content.map(track => {
        const name = track.translations[lang] !== undefined
          ? track.translations[lang].name
          : t('error.translation_not_found');
        return {
          'id': track.id,
          'name': name,
        };
      });
      newCircuitsList.sort((a, b) => a.name.localeCompare(b.name));
      setCircuits(newCircuitsList);
    }
  }

  const isEditing = challenge !== null;

  const handleStatePropertyChange = (property, value) => {
    let newState = { ...state };
    newState[property] = value;
    let newErrors = { ...errors };
    newErrors[property + '_error'] = null;
    if (property === 'type' && value === 0) {
      newState['start_date'] = null;
      newState['end_date'] = null;
      newErrors['start_date_error'] = null;
      newErrors['end_date_error'] = null;
    }
    if (property === 'obtaining_type') {
      if (value >= 1 && value <= 3) {
        newState.obtaining_country_codes = INITIAL_STATE.obtaining_country_codes;
      }
      if (value === 1 || value === 2) {
        newState.country_codes = INITIAL_STATE.country_codes;
      }
      if (value === 2 || value === 3) {
        newState.obtaining_environments = INITIAL_STATE.obtaining_environments;
      }
      if (value !== 3) {
        newState.obtaining_circuit_id = INITIAL_STATE.obtaining_circuit_id;
      }
      if (value === 0 || value === 3) {
        newState.unlock_free_dry_boundaries = INITIAL_STATE.unlock_free_dry_boundaries;
      }
      if (value !== 10) {
        newState.obtaining_percent = INITIAL_STATE.obtaining_percent;
      }
    }
    if (
      (
        property === 'start_date' || property === 'end_date'
      )
      && value !== null
    ) {
      value.setHours(0);
      value.setMinutes(0);
      value.setSeconds(0);
      value.setMilliseconds(0);
    }
    if (property === 'obtaining_country_codes' && state.retroactive === true && value.length > 0) {
      newState.retroactive = false;
    }
    setState(newState);
    setErrors(newErrors);
  };

  const handlePictureMediaDrop = (accepted, rejected) => {
    if (rejected.length) {
      if (rejected[0].type === '') {
        dispatch(handleError({
          message: t('error.format.image_unknown_type'),
        }));
      } else {
        dispatch(handleError({
          message: t('error.format.image') + rejected[0].type,
        }));
      }

    } else {
      setState({
        ...state,
        picture_to_upload: accepted[0],
      });
      const fileReader = new FileReader();
      fileReader.readAsDataURL(accepted[0]);
      fileReader.addEventListener("load", function () {
        setPicture(this.result);
      });
    }
  };

  const handleSubmit = event => {
    event.preventDefault();
    event.stopPropagation();

    if (!sending) {
      let errorCount = 0;
      let newErrorState = { ...INITIAL_ERROR_STATE };
      data_languages.forEach(dl => {
        const nameKey = `name_${dl}`;
        const name = state[nameKey];
        const descKey = `description_${dl}`;
        const desc = state[descKey];
        if (name === null || name.trim() === '') {
          errorCount++;
          newErrorState[nameKey + '_error'] = t('error.required_error');
        }
        if (desc === null || desc.trim() === '') {
          errorCount++;
          newErrorState[descKey + '_error'] = t('error.required_error');
        }
      });
      if (picture === null || picture === '') {
        errorCount++;
        newErrorState.picture_error = t('error.required_error');
      }
      if (parseInt(state.obtaining_needed_point) < 1) {
        errorCount++;
        newErrorState.obtaining_needed_point_error = t('error.min1');
      }
      if (state.obtaining_max_winner !== null && parseInt(state.obtaining_max_winner) < 1) {
        errorCount++;
        newErrorState.obtaining_max_winner_error = t('error.min1');
      }
      if (state.type === 1) {
        // for temporary challenge dates are required
        if (state.start_date === null) {
          errorCount++;
          newErrorState.start_date_error = t('error.required_error');
        }
        if (state.end_date === null) {
          errorCount++;
          newErrorState.end_date_error = t('error.required_error');
        }
        if (state.start_date !== null && state.end_date !== null) {
          if (state.start_date.getTime() === state.end_date.getTime()) {
            errorCount++;
            newErrorState.end_date_error = t('error.date_is_equals_error');
          } else if (state.start_date.getTime() > state.end_date.getTime()) {
            errorCount++;
            newErrorState.end_date_error = t('error.end_date_less_than_start_date_error');
          }
        }
      }
      if (
        state.obtaining_type === 10
        && (state.obtaining_percent < 0 || state.obtaining_percent > 100)
      ) {
        errorCount++;
        newErrorState.obtaining_percent_error = t('error.percent')
      }
      if (
        state.obtaining_type === 3
        && (
          state.obtaining_circuit_id === null || state.obtaining_circuit_id === ''
        )) {
        errorCount++;
        newErrorState.obtaining_circuit_id_error = t('error.required_error');
      }
      if (errorCount === 0) {
        const formData = new FormData();
        if (state.picture_to_upload) {
          formData.append('picture', state.picture_to_upload);
        }
        formData.append('json', JSON.stringify({
          type: state.type,
          retroactive: state.retroactive,
          state: state.state,
          obtaining_circuit_id: state.obtaining_circuit_id != ''
            ? state.obtaining_circuit_id
            : null,
          obtaining_type: state.obtaining_type,
          obtaining_percent: state.obtaining_percent,
          obtaining_needed_point: parseInt(state.obtaining_needed_point),
          obtaining_max_winner: state.obtaining_max_winner != null
            ? parseInt(state.obtaining_max_winner)
            : null,
          country_codes: state.country_codes.length > 0
            ? state.country_codes.join(',').toUpperCase()
            : null,
          obtaining_country_codes: state.obtaining_country_codes.length > 0
            ? state.obtaining_country_codes.join(',').toUpperCase()
            : null,
          obtaining_environments: state.obtaining_environments.length > 0
            ? state.obtaining_environments.join(',').toLowerCase()
            : null,
          start_date: state.start_date
            ? Math.trunc(state.start_date.getTime() / 1000)
            : null,
          end_date: state.end_date
            ? Math.trunc(state.end_date.getTime() / 1000)
            : null,
          display_rule: state.display_rule,
          unlock_free_dry_boundaries: state.unlock_free_dry_boundaries,
          translations: data_languages.reduce((o, key) => (
            {
              ...o,
              [`${key}`]: {
                name: state[`name_${key}`],
                description: state[`description_${key}`]
              },
            }
          ), {}),
        }));
        if (isEditing) {
          dispatch(putChallenge(challenge.id, formData))
            .then(data => {
              if (data && data.id) {
                history.push(CHALLENGE.replace(':challengeId', data.id));
              }
            });
        } else {
          dispatch(postChallenge(formData))
            .then(data => {
              if (data && data.id) {
                history.push(CHALLENGE.replace(':challengeId', data.id));
              }
            });
        }
      } else {
        setErrors(newErrorState);
      }
    }
  };

  const retroactiveDisabled = (
    state.obtaining_country_codes.length > 0
    || (state.obtaining_max_winner !== null && state.obtaining_max_winner !== '')
    || [2, 5, 6, 8].includes(state.obtaining_type)
  );
  const hideCountryCodes = [1, 2, 4, 6, 8].includes(state.obtaining_type);
  const hideObtainingCountryCodes = [
    1,
    2,
    3,
    4,
    6,
    7,
    8,
    10,
    11,
    12,
    13,
  ].includes(state.obtaining_type);
  const hideObtainingEnvironment = [2, 4, 6, 8, 12].includes(state.obtaining_type);
  const hideUnlockFreeDryBoundaries = [
    1,
    2,
    4,
    5,
    6,
    7,
    8,
    9,
    10,
    11,
    12,
    13,
  ].includes(state.obtaining_type);
  const disabledUnlockFreeDryBoundaries = state.obtaining_environments.length !== 1
    || state.obtaining_environments[0] !== 'circuit_free';
  let envList
  switch (state.obtaining_type) {
    case 3:
    case 5:
      envList = ['circuit_free', 'circuit_expert'];
      break;
    case 10:
    case 11:
      envList = ['circuit_expert', 'rally'];
      break;
    default:
      envList = ['circuit_free', 'circuit_expert', 'rally'];
  }
  const isForRallyEnv = envList.includes('rally')
    && (
      state.obtaining_environments.length === 0
      || state.obtaining_environments.includes('rally')
    );
  const hideObtainingCircuit = state.obtaining_type !== 3;

  return (
    <MuiPickersUtilsProvider
      utils={DateFnsUtils}
      locale={localeMap[i18next.languages[0].split('-')[0]]}
    >
      <form>
        <Grid
          container
          align="center"
          className={classes.grid}
          direction={'column'}
          justifyContent={'center'}
        >
          <Grid item xs={12}>
            <Paper className={classes.paper}>
              <Grid container>
                <Grid item xs={12}>
                  <Typography
                    align="center"
                    variant="h5"
                    component="h2"
                    gutterBottom>
                    {t('general_infos')}
                  </Typography>
                </Grid>

                <Grid item xs={12}>
                  <FormControl margin={'normal'}>
                    <InputLabel id="type_label" required={true} disabled={isEditing}>
                      {'Type'}
                    </InputLabel>
                    <Select
                      id={'type'}
                      onChange={event => handleStatePropertyChange(
                        'type',
                        event.target.value,
                      )}
                      value={state.type}
                      className={classes.input}
                      disabled={isEditing}
                    >
                      {TYPES.map(type => (
                        <MenuItem value={type} key={`challenge_types.${type}`}>
                          {t(`challenge_types.${type}`)}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={12}>
                  <FormControl margin={'normal'}>
                    <InputLabel id="obtaining_type_label" disabled={isEditing}>
                      {t('obtaining_type')}{' *'}
                    </InputLabel>
                    <Select
                      id={'obtaining_type'}
                      onChange={event => handleStatePropertyChange(
                        'obtaining_type',
                        event.target.value,
                      )}
                      value={state.obtaining_type}
                      className={classes.input}
                      disabled={isEditing}
                    >
                      {OBTAINING_TYPES
                        .filter(ot => ot !== 13)
                        .map(ot => {
                          return (
                            <MenuItem value={ot} key={`challenge_obtaining_types.${ot}`}>
                              {t(`challenge_obtaining_types.${ot}`)}
                            </MenuItem>
                          );
                      })}
                    </Select>
                  </FormControl>
                </Grid>

                <MTCTextField
                  gridMD={12}
                  type={'number'}
                  id={`obtaining_needed_point`}
                  key={`obtaining_needed_point`}
                  onChange={event => handleStatePropertyChange(
                    'obtaining_needed_point',
                    event.target.value,
                  )}
                  value={state.obtaining_needed_point}
                  required={true}
                  helperText={'Min: 1'}
                  errorMessage={errors.obtaining_needed_point_error}
                  inputProps={{
                    min: 1,
                    minLength: '1',
                    required: true,
                    step: 1,
                  }}
                  disabled={isEditing}
                />
                {state.obtaining_type === 10
                && <MTCTextField
                  gridMD={12}
                  type={'number'}
                  id={`obtaining_percent`}
                  key={`obtaining_percent`}
                  onChange={event => handleStatePropertyChange(
                    'obtaining_percent',
                    event.target.value,
                  )}
                  value={state.obtaining_percent}
                  required={true}
                  helperText={'Min 0%, max 100%'}
                  errorMessage={errors.obtaining_percent_error}
                  inputProps={{
                    min: 0,
                    max: 100,
                    minLength: '1',
                    required: true,
                    step: 1,
                  }}
                  disabled={isEditing}
                  textStartAdornment={"% "}
                />}
                {!hideObtainingCircuit && isEditing &&
                <MTCTextField
                  gridMD={12}
                  type={'text'}
                  id={`obtaining_circuit_id`}
                  key={`obtaining_circuit_id`}
                  value={circuits.length > 0 && circuits.find(option => option.id
                    === state.obtaining_circuit_id).name}
                  required={true}
                  onChange={() => {
                  }}
                  inputProps={{
                    required: true,
                  }}
                  disabled={true}
                />
                }

                {!hideObtainingCircuit && !isEditing && <Grid item xs={12}>
                  <Autocomplete
                    id={'obtaining_circuit_id'}
                    inputValue={circuits.find(option => option.id === state.obtaining_circuit.id)}
                    onInputChange={(event, value) => {
                      if (value) {
                        handleStatePropertyChange('obtaining_circuit', value);
                        handleStatePropertyChange(
                          'obtaining_circuit_id',
                          circuits.find(c => c.name == value)?.id,
                        );
                      }
                    }}
                    renderInput={params =>
                      <TextField
                        {...params}
                        margin={'normal'}
                        label={t('obtaining_circuit_id')}
                        error={errors.obtaining_circuit_id_error !== null}
                        helperText={errors.obtaining_circuit_id_error}
                      />
                    }
                    options={circuits || []}
                    getOptionLabel={option => option.name}
                    disabled={isEditing}
                    error={errors.obtaining_circuit_id_error}
                    className={classes.input}
                    loadingText={t('loading')}
                    noOptionsText={t('no_track')}
                  />
                </Grid>}


                {!hideObtainingEnvironment && <Grid item xs={12}>
                  <FormControl margin={'normal'}>
                    <InputLabel id="obtaining_environments_label" disabled={isEditing}>
                      {t('obtaining_environments')}
                    </InputLabel>
                    <Select
                      id={'obtaining_environments'}
                      onChange={event => handleStatePropertyChange(
                        'obtaining_environments',
                        event.target.value,
                      )}
                      value={state.obtaining_environments}
                      className={classes.input}
                      multiple={true}
                      disabled={isEditing}
                    >
                      {envList.map(env => (
                        <MenuItem value={env} key={env}>
                          {t(`challenge_environments.${env}`)}
                        </MenuItem>
                      ))}
                    </Select>
                    <FormHelperText disabled={isEditing}>
                      {t('challenge_tips.empty_for_all')}
                    </FormHelperText>
                  </FormControl>
                </Grid>}

                {!hideObtainingCountryCodes && <Grid item xs={12}>
                  <FormControl margin={'normal'}>
                    <InputLabel
                      id="obtaining_country_codes_label"
                      disabled={isEditing || isForRallyEnv}
                    >
                      {t('obtaining_country_codes')}
                    </InputLabel>
                    <Select
                      id={'obtaining_country_codes'}
                      onChange={event => handleStatePropertyChange(
                        'obtaining_country_codes',
                        event.target.value,
                      )}
                      value={state.obtaining_country_codes}
                      className={classes.input}
                      multiple={true}
                      renderValue={(selected) => (
                          selected.map((value) => (
                            <Chip key={value} label={value} />
                          ))
                      )}
                      disabled={isEditing || isForRallyEnv}
                    >
                      {countries.getData().sort().map(country => (
                        <MenuItem
                          key={'obtaining_country_codes_' + country.code}
                          value={country.code}
                        >
                          {country.code} - {country.name}
                        </MenuItem>
                      ))}
                    </Select>
                    <FormHelperText disabled={isEditing}>
                      {isForRallyEnv && <>
                        {t('challenge_tips.is_not_for_rally_env')}
                        <br />
                      </>}
                      {t('challenge_tips.obtaining_country_codes')}
                      <br />
                      {t('challenge_tips.obtaining_country_codes_2')}
                      <br />
                      {t('challenge_tips.empty_for_all')}
                    </FormHelperText>
                  </FormControl>
                </Grid>}

                {!hideCountryCodes && <Grid item xs={12}>
                  <FormControl margin={'normal'}>
                    <InputLabel id="country_codes_label" disabled={isEditing}>
                      {t('country_codes')}
                    </InputLabel>
                    <Select
                      id={'country_codes'}
                      onChange={event =>
                        handleStatePropertyChange('country_codes', event.target.value)
                      }
                      value={state.country_codes}
                      className={classes.input}
                      multiple={true}
                      renderValue={(selected) => (
                          selected.map((value) => (
                            <Chip key={value} label={value} />
                          ))
                      )}
                      disabled={isEditing}
                    >
                      {countries.getData().sort().map(country => (
                        <MenuItem key={'country_codes_' + country.code} value={country.code}>
                          {country.code} - {country.name}
                        </MenuItem>
                      ))}
                    </Select>
                    <FormHelperText disabled={isEditing}>
                      {t('challenge_tips.country_codes')}
                      <br />
                      {t('challenge_tips.country_codes_2')}
                      <br />
                      {t('challenge_tips.empty_for_all')}
                    </FormHelperText>
                  </FormControl>
                </Grid>}
                {!hideUnlockFreeDryBoundaries
                && <Grid item xs={12}>
                  <MTCSwitch
                    id={'unlock_free_dry_boundaries'}
                    value={state.unlock_free_dry_boundaries}
                    onChange={() => {
                      handleStatePropertyChange(
                        'unlock_free_dry_boundaries',
                        !state.unlock_free_dry_boundaries
                      );
                    }}
                    disabled={isEditing || disabledUnlockFreeDryBoundaries}
                    helperText={t('available_only_for_free')}
                  />
                </Grid>}
                <MTCTextField
                  gridMD={12}
                  type={'number'}
                  id={`obtaining_max_winner`}
                  key={`obtaining_max_winner`}
                  onChange={event => handleStatePropertyChange(
                    'obtaining_max_winner',
                    event.target.value,
                  )}
                  value={state.obtaining_max_winner}
                  required={false}
                  errorMessage={errors.obtaining_max_winner_error}
                  helperText={(
                    <>
                      {t('challenge_tips.obtaining_max_winner')}{' (Min: 1)'}
                      <br />
                      {t('challenge_tips.empty_for_no_limit')}
                    </>
                  )}
                  inputProps={{
                    min: 1,
                    minLength: '1',
                    required: false,
                    step: 1,
                  }}
                  disabled={isEditing}
                />
              </Grid>
            </Paper>
          </Grid>
          <Grid item xs={12}>
            <Paper className={classes.paper}>
              <Grid container>
                <Grid item xs={12}>
                  <Typography
                    align="center"
                    variant="h5"
                    component="h2"
                    gutterBottom>
                    {t('name')}
                  </Typography>
                </Grid>
                <MTCTextField
                  gridMD={12}
                  id={`name_fr`}
                  key={`name_fr`}
                  onChange={event => handleStatePropertyChange(`name_fr`, event.target.value)}
                  value={state[`name_fr`]}
                  required={true}
                  errorMessage={errors[`name_fr_error`]}
                  onBlur={event => setState({
                    ...state,
                    ...data_languages.reduce((o, key) => (
                      {
                        ...o,
                        [`name_${key}`]: state[`name_${key}`] || event.target.value,
                      }
                    ), {})
                  })}
                />

                {data_languages_no_fr.map(lg => (
                  <MTCTextField
                    gridMD={12}
                    id={`name_${lg}`}
                    key={`name_${lg}`}
                    onChange={event => handleStatePropertyChange(`name_${lg}`,
                      event.target.value,
                    )}
                    value={state[`name_${lg}`]}
                    required={true}
                    errorMessage={errors[`name_${lg}_error`]}
                  />
                ))}
              </Grid>
            </Paper>
          </Grid>

          <Grid item xs={12}>
            <Paper className={classes.paper}>
              <Grid container>
                <Grid item xs={12}>
                  <Typography
                    align="center"
                    variant="h5"
                    component="h2"
                    gutterBottom>
                    {t('description')}
                  </Typography>
                </Grid>
                <MTCTextField
                  gridMD={12}
                  id={`description_fr`}
                  key={`description_fr`}
                  onChange={event => handleStatePropertyChange(`description_fr`,
                    event.target.value,
                  )}
                  value={state[`description_fr`]}
                  required={true}
                  multiline={true}
                  errorMessage={errors[`description_fr_error`]}
                  onBlur={event => setState({
                    ...state,
                    ...data_languages.reduce((o, key) => (
                      {
                        ...o,
                        [`description_${key}`]: state[`description_${key}`] || event.target.value,
                      }
                    ), {})
                  })}
                />
                {data_languages_no_fr.map(lg => (
                  <MTCTextField
                    gridMD={12}
                    id={`description_${lg}`}
                    key={`description_${lg}`}
                    onChange={event => handleStatePropertyChange(`description_${lg}`,
                      event.target.value,
                    )}
                    value={state[`description_${lg}`]}
                    required={true}
                    multiline={true}
                    errorMessage={errors[`description_${lg}_error`]}
                  />
                ))}
              </Grid>
            </Paper>
          </Grid>

          {state.type === 0 && !challenge &&
          <Grid item xs={12}>
            <Paper className={classes.paper}>
              <Grid container>
                <Grid item xs={12}>
                  <Typography
                    align="center"
                    variant="h5"
                    component="h2"
                    gutterBottom>
                    {t('retroactive')}
                  </Typography>
                </Grid>
                {retroactiveDisabled &&
                <Grid item xs={12}>{t('retroactive_disabled')}</Grid>}
                <MTCSwitch
                  id={'retroactive'}
                  value={state.retroactive}
                  onChange={() => {
                    handleStatePropertyChange('retroactive', !state.retroactive);
                  }}
                  disabled={retroactiveDisabled}
                />
              </Grid>
            </Paper>
          </Grid>}
          {state.type === 1
          && <Grid item xs={12}>
            <Paper className={classes.paper}>
              <Grid container>
                <Grid item xs={12}>
                  <Typography
                    align="center"
                    variant="h5"
                    component="h2"
                    gutterBottom>
                    Date
                  </Typography>
                </Grid>

                <Grid item xs={12}>
                  <KeyboardDatePicker
                    margin="normal"
                    id="start_date"
                    label={t('start_date') + ' *'}
                    format="dd/MM/yyyy"
                    value={state.start_date}
                    onChange={date => handleStatePropertyChange(`start_date`, date)}
                    KeyboardButtonProps={{
                      'aria-label': 'change start date',
                    }}
                    className={classes.input}
                    helperText={errors.start_date_error}
                    error={errors.start_date_error !== null}
                    disabled={isEditing}
                  />
                </Grid>
                <Grid item xs={12}>
                  <KeyboardDatePicker
                    margin="normal"
                    id="end_date"
                    label={t('end_date') + ' *'}
                    format="dd/MM/yyyy"
                    value={state.end_date}
                    onChange={date => handleStatePropertyChange(`end_date`, date)}
                    KeyboardButtonProps={{
                      'aria-label': 'change end date',
                    }}
                    className={classes.input}
                    helperText={errors.end_date_error}
                    error={errors.end_date_error !== null}
                    disabled={isEditing}
                  />
                </Grid>
              </Grid>
            </Paper>
          </Grid>}

          <Grid item xs={12}>
            <Paper className={classes.paper}>
              <Grid container alignItems={'center'} direction={'column'}>
                <Grid item xs={12}>
                  <Typography
                    align="center"
                    variant="h5"
                    component="h2"
                    gutterBottom>
                    {t('display')}
                  </Typography>
                </Grid>

                <Grid item xs={12}>
                  <FormControl margin={'normal'}>
                    <InputLabel id="type_label" required={true}>
                      {t('display_rule')}
                    </InputLabel>
                    <Select
                      id={'display_rule'}
                      onChange={event => handleStatePropertyChange(
                        'display_rule',
                        event.target.value,
                      )}
                      value={state.display_rule}
                      className={classes.input}
                    >
                      <MenuItem value={0}>
                        {t('challenge_display_rule.0')}
                      </MenuItem>
                      <MenuItem value={1}>
                        {t('challenge_display_rule.1')}
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <FormControl
                    margin={'normal'}
                    required={true}
                    error={errors['picture_error'] !== null}
                  >
                    <InputLabel
                      id="type_label"
                      required={!isEditing}
                      className={classes.dropzoneLabel}
                    >
                      {t('challenge_picture')}
                    </InputLabel>
                    <Dropzone
                      className={classes.media}
                      accept={supportedPictureDropFiles}
                      onDrop={handlePictureMediaDrop}
                    >
                      {picture
                        ? <CardMedia
                          component="img"
                          image={picture}
                          title={t('picture')}
                          className={classes.cardMedia}
                        />
                        : <div>
                          <Typography variant="subtitle1">{t('image_drop')}</Typography>
                          <br />
                          <Image />
                        </div>
                      }
                    </Dropzone>
                  </FormControl>
                </Grid>
                {errors['picture_error']
                && <Grid item xs={12}>
                  <FormHelperText error={true}>{errors['picture_error']}</FormHelperText>
                </Grid>
                }
              </Grid>
            </Paper>
          </Grid>

          {challenge && <Grid item>
            <MTCSwitch
              id={'state'}
              value={state.state !== 2}
              onChange={event => {
                handleStatePropertyChange(
                  'state',
                  state.state === 2
                    ? (
                      challenge.previous_state || 0
                    )
                    : 2,
                );
              }}
              disableText={t('challenge_state.2')}
              enableText={
                t(`challenge_state.${
                  (
                    challenge.previous_state !== null && challenge.previous_state !== undefined
                  )
                    ? challenge.previous_state
                    : (
                      challenge.state !== 2
                        ? challenge.state
                        : 0
                    )
                }`)
              }
            />
          </Grid>}

          <Grid item>
            <MTCButton
              disabled={false}
              onClick={handleSubmit}
              type={'button'}
              sending={sending}
              text={isEditing
                ? t('edit')
                : t('create')}
            />
          </Grid>
        </Grid>
      </form>
    </MuiPickersUtilsProvider>
  );
};

ChallengeForm.defaultProps = {
  challenge: null,
};

export default ChallengeForm;
