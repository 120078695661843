import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { useTheme } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { INITIAL_ERROR_STATE, INITIAL_STATE } from './AuthorizedExportForm.const';
import useStyles from './AuthorizedExportForm.style';
import MTCButton from '../../Layout/Button/MTCButton';
import MTCTextField from '../../Form/MTCTextField';
import { useDispatch, useSelector } from 'react-redux';
import {
  postAuthorizedExport,
  putAuthorizedExport,
} from '../../../store/authorizedExports/actions';
import { useHistory } from 'react-router';
import { SUPPORT } from '../../../constants/routes';
import { stateWithAuthorizedExport } from './AuthorizedExportForm.func';

export const AuthorizedExportForm = ({ authorizedExport }) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const history = useHistory();
  const classes = useStyles(theme);
  const dispatch = useDispatch();
  const [state, setState] = useState(INITIAL_STATE);
  const [errors, setErrors] = useState(INITIAL_ERROR_STATE);
  const { sending } = useSelector(state => state.authorizedExports);

  const isEditing = authorizedExport !== undefined && authorizedExport !== null;

  if (authorizedExport !== undefined && authorizedExport !== null && state.id === null) {
    setState(stateWithAuthorizedExport(authorizedExport));
  }
  const handleSubmit = event => {
    event.preventDefault();
    event.stopPropagation();
    if (!sending) {
      let errorCount = 0;
      let newErrorState = { ...INITIAL_ERROR_STATE };

      if (
        (
          state.emails === null || state.emails === ''
        )
        && (
          state.receiver_names === null || state.receiver_names === ''
        )
      ) {
        newErrorState.emails_error = t('error.emails_or_receiver_names_required');
        errorCount++;
      }
      if (errorCount === 0) {
        const data = {
          emails: state.emails?.trim() === ''
            ? null
            : state.emails,
          'receiver_names': state.receiver_names?.trim() === ''
            ? null
            : state.receiver_names,
          status: state.status,
        };
        if (isEditing) {
          dispatch(putAuthorizedExport(authorizedExport.id, data))
            .then(data => {
              if (data && data.id) {
                history.push({
                  pathname: SUPPORT,
                  state: {
                    value: data.status === 'OPEN'
                      ? 0
                      : 1,
                  },
                });
              }
            });
        } else {
          dispatch(postAuthorizedExport(data))
            .then(data => {
              if (data && data.id) {
                history.push({
                  pathname: SUPPORT,
                  state: {
                    value: data.status === 'OPEN'
                      ? 0
                      : 1,
                  },
                });
              }
            });
        }
      } else {
        setErrors(newErrorState);
      }
    }
  };

  const handleStatePropertyChange = (property, value) => {
    let newState = { ...state };
    let newErrors = { ...errors };
    if (
      (property === 'emails' || property === 'receiver_names') &&
      errors.emails_error === t('error.emails_or_receiver_names_required')
    ) {
      newErrors['emails_error'] = null;
    }
    newState[property] = value;
    newErrors[property + '_error'] = null;
    setState(newState);
    setErrors(newErrors);
  };

  return (
    <form onSubmit={handleSubmit}>

      <Grid
        container
        align="center"
        className={classes.grid}
        direction={'column'}
        justifyContent={'center'}
      >
        <Grid item xs={12}>
          <Paper className={classes.paper}>
            <Grid container>
              <Grid item xs={12}>
                <Typography
                  align="center"
                  variant="h5"
                  component="h2"
                  gutterBottom>
                  {t('general_infos')}
                </Typography>
              </Grid>
              <MTCTextField
                gridMD={12}
                id={`emails`}
                key={`emails`}
                onChange={event => handleStatePropertyChange(`emails`, event.target.value)}
                value={state[`emails`]}
                errorMessage={errors[`emails_error`]}
                helperText={t('support:coma_separated_values_helper')}
              />
              <MTCTextField
                gridMD={12}
                id={`receiver_names`}
                key={`receiver_names`}
                onChange={event => handleStatePropertyChange(`receiver_names`, event.target.value)}
                value={state[`receiver_names`]}
                errorMessage={errors[`receiver_names_error`]}
                helperText={t('support:coma_separated_values_helper')}
              />
              <Grid item xs={12}>
                <FormControl>
                  <InputLabel
                    id="status-label"
                    disabled={isEditing}
                  >
                    {t('status')}
                  </InputLabel>
                  <Select
                    className={classes.select}
                    labelId="status-label"
                    id="status"
                    multiple={false}
                    value={state.status}
                    onChange={event => handleStatePropertyChange('status', event.target.value)}
                  >
                    <MenuItem value={'OPEN'}>
                      {t('support:open')}
                    </MenuItem>
                    <MenuItem value={'CLOSE'}>
                      {t('support:close')}
                    </MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        <Grid item>
          <MTCButton
            disabled={false}
            type={'submit'}
            sending={sending}
            text={isEditing
              ? t('edit')
              : t('create')}
          />
        </Grid>
      </Grid>
    </form>
  );
};

export default AuthorizedExportForm;
