import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router';
import axios from 'axios';
import { useTranslation } from 'react-i18next';

const dayjs = require('dayjs');
import * as localizedFormat from 'dayjs/plugin/localizedFormat';
import michelinLogo from '../../resources/pictures/circuitEol/eol_michelin_logo.svg';
import mtcLogo from '../../resources/pictures/circuitEol/eol_mtc_logo.svg';
import yellowCircuitIcon from '../../resources/pictures/circuitEol/yellow_circuit_icon.svg';
import yellowHashIcon from '../../resources/pictures/circuitEol/yellow_hash_icon.svg';
import trophy from '../../resources/pictures/circuitEol/trophy.svg';
import tvRetroIcon from '../../resources/pictures/circuitEol/tv_retro_icon.svg';
import carIcon from '../../resources/pictures/circuitEol/car_icon.svg';
import arrowUpwardIcon from '../../resources/pictures/circuitEol/arrow_upward_icon.svg';
import goldMedal from '../../resources/pictures/circuitEol/gold_medal.svg';
import silverMedal from '../../resources/pictures/circuitEol/silver_medal.svg';
import bronzeMedal from '../../resources/pictures/circuitEol/bronze_medal.svg';
import trophyIcon from '../../resources/pictures/circuitEol/trophy_icon.svg';
import hashOutlinedIcon from '../../resources/pictures/circuitEol/hash_outlined_icon.svg';
import flagOutlinedIcon from '../../resources/pictures/circuitEol/flag_outlined_icon.svg';
import circuitOutlinedIcon from '../../resources/pictures/circuitEol/circuit_outlined_icon.svg';
import tires from '../../resources/pictures/circuitEol/tires.svg';
import bibPlaceholder from '../../resources/pictures/circuitEol/bib_placeholder.svg';
import './CircuitEolPage.css';
import MTCLoader from '../../components/Layout/MTCLoader';
import { API_ROOT_USER } from '../../constants/api';
import errorFace from '../../resources/pictures/error-face.svg';
import i18next from '../../i18n';
import { kilometersToMiles } from '../../utils/UnitUtils';

dayjs.extend(localizedFormat);
const ExperienceCard = (props) => {
  const { t } = useTranslation(['circuitEol']);
  return (
    <div className="experience-card">
      <div>
        <p className="emphasis">
          {props.number}
        </p>
        <p className="label1">
          {props.type}
        </p>
      </div>
      <div>
        {props.firstRecord
          ? <p>
            {t('distanceOnCircuit', {
              distance: props.firstRecord,
              unit: props.type.toLowerCase(),
              circuit: props.firstRecordCircuit,
            })}
          </p>
          : null}
        {props.secondRecord
          ? <p>
            {t('distanceOnCircuit', {
              distance: props.secondRecord,
              unit: props.type.toLowerCase(),
              circuit: props.secondRecordCircuit,
            })}
          </p>
          : null}
      </div>
      <img
        src={props.icon}
        alt=""
      />
    </div>
  );
};

const CarValue = (props) => (
  <div className="car-value">
    <div>
      <img
        src={props.icon}
        alt=""
        height={38}
      />
      <p className="label1">
        {props.label}
      </p>
    </div>
    <p className="emphasis">
      {props.value}
    </p>
  </div>
);

const Dimension = (props) => (
  <div className="dimension">
    <p>{props.dimension}</p>
    <img
      src={tires}
      alt=""
      width={177}
      height={49.5}
    />
    <p className="label2">{props.description}</p>
    <div className="pressure-color-line">
      <div className="red" />
      <div className="white" />
      <div className="orange" />
      <div className="white" />
      <div className="green" />
      <div className="white" />
      <div className="orange" />
      <div className="white" />
      <div className="red" />
    </div>
    <div className="pressures-line">
      <p>{props.pro}</p>
      <p>{props.pog}</p>
      <p>{props.pgp}</p>
      <p>{props.por}</p>
    </div>
  </div>
);

const BestChrono = (props) => {
  const { t } = useTranslation(['circuitEol']);
  const locale = i18next.languages[0];
  let medalText = '';
  switch (props.medal) {
    case 1:
      medalText = t('firstPosition');
      break;
    case 2:
      medalText = t('secondPosition');
      break;
    case 3:
      medalText = t('thirdPosition');
      break;
  }
  return (
    <div className="best-chrono">
      <div className="circuit">
        <img
          src={props.picture ? props.picture : bibPlaceholder}
          alt=""
        />
        <div>
          <h3>{props.circuitName}</h3>
          <p>{t('date', { date: dayjs.unix(props.date).locale(locale).format('L') })}</p>
        </div>
      </div>
      <div className="chrono">
        <p className="label2">
          {t('yourBestChrono')}
        </p>
        <p className="bold">
          {dayjs(props.chrono).format('mm:ss.SSS')}
        </p>
        <p className="label2">
          {t('communityBestChrono')}
        </p>
        <p className="bold">
          {dayjs(props.bestChrono).format('mm:ss.SSS')}
        </p>
      </div>
      <div className="medal">
        {props.medal === 1
          && <img
            height={53}
            src={goldMedal}
            alt="Gold medal"
          />
        }
        {props.medal === 2
          && <img
            height={53}
            src={silverMedal}
            alt="Silver medal"
          />
        }
        {props.medal === 3
          && <img
            height={53}
            src={bronzeMedal}
            alt="Bronze medal"
          />
        }
        <div>
          <p
            className="bold"
            dangerouslySetInnerHTML={{ __html: medalText }}
          />
          <p className="label2">{t('fromMtcCommunity')}</p>
        </div>
      </div>
    </div>
  );
};

const CircuitEolPage = () => {
  const { t } = useTranslation(['circuitEol']);
  const { referralCode } = useParams();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [eolUser, setEolUser] = useState(null);
  const [distanceUnit, setDistanceUnit] = useState(0);
  const locale = i18next.languages[0];

  const downloadCsv = (event) => {
    event.stopPropagation();
    event.preventDefault();
    const url = `${API_ROOT_USER}/circuit-eol/export?format=excel&referral-code=${referralCode}`;
    window.open(url, '_blank').focus();
  };

  useEffect(() => {
    axios.get(`${API_ROOT_USER}/circuit-eol/retrospective?referral-code=${referralCode}`)
      .then((response) => {
        setLoading(false);
        setEolUser(response.data);
        setDistanceUnit(response.data.distance_unit);
      })
      .catch((error) => {
        setLoading(false);
        setError('Une erreur est survenu lors de la récupération de votre rétrospective');
      });
  }, []);

  return (
    <div className="retrospective">
      <header>
        <div>
          <img
            src={michelinLogo}
            height="48"
            width="254"
            alt="Michelin"
            title="Michelin"
          />
          <img
            src={mtcLogo}
            height="48"
            width="264"
            alt="Michelin Track Connect"
            title="Michelin Track Connect"
          />
        </div>
      </header>
      <main>
        {loading === true && <MTCLoader secondary />}
        {loading === false && error !== null && <>
          <section>
            <div className="content">
              <img
                src={errorFace}
                alt=""
                width="168"
                height="168"
              />
              <h1>{error}</h1>
            </div>
          </section>
        </>}
        {loading === false && eolUser !== null && <>
          <section>
            <div className="content">
              <h1
                dangerouslySetInnerHTML={{
                  __html: t('title',
                    { username: eolUser.username },
                  ),
                }}
              />
              <p className="subtitle">{
                t(
                  'subtitle',
                  { date: dayjs.unix(eolUser.user_sign_in_date).locale(locale).format('L') },
                )
              }</p>
              <img
                src={tvRetroIcon}
                alt=""
                className="icon"
              />
              <h2>{t('experienceTitle')}</h2>
              <div className="experience-card-list">
                <ExperienceCard
                  number={eolUser.total_circuit_run}
                  type={t('experienceCardRuns')}
                  firstRecord={eolUser.first_run_count_record_run_count}
                  firstRecordCircuit={eolUser.first_run_count_record_circuit_name}
                  secondRecord={eolUser.second_run_count_record_run_count}
                  secondRecordCircuit={eolUser.second_run_count_record_circuit_name}
                  icon={yellowHashIcon}
                />
                <ExperienceCard
                  number={distanceUnit === 1
                    ? Math.round(kilometersToMiles(eolUser.total_kilometers))
                    : eolUser.total_kilometers
                  }
                  type={t(distanceUnit === 0 ? 'distance.km' : 'distance.mile')}
                  firstRecord={distanceUnit === 1
                    ? Math.round(kilometersToMiles(eolUser.first_total_km_record_total_km))
                    : eolUser.first_total_km_record_total_km
                  }
                  firstRecordCircuit={eolUser.first_total_km_record_circuit_name}
                  secondRecord={distanceUnit === 1
                    ? Math.round(kilometersToMiles(eolUser.second_total_km_record_total_km))
                    : eolUser.second_total_km_record_total_km
                  }
                  secondRecordCircuit={eolUser.second_total_km_record_circuit_name}
                  icon={yellowCircuitIcon}
                />
              </div>
              {eolUser.total_challenges_won
                && eolUser.total_challenges_won > 0
                && <div className="trophy">
                  <img
                    src={trophy}
                    alt=""
                  />
                  <p className="emphasis">
                    {eolUser.total_challenges_won}
                  </p>
                  <p className="label1">
                    {t('trophies')}
                  </p>
                </div>
              }
            </div>
          </section>
          <hr />
          <section>
            <div className="content">
              {(
                eolUser.favorite_car_brand
              )
                ? <>
                  <img
                    src={carIcon}
                    alt=""
                    className="icon"
                  />
                  <h2>{t('favoriteCarTitle')}</h2>
                  <div className="dark-card">
                    <div>
                      <h3>
                        {`${eolUser.favorite_car_brand} ${eolUser.favorite_car_range}`}
                      </h3>
                      <p className="label2">
                        {t('carType')}
                      </p>
                      <p className="bold">
                        {eolUser.favorite_car_range_model}
                      </p>
                      <p className="label2">
                        {t('carPower')}
                      </p>
                      <p className="bold">
                        {eolUser.favorite_car_engine}
                      </p>
                    </div>
                    <div className="car-value-list">
                      {(
                        eolUser.favorite_car_total_run_count
                        && eolUser.favorite_car_total_run_count > 0
                      )
                        ? <CarValue
                          label={t('experienceCardRuns')}
                          value={eolUser.favorite_car_total_run_count}
                          icon={hashOutlinedIcon}
                        />
                        : null
                      }
                      {(
                        eolUser.favorite_car_total_kilometers
                        && eolUser.favorite_car_total_kilometers > 0
                      )
                        ? <CarValue
                          label={t(distanceUnit === 0 ? 'distance.km' : 'distance.mile')}
                          value={distanceUnit === 1
                            ? Math.round(kilometersToMiles(eolUser.favorite_car_total_kilometers))
                            : eolUser.favorite_car_total_kilometers
                          }
                          icon={circuitOutlinedIcon}
                        />
                        : null
                      }
                      {(
                        eolUser.favorite_car_total_kilometers
                        && eolUser.favorite_car_total_kilometers > 0
                      )
                        ? <CarValue
                          label="Records"
                          value={eolUser.favorite_car_total_kilometers}
                          icon={flagOutlinedIcon}
                        />
                        : null
                      }
                    </div>
                    <div className="dimension-list">
                      {eolUser.favorite_car_front_dimension
                        ? <Dimension
                          dimension={eolUser.favorite_car_front_dimension}
                          description={t('pressureRecoFront')}
                          pro={eolUser.favorite_car_bf_red_orange}
                          pog={eolUser.favorite_car_bf_orange_green}
                          pgp={eolUser.favorite_car_bf_green_orange}
                          por={eolUser.favorite_car_bf_orange_red}
                        />
                        : null
                      }
                      {eolUser.favorite_car_rear_dimension
                        ? <Dimension
                          dimension={eolUser.favorite_car_rear_dimension}
                          description={t('pressureRecoRear')}
                          pro={eolUser.favorite_car_br_red_orange}
                          pog={eolUser.favorite_car_br_orange_green}
                          pgp={eolUser.favorite_car_br_green_orange}
                          por={eolUser.favorite_car_br_orange_red}
                        />
                        : null
                      }
                    </div>
                  </div>
                </>
                : null
              }
              {eolUser.eol_best_runs.length > 0 && <>
                <h2>{t('performanceTitle')}</h2>
                <div className="medal-list">
                  <img
                    src={goldMedal}
                    alt=""
                  />
                  <img
                    src={silverMedal}
                    alt=""
                  />
                  <img
                    src={bronzeMedal}
                    alt=""
                  />
                </div>
                <div className="dark-card column">
                  {eolUser.eol_best_runs.map((bc) => (
                    <BestChrono
                      key={bc.circuit_id}
                      medal={bc.medal}
                      circuitName={bc.circuit_name}
                      date={bc.date}
                      circuitPicture={bc.circuit_picture}
                      chrono={bc.chrono}
                      bestChrono={bc.best_chrono}
                    />
                  ))}
                </div>
              </>}
              <h2>{t('downloadLinkTitle')}</h2>
              <img
                src={arrowUpwardIcon}
                alt=""
                className="icon after"
              />
              <button
                type="button"
                onClick={downloadCsv}
              >
                <div>
                  <img
                    src={trophyIcon}
                    alt=""
                  />
                </div>
              </button>
            </div>
          </section>
        </>}
      </main>
    </div>
  );
};

export default CircuitEolPage;
