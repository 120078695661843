import makeStyles from '@material-ui/core/styles/makeStyles';
import { inputStyle } from '../../../constants/styles/input.style';

export const useStyles = makeStyles((theme) => {
  return {
    form: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    },
    input: {
      ...inputStyle(theme),
      color: theme.palette.textColor,
      backgroundColor: theme.palette.alternateTextColor,
    },
    paper: {
      padding: '20px 28px',
      margin: '20px 15px',
      width: 'calc(100% - 30px)',
      borderRadius: '4px',
      display: 'flex',
      flex: 'auto',
      flexDirection: 'column',
      alignItems: 'center',
      textAlign: 'center',
    },
    paperSubtitle: {
      fontWeight: '400',
      marginTop: '1rem',
    },
    emptyOption: {
      padding: 0,
      height: 0,
    },
  };
});
