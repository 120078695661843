import React from 'react';
import { useTranslation } from 'react-i18next';

const withTranslation = (Component) => {
  function WithTranslation(props) {
    const { t } = useTranslation();
    return <Component {...props} t={t} />;
  }
  return WithTranslation;
};

export default withTranslation;
