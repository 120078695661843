import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { setBack, setTitle } from '../../store/title/actions';
import withAuthentication from '../../hoc/withAuthentication';
import { ROLE_ADMIN } from '../../constants/roles';
import InAppNotificationForm from '../../components/InAppNotifications/Form/InAppNotificationForm';
import { IN_APP_NOTIFICATION, IN_APP_NOTIFICATIONS } from '../../constants/routes';

export const AddInAppNotificationPage = () => {
  const { t } = useTranslation();
  const { title, back } = useSelector(state => state.title);
  const dispatch = useDispatch();
  const history = useHistory();
  const notification = (
    history.location.state
    && history.location.state.notification
  ) ? history.location.state.notification
    : null;
  const fromDetails = (
    history.location.state
    && history.location.state.fromDetails
  ) ? history.location.state.fromDetails
    : false;

  if (notification === null && title !== t('title:title.in_app_notification_add_page')) {
    dispatch(setTitle(t('title:title.in_app_notification_add_page')));
  }

  if (notification !== null && title !== t('title:title.in_app_notification_duplicate_page')) {
    dispatch(setTitle(t('title:title.in_app_notification_duplicate_page')));
    if (
      fromDetails
      && back !== IN_APP_NOTIFICATION.replace(':notificationId', notification.id)
    ) {
      dispatch(setBack(IN_APP_NOTIFICATION.replace(':notificationId', notification.id)))
    } else if (back !== IN_APP_NOTIFICATIONS) {
      dispatch(setBack(IN_APP_NOTIFICATIONS))
    }
  }

  if (history.location.state && history.location.state.notification) {
    return <InAppNotificationForm
      notification={history.location.state.notification}
      duplicate={true}
    />;
  } else {
    return <InAppNotificationForm />;
  }
};

export default withAuthentication(ROLE_ADMIN)(AddInAppNotificationPage);
