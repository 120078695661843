import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ROLE_ADMIN } from '../../../constants/roles';
import withAuthentication from '../../../hoc/withAuthentication';
import { useTranslation } from 'react-i18next';
import { setTitle } from '../../../store/title/actions';
import AddCarForm from '../../../components/Cars/Form/AddCarForm';

export const AddCarPage = () => {
  const { title } = useSelector(state => state.title);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  if (title !== t('title:title.add_car_page')) {
    dispatch(setTitle(t('title:title.add_car_page')));
  }

  return <AddCarForm isDuplicate={false} />;
}

export default withAuthentication(ROLE_ADMIN)(AddCarPage);
