import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { columns, defaultOrderBy } from './FaqArticlesTable.const';
import FaqArticlesRow from '../FaqArticlesRow/FaqArticlesRow';
import MTCLoader from '../../../Layout/MTCLoader';
import MTCTable from '../../../Layout/Table/MTCTable';
import { getFaqArticles } from '../../../../store/faqArticles/actions';

export const FaqArticlesTable = () => {

  const {
    pending,
    content,
    error,
  } = useSelector(state => state.faqArticles);


  const { t } = useTranslation();
  const dispatch = useDispatch();

  if (!content && !pending && !error) {
    dispatch(getFaqArticles());
  }

  if (pending) {
    return <MTCLoader />;
  } else {
    return <MTCTable
      content={content}
      defaultOrderBy={defaultOrderBy}
      rowComponent={FaqArticlesRow}
      columns={columns}
      paginationLabel={t('faq_articles_per_page')}
      emptyDataLabel={t('no_faq_article')}
    />
  }
}

export default FaqArticlesTable;
