import React from 'react';
import Fab from '@material-ui/core/Fab';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import useTheme from '@material-ui/core/styles/useTheme';
import { useStyles } from './FaqElementExplorerButton.style';

const FaqElementExplorerButton = ({
                                    title,
                                    icon,
                                    action,
                                    disabled,
                                    secondary,
                                    alert,
                                  }) => {
  const theme = useTheme();
  const classes = useStyles(theme);

  if (secondary) {
    return (
      <IconButton
        size="small"
        onClick={action}
        className={classes.iconButton + (
          alert
            ? ' ' + classes.alert
            : ''
        ) + (
          disabled
            ? ' ' + classes.disabled
            : ''
        )}
        color="primary"
        component="span"
        disabled={disabled}
      >
        {icon}
      </IconButton>
    );
  }

  return <Tooltip title={title}>
    <Fab
      onClick={action}
      size="small"
      className={classes.fab + (
        alert
          ? ' ' + classes.alert
          : ''
      )}
      disabled={disabled}
    >
      {icon}
    </Fab>
  </Tooltip>;
};
export default FaqElementExplorerButton;
