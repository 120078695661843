import React from 'react';
import PropTypes from 'prop-types';
import Timestamp from 'react-timestamp';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import useTheme from '@material-ui/core/styles/useTheme';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import { RALLY_CAR_REQUEST_EDIT } from '../../../constants/routes';
import { REQUEST_RALLY_CAR, REQUEST_STATE } from '../../../store/requests/types';
import Typography from '@material-ui/core/Typography';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { useStyles } from './RequestRallyCarRow.style';

const RequestRallyCarRow = ({ value }) => {

  const theme = useTheme();
  const classes = useStyles(theme);
  const { t } = useTranslation();
  const history = useHistory();

  const handleEdit = event => {
    event.stopPropagation();
    history.push(
      RALLY_CAR_REQUEST_EDIT.replace(':requestId', value.id),
      { requestType: REQUEST_RALLY_CAR },
    );
  };

  return (
    <TableRow
      className={classes.row}
      key={value.id}
      hover
      onClick={handleEdit}
      tabIndex={-1}
    >
      <TableCell>
        <Typography>
          <Timestamp
            format='full'
            twentyFourHour
            time={value.created_at}
          />
        </Typography>
      </TableCell>
      <TableCell>
        <Typography>
          {value.brand + ' / ' + value.model + ' / ' + value.group}
        </Typography>
      </TableCell>
      {/* TODO maybe add default value for firstname and lastname */}
      <TableCell>
        <Typography>
          {value.firstname}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography>
          {value.lastname}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography>
          {t(REQUEST_STATE.properties[value.request_state].name)}
        </Typography>
      </TableCell>
      <TableCell align={'right'}>
        <Tooltip title={t('edit')}>
          <IconButton
            aria-label="update"
            onClick={handleEdit}
            color="primary"
            className={classes.button}
          >
            <EditIcon />
          </IconButton>
        </Tooltip>
      </TableCell>
    </TableRow>
  );
};

RequestRallyCarRow.propTypes = {
  value: PropTypes.object.isRequired,
};

export default RequestRallyCarRow;
