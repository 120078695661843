import {
  FORM_CAR_FAILED,
  FORM_CAR_SENDING,
  FORM_CARS_BOUNDARIES_DOWNLOADED,
  FORM_CARS_BOUNDARIES_PENDING,
  FORM_CARS_BRANDS_DOWNLOADED,
  FORM_CARS_BRANDS_PENDING,
  FORM_CARS_DIMENSION_DOWNLOADED,
  FORM_CARS_DIMENSION_PENDING,
  FORM_CARS_DIMENSIONS_DOWNLOADED,
  FORM_CARS_DIMENSIONS_PENDING,
  FORM_CARS_ENGINES_DOWNLOADED,
  FORM_CARS_ENGINES_PENDING,
  FORM_CARS_MODELS_DOWNLOADED,
  FORM_CARS_MODELS_PENDING,
  FORM_CARS_RANGES_DOWNLOADED,
  FORM_CARS_RANGES_PENDING,
  FORM_CAR_RESET,
  FORM_CAR_UPLOADED
} from './types';
import ApiService from '../../services/ApiService';
import { handleError } from '../infos/actions';

export const formCarSendingAction = () => (
  { type: FORM_CAR_SENDING }
);
export const formCarUploadedAction = () => (
  { type: FORM_CAR_UPLOADED }
);
export const formCarsBrandsPendingAction = () => (
  { type: FORM_CARS_BRANDS_PENDING }
);
export const formCarsRangesPendingAction = () => (
  { type: FORM_CARS_RANGES_PENDING }
);
export const formCarsModelsPendingAction = () => (
  { type: FORM_CARS_MODELS_PENDING }
);
export const formCarsEnginesPendingAction = () => (
  { type: FORM_CARS_ENGINES_PENDING }
);
export const formCarsDimensionsPendingAction = () => (
  { type: FORM_CARS_DIMENSIONS_PENDING }
);
export const formCarsBoundariesPendingAction = () => (
  { type: FORM_CARS_BOUNDARIES_PENDING }
);
export const formCarsBrandsDownloadedAction = (data) => (
  {
    type: FORM_CARS_BRANDS_DOWNLOADED,
    payload: { data },
  }
);
export const formCarsRangesDownloadedAction = (data, id) => (
  {
    type: FORM_CARS_RANGES_DOWNLOADED,
    payload: {
      data,
      id,
    },
  }
);
export const formCarsModelsDownloadedAction = (data, id) => (
  {
    type: FORM_CARS_MODELS_DOWNLOADED,
    payload: {
      data,
      id,
    },
  }
);
export const formCarsEnginesDownloadedAction = (data, id) => (
  {
    type: FORM_CARS_ENGINES_DOWNLOADED,
    payload: {
      data,
      id,
    },
  }
);
export const formCarsDimensionsDownloadedAction = (data, id) => (
  {
    type: FORM_CARS_DIMENSIONS_DOWNLOADED,
    payload: {
      data,
      id,
    },
  }
);
export const formCarsBoundariesDownloadedAction = (data, id) => (
  {
    type: FORM_CARS_BOUNDARIES_DOWNLOADED,
    payload: {
      data,
      id,
    },
  }
);

export const formCarDimensionPendingAction = () => (
  { type: FORM_CARS_DIMENSION_PENDING }
);

export const formCarDimensionDownloadedAction = (data) => (
  {
    type: FORM_CARS_DIMENSION_DOWNLOADED,
    payload: data,
  }
);
export const formCarFailedAction = (error) => (
  {
    type: FORM_CAR_FAILED,
    payload: error,
  }
);
export const formCarsResetAction = () => (
  {type: FORM_CAR_RESET}
);

export function formReset() {
  return (dispatch) => {
    dispatch(formCarsResetAction());
  }
}


export function getBrandsCars() {
  return (dispatch) => {
    dispatch(formCarsBrandsPendingAction());
    return ApiService
      .getCarsBrands()
      .then((data) => {
        dispatch(formCarsBrandsDownloadedAction(data));
      })
      .catch((error) => {
        dispatch(formCarFailedAction(error));
        dispatch(handleError(error));
      });
  };
}

export function getRangesCars(brandId) {
  return (dispatch) => {
    dispatch(formCarsRangesPendingAction());
    return ApiService
      .getCarsRanges(brandId)
      .then((data) => {
        dispatch(formCarsRangesDownloadedAction(data, brandId));
      })
      .catch((error) => {
        dispatch(formCarFailedAction(error));
        dispatch(handleError(error));
      });
  };
}

export function getModelsCars(brandId, rangeId) {
  return (dispatch) => {
    dispatch(formCarsModelsPendingAction());
    return ApiService
      .getCarsModels(brandId, rangeId)
      .then((data) => {
        dispatch(formCarsModelsDownloadedAction(data, rangeId));
      })
      .catch((error) => {
        dispatch(formCarFailedAction(error));
        dispatch(handleError(error));
      });
  };
}

export function getEnginesCars(brandId, rangeId, modelId) {
  return (dispatch) => {
    dispatch(formCarsEnginesPendingAction());
    return ApiService
      .getCarsEngines(brandId, rangeId, modelId)
      .then((data) => {
        dispatch(formCarsEnginesDownloadedAction(data, modelId));
      })
      .catch((error) => {
        dispatch(formCarFailedAction(error));
        dispatch(handleError(error));
      });
  };
}

export function getDimensionsCars(brandId, rangeId, modelId, engineId) {
  return (dispatch) => {
    dispatch(formCarsDimensionsPendingAction());
    return ApiService
      .getCarsDimensions(brandId, rangeId, modelId, engineId)
      .then((data) => {
        dispatch(formCarsDimensionsDownloadedAction(data, engineId));
      })
      .catch((error) => {
        dispatch(formCarFailedAction(error));
        dispatch(handleError(error));
      });
  };
}

export function getBoundariesCars(brandId, rangeId, modelId, engineId, dimensionId) {
  return (dispatch) => {
    dispatch(formCarsBoundariesPendingAction());
    return ApiService
      .getCarsBoundaries(brandId, rangeId, modelId, engineId, dimensionId)
      .then((data) => {
        dispatch(formCarsBoundariesDownloadedAction(data, dimensionId));
      })
      .catch((error) => {
        dispatch(formCarFailedAction(error));
        dispatch(handleError(error));
      });
  };
}

export function getDimension(dimensionId) {
  return (dispatch) => {
    dispatch(formCarDimensionPendingAction());
    return ApiService
      .getCarsDimension(dimensionId)
      .then((data) => {
        dispatch(formCarDimensionDownloadedAction(data));
        return data;
      })
      .catch((error) => {
        dispatch(formCarFailedAction(error));
        dispatch(handleError(error));
      });
  };
}

export function postCar(data) {
  return (dispatch) => {
    dispatch(formCarSendingAction());
    return ApiService.postCar(data)
      .then((data) => {
        dispatch(formCarUploadedAction());
        return data;
      })
      .catch((error) => {
        dispatch(formCarFailedAction(error));
        dispatch(handleError(error));
      });
  };
}
export function putCar(data) {
  return (dispatch) => {
    dispatch(formCarSendingAction());
    return ApiService.putCar(data)
      .then((data) => {
        dispatch(formCarUploadedAction());
        return data;
      })
      .catch((error) => {
        dispatch(formCarFailedAction(error));
        dispatch(handleError(error));
      });
  };
}