import React from 'react';
import TreeView from '@material-ui/lab/TreeView';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import useTheme from '@material-ui/core/styles/useTheme';
import { useStyles } from './FaqElementsExplorerTree.style';
import FaqElementsExplorerTreeItem from './FaqElementsExplorerTreeItem';
import {
  ARTICLE,
  DIRECTORY,
  EXTERNAL_LINK,
  getFaqElementTypeColor,
  getFaqElementTypeIcon,
  ROOT,
} from '../FaqElementType.const';
import i18next from '../../../../i18n';

const renderTree = (
  current,
  nodes,
  lang,
  changeCurrent,
  setOpenExternalLinkDialog,
  forDialog,
  hideId
) => {
  if (hideId && current.id === hideId) {
    return <></>;
  }
  let title = '';
  if (current.type === ROOT) {
    title = 'FAQ';
  } else {
    title = current.translations[lang]
      ? current.translations[lang].title
      : '';
  }

  let children = nodes.filter(el =>
      el.parent_id === current.id
      && (
        !forDialog || el.type === DIRECTORY
      )
      && (!hideId || el.id !== hideId),
  ).sort((a, b) => {
    return a.element_order - b.element_order;
  });

  const icon = getFaqElementTypeIcon(current.type);

  let action = null;
  if (current.type === DIRECTORY && forDialog) {
    action = () => {
      changeCurrent(current.id);
    };
  } else if ([ROOT, DIRECTORY, ARTICLE].includes(current.type)) {
    action = changeCurrent;
  } else if (current.type === EXTERNAL_LINK) {
    action = () => {
      setOpenExternalLinkDialog({
        open: true,
        title: title,
        link: current.translations[lang]
          ? current.translations[lang].external_link
          : '',
      });
    };
  }

  if (children.length > 0) {
    return (
      <FaqElementsExplorerTreeItem
        key={current.id}
        elementId={current.id}
        nodeId={current.id.toString()}
        labelText={title}
        onLabelClick={action}
        labelIcon={icon}
        color={getFaqElementTypeColor(current.type)}
      >
        {children.map((node) => renderTree(
          node,
          nodes,
          lang,
          changeCurrent,
          setOpenExternalLinkDialog,
          forDialog,
          hideId
        ))}
      </FaqElementsExplorerTreeItem>
    );
  }
  return <FaqElementsExplorerTreeItem
    key={current.id}
    elementId={current.id}
    nodeId={current.id.toString()}
    labelText={title}
    labelIcon={icon}
    onLabelClick={action}
    color={getFaqElementTypeColor(current.type)}
  />;
};

const FaqElementsExplorerTree = ({
                                   elements,
                                   changeCurrent,
                                   setOpenExternalLinkDialog,
                                   forDialog,
                                   hideId
                                 }) => {
  const [expanded, setExpanded] = React.useState([]);
  const [selected, setSelected] = React.useState([]);

  const lang = i18next.languages[0].split('-')[0];
  const theme = useTheme();
  const classes = useStyles(theme);
  const root = elements.find(element => element.type === ROOT);

  const handleToggle = (event, nodeIds) => {
    setExpanded(nodeIds);
  };

  const handleSelect = (event, nodeIds) => {
    setSelected(nodeIds);
  };

  return <div className={classes.container + (
    forDialog
      ? (
        ' ' + classes.forDialog
      )
      : ''
  )}>
    <TreeView
      defaultCollapseIcon={<ExpandMoreIcon />}
      defaultExpandIcon={<ChevronRightIcon />}
      expanded={expanded}
      selected={selected}
      onNodeToggle={handleToggle}
      onNodeSelect={handleSelect}
    >
      {renderTree(
        root,
        elements,
        lang,
        changeCurrent,
        setOpenExternalLinkDialog,
        forDialog,
        hideId
      )}
    </TreeView>
  </div>;
};

FaqElementsExplorerTree.defaultProps = {
  forDialog: false,
};

export default FaqElementsExplorerTree;
