import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import MTCTable from '../Layout/Table/MTCTable';
import { getLangCallCenters } from '../../store/langCallCenters/actions';
import MTCLoader from '../Layout/MTCLoader';
import LangCallCenterRow from './LangCallCenterRow';
import Typography from '@material-ui/core/Typography';
import { columns, defaultOrderBy } from './LangCallCentersTable.const';

const LangCallCentersTable = () => {

  const { pending, content, error } = useSelector(state => state.langCallCenters);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  if (!content && !pending && !error) {
    dispatch(getLangCallCenters());
  }

  if (pending) {
    return <MTCLoader />;
  } else {
    return (
      <>
        <Typography
          gutterBottom
          align="center"
          variant="h5"
          component="h2"
        >
          {t('lang_call_center_subtitle')}
        </Typography>
        <MTCTable
          content={content}
          defaultOrderBy={defaultOrderBy}
          rowComponent={LangCallCenterRow}
          columns={columns}
          paginationLabel={t('call_center_per_page')}
          emptyDataLabel={t('no_call_center')}
        />
      </>
    );
  }
}

export default LangCallCentersTable;
