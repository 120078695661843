export const INITIAL_STATE = {
  id: null,
  emails: null,
  receiver_names: null,
  status: 'OPEN',
};

export const INITIAL_ERROR_STATE = {
  emails_error: null,
  receiver_names_error: null,
  status_error: null,
};
