import thunk from 'redux-thunk';
import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import tracksReducers from './tracks/reducers';
import searchCarsReducers from './searchCars/reducers';
import tableCarsReducers from './tableCars/reducers';
import tableRallyCarsReducers from './tableRallyCars/reducers';
import searchRallyCarsReducers from './searchRallyCars/reducers';
import formCarsReducers from './formCars/reducers';
import formRallyCarsReducers from './formRallyCars/reducers';
import receiversMtcBleReducers from './receiversMtcBle/reducers';
import receiversMtcRfReducers from './receiversMtcRf/reducers';
import receiversMmcBleReducers from './receiversMmcBle/reducers';
import receiversMmcRfReducers from './receiversMmcRf/reducers';
import requestsReducers from './requests/reducers';
import messageRequestsReducers from './messageRequests/reducers';
import usersReducers from './users/reducers';
import infoReducers from './infos/reducers';
import testersReducers from './testers/reducers';
import tiresReducers from './tires/reducers';
import titleReducers from './title/reducers';
import callCentersReducers from './callCenters/reducers';
import defaultCallCentersReducers from './defaultCallCenters/reducers';
import langCallCentersReducers from './langCallCenters/reducers';
import contactsReducers from './contacts/reducers';
import defaultContactsReducers from './defaultContacts/reducers';
import challengesReducers from './challenges/reducers';
import contentLangReducers from './contentLang/reducers';
import catalogsTagsReducers from './catalogsTags/reducers';
import catalogsItemsReducers from './catalogsItems/reducers';
import firmwareGroupsReducers from './firmwareGroups/reducers';
import firmwareExcludedVersionsReducers from './firmwareExcludedVersions/reducers';
import inAppNotificationsReducers from './inAppNotifications/reducers';
import trackCommentsReducers from './trackComments/reducers';
import homeReducers from './home/reducers';
import faqArticlesReducers from './faqArticles/reducers';
import faqElementsReducers from './faqElements/reducers';
import authorizedExportsReducers from './authorizedExports/reducers';

const reducers = combineReducers({
  tracks: tracksReducers,
  searchCars: searchCarsReducers,
  searchRallyCars: searchRallyCarsReducers,
  tableCars: tableCarsReducers,
  tableRallyCars: tableRallyCarsReducers,
  formCars: formCarsReducers,
  formRallyCars: formRallyCarsReducers,
  receiversMtcBle: receiversMtcBleReducers,
  receiversMtcRf: receiversMtcRfReducers,
  receiversMmcBle: receiversMmcBleReducers,
  receiversMmcRf: receiversMmcRfReducers,
  messageRequests: messageRequestsReducers,
  requests: requestsReducers,
  users: usersReducers,
  info: infoReducers,
  testers: testersReducers,
  tires: tiresReducers,
  title: titleReducers,
  callCenters: callCentersReducers,
  defaultCallCenters: defaultCallCentersReducers,
  langCallCenters: langCallCentersReducers,
  contacts: contactsReducers,
  defaultContacts: defaultContactsReducers,
  challenges: challengesReducers,
  contentLang: contentLangReducers,
  catalogsTags: catalogsTagsReducers,
  catalogsItems: catalogsItemsReducers,
  firmwareGroups: firmwareGroupsReducers,
  firmwareExcludedVersions: firmwareExcludedVersionsReducers,
  inAppNotifications: inAppNotificationsReducers,
  trackComments: trackCommentsReducers,
  faqArticles: faqArticlesReducers,
  faqElements: faqElementsReducers,
  home: homeReducers,
  authorizedExports: authorizedExportsReducers,
});

const composeEnhancers = (
  typeof window !== 'undefined' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
) || compose;
const store = createStore(reducers, composeEnhancers(applyMiddleware(thunk)));

export default store;
