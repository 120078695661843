import { data_languages } from '../../../../constants/lang';

export const INITIAL_STATE = {
  id: null,
  tags: [],
  type: '',
  ...data_languages.reduce((o, key) => (
    {
      ...o,
      [`name_${key}`]: '',
      [`description_${key}`]: '',
      [`pdf_${key}`]: '',
      [`pdf_${key}_to_upload`]: null,
    }
  ), {}),
  link_youtube: '',
  thumbnail_to_upload: null,
};

export const INITIAL_ERROR_STATE = {
  type_error: null,
  thumbnail_error: null,
  link_youtube_error: null,
  ...data_languages.reduce((o, key) => (
    {
      ...o,
      [`name_${key}_error`]: null,
      [`description_${key}_error`]: null,
      [`pdf_${key}_error`]: null,
    }
  ), {}),
};
