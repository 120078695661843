import React, { useState } from 'react';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import AppBar from '@material-ui/core/AppBar';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import RaceCarsTable from './Table/RaceCarsTable';
import RallyCarsTable from './Table/RallyCarsTable';

const useStyles = makeStyles(
  () => {
    return {
      root: {
        flexGrow: 1,
      },
    };
  });

export const CarTabs = () => {

  const history = useHistory();
  const classes = useStyles();
  const { t } = useTranslation();
  const [value, setValue] = useState(0);
  const [locationStateMatch, setLocationStateMatch] = useState(true);

  if (
    history.location
    && history.location.state
    && locationStateMatch
  ) {
    if (history.location.state.value) {
      setValue(history.location.state.value);
    }
    setLocationStateMatch(false);
  }

  const handleChange = (event, value) => {
    setValue(value);
  };

  return (
    <div className={classes.root}>
      <AppBar position={'static'}>
        <Tabs
          value={value}
          onChange={handleChange}
          variant={'scrollable'}
          scrollButtons={'on'}
        >
          <Tab label={t('cars_page.tabs.track_title')}/>
          <Tab label={t('cars_page.tabs.rally_title')}/>
        </Tabs>
      </AppBar>
      {value === 0 && <RaceCarsTable/>}
      {value === 1 && <RallyCarsTable/>}
    </div>
  );
}

export default React.memo(CarTabs);
