const HOME_PENDING = 'home_pending';
const HOME_FAILED = 'home_failed';
const HOME_DOWNLOADED = 'home_downloaded';
const HOME_RESET_TIMER = 'home_reset_timer';
const HOME_SEARCH = 'home_search';

export {
  HOME_PENDING,
  HOME_FAILED,
  HOME_DOWNLOADED,
  HOME_RESET_TIMER,
  HOME_SEARCH
}
