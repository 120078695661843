import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Grid from '@material-ui/core/Grid';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import {
  michelin_blue_main,
  michelin_text_grey_dark,
  michelin_white,
} from '../../../constants/michelin_colors';
import {
  RECEIVER_MMC_BLE,
  RECEIVER_MMC_RF,
  RECEIVER_MTC_BLE,
  RECEIVER_MTC_RF,
} from '../../../constants/routes';

const useStyles = makeStyles(() => {
  return {
    menu: {
      backgroundColor: '#ffffff',
    },
    bar: {
      boxShadow: 'none',
    },
    item: {
      '&:hover': {
        backgroundColor: michelin_blue_main,
        color: michelin_white,
        "& span": {
          color: michelin_white,
        },
      },
    },
    version: {
      marginLeft: '10px',
      color: michelin_text_grey_dark,
    },
  };
});

const LinkBlock = ({ title, versions }) => {

  const history = useHistory();
  const classes = useStyles();
  const [bleVersion, setBleVersion] = useState();
  const [rfVersion, setRfVersion] = useState();

  useEffect(() => {
    if (versions !== null) {
      if (title === 'MTC') {
        setRfVersion(versions.last_mtc_rf_version);
        setBleVersion(versions.last_mtc_ble_version);
      } else {
        setRfVersion(versions.last_mmc_rf_version);
        setBleVersion(versions.last_mmc_ble_version);
      }
    }
  }, [versions]);

  const handleBle = (event) => {
    event.preventDefault();
    if (title === 'MTC') {
      history.push(RECEIVER_MTC_BLE);
    } else {
      history.push(RECEIVER_MMC_BLE);
    }
  };
  const handleRf = (event) => {
    event.preventDefault();
    if (title === 'MTC') {
      history.push(RECEIVER_MTC_RF);
    } else {
      history.push(RECEIVER_MMC_RF);
    }
  };

  return (
    <Grid item xs={12}>
      <AppBar position={'relative'} component={'div'} className={classes.bar}>
        <Toolbar>
          <Typography variant="h6" color="inherit">
            {title}
          </Typography>
        </Toolbar>
      </AppBar>
      <MenuList className={classes.menu}>
        <MenuItem key={title + 'BLE'} onClick={handleBle} className={classes.item}>
          BLE
          <Typography variant={'body1'} component={'span'} className={classes.version}>
            {bleVersion}
          </Typography>
        </MenuItem>
        <MenuItem key={title + 'RF'} onClick={handleRf} className={classes.item}>
          RF
          <Typography variant={'body1'} component={'span'} className={classes.version}>
            {rfVersion}
          </Typography>
        </MenuItem>
      </MenuList>
    </Grid>
  );
};

LinkBlock.propTypes = {
  title: PropTypes.string.isRequired,
};

export default React.memo(LinkBlock);
