import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { setTitle, setBack } from '../../store/title/actions';
import withAuthentication from '../../hoc/withAuthentication';
import { ROLE_ADMIN } from '../../constants/roles';
import { getChallenges } from '../../store/challenges/actions';
import MTCLoader from '../../components/Layout/MTCLoader';
import { CHALLENGE } from '../../constants/routes';
import ChallengeForm from '../../components/Challenges/Form/ChallengeForm';
import Error404 from '../Error404';

export const ChallengeEditPage = () => {
  const { t } = useTranslation();
  const { content, pending, error } = useSelector(state => state.challenges);
  const { title } = useSelector(state => state.title);
  let { challengeId } = useParams();
  const dispatch = useDispatch();
  const [challenge, setChallenge] = useState(null)

  if (title !== t('title:title.edit_challenge_page')) {
    dispatch(setTitle(t('title:title.edit_challenge_page')));
    dispatch(setBack(CHALLENGE.replace(':challengeId', challengeId)));
  }

  if (!content && !pending && !error) {
    dispatch(getChallenges());
  }

  if (content && challenge === null) {
    const result = content.find(ch => ch.id == challengeId);
    if (result) {
      setChallenge(result);
    } else {
      setChallenge(false);
    }
  }

  if (pending) {
    return <MTCLoader />;
  } else if (!pending && content && !challenge) {
    return <Error404 />;
  } else {
    return <ChallengeForm challenge={challenge}/>;
  }
}

export default withAuthentication(ROLE_ADMIN)(ChallengeEditPage);
