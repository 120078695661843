import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import makeStyles from '@material-ui/core/styles/makeStyles';
import useTheme from '@material-ui/core/styles/useTheme';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import Tooltip from '@material-ui/core/Tooltip';
import { deleteTester } from '../../store/testers/actions';
import Typography from '@material-ui/core/Typography';
import { useTranslation } from 'react-i18next';
import { simpleRowStyle } from '../../constants/styles/row.style';

const useStyles = makeStyles(theme => ({ row: simpleRowStyle(theme)}));

const TesterRow = ({ value }) => {

  const theme = useTheme();
  const classes = useStyles(theme);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handleDeleteTester = (email) => {
    dispatch(deleteTester(email));
  };

  return (
    <TableRow
      className={classes.row}
      key={value.id}
      tabIndex={-1}
    >
      <TableCell>
        <Typography>
          {value.email}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography>
          {value.lastname}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography>
          {value.firstname}
        </Typography>
      </TableCell>
      <TableCell align={'right'}>
        <Tooltip title={t('remove')}>
          <IconButton
            aria-label="delete"
            onClick={() => handleDeleteTester(value.email)}
            color="primary"
          >
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      </TableCell>
    </TableRow>
  );
};

TesterRow.propTypes = {
  value: PropTypes.object.isRequired,
};

export default React.memo(TesterRow);
