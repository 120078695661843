import makeStyles from '@material-ui/core/styles/makeStyles';
import { drawerWidth, drawerWidthMd } from './Menu/Menu.const';
import { michelin_white } from '../../../constants/michelin_colors';

export const useStyles = makeStyles(theme => {
  return {
    scrollContainer: {
      height: '100vh'
    },
    grid: {
      [theme.breakpoints.up('lg')]: {
        width: `calc(100vw - ${drawerWidth}px)`,
        marginLeft: drawerWidth,
      },
      [theme.breakpoints.down('md')]: {
        width: `calc(100vw - ${drawerWidthMd}px)`,
        marginLeft: drawerWidthMd,
      },
      [theme.breakpoints.down('sm')]: {
        width: '100vw',
        marginTop: '64px',
        height: "calc(100vh - 64px)",
        marginLeft: 0,
        paddingLeft: 0,
        paddingRight: 0,
      },
      [theme.breakpoints.down('xs')]: {
        marginTop: '56px',
        height: "calc(100vh - 56px)",
        paddingLeft: 0,
        paddingRight: 0,
      },
      textAlign: 'center',
      boxSizing: 'border-box',
      maxWidth: 'none',
      marginTop: '1em',
    },
    appBar: {
      [theme.breakpoints.up('md')]: {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: drawerWidth,
      },
      [theme.breakpoints.down('sm')]: {
        position: 'fixed',
      },
      backgroundColor: theme.palette.primary.main,
      //minHeight: '64px',
      paddingRight: '2vh',
    },
    textTitle: {
      marginTop: '1em',
    },
    textTitleWithBack: {
      marginTop: '0.4em',
    },
    back: {
      cursor: 'pointer',
      width: '5em',
      borderRadius: '2em',
      paddingTop: '0.2em',
      paddingBottom: '0.2em',
      paddingLeft: '0.5em',
      paddingRight: '0.5em',
      '&:hover': {
        backgroundColor: 'rgba(0,0,0,0.1)',
      },
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'nowrap',
      alignItems: 'center',
    },
    backButton: {
      marginTop: '0.5em',
      marginBottom: '0.5em',
    },
    backIcon: {
      height: '0.5em',
      width: '0.5em',
    },
    menuButton: {
      marginRight: theme.spacing(2),
      [theme.breakpoints.up('md')]: {
        display: 'none',
      },
      color: theme.palette.primary.contrastText,
    },
    langButton: {
      position: 'absolute',
      top: '10px',
      right: '10px',
      [theme.breakpoints.down('sm')]: {
        right: 0,
        color: michelin_white,
      },
      [theme.breakpoints.down('xs')]: {
        top: '5px',
      },
    },
    alertButton: {
      position: 'absolute',
      top: '10px',
      right: '60px',
      [theme.breakpoints.down('sm')]: {
        right: 0,
        color: michelin_white,
      },
      [theme.breakpoints.down('xs')]: {
        top: '5px',
      },
    },
  };
});
