import makeStyles from '@material-ui/core/styles/makeStyles';
import { inputStyle } from '../../constants/styles/input.style';

export const useStyles = makeStyles(theme => {
  return {
    form: {
      paddingBottom: '5em',
    },
    textField: {
      ...inputStyle(theme),
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      marginTop: '16px',
    },
    menu: {
      width: 100,
    },
    selectLabel: {
      zIndex: 2,
    },
    grid: {},
  };
});
