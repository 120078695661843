import makeStyles from '@material-ui/core/styles/makeStyles';

export const useStyles = makeStyles(() => (
  {
    paper: {
      padding: '10px',
      margin: '10px',
      width: 'calc(100% - 30px)',
    },
    grid: {
      marginBottom: '2em',
    },
  }
));
