import React from 'react';
import { useTranslation } from 'react-i18next';
import MTCLoader from '../../Layout/MTCLoader';
import MTCTable from '../../Layout/Table/MTCTable';
import CatalogsItemRow from './CatalogsitemRow';
import { columns, defaultOrderBy } from './CatalogsItemsTable.const';

export const CatalogsItemsTable = ({pending, content}) => {
  const { t } = useTranslation();
  if (pending) {
    return <MTCLoader />;
  } else {
    return <MTCTable
      content={content}
      defaultOrderBy={defaultOrderBy}
      defaultOrder={'asc'}
      rowComponent={CatalogsItemRow}
      columns={columns}
      paginationLabel={t('catalogs_items_per_page')}
      emptyDataLabel={t('no_catalogs_items')}
    />
  }
}

export default CatalogsItemsTable;
