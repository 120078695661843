const RALLY_CARS_BRANDS_PENDING = "search_rally_cars_brands_pending";
const RALLY_CARS_MODELS_PENDING = "search_rally_cars_models_pending";
const RALLY_CARS_GROUPS_PENDING = "search_rally_cars_groups_pending";
const RALLY_CARS_DIMENSIONS_PENDING = "search_rally_cars_dimensions_pending";
const RALLY_CARS_BOUNDARIES_PENDING = "search_rally_cars_boundaries_pending";
const RALLY_CARS_BRANDS_DOWNLOADED = "search_rally_cars_brands_downloaded";
const RALLY_CARS_MODELS_DOWNLOADED = "search_rally_cars_models_downloaded";
const RALLY_CARS_GROUPS_DOWNLOADED = "search_rally_cars_groups_downloaded";
const RALLY_CARS_DIMENSIONS_DOWNLOADED = "search_rally_cars_dimensions_downloaded";
const RALLY_CARS_BOUNDARIES_DOWNLOADED = "search_rally_cars_boundaries_downloaded";
const RALLY_CARS_CSV_DOWNLOADED = 'search_rally_cars_csv_downloaded';
const RALLY_CAR_PENDING = 'search_rally_car_pending';
const RALLY_CAR_FAILED = 'search_rally_car_failed';
const RALLY_CAR_RESET = 'search_rally_car_reset';
const RALLY_CAR_TABLE_LIMIT_CHANGE = 'search_rally_car_table_limit_change';
const RALLY_CHANGE_SEARCH_IS_PUBLIC = 'rally_change_search_is_public';
const RALLY_CHANGE_SEARCH_CAR_STATE = 'rally_change_search_car_state';

const RALLY_WEATHER_TYPE = Object.freeze({
  DRY: 1,
  WET: 3,
  properties: [
    { name: 'weather_dry', value: 1 },
    { name: 'weather_wet', value: 3 },
  ],
});

export {
  RALLY_CARS_BRANDS_PENDING,
  RALLY_CARS_MODELS_PENDING,
  RALLY_CARS_GROUPS_PENDING,
  RALLY_CARS_DIMENSIONS_PENDING,
  RALLY_CARS_BOUNDARIES_PENDING,
  RALLY_CARS_BRANDS_DOWNLOADED,
  RALLY_CARS_MODELS_DOWNLOADED,
  RALLY_CARS_GROUPS_DOWNLOADED,
  RALLY_CARS_DIMENSIONS_DOWNLOADED,
  RALLY_CARS_BOUNDARIES_DOWNLOADED,
  RALLY_CAR_PENDING,
  RALLY_CAR_FAILED,
  RALLY_WEATHER_TYPE,
  RALLY_CAR_RESET,
  RALLY_CAR_TABLE_LIMIT_CHANGE,
  RALLY_CARS_CSV_DOWNLOADED,
  RALLY_CHANGE_SEARCH_IS_PUBLIC,
  RALLY_CHANGE_SEARCH_CAR_STATE
};
