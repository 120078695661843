import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import withAuthentication from '../../hoc/withAuthentication';
import { ROLE_SUPPORT } from '../../constants/roles';
import { useTranslation } from 'react-i18next';
import { setTitle } from '../../store/title/actions';
import AuthorizedExportForm
  from '../../components/Support/AuthorizedExportForm/AuthorizedExportForm';

const AuthorizedExportAddPage = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { title } = useSelector(state => state.title);

  if (title !== t('title:title.authorized_export_add_page')) {
    dispatch(setTitle(t('title:title.authorized_export_add_page')));
  }
  return (<AuthorizedExportForm />);
}

export default withAuthentication(ROLE_SUPPORT)(AuthorizedExportAddPage);
