import { INITIAL_STATE } from './FaqElementsDialogElement.const';
import { data_languages } from '../../../../../constants/lang';

export const stateWithElement = (element) => {
  return {
    ...INITIAL_STATE,
    id: element.id,
    ...data_languages.reduce((o, key) => (
      {
        ...o,
        [`title_${key}`]: element.translations[key]
          ? element.translations[key].title
          : '',
        [`tags_${key}`]: element.translations[key]
          ? element.translations[key].tags
          : '',
      }
    ), {}),
  };
};
