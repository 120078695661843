import { DEFAULT_LATITUDE, DEFAULT_LONGITUDE } from '../../constants/map_default';
import marker_section_1 from '../../resources/pictures/picto-1.png';
import marker_section_2 from '../../resources/pictures/picto-2.png';
import marker_section_3 from '../../resources/pictures/picto-3.png';
import marker_section_4 from '../../resources/pictures/picto-4.png';
import marker_section_5 from '../../resources/pictures/picto-5.png';
import marker_section_6 from '../../resources/pictures/picto-6.png';
import marker_section_7 from '../../resources/pictures/picto-7.png';
import marker_section_8 from '../../resources/pictures/picto-8.png';
import marker_section_9 from '../../resources/pictures/picto-9.png';
import marker_section_10 from '../../resources/pictures/picto-10.png';
import marker_section_11 from '../../resources/pictures/picto-11.png';
import marker_section_12 from '../../resources/pictures/picto-12.png';
import marker_section_13 from '../../resources/pictures/picto-13.png';
import marker_section_14 from '../../resources/pictures/picto-14.png';
import marker_section_15 from '../../resources/pictures/picto-15.png';
import marker_section_16 from '../../resources/pictures/picto-16.png';
import marker_section_17 from '../../resources/pictures/picto-17.png';
import marker_section_18 from '../../resources/pictures/picto-18.png';
import marker_section_19 from '../../resources/pictures/picto-19.png';
import marker_section_20 from '../../resources/pictures/picto-20.png';

export const getLatitudes = (
  trackMarker,
  startPointMarker,
  endPointMarker,
  finishStartPointMarker,
  finishEndPointMarker,
  sectors
) => {
  let latitudes = [];
  if (trackMarker != null) {
    latitudes.push(trackMarker.latitude);
  }
  if (startPointMarker != null) {
    latitudes.push(startPointMarker.latitude);
  }
  if (endPointMarker != null) {
    latitudes.push(endPointMarker.latitude);
  }
  if (finishStartPointMarker != null) {
    latitudes.push(finishStartPointMarker.latitude);
  }
  if (finishEndPointMarker != null) {
    latitudes.push(finishEndPointMarker.latitude);
  }
  if (sectors != null) {
    sectors.forEach(sector => {
      latitudes.push(sector.line_start_point_latitude);
      latitudes.push(sector.line_end_point_latitude);
    });
  }
  return latitudes;
};
export const getLongitude = (
  trackMarker,
  startPointMarker,
  endPointMarker,
  finishStartPointMarker,
  finishEndPointMarker,
  sectors
) => {
  let longitudes = [];
  if (trackMarker != null) {
    longitudes.push(trackMarker.longitude);
  }
  if (startPointMarker != null) {
    longitudes.push(startPointMarker.longitude);
  }
  if (endPointMarker != null) {
    longitudes.push(endPointMarker.longitude);
  }
  if (finishStartPointMarker != null) {
    longitudes.push(finishStartPointMarker.longitude);
  }
  if (finishEndPointMarker != null) {
    longitudes.push(finishEndPointMarker.longitude);
  }
  if (sectors != null) {
    sectors.forEach(sector => {
      longitudes.push(sector.line_start_point_longitude);
      longitudes.push(sector.line_end_point_longitude);
    });
  }
  return longitudes;
};
export const getSectorFromNumberAndPoint = (sectors, number, position) => {
  if (sectors.length > 0) {
    const sector = sectors.find(s => s.number === number);
    if (sector) {
      switch (position) {
        case 'start':
          return [sector.line_start_point_longitude, sector.line_start_point_latitude];
        case 'end':
          return [sector.line_end_point_longitude, sector.line_end_point_latitude];
        default:
          return [DEFAULT_LONGITUDE, DEFAULT_LATITUDE]
      }
    }
  }
  return [DEFAULT_LONGITUDE, DEFAULT_LATITUDE];
};
export const getSectionImage = (number) => {
  switch (number) {
    case 1:
      return marker_section_1;
    case 2:
      return marker_section_2;
    case 3:
      return marker_section_3;
    case 4:
      return marker_section_4;
    case 5:
      return marker_section_5;
    case 6:
      return marker_section_6;
    case 7:
      return marker_section_7;
    case 8:
      return marker_section_8;
    case 9:
      return marker_section_9;
    case 10:
      return marker_section_10;
    case 11:
      return marker_section_11;
    case 12:
      return marker_section_12;
    case 13:
      return marker_section_13;
    case 14:
      return marker_section_14;
    case 15:
      return marker_section_15;
    case 16:
      return marker_section_16;
    case 17:
      return marker_section_17;
    case 18:
      return marker_section_18;
    case 19:
      return marker_section_19;
    case 20:
      return marker_section_20;
    default:
      return marker_section_1;
  }
};
