export const columns = [
  {
    id: 'emails',
    numeric: false,
    disablePadding: false,
  },
  {
    id: 'receiver_names',
    numeric: false,
    disablePadding: false,
  },
  {
    id: 'export_count',
    numeric: true,
    disablePadding: false,
  },
  {
    numeric: false,
    disablePadding: false,
  },
];
export const defaultOrderBy = 'emails';
