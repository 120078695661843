import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Link from '@material-ui/core/Link';
import {
  getBoundariesCars,
  getBrandsCars,
  getCarsCsv,
  getDimensionsCars,
  getEnginesCars,
  getModelsCars,
  getRangesCars,
  reset,
  setSelectedCarState,
  setSelectedIsPublic,
  setSelectedMode,
  setSelectedTireType,
} from '../../../store/searchCars/actions';
import MTCButton from '../../Layout/Button/MTCButton';
import CsvFile from '../../icon/CsvFile';
import { useStyles } from './RaceCarSearch.style';
import { INITIAL_STATE } from './RaceCarSearch.const';

const RaceCarSearch = ({ handleSearch, handleReset }) => {
  const classes = useStyles();
  const {
    brands,
    brands_pending,
    selected_brand,
    ranges,
    ranges_pending,
    selected_range,
    models,
    models_pending,
    selected_model,
    engines,
    engines_pending,
    selected_engine,
    dimensions,
    dimensions_pending,
    selected_dimension,
    selected_mode,
    selected_tire_type,
    selected_is_public,
    selected_car_state,
    error,
  } = useSelector(state => state.searchCars);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [state, setState] = useState(INITIAL_STATE);

  if (selected_mode !== null && state.mode === INITIAL_STATE.mode) {
    setState({...state, mode: selected_mode});
  }
  if (selected_tire_type !== null && state.tire_type === INITIAL_STATE.tire_type) {
    setState({...state, tire_type: selected_tire_type});
  }
  if (selected_is_public !== null && state.is_public === INITIAL_STATE.is_public) {
    setState({...state, is_public: selected_is_public});
  }
  if (selected_car_state !== null && state.car_state === INITIAL_STATE.car_state) {
    setState({...state, car_state: selected_car_state});
  }


  if (!brands && !brands_pending && !error) {
    dispatch(getBrandsCars());
  }

  if (selected_brand !== state.brand) {
    setState({
      ...state,
      brand: selected_brand,
    });
  }
  if (selected_range !== state.range) {
    setState({
      ...state,
      range: selected_range,
    });
  }
  if (selected_model !== state.model) {
    setState({
      ...state,
      model: selected_model,
    });
  }
  if (selected_engine !== state.engine) {
    setState({
      ...state,
      engine: selected_engine,
    });
  }
  if (selected_dimension !== state.dimension) {
    setState({
      ...state,
      dimension: selected_dimension,
    });
  }

  const onBrandChange = (event) => {
    event.stopPropagation();
    setState({
      ...state,
      brand: event.target.value,
      range: '',
      model: '',
      engine: '',
      dimension: '',
    });
    dispatch(getRangesCars(event.target.value));
  };

  const onRangeChange = (event) => {
    event.stopPropagation();
    setState({
      ...state,
      range: event.target.value,
      model: '',
      engine: '',
      dimension: '',
    });
    dispatch(
      getModelsCars(state.brand, event.target.value),
    );
  };

  const onModelChange = (event) => {
    event.stopPropagation();
    setState({
      ...state,
      model: event.target.value,
      engine: '',
      dimension: '',
    });
    dispatch(
      getEnginesCars(state.brand, state.range, event.target.value),
    );
  };

  const onEngineChange = (event) => {
    event.stopPropagation();
    setState({
      ...state,
      engine: event.target.value,
      dimension: '',
    });
    dispatch(
      getDimensionsCars(state.brand, state.range, state.model, event.target.value),
    );
  };

  const onDimensionChange = (event) => {
    event.stopPropagation();
    setState({
      ...state,
      dimension: event.target.value,
    });
    dispatch(
      getBoundariesCars(state.brand, state.range, state.model, state.engine, event.target.value),
    );
  };

  const onModeChange = (event) => {
    event.stopPropagation();
    setState({
      ...state,
      mode: event.target.value,
    });
    dispatch(
      setSelectedMode(event.target.value === INITIAL_STATE.mode
        ? null
        : event.target.value
      ),
    );
  };

  const onTireTypeChange = (event) => {
    event.stopPropagation();
    setState({
      ...state,
      tire_type: event.target.value,
    });
    dispatch(
      setSelectedTireType(event.target.value === INITIAL_STATE.tire_type
        ? null
        : event.target.value
      ),
    );
  };

  const onIsPublicChange = (event) => {
    event.stopPropagation();
    setState({
      ...state,
      is_public: event.target.value,
    });
    dispatch(
      setSelectedIsPublic(event.target.value === INITIAL_STATE.is_public
        ? null
        : event.target.value
      ),
    );
  }

  const onCarStateChange = (event) => {
    event.stopPropagation();
    setState({
      ...state,
      car_state: event.target.value,
    });
    dispatch(
      setSelectedCarState(event.target.value === INITIAL_STATE.car_state
        ? null
        : event.target.value
      ),
    );
  }

  const handleClickReset = (event) => {
    event.stopPropagation();
    setState(INITIAL_STATE);
    dispatch(reset());
    handleReset();
  };

  const handleClickSearch = (event) => {
    event.stopPropagation();
    let search = '';
    if (state.brand !== '') {
      search = `brandId=${state.brand}`;
    }
    if (state.range !== '') {
      if (search === '') {
        search = `rangeId=${state.range}`;
      } else {
        search = search + `&rangeId=${state.range}`;
      }
    }
    if (state.model !== '') {
      if (search === '') {
        search = `modelId=${state.model}`;
      } else {
        search = search + `&modelId=${state.model}`;
      }
    }
    if (state.engine !== '') {
      if (search === '') {
        search = `engineId=${state.engine}`;
      } else {
        search = search + `&engineId=${state.engine}`;
      }
    }
    if (state.dimension !== '') {
      if (search === '') {
        search = `dimensionId=${state.dimension}`;
      } else {
        search = search + `&dimensionId=${state.dimension}`;
      }
    }
    if (state.mode !== '') {
      if (search === '') {
        search = `compatibleWithReceiver=${state.mode}`;
      } else {
        search = search + `&compatibleWithReceiver=${state.mode}`;
      }
    }
    if (state.tire_type !== '') {
      if (search === '') {
        search = `tireType=${state.tire_type}`;
      } else {
        search = search + `&tireType=${state.tire_type}`;
      }
    }
    if (state.is_public !== '') {
      if (search === '') {
        search = `isPublic=${state.is_public}`;
      } else {
        search = search + `&isPublic=${state.is_public}`;
      }
    }
    if (state.car_state !== '') {
      if (search === '') {
        search = `carState=${state.car_state}`;
      } else {
        search = search + `&carState=${state.car_state}`;
      }
    }
    handleSearch(search);
  };

  const exportCsv = (event) => {
    event.preventDefault();
    event.stopPropagation();
    dispatch(
      getCarsCsv(
        state.brand,
        state.range,
        state.model,
        state.engine,
        state.dimension,
        state.mode,
        state.tire_type,
        state.is_public,
        state.car_state
      )
    );
  }

  return (
    <Paper className={classes.paper}>
      <Grid
        container
        spacing={2}
        justifyContent="flex-start"
        alignItems="stretch"
        className={classes.form}
      >
        <Grid item>
          <FormControl className={classes.select}>
            <InputLabel id="brands_select_label">{t('brand_id')}</InputLabel>
            <Select
              id={'brands_select'}
              labelId={'brands_select_label'}
              onChange={onBrandChange}
              value={state.brand}
            >
              {brands
              && brands.map((b) =>
                <MenuItem key={b.id} value={b.id}>
                  {b.name}
                </MenuItem>,
              )}
            </Select>
          </FormControl>
        </Grid>

        <Grid item>
          <FormControl className={classes.select}>
            <InputLabel id="ranges_select_label">{t('range_id')}</InputLabel>
            <Select
              id={'ranges_select'}
              labelId={'ranges_select_label'}
              onChange={onRangeChange}
              value={state.range}
            >
              {!ranges_pending
              && ranges
              && ranges.map((r) =>
                <MenuItem key={r.id} value={r.id}>
                  {r.name}
                </MenuItem>,
              )}
            </Select>
          </FormControl>
        </Grid>

        <Grid item>
          <FormControl className={classes.select}>
            <InputLabel id="models_select_label">{t('model_id')}</InputLabel>
            <Select
              id={'models_select'}
              labelId={'models_select_label'}
              onChange={onModelChange}
              value={state.model}
            >
              {!models_pending
              && models
              && models.map((m) =>
                <MenuItem key={m.id} value={m.id}>
                  {m.name}
                </MenuItem>,
              )}
            </Select>
          </FormControl>
        </Grid>

        <Grid item>
          <FormControl className={classes.select}>
            <InputLabel id="engines_select_label">{t('engine_id')}</InputLabel>
            <Select
              id={'engines_select'}
              labelId={'engines_select_label'}
              onChange={onEngineChange}
              value={state.engine}
            >
              {!engines_pending
              && engines
              && engines.map((e) =>
                <MenuItem key={e.id} value={e.id}>
                  {e.name}
                </MenuItem>,
              )}
            </Select>
          </FormControl>
        </Grid>

        <Grid item>
          <FormControl className={classes.select}>
            <InputLabel id="dimensions_select_label">{t('front_dimension')}</InputLabel>
            <Select
              id={'dimensions_select'}
              labelId={'dimensions_select_label'}
              onChange={onDimensionChange}
              value={state.dimension}
            >
              {!dimensions_pending
              && dimensions
              && dimensions.map((d) =>
                <MenuItem key={d.id} value={d.id}>
                  {d.front_dimension} / {d.rear_dimension}
                </MenuItem>,
              )}
            </Select>
          </FormControl>
        </Grid>
        <Grid item>
          <FormControl className={classes.select}>
            <InputLabel shrink={true} id="mode_select_label">{t('mode')}</InputLabel>
            <Select
              id={'mode_select'}
              labelId={'mode_select_label'}
              onChange={onModeChange}
              value={state.mode}
              displayEmpty={true}
            >
              <MenuItem key={'modeAll'} value={''}>
                <em>{t('all_mode')}</em>
              </MenuItem>
              <MenuItem key={'modeFree'} value={'0'}>
                {t('mode_free')}
              </MenuItem>
              <MenuItem key={'modeAmateur'} value={'1'}>
                {t('mode_amateur')}
              </MenuItem>
            </Select>
          </FormControl>
        </Grid>

        <Grid item>
          <FormControl className={classes.select}>
            <InputLabel shrink={true} id="tire_type_select_label">{t('tire_type')}</InputLabel>
            <Select
              id={'tire_type_select'}
              labelId={'tire_type_select_label'}
              onChange={onTireTypeChange}
              value={state.tire_type}
              displayEmpty={true}
            >
              <MenuItem key={'allTireType'} value={''}>
                <em>{t('all_tire_type')}</em>
              </MenuItem>
              <MenuItem key={'typePsc2'} value={'PSC2'}>
                PSC2
              </MenuItem>
              <MenuItem key={'typePsc2r'} value={'PSC2R'}>
                PSC2R
              </MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item>
          <FormControl className={classes.select}>
            <InputLabel shrink={true} id="is_public_select_label">{t('is_public')}</InputLabel>
            <Select
              id={'is_public_select'}
              labelId={'is_public_select_label'}
              onChange={onIsPublicChange}
              value={state.is_public}
              displayEmpty={true}
            >
              <MenuItem key={'allStatus'} value={''}>
                <em>{t('all_mode')}</em>
              </MenuItem>
              <MenuItem key={'is_public_yes'} value={'1'}>
                {t('public')}
              </MenuItem>
              <MenuItem key={'is_public_no'} value={'0'}>
                {t('tester')}
              </MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item>
          <FormControl className={classes.select}>
            <InputLabel shrink={true} id="car_state_select_label">{t('display')}</InputLabel>
            <Select
              id={'car_state_select'}
              labelId={'car_state_select_label'}
              onChange={onCarStateChange}
              value={state.car_state}
              displayEmpty={true}
            >
              <MenuItem key={'allStates'} value={''}>
                <em>{t('all_mode')}</em>
              </MenuItem>
              <MenuItem key={'state_visible'} value={'0'}>
                {t('all_visible')}
              </MenuItem>
              <MenuItem key={'state_hide'} value={'1'}>
                {t('all_hidden')}
              </MenuItem>
            </Select>
          </FormControl>
        </Grid>
      </Grid>

      <Grid container justifyContent={'center'} alignItems={'center'} spacing={2}>
        <Grid item>
            <MTCButton
              id={'search_button'}
              text={t('search')}
              onClick={handleClickSearch}
              style={{width: 200}}
            />
        </Grid>
        <Grid item>
            <MTCButton
              id={'reset_button'}
              text={t('reset')}
              onClick={handleClickReset}
              color={'primary'}
              style={{width: 200}}
            />
        </Grid>
      </Grid>

      <Grid container justifyContent={'center'} alignItems={'center'} spacing={2}>
        <Link href={'#'} onClick={exportCsv} className={classes.link}>
          <CsvFile size={'20'} style={{ verticalAlign: 'text-bottom', marginRight: '5px' }}/>
          {t('search_csv_cars_export')}
        </Link>
      </Grid>
    </Paper>
  );
};

export default RaceCarSearch;
