import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import withAuthentication from '../../hoc/withAuthentication';
import { ROLE_ADMIN } from '../../constants/roles';
import { setTitle } from '../../store/title/actions';
import AddCallCenterForm from '../../components/CallCenters/AddCallCenterForm';
import { getLangCallCenters } from '../../store/langCallCenters/actions';
import MTCLoader from '../../components/Layout/MTCLoader';

const AddLangCallCentersPage = () => {
  const dispatch = useDispatch();
  const { title } = useSelector(state => state.title);
  const { pending, content, error, unavailableLang }  = useSelector(state => state.langCallCenters);
  const { t } = useTranslation();

  if (!content && !pending && !error) {
    dispatch(getLangCallCenters());
  }

  if (title !== t('title:title.add_lang_call_centers_page')) {
    dispatch(setTitle(t('title:title.add_lang_call_centers_page')));
  }

  if (pending) {
    return <MTCLoader />;
  } else {
    return (
      <AddCallCenterForm langCallCenter={true} unavailableLang={unavailableLang}/>
    );
  }
}

export default withAuthentication(ROLE_ADMIN)(AddLangCallCentersPage);