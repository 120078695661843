import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import withAuthentication from '../../hoc/withAuthentication';
import { ROLE_ADMIN } from '../../constants/roles';
import { CATALOGS_ITEMS_ADD, CATALOGS_TAGS } from '../../constants/routes';
import { setTitle } from '../../store/title/actions';
import MTCButton from '../../components/Layout/Button/MTCButton';
import { getCatalogsItems } from '../../store/catalogsItems/actions';
import CatalogsItemsTabs from '../../components/Catalogs/Items/CatalogsItemsTabs';
import AddButton from '../../components/Layout/Button/AddButton';

export const CatalogsPage = () => {

  const { t } = useTranslation();
  const { title } = useSelector(state => state.title);
  const {
    content,
    pending,
    error,
  } = useSelector(state => state.catalogsItems);
  const dispatch = useDispatch();
  const history = useHistory();

  if (title !== t('title:title.catalogs_page')) {
    dispatch(setTitle(t('title:title.catalogs_page')));
  }
  if (!content && !pending && !error) {
    dispatch(getCatalogsItems());
  }

  const handleClickShowTags = () => {
    history.push(CATALOGS_TAGS)
  }

  return <>
    <MTCButton
      text={t('title:title.catalog_tags')}
      onClick={handleClickShowTags}
      style={{float: 'right'}}
    />
    <CatalogsItemsTabs pending={pending}/>
    <AddButton path={CATALOGS_ITEMS_ADD} />
  </>
}


export default withAuthentication(ROLE_ADMIN)(CatalogsPage);