import React from 'react';
import { useHistory } from 'react-router';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import useTheme from '@material-ui/core/styles/useTheme';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import { CATALOGS_ITEMS_EDIT } from '../../../constants/routes';
import { useStyles } from './CatalogsItemRow.style';

const CatalogsItemRow = ({ value }) => {

  const theme = useTheme();
  const classes = useStyles(theme);
  const history = useHistory();
  const { sending } = useSelector(state => state.catalogsItems);

  const handleEdit = (id) => {
    history.push(CATALOGS_ITEMS_EDIT.replace(':itemId', id));
  };

  return (
    <TableRow
      className={classes.row}
      key={value.id}
      tabIndex={-1}
    >
      <TableCell>{value.translations.fr !== undefined && value.translations['fr'].name}</TableCell>
      <TableCell>
        <IconButton
          id='edit'
          aria-label="edit"
          onClick={() => handleEdit(value.id)}
          className={classes.button}
          disabled={sending}
        >
          <EditIcon />
        </IconButton>
      </TableCell>
    </TableRow>
  );
};

CatalogsItemRow.propTypes = {
  value: PropTypes.object.isRequired,
};

export default React.memo(CatalogsItemRow);
