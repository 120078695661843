const TRACK_COMMENTS_PENDING = 'track_comments_pending';
const TRACK_COMMENTS_FAILED = 'track_comments_failed';
const TRACK_COMMENTS_DOWNLOADED = 'track_comments_downloaded';
const TRACK_COMMENT_SENDING = 'track_comment_sending';
const TRACK_COMMENT_DOWNLOADED = 'track_comment_downloaded';

export {
  TRACK_COMMENTS_PENDING,
  TRACK_COMMENTS_FAILED,
  TRACK_COMMENTS_DOWNLOADED,
  TRACK_COMMENT_SENDING,
  TRACK_COMMENT_DOWNLOADED,
};
