const FIRMWARE_EXCLUDED_VERSIONS_PENDING = 'firmware_excluded_versions_pending';
const FIRMWARE_EXCLUDED_VERSIONS_FAILED = 'firmware_excluded_versions_failed';
const FIRMWARE_EXCLUDED_VERSIONS_DOWNLOADED = 'firmware_excluded_versions_downloaded';
const FIRMWARE_EXCLUDED_VERSION_SENDING = 'firmware_excluded_version_sending';
const FIRMWARE_EXCLUDED_VERSION_DOWNLOADED = 'firmware_excluded_version_downloaded';
const FIRMWARE_EXCLUDED_VERSION_DELETED = 'firmware_excluded_version_deleted';

export {
  FIRMWARE_EXCLUDED_VERSIONS_PENDING,
  FIRMWARE_EXCLUDED_VERSIONS_FAILED,
  FIRMWARE_EXCLUDED_VERSIONS_DOWNLOADED,
  FIRMWARE_EXCLUDED_VERSION_SENDING,
  FIRMWARE_EXCLUDED_VERSION_DOWNLOADED,
  FIRMWARE_EXCLUDED_VERSION_DELETED
};
