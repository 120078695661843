import makeStyles from '@material-ui/core/styles/makeStyles';

export const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '50px auto 50px',
  },
});
