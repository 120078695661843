export const columns = [
  {
    id: 'title',
    numeric: false,
    disablePadding: false,
  },
  {
    id: 'tags',
    numeric: false,
    disablePadding: false,
  },
  {
    numeric: false,
    disablePadding: false,
  },
];

export const defaultOrderBy = 'title';
