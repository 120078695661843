import React from 'react';
import PropTypes from 'prop-types';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import makeStyles from '@material-ui/core/styles/makeStyles';
import useTheme from '@material-ui/core/styles/useTheme';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { deleteFirmwareGroupItems } from '../../../../store/firmwareGroups/actions';
import { useParams } from 'react-router';
import { clickableRowStyle } from '../../../../constants/styles/row.style';

const useStyles = makeStyles(theme => ({ row: clickableRowStyle(theme)}));

const FirmwareGroupItemRow = ({ value }) => {

  const theme = useTheme();
  const classes = useStyles(theme);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { groupId } = useParams();

  const handleDelete = (event) => {
    event.preventDefault();
    event.stopPropagation();
    dispatch(deleteFirmwareGroupItems(groupId, value.id));
  }

  return (
      <TableRow
        className={classes.row}
        key={value.id}
        tabIndex={-1}
        hover
      >
        <TableCell>{value.name}</TableCell>
        <TableCell>{value.emails.join(', ')}</TableCell>
        <TableCell align={'right'}>
          <Tooltip title={t('remove')}>
            <IconButton
              id='remove'
              aria-label="delete"
              onClick={handleDelete}
            >
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </TableCell>
      </TableRow>
  );
};

FirmwareGroupItemRow.propTypes = {
  value: PropTypes.object.isRequired
};

export default React.memo(FirmwareGroupItemRow);
