import { data_languages } from '../../../constants/lang';
import { INITIAL_STATE } from './InAppNotificationForm.const';
import { htmlToEditorState } from '../../../utils/DraftEditorUtils'

export const stateWithInAppNotification = (notification, isDuplicate) => {
  return {
    ...INITIAL_STATE,
    id: notification.id,
    start_date: notification.start_date
      ? new Date(notification.start_date * 1000)
      : null,
    end_date: notification.end_date
      ? new Date(notification.end_date * 1000)
      : null,
    has_call_to_action: notification.has_call_to_action,
    is_activated: notification.is_activated,
    device_os: notification.device_os,
    has_been_pushed: notification.has_been_pushed,
    has_scheduled_pushed: notification.has_scheduled_pushed,
    can_push: !notification.has_been_pushed && !notification.has_scheduled_pushed,
    push: notification.has_been_pushed || notification.has_scheduled_pushed,
    push_time: notification.push_time ?? '',
    user_type: notification.user_type,
    country_codes: notification.country_codes ? notification.country_codes.split(',') : [],
    user_has_expert_compatible_free_car: notification.user_has_expert_compatible_free_car,
    with_app_version: notification.app_version !== null,
    app_version: notification.app_version,
    app_version_condition: notification.app_version_condition,
    ...data_languages.reduce((o, key) => (
      {
        ...o,
        [`title_${key}`]: notification.translations[key]
          ? notification.translations[key].title
          : '',
        [`text_${key}`]: htmlToEditorState(
          notification.translations[key]
            ? notification.translations[key].text
            : ''
        ),
        [`call_to_action_title_${key}`]: (
          notification.has_call_to_action && notification.translations[key]
        )
          ? notification.translations[key].call_to_action_title
          : '',
        [`call_to_action_link_${key}`]: (
          notification.has_call_to_action && notification.translations[key]
        )
          ? notification.translations[key].call_to_action_link
          : '',
        [`push_title_${key}`]: notification.translations[key]
          ? notification.translations[key].push_title
          : '',
        [`push_body_${key}`]: notification.translations[key]
          ? notification.translations[key].push_body
          : '',
      }
    ), {}),
  };
};
