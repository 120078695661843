import {
  CHALLENGES_PENDING,
  CHALLENGES_FAILED,
  CHALLENGES_DOWNLOADED,
  CHALLENGE_DOWNLOADED,
  CHALLENGE_SENDING
} from './types';

export const INITIAL_STATE = {
  content: null,
  pending: false,
  sending: false,
  error: null,
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case CHALLENGES_PENDING:
      return {
        ...state,
        pending: true,
        error: null
      };
    case CHALLENGE_SENDING:
      return {
        ...state,
        sending: true
      };
    case CHALLENGES_FAILED:
      return {
        ...state,
        error: action.payload,
        pending: false,
        sending: false
      };
    case CHALLENGES_DOWNLOADED:
      return {
        content: action.payload,
        error: null,
        pending: false,
        sending: false
      };
    case CHALLENGE_DOWNLOADED: {
      let newContent = [];
      if (state.content != null) {
        newContent = [...state.content];
      }
      let index = newContent.findIndex(ch => ch.id === action.payload.id);
      if (index === -1) {
        newContent.push(action.payload);
      } else {
        newContent[index] = action.payload;
      }
      return {
        content: newContent,
        error: null,
        pending: false,
        sending: false
      };
    }
    default:
      break;
  }
  return state;
}