import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import withAuthentication from '../../hoc/withAuthentication';
import { ROLE_SUPPORT } from '../../constants/roles';
import { setTitle } from '../../store/title/actions';
import AuthorizedExportForm
  from '../../components/Support/AuthorizedExportForm/AuthorizedExportForm';
import { getAuthorizedExports } from '../../store/authorizedExports/actions';
import MTCLoader from '../../components/Layout/MTCLoader';
import Error404 from '../Error404';

const AuthorizedExportEditPage = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { authorizedExportId } = useParams();
  const [authorizedExport, setAuthorizedExport] = useState(null);
  const {
    pending,
    content,
    error,
  } = useSelector(state => state.authorizedExports);

  const { title } = useSelector(state => state.title);

  if (title !== t('title:title.authorized_export_edit_page')) {
    dispatch(setTitle(t('title:title.authorized_export_edit_page')));
  } else if (!content && !pending && !error) {
    dispatch(getAuthorizedExports());
  } else if (content && authorizedExportId && authorizedExport === null) {
    const result = content.find(ae => ae.id == authorizedExportId);
    if (result) {
      setAuthorizedExport(result);
    } else {
      setAuthorizedExport(false);
    }
  }
  if (pending || authorizedExport === null) {
    return <MTCLoader />;
  } else if (content && authorizedExport === false) {
    return <Error404 />;
  } else {
    return (
      <AuthorizedExportForm authorizedExport={authorizedExport} />
    );
  }
};

export default withAuthentication(ROLE_SUPPORT)(AuthorizedExportEditPage);
