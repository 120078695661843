import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { WEATHER_TYPE } from '../../../store/formCars/types';
import BoundaryTextField from './BoundaryTextField';
import { useTranslation } from 'react-i18next';

function BoundaryForm(props) {

  const {
    boundary,
    onChange,
    isRally,
  } = props;
  const { t } = useTranslation();
  return (
    <Grid container>
      <Grid item xs={12} sm={12} md={2}>
        <Typography
          variant="subtitle1"
          align="left"
          style={{ marginTop: '20px' }}
        >
          {t(WEATHER_TYPE.properties.find(
            property => property.value === boundary.weather_type,
          ).name)} :
        </Typography>
      </Grid>
      {['red_orange', 'orange_green', 'green_orange', 'orange_red'].map(property =>
        <BoundaryTextField
          required={boundary.compatible_with_receiver || isRally || false}
          boundary={boundary}
          property={property}
          onChange={onChange}
          key={'boundary' + boundary.is_front + boundary.weather_type + property}
        />,
      )}
    </Grid>
  );
}

BoundaryForm.propTypes = {
  boundary: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  isRally: PropTypes.bool,
};

export default BoundaryForm;
