/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import ReactMapboxGl, { Feature, GeoJSONLayer, Layer } from 'react-mapbox-gl';
import {
  michelin_black,
  michelin_blue_main,
  michelin_yellow_main,
} from '../../constants/michelin_colors';
import { MAPBOX_KEY } from '../../constants/env';
import blue_MarkerP from '../../resources/pictures/picto-parking.png';
import marker_start from '../../resources/pictures/picto-depart.png';
import marker_finish from '../../resources/pictures/picto-arrivee.png';
import greenArrow from '../../resources/pictures/green_arrow.png';
import redArrow from '../../resources/pictures/red_arrow.png';
import { DEFAULT_LATITUDE, DEFAULT_LONGITUDE } from '../../constants/map_default';
import {
  getLatitudes,
  getLongitude,
  getSectionImage,
  getSectorFromNumberAndPoint,
} from './TrackMapBox.func';

//CHANGE : Edit accessToken with mapBox Key
const Map = ReactMapboxGl({
  accessToken: MAPBOX_KEY,
});

export const TrackMapBox = (props) => {

  const {
    trackMarker,
    startPointMarker,
    endPointMarker,
    hasFinishLine,
    finishStartPointMarker,
    finishEndPointMarker,
    handleMarker,
    handleDragMarker,
    sectors,
    geojson,
    disableEditing,
    lapLineBearing,
    finishLapLineBearing,
  } = props;
  let sectorsPoints = [...sectors];
  sectorsPoints.sort((a, b) => {
    return a.number - b.number;
  });
  sectorsPoints.pop(); // remove last sector, same as finish lap line

  let displayedGeojson;
  let canEdit = true;
  if (disableEditing) {
    canEdit = false;
  }


  if (geojson == null || geojson == '' || geojson == undefined) {
    displayedGeojson = {
      'type': 'FeatureCollection',
      'features': [
        {
          'type': 'Feature',
          'geometry': {
            'type': 'LineString',
            'coordinates': [
            ]
          }
        }
      ]
    };
  } else {
    displayedGeojson = geojson;
  }

  const defaultLatLng = [DEFAULT_LONGITUDE, DEFAULT_LATITUDE];

  let imageBlueP = new Image(20, 34);
  imageBlueP.src = blue_MarkerP;
  let imagesBlueP = ['imageBlueP', imageBlueP];

  let imageMarkerStart = new Image(20, 34);
  imageMarkerStart.src = marker_start;
  let imagesLineStartPoint = ['imageLineStartPoint', imageMarkerStart];
  let imagesLineEndPoint = ['imageLineEndPoint', imageMarkerStart];

  let imageMarkerStartBearing = new Image(35, 35);
  imageMarkerStartBearing.src = greenArrow;
  let imagesLineStartBearingPoint = ['imageLineStartBearingPoint', imageMarkerStartBearing];

  let imageMarkerFinish = new Image(20, 34);
  imageMarkerFinish.src = marker_finish;
  let imagesFinishLineStartPoint = ['imageFinishLineStartPoint', imageMarkerFinish];
  let imagesFinishLineEndPoint = ['imageFinishLineEndPoint', imageMarkerFinish];

  let imageMarkerFinishBearing = new Image(35, 35);
  imageMarkerFinishBearing.src = redArrow;
  let imagesLineFinishBearingPoint = ['imageLineFinishBearingPoint', imageMarkerFinishBearing];

  let imageMarkerSection = [];
  let imagesMarkerSectionStartPoint = [];
  let imagesMarkerSectionEndPoint = [];
  for (let i = 1; i < 21; i++) {
    imageMarkerSection[i] = new Image(20, 34);
    imageMarkerSection[i].src = getSectionImage(i);
    imagesMarkerSectionStartPoint[i] = [`imageMarkerSectionStartPoint${i}`, imageMarkerSection[i]];
    imagesMarkerSectionEndPoint[i] = [`imageMarkerSectionEndPoint${i}`, imageMarkerSection[i]];
  }

  let bearingStartPoint = { longitude: defaultLatLng[0], latitude: defaultLatLng[1]};
  let bearingFinishStartPoint = { longitude: defaultLatLng[0], latitude: defaultLatLng[1]};

  if (startPointMarker != null && endPointMarker != null) {
    bearingStartPoint = {
      longitude: (startPointMarker.longitude + endPointMarker.longitude) / 2,
      latitude: (startPointMarker.latitude + endPointMarker.latitude) / 2
    }
  }

  if (finishStartPointMarker != null && finishEndPointMarker != null) {
    bearingFinishStartPoint = {
      longitude: (finishStartPointMarker.longitude + finishEndPointMarker.longitude) / 2,
      latitude: (finishStartPointMarker.latitude + finishEndPointMarker.latitude) / 2
    }
  }

  const lng = getLongitude(
    trackMarker,
    startPointMarker,
    endPointMarker,
    finishStartPointMarker,
    finishEndPointMarker,
    sectorsPoints,
  );
  const lat = getLatitudes(
    trackMarker,
    startPointMarker,
    endPointMarker,
    finishStartPointMarker,
    finishEndPointMarker,
    sectorsPoints,
  );

  const maxLat = Math.max(...lat);
  const minLat = Math.min(...lat);
  const maxLng = Math.max(...lng);
  const minLng = Math.min(...lng);

  let sectorsNumbers = [];
  if (canEdit) {
    sectorsNumbers = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20];
  } else {
    sectorsPoints.forEach(e => sectorsNumbers.push(e.number))
    sectorsNumbers = [...new Set(sectorsNumbers)]
  }

  let bbox = [[minLng, minLat], [maxLng, maxLat]];
  try {
    return (
      <Map
        fitBounds={bbox}
        fitBoundsOptions={{ padding: 50 }}
        style="mapbox://styles/mapbox/satellite-streets-v10"
        containerStyle={{
          height: '550px',
          width: '100%',
        }}
        onClick={handleMarker}
      >
        {/* geoJson */}
        <GeoJSONLayer
          data={displayedGeojson}
          linePaint={{
            'line-color': michelin_blue_main,
            'line-width': 4
          }}
        />
        {/* Parking point */}
        <Layer
          type="symbol"
          id="imageBlueP_layer"
          layout={{
            'icon-image': 'imageBlueP',
            'icon-allow-overlap': true,
          }}
          images={imagesBlueP}
        >
          <Feature
            coordinates={trackMarker != null
              ? [trackMarker.longitude, trackMarker.latitude]
              : defaultLatLng}
            draggable={canEdit}
            onDragEnd={event => handleDragMarker(event, 'P')}
          />
        </Layer>

        {/* Start line */}
        <Layer
          type="line"
          id="start_line"
          paint={{
            'line-color': michelin_yellow_main,
            'line-width': 2,
          }}
        >
          <Feature
            coordinates={[
              startPointMarker != null
                ? [startPointMarker.longitude, startPointMarker.latitude]
                : defaultLatLng,
              endPointMarker != null
                ? [endPointMarker.longitude, endPointMarker.latitude]
                : defaultLatLng,
            ]}
          />
        </Layer>


        {/* Finish line */}
        {(canEdit || (!canEdit && finishEndPointMarker != null) )
        && <>
          <Layer
            type="line"
            id="finish_line"
            paint={{
              'line-color': michelin_yellow_main,
              'line-width': 2,
            }}
            layout={{
              'visibility': hasFinishLine
                ? 'visible'
                : 'none',
            }}
          >
            <Feature
              coordinates={[
                finishStartPointMarker != null
                  ? [finishStartPointMarker.longitude, finishStartPointMarker.latitude]
                  : defaultLatLng,
                finishEndPointMarker != null
                  ? [finishEndPointMarker.longitude, finishEndPointMarker.latitude]
                  : defaultLatLng,
              ]}
            />
          </Layer>
        </>}

        {/* Sectors lines */}
        {sectorsPoints.map(sector => {
          return <Layer
            key={`key_sector_line_${sector.number}`}
            type="line"
            id={`sector_line_${sector.number}`}
            paint={{
              'line-color': michelin_yellow_main,
              'line-width': 2,
            }}
            layout={{ 'visibility': 'visible' }}
          >
            <Feature
              coordinates={[
                [sector.line_start_point_longitude, sector.line_start_point_latitude],
                [sector.line_end_point_longitude, sector.line_end_point_latitude],
              ]}
            />
          </Layer>;
        })}

        {/*Start Line points*/}
        <Layer
          type="symbol"
          id="imageLineStartPoint_layer"
          layout={{
            'icon-image': 'imageLineStartPoint',
            'icon-allow-overlap': true,
          }}
          images={imagesLineStartPoint}
        >
          <Feature
            coordinates={startPointMarker != null
              ? [startPointMarker.longitude, startPointMarker.latitude]
              : defaultLatLng}
            draggable={canEdit}
            onDragEnd={event => handleDragMarker(event, 'A')}
          />
        </Layer>
        <Layer
          type="symbol"
          id="imageLineEndPoint_layer"
          layout={{
            'icon-image': 'imageLineEndPoint',
            'icon-allow-overlap': true,
          }}
          images={imagesLineEndPoint}
        >
          <Feature
            coordinates={endPointMarker != null
              ? [endPointMarker.longitude, endPointMarker.latitude]
              : defaultLatLng}
            draggable={canEdit}
            onDragEnd={event => handleDragMarker(event, 'B')}
          />
        </Layer>

        {/*Start Line bearing point*/}
        <Layer
          type="symbol"
          id="imageLineStartBearingPoint_layer"
          layout={{
            'icon-image': 'imageLineStartBearingPoint',
            'icon-allow-overlap': true,
            'icon-rotate': ['get', 'bearing'],
            'visibility': lapLineBearing !== null
              ? 'visible'
              : 'none',
            }}
          images={imagesLineStartBearingPoint}
        >
          <Feature
            coordinates={[bearingStartPoint.longitude, bearingStartPoint.latitude]}
            draggable={false}
            properties={{bearing: lapLineBearing}}
          />
        </Layer>
        {(canEdit || (!canEdit && finishEndPointMarker != null) )
        && <>
        {/*End Line points*/}
            <Layer
              type="symbol"
              id="imageFinishLineStartPoint_layer"
              layout={{
                'icon-image': 'imageFinishLineStartPoint',
                'icon-allow-overlap': true,
                'visibility': hasFinishLine
                  ? 'visible'
                  : 'none',
              }}
              images={imagesFinishLineStartPoint}
            >
              <Feature
                coordinates={finishStartPointMarker != null
                  ? [finishStartPointMarker.longitude, finishStartPointMarker.latitude]
                  : defaultLatLng}
                draggable={canEdit}
                onDragEnd={event => handleDragMarker(event, 'C')}
              />
            </Layer>
            <Layer
              type="symbol"
              id="imageFinishLineEndPoint_layer"
              layout={{
                'icon-image': 'imageFinishLineEndPoint',
                'icon-allow-overlap': true,
                'visibility': hasFinishLine
                  ? 'visible'
                  : 'none',
              }}
              images={imagesFinishLineEndPoint}
            >
            <Feature
              coordinates={finishEndPointMarker != null
                ? [finishEndPointMarker.longitude, finishEndPointMarker.latitude]
                : defaultLatLng}
              draggable={canEdit}
              onDragEnd={event => handleDragMarker(event, 'D')}
            />
          </Layer>
          {/*Finish Line bearing point*/}
          <Layer
            type="symbol"
            id="imageLineFinishBearingPoint_layer"
            layout={{
              'icon-image': 'imageLineFinishBearingPoint',
              'icon-allow-overlap': true,
              'icon-rotate': ['get', 'bearing'],
              'visibility': (hasFinishLine && finishLapLineBearing !== null)
                ? 'visible'
                : 'none',
            }}
            images={imagesLineFinishBearingPoint}
          >
            <Feature
              coordinates={[bearingFinishStartPoint.longitude, bearingFinishStartPoint.latitude]}
              draggable={false}
              properties={{bearing: finishLapLineBearing ?? 0}}
            />
          </Layer>
        </>}
        {/*Sector 1 - 20 Start Line points*/}
        {sectorsNumbers.map(i => {
          return (
          <React.Fragment key={`section_fragment_${i}`}>
            <Layer
              type="symbol"
              id={`imageMarkerSectionStartPoint${i}_layer`}
              layout={{
                'icon-image': `imageMarkerSectionStartPoint${i}`,
                'icon-allow-overlap': true,
                'visibility': (
                  sectors.length > i
                )
                  ? 'visible'
                  : 'none',
              }}
              images={imagesMarkerSectionStartPoint[i]}
            >
              <Feature
                coordinates={getSectorFromNumberAndPoint(sectors, i, 'start')}
                draggable={canEdit}
                onDragEnd={event => handleDragMarker(event, `SLS_${i}`)}
              />
            </Layer>
            <Layer
              type="symbol"
              id={`imageMarkerSectionEndPoint${i}_layer`}
              layout={{
                'icon-image': `imageMarkerSectionEndPoint${i}`,
                'icon-allow-overlap': true,
                'visibility': (
                  sectors.length > i
                )
                  ? 'visible'
                  : 'none',
              }}
              images={imagesMarkerSectionEndPoint[i]}
            >
              <Feature
                coordinates={getSectorFromNumberAndPoint(sectors, i, 'end')}
                draggable={canEdit}
                onDragEnd={event => handleDragMarker(event, `SLE_${i}`)}
              />
            </Layer>
          </React.Fragment>);
        })}

      </Map>
    );
  } catch (error) {
    console.error(error);
  }
};

TrackMapBox.defaultProps = {
  sectors: []
}

TrackMapBox.propTypes = {
  isMarkerShown: PropTypes.bool.isRequired,
  hasFinishLine: PropTypes.bool.isRequired,
  handleDragMarker: PropTypes.func.isRequired,
  trackMarker: PropTypes.object,
  startPointMarker: PropTypes.object,
  endPointMarker: PropTypes.object,
  finishStartPointMarker: PropTypes.object,
  finishEndPointMarker: PropTypes.object,
  handleMarker: PropTypes.func,
  sectors: PropTypes.array,
};

export default React.memo(TrackMapBox);

