import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import DateFnsUtils from '@date-io/date-fns';
import { setHours, setMinutes, setSeconds } from 'date-fns';
import enLocale from 'date-fns/locale/en-GB';
import frLocale from 'date-fns/locale/fr';
import Grid from '@material-ui/core/Grid';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import useTheme from '@material-ui/core/styles/useTheme';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import Tooltip from '@material-ui/core/Tooltip';
import GetAppIcon from '@material-ui/icons/GetApp';
import DeleteIcon from '@material-ui/icons/Delete';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { useStyles } from './AuthorizedExportRow.style';
import { AUTHORIZED_EXPORT_EDIT } from '../../../constants/routes';
import { ROLE_ADMIN } from '../../../constants/roles';
import MTCDialog from '../../Form/MTCDialog';
import {
  deleteAuthorizedExport,
  increaseAuthorizedExportCount, startAuthorizedExport,
} from '../../../store/authorizedExports/actions';
import ApiService from '../../../services/ApiService';
import i18next from '../../../i18n';

const localeMap = {
  en: enLocale,
  fr: frLocale,
};
const AuthorizedExportRow = ({ value }) => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const role = localStorage.getItem('role');
  const [openDialog, setOpenDialog] = useState(false);
  const [openExportDialog, setOpenExportDialog] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const { exportPending } = useSelector(state => state.authorizedExports);
  console.log(exportPending);
  // Handler
  const handleEdit = event => {
    if (event) {
      event.stopPropagation();
    }
    history.push(AUTHORIZED_EXPORT_EDIT.replace(':authorizedExportId', value.id));
  };
  const handleOpen = (event) => {
    event.stopPropagation();
    setOpenDialog(true);
  };
  const handleClose = event => {
    event.stopPropagation();
    setOpenDialog(false);
  };

  const handleOpenExport = (event) => {
    event.stopPropagation();
    setOpenExportDialog(true);
  };
  const handleCloseExport = event => {
    event.stopPropagation();
    setOpenExportDialog(false);
    setStartDate(null);
    setEndDate(null);
  };
  const handleRemove = () => {
    dispatch(deleteAuthorizedExport(value.id));
  };

  const handleExport = (event) => {
    event.stopPropagation();
    dispatch(startAuthorizedExport());
    let start = startDate
    if (start) {
      start = setHours(start, 0)
      start = setMinutes(start, 0)
      start = setSeconds(start, 0)
    }
    let end = endDate
    if (end) {
      end = setHours(end, 23)
      end = setMinutes(end, 59)
      end = setSeconds(end, 59)
    }
    ApiService.getExports(
      value.id,
      start
        ? Math.trunc(start.getTime() / 1000)
        : null,
      end
        ? Math.trunc(end.getTime() / 1000)
        : null,
    ).then(() => {
      dispatch(increaseAuthorizedExportCount(value));
    });
  };

  return (
    <TableRow
      className={classes.row}
      key={'r_' + value.id}
      hover
      tabIndex={-1}
    >
      <TableCell>{value.emails ?? '-'}</TableCell>
      <TableCell>{value.receiver_names ?? '-'}</TableCell>
      <TableCell align="right">{value.export_count ?? 0}</TableCell>
      <TableCell align="right">

        {value.status === 'OPEN' && <Tooltip title={t('download')}>
          <IconButton
            id="export"
            aria-label="export"
            onClick={handleOpenExport}
            className={classes.button}
          >
            <GetAppIcon />
          </IconButton>
        </Tooltip>}
        {role === ROLE_ADMIN && (
          <>
            <Tooltip title={t('edit')}>
              <IconButton
                id="edit"
                aria-label="update"
                onClick={handleEdit}
                className={classes.button}
              >
                <EditIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title={t('remove')}>
              <IconButton
                id="remove"
                aria-label="remove"
                onClick={handleOpen}
                color="primary"
                className={classes.button}
              >
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          </>
        )}
      </TableCell>
      <MTCDialog
        open={openDialog}
        handleClose={handleClose}
        dialogTitle={t('support:authorized_export_delete_dialog_title')}
        dialogText={t('support:authorized_export_delete_dialog_text')}
        handleCloseCancel={handleClose}
        handleCloseOK={handleRemove}
        cancelText={t('no')}
        okText={t('yes')}
      />
      <MTCDialog
        open={openExportDialog}
        handleClose={handleCloseExport}
        dialogTitle={t('support:export_dialog_title')}
        dialogText={t('support:export_dialog_text')}
        okPending={exportPending}
        textField={
          <MuiPickersUtilsProvider
            utils={DateFnsUtils}
            locale={localeMap[i18next.languages[0].split('-')[0]]}
          >
            <Grid container spacing={0}>
              <Grid item xs={12}>
                <KeyboardDatePicker
                  className={classes.input}
                  margin="normal"
                  id="start_date"
                  label={t('start_date')}
                  format="dd/MM/yyyy"
                  value={startDate}
                  onChange={date => setStartDate(date)}
                  KeyboardButtonProps={{
                    'aria-label': 'Start date',
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <KeyboardDatePicker
                  className={classes.input}
                  margin="normal"
                  id="end_date"
                  label={t('end_date')}
                  format="dd/MM/yyyy"
                  value={endDate}
                  onChange={date => setEndDate(date)}
                  KeyboardButtonProps={{
                    'aria-label': 'End date',
                  }}
                />
              </Grid>
            </Grid>
          </MuiPickersUtilsProvider>}
        handleCloseCancel={handleCloseExport}
        handleCloseOK={handleExport}
        cancelText={t('cancel')}
        okText={t('export')}
      />
    </TableRow>
  );
};

export default React.memo(AuthorizedExportRow);
