import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import useTheme from '@material-ui/core/styles/useTheme';
import Grid from '@material-ui/core/Grid';
import Avatar from '@material-ui/core/Avatar';
import { useStyles } from './InAppNotificationDetails.style';
import Typography from '@material-ui/core/Typography';
import MTCChip from '../../Layout/MTCChip';
import { Paper, Tooltip } from '@material-ui/core';
import MTCDetailLabel from '../../Layout/MTCDetailLabel';

const InAppNotificationDetails = ({ notification }) => {
  const theme = useTheme();
  const { t } = useTranslation(['inAppNotification', 'translations']);
  const classes = useStyles(theme);
  const { lang } = useSelector(state => state.contentLang);
  const tr = notification.translations[lang];

  return (
    <Grid
      container
      direction={'column'}
      className={classes.container}
      spacing={0}
    >
      <Grid item xs={12}>
        <Avatar
          alt={'notification picture'}
          src={notification.picture}
          className={classes.avatar}
        />
      </Grid>
      <Grid item xs={12}>
        <Typography
          component="p"
          variant="h3"
          className={classes.title}
        >
          {tr && tr.title}{' '}
          <MTCChip
            text={t(`state.${notification.state}`)}
            error={notification.state === 'expired' || notification.state === 'disabled'}
            success={notification.state === 'in_progress'}
          />
        </Typography>
        <Typography
          component="p"
          variant="subtitle1"
          className={classes.description}
        >
          {tr
          && <Tooltip title={tr.text}>
            <div dangerouslySetInnerHTML={{ __html: tr.text }} />
          </Tooltip>
          }
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <Grid container spacing={2} alignItems={'flex-start'} justifyContent={'center'}>
          <Grid item xs={12} lg={6}>
            <Paper className={classes.information}>
              <Typography
                component="p"
                variant="h4"
                color={'primary'}
                className={classes.title}
              >
                {t('triggers')}
              </Typography>
              <MTCDetailLabel
                label={t('user_type')}
                value={t('user_types.' + notification.user_type)}
              />
              <MTCDetailLabel
                label={t('user_has_expert_compatible_free_car')}
                value={t(notification.user_has_expert_compatible_free_car
                  ? 'translations:yes'
                  : 'translations:no')}
              />
              <MTCDetailLabel
                label={t('translations:country_code')}
                value={notification.country_codes || '-'}
              />
              <MTCDetailLabel label={t('device_os')} value={notification.device_os || '-'} />

              <MTCDetailLabel
                label={t('app_version')}
                value={notification.app_version
                  ? t(`app_version_conditions.${notification.app_version_condition}`)
                  + ' '
                  + notification.app_version
                  : '-'}
              />
            </Paper>
          </Grid>
          <Grid item xs={12} lg={6}>
            <Grid
              container
              direction="column"
              justifyContent={'flex-start'}
              alignItems={'flex-start'}
            >
              {(
                notification.has_scheduled_pushed || notification.has_been_pushed
              )
              && <Paper className={classes.information}>
                <Typography
                  component="p"
                  variant="h4"
                  color={'primary'}
                  className={classes.title}
                >
                  {t('push')}
                </Typography>
                <MTCDetailLabel
                  label={t('translations:push_title')}
                  value={tr && tr.push_title}
                />
                <MTCDetailLabel
                  label={t('translations:push_body')}
                  value={(tr && tr.push_body) || '-'}
                />
              </Paper>}
              <Paper className={classes.information}>
                <Typography
                  component="p"
                  variant="h4"
                  color={'primary'}
                  className={classes.title}
                >
                  {t('stats')}
                </Typography>
                <MTCDetailLabel
                  label={t('user_count')}
                  value={notification.user_count}
                />
                <MTCDetailLabel
                  label={t('view_count')}
                  value={notification.user_count > 0 ? (
                    100 * notification.view_count / notification.user_count
                  ).toFixed(2) + '%' : '-'}
                />
                {notification.has_call_to_action && <MTCDetailLabel
                  label={t('click_count')}
                  value={notification.user_count > 0 ? (
                    100 * notification.click_count / notification.user_count
                  ).toFixed(2) + '%' : '-'}
                />}
              </Paper>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default React.memo(InAppNotificationDetails);
