import {
  CAR_FAILED,
  CAR_PENDING,
  CAR_RESET,
  CAR_TABLE_LIMIT_CHANGE,
  CARS_BOUNDARIES_DOWNLOADED,
  CARS_BOUNDARIES_PENDING,
  CARS_BRANDS_DOWNLOADED,
  CARS_BRANDS_PENDING,
  CARS_CSV_DOWNLOADED,
  CARS_DIMENSIONS_DOWNLOADED,
  CARS_DIMENSIONS_PENDING,
  CARS_ENGINES_DOWNLOADED,
  CARS_ENGINES_PENDING,
  CARS_MODELS_DOWNLOADED,
  CARS_MODELS_PENDING,
  CARS_RANGES_DOWNLOADED,
  CARS_RANGES_PENDING,
  CHANGE_SEARCH_CAR_STATE,
  CHANGE_SEARCH_IS_PUBLIC,
  CHANGE_SEARCH_MODE,
  CHANGE_SEARCH_TIRE_TYPE,
} from './types';
import ApiService from '../../services/ApiService';
import { handleError } from '../infos/actions';

export const carPendingAction = () => (
  { type: CAR_PENDING }
);
export const carsCsvDownloadedAction = () => (
  { type: CARS_CSV_DOWNLOADED }
);
export const carsBrandsPendingAction = () => (
  { type: CARS_BRANDS_PENDING }
);
export const carsRangesPendingAction = () => (
  { type: CARS_RANGES_PENDING }
);
export const carsModelsPendingAction = () => (
  { type: CARS_MODELS_PENDING }
);
export const carsEnginesPendingAction = () => (
  { type: CARS_ENGINES_PENDING }
);
export const carsDimensionsPendingAction = () => (
  { type: CARS_DIMENSIONS_PENDING }
);
export const carsBoundariesPendingAction = () => (
  { type: CARS_BOUNDARIES_PENDING }
);
export const ChangeSelectedModeAction = (mode) => (
  {
    type: CHANGE_SEARCH_MODE,
    payload: mode ,
  }
);
export const ChangeSelectedTireTypeAction = (tireType) => (
  {
    type: CHANGE_SEARCH_TIRE_TYPE,
    payload: tireType,
  }
);
export const ChangeSelectedIsPublicAction = (isPublic) => (
  {
    type: CHANGE_SEARCH_IS_PUBLIC,
    payload: isPublic,
  }
);
export const ChangeSelectedCarStateAction = (carState) => (
  {
    type: CHANGE_SEARCH_CAR_STATE,
    payload: carState,
  }
);
export const carsBrandsDownloadedAction = (data) => (
  {
    type: CARS_BRANDS_DOWNLOADED,
    payload: { data },
  }
);
export const carTableLimitChangeAction = (data) => (
  {
    type: CAR_TABLE_LIMIT_CHANGE,
    payload: data,
  }
);
export const carsRangesDownloadedAction = (data, id) => (
  {
    type: CARS_RANGES_DOWNLOADED,
    payload: {
      data,
      id,
    },
  }
);
export const carsModelsDownloadedAction = (data, id) => (
  {
    type: CARS_MODELS_DOWNLOADED,
    payload: {
      data,
      id,
    },
  }
);
export const carsEnginesDownloadedAction = (data, id) => (
  {
    type: CARS_ENGINES_DOWNLOADED,
    payload: {
      data,
      id,
    },
  }
);
export const carsDimensionsDownloadedAction = (data, id) => (
  {
    type: CARS_DIMENSIONS_DOWNLOADED,
    payload: {
      data,
      id,
    },
  }
);
export const carsBoundariesDownloadedAction = (data, id) => (
  {
    type: CARS_BOUNDARIES_DOWNLOADED,
    payload: {
      data,
      id,
    },
  }
);
export const carsFailedAction = error => (
  {
    type: CAR_FAILED,
    payload: error,
  }
);
export const carsResetAction = () => (
  { type: CAR_RESET }
);

export function reset() {
  return (dispatch) => {
    dispatch(carsResetAction());
  };
}

export function setSelectedMode(mode) {
  return (dispatch) => {
    dispatch(ChangeSelectedModeAction(mode));
  };
}

export function setSelectedTireType(tireType) {
  return (dispatch) => {
    dispatch(ChangeSelectedTireTypeAction(tireType));
  };
}

export function setSelectedIsPublic(isPublic) {
  return (dispatch) => {
    dispatch(ChangeSelectedIsPublicAction(isPublic));
  };
}

export function setSelectedCarState(carState) {
  return (dispatch) => {
    dispatch(ChangeSelectedCarStateAction(carState));
  };
}

export function changeLimit(limit) {
  return (dispatch) => {
    dispatch(carTableLimitChangeAction(limit));
  };
}

export function getBrandsCars() {
  return (dispatch) => {
    dispatch(carsBrandsPendingAction());
    return ApiService
      .getCarsBrands()
      .then((data) => {
        dispatch(carsBrandsDownloadedAction(data));
      })
      .catch((error) => {
        dispatch(carsFailedAction(error));
        dispatch(handleError(error));
      });
  };
}

export function getRangesCars(brandId) {
  return (dispatch) => {
    dispatch(carsRangesPendingAction());
    return ApiService
      .getCarsRanges(brandId)
      .then((data) => {
        dispatch(carsRangesDownloadedAction(data, brandId));
        if (data.length === 1) {
          dispatch(getModelsCars(brandId, data[0].id));
        }
      })
      .catch((error) => {
        dispatch(carsFailedAction(error));
        dispatch(handleError(error));
      });
  };
}

export function getModelsCars(brandId, rangeId) {
  return (dispatch) => {
    dispatch(carsModelsPendingAction());
    return ApiService
      .getCarsModels(brandId, rangeId)
      .then((data) => {
        dispatch(carsModelsDownloadedAction(data, rangeId));
        if (data.length === 1) {
          dispatch(getEnginesCars(brandId, rangeId, data[0].id));
        }
      })
      .catch((error) => {
        dispatch(carsFailedAction(error));
        dispatch(handleError(error));
      });
  };
}

export function getEnginesCars(brandId, rangeId, modelId) {
  return (dispatch) => {
    dispatch(carsEnginesPendingAction());
    return ApiService
      .getCarsEngines(brandId, rangeId, modelId)
      .then((data) => {
        dispatch(carsEnginesDownloadedAction(data, modelId));
        if (data.length === 1) {
          dispatch(getDimensionsCars(brandId, rangeId, modelId, data[0].id));
        }
      })
      .catch((error) => {
        dispatch(carsFailedAction(error));
        dispatch(handleError(error));
      });
  };
}

export function getDimensionsCars(brandId, rangeId, modelId, engineId) {
  return (dispatch) => {
    dispatch(carsDimensionsPendingAction());
    return ApiService
      .getCarsDimensions(brandId, rangeId, modelId, engineId)
      .then((data) => {
        dispatch(carsDimensionsDownloadedAction(data, engineId));
        if (data.length === 1) {
          dispatch(getBoundariesCars(brandId, rangeId, modelId, engineId, data[0].id));
        }
      })
      .catch((error) => {
        dispatch(carsFailedAction(error));
        dispatch(handleError(error));
      });
  };
}

export function getBoundariesCars(brandId, rangeId, modelId, engineId, dimensionId) {
  return (dispatch) => {
    dispatch(carsBoundariesPendingAction());
    return ApiService
      .getCarsBoundaries(brandId, rangeId, modelId, engineId, dimensionId)
      .then((data) => {
        dispatch(carsBoundariesDownloadedAction(data, dimensionId));
      })
      .catch((error) => {
        dispatch(carsFailedAction(error));
        dispatch(handleError(error));
      });
  };
}

export function getCarsCsv(
  brandId,
  rangeId,
  modelId,
  engineId,
  dimensionId,
  mode,
  tireType,
  isPublic,
  carState,
)
{
  return (dispatch) => {
    dispatch(carPendingAction());
    return ApiService
      .getCarsCsv(brandId,
        rangeId,
        modelId,
        engineId,
        dimensionId,
        mode,
        tireType,
        isPublic,
        carState,
      )
      .then(() => {
        dispatch(carsCsvDownloadedAction());
      })
      .catch((error) => {
        dispatch(carsFailedAction(error));
        dispatch(handleError(error));
      });
  };
}