import React from 'react';
import PropTypes from 'prop-types';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import makeStyles from '@material-ui/core/styles/makeStyles';
import useTheme from '@material-ui/core/styles/useTheme';
import { useHistory } from 'react-router';
import { FIRMWARE_GROUP } from '../../../constants/routes';
import { MMCBLE, MMCRF, MTCBLE, MTCRF } from '../../../constants/firmwareTypes';
import { clickableRowStyle } from '../../../constants/styles/row.style';

const useStyles = makeStyles(theme => ({ row: clickableRowStyle(theme)}));

const FirmwareGroupRow = ({ value }) => {

  const theme = useTheme();
  const classes = useStyles(theme);
  const history = useHistory();
  const handleClick = (event) => {
    event.preventDefault();
    event.stopPropagation();
    history.push(FIRMWARE_GROUP.replace(':groupId', value.id));
  }

  let mtcBle = '';
  let mtcRf = '';
  let mmcBle = '';
  let mmcRf = '';
  if (value.firmwares !== undefined && value.firmwares !== null) {
    value.firmwares.forEach(firmware => {
      switch (firmware.type) {
        case MTCBLE:
          mtcBle = firmware.version;
          break;
        case MTCRF:
          mtcRf = firmware.version;
          break;
        case MMCBLE:
          mmcBle = firmware.version;
          break;
        case MMCRF:
          mmcRf = firmware.version;
          break;
        default:
          console.error("Wrong firmware type : " + firmware.type);
          break;
      }
    });
  }

  return (
    <>
      <TableRow
        className={classes.row}
        key={value.id}
        tabIndex={-1}
        hover
        onClick={handleClick}
      >
        <TableCell>{value.name}</TableCell>
        <TableCell>{value.item_count}</TableCell>
        <TableCell>{mtcBle}</TableCell>
        <TableCell>{mtcRf}</TableCell>
        <TableCell>{mmcBle}</TableCell>
        <TableCell>{mmcRf}</TableCell>
      </TableRow>
    </>
  );
};

FirmwareGroupRow.propTypes = {
  value: PropTypes.object.isRequired
};

export default React.memo(FirmwareGroupRow);
