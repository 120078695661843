import React from 'react';
import { Link } from 'react-router-dom';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import { RedChip } from '../RedChip/RedChip';

const MTCMenuItem = props => {

  const {
    path,
    pathname,
    value,
    handleCloseDrawer,
    subpath,
    alertCount
  } = props;

  return (
    <MenuItem
      component={Link}
      to={path}
      selected={
        pathname.startsWith(path)
        || (
          subpath && pathname.startsWith(subpath)
        )
      }
      value={value}
      onClick={handleCloseDrawer}
    >
      <Typography variant={'subtitle1'}>
        {value}{alertCount > 0 && (<>{' '}<RedChip count={alertCount} /></>)}
      </Typography>
    </MenuItem>
  );
};

MTCMenuItem.propTypes = {
  path: PropTypes.string.isRequired,
  pathname: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  handleCloseDrawer: PropTypes.func,
  subpath: PropTypes.string,
  alertCount: PropTypes.number,
};
MTCMenuItem.defaultProps = {
  alertCount: 0,
  subpath: null,
  handleCloseDrawer: null
}

export default React.memo(MTCMenuItem);
