import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import { ROLE_ADMIN } from '../../../constants/roles';
import withAuthentication from '../../../hoc/withAuthentication';
import { setTitle } from '../../../store/title/actions';
import AddCarForm from '../../../components/Cars/Form/AddCarForm';
import {
  getBrandsCars,
  getDimension,
  getDimensionsCars,
  getEnginesCars,
  getModelsCars,
  getRangesCars,
} from '../../../store/formCars/actions';
import MTCLoader from '../../../components/Layout/MTCLoader';

export const DuplicateCarPage = () => {
  const { title } = useSelector(state => state.title);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  let { dimensionId } = useParams();
  const [loading, setLoading] = useState(true);
  const [loadingApi, setLoadingApi] = useState(false);
  const {
    dimension,
    dimension_pending,
    error,
  } = useSelector(state => state.formCars);

  if (!loading && dimension_pending) {
    setLoading(true);
  }

  if (
    !dimension_pending
    && !error
    && (!dimension || dimension.dimension.id != dimensionId)
  ) {
    setLoadingApi(true);
    dispatch(getDimension(dimensionId)).then(async (data) =>
    {
      await dispatch(getBrandsCars());
      await dispatch(getRangesCars(data.brand.id));
      await dispatch(getModelsCars(data.brand.id, data.range.id));
      await dispatch(getEnginesCars(data.brand.id, data.range.id, data.range_model.id));
      await dispatch(getDimensionsCars(
        data.brand.id,
        data.range.id,
        data.range_model.id,
        data.engine.id,
      ));
      setLoading(false);
      setLoadingApi(false);
    });
  } else if (
    loading
    && !loadingApi
    && dimension
    && dimension.dimension.id == dimensionId
  ) {
    setLoading(false);
  }

  if (title !== t('title:title.duplicate_car_page')) {
    dispatch(setTitle(t('title:title.duplicate_car_page')));
  }

  if (loading) {
    return <MTCLoader />;
  } else {
    return <AddCarForm duplicatedCar={dimension} />;
  }
};

export default withAuthentication(ROLE_ADMIN)(DuplicateCarPage);
