import {
  RECEIVER_MTC_BLE_DOWNLOADED,
  RECEIVER_MTC_BLE_FAILED,
  RECEIVER_MTC_BLE_SENDING, RECEIVERS_MTC_BLE_DELETED,
  RECEIVERS_MTC_BLE_DOWNLOADED,
  RECEIVERS_MTC_BLE_PENDING,
} from './types';
import ApiService from '../../services/ApiService';
import { handleError } from '../infos/actions';

export const receiverMtcBleSendingAction = () => ({
  type: RECEIVER_MTC_BLE_SENDING,
});

export const receiverMtcBleDownloadedAction = receiver => ({
  type: RECEIVER_MTC_BLE_DOWNLOADED,
  payload: receiver,
});

export const receiversMtcBleDownloadedAction = receivers => ({
  type: RECEIVERS_MTC_BLE_DOWNLOADED,
  payload: receivers,
});

export const receiverMtcBleDeletedAction = id => ({
  type: RECEIVERS_MTC_BLE_DELETED,
  payload: id,
});

export const receiverMtcBleFailedAction = error => ({
  type: RECEIVER_MTC_BLE_FAILED,
  payload: error,
});

export const receiversMtcBlePendingAction = () => ({
  type: RECEIVERS_MTC_BLE_PENDING,
});

export function getReceiversMtcBle() {
  return (dispatch) => {
    dispatch(receiversMtcBlePendingAction());
    return ApiService
      .getMtcReceiversBle()
      .then((data) => {
        dispatch(receiversMtcBleDownloadedAction(data));
      })
      .catch((error) => {
        dispatch(receiverMtcBleFailedAction(error));
        dispatch(handleError(error));
      });
  };
}

export function postReceiverMtcBle(receiver) {
  return (dispatch) => {
    dispatch(receiverMtcBleSendingAction());
    return ApiService
      .postMtcReceiverBle(receiver)
      .then((data) => {
        dispatch(receiverMtcBleDownloadedAction(data));
        return data;
      })
      .catch((error) => {
        dispatch(receiverMtcBleFailedAction(error));
        if (error.response && error.response.status === 409) {
          error.response.statusText = 'Conflict: File already exists on server';
        }
        dispatch(handleError(error));
      });
  };
}

export function putReceiverMtcBle(receiver) {
  return (dispatch) => {
    dispatch(receiverMtcBleSendingAction());
    return ApiService
      .putMtcReceiverBle(receiver)
      .then((data) => {
        dispatch(receiverMtcBleDownloadedAction(data));
      })
      .catch((error) => {
        dispatch(receiverMtcBleFailedAction(error));
        dispatch(handleError(error));
      });
  };
}

export function deleteReceiverMtcBle(id) {
  return (dispatch) => {
    dispatch(receiverMtcBleSendingAction());
    return ApiService
      .deleteMtcReceiverBle(id)
      .then(() => {
        dispatch(receiverMtcBleDeletedAction(id));
      })
      .catch((error) => {
        dispatch(receiverMtcBleFailedAction(error));
        dispatch(handleError(error));
      });
  };
}