import makeStyles from '@material-ui/core/styles/makeStyles';

export const useStyles = makeStyles((theme) => {
  return {
    number: {
      fontSize: "72px",
      fontWeight: 800,
    },
    title: {
      fontSize: "24px",
      fontWeight: 600,
      marginTop: '0.7em',
      marginBottom: '0.5em',
    }
  }
});
