import i18n from 'i18next';
import XHR from 'i18next-xhr-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import translationEn from './locales/en/translation.json';
import translationFr from './locales/fr/translation.json';
import langFr from './locales/fr/lang.json';
import langEn from './locales/en/lang.json';
import storeFr from './locales/fr/store.json';
import storeEn from './locales/en/store.json';
import titleFr from './locales/fr/title.json';
import titleEn from './locales/en/title.json';
import inAppNotificationFr from './locales/fr/inAppNotification.json';
import inAppNotificationEn from './locales/en/inAppNotification.json';
import kpiFr from './locales/fr/kpi.json';
import kpiEn from './locales/en/kpi.json';
import supportFr from './locales/fr/support.json';
import supportEn from './locales/en/support.json';
import circuitEolAt from './locales/at/circuitEol.json';
import circuitEolDe from './locales/de/circuitEol.json';
import circuitEolEn from './locales/en/circuitEol.json';
import circuitEolEs from './locales/es/circuitEol.json';
import circuitEolFr from './locales/fr/circuitEol.json';
import circuitEolIt from './locales/it/circuitEol.json';
import circuitEolNl from './locales/nl/circuitEol.json';
import circuitEolPt from './locales/pt/circuitEol.json';
import circuitEolRu from './locales/ru/circuitEol.json';
import circuitEolUs from './locales/us/circuitEol.json';
import circuitEolZh from './locales/zh/circuitEol.json';

i18n.use(XHR)
  .use(LanguageDetector)
  .init({
    fallbackLng: 'fr',
    debug: false,
    ns: ['translations', 'lang', 'store', 'title', 'inAppNotification', 'kpi'],
    defaultNS: 'translations',
    interpolation: {
      escapeValue: false, // not needed for react!!
      formatSeparator: ',',
    },
    react: {
      useSuspense: false,
    },
    resources: {
      fr: {
        translations: translationFr,
        lang: langFr,
        store: storeFr,
        title: titleFr,
        inAppNotification: inAppNotificationFr,
        kpi: kpiFr,
        support: supportFr,
        circuitEol: circuitEolFr,
      },
      en: {
        translations: translationEn,
        lang: langEn,
        store: storeEn,
        title: titleEn,
        inAppNotification: inAppNotificationEn,
        kpi: kpiEn,
        support: supportEn,
        circuitEol: circuitEolEn,
      },
      us: {
        translations: translationEn,
        lang: langEn,
        store: storeEn,
        title: titleEn,
        inAppNotification: inAppNotificationEn,
        kpi: kpiEn,
        support: supportEn,
        circuitEol: circuitEolUs,
      },
      es: {
        translations: translationEn,
        lang: langEn,
        store: storeEn,
        title: titleEn,
        inAppNotification: inAppNotificationEn,
        kpi: kpiEn,
        support: supportEn,
        circuitEol: circuitEolEs,
      },
      it: {
        translations: translationEn,
        lang: langEn,
        store: storeEn,
        title: titleEn,
        inAppNotification: inAppNotificationEn,
        kpi: kpiEn,
        support: supportEn,
        circuitEol: circuitEolIt,
      },
      de: {
        translations: translationEn,
        lang: langEn,
        store: storeEn,
        title: titleEn,
        inAppNotification: inAppNotificationEn,
        kpi: kpiEn,
        support: supportEn,
        circuitEol: circuitEolDe,
      },
      at: {
        translations: translationEn,
        lang: langEn,
        store: storeEn,
        title: titleEn,
        inAppNotification: inAppNotificationEn,
        kpi: kpiEn,
        support: supportEn,
        circuitEol: circuitEolAt,
      },
      nl: {
        translations: translationEn,
        lang: langEn,
        store: storeEn,
        title: titleEn,
        inAppNotification: inAppNotificationEn,
        kpi: kpiEn,
        support: supportEn,
        circuitEol: circuitEolNl,
      },
      zh: {
        translations: translationEn,
        lang: langEn,
        store: storeEn,
        title: titleEn,
        inAppNotification: inAppNotificationEn,
        kpi: kpiEn,
        support: supportEn,
        circuitEol: circuitEolZh,
      },
      pt: {
        translations: translationEn,
        lang: langEn,
        store: storeEn,
        title: titleEn,
        inAppNotification: inAppNotificationEn,
        kpi: kpiEn,
        support: supportEn,
        circuitEol: circuitEolPt,
      },
      ru: {
        translations: translationEn,
        lang: langEn,
        store: storeEn,
        title: titleEn,
        inAppNotification: inAppNotificationEn,
        kpi: kpiEn,
        support: supportEn,
        circuitEol: circuitEolRu,
      },
    },
    detection: {
      // order and from where user language should be detected
      order: ['querystring', 'navigator',  'cookie', 'localStorage','htmlTag', 'subdomain', 'path'],

      // keys or params to lookup language from
      lookupQuerystring: 'lang',
      checkWhitelist: true
    }
  });

export default i18n;
