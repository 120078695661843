import {
  CONTACT_DELETED,
  CONTACT_SENDING,
  CONTACTS_DOWNLOADED,
  CONTACT_DOWNLOADED,
  CONTACTS_FAILED,
  CONTACTS_PENDING,
} from './types';
import ApiService from '../../services/ApiService';
import { handleError } from '../infos/actions';

export const contactsDownloadedAction = contacts => (
  {
    type: CONTACTS_DOWNLOADED,
    payload: contacts,
  }
);

export const contactDownloadedAction = (contact) => (
  {
    type: CONTACT_DOWNLOADED,
    payload: contact,
  }
);

export const contactsFailedAction = error => (
  {
    type: CONTACTS_FAILED,
    payload: error,
  }
);

export const contactsPendingAction = () => (
  {
    type: CONTACTS_PENDING,
  }
);

export const contactSendingAction = () => (
  {
    type: CONTACT_SENDING,
  }
);

export const contactDeletedAction = (type, id) => (
  {
    type: CONTACT_DELETED,
    payload: {
      type,
      id,
    },
  }
);

export function getContacts() {
  return (dispatch) => {
    dispatch(contactsPendingAction());
    return ApiService
      .getContacts()
      .then((data) => {
        dispatch(contactsDownloadedAction(data));
      })
      .catch((error) => {
        dispatch(contactsFailedAction(error));
        dispatch(handleError(error));
      });
  };
}

export function postContacts(contact) {
  return (dispatch) => {
    dispatch(contactsPendingAction());
    return ApiService
      .postContact(contact)
      .then((data) => {
        dispatch(contactDownloadedAction(data));
      })
      .catch((error) => {
        dispatch(contactsFailedAction(error));
        dispatch(handleError(error));
      });
  };
}

export function putContacts(contact) {
  return (dispatch) => {
    dispatch(contactsPendingAction());
    return ApiService
      .putContact(contact)
      .then((data) => {
        dispatch(contactDownloadedAction(data));
      })
      .catch((error) => {
        dispatch(contactsFailedAction(error));
        dispatch(handleError(error));
      });
  };
}

export function deleteContact(type, id) {
  return (dispatch) => {
    dispatch(contactSendingAction());
    return ApiService
      .deleteContact(type, id)
      .then(({ type, id }) => {
        dispatch(contactDeletedAction(type, id));
      })
      .catch((error) => {
        dispatch(contactsFailedAction(error));
        dispatch(handleError(error));
      });
  };
}