import {
  RALLY_CAR_DOWNLOADED,
  RALLY_CAR_SENDING,
  RALLY_CARS_DOWNLOADED,
  RALLY_CARS_FAILED,
  RALLY_CARS_PENDING,
  RALLY_CARS_RESET,
} from './types';
import ApiService from '../../services/ApiService';
import { handleError } from '../infos/actions';

export const rallyCarSendingAction = () => (
  {
    type: RALLY_CAR_SENDING,
  }
);
export const rallyCarDownloadedAction = rallyCar => (
  {
    type: RALLY_CAR_DOWNLOADED,
    payload: rallyCar,
  }
);
export const rallyCarsDownloadedAction = rallyCars => (
  {
    type: RALLY_CARS_DOWNLOADED,
    payload: rallyCars,
  }
);
export const rallyCarsFailedAction = error => (
  {
    type: RALLY_CARS_FAILED,
    payload: error,
  }
);
export const rallyCarsPendingAction = () => (
  {
    type: RALLY_CARS_PENDING,
  }
);
export const rallyCarsResetAction = () => (
  {
    type: RALLY_CARS_RESET,
  }
);

export function formReset() {
  return (dispatch) => {
    dispatch(rallyCarsResetAction());
  };
}

export function getRallyCars() {
  return (dispatch) => {
    dispatch(rallyCarsPendingAction());
    return ApiService
      .getRallyCars()
      .then((data) => {
        dispatch(rallyCarsDownloadedAction(data));
      })
      .catch((error) => {
        dispatch(rallyCarsFailedAction(error));
        dispatch(handleError(error));
      });
  };
}

export function postRallyCar(rallyCar) {
  return (dispatch) => {
    dispatch(rallyCarSendingAction());
    return ApiService
      .postRallyCar(rallyCar)
      .then((data) => {
        dispatch(rallyCarDownloadedAction(data));
        return data;
      })
      .catch((error) => {
        dispatch(rallyCarsFailedAction(error));
        dispatch(handleError(error));
      });
  };
}

export function putRallyCar(rallyCar) {
  return (dispatch) => {
    dispatch(rallyCarSendingAction());
    return ApiService
      .putRallyCar(rallyCar)
      .then((data) => {
        dispatch(rallyCarDownloadedAction(data));
        return data;
      })
      .catch((error) => {
        dispatch(rallyCarsFailedAction(error));
        dispatch(handleError(error));
      });
  };
}