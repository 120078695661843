import makeStyles from '@material-ui/core/styles/makeStyles';

export const useStyles = makeStyles((theme) => {
  return {
    list: {
      overflow: "auto",
      height: 'calc(100vh - 170px)',
      width: '75%',
      [theme.breakpoints.up('lg')]: {
        width: '85%',
      },
      [theme.breakpoints.down('sm')]: {
        width: '100%',
        height: "calc(100vh - 100px)"
      },
    }
  };
});
