import {
  BACK_CHANGE, SHOW_LANG_MENU,
  TITLE_CHANGE,
} from './types';

export const titleChangeAction = title => ({
  type : TITLE_CHANGE,
  payload: title,
});

export const backChangeAction = (back, params) => ({
  type : BACK_CHANGE,
  payload: {path: back, params},
});

export const showLangMenuAction = () => ({
  type : SHOW_LANG_MENU
});

export function setTitle(title) {
  return (dispatch) => {
    dispatch(titleChangeAction(title));
    return true;
  }
}
export function setBack(back, params) {
  return (dispatch) => {
    dispatch(backChangeAction(back, params));
    return true;
  }
}
export function showLangMenu() {
  return (dispatch) => {
    dispatch(showLangMenuAction());
    return true;
  }
}