export const supportedPictureDropFiles = [
  'image/bmp',
  'image/x-ms-bmp',
  'image/png',
  'image/x-icon',
  'image/vnd.microsoft.icon',
  'image/jpeg',
  'image/gif',
  'image/svg+xml',
].toString();

export const supportedPdfDropFiles = [
  'application/pdf',
  'application/acrobat',
  'application/vnd.pdf',
  'application/x-pdf'
].toString();

export const supportedZipDropFiles = [
  'application/zip',
  'application/x-zip-compressed'
].toString();

export const supportedBinDropFiles = [
  'application/octet-stream',
  'application/macbinary',
  'application/x-macbinary',
].toString();
