import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import MTCTable from '../Layout/Table/MTCTable';
import { getCallCenters } from '../../store/callCenters/actions';
import MTCLoader from '../Layout/MTCLoader';
import CallCenterRow from './CallCenterRow';
import AddButton from '../Layout/Button/AddButton';
import { CALL_CENTERS_ADD } from '../../constants/routes';
import Typography from '@material-ui/core/Typography';
import { columns, defaultOrderBy } from './CallCentersTable.const';

const CallCentersTable = () => {

  const { pending, content, error } = useSelector(state => state.callCenters);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  if (!content && !pending && !error) {
    dispatch(getCallCenters());
  }

  if (pending) {
    return <MTCLoader />;
  } else {
    return (
      <>
        <Typography
          gutterBottom
          align="center"
          variant="h5"
          component="h2"
        >
          {t('call_center_subtitle')}
        </Typography>
        <MTCTable
          content={content}
          defaultOrderBy={defaultOrderBy}
          rowComponent={CallCenterRow}
          columns={columns}
          paginationLabel={t('call_center_per_page')}
          emptyDataLabel={t('no_call_center')}
        />
        <AddButton path={CALL_CENTERS_ADD} />
      </>
    );
  }
}

export default CallCentersTable;
