import {
  FAQ_ARTICLE_DOWNLOADED,
  FAQ_ARTICLE_SENDING,
  FAQ_ARTICLES_DOWNLOADED,
  FAQ_ARTICLES_FAILED,
  FAQ_ARTICLES_PENDING,
  FAQ_ARTICLE_DELETED,
} from './types';
import ApiService from '../../services/ApiService';
import { handleError } from '../infos/actions';

export const faqArticlesPendingAction = () => (
  {
    type: FAQ_ARTICLES_PENDING,
  }
);

export const faqArticleSendingAction = () => (
  {
    type: FAQ_ARTICLE_SENDING,
  }
);

export const faqArticlesDownloadedAction = (articles) => (
  {
    type: FAQ_ARTICLES_DOWNLOADED,
    payload: articles,
  }
);

export const faqArticleDownloadedAction = (article) => (
  {
    type: FAQ_ARTICLE_DOWNLOADED,
    payload: article,
  }
);

export const faqArticlesFailedAction = (error) => (
  {
    type: FAQ_ARTICLES_FAILED,
    payload: error,
  }
);

export const faqArticleDeletedAction = (article) => (
  {
    type: FAQ_ARTICLE_DELETED,
    payload: article,
  }
);

export function getFaqArticles() {
  return (dispatch) => {
    dispatch(faqArticlesPendingAction());
    return ApiService.getFaqArticles()
      .then((data) => {
        dispatch(faqArticlesDownloadedAction(data));
      }).catch((error) => {
        dispatch(faqArticlesFailedAction(error));
        dispatch(handleError(error));
      });
  };
}

export function postFaqArticle(article) {
  return (dispatch) => {
    dispatch(faqArticleSendingAction());
    return ApiService.postFaqArticles(article)
      .then((data) => {
        dispatch(faqArticleDownloadedAction(data));
        return data;
      }).catch((error) => {
        dispatch(faqArticlesFailedAction(error));
        dispatch(handleError(error));
      });
  };
}

export function deleteFaqArticle(article) {
  return (dispatch) => {
    dispatch(faqArticleSendingAction());
    return ApiService.deleteFaqArticles(article.id)
      .then(() => {
        dispatch(faqArticleDeletedAction(article));
      }).catch((error) => {
        dispatch(faqArticlesFailedAction(error));
        dispatch(handleError(error));
      });
  };
}
export function deleteFaqArticleById(articleId) {
  return (dispatch) => {
    dispatch(faqArticleSendingAction());
    return ApiService.deleteFaqArticles(articleId)
      .then(() => {
        dispatch(faqArticleDeletedAction(articleId));
      }).catch((error) => {
        dispatch(faqArticlesFailedAction(error));
        dispatch(handleError(error));
      });
  };
}

export function putFaqArticle(id, article) {
  return (dispatch) => {
    dispatch(faqArticleSendingAction());
    return ApiService.putFaqArticles(id, article)
      .then((data) => {
        dispatch(faqArticleDownloadedAction(data));
        return data;
      }).catch((error) => {
        dispatch(faqArticlesFailedAction(error));
        dispatch(handleError(error));
      });
  };
}
