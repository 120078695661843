import React from 'react';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { useTranslation } from 'react-i18next';

const initialState = {
  mouseX: null,
  mouseY: null,
};

const CarContextMenu = (props) => {
  const {
    x,
    y,
    mainAction,
    editAction,
    duplicateAction,
    mainText,
    handleClose
  } = props;
  const [state, setState] = React.useState(initialState);
  const { t } = useTranslation();
  if (x && state.mouseX === null) {
    setState({
      mouseX: x - 2,
      mouseY: y - 4,
    });
  } else if (!x && state.mouseX) {
    setState(initialState);
  }

  const handleActionAndClose = (action) => {
      action();
      handleClose();
  };

  return (
    <Menu
      open={state.mouseY !== null}
      onClose={handleClose}
      anchorReference="anchorPosition"
      anchorPosition={
        state.mouseY !== null && state.mouseX !== null
          ? {
            top: state.mouseY,
            left: state.mouseX,
          }
          : undefined
      }
    >
      {mainAction
      && <MenuItem onClick={() => handleActionAndClose(mainAction)}>
        {mainText}
      </MenuItem>}
      {editAction
      && <MenuItem onClick={() => handleActionAndClose(editAction)}>
        {t('edit')}
      </MenuItem>}
      {duplicateAction
      && <MenuItem onClick={() => handleActionAndClose(duplicateAction)}>
        {t('duplicate')}
      </MenuItem>}
    </Menu>
  );
};

export default CarContextMenu;