import {
  TESTER_DELETED,
  TESTER_DOWNLOADED,
  TESTER_SENDING,
  TESTERS_DOWNLOADED,
  TESTERS_FAILED,
  TESTERS_PENDING,
} from './types';
import ApiService from '../../services/ApiService';
import { ERROR } from '../infos/types';

export const testerSendingAction = () => ({
  type: TESTER_SENDING,
});

export const testerDownloadedAction = tester => ({
  type: TESTER_DOWNLOADED,
  payload: tester,
});

export const testersDownloadedAction = testers => ({
  type: TESTERS_DOWNLOADED,
  payload: testers,
});

export const testersDeletedAction = email => ({
  type: TESTER_DELETED,
  payload: email,
});

export const testersFailedAction = error => ({
  type: TESTERS_FAILED,
  payload: error,
});

export const testersErrorAction = error => ({
  type: ERROR,
  payload: error,
});

export const testersPendingAction = () => ({
  type: TESTERS_PENDING,
});

export function getTesters() {
  return (dispatch) => {
    dispatch(testersPendingAction());
    return ApiService
      .getTesters()
      .then((data) => {
        dispatch(testersDownloadedAction(data));
      })
      .catch((error) => {
        dispatch(testersFailedAction(error));
      });
  };
}

export function postTester(tester) {
  return (dispatch) => {
    dispatch(testerSendingAction());
    return ApiService
      .postTester(tester)
      .then((data) => {
        localStorage.setItem('in_db', 'inDB');
        dispatch(testerDownloadedAction(data));
      })
      .catch((error) => {
        dispatch(testersErrorAction(error.response.data.message));
        dispatch(testersFailedAction(error));
      });
  };
}

export function deleteTester(email) {
  return (dispatch) => {
    dispatch(testerSendingAction());
    return ApiService
      .deleteTester(email)
      .then(() => {
        localStorage.setItem('in_db', 'inDB');
        dispatch(testersDeletedAction(email));
      })
      .catch((error) => {
        dispatch(testersErrorAction(error.response.data.message));
        dispatch(testersFailedAction(error));
      });
  };
}
