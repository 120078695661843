import React, { useState } from 'react';
import { Fab, Menu, MenuItem } from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import {
  FIRMWARE_GROUP_EDIT,
  FIRMWARE_GROUP_FIRMWARE_ADD,
  FIRMWARE_GROUP_ITEM_ADD,
  RECEIVER,
} from '../../../constants/routes';
import MTCDialog from '../../Form/MTCDialog';
import { useDispatch } from 'react-redux';
import { deleteFirmwareGroups } from '../../../store/firmwareGroups/actions';
import { useStyles } from './FirmwareGroupMenuButton.style';

const FirmwareGroupMenuButton = (props) => {
  const { id } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);
  const history = useHistory();

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDelete = (event) => {
    event.preventDefault();
    event.stopPropagation();
    dispatch(deleteFirmwareGroups(id))
      .then(() => {
        history.push(RECEIVER);
      });
  };

  const handleCloseDialog = () => {
    setOpen(false);
  };

  const handleOpenDialog = () => {
    setAnchorEl(null);
    setOpen(true);
  };

  return (
    <div>
      <Fab
        className={classes.button}
        color="secondary"
        aria-owns={anchorEl
          ? 'simple-menu'
          : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon />
      </Fab>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={() => history.push(FIRMWARE_GROUP_FIRMWARE_ADD.replace(':groupId', id))}>
          {t('firmware_group_page.menu.message_add_firmware')}
        </MenuItem>
        <MenuItem onClick={() => history.push(
          FIRMWARE_GROUP_ITEM_ADD.replace(':groupId', id)
        )}>
          {t('firmware_group_page.menu.message_add_item')}
        </MenuItem>
        <MenuItem onClick={() => history.push(FIRMWARE_GROUP_EDIT.replace(':groupId', id))}>
          {t('firmware_group_page.menu.message_edit')}
        </MenuItem>
        <MenuItem onClick={handleOpenDialog}>
          {t('firmware_group_page.menu.message_delete')}
        </MenuItem>
      </Menu>
      <MTCDialog
        open={open}
        handleClose={handleCloseDialog}
        dialogTitle={t('remove_firmware_group_title')}
        dialogText={t('remove_firmware_group_text')}
        handleCloseCancel={handleCloseDialog}
        handleCloseOK={handleDelete}
        cancelText={t('no')}
        okText={t('yes')}
      />
    </div>
  );
};

export default React.memo(FirmwareGroupMenuButton);
