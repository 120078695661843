import React, { useState } from 'react';
import useTheme from '@material-ui/core/styles/useTheme';
import { useTranslation } from 'react-i18next';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import { useDispatch } from 'react-redux';
import { deleteContact } from '../../store/contacts/actions';
import MTCDialog from '../Form/MTCDialog';
import { useHistory } from 'react-router';
import { CONTACT_EDIT } from '../../constants/routes';
import { useStyles } from './CircuitContactsRow.style';

const CarContactRow = ({ value }) => {

  const theme = useTheme();
  const classes = useStyles(theme);
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const [openDialog, setOpenDialog] = useState(false);

  const handleEdit = (event) => {
    event.stopPropagation();
    history.push(
      CONTACT_EDIT
        .replace(':type', 'circuit')
        .replace(':contactId', value.id),
    );
  };

  const handleDialogRemove = (event) => {
    event.stopPropagation();
    setOpenDialog(true);
  };

  const handleCloseDialog = event => {
    event.stopPropagation();
    setOpenDialog(false);
  };

  const handleRemove = event => {
    event.stopPropagation();
    dispatch(deleteContact('circuit', value.id));
    setOpenDialog(false);
  };

  return (
    <TableRow
      className={classes.row}
      key={value.id}
      hover
      onClick={handleEdit}
      tabIndex={-1}
    >
      <TableCell>
        {value.email}
      </TableCell>
      <TableCell align={'right'}>
        <Tooltip title={t('edit')}>
          <IconButton
            aria-label="update"
            onClick={handleEdit}
            color="primary"
            className={classes.button}
          >
            <EditIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title={t('remove')}>
          <IconButton
            aria-label="delete"
            onClick={handleDialogRemove}
            color="primary"
            className={classes.button}
          >
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      </TableCell>
      <MTCDialog
        open={openDialog}
        handleClose={handleCloseDialog}
        dialogTitle={t('contact_delete_dialog_title')}
        dialogText={t('contact_delete_dialog_text')}
        handleCloseCancel={handleCloseDialog}
        handleCloseOK={handleRemove}
        cancelText={t('no')}
        okText={t('yes')}
      />
    </TableRow>
  );
};

export default CarContactRow;
