import React from 'react';
import Grid from '@material-ui/core/Grid';
import { useStyles } from './BoundaryRangeIndicator.style';

const BoundaryRangeIndicator = () => {
  const classes = useStyles();
  return (
    <Grid container className={classes.boundaries} direction={'row'} justifyContent={'center'}
          alignItems={'center'} spacing={0}>
      <div className={classes.redBoundary} />
      <div className={classes.orangeBoundary} />
      <div className={classes.greenBoundary} />
      <div className={classes.orangeBoundary} />
      <div className={classes.redBoundary} />
    </Grid>
  );
};

export default BoundaryRangeIndicator;
