import React, { useState } from 'react';
import withAuthentication from '../../hoc/withAuthentication';
import { ROLE_ADMIN } from '../../constants/roles';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { setTitle } from '../../store/title/actions';
import { useParams } from 'react-router';
import ContactFrom from '../../components/Contacts/ContactForm';
import { getContacts } from '../../store/contacts/actions';
import MTCSpinner from '../../components/Layout/MTCSpinner';
import Error404 from '../Error404';

export const EditContactPage = () => {
  const { t } = useTranslation();
  const { title } = useSelector(state => state.title);
  const dispatch = useDispatch();
  const { type, contactId } = useParams();
  const { pending, content, error } = useSelector(state => state.contacts);
  const [contact, setContact] = useState(null);

  if (!content && !pending && !error) {
    dispatch(getContacts());
  }

  if (title !== (
    t('title:title.edit_contact_email')
  )) {
    dispatch(setTitle(t('title:title.edit_contact_email')));
  }

  if (content && contact === null) {
    let result = null;
    switch (type) {
      case 'car':
        result = content.car_request_contact.find(c => c.id == contactId);
        break;
      case 'message':
        result = content.message_request_contact.find(c => c.id == contactId);
        break;
      case 'circuit':
        result = content.circuit_request_contact.find(c => c.id == contactId);
        break;
      case 'rally-car':
        result = content.rally_car_request_contact.find(c => c.id == contactId);
        break;
      default:
        console.error("Wrong type : " + type);
        break;
    }
    if (result) {
      setContact(result);
    } else {
      setContact(false);
    }
  }

  if (pending) {
    return <MTCSpinner />;
  } else if (content && contact) {
    return <ContactFrom type={type} contact={contact} />;
  } else {
    return <Error404 />;
  }
};

export default withAuthentication(ROLE_ADMIN)(EditContactPage);
