import React, { createRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Dropzone from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import ArchiveIcon from '@material-ui/icons/Archive';
import makeStyles from '@material-ui/core/styles/makeStyles';
import MTCTextField from '../../components/Form/MTCTextField';
import {
  getReceiversMmcRf,
  postReceiverMmcRf,
  putReceiverMmcRf,
} from '../../store/receiversMmcRf/actions';
import { ROLE_ADMIN } from '../../constants/roles';
import withAuthentication from '../../hoc/withAuthentication';
import StringService from '../../services/StringService';
import { handleError } from '../../store/infos/actions';
import MTCButton from '../../components/Layout/Button/MTCButton';
import { setTitle } from '../../store/title/actions';
import ReceiversTable from '../../components/Receivers/ReceiversTable';
import { useTheme } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import { supportedBinDropFiles } from '../../constants/files';

const useStyles = makeStyles(theme => (
  {
    paper: {
      padding: '10px',
      margin: '10px',
      height: '310px',
      [theme.breakpoints.down('sm')]: {
        height: 'auto',
      },
    },
    grid: {
      marginBottom: '2em',
    },
  }
));

export const ReceiverMTCRFPage = () => {

  const { content, sending, pending, error } = useSelector(state => state.receiversMmcRf);
  const { title } = useSelector(state => state.title);
  const dispatch = useDispatch();
  const theme = useTheme();
  const classes = useStyles(theme);
  const { t } = useTranslation();

  const [id, setId] = useState('');
  const [version, setVersion] = useState('');
  const [isLastReceiverSet, setIsLastReceiverSet] = useState(false);

  const dropzoneRef = createRef();

  const stateWithLastReceiver = (receiver) => {
    setId(receiver.id);
    setVersion(receiver.version);
    setIsLastReceiverSet(true);
  };

  if (title !== t('title:title.receiver_mmc_rf_page')) {
    dispatch(setTitle(t('title:title.receiver_mmc_rf_page')));
  }

  if (!content && !pending && !error) {
    dispatch(getReceiversMmcRf());
  }
  if (content && content.length && id === '' && !isLastReceiverSet) {
    const lastReceiver = content.reduce((prev, curr) => {
      return prev.created_at > curr.created_at
        ? prev
        : curr;
    });
    stateWithLastReceiver(lastReceiver);
  }

  // Handler

  const onSubmit = (event) => {
    dispatch(putReceiverMmcRf({
      id,
      version,
    }));
    event.preventDefault();
  };

  const handleAddFile = () => {
    dropzoneRef.current.open();
  };

  const handleFileDrop = (accepted, rejected) => {
    if (rejected.length) {
      dispatch(handleError({
        message: t('error.format.bin') + rejected[0].type,
      }));
    } else {
      dispatch(postReceiverMmcRf(accepted[0]))
        .then(newReceiver => {
          if (newReceiver !== undefined && newReceiver !== null) {
            stateWithLastReceiver(newReceiver);
          }
        });
    }
  };

  const isInvalid = StringService.isEmpty(version) || sending;

  return (
    <>

      <Grid container align="center" className={classes.grid}>
        <Grid item xs={12} sm={12} md={6}>
          <Paper className={classes.paper}>
            <Typography
              gutterBottom
              align="center"
              variant="h5"
              component="h2"
            >
              {t('software_rf_mtc')}
            </Typography>

            <Dropzone
              ref={dropzoneRef}
              accept={supportedBinDropFiles}
              onDrop={handleFileDrop}
            >
              <div>
                <Typography variant="subtitle1">{t('software_drop_rf')}</Typography>
                <br />
                <ArchiveIcon />
              </div>
            </Dropzone>
            <MTCButton
              onClick={handleAddFile}
              text={t('upload_software_rf')}
            />
          </Paper>
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <Paper className={classes.paper}>
            <form onSubmit={onSubmit}>
              <Typography
                gutterBottom
                align="center"
                variant="h5"
                component="h2"
              >
                {t('update_last_receiver')}
              </Typography>
              <MTCTextField
                gridMD={12}
                id="version"
                onChange={event => setVersion(event.target.value)}
                value={version}
              />
              <MTCButton
                disabled={isInvalid}
                type={'submit'}
                sending={sending}
                text={t('edit')}
              />
            </form>
          </Paper>
        </Grid>
      </Grid>
      <Typography
        gutterBottom
        align="center"
        variant="h5"
        component="h2"
      >
        {t('title:title.history_receiver')}
      </Typography>
      <ReceiversTable pending={pending} content={content} identifier={'mmcRf'} />
    </>
  );
};

export default withAuthentication(ROLE_ADMIN)(ReceiverMTCRFPage);
