import {
  DEFAULT_CONTACTS_SENDING,
  DEFAULT_CONTACTS_DOWNLOADED,
  DEFAULT_CONTACTS_FAILED,
  DEFAULT_CONTACTS_PENDING
} from './types';

export const INITIAL_STATE = {
  error: null,
  content: null,
  pending: false,
  sending: false,
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case DEFAULT_CONTACTS_DOWNLOADED:
      return {
        content: action.payload,
        error: null,
        pending: false,
        sending: false,
      };
    case DEFAULT_CONTACTS_FAILED:
      return {
        ...state,
        error: action.payload,
        pending: false,
        sending: false,
      };
    case DEFAULT_CONTACTS_PENDING:
      return {
        ...state,
        pending: true,
        error: null,
      };
    case DEFAULT_CONTACTS_SENDING:
      return {
        ...state,
        sending: true,
        error: null,
      };
    default:
      break;
  }
  return state;
}
