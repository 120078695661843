import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import useTheme from '@material-ui/core/styles/useTheme';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import MTCDialog from '../../Form/MTCDialog';
import i18next from '../../../i18n';
import { CATALOGS_TAGS_EDIT } from '../../../constants/routes';
import { deleteCatalogsTags } from '../../../store/catalogsTags/actions';
import { useStyles } from './CatalogsTagRow.style';

const CatalogsTagRow = ({ value }) => {

  const { t } = useTranslation();
  const theme = useTheme();
  const classes = useStyles(theme);
  const dispatch = useDispatch();
  const history = useHistory();
  const { sending } = useSelector(state => state.catalogsTags);
  const [open, setOpen] = React.useState(false);

  const lang = i18next.languages[0].split('-')[0];
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleEdit = (id) => {
    history.push(CATALOGS_TAGS_EDIT.replace(':tagId', id));
  };

  const handleDelete = (id) => {
    if (!sending) {
      dispatch(deleteCatalogsTags(id)).then(() => {
        setOpen(false);
      });
    }
  };

  return (
    <>
      <TableRow
        className={classes.row}
        key={value.id}
        tabIndex={-1}
      >
        <TableCell>{value.translations[lang].name}</TableCell>
        <TableCell>
          <IconButton
            id='edit'
            aria-label="edit"
            onClick={() => handleEdit(value.id)}
            className={classes.button}
            disabled={sending}
          >
            <EditIcon />
          </IconButton>
          <IconButton
            id='remove'
            aria-label="remove"
            onClick={handleOpen}
            className={classes.button}
            disabled={sending}
          >
            <DeleteIcon />
          </IconButton>
        </TableCell>
      </TableRow>
      <MTCDialog
        open={open}
        handleClose={handleClose}
        dialogTitle={t('remove_catalogs_tag_title')}
        dialogText={t('remove_catalogs_tag_text')}
        handleCloseCancel={handleClose}
        handleCloseOK={() => handleDelete(value.id)}
        cancelText={t('cancel')}
        okText={t('validate')}
      />
    </>
  );
};

CatalogsTagRow.propTypes = {
  value: PropTypes.object.isRequired,
};

export default React.memo(CatalogsTagRow);
