import makeStyles from '@material-ui/core/styles/makeStyles';
import {
  michelin_blue_main,
  michelin_red,
  michelin_white,
} from '../../../constants/michelin_colors';

export const useStyles = makeStyles(() => {
  return {
    item: {
      transition: 'height  1s',
      maxWidth: '600px'
    },
    avatar: {
      backgroundColor: michelin_blue_main,
      color: michelin_white,
    },
    title: {
      fontSize: '1.3em',
    },
    commentContainer: {
      marginLeft: '56px',
      textAlign: 'justify',
      transition: 'height  1s',
    },
    ratingContainer: {
      marginTop: '10px',
    },
    arrow: {
      display: 'block',
      margin: 'auto',
      textAlign: "center",
      cursor: 'pointer',
    },
    arrowText: {
      marginTop: '5px'
    },
    reportCount: {
      color: michelin_red
    },
    buttonIcon: {
      width: '2em',
      height: '2em',
    }
  };
});
