import {
  FIRMWARE_EXCLUDED_VERSION_DELETED,
  FIRMWARE_EXCLUDED_VERSION_DOWNLOADED,
  FIRMWARE_EXCLUDED_VERSION_SENDING,
  FIRMWARE_EXCLUDED_VERSIONS_DOWNLOADED,
  FIRMWARE_EXCLUDED_VERSIONS_FAILED,
  FIRMWARE_EXCLUDED_VERSIONS_PENDING,
} from './types';
import ApiService from '../../services/ApiService';
import { handleError } from '../infos/actions';

export const firmwareExcludedVersionsPendingAction = () => (
  {
    type: FIRMWARE_EXCLUDED_VERSIONS_PENDING,
  }
);
export const firmwareExcludedVersionSendingAction = () => (
  {
    type: FIRMWARE_EXCLUDED_VERSION_SENDING,
  }
);
export const firmwareExcludedVersionsFailedAction = (error) => (
  {
    type: FIRMWARE_EXCLUDED_VERSIONS_FAILED,
    payload: error,
  }
);
export const firmwareExcludedVersionsDownloadedAction = (versions) => (
  {
    type: FIRMWARE_EXCLUDED_VERSIONS_DOWNLOADED,
    payload: versions,
  }
);
export const firmwareExcludedVersionDownloadedAction = (version) => (
  {
    type: FIRMWARE_EXCLUDED_VERSION_DOWNLOADED,
    payload: version,
  }
);
export const firmwareExcludedVersionDeletedAction = (versionId) => (
  {
    type: FIRMWARE_EXCLUDED_VERSION_DELETED,
    payload: versionId,
  }
);

export function getFirmwareExcludedVersions() {
  return (dispatch) => {
    dispatch(firmwareExcludedVersionsPendingAction());
    return ApiService.getFirmwareExcludedVersions()
      .then((data) => {
        dispatch(firmwareExcludedVersionsDownloadedAction(data));
        return data;
      }).catch((error) => {
        dispatch(firmwareExcludedVersionsFailedAction(error));
        dispatch(handleError(error));
      });
  };
}

export function postFirmwareExcludedVersions(formData) {
  return (dispatch) => {
    dispatch(firmwareExcludedVersionSendingAction());
    return ApiService.postFirmwareExcludedVersions(formData)
      .then((data) => {
        dispatch(firmwareExcludedVersionDownloadedAction(data));
        return data;
      }).catch((error) => {
        dispatch(firmwareExcludedVersionsFailedAction(error));
        dispatch(handleError(error));
      });
  };
}

export function deleteFirmwareExcludedVersions(id) {
  return (dispatch) => {
    dispatch(firmwareExcludedVersionSendingAction());
    return ApiService.deleteFirmwareExcludedVersions(id)
      .then(() => {
        dispatch(firmwareExcludedVersionDeletedAction(id));
      }).catch((error) => {
        dispatch(firmwareExcludedVersionsFailedAction(error));
        dispatch(handleError(error));
      });
  };
}
