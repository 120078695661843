import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import withAuthentication from '../../../hoc/withAuthentication';
import { ROLE_ADMIN } from '../../../constants/roles';
import { setTitle } from '../../../store/title/actions';
import FaqElementsExplorer
  from '../../../components/Faq/Elements/FaqElementsExplorer/FaqElementsExplorer';

export const FaqElementPage = () => {
  const { t } = useTranslation();
  const { title } = useSelector(state => state.title);
  const dispatch = useDispatch();
  if (title !== t('title:title.faq_elements_page')) {
    dispatch(setTitle(t('title:title.faq_elements_page')));
  }
  const history = useHistory();
  let defaultId = null;
  if (
    history.location
    && history.location.state
    && history.location.state.elementId
  ) {
    defaultId = history.location.state.elementId;
  }

  return <FaqElementsExplorer defaultId={defaultId}/>;
}

export default withAuthentication(ROLE_ADMIN)(FaqElementPage);
