import hash from 'object-hash';

export default class ArrayService {
  static hashAndSortArray = (table) => {
    let hashedTable = [];
    for (let i = 0; i < table.length; i++) {
      hashedTable[i] = hash(table[i]);
    }
    return hash(hashedTable.sort());
  };

  static isArrayEquals = (table1, table2) => {
    if (table1 === null && table2 === null) {
      return true;
    } else if ((table1 === null && table2 !== null) || (table1 !== null && table2 === null)) {
      return false;
    } else {
      return this.hashAndSortArray(table1) === this.hashAndSortArray(table2);
    }
  };
}