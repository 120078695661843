import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import { useTranslation } from 'react-i18next';
import logo from '../../resources/pictures/logo_michelin_splash.svg';
import Auth from '../../services/Auth/Auth';
import { postUser } from '../../store/users/actions';
import MTCLoader from '../../components/Layout/MTCLoader';
import MTCButton from '../../components/Layout/Button/MTCButton';
import { CardContent, useTheme } from '@material-ui/core';
import { setTitle } from '../../store/title/actions';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { michelin_white } from '../../constants/michelin_colors';

const useStyles = makeStyles(theme => (
  {
    root: {
      paddingTop: '10px',
    },
    header: {
      backgroundColor: theme.palette.primary.main,
      marginBottom: '2px',
    },
    text: {
      color: michelin_white,
    },
  }
));

const Homepage = ({ auth }) => {

  const theme = useTheme();
  const classes = useStyles(theme);
  const { t } = useTranslation();
  const {
    sending,
    error,
    pending,
  } = useSelector(state => state.users);
  const dispatch = useDispatch();

  dispatch(setTitle(''));

  const token = localStorage.getItem('access_token');

  if (!Auth.isAuthenticated()) {
    if (auth
      && error
      && error.response
      && error.response.config
      && error.response.config.method === 'get'
      && sending === false
      && token !== null) {
      dispatch(
        postUser({
          email: localStorage.getItem('email'),
          lastname: localStorage.getItem('nickname'),
          firstname: localStorage.getItem('nickname'),
          username: localStorage.getItem('email'),
          access_token: localStorage.getItem('access_token'),
          pressure_unit: 1,
          distance_unit: 1,
          temperature_unit: 1,
        }),
      );
    }
  }

  if (pending) {
    return <MTCLoader />;
  } else {
    return (
      <div className={classes.root}>
        <Grid
          container
          spacing={3}
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
        >
          <Grid item xs>
            <Card className={classes.header}>
              <CardMedia
                src={logo}
                component={'img'}
                className={'App-logo'}
                title={'Michelin Track Connect'}
              />
              <CardContent>
                <Typography
                  gutterBottom={true}
                  variant={'h3'}
                  component={'h2'}
                  className={classes.text}
                >
                  Michelin Track Connect
                </Typography>
                <Typography
                  variant={'h6'}
                  component={'p'}
                  className={classes.text}
                >
                  {t('backOfficeTitle')}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
        {
          !Auth.isAuthenticated() && (
            <Grid
              spacing={3}
              container
              direction="column"
              justifyContent="flex-start"
              alignItems="center"
            >
              <Grid item xs>
                <MTCButton
                  onClick={() => auth.login()}
                  text={t('login')}
                />
              </Grid>
            </Grid>
          )
        }
      </div>
    );
  }
};

Homepage.propTypes = {
  auth: PropTypes.object.isRequired,
};

export default Homepage;
