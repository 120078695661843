const FAQ_ARTICLES_PENDING = "faq_articles_pending";
const FAQ_ARTICLES_FAILED = "faq_articles_failed";
const FAQ_ARTICLES_DOWNLOADED = "faq_articles_downloaded";
const FAQ_ARTICLE_SENDING = "faq_article_sending";
const FAQ_ARTICLE_DOWNLOADED = "faq_article_downloaded";
const FAQ_ARTICLE_DELETED = "faq_article_deleted";

export {
  FAQ_ARTICLES_PENDING,
  FAQ_ARTICLES_FAILED,
  FAQ_ARTICLES_DOWNLOADED,
  FAQ_ARTICLE_SENDING,
  FAQ_ARTICLE_DOWNLOADED,
  FAQ_ARTICLE_DELETED
}
