import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import withAuthentication from '../../hoc/withAuthentication';
import { ROLE_ADMIN } from '../../constants/roles';
import { setTitle } from '../../store/title/actions';
import AddCallCenterForm from '../../components/CallCenters/AddCallCenterForm';
import { getCallCenters } from '../../store/callCenters/actions';

const AddCallCentersPage = () => {
  const dispatch = useDispatch();
  const { title } = useSelector(state => state.title);
  const { pending, content, error } = useSelector(state => state.callCenters);
  const { t } = useTranslation();
  if (!content && !pending && !error) {
    dispatch(getCallCenters());
  }
  if (title !== t('title:title.add_call_centers_page')) {
    dispatch(setTitle(t('title:title.add_call_centers_page')));
  }
  return (
    <AddCallCenterForm/>
  );
}

export default withAuthentication(ROLE_ADMIN)(AddCallCentersPage);