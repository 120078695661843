const TESTERS_PENDING = 'testers_pending';
const TESTERS_FAILED = 'testers_failed';
const TESTERS_DOWNLOADED = 'testers_downloaded';
const TESTER_SENDING = 'tester_sending';
const TESTER_DOWNLOADED = 'tester_downloaded';
const TESTER_DELETED = 'tester_deleted';

export {
  TESTERS_PENDING,
  TESTERS_FAILED,
  TESTERS_DOWNLOADED,
  TESTER_SENDING,
  TESTER_DOWNLOADED,
  TESTER_DELETED,
};
