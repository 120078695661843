import {
  CATALOGS_ITEM_DOWNLOADED,
  CATALOGS_ITEMS_DOWNLOADED,
  CATALOGS_ITEMS_FAILED,
  CATALOGS_ITEMS_PENDING,
  CATALOGS_ITEMS_SENDING,
} from './types';
import ApiService from '../../services/ApiService';
import { handleError } from '../infos/actions';

export const catalogsItemsPendingAction = () => (
  {
    type: CATALOGS_ITEMS_PENDING,
  }
);

export const catalogsItemsSendingAction = () => (
  {
    type: CATALOGS_ITEMS_SENDING,
  }
);

export const catalogsItemsFailedAction = (error) => (
  {
    type: CATALOGS_ITEMS_FAILED,
    payload: error,
  }
);

export const catalogsItemsDownloadedAction = (items) => (
  {
    type: CATALOGS_ITEMS_DOWNLOADED,
    payload: items,
  }
);

export const catalogsItemDownloadedAction = (item) => (
  {
    type: CATALOGS_ITEM_DOWNLOADED,
    payload: item,
  }
);

export function getCatalogsItems() {
  return (dispatch) => {
    dispatch(catalogsItemsPendingAction());
    return ApiService.getCatalogsItems()
      .then(data => {
        dispatch(catalogsItemsDownloadedAction(data));
        return data;
      })
      .catch(error => {
        dispatch(catalogsItemsFailedAction(error));
        dispatch(handleError(error));
      });
  };
}

export function postCatalogsItem(item) {
  return (dispatch) => {
    dispatch(catalogsItemsSendingAction());
    return ApiService.postCatalogsItem(item)
      .then(data => {
        dispatch(catalogsItemDownloadedAction(data));
        return data;
      })
      .catch(error => {
        dispatch(catalogsItemsFailedAction(error));
        dispatch(handleError(error));
      });
  };
}

export function putCatalogsItem(itemId, item) {
  return (dispatch) => {
    dispatch(catalogsItemsSendingAction());
    return ApiService.putCatalogsItem(itemId, item)
      .then(data => {
        dispatch(catalogsItemDownloadedAction(data));
        return data;
      })
      .catch(error => {
        dispatch(catalogsItemsFailedAction(error));
        dispatch(handleError(error));
      });
  };
}