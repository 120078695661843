import React, { useState } from 'react';
import hash from 'object-hash';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import MTCTable from '../Layout/Table/MTCTable';
import { getTires } from '../../store/tires/actions';
import MTCLoader from '../Layout/MTCLoader';
import TireRow from '../Tires/TireRow';
import { columns, createDataFromTires, defaultOrderBy } from './TiresTable.const';

const TiresTable = () => {

  const {
    pending,
    content,
    error,
  } = useSelector(state => state.tires);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  if (!content && !pending && !error) {
    dispatch(getTires());
  }

  const [contentHash, setContentHash] = useState(null);
  const [data, setData] = useState(null);

  if (content && (
    data === null || contentHash !== hash(content)
  )) {
    setData(createDataFromTires(content));
    setContentHash(hash(content));
  }

  if (pending) {
    return <MTCLoader />;
  } else {
    return <MTCTable
      content={data}
      defaultOrderBy={defaultOrderBy}
      rowComponent={TireRow}
      columns={columns}
      paginationLabel={t('tires_per_page')}
      emptyDataLabel={t('no_tire')}
    />;
  }
};

export default TiresTable;
