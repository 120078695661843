import React from 'react';
import { List, Typography } from '@material-ui/core';
import { useStyles } from './TrackCommentsList.style';
import TrackCommentsListItem from '../TrackCommentsListItem/TrackCommentsListItem';
import { useTranslation } from 'react-i18next';

const TrackCommentsList = ({ comments }) => {

  const classes = useStyles();
  const { t } = useTranslation();

  if (comments.length > 0) {
    return (
      <List className={classes.list}>
        {comments.map(comment =>
          <TrackCommentsListItem comment={comment} key={`comment-${comment.id}`} />
        )}
      </List>
    );
  } else {
    return (
      <Typography variant={'body1'} className={classes.emptyList}>
        {t("no_comment")}
      </Typography>
    );
  }
};
export default TrackCommentsList;
