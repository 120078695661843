import { Chip } from '@material-ui/core';
import React from 'react';
import PropTypes from 'prop-types';
import { useStyles } from './RedChip.style';

export const RedChip = (props) => {
  const { count } = props;

  const classes = useStyles();

  return (<Chip size="small" label={count} className={classes.chip}/>);
}

RedChip.propTypes = {
  count: PropTypes.number.isRequired,
}

export default React.memo(RedChip);
