import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Grid from '@material-ui/core/Grid';
import { useHistory } from 'react-router';
import MTCTextField from '../../Form/MTCTextField';
import MTCButton from '../../Layout/Button/MTCButton';
import { RECEIVER } from '../../../constants/routes';
import {
  getFirmwareExcludedVersions,
  postFirmwareExcludedVersions,
} from '../../../store/firmwareExcludedVersions/actions';
import { useStyles } from './FirmwareExcludedVersionForm.style';

export const FirmwareExcludedVersionForm = () => {
  const {
    content,
    sending,
    pending,
    error,
  } = useSelector(state => state.firmwareExcludedVersions);
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const classes = useStyles();
  const [bleVersion, setBleVersion] = useState('');
  const [rfVersion, setRfVersion] = useState('');

  if (!content && !pending && !error) {
    dispatch(getFirmwareExcludedVersions());
  }

  const formSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();
    if (bleVersion !== '' && rfVersion !== '') {

        dispatch(postFirmwareExcludedVersions({
          ble_version: bleVersion,
          rf_version: rfVersion,
        }))
          .then(data => {
            if (data !== null) {
              history.push(RECEIVER);
            }
          });
      }
  };

  return (
    <form onSubmit={formSubmit}>
      <Grid
        container
        alignItems={'center'}
        direction={'column'}
        justifyContent={'center'}
        className={classes.grid}
      >
        <MTCTextField
          gridMD={12}
          id="ble_version"
          onChange={event => setBleVersion(event.target.value)}
          value={bleVersion}
          required={true}
        />
        <MTCTextField
          gridMD={12}
          id="rf_version"
          onChange={event => setRfVersion(event.target.value)}
          value={rfVersion}
          required={true}
        />
        <MTCButton
          type={'submit'}
          sending={sending}
          text={t('create')}
        />
      </Grid>
    </form>
  );
};

export default FirmwareExcludedVersionForm;
