import {
  TABLE_CARS_BRAND_DOWNLOADED,
  TABLE_CARS_DIMENSION_DOWNLOADED,
  TABLE_CARS_DOWNLOADED,
  TABLE_CARS_ENGINE_DOWNLOADED,
  TABLE_CARS_FAILED,
  TABLE_CARS_MODEL_DOWNLOADED,
  TABLE_CARS_PENDING,
  TABLE_CARS_RANGE_DOWNLOADED,
  TABLE_CARS_RESET,
} from './types';
import ApiService from '../../services/ApiService';
import { handleError } from '../infos/actions';

export const tableCarsDownloadedAction = cars => (
  {
    type: TABLE_CARS_DOWNLOADED,
    payload: cars,
  }
);

export const tableCarsBrandDownloadedAction = brand => (
  {
    type: TABLE_CARS_BRAND_DOWNLOADED,
    payload: brand,
  }
);

export const tableCarsRangeDownloadedAction = range => (
  {
    type: TABLE_CARS_RANGE_DOWNLOADED,
    payload: range,
  }
);

export const tableCarsModelDownloadedAction = model => (
  {
    type: TABLE_CARS_MODEL_DOWNLOADED,
    payload: model,
  }
);

export const tableCarsEngineDownloadedAction = engine => (
  {
    type: TABLE_CARS_ENGINE_DOWNLOADED,
    payload: engine,
  }
);
export const tableCarsDimensionDownloadedAction = dim => (
  {
    type: TABLE_CARS_DIMENSION_DOWNLOADED,
    payload: dim,
  }
);

export const tableCarsFailedAction = error => (
  {
    type: TABLE_CARS_FAILED,
    payload: error,
  }
);

export const tableCarsPendingAction = () => (
  {
    type: TABLE_CARS_PENDING,
  }
);

export const tableCarsResetAction = () => (
  {
    type: TABLE_CARS_RESET,
  }
);

export function getTableCars(params) {
  return (dispatch) => {
    dispatch(tableCarsPendingAction());
    return ApiService
      .getTableCars(params)
      .then((data) => {
        dispatch(tableCarsDownloadedAction(data));
      })
      .catch((error) => {
        dispatch(tableCarsFailedAction(error));
        dispatch(handleError(error));
      });
  };
}

export function resetTableCars() {
  return (dispatch) => {
    dispatch(tableCarsResetAction());
    return true;
  };
}

export function editCarsBrand(brandId, data) {
  return (dispatch) => {
    dispatch(tableCarsPendingAction());
    return ApiService
      .putCarsBrandState(brandId, data)
      .then((data) => {
        dispatch(tableCarsBrandDownloadedAction(data));
      })
      .catch((error) => {
        dispatch(tableCarsFailedAction(error));
        dispatch(handleError(error));
      });
  };
}

export function editCarsRange(brandId, rangeId, data) {
  return (dispatch) => {
    dispatch(tableCarsPendingAction());
    return ApiService
      .putCarsRangeState(brandId, rangeId, data)
      .then((data) => {
        dispatch(tableCarsRangeDownloadedAction(data));
      })
      .catch((error) => {
        dispatch(tableCarsFailedAction(error));
        dispatch(handleError(error));
      });
  };
}

export function editCarsModel(brandId, rangeId, modelId, data) {
  return (dispatch) => {
    dispatch(tableCarsPendingAction());
    return ApiService
      .putCarsModelState(brandId, rangeId, modelId, data)
      .then((data) => {
        dispatch(tableCarsModelDownloadedAction(data));
      })
      .catch((error) => {
        dispatch(tableCarsFailedAction(error));
        dispatch(handleError(error));
      });
  };
}

export function editCarsEngine(brandId, rangeId, modelId, engineId, data) {
  return (dispatch) => {
    dispatch(tableCarsPendingAction());
    return ApiService
      .putCarsEngineState(brandId, rangeId, modelId, engineId, data)
      .then((data) => {
        dispatch(tableCarsEngineDownloadedAction(data));
      })
      .catch((error) => {
        dispatch(tableCarsFailedAction(error));
        dispatch(handleError(error));
      });
  };
}

export function editCarsDimension(brandId, rangeId, modelId, engineId, dimensionId, data) {
  return (dispatch) => {
    dispatch(tableCarsPendingAction());
    return ApiService
      .putCarsDimensionState(brandId, rangeId, modelId, engineId, dimensionId, data)
      .then((data) => {
        dispatch(tableCarsDimensionDownloadedAction(data));
      })
      .catch((error) => {
        dispatch(tableCarsFailedAction(error));
        dispatch(handleError(error));
      });
  };
}
