import axios from 'axios';
import API_URL, { API_ROOT_USER } from '../constants/api';
import i18next from '../i18n';

const ApiService = {
  headers(token = null) {
    token = token || localStorage.getItem('access_token');
    const header = {
      Accept: 'application/json',
      'Accept-Language': i18next.languages[0],
    };
    if (token) {
      header.Authorization = `Bearer ${token}`;
    }
    return header;
  },

  callGet(url) {
    return axios
      .get(url, { headers: this.headers() })
      .then(response => response.data);
  },
  callDelete(url) {
    return axios
      .delete(url, { headers: this.headers() })
      .then(response => response.data);
  },

  /**
   * Home
   */

  getHome(search) {
    let url = `${API_URL}/home`;
    if (search) {
      url += `?email=${search}`;
    }
    return this.callGet(url);
  },

  /**
   * tracks
   */

  getExportCsv() {
    return axios
      .get(`${API_URL}/csv/circuits`, {
        headers: this.headers(),
      }).then((response) => {
        const type = response.headers['content-type'];
        const blob = new Blob([response.data],
          {
            type: type,
            encoding: 'UTF-8',
          },
        );
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = 'circuits_export.csv';
        link.click();
      }).catch((response) => {
        console.error('Download error', response);
      });
  },

  setParam(params, propName, value) {
    if (value !== undefined && String(value).trim() !== '') {
      if (params !== '?') {
        params += '&';
      }
      params += propName + '=' + String(value);
    }
    return params;
  },

  getCarsCsv(brandId, rangeId, modelId, engineId, dimensionId, mode, tireType, isPublic, carState) {
    let params = '?';
    params = this.setParam(params, 'brandId', brandId);
    params = this.setParam(params, 'rangeId', rangeId);
    params = this.setParam(params, 'modelId', modelId);
    params = this.setParam(params, 'engineId', engineId);
    params = this.setParam(params, 'dimensionId', dimensionId);
    params = this.setParam(params, 'compatibleWithReceiver', mode);
    params = this.setParam(params, 'tireType', tireType);
    params = this.setParam(params, 'isPublic', isPublic);
    params = this.setParam(params, 'carState', carState);
    if (params === '?') {
      params = '';
    }
    return axios
      .get(`${API_URL}/csv/cars${params}`, {
        headers: this.headers(),
      }).then((response) => {
        const type = response.headers['content-type'];
        const blob = new Blob([response.data],
          {
            type: type,
            encoding: 'UTF-8',
          },
        );
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = 'cars_export.csv';
        link.click();
      }).catch((response) => {
        console.error('Download error', response);
      });
  },

  getRallyCarsCsv(brandId, modelId, groupId, dimensionId, isPublic, carState) {
    let params = '?';
    params = this.setParam(params, 'brandId', brandId);
    params = this.setParam(params, 'modelId', modelId);
    params = this.setParam(params, 'groupId', groupId);
    params = this.setParam(params, 'dimensionId', dimensionId);
    params = this.setParam(params, 'isPublic', isPublic);
    params = this.setParam(params, 'carState', carState);
    if (params === '?') {
      params = '';
    }
    return axios
      .get(`${API_URL}/csv/rally-cars${params}`, {
        headers: this.headers(),
      }).then((response) => {
        const type = response.headers['content-type'];
        const blob = new Blob([response.data],
          {
            type: type,
            encoding: 'UTF-8',
          },
        );
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = 'rally_cars_export.csv';
        link.click();
      }).catch((response) => {
        console.error('Download error', response);
      });
  },

  getTracks() {
    return this.callGet(`${API_URL}/circuits`);
  },

  postTrack(data) {
    return axios
      .post(`${API_URL}/circuits`, data, {
        headers: {
          ...this.headers(),
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      })
      .then(response => response.data);
  },

  postTrackPicture(trackId, picture, onUploadProgress = null) {
    let data = new FormData();
    data.append('picture', picture);

    return axios
      .post(`${API_URL}/circuits/${trackId}/picture`, data, {
        headers: this.headers(),
        onUploadProgress: onUploadProgress,
      })
      .then(response => response.data);
  },

  putMultipartTrack(trackId, data) {
    // POST instead of PUT because PUT does not support multipart
    return axios
      .post(`${API_URL}/circuits/${trackId}`, data, {
        headers: {
          ...this.headers(),
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      })
      .then(response => response.data);
  },

  putTrack(data) {
    return axios
      .put(`${API_URL}/circuits/${data.id}`, data, {
        headers: this.headers(),
      })
      .then(response => response.data);
  },

  /**
   * tracks comment
   */

  getTrackComments(circuitId) {
    return this.callGet(`${API_URL}/circuit-comments?circuitId=${circuitId}`);
  },
  putTrackComments(commentId, data) {
    return axios
      .put(`${API_URL}/circuit-comments/${commentId}`,
        data,
        { headers: this.headers() },
      )
      .then(response => response.data);
  },

  /**
   * cars
   */

  getCarsBrands() {
    return this.callGet(`${API_URL}/cars/brands`);
  },
  putCarsBrandState(brandId, data) {
    return axios
      .put(`${API_URL}/cars/brands/${brandId}`, data, {
        headers: this.headers(),
      })
      .then(response => response.data);
  },
  getCarsRanges(brandId) {
    return this.callGet(`${API_URL}/cars/brands/${brandId}/ranges`);
  },
  putCarsRangeState(brandId, rangeId, data) {
    return axios
      .put(`${API_URL}/cars/brands/${brandId}/ranges/${rangeId}`, data, {
        headers: this.headers(),
      })
      .then(response => response.data);
  },
  getCarsModels(brandId, rangeId) {
    return this.callGet(`${API_URL}/cars/brands/${brandId}/ranges/${rangeId}/models`);
  },
  putCarsModelState(brandId, rangeId, modelId, data) {
    return axios
      // eslint-disable-next-line max-len
      .put(`${API_URL}/cars/brands/${brandId}/ranges/${rangeId}/models/${modelId}`, data, {
        headers: this.headers(),
      })
      .then(response => response.data);
  },
  getCarsEngines(brandId, rangeId, modelId) {
    // eslint-disable-next-line max-len
    return this.callGet(`${API_URL}/cars/brands/${brandId}/ranges/${rangeId}/models/${modelId}/engines`);
  },
  putCarsEngineState(brandId, rangeId, modelId, engineId, data) {
    return axios
      // eslint-disable-next-line max-len
      .put(`${API_URL}/cars/brands/${brandId}/ranges/${rangeId}/models/${modelId}/engines/${engineId}`,
        data,
        {
          headers: this.headers(),
        },
      )
      .then(response => response.data);
  },
  getCarsDimensions(brandId, rangeId, modelId, engineId) {
    // eslint-disable-next-line max-len
    return this.callGet(`${API_URL}/cars/brands/${brandId}/ranges/${rangeId}/models/${modelId}/engines/${engineId}/dimensions`);
  },
  putCarsDimensionState(brandId, rangeId, modelId, engineId, dimensionId, data) {
    return axios
      // eslint-disable-next-line max-len
      .put(`${API_URL}/cars/brands/${brandId}/ranges/${rangeId}/models/${modelId}/engines/${engineId}/dimensions/${dimensionId}`,
        data,
        {
          headers: this.headers(),
        },
      )
      .then(response => response.data);
  },
  getCarsDimension(dimensionId) {
    return this.callGet(`${API_URL}/cars/dimensions/${dimensionId}`);
  },
  getCarsBoundaries(brandId, rangeId, modelId, engineId, dimensionId) {
    // eslint-disable-next-line max-len
    return this.callGet(`${API_URL}/cars/brands/${brandId}/ranges/${rangeId}/models/${modelId}/engines/${engineId}/dimensions/${dimensionId}/boundaries`);
  },

  getTableCars(params) {
    return this.callGet(`${API_URL}/cars?${params}`);
  },

  postCar(data) {
    return axios
      .post(`${API_URL}/cars`, data, {
        headers: this.headers(),
      })
      .then(response => response.data);
  },

  putCar(data) {
    return axios
      .put(`${API_URL}/cars`, data, {
        headers: this.headers(),
      })
      .then(response => response.data);
  },

  /**
   * rally_cars
   */

  getRallyCars() {
    return this.callGet(`${API_URL}/rally-cars/all`);
  },

  getTableRallyCars(params) {
    return this.callGet(`${API_URL}/rally-cars?${params}`);
  },
  getRallyCarsBrands() {
    return this.callGet(`${API_URL}/rally-cars/brands`);
  },
  putRallyCarsBrandState(brandId, data) {
    return axios
      .put(`${API_URL}/rally-cars/brands/${brandId}`, data, {
        headers: this.headers(),
      })
      .then(response => response.data);
  },
  getRallyCarsModels(brandId) {
    return this.callGet(`${API_URL}/rally-cars/brands/${brandId}/models`);
  },
  putRallyCarsModelState(brandId, modelId, data) {
    return axios
      .put(`${API_URL}/rally-cars/brands/${brandId}/models/${modelId}`, data, {
        headers: this.headers(),
      })
      .then(response => response.data);
  },
  getRallyCarsGroups(brandId, modelId) {
    return this.callGet(`${API_URL}/rally-cars/brands/${brandId}/models/${modelId}/groups`);
  },
  putRallyCarsGroupState(brandId, modelId, groupId, data) {
    return axios
      .put(`${API_URL}/rally-cars/brands/${brandId}/models/${modelId}/groups/${groupId}`, data, {
        headers: this.headers(),
      })
      .then(response => response.data);
  },
  getRallyCarsDimensions(brandId, modelId, groupId) {
    // eslint-disable-next-line max-len
    return this.callGet(`${API_URL}/rally-cars/brands/${brandId}/models/${modelId}/groups/${groupId}/dimensions`);
  },
  putRallyCarsDimensionState(brandId, modelId, groupId, dimensionId, data) {
    return axios
      // eslint-disable-next-line max-len
      .put(`${API_URL}/rally-cars/brands/${brandId}/models/${modelId}/groups/${groupId}/dimensions/${dimensionId}`,
        data,
        {
          headers: this.headers(),
        },
      )
      .then(response => response.data);
  },
  getRallyCarsBoundaries(brandId, modelId, groupId, dimensionId) {
    // eslint-disable-next-line max-len
    return this.callGet(`${API_URL}/rally-cars/brands/${brandId}/models/${modelId}/groups/${groupId}/dimensions/${dimensionId}/boundaries`);
  },

  postRallyCar(data) {
    return axios
      .post(`${API_URL}/rally-cars`, data, {
        headers: this.headers(),
      })
      .then(response => response.data);
  },

  putRallyCar(data) {
    return axios
      .put(`${API_URL}/rally-cars`, data, {
        headers: this.headers(),
      })
      .then(response => response.data);
  },

  /**
   * tires
   */

  getTires() {
    return this.callGet(`${API_URL}/tires`);
  },

  postTire(data) {
    return axios
      .post(`${API_URL}/tires`, data, {
        headers: this.headers(),
      })
      .then(response => response.data);
  },

  putTire(data) {
    return axios
      .put(`${API_URL}/tires`, data, {
        headers: this.headers(),
      })
      .then(response => response.data);
  },

  /**
   * Requests
   */

  getRequests() {
    return this.callGet(`${API_URL}/requests`);
  },

  getMessageRequest(id) {
    return this.callGet(`${API_URL}/requests/messages/${id}`);
  },

  putRequest(data, type) {
    return axios
      .put(`${API_URL}/requests/${type}/${data.id}`, data, {
        headers: this.headers(),
      })
      .then(response => response.data);
  },

  /**
   * Receivers MTC BLE
   */

  getReceiversLastVersion() {
    return this.callGet(`${API_URL}/firmwares`);
  },

  getMtcReceiversBle() {
    return this.callGet(`${API_URL}/firmwares/mtc/ble`);
  },

  postMtcReceiverBle(data) {
    let formData = new FormData();
    formData.append('firmware', data);
    return axios
      .post(`${API_URL}/firmwares/mtc/ble`, formData, {
        headers: this.headers(),
      })
      .then(response => response.data);
  },

  putMtcReceiverBle(data) {
    return axios
      .put(`${API_URL}/firmwares/mtc/ble/${data.id}`, data, {
        headers: this.headers(),
      })
      .then(response => response.data);
  },

  deleteMtcReceiverBle(id) {
    return this.callDelete(`${API_URL}/firmwares/mtc/ble/${id}`);
  },

  /**
   * Receivers MTC RF
   */

  getMtcReceiversRf() {
    return this.callGet(`${API_URL}/firmwares/mtc/rf`);
  },

  postMtcReceiverRf(data) {
    let formData = new FormData();
    formData.append('firmware', data);
    return axios
      .post(`${API_URL}/firmwares/mtc/rf`, formData, {
        headers: this.headers(),
      })
      .then(response => response.data);
  },

  putMtcReceiverRf(data) {
    return axios
      .put(`${API_URL}/firmwares/mtc/rf/${data.id}`, data, {
        headers: this.headers(),
      })
      .then(response => response.data);
  },

  deleteMtcReceiverRf(id) {
    return this.callDelete(`${API_URL}/firmwares/mtc/rf/${id}`);
  },
  /**
   * Receivers MMC BLE
   */

  getMmcReceiversBle() {
    return this.callGet(`${API_URL}/firmwares/mmc/ble`);
  },

  postMmcReceiverBle(data) {
    let formData = new FormData();
    formData.append('firmware', data);
    return axios
      .post(`${API_URL}/firmwares/mmc/ble`, formData, {
        headers: this.headers(),
      })
      .then(response => response.data);
  },

  putMmcReceiverBle(data) {
    return axios
      .put(`${API_URL}/firmwares/mmc/ble/${data.id}`, data, {
        headers: this.headers(),
      })
      .then(response => response.data);
  },

  deleteMmcReceiverBle(id) {
    return this.callDelete(`${API_URL}/firmwares/mmc/ble/${id}`);
  },

  /**
   * Receivers MMC RF
   */

  getMmcReceiversRf() {
    return this.callGet(`${API_URL}/firmwares/mmc/rf`);
  },

  postMmcReceiverRf(data) {
    let formData = new FormData();
    formData.append('firmware', data);
    return axios
      .post(`${API_URL}/firmwares/mmc/rf`, formData, {
        headers: this.headers(),
      })
      .then(response => response.data);
  },

  putMmcReceiverRf(data) {
    return axios
      .put(`${API_URL}/firmwares/mmc/rf/${data.id}`, data, {
        headers: this.headers(),
      })
      .then(response => response.data);
  },

  deleteMmcReceiverRf(id) {
    return this.callDelete(`${API_URL}/firmwares/mmc/rf/${id}`);
  },

  /**
   * Firmware groups
   */

  getFirmwareGroups() {
    return this.callGet(`${API_URL}/firmware-groups`);
  },

  postFirmwareGroups(name) {
    return axios
      .post(`${API_URL}/firmware-groups`,
        { name },
        {
          headers: this.headers(),
        },
      )
      .then(response => response.data);
  },

  putFirmwareGroups(id, name) {
    return axios
      .put(`${API_URL}/firmware-groups/${id}`,
        { name },
        {
          headers: this.headers(),
        },
      )
      .then(response => response.data);
  },

  deleteFirmwareGroups(id) {
    return this.callDelete(`${API_URL}/firmware-groups/${id}`);
  },

  postFirmwareGroupItems(id, name) {
    return axios
      .post(`${API_URL}/firmware-groups/${id}/items`,
        { name },
        {
          headers: this.headers(),
        },
      )
      .then(response => response.data);
  },

  deleteFirmwareGroupItems(id, itemId) {
    return this.callDelete(`${API_URL}/firmware-groups/${id}/items/${itemId}`);
  },

  postFirmwareGroupFirmware(id, data) {
    return axios
      .post(`${API_URL}/firmware-groups/${id}/firmwares`,
        data,
        {
          headers: {
            ...this.headers(),
            'Content-Type': 'application/x-www-form-urlencoded',
          },
        },
      )
      .then(response => response.data);
  },

  deleteFirmwareGroupFirmware(id, firmwareId) {
    return this.callDelete(`${API_URL}/firmware-groups/${id}/firmwares/${firmwareId}`);
  },

  /**
   * FirmwareExcludedVersion
   */

  getFirmwareExcludedVersions() {
    return this.callGet(`${API_URL}/firmwares/excluded-versions`);
  },

  postFirmwareExcludedVersions(data) {
    return axios
      .post(`${API_URL}/firmwares/excluded-versions`,
        data,
        {
          headers: this.headers(),
        },
      )
      .then(response => response.data);
  },

  deleteFirmwareExcludedVersions(id) {
    return this.callDelete(`${API_URL}/firmwares/excluded-versions/${id}`);
  },

  /**
   * Users
   */

  getUsers() {
    return this.callGet(`${API_URL}/admins`);
  },

  getUser() {
    return this.callGet(`${API_URL}/users`);
  },

  postUser(data) {
    return axios
      .post(`${API_ROOT_USER}/users`, data)
      .then(response => response.data);
  },

  getTesters() {
    return this.callGet(`${API_URL}/testers`);
  },

  postTester(data) {
    return axios
      .post(`${API_URL}/testers`, data, {
        headers: this.headers(),
      })
      .then(response => response.data);
  },

  deleteTester(email) {
    return axios
      .delete(`${API_URL}/testers`, {
        data: { email },
        headers: this.headers(),
      })
      .then(() => email);
  },

  /** call centers **/

  getCallCenters() {
    return this.callGet(`${API_URL}/call-centers`);
  },

  postCallCenter(data) {
    return axios
      .post(`${API_URL}/call-centers`, data, { headers: this.headers() })
      .then(response => response.data);
  },

  putCallCenter(data) {
    return axios
      .put(`${API_URL}/call-centers/${data.id}`, data, { headers: this.headers() })
      .then(response => response.data);
  },

  deleteCallCenters(data) {
    return this.callDelete(`${API_URL}/call-centers/${data.id}`);
  },

  /** default call centers **/

  getDefaultCallCenters() {
    return this.callGet(`${API_URL}/call-centers/00`);
  },

  putDefaultCallCenter(data) {
    return axios
      .put(`${API_URL}/call-centers/00`, data, { headers: this.headers() })
      .then(response => response.data);
  },

  /** lang call centers **/

  getLangCallCenters() {
    return this.callGet(`${API_URL}/lang-call-centers`);
  },

  postLangCallCenter(data) {
    return axios
      .post(`${API_URL}/lang-call-centers`, data, { headers: this.headers() })
      .then(response => response.data);
  },

  putLangCallCenter(data) {
    return axios
      .put(`${API_URL}/lang-call-centers/${data.lang}`, data, { headers: this.headers() })
      .then(response => response.data);
  },

  deleteLangCallCenters(data) {
    return this.callDelete(`${API_URL}/lang-call-centers/${data.lang}`);
  },

  /** Contact **/

  getContacts() {
    return this.callGet(`${API_URL}/contacts`);
  },

  getDefaultContacts() {
    return this.callGet(`${API_URL}/default-contacts`);
  },

  postContact(data) {
    return axios
      .post(`${API_URL}/contacts/${data.type}`, data, { headers: this.headers() })
      .then(response => {
        const type = response.config.url.split('/').slice(-1)[0];
        return {
          ...response.data,
          type,
        };
      });
  },

  putContact(data) {
    return axios
      .put(`${API_URL}/contacts/${data.type}/${data.id}`, data, { headers: this.headers() })
      .then(response => {
        const type = response.config.url.split('/').slice(-2)[0];
        return {
          ...response.data,
          type,
        };
      });
  },

  putDefaultContacts(data) {
    return axios
      .put(`${API_URL}/default-contacts`, data, { headers: this.headers() })
      .then(response => response.data);
  },

  deleteContact(type, id) {
    return axios
      .delete(`${API_URL}/contacts/${type}/${id}`, {
        headers: this.headers(),
      })
      .then(() => (
        {
          type,
          id,
        }
      ));
  },

  /** Challenge **/

  getChallenges() {
    return this.callGet(`${API_URL}/challenges`);
  },
  postChallenge(data) {
    return axios
      .post(`${API_URL}/challenges`, data, {
        headers: {
          ...this.headers(),
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      })
      .then(response => response.data);
  },
  putChallenge(challengeId, data) {
    return axios
      .post(`${API_URL}/challenges/${challengeId}`, data, {
        headers: {
          ...this.headers(),
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      })
      .then(response => response.data);
  },
  putChallengeState(challengeId, state) {
    return axios
      .put(`${API_URL}/challenges/${challengeId}`, { state }, {
        headers: this.headers(),
      })
      .then(response => response.data);
  },

  /** Catalogs tags **/

  getCatalogsTags() {
    return this.callGet(`${API_URL}/catalogs-tags`);
  },

  postCatalogsTags(data) {
    return axios
      .post(`${API_URL}/catalogs-tags`, data, {
        headers: this.headers(),
      })
      .then(response => response.data);
  },

  putCatalogsTags(tagId, data) {
    return axios
      .put(`${API_URL}/catalogs-tags/${tagId}`, data, {
        headers: this.headers(),
      })
      .then(response => response.data);
  },

  deleteCatalogsTags(tagId) {
    return this.callDelete(`${API_URL}/catalogs-tags/${tagId}`);
  },

  /** Catalogs items **/

  getCatalogsItems() {
    return this.callGet(`${API_URL}/catalogs-items`);
  },

  postCatalogsItem(data) {
    return axios
      .post(`${API_URL}/catalogs-items`,
        data,
        {
          headers: {
            ...this.headers(),
            'Content-Type': 'application/x-www-form-urlencoded',
          },
        },
      )
      .then(response => response.data);
  },

  putCatalogsItem(itemId, data) {
    return axios
      .post(`${API_URL}/catalogs-items/${itemId}`,
        data,
        {
          headers: {
            ...this.headers(),
            'Content-Type': 'application/x-www-form-urlencoded',
          },
        },
      )
      .then(response => response.data);
  },

  /** in app notification */


  getInAppNotification() {
    return this.callGet(`${API_URL}/in-app-notifications`);
  },

  postInAppNotification(data) {
    return axios
      .post(`${API_URL}/in-app-notifications`,
        data,
        {
          headers: {
            ...this.headers(),
            'Content-Type': 'application/x-www-form-urlencoded',
          },
        },
      )
      .then(response => response.data);
  },

  putInAppNotification(id, data) {
    return axios
      .post(`${API_URL}/in-app-notifications/${id}`,
        data,
        {
          headers: {
            ...this.headers(),
            'Content-Type': 'application/x-www-form-urlencoded',
          },
        },
      )
      .then(response => response.data);
  },

  deleteInAppNotification(id) {
    return this.callDelete(`${API_URL}/in-app-notifications/${id}`);
  },

  /* FAQ articles */

  getFaqArticles() {
    return this.callGet(`${API_URL}/faq/articles`);
  },
  postFaqArticles(data) {
    return axios
      .post(`${API_URL}/faq/articles`, data, {
        headers: this.headers(),
      })
      .then(response => response.data);
  },
  deleteFaqArticles(id) {
    return this.callDelete(`${API_URL}/faq/articles/${id}`);
  },
  putFaqArticles(id, data) {
    return axios
      .put(`${API_URL}/faq/articles/${id}`, data, {
        headers: this.headers(),
      })
      .then(response => response.data);
  },

  /* FAQ elements */
  getFaqElements() {
    return this.callGet(`${API_URL}/faq/elements`);
  },

  postFaqElements(data) {
    return axios
      .post(`${API_URL}/faq/elements`, data, {
        headers: this.headers(),
      })
      .then(response => response.data);
  },

  putFaqElements(id, data) {
    return axios
      .put(`${API_URL}/faq/elements/${id}`, data, {
        headers: this.headers(),
      })
      .then(response => response.data);
  },

  deleteFaqElements(id, newParentId) {
    let url = `${API_URL}/faq/elements/${id}`;
    if (newParentId) {
      url += `?new_parent_id=${newParentId}`;
    }
    return axios
      .delete(url, {
        headers: this.headers(),
      })
      .then(response => response.data);
  },

  /* Support */
  getAuthorizedExports() {
    return this.callGet(`${API_URL}/support/authorized-exports`);
  },

  postAuthorizedExports(data) {
    return axios
      .post(`${API_URL}/support/authorized-exports`, data, {
        headers: this.headers(),
      })
      .then(response => response.data);
  },

  putAuthorizedExports(id, data) {
    return axios
      .put(`${API_URL}/support/authorized-exports/${id}`, data, {
        headers: this.headers(),
      })
      .then(response => response.data);
  },

  deleteAuthorizedExports(id) {
    return this.callDelete(`${API_URL}/support/authorized-exports/${id}`);
  },

  getExports(id, startDate = null, endDate = null) {
    let url = `${API_URL}/support/authorized-exports/${id}/export`;
    if (startDate) {
      url += `?start_date=${startDate}`;
    }
    if (endDate) {
      if (startDate) {
        url += '&';
      } else {
        url += '?';
      }
      url += `end_date=${endDate}`;
    }
    return axios
      .get(url, {
        headers: this.headers(),
        responseType: 'arraybuffer' // transform correctly data into zip file
      }).then((response) => {
        //const type = response.headers['content-type'];
        const blob = new Blob([response.data], { type: 'application/zip' });
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        const d = new Date();
        const year = d.getFullYear();
        let month = d.getMonth() + 1;
        if (month < 10) {
          month = '0' + month.toString()
        }
        const day = d.getDate();
        link.setAttribute('download', `package_${day}-${month}-${year}.zip`);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      }).catch((response) => {
        console.error('Download error', response);
      });
  },

};

export default ApiService;
