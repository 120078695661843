import React from 'react';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import MTCSpinner from '../../Layout/MTCSpinner';
import Typography from '@material-ui/core/Typography';
import { useStyles } from './MTCButton.style';

const MTCButton = (props) => {

  const {
    id,
    disabled,
    sending,
    text,
    type,
    onClick,
    style,
    icon,
    color,
    startIcon
  } = props;
  const classes = useStyles();
  return <Button
    id={id}
    className={classes.button}
    variant="contained"
    color={color || 'secondary'}
    disabled={disabled || sending}
    type={type
      ? type
      : 'button'}
    onClick={onClick}
    style={style}
    startIcon={startIcon}
  >
    {sending
      ? <MTCSpinner />
      : <div>
        <Typography variant={'button'} className={classes.text}>{icon} {text}</Typography>
      </div>
    }
  </Button>;

};

MTCButton.propTypes = {
  id: PropTypes.string,
  disabled: PropTypes.bool,
  sending: PropTypes.bool,
  text: PropTypes.string.isRequired,
  type: PropTypes.string,
  color: PropTypes.string,
  onClick: PropTypes.func,
  icon: PropTypes.object,
  style: PropTypes.object,
};

export default React.memo(MTCButton);
