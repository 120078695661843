import React from 'react';
import withAuthentication from '../../../../hoc/withAuthentication';
import { ROLE_ADMIN } from '../../../../constants/roles';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { setTitle } from '../../../../store/title/actions';
import { useParams } from 'react-router';
import FirmwareGroupFirmwareForm
  from '../../../../components/Receivers/FirmwareGroups/Firmwares/FirmwareGroupFirmwareForm';

export const AddFirmwareGroupFirmwarePage = () => {
  const { t } = useTranslation();
  const { title } = useSelector(state => state.title);
  const dispatch = useDispatch();
  const { groupId } = useParams();

  if (title !== (
    t('title:title.firmware_group_firmware_add_page')
  )) {
    dispatch(setTitle(t('title:title.firmware_group_firmware_add_page')));
  }

  return <FirmwareGroupFirmwareForm id={groupId} />;
};

export default withAuthentication(ROLE_ADMIN)(AddFirmwareGroupFirmwarePage);
