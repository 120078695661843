const IN_APP_NOTIFICATIONS_PENDING = 'in_app_notifications_pending';
const IN_APP_NOTIFICATIONS_FAILED = 'in_app_notifications_failed';
const IN_APP_NOTIFICATIONS_DOWNLOADED = 'in_app_notifications_downloaded';
const IN_APP_NOTIFICATION_DOWNLOADED = 'in_app_notification_downloaded';
const IN_APP_NOTIFICATION_SENDING = 'in_app_notification_sending';
const IN_APP_NOTIFICATION_DELETED = 'in_app_notification_deleted';

export {
  IN_APP_NOTIFICATIONS_PENDING,
  IN_APP_NOTIFICATIONS_FAILED,
  IN_APP_NOTIFICATIONS_DOWNLOADED,
  IN_APP_NOTIFICATION_DOWNLOADED,
  IN_APP_NOTIFICATION_SENDING,
  IN_APP_NOTIFICATION_DELETED
};
