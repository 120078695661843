import React from 'react';
import Typography from '@material-ui/core/Typography';
import MTCSpinner from '../Layout/MTCSpinner';
import { useTranslation } from 'react-i18next';
import { useStyles } from './MTCLoader.style';

const MTCLoader = (props) => {
  const { secondary } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div className={classes.container}>
      <MTCSpinner secondary={secondary} />
      <Typography
        variant="h6"
        gutterBottom
      >
        {t('loading')}
      </Typography>
    </div>
  );
};

export default React.memo(MTCLoader);
