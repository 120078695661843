import {
  CALL_CENTER_DOWNLOADED,
  CALL_CENTER_SENDING,
  CALL_CENTERS_DOWNLOADED,
  CALL_CENTERS_FAILED,
  CALL_CENTERS_PENDING,
  CALL_CENTER_DELETED,
  CALL_CENTERS_UPLOADED
} from './types';
import ApiService from '../../services/ApiService';
import { handleError } from '../infos/actions';

export const callCenterSendingAction = () => ({
  type: CALL_CENTER_SENDING,
});

export const callCenterDownloadedAction = callCenter => ({
  type: CALL_CENTER_DOWNLOADED,
  payload: callCenter,
});

export const callCentersDownloadedAction = callCenters => ({
  type: CALL_CENTERS_DOWNLOADED,
  payload: callCenters,
});

export const callCentersUploadedAction = callCenters => ({
  type: CALL_CENTERS_UPLOADED,
  payload: callCenters,
});

export const callCentersFailedAction = error => ({
  type: CALL_CENTERS_FAILED,
  payload: error,
});

export const callCentersPendingAction = () => ({
  type: CALL_CENTERS_PENDING,
});

export const callCenterDeleteAction = callCenter => ({
  type: CALL_CENTER_DELETED,
  payload: callCenter,
});

export function getCallCenters() {
  return (dispatch) => {
    dispatch(callCentersPendingAction());
    return ApiService
      .getCallCenters()
      .then((data) => {
        dispatch(callCentersDownloadedAction(data));
      })
      .catch((error) => {
        dispatch(callCentersFailedAction(error));
        dispatch(handleError(error));
      });
  };
}

export function deleteCallCenters(callCenter) {
  return (dispatch) => {
    dispatch(callCenterSendingAction());
    return ApiService
      .deleteCallCenters(callCenter)
      .then((data) => {
        dispatch(callCenterDeleteAction(callCenter));
      })
      .catch((error) => {
        dispatch(callCentersFailedAction(error));
        dispatch(handleError(error));
      });
  };
}

export function postCallCenter(callCenter) {
  return (dispatch) => {
    dispatch(callCenterSendingAction());
    return ApiService
      .postCallCenter(callCenter)
      .then((data) => {
        dispatch(callCentersUploadedAction(data));
        return data.id;
      })
      .catch((error) => {
        dispatch(callCentersFailedAction(error));
        dispatch(handleError(error));
      });
  };
}

export function putCallCenter(callCenter) {
  return (dispatch) => {
    dispatch(callCenterSendingAction());
    return ApiService
      .putCallCenter(callCenter)
      .then((data) => {
        dispatch(callCenterDownloadedAction(data));
      })
      .catch((error) => {
        dispatch(callCentersFailedAction(error));
        dispatch(handleError(error));
      });
  };
}
