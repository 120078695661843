const CHALLENGES_PENDING = 'challenges_pending';
const CHALLENGES_FAILED = 'challenges_failed';
const CHALLENGES_DOWNLOADED = 'challenges_downloaded';
const CHALLENGE_DOWNLOADED = 'challenge_downloaded';
const CHALLENGE_SENDING = 'challenge_sending';

export {
  CHALLENGES_PENDING,
  CHALLENGES_FAILED,
  CHALLENGES_DOWNLOADED,
  CHALLENGE_DOWNLOADED,
  CHALLENGE_SENDING
};
