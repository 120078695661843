import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Timestamp from 'react-timestamp';
import useTheme from '@material-ui/core/styles/useTheme';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Select from '@material-ui/core/Select';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { REQUEST_STATE, REQUEST_TRACK } from '../../../store/requests/types';
import MTCButton from '../../Layout/Button/MTCButton';
import { putRequest } from '../../../store/requests/actions';
import { dispatchInfo } from '../../../store/infos/actions';
import { useStyles } from './EditCircuitRequest.style';
import RequestUserCard from '../RequestUserCard';

const EditCircuitRequest = (props) => {
  const theme = useTheme();
  const classes = useStyles(theme);

  const [request, setRequest] = useState({ ...props.request });
  const {
    content,
    sending,
  } = useSelector(state => state.requests);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [state, setState] = useState(request.request_state);
  const [user, setUser] = useState(null);

  let mailSubject = 'New Circuit : ' + request.name;

  if (user === null && request.user_id) {
    let requestUser = content.users.find(user => user.id === request.user_id);
    if (requestUser) {
      setUser(requestUser);
    } else {
      setUser(false);
    }
  }

  const onSelectChange = (event) => {
    setState(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();
    dispatch(putRequest({
      ...request,
      request_state: state,
    }, REQUEST_TRACK))
      .then(() => {
        dispatch(dispatchInfo(t('success')));
        setRequest({
          ...request,
          request_state: state,
        });
      });
  };

  let actualStale = '';
  switch (request.request_state) {
    case REQUEST_STATE.NEW:
      actualStale = t('new');
      break;
    case REQUEST_STATE.IN_PROGRESS:
      actualStale = t('in_progress');
      break;
    case REQUEST_STATE.NOT_DONE:
      actualStale = t('not_done');
      break;
    case REQUEST_STATE.DONE:
      actualStale = t('done');
      break;
    default:
      console.error("Wrong request state : " + request.request_state);
      break;
  }

  return (
    <Grid
      container
      direction={'column'}
      spacing={0}
      className={classes.container}
    >
      <Grid item xs={12}>
        <Typography
          component="p"
          variant="h3"
          className={classes.title}
        >
          Circuit
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={2} alignItems={'flex-start'} justifyContent={'center'}>
          <RequestUserCard request={request} classes={classes} mailSubject={mailSubject}/>
          <Grid item xs={12} md={6} xl={4}>
            <Paper className={classes.information}>
              <Typography
                component="p"
                variant="h4"
                color={'primary'}
                className={classes.title}
              >
                {t('request')}
              </Typography>
              <p className={classes.text}>
                <Typography
                  component="span"
                  variant="subtitle1"
                  color={'primary'}
                  className={classes.textLabel}
                >
                  {t('created_at')}
                </Typography>
                <Timestamp
                  format='full'
                  twentyFourHour
                  time={request.created_at}
                />
              </p>
              <p className={classes.text}>
                <Typography
                  component="span"
                  variant="subtitle1"
                  color={'primary'}
                  className={classes.textLabel}
                >
                  {t('state')}
                </Typography>
                {actualStale}
              </p>
              <p className={classes.text}>
                <Typography
                  component="span"
                  variant="subtitle1"
                  color={'primary'}
                  className={classes.textLabel}
                >
                  {t('name')}
                </Typography>
                {request.name}
              </p>
              <p className={classes.text}>
                <Typography
                  component="span"
                  variant="subtitle1"
                  color={'primary'}
                  className={classes.textLabel}
                >
                  {t('country_code')}
                </Typography>
                {request.country_code}
              </p>
            </Paper>
          </Grid>
          <Grid item xs={12} md={6} xl={4}>
            <Paper className={classes.information}>
              <Typography
                component="p"
                variant="h4"
                color={'primary'}
                className={classes.title}
              >
                {t('edit')}
              </Typography>
              <form style={{ textAlign: 'left', width: '100%', }}>
                <Grid
                  container
                  alignItems={'center'}
                  className={classes.grid}
                  direction={'column'}
                  justifyContent={'center'}
                  spacing={2}
                >
                  <Grid item xs={12}>
                    <FormControl margin={'normal'} style={{ width: '100%', }}>
                      <FormLabel required={true}>
                        {t('state')}
                      </FormLabel>
                      <Select
                        id="select"
                        value={state}
                        onChange={onSelectChange}
                        className={classes.input}
                      >
                        {REQUEST_STATE.properties.map(property => (
                          <MenuItem
                            key={property.value}
                            value={property.value}
                          >
                            {t(property.name)}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item>
                    <MTCButton
                      disabled={false}
                      onClick={handleSubmit}
                      type={'button'}
                      sending={sending}
                      text={t('edit')}
                    />
                  </Grid>
                </Grid>
              </form>
            </Paper>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default EditCircuitRequest;
