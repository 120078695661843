import React from 'react';
import { useStyles } from './TrackDetails.style';
import { Grid, useTheme } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import TrackMapBox from '../TrackMapBox';
import {
  GOOGLE_MAPS_API_KEY,
  GOOGLE_MAPS_HOST,
  GOOGLE_MAPS_OPTIONS,
} from '../TrackForm/TrackForm.const';
import TrackCommentsTab from '../TrackCommentsTab/TrackCommentsTab';
import { ROLE_ADMIN } from '../../../constants/roles';

const TrackDetails = ({ circuit }) => {

  const theme = useTheme();
  const { t } = useTranslation();
  const classes = useStyles(theme);
  const role = localStorage.getItem('role');

  return <>
    <Grid container spacing={0} className={classes.mapContainer}>
      {circuit.picture
      && <div className={classes.pictureBloc}>
        <div className={classes.centerImg}>
          <img  className={classes.img} src={circuit.picture} alt={t('no_picture')}/>
        </div>
      </div>
      }
      <Grid item sm={12} className={classes.mapBloc}>
        <TrackMapBox
          disableEditing
          isMarkerShown={true}
          handleMarker={null}
          handleDragMarker={null}
          trackMarker={circuit.latitude && circuit.longitude
            ? {
              latitude: Number(circuit.latitude),
              longitude: Number(circuit.longitude),
            }
            : null}
          startPointMarker={circuit.start_point_latitude && circuit.start_point_longitude
            ? {
              latitude: Number(circuit.start_point_latitude),
              longitude: Number(circuit.start_point_longitude),
            }
            : null}
          endPointMarker={circuit.end_point_latitude && circuit.end_point_longitude
            ? {
              latitude: Number(circuit.end_point_latitude),
              longitude: Number(circuit.end_point_longitude),
            }
            : null}
          finishStartPointMarker={circuit.has_finish_line
          && circuit.finish_start_point_latitude
          && circuit.finish_start_point_longitude
            ? {
              latitude: Number(circuit.finish_start_point_latitude),
              longitude: Number(circuit.finish_start_point_longitude),
            }
            : null}
          finishEndPointMarker={circuit.has_finish_line
          && circuit.finish_end_point_latitude
          && circuit.finish_end_point_longitude
            ? {
              latitude: Number(circuit.finish_end_point_latitude),
              longitude: Number(circuit.finish_end_point_longitude),
            }
            : null}
          hasFinishLine={circuit.has_finish_line}
          sectors={circuit.sectors.filter((sector) =>
            sector.version == circuit.last_sector_version
          ) || []}
          geojson={circuit.geojson}
          googleMapURL={GOOGLE_MAPS_HOST + GOOGLE_MAPS_API_KEY + GOOGLE_MAPS_OPTIONS}
          loadingElement={<div style={{ height: `100%` }} />}
          containerElement={<div style={{
            height: `400px`,
            width: `100%`,
          }} />}
          mapElement={<div style={{ height: `100%` }} />}
          lapLineBearing={circuit.lap_line_bearing}
          finishLapLineBearing={circuit.finish_lap_line_bearing}
          />
      </Grid>
    </Grid>
    {role === ROLE_ADMIN && <TrackCommentsTab id={circuit.id}/>}
  </>
}

export default React.memo(TrackDetails);
