import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { getChallenges } from '../../../store/challenges/actions';
import MTCLoader from '../../Layout/MTCLoader';
import MTCTable from '../../Layout/Table/MTCTable';
import ClassicChallengeRow from './ClassicChallengeRow';
import { columns, defaultOrderBy } from './ClassicChallengeTable.const';

const ClassicChallengeTable = () => {
  const {
    pending,
    content,
    error,
  } = useSelector(state => state.challenges);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  if (!content && !pending && !error) {
    dispatch(getChallenges());
  }

  if (pending) {
    return <MTCLoader />;
  } else {
    return <MTCTable
      content={content && content.filter(challenge => challenge.type === 0)}
      defaultOrderBy={defaultOrderBy}
      rowComponent={ClassicChallengeRow}
      columns={columns}
      paginationLabel={t('challenges_per_page')}
      emptyDataLabel={t('no_challenge')}
    />;
  }
};

export default ClassicChallengeTable;
