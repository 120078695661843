import React, { useState } from 'react';
import { useHistory, matchPath } from 'react-router';
import { useTranslation } from 'react-i18next';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import { shallowEqual, useSelector } from 'react-redux';
import { Badge, Container } from '@material-ui/core';
import useTheme from '@material-ui/core/styles/useTheme';
import Typography from '@material-ui/core/Typography';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import MenuIcon from '@material-ui/icons/Menu';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import NotificationImportantIcon from '@material-ui/icons/NotificationImportant';
import Hidden from '@material-ui/core/Hidden';
import CssBaseline from '@material-ui/core/CssBaseline';
import TranslateIcon from '@material-ui/icons/Translate';
import Menu from './Menu/Menu';
import InfoSnackbar from '../Snackbar/InfoSnackbar';
import ErrorSnackbar from '../Snackbar/ErrorSnackbar';
import * as routes from '../../../constants/routes';
import { TRACKS } from '../../../constants/routes';
import Auth from '../../../services/Auth/Auth';
import MTCButton from '../Button/MTCButton';
import LangMenu from './LangMenu/LangMenu';
import { OPTIONS } from '../../../constants/overlay-scrollbars-options';
import { useStyles } from './MTCContainer.style';
import CircuitAlertMenu from './CircuitAlertMenu/CircuitAlertMenu';
import { ROLE_ADMIN } from '../../../constants/roles';

const auth = new Auth();

export const MTCContainer = (props) => {

  const theme = useTheme();
  const { t } = useTranslation();
  const classes = useStyles(theme);
  const history = useHistory();
  const title = useSelector(state => state.title.title, shallowEqual);
  const back = useSelector(state => state.title.back, shallowEqual);
  const backParams = useSelector(state => state.title.backParams, shallowEqual);
  const displayLangMenu = useSelector(state => state.title.displayLangMenu, shallowEqual);
  const role = localStorage.getItem('role');
  const circuitAlertMenuCount = useSelector(state =>
    state.home.reportedCircuitCommentTotalCount, shallowEqual,
  );

  const [drawerOpen, setDrawerOpen] = useState(false);
  const [langDrawerOpen, setLangDrawerOpen] = useState(false);
  const [circuitAlertDrawerOpen, setCircuitAlertDrawerOpen] = useState(false);

  const handleOpenDrawer = () => {
    setDrawerOpen(true);
  };

  const handleCloseDrawer = () => {
    setDrawerOpen(false);
  };

  const handleOpenLangDrawer = () => {
    setLangDrawerOpen(true);
  };

  const handleCloseLangDrawer = () => {
    setLangDrawerOpen(false);
  };

  const handleOpenCircuitAlertDrawer = () => {
    setCircuitAlertDrawerOpen(true);
  };

  const handleCloseCircuitAlertDrawer = () => {
    setCircuitAlertDrawerOpen(false);
  };

  const handleClickBack = () => {
    history.push({
      pathname: back,
      state: backParams,
    });
  };
  let matchMenuRoutes = false;
  routes.ALL_MENU_BASE_ROUTES.forEach(r => {
    matchMenuRoutes = matchPath(history.location.pathname, r) || matchMenuRoutes;
  });

  let hasMenu = matchMenuRoutes
    && history.location.pathname !== routes.STORE
    && history.location.pathname.indexOf('/apidoc') < 0
    && !history.location.pathname.startsWith(routes.USER_RETROSPECTIVE_BASE);
  let isSpecialPage = history.location.pathname.startsWith(routes.USER_RETROSPECTIVE_BASE);
  return (
    <OverlayScrollbarsComponent
      options={OPTIONS}
      className={classes.scrollContainer}
    >
      <>
        <CssBaseline />
        {isSpecialPage && props.children}
        {!isSpecialPage && <>
          {hasMenu
            && <Menu
              auth={auth}
              drawerOpen={drawerOpen}
              handleCloseDrawer={handleCloseDrawer}
            />}
          <LangMenu
            drawerOpen={langDrawerOpen}
            handleCloseDrawer={handleCloseLangDrawer}
          />
          {role === ROLE_ADMIN &&
            <CircuitAlertMenu
              drawerOpen={circuitAlertDrawerOpen}
              handleCloseDrawer={handleCloseCircuitAlertDrawer}
            />
          }
          {hasMenu
            && <Hidden mdUp>
              <AppBar
                position={'static'}
                className={classes.appBar}
              >
                <Toolbar>
                  <IconButton
                    id="menu"
                    onClick={handleOpenDrawer}
                    className={classes.menuButton}
                    color="primary"
                  >
                    <MenuIcon />
                  </IconButton>
                  <Typography
                    variant={'h6'}
                  >
                    {title}
                  </Typography>
                  {Auth.isAuthenticated()
                    && <IconButton
                      aria-label="change lang"
                      className={classes.langButton}
                      onClick={handleOpenLangDrawer}
                    >
                      <TranslateIcon />
                    </IconButton>}
                </Toolbar>
              </AppBar>
            </Hidden>}
          <Container
            className={hasMenu
              ? classes.grid
              : ''}
          >
            <Hidden mdUp>
              {back && <div className={classes.backButton}>
                <MTCButton
                  text={t('back')}
                  onClick={handleClickBack}
                  icon={<ArrowBackIosIcon className={classes.backIcon} />}
                  color={'primary'}
                />
              </div>}
            </Hidden>
            <Hidden smDown>
              {back && <Typography
                variant={'body1'}
                align={'left'}
                gutterBottom={false}
                className={classes.back}
                onClick={handleClickBack}
              >
                <ArrowBackIosIcon className={classes.backIcon} /> {t('back')}
              </Typography>}
              {title && <Typography
                variant={'h2'}
                align={'left'}
                gutterBottom={true}
                className={(
                  back
                    ? classes.textTitleWithBack
                    : classes.textTitle
                )}
              >
                {title}
              </Typography>}
              {Auth.isAuthenticated() && role === ROLE_ADMIN && circuitAlertMenuCount > 0
                && history.location.pathname.startsWith(TRACKS)
                && <IconButton
                  aria-label="Circuit alert"
                  className={!displayLangMenu
                    ? classes.langButton
                    : classes.alertButton}
                  onClick={handleOpenCircuitAlertDrawer}
                >
                  <Badge
                    badgeContent={circuitAlertMenuCount}
                    color="error"
                  >
                    <NotificationImportantIcon />
                  </Badge>
                </IconButton>}
              {Auth.isAuthenticated() && displayLangMenu
                && <IconButton
                  aria-label="change lang"
                  className={classes.langButton}
                  onClick={handleOpenLangDrawer}
                >
                  <TranslateIcon />
                </IconButton>}
            </Hidden>
            <InfoSnackbar />
            <ErrorSnackbar />
            {props.children}
          </Container>
        </>}
      </>
    </OverlayScrollbarsComponent>
  );
};

export default MTCContainer;
