import {
  RECEIVER_MTC_BLE_DOWNLOADED,
  RECEIVER_MTC_BLE_FAILED,
  RECEIVER_MTC_BLE_SENDING,
  RECEIVERS_MTC_BLE_DOWNLOADED,
  RECEIVERS_MTC_BLE_PENDING,
  RECEIVERS_MTC_BLE_DELETED
} from './types';

export const INITIAL_STATE = {
  error: null,
  content: null,
  pending: false,
  sending: false,
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case RECEIVER_MTC_BLE_DOWNLOADED: {
      let receivers;
      let receiverIndex = -1;
      if (state.content && state.content.length > 0) {
        receivers = state.content.slice();
        receiverIndex = receivers.findIndex(receiver => receiver.id === action.payload.id);
      } else {
        receivers = [];
      }
      if (receiverIndex === -1) {
        receivers.push(action.payload);
      } else {
        receivers[receiverIndex] = action.payload;
      }
      return {
        error: null,
        pending: false,
        sending: false,
        content: receivers,
      };
    }
    case RECEIVERS_MTC_BLE_PENDING:
      return {
        ...state,
        pending: true,
        error: null,
      };

    case RECEIVER_MTC_BLE_FAILED:
      return {
        ...state,
        error: action.payload,
        pending: false,
        sending: false,
      };

    case RECEIVER_MTC_BLE_SENDING:
      return {
        ...state,
        sending: true,
        error: null,
      };

    case RECEIVERS_MTC_BLE_DOWNLOADED:
      return {
        error: null,
        pending: false,
        sending: false,
        content: action.payload,
      };
    case RECEIVERS_MTC_BLE_DELETED: {
      let newContent = [...state.content];
      if (newContent.length > 0) {
        let firmwareIndex = newContent.findIndex(firmware => firmware.id === action.payload);
        if (firmwareIndex > -1) {
          newContent.splice(firmwareIndex, 1);
        }
      }
      return {
        error: null,
        pending: false,
        sending: false,
        content: newContent
      };
    }
    default:
      break;
  }
  return state;
}
