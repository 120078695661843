import i18n from 'i18next';
import { ERROR, ERROR_RESET, INFO, INFO_RESET } from './types';

// Actions creator

export function resetInfoAction() {
  return { type: INFO_RESET };
}

export function genericInfoAction(info) {
  return {
    type: INFO,
    payload: info,
  };
}

export function resetErrorAction() {
  return { type: ERROR_RESET };
}

export function genericErrorAction(error) {
  return {
    type: ERROR,
    payload: error,
  };
}

// Actions

export function resetError() {
  return (dispatch) => {
    dispatch(resetErrorAction());
  };
}

export function resetInfo() {
  return (dispatch) => {
    dispatch(resetInfoAction());
  };
}

export function dispatchInfo(info) {
  return (dispatch) => {
    dispatch(genericInfoAction(info));
  };
}

export function handleError(error, type = null) {

  const t = i18n.t.bind(i18n);
  return (dispatch) => {
    let event = {
      type: type,
      payload: t('error.default'),
    };
    if (error) {
      if (error.response) {
        if (error.response.status === 401 || error.response.status === 403) {
          event.payload = t('error.unauthorized');
        } else if (error.response.status === 404) {
          event.payload = t('error.not_found');
        } else if (error.response.statusText) {
          event.payload = error.response.statusText;
        } else {
          if (error.response.data && error.response.data.error) {
            event.payload = error.response.data.error;
          } else if (error.response.data && error.response.data.message) {
            event.payload = error.response.data.message;
          } else if (error.response.data) {
            event.payload = error.response.data;
          }
        }
      } else if (error.message) {
        event.payload = error.message;
      }
    }

    // Dispatch real event error
    if (type) {
      dispatch(event);
    }

    // Dispatch generic error
    dispatch(genericErrorAction(event.payload));
  };
}
