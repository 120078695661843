import { PSC2, PSC2R } from '../../../constants/raceTireType';

export const INITIAL_STATE = {
  brand: null,
  range: null,
  model: null,
  engine: null,
  confirmDialogOpen: false,
  dimension: null,
  boundaries: [],
};

export function stateToApiFormat(state, dimensionId) {
  let apiBoundaries = [];
  if (state.dimension.compatible_with_receiver) {
    if (state.dimension.has_psc2 && state.dimension.has_psc2r) {
      apiBoundaries = state.boundaries;
    } else if (state.dimension.has_psc2) {
      apiBoundaries = state.boundaries.filter(b => b.tire_type === PSC2);
    } else if (state.dimension.has_psc2r) {
      apiBoundaries = state.boundaries.filter(b => b.tire_type === PSC2R);
    }
  }
  return {
    'brand': state.brand,
    'range': state.range,
    'range_model': state.model,
    'engine': state.engine,
    'dimension': {
      ...state.dimension,
      id: parseInt(dimensionId),
      front_pressure: parseFloat(state.dimension.front_pressure),
      rear_pressure: parseFloat(state.dimension.rear_pressure),
      available_tire_types: state.dimension.available_tire_types.join(','),
      state: state.dimension.state
        ? 0
        : 1,
    },
    'boundaries': apiBoundaries,
  };
}
