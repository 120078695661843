import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { ROLE_ADMIN } from '../../constants/roles';
import withAuthentication from '../../hoc/withAuthentication';
import { setTitle } from '../../store/title/actions';
import LinkBlock from '../../components/Layout/Menu/LinkBlock';
import ApiService from '../../services/ApiService';
import FirmwareGroupsTable from '../../components/Receivers/FirmwareGroups/FirmwareGroupsTable';
import { getFirmwareGroups } from '../../store/firmwareGroups/actions';
import { getFirmwareExcludedVersions } from '../../store/firmwareExcludedVersions/actions';
import ExcludedVersionsTable
  from '../../components/Receivers/ExcludedVersions/ExcludedVersionsTable';
import FirmwareGroupMenuButton from '../../components/Receivers/ReceiverMenuButton';

const useStyles = makeStyles(() => {
  return {
    container: {
      margin: '0 -12px'
    }
  }
});

const initialVersions = {
  last_mtc_rf_version: "",
  last_mtc_ble_version: "",
  last_mmc_rf_version: "",
  last_mmc_ble_version: ""
};

export function ReceiverPage() {

  const { t } = useTranslation();
  const { title } = useSelector(state => state.title);
  const dispatch = useDispatch();
  const classes = useStyles();
  const [versions, setVersions] = useState(null);
  const firmwareGroups = useSelector(state => state.firmwareGroups);
  const firmwareExcludedVersions = useSelector(state => state.firmwareExcludedVersions);

  if (title !== t('title:title.receiver_page')) {
    dispatch(setTitle(t('title:title.receiver_page')));
  }

  if (versions === null) {
    setVersions(initialVersions);
    ApiService.getReceiversLastVersion().then(data => setVersions(data))
  }

  if (!firmwareGroups.content && !firmwareGroups.pending && !firmwareGroups.error) {
    dispatch(getFirmwareGroups());
  }

  if (
    !firmwareExcludedVersions.content
    && !firmwareExcludedVersions.pending
    && !firmwareExcludedVersions.error
  ) {
    dispatch(getFirmwareExcludedVersions());
  }

  return (
    <Grid
      spacing={3}
      container
      direction="column"
      className={classes.container}
    >
      <Typography variant="h5" color="inherit">
        {t('public_firmwares')}
      </Typography>
      <LinkBlock
        key={'mtc'}
        title={'MTC'}
        versions={versions}
      />
      <LinkBlock
        key={'mmc'}
        title={'MMC'}
        versions={versions}
      />
      <Typography variant="h5" color="inherit">
        {t('beta_testers_firmwares')}
      </Typography>
      <FirmwareGroupsTable pending={firmwareGroups.pending} content={firmwareGroups.content} />
      <Typography variant="h5" color="inherit">
        {t('firmware_excluded_versions')}
      </Typography>
      <Typography variant="body1" color="inherit">
        {t('firmware_excluded_versions_description_part1')}
      </Typography>
      <Typography variant="body1" color="inherit">
        {t('firmware_excluded_versions_description_part2')}
      </Typography>
      <ExcludedVersionsTable
        pending={firmwareExcludedVersions.pending}
        content={firmwareExcludedVersions.content}
      />
      <FirmwareGroupMenuButton />
    </Grid>

  );
}

export default withAuthentication(ROLE_ADMIN)(ReceiverPage);
