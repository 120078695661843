import makeStyles from '@material-ui/core/styles/makeStyles';

export const useStyles = makeStyles(() => {
  return {
    paper: {
      width: 'calc(100% - 2em)',
      paddingBottom: '1em',
      paddingLeft: '1em',
      paddingRight: '1em',
      paddingTop: '0.5em',
      margin: '1em',
    },
    select: {
      width: 200,
    },
    form: {
      marginBottom: '1em',
    },
    link: {
      padding: '5px 0',
    },
  };
});
