export default class CarFormatter {
  static formatValue = (key, value) => {
    // Dimension Case
    if (key.includes('pressure')) {
      value = Number(value);
    }
    return value;
  };

  static canBeEdited = (key) => {
    return key === 'engine';
  };
}
