export const INITIAL_STATE = {
  id: '',
  country_code: '',
  lang: '',
  phone: '',
  opening: 1,
};
export const ERROR_STATE = {
  phoneError: null,
};
