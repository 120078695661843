import React from 'react';
import Typography from '@material-ui/core/Typography';
import { useStyles } from './NumberKPIBlock.style';
import KpiPaper from '../KpiPaper/KpiPaper';

const NumberKPIBlock = ({ number, title, description, isInt }) => {

  const classes = useStyles();

  return (
    <KpiPaper>
      <Typography
        variant="h1"
        color="primary"
        component="p"
        className={classes.number}
      >
        {isInt ? number : number.toFixed(2)}
      </Typography>
      <Typography
        variant="h3"
        color="primary"
        component="p"
        className={classes.title}
      >
        {title}
      </Typography>
      <Typography
        variant="body1"
        component="p"
      >
        {description}
      </Typography>
    </KpiPaper>
  );
};

NumberKPIBlock.defaultProps = {
  isInt: true
}

export default NumberKPIBlock;
