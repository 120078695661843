import React, { useState } from 'react';
import { Fab, Menu, MenuItem } from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { FIRMWARE_EXCLUDED_VERSIONS_ADD, FIRMWARE_GROUP_ADD } from '../../constants/routes';
import { useStyles } from './ReceiverMenuButton.style';

const FirmwareGroupMenuButton = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const history = useHistory();

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };


  return (
    <div>
      <Fab
        className={classes.button}
        color="secondary"
        aria-owns={anchorEl
          ? 'simple-menu'
          : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon />
      </Fab>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={() => history.push(FIRMWARE_GROUP_ADD)}>
          {t('receiver_page.menu.message_add_firmware_group')}
        </MenuItem>
        <MenuItem onClick={() => history.push(FIRMWARE_EXCLUDED_VERSIONS_ADD)}>
          {t('receiver_page.menu.message_add_firmware_excluded_versions')}
        </MenuItem>
      </Menu>
    </div>
  );
};

export default FirmwareGroupMenuButton;
