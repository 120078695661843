import { ContentState, convertToRaw, EditorState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';

export const editorStateToHtml = (editorState) => {
  if (editorState === undefined || editorState === null) {
    return null;
  }
  if (editorState.getCurrentContent().hasText()) {
    const tmpVal = convertToRaw(editorState.getCurrentContent());
    return draftToHtml(tmpVal);
  } else {
    return null;
  }
};

export const htmlToEditorState = (html) => {
  if (html === undefined || html === null || html.trim() === '') {
    return EditorState.createEmpty();
  }
  const contentBlock = htmlToDraft(html);
  if (contentBlock) {
    const contentState = ContentState.createFromBlockArray(
      contentBlock.contentBlocks,
    );
    return EditorState.createWithContent(contentState);
  } else {
    return EditorState.createEmpty();
  }
};
