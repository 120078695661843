import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import FaqElementsDialogElement from './FaqElementsDialogElement/FaqElementsDialogElement';
import { ARTICLE, EXTERNAL_LINK } from '../FaqElementType.const';
import FaqElementsDialogArticle from './FaqElementsDialogArticle/FaqElementsDialogArticle';
import FaqElementsDialogExternalLink
  from './FaqElementsDialogExternalLink/FaqElementsDialogExternalLink';

const FaqElementsDialog = ({ open, handleClose, parent, type, defaultElement }) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  if (open && !parent) {
    handleClose();
  }
  return (
    <Dialog
      open={open}
      scroll="paper"
      fullScreen={fullScreen}
      aria-labelledby={'faq-element-dialog-title'}
      aria-describedby={'faq-element-dialog-description'}
      onClose={handleClose}
    >
        {parent && ![ARTICLE, EXTERNAL_LINK].includes(type)
        && <FaqElementsDialogElement
          handleClose={handleClose}
          parentId={parent.id}
          defaultElement={defaultElement}
          type={type}
        />}
        {parent && type === ARTICLE
        && <FaqElementsDialogArticle
          handleClose={handleClose}
          parentId={parent.id}
          defaultElement={defaultElement}
        />}
        {parent && type === EXTERNAL_LINK
        && <FaqElementsDialogExternalLink
          handleClose={handleClose}
          parentId={parent.id}
          defaultElement={defaultElement}
        />}
    </Dialog>
  );
};

export default FaqElementsDialog;

