import React from 'react';
import { useTranslation } from 'react-i18next';
import withAuthentication from '../../hoc/withAuthentication';
import { ROLE_ADMIN } from '../../constants/roles';
import { useDispatch, useSelector } from 'react-redux';
import { setTitle } from '../../store/title/actions';
import ChallengeForm from '../../components/Challenges/Form/ChallengeForm';

export const ChallengeAddPage = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { title } = useSelector(state => state.title);

  if (title !== t('title:title.add_challenge_page')) {
    dispatch(setTitle(t('title:title.add_challenge_page')));
  }
  return <ChallengeForm/>;
}

export default withAuthentication(ROLE_ADMIN)(ChallengeAddPage);
