import React, { useState } from 'react';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import makeStyles from '@material-ui/core/styles/makeStyles';
import MTCTable from '../Layout/Table/MTCTable';
import MessageContactRow from './MessageContactsRow';
import CarContactsRow from './CarContactsRow';
import RallyCarContactsRow from './RallyCarContactsRow';
import CircuitContactsRow from './CircuitContactsRow';
import { getContacts } from '../../store/contacts/actions';
import MTCLoader from '../Layout/MTCLoader';

const useStyles = makeStyles(() => {
  return {
    root: {
      flexGrow: 1,
    },
  };
});

export const ContactsTabs = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const { pending, content, error } = useSelector(state => state.contacts);

  const [value, setValue] = useState(0);
  const [locationStateMatch, setLocationStateMatch] = useState(true);

  if (!content && !pending && !error) {
    dispatch(getContacts());
  }

  if (
    history.location
    && history.location.state
    && locationStateMatch
  ) {
    if (history.location.state.value) {
      setValue(history.location.state.value);
    }
    setLocationStateMatch(false);
  }
  const handleChange = (event, value) => {
    setValue(value);
  };

  const messageColumns = [
    {
      id: 'email',
      numeric: false,
      disablePadding: false,
    },
    {
      id: 'country_codes',
      numeric: false,
      disablePadding: false,
    },
    {
      numeric: false,
      disablePadding: false,
    },
  ];

  const carColumns = [
    {
      id: 'email',
      numeric: false,
      disablePadding: false,
    },
    {
      numeric: false,
      disablePadding: false,
    },
  ];
  const defaultOrderBy = 'email';
  if (pending || !content) {
    return <MTCLoader />;
  }
  if (error) {
    return <p>{error}</p>;
  }
  if (!error) {
    return (
      <div className={classes.root}>
        <AppBar position={'static'}>
          <Tabs
            value={value}
            onChange={handleChange}
            variant={'scrollable'}
            scrollButtons={'on'}
          >
            <Tab label={t('title:title.message_requests_page')} />
            <Tab label={t('title:title.track_requests_page')} />
            <Tab label={t('title:title.car_requests_page')} />
            <Tab label={t('title:title.rally_car_requests_page')} />
          </Tabs>
        </AppBar>
        {value === 0
        && <MTCTable
          content={content['message_request_contact']}
          rowComponent={MessageContactRow}
          defaultOrderBy={defaultOrderBy}
          columns={messageColumns}
          paginationLabel={t('request_contact_per_page')}
          emptyDataLabel={t('no_request_contact')}
        />
        }
        {value === 1 && <MTCTable
          content={content['circuit_request_contact']}
          rowComponent={CircuitContactsRow}
          defaultOrderBy={defaultOrderBy}
          columns={carColumns}
          paginationLabel={t('request_contact_per_page')}
          emptyDataLabel={t('no_request_contact')}
        />}
        {value === 2 && <MTCTable
          content={content['car_request_contact']}
          rowComponent={CarContactsRow}
          defaultOrderBy={defaultOrderBy}
          columns={carColumns}
          paginationLabel={t('request_contact_per_page')}
          emptyDataLabel={t('no_request_contact')}
        />}
        {value === 3 && <MTCTable
          content={content['rally_car_request_contact']}
          rowComponent={RallyCarContactsRow}
          defaultOrderBy={defaultOrderBy}
          columns={carColumns}
          paginationLabel={t('request_contact_per_page')}
          emptyDataLabel={t('no_request_contact')}
        />}
      </div>
    );
  }
};

export default ContactsTabs;
