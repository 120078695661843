import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import TiresTable from '../../components/Tires/TiresTable';
import withAuthentication from '../../hoc/withAuthentication';
import { ROLE_ADMIN } from '../../constants/roles';
import AddButton from '../../components/Layout/Button/AddButton';
import { TIRE_ADD } from '../../constants/routes';
import { setTitle } from '../../store/title/actions';

function TiresPage() {

  const { t } = useTranslation();
  const { title } = useSelector(state => state.title);
  const dispatch = useDispatch();

  if (title !== t('title:title.tires_page')) {
    dispatch(setTitle(t('title:title.tires_page')));
  }

  return (
    <div>
      <TiresTable />
      <AddButton
        path={TIRE_ADD}
      />
    </div>
  );
}

export default withAuthentication(ROLE_ADMIN)(TiresPage);
