import { PSC2 } from '../../../constants/raceTireType';

export const MIN_BOUNDARY_VALUE = 1.9;

/**
 * Create default dimension
 * @returns object
 */
export const emptyDimension = () => {
  return {
    front_dimension: '',
    front_pressure: '',
    rear_dimension: '',
    rear_pressure: '',
    compatible_with_receiver: true,
    is_public: false,
    origin: 0,
    data_version: '',
    state: true,
    available_tire_types: [PSC2],
    has_psc2: true,
    has_psc2r: false,
  };
};

/**
 * Create default boundary
 * @param isFront
 * @param weatherType
 * @param tireType
 * @returns object
 */
export const createEmptyBoundary = (isFront, weatherType, tireType) => {
  return {
    red_orange: '',
    orange_green: '',
    green_orange: '',
    orange_red: '',
    is_front: isFront,
    weather_type: weatherType,
    tire_type: tireType,
  };
};

/**
 * Create new boundary collection
 * @param weatherTypes
 * @param tireTypes
 * @returns object[]
 */
export const emptyBoundaries = (weatherTypes, tireTypes) => {
  let result = [];
  weatherTypes.properties.forEach(weatherType => {
    tireTypes.forEach(tireType => {
      result.push(createEmptyBoundary(true, weatherType.value, tireType));
      result.push(createEmptyBoundary(false, weatherType.value, tireType));
    });
  });
  return result;
};

/**
 * Use to detect if the admin want to create Dimension without boundary
 * @param state
 * @returns boolean
 */
export const expertDimensionWithoutBoundary = (state) => {
  if (
    state.dimension.compatible_with_receiver
    && !state.dimension.has_psc2
    && !state.dimension.has_psc2r
  ) {
    return true;
  }
  return false;
};

/**
 * Use to detect if some boundaries have to small values
 * @param state
 * @returns boolean
 */
export const hasLessThanBoundaries = (state) => {
  let result = false;
  if (state.dimension.compatible_with_receiver && state.boundaries) {
    state.boundaries.forEach(e => {
      if (
        (
          e.red_orange !== null && e.red_orange !== '' && e.red_orange < MIN_BOUNDARY_VALUE
        )
        || (
          e.orange_green !== null && e.orange_green !== '' && e.orange_green < MIN_BOUNDARY_VALUE
        )
        || (
          e.green_orange !== null && e.green_orange !== '' && e.green_orange < MIN_BOUNDARY_VALUE
        )
        || (
          e.orange_red !== null && e.orange_red !== '' && e.orange_red < MIN_BOUNDARY_VALUE
        )
      ) {
        result = true;
      }
    });
  }
  return result;
};

/**
 * Use to detect if no change was made on duplicate car
 * @param state
 * @param duplicatedCar
 * @returns boolean
 */
export const isTheSame = (state, duplicatedCar) => {
  let result = false;
  if (duplicatedCar) {
    if (
      duplicatedCar.brand.id === state.brandId
      && duplicatedCar.range.id === state.rangeId
      && duplicatedCar.range_model.id === state.modelId
      && duplicatedCar.engine.id === state.engineId
      && duplicatedCar.dimension.frontDimension === state.dimension.front_dimension
      && duplicatedCar.dimension.rearDimension === state.dimension.rear_dimension
      && !state.newEngine
    ) {
      result = true;
    }
  }
  return result;
};

/**
 * Use to detect if some boundaries have empty or null values
 * @param state
 * @returns String[]
 */
export const hasEmptyBoundaries = (state) => {
  let result = [];
  if (state.dimension.compatible_with_receiver && state.boundaries) {
    state.boundaries.forEach(e => {
      if (
        state.dimension.available_tire_types.includes(e.tire_type)
        && (
          e.red_orange === null || e.red_orange === ''
          || e.orange_green === null || e.orange_green === ''
          || e.green_orange === null || e.green_orange === ''
          || e.orange_red === null || e.orange_red === ''
        )
      ) {
        if (result.indexOf(e.tire_type) === -1) {
          result.push(e.tire_type);
        }
      }
    });
  }
  return result;
};