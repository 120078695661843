import React, { useState } from 'react';
import withAuthentication from '../../hoc/withAuthentication';
import { ROLE_ADMIN } from '../../constants/roles';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import i18next from '../../i18n';
import { getInAppNotifications } from '../../store/inAppNotifications/actions';
import { setBack, setTitle, showLangMenu } from '../../store/title/actions';
import { IN_APP_NOTIFICATION, IN_APP_NOTIFICATIONS } from '../../constants/routes';
import MTCLoader from '../../components/Layout/MTCLoader';
import InAppNotificationForm from '../../components/InAppNotifications/Form/InAppNotificationForm';
import Error404 from '../Error404';

export const EditInAppNotificationPage = () => {
  const {
    pending,
    content,
    error,
  } = useSelector(state => state.inAppNotifications);
  const {
    title,
    back,
    displayLangMenu,
  } = useSelector(state => state.title);
  const dispatch = useDispatch();
  const { notificationId } = useParams();
  const lang = i18next.languages[0].split('-')[0];
  const [notification, setNotification] = useState(null);
  const history = useHistory();
  const fromDetails = (
    history.location.state
    && history.location.state.fromDetails
  )
    ? history.location.state.fromDetails
    : false;

  if (!content && !pending && !error) {
    dispatch(getInAppNotifications());
  }
  if (
    notification
    && notification.translations
    && notification.translations[lang]
    && title !== notification.translations[lang].title
  ) {
    dispatch(setTitle(notification.translations[lang].title));
  }
  if (notification && !fromDetails && back !== IN_APP_NOTIFICATIONS) {
    dispatch(setBack(IN_APP_NOTIFICATIONS, {}));
  }
  if (
    notification
    && fromDetails
    && back !== IN_APP_NOTIFICATION.replace(':notificationId', notification.id)
  ) {
    dispatch(
      setBack(IN_APP_NOTIFICATION.replace(':notificationId', notification.id), {})
    );
  }

  if (content && notification === null) {
    const result = content.find(ch => ch.id == notificationId);
    if (result) {
      setNotification(result);
    } else {
      setNotification(false);
    }
  }

  if (notification && !displayLangMenu) {
    dispatch(showLangMenu());
  }

  if (pending) {
    return <MTCLoader />;
  } else if (content && !notification) {
    return <Error404 />;
  } else {
    return <InAppNotificationForm notification={notification} />;
  }
}

export default withAuthentication(ROLE_ADMIN)(EditInAppNotificationPage);
