import {
  TABLE_CARS_BRAND_DOWNLOADED,
  TABLE_CARS_DOWNLOADED,
  TABLE_CARS_FAILED,
  TABLE_CARS_PENDING,
  TABLE_CARS_RESET,
  TABLE_CARS_RANGE_DOWNLOADED,
  TABLE_CARS_MODEL_DOWNLOADED,
  TABLE_CARS_ENGINE_DOWNLOADED,
  TABLE_CARS_DIMENSION_DOWNLOADED
} from './types';

export const INITIAL_STATE = {
  data: null,
  count: 0,
  error: null,
  pending: false,
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case TABLE_CARS_DOWNLOADED:
      return {
        ...state,
        data: action.payload.data,
        count: action.payload.count,
        pending: false,
      };
    case TABLE_CARS_FAILED:
      return {
        ...state,
        error: action.payload,
        pending: false,
      };
    case TABLE_CARS_PENDING:
      return {
        ...state,
        pending: true,
        error: null,
      };
    case TABLE_CARS_RESET:
      return { ...INITIAL_STATE };
    case TABLE_CARS_BRAND_DOWNLOADED: {
      let newData = [...state.data];
      newData.forEach(car => {
        if (car.brand_id == action.payload.id) {
          car.brand_state = action.payload.state;
        }
      });
      return {
        ...state,
        data: newData,
        pending: false,
      };
    }

    case TABLE_CARS_RANGE_DOWNLOADED: {
      let newData = [...state.data];
      newData.forEach(car => {
        if (car.range_id == action.payload.id) {
          car.range_state = action.payload.state;
        }
      });
      return {
        ...state,
        data: newData,
        pending: false,
      };
    }
    case TABLE_CARS_MODEL_DOWNLOADED: {
      let newData = [...state.data];
      newData.forEach(car => {
        if (car.model_id == action.payload.id) {
          car.model_state = action.payload.state;
        }
      });
      return {
        ...state,
        data: newData,
        pending: false,
      };
    }
    case TABLE_CARS_ENGINE_DOWNLOADED: {
      let newData = [...state.data];
      newData.forEach(car => {
        if (car.engine_id == action.payload.id) {
          car.engine_state = action.payload.state;
        }
      });
      return {
        ...state,
        data: newData,
        pending: false,
      };
    }
    case TABLE_CARS_DIMENSION_DOWNLOADED: {
      let newData = [...state.data];
      newData.forEach(car => {
        if (car.dimension_id == action.payload.id) {
          car.dimension_state = action.payload.state;
        }
      });
      return {
        ...state,
        data: newData,
        pending: false,
      };
    }

    default:
      break;
  }
  return state;
}
