import { inputStyle } from '../../constants/styles/input.style';

export const styles = theme => (
  {
    input: {
      ...inputStyle(theme),
      color: theme.palette.textColor,
      backgroundColor: theme.palette.alternateTextColor,
    },
    container: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    },
  }
);
