import React, { Component } from 'react';
import PropTypes from 'prop-types';
import i18n from 'i18next';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import Dropzone from 'react-dropzone';
import { withStyles } from '@material-ui/core/styles/index';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import Paper from '@material-ui/core/Paper';
import Image from '@material-ui/icons/Image';
import MapIcon from '@material-ui/icons/Map';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import Link from '@material-ui/core/Link';
import MTCTextField from '../../Form/MTCTextField';
import MTCLoader from '../../Layout/MTCLoader';
import { getTracks, postTrack, putMultipartTrack } from '../../../store/tracks/actions';
import { handleError } from '../../../store/infos/actions';
import withTranslation from '../../../hoc/withTranslation';
import { TRACK, TRACK_EDIT } from '../../../constants/routes';
import StringService from '../../../services/StringService';
import TrackMapBox from '../TrackMapBox';
import MTCSwitch from '../../Form/MTCSwitch';
import MTCButton from '../../Layout/Button/MTCButton';
import { michelin_red } from '../../../constants/michelin_colors';
import { supportedPictureDropFiles } from '../../../constants/files';
import { styles } from './TrackForm.style';
import {
  createEmptySector,
  createLastSector,
  GOOGLE_MAPS_API_KEY,
  GOOGLE_MAPS_HOST,
  GOOGLE_MAPS_OPTIONS,
  INITIAL_STATE,
  stateWithTrack,
} from './TrackForm.const';
import { isValidLatitude, isValidLongitude } from './TrackForm.func';
import { data_languages, data_languages_no_fr } from '../../../constants/lang';
import {
  formatDegrees
} from '../../../utils/MapUtils';

const geoFunctions = require('geo-points-and-paths').geoFunctions;
const Point = require('geo-points-and-paths').Point;
const countries = require('country-list')();

export class TrackForm extends Component {

  static propTypes = {
    trackId: PropTypes.string,
    path: PropTypes.string,
    classes: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  }

  // Lifecycle

  static getDerivedStateFromProps(props, state) {
    if (props.duplicateValue && !state.duplicateFrom) {
      let newState = {
        ...stateWithTrack(props.duplicateValue),
        id: null,
        duplicateFrom: props.duplicateValue.id,
        picture: '',
        use_algorithm: false,
        geojson: '',
        pictureToUpload: null,
        geojsonToUpload: null,
        has_been_notify: false,
        canNotify: props.duplicateValue.is_public,
        originalNotify: false,
        notify: false,
      };
      if (newState.sectors.length > 0) {
        newState.create_sectors = true;
      }
      return newState;
    }

    if (props.tracks && props.trackId && state.id === INITIAL_STATE.id) {
      const tracks = props.tracks;
      const currentTrackId = props.trackId;
      const foundTrack = tracks
        .find(track => track.id === Number(currentTrackId));
      if (foundTrack) {
        return {
          ...stateWithTrack(foundTrack),
          nextTrackToUpdate: TrackForm.nextTrackToUpdate(tracks, currentTrackId),
        };
      }
    }

    if (props.sending && !state.sending) { // Start pending
      return { sending: true };
    }

    if (!props.sending && state.sending) { // End sending
      if (state.nextTrackToUpdate) {
        if (state.nextTrackToUpdate.id) {
          props.history.push(TRACK_EDIT.replace(':trackId', state.nextTrackToUpdate.id));
          window.scrollTo(0, 0);
          return { ...INITIAL_STATE };
        }
      } else {
        if (state.id) {
          props.history.push(TRACK.replace(':trackId', state.id));
          window.scrollTo(0, 0);
        }
      }
    }

    if (state.lap_line_bearing === null) {
      return {lap_line_bearing: Math.round(
        TrackForm.calculateLineBearing(
          state.start_point_latitude,
          state.start_point_longitude,
          state.end_point_latitude,
          state.end_point_longitude,
          state.is_lap_line_bearing_reversed
        )
      )};
    }
    if (state.has_finish_line && state.finish_lap_line_bearing === null) {
      return {finish_lap_line_bearing: Math.round(
        TrackForm.calculateLineBearing(
          state.finish_start_point_latitude,
          state.finish_start_point_longitude,
          state.finish_end_point_latitude,
          state.finish_end_point_longitude,
          state.is_finish_lap_line_bearing_reversed
        )
      )};
    }
    return null;
  }

  static nextTrackToUpdate = (tracks, currentTrackId) => {
    if (tracks) {
      const nextTracks = tracks.filter(track =>
        (track.id !== Number(currentTrackId)
        && track.picture === null)
        || track.start_point_latitude === null
      );
      if (nextTracks && nextTracks.length > 0) {
        return nextTracks[0];
      }
    }
    return null;
  };

  componentDidMount() {
    if (!this.props.tracks && !this.props.pending && !this.props.error) {
      this.props.getTracks();
    }
    if (this.props.tracks && this.props.trackId) {
      const tracks = this.props.tracks;
      const currentTrackId = this.props.trackId;
      const foundTrack = tracks
        .find(track => track.id === Number(currentTrackId));
      if (foundTrack) {
        this.setState(stateWithTrack(foundTrack));
      }
      this.setState({
        nextTrackToUpdate: TrackForm.nextTrackToUpdate(tracks, currentTrackId),
      });
    }
  }

  // sectors

  addSector = () => {
    if (this.state.sectors.length > 1) {
      let sectors = this.state.sectors;
      const newSector = createEmptySector(sectors.length, this.state);
      let lastSector = sectors.pop();
      lastSector.number += 1;
      sectors.push(newSector);
      sectors.push(lastSector);
      this.setState({
        sectors,
        create_sectors: true,
      });
    } else {
      // no sector yet, min number of sectors is 2
      this.setState({
        sectors: [createEmptySector(1, this.state), createLastSector(2, this.state)],
        create_sectors: true,
      });
    }
  };

  removeSector = (number) => {
    let sectors = this.state.sectors;
    if (sectors.length <= 2) {
      this.setState({
        sectors: [],
        create_sectors: false,
      });
    } else {
      sectors.splice(number - 1, 1);
      sectors.forEach(s => {
        if (s.number > number) {
          s.number -= 1;
        }
      });
      this.setState({
        sectors,
        create_sectors: true,
      });
    }
  };

  drawSector = (number) => {
    this.setState({
      drawMarkerLabel: `sector_line_${number}`,
    });
  };

  setSectorPosition = async (number, propName, value) => {
    let sectorIndex = this.state.sectors.findIndex(e => e.number == number);
    if (sectorIndex !== -1) {
      let sectors = [...this.state.sectors];
      let sector = sectors[sectorIndex];
      sector[propName] = Number(value);
      this.setState({
        sectors,
        create_sectors: true,
      });
    }
  };

  // Handler

  onClickUpdate = () => {
    this.setState({ nextTrackToUpdate: null });
  };

  onSubmit = (event, goToNext) => {
    if (event) {
      event.preventDefault();
    }
    if (goToNext === undefined || !goToNext) {
      this.setState({ nextTrackToUpdate: null });
    } else {
      this.setState({ goToNext: true });
    }
    if (this.props.trackId && this.props.path === TRACK_EDIT) {
      if (this.state.create_sectors) {
        this.setState({ confirmDialogOpen: true });
      } else {
        this.onSubmitSend(true);
      }
    } else {
      this.onSubmitSend(false);
    }
  };

  checkDuplicateValues = () => {
    if (this.props.duplicateValue) {
      return data_languages.reduce(
        (o, key) => o || (
          this.props.duplicateValue.translations[key].name === this.state[`name_${key}`]
        ),
        false
      );
    } else {
      return false;
    }
  };

  onSubmitSend = (edit) => {
    // TODO : use toFixed with number of decimal pour latitude/longitude
    if (edit) {
      this.setState({ confirmDialogOpen: false });
    }
    let needToChangeDuplicate = false;
    if (!edit) {
      needToChangeDuplicate = this.checkDuplicateValues();
    }
    if (needToChangeDuplicate) {
      this.props.handleError({ message: this.props.t('track_duplicate_values_error') });
    } else {
      const formData = new FormData();

      if (this.state.pictureToUpload) {
        formData.append('picture', this.state.pictureToUpload);
      }
      if (this.state.geojsonToUpload) {
        formData.append('geojson', this.state.geojsonToUpload);
      }

      formData.append('json', JSON.stringify({
        id: this.state.id,
        country_code: this.state.country_code,
        is_public: this.state.is_public,
        end_point_latitude: Number(this.state.end_point_latitude),
        end_point_longitude: Number(this.state.end_point_longitude),
        length: Number(this.state.length),
        latitude: Number(this.state.latitude),
        longitude: Number(this.state.longitude),
        start_point_latitude: Number(this.state.start_point_latitude),
        start_point_longitude: Number(this.state.start_point_longitude),
        has_finish_line: this.state.has_finish_line,
        finish_start_point_latitude: this.state.has_finish_line
          ? this.state.finish_start_point_latitude
          : null,
        finish_start_point_longitude: this.state.has_finish_line
          ? this.state.finish_start_point_longitude
          : null,
        finish_end_point_latitude: this.state.has_finish_line
          ? this.state.finish_end_point_latitude
          : null,
        finish_end_point_longitude: this.state.has_finish_line
          ? this.state.finish_end_point_longitude
          : null,
        translations: data_languages.reduce((o, key) => (
          {
            ...o,
            [`${key}`]: {name: this.state[`name_${key}`]},
          }
        ), {}),
        create_sectors: this.state.create_sectors,
        sectors: this.state.sectors,
        use_algorithm: this.state.use_algorithm,
        lap_line_bearing: this.state.lap_line_bearing,
        is_lap_line_bearing_reversed: this.state.is_lap_line_bearing_reversed,
        finish_lap_line_bearing: this.state.finish_lap_line_bearing,
        is_finish_lap_line_bearing_reversed: this.state.is_finish_lap_line_bearing_reversed,
        notify: this.state.notify
      }));
      if (edit) {
        this.props.putMultipartTrack(this.state.id, formData);
      } else {
        this.props.postTrack(formData)
          .then(newTrackId => {
            if (this.state.pictureToUpload) {
              this.setState({ id: newTrackId });
            }
            this.props.history.push(TRACK.replace(':trackId', newTrackId));
            window.scrollTo(0, 0);
          });
      }
    }
  };

  handleAddPictureMedia = () => {
    this.dropzonePictureRef.open();
  };

  handleAddGeojsonMedia = () => {
    this.dropzoneGeojsonRef.open();
  };

  updateStartLine = async (propName, value) => {
    let isValid = false;
    if (propName.includes('latitude')) {
      isValid = isValidLatitude(value);
    } else if (propName.includes('longitude')) {
      isValid = isValidLongitude(value);
    }
    if (isValid) {
      if (!this.state.has_finish_line && this.state.sectors.length > 0) {
        // update last sector if no finish line
        let sectors = [...this.state.sectors];
        let sectorIndex = sectors.findIndex(e => e.number == sectors.length);
        if (sectorIndex != -1) {
          sectors[sectorIndex][`line_${propName}`] = value;
        }
        this.setState({
          [propName]: value,
          sectors,
          create_sectors: true,
        });
      } else {
        this.setState({ [propName]: value });
      }
      const newState = {...this.state, [propName]: value}
      this.setState({lap_line_bearing: Math.round(TrackForm.calculateLineBearing(
        newState.start_point_latitude,
        newState.start_point_longitude,
        newState.end_point_latitude,
        newState.end_point_longitude,
        newState.is_lap_line_bearing_reversed
      ))});
      return true;
    }
    return false;
  };

  updateEndLine = async (propName, value) => {
    let isValid = false;
    if (propName.includes('latitude')) {
      isValid = isValidLatitude(value);
    } else if (propName.includes('longitude')) {
      isValid = isValidLongitude(value);
    }
    if (isValid && this.state.has_finish_line) {
      if (this.state.sectors.length > 0) {
        // update last sector if no finish line
        let sectors = [...this.state.sectors];
        let sectorIndex = sectors.findIndex(e => e.number == sectors.length);
        if (sectorIndex != -1) {
          sectors[sectorIndex][propName.replace('finish', 'line')] = value;
        }
        this.setState({
          [propName]: value,
          sectors,
          create_sectors: true,
        });
      } else {
        this.setState({
          [propName]: value,
        });
      }
      const newState = {...this.state, [propName]: value}
      this.setState({finish_lap_line_bearing: Math.round(TrackForm.calculateLineBearing(
          newState.finish_start_point_latitude,
          newState.finish_start_point_longitude,
          newState.finish_end_point_latitude,
          newState.finish_end_point_longitude,
          newState.is_finish_lap_line_bearing_reversed
        ))});
      return true;
    }
    return false;
  };

  changeUseAlgorithm = () => {
    let newValue = !this.state.use_algorithm;
    this.setState({ use_algorithm: newValue });
  };

  changeHasFinishLine = () => {
    let newValue = !this.state.has_finish_line;
    this.setState({ has_finish_line: newValue });
    // if add or remove finish line, update last sector
    if (this.state.sectors.length > 0) {
      let sectors = [...this.state.sectors];
      let sectorIndex = sectors.findIndex(e => e.number == sectors.length);
      if (sectorIndex != -1) {
        if (newValue) {
          const {
            finish_start_point_latitude,
            finish_start_point_longitude,
            finish_end_point_latitude,
            finish_end_point_longitude,
          } = this.state;
          sectors[sectorIndex]['line_start_point_latitude'] = finish_start_point_latitude;
          sectors[sectorIndex]['line_start_point_longitude'] = finish_start_point_longitude;
          sectors[sectorIndex]['line_end_point_latitude'] = finish_end_point_latitude;
          sectors[sectorIndex]['line_end_point_longitude'] = finish_end_point_longitude;
        } else {
          sectors[sectorIndex]['line_start_point_latitude'] = this.state.start_point_latitude;
          sectors[sectorIndex]['line_start_point_longitude'] = this.state.start_point_longitude;
          sectors[sectorIndex]['line_end_point_latitude'] = this.state.end_point_latitude;
          sectors[sectorIndex]['line_end_point_longitude'] = this.state.end_point_longitude;
        }
      }
    }
  };

  changeIsLapLineBearingReversed = () => {

    const newLapLineBearing = Math.round(TrackForm.calculateLineBearing(
      this.state.start_point_latitude,
      this.state.start_point_longitude,
      this.state.end_point_latitude,
      this.state.end_point_longitude,
      !this.state.is_lap_line_bearing_reversed
    ));

    this.setState({
      is_lap_line_bearing_reversed: !this.state.is_lap_line_bearing_reversed,
      lap_line_bearing: newLapLineBearing
    });
  }

  changeIsFinishLapLineBearingReversed = () => {
    const newFinishLapLineBearing = Math.round(TrackForm.calculateLineBearing(
      this.state.finish_start_point_latitude,
      this.state.finish_start_point_longitude,
      this.state.finish_end_point_latitude,
      this.state.finish_end_point_longitude,
      !this.state.is_finish_lap_line_bearing_reversed
    ));

    this.setState({
      is_finish_lap_line_bearing_reversed: !this.state.is_finish_lap_line_bearing_reversed,
      finish_lap_line_bearing: newFinishLapLineBearing
    });
  }

  // Computation

  handlePictureMediaDrop = (accepted, rejected) => {
    if (rejected.length) {
      const t = i18n.t.bind(i18n);
      if (rejected[0].type === '') {
        this.props.handleError({
          message: t('error.format.image_unknown_type'),
        });
      } else {
        this.props.handleError({
          message: t('error.format.image') + rejected[0].type,
        });
      }

    } else {
      this.setState({
        pictureToUpload: accepted[0],
      });
      const fileReader = new FileReader();
      fileReader.readAsDataURL(accepted[0]);
      fileReader.addEventListener("load", this.updatePicture);
    }
  };

  updatePicture = (fileReader) => {
    this.setState({ picture: fileReader.target.result });
  };

  setGeojson = (geojsonFile) => {
    this.setState({ geojson: JSON.parse(geojsonFile.target.result) });
  };

  handleGeojsonMediaDrop = (accepted, rejected) => {
    if (rejected.length || (accepted[0] && accepted[0].name.split('.')[1] !== 'geojson')) {
      const t = i18n.t.bind(i18n);
      if (accepted[0]) {
        this.props.handleError({
          message: t('error.format.geojson'),
        });
      } else {
        this.props.handleError({
          message: t('error.format.geojson'),
        });
      }
    } else {
      this.setState({
        geojsonToUpload: accepted[0],
      });
      let reader = new FileReader();
      reader.onload = this.setGeojson;
      reader.readAsText(accepted[0]);
    }
  };

  handleMarkerComplete = (map, ev) => {
    if (this.state.drawMarkerLabel === 'line_start') {
      this.updateStartLine('start_point_latitude', ev.lngLat.lat).then(() => {
        return this.updateStartLine('start_point_longitude', ev.lngLat.lng);
      }).then(() => {
        this.setState({
          drawMarkerLabel: 'B',
        });
      });
    } else if (this.state.drawMarkerLabel === 'finish_line_start') {
      this.updateEndLine('finish_start_point_latitude', ev.lngLat.lat).then(() => {
        return this.updateEndLine('finish_start_point_longitude', ev.lngLat.lng);
      }).then(() => {
        this.setState({
          drawMarkerLabel: 'D',
        });
      });
    } else if (this.state.drawMarkerLabel.startsWith('sector_line_')) {
      const number = this.state.drawMarkerLabel.split('_')[2];
      this.setSectorPosition(number, 'line_start_point_latitude', ev.lngLat.lat).then(() => {
        return this.setSectorPosition(number, 'line_start_point_longitude', ev.lngLat.lng);
      }).then(() => {
        this.setState({
          drawMarkerLabel: `SLE_${number}`,
        });
      });
    } else if (this.state.drawMarkerLabel === 'A') {
      this.updateStartLine('start_point_latitude', ev.lngLat.lat).then(() => {
        return this.updateStartLine('start_point_longitude', ev.lngLat.lng);
      }).then(() => {
        this.setState({
          drawMarkerLabel: null,
        });
      });
    } else if (this.state.drawMarkerLabel === 'B') {
      this.updateStartLine('end_point_latitude', ev.lngLat.lat).then(() => {
        return this.updateStartLine('end_point_longitude', ev.lngLat.lng);
      }).then(() => {
        this.setState({
          drawMarkerLabel: null,
        });
      });
    } else if (this.state.drawMarkerLabel === 'C') {
      this.updateEndLine('finish_start_point_latitude', ev.lngLat.lat).then(() => {
        return this.updateEndLine('finish_start_point_longitude', ev.lngLat.lng);
      }).then(() => {
        this.setState({
          drawMarkerLabel: null,
        });
      });
    } else if (this.state.drawMarkerLabel === 'D') {
      this.updateEndLine('finish_end_point_latitude', ev.lngLat.lat).then(() => {
        return this.updateEndLine('finish_end_point_longitude', ev.lngLat.lng);
      }).then(() => {
        this.setState({
          drawMarkerLabel: null,
        });
      });
    } else if (this.state.drawMarkerLabel === 'P') {
      this.setState({
        latitude: ev.lngLat.lat,
        longitude: ev.lngLat.lng,
        drawMarkerLabel: null,
      });
    } else if (this.state.drawMarkerLabel.startsWith('SLS_')) {
      // sector line start
      const number = this.state.drawMarkerLabel.split('_')[1];
      this.setSectorPosition(number, 'line_start_point_latitude', ev.lngLat.lat).then(() => {
        return this.setSectorPosition(number, 'line_start_point_longitude', ev.lngLat.lng);
      }).then(() => {
        this.setState({
          drawMarkerLabel: null,
        });
      });
    } else if (this.state.drawMarkerLabel.startsWith('SLE_')) {
      // sector line end
      const number = this.state.drawMarkerLabel.split('_')[1];
      this.setSectorPosition(number, 'line_end_point_latitude', ev.lngLat.lat).then(() => {
        return this.setSectorPosition(number, 'line_end_point_longitude', ev.lngLat.lng);
      }).then(() => {
        this.setState({
          drawMarkerLabel: null,
        });
      });
    }
  };

  handleDragMarker = (event, title) => {
    if (title === 'A') {
      this.updateStartLine('start_point_latitude', event.lngLat.lat).then(() => {
        return this.updateStartLine('start_point_longitude', event.lngLat.lng);
      });
    } else if (title === 'B') {
      this.updateStartLine('end_point_latitude', event.lngLat.lat).then(() => {
        return this.updateStartLine('end_point_longitude', event.lngLat.lng);
      });
    } else if (title === 'C') {
      this.updateEndLine('finish_start_point_latitude', event.lngLat.lat).then(() => {
        return this.updateEndLine('finish_start_point_longitude', event.lngLat.lng);
      });
    } else if (title === 'D') {
      this.updateEndLine('finish_end_point_latitude', event.lngLat.lat).then(() => {
        return this.updateEndLine('finish_end_point_longitude', event.lngLat.lng);
      });
    } else if (title === 'P') {
      this.setState({
        latitude: event.lngLat.lat,
        longitude: event.lngLat.lng,
      });
    } else if (title.startsWith('SLS_')) {
      // sector line start
      const number = title.split('_')[1];
      this.setSectorPosition(number, 'line_start_point_latitude', event.lngLat.lat);
      this.setSectorPosition(number, 'line_start_point_longitude', event.lngLat.lng);
    } else if (title.startsWith('SLE_')) {
      // sector line end
      const number = title.split('_')[1];
      this.setSectorPosition(number, 'line_end_point_latitude', event.lngLat.lat);
      this.setSectorPosition(number, 'line_end_point_longitude', event.lngLat.lng);
    }
  };

  static calculateLineBearing = (
    aLatitude,
    aLongitude,
    bLatitude,
    bLongitude,
    bearingDirection
  ) => {
    const pointA = new Point({"lat":aLatitude,"lng":aLongitude});
    const pointB = new Point({"lat":bLatitude,"lng":bLongitude});
    let bearing = geoFunctions.bearing(pointA, pointB) + 90;
    if (bearingDirection) {
      bearing = bearing - 180;
    }
    return formatDegrees(bearing);
  }

  // dialog

  handleCloseCancel = () => {
    this.setState({ confirmDialogOpen: false });
  };

  // Renderer

  render() {
    const {
      name_fr,
      country_code,
      is_public,
      picture,
      geojson,
      length,
      latitude,
      longitude,
      start_point_latitude,
      start_point_longitude,
      end_point_latitude,
      end_point_longitude,
      has_finish_line,
      finish_start_point_latitude,
      finish_start_point_longitude,
      finish_end_point_latitude,
      finish_end_point_longitude,
      use_algorithm,
      drawMarkerLabel,
      nextTrackToUpdate,
      sectors,
      confirmDialogOpen,
      geojsonToUpload,
      lap_line_bearing,
      is_lap_line_bearing_reversed,
      finish_lap_line_bearing,
      is_finish_lap_line_bearing_reversed,
      notify,
      originalNotify,
      canNotify,
      has_been_notify
    } = this.state;
    const isEditing = this.props.trackId && this.props.path === TRACK_EDIT;

    let isInvalid =
      data_languages.reduce(
        (o, key) => o || StringService.isEmpty(this.state[`name_${key}`]),
        false
      ) ||
      StringService.isEmpty(country_code) ||
      StringService.isEmpty(length) ||
      StringService.isEmpty(latitude) ||
      StringService.isEmpty(longitude) ||
      StringService.isEmpty(start_point_latitude) ||
      StringService.isEmpty(start_point_longitude) ||
      StringService.isEmpty(end_point_latitude) ||
      StringService.isEmpty(end_point_longitude) ||
      this.props.sending;

    if (has_finish_line) {
      isInvalid = isInvalid ||
        StringService.isEmpty(finish_start_point_latitude) ||
        StringService.isEmpty(finish_start_point_longitude) ||
        StringService.isEmpty(finish_end_point_latitude) ||
        StringService.isEmpty(finish_end_point_longitude);
    }

    const {
      classes,
      pending,
      t,
    } = this.props;

    if (pending) {
      return <MTCLoader />;
    } else {
      return (
        <form>
          <Grid
            container
            alignItems={'center'}
            direction={'column'}
            justifyContent={'center'}
            className={classes.grid}
          >
            <Paper className={classes.paper}>
              <Grid container alignItems={'center'} direction={'column'} justifyContent={'center'}>
                <Typography
                  align="center"
                  variant="h5"
                  component="h2"
                  gutterBottom
                >
                  {t('general_infos')}
                </Typography>
                <MTCTextField
                  gridMD={12}
                  id="name_fr"
                  onChange={event => this.setState({ name_fr: event.target.value })}
                  onBlur={event => this.setState(
                    data_languages.reduce((o, key) => (
                      {
                        ...o,
                        [`name_${key}`]: this.state[`name_${key}`] || event.target.value,
                      }
                    ), {})
                   )}
                  value={name_fr}
                  required={true}
                />
                {data_languages_no_fr.map(lang => (
                  <MTCTextField
                    gridMD={12}
                    id={`name_${lang}`}
                    key={`name_${lang}`}
                    onChange={event => this.setState({ [`name_${lang}`]: event.target.value })}
                    value={this.state[`name_${lang}`]}
                    required={true}
                  />
                ))}
                <Grid item xs={12}>
                  <TextField
                    className={classes.textField}
                    id="country_code"
                    onChange={event => this.setState({ country_code: event.target.value })}
                    InputProps={{
                      className: classes.input,
                    }}
                    label={t('country_code')}
                    placeholder={t('country_code')}
                    SelectProps={{
                      MenuProps: {
                        className: classes.menu,
                      },
                    }}
                    value={country_code}
                    required={true}
                    select
                    InputLabelProps={{
                      className: classes.selectLabel,
                    }}
                  >
                    {countries.getData().map(country => (
                      <MenuItem key={country.code} value={country.code}>
                        {country.code} - {country.name}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <MTCTextField
                  gridMD={12}
                  id="length"
                  onChange={event => this.setState({ length: event.target.value })}
                  type="number"
                  value={length}
                  required={true}
                />
              </Grid>
            </Paper>

            <Paper className={classes.paper}>
              <Grid container alignItems={'center'} direction={'column'} justifyContent={'center'}>
                <Typography
                  align="center"
                  variant="h5"
                  component="h2"
                  gutterBottom
                >
                  {t('picture')}
                </Typography>
                <Dropzone
                  className={classes.media}
                  ref={(node) => {
                    this.dropzonePictureRef = node;
                  }}
                  accept={supportedPictureDropFiles}
                  onDrop={this.handlePictureMediaDrop}
                >
                  {picture
                    ? <CardMedia
                      component="img"
                      image={picture}
                      title={t('picture')}
                    />
                    : <div>
                      <Typography variant="subtitle1">{t('image_drop')}</Typography>
                      <br />
                      <Image />
                    </div>
                  }
                </Dropzone>
                <MTCButton
                  onClick={this.handleAddPictureMedia}
                  text={t('upload_picture')}
                />
              </Grid>
            </Paper>

            <Paper className={classes.paper}>
              <Grid container alignItems={'center'} direction={'column'} justifyContent={'center'}>
                <Typography
                  align="center"
                  variant="h5"
                  component="h2"
                  gutterBottom
                >
                  {t('geojson')}
                </Typography>
                <Dropzone
                  className={classes.media}
                  ref={(node) => {
                    this.dropzoneGeojsonRef = node;
                  }}
                  onDrop={this.handleGeojsonMediaDrop}
                >
                  {geojsonToUpload && <CardContent>{geojsonToUpload.name}</CardContent>}
                  {geojson
                  && !geojsonToUpload
                  && <div>
                    <Typography variant="subtitle1">{t('replace_geojson')}</Typography>
                    <br />
                    <MapIcon />
                  </div>
                  }
                  {!geojson
                  && !geojsonToUpload
                  && <div>
                    <Typography variant="subtitle1">{t('geojson_drop')}</Typography>
                    <br />
                    <MapIcon />
                  </div>
                  }
                </Dropzone>
                <MTCButton
                  onClick={this.handleAddGeojsonMedia}
                  text={t('upload_geojson')}
                />
                {typeof geojson == 'string' && geojson != ''
                &&
                <Link href={geojson} className={classes.link}>
                  <MapIcon size={'20'} style={{
                    verticalAlign: 'middle',
                    marginRight: '5px',
                  }} />
                  {t('download_geojson')}
                </Link>
                }
                {geojson
                &&
                <MTCSwitch
                  id="use_algorithm"
                  onChange={this.changeUseAlgorithm}
                  value={use_algorithm}
                />}
              </Grid>
            </Paper>

            <Paper className={classes.paper}>
              <Grid container alignItems={'center'} direction={'column'} justifyContent={'center'}>
                <Typography
                  align="center"
                  variant="h5"
                  component="h2"
                  gutterBottom
                >
                  {this.props.t('map_and_coordinates')}
                </Typography>

                <Typography
                  align="left"
                  variant="h6"
                  gutterBottom>
                  {this.props.t('parking_point')}
                </Typography>

                <Grid container justifyContent={'center'} spacing={1}>
                  <MTCTextField
                    gridMD={6}
                    gridLG={4}
                    id="latitude"
                    onChange={event => {
                      if (isValidLatitude(event.target.value)) {
                        this.setState({ latitude: event.target.value });
                      }
                    }}
                    type="number"
                    value={latitude}
                    required={true}
                    inputProps={{
                      min: -90,
                      max: 90,
                      minLength: '1',
                      required: true,
                      step: 0.00000000000000000001,
                    }}
                  />
                  <MTCTextField
                    gridMD={6}
                    gridLG={4}
                    id="longitude"
                    onChange={event => {
                      if (isValidLongitude(event.target.value)) {
                        this.setState({ longitude: event.target.value });
                      }
                    }}
                    type="number"
                    value={longitude}
                    required={true}
                    inputProps={{
                      min: -180,
                      max: 180,
                      minLength: '1',
                      required: true,
                      step: 0.00000000000000000001,
                    }}
                  />
                </Grid>
                <MTCButton
                  id={'draw_p'}
                  disabled={drawMarkerLabel != null}
                  text={t('select_parking')}
                  onClick={() => this.setState({ drawMarkerLabel: 'P' })}
                  color={'primary'}
                />

                <Divider className={classes.sectorDivider} />

                <Typography
                  align="left"
                  variant="h6"
                  gutterBottom>
                  {this.props.t('map_start_line')}
                </Typography>

                <Grid container justifyContent={'center'} spacing={1}>
                  <MTCTextField
                    gridMD={6}
                    gridLG={4}
                    id="start_point_latitude"
                    onChange={event => {
                      this.updateStartLine('start_point_latitude', event.target.value);
                    }}
                    type="number"
                    value={start_point_latitude}
                    required={true}
                    inputProps={{
                      min: -90,
                      max: 90,
                      minLength: '1',
                      required: true,
                      step: 0.00000000000000000001,
                    }}
                  />
                  <MTCTextField
                    gridMD={6}
                    gridLG={4}
                    id="start_point_longitude"
                    onChange={event => {
                      this.updateStartLine('start_point_longitude', event.target.value);
                    }}
                    type="number"
                    value={start_point_longitude}
                    required={true}
                    inputProps={{
                      min: -180,
                      max: 180,
                      minLength: '1',
                      required: true,
                      step: 0.00000000000000000001,
                    }}
                  />
                </Grid>
                <Grid container justifyContent={'center'} spacing={1}>
                  <MTCTextField
                    gridMD={6}
                    gridLG={4}
                    id="end_point_latitude"
                    onChange={event => {
                      this.updateStartLine('end_point_latitude', event.target.value);
                    }}
                    type="number"
                    value={end_point_latitude}
                    required={true}
                    inputProps={{
                      min: -90,
                      max: 90,
                      minLength: '1',
                      required: true,
                      step: 0.00000000000000000001,
                    }}
                  />
                  <MTCTextField
                    gridMD={6}
                    gridLG={4}
                    id="end_point_longitude"
                    onChange={event => {
                      this.updateStartLine('end_point_longitude', event.target.value);
                    }}
                    type="number"
                    value={end_point_longitude}
                    required={true}
                    inputProps={{
                      min: -180,
                      max: 180,
                      minLength: '1',
                      required: true,
                      step: 0.00000000000000000001,
                    }}
                  />

                </Grid>
                <Grid container justifyContent={'center'} spacing={1}>
                  <MTCTextField
                    gridMD={12}
                    id="lap_line_bearing"
                    onChange={event => {event.stopPropagation();}}
                    disabled
                    type="number"
                    value={lap_line_bearing}
                  />

                  <MTCSwitch
                    id="is_lap_line_bearing_reversed"
                    onChange={this.changeIsLapLineBearingReversed}
                    value={is_lap_line_bearing_reversed}
                  />
                </Grid>

                <MTCButton
                  onClick={() => this.setState({
                    drawMarkerLabel: 'line_start',
                  })}
                  disabled={drawMarkerLabel != null}
                  text={t('draw_line')}
                  color={'primary'}
                />

                <Divider className={classes.sectorDivider} />

                <MTCSwitch
                  id="has_finish_line"
                  onChange={this.changeHasFinishLine}
                  value={has_finish_line}
                />

                {has_finish_line &&
                <Typography
                  align="left"
                  variant="h6"
                  gutterBottom>
                  {this.props.t('map_finish_line')}
                </Typography>}

                {has_finish_line
                && <>
                  <Grid container justifyContent={'center'}>
                    <MTCTextField
                      gridMD={6}
                      gridLG={4}
                      id="finish_start_point_latitude"
                      onChange={event => {
                        this.updateEndLine('finish_start_point_latitude', event.target.value);
                      }}
                      type="number"
                      value={finish_start_point_latitude}
                      required={true}
                      inputProps={{
                        min: -90,
                        max: 90,
                        minLength: '1',
                        required: true,
                        step: 0.00000000000000000001,
                      }}
                    />
                    <MTCTextField
                      gridMD={6}
                      gridLG={4}
                      id="finish_start_point_longitude"
                      onChange={event => {
                        this.updateEndLine('finish_start_point_longitude', event.target.value);
                      }}
                      type="number"
                      value={finish_start_point_longitude}
                      required={true}
                      inputProps={{
                        min: -180,
                        max: 180,
                        minLength: '1',
                        required: true,
                        step: 0.00000000000000000001,
                      }}
                    />
                  </Grid>
                  <Grid container justifyContent={'center'}>
                    <MTCTextField
                      gridMD={6}
                      gridLG={4}
                      id="finish_end_point_latitude"
                      onChange={event => {
                        this.updateEndLine('finish_end_point_latitude', event.target.value);
                      }}
                      type="number"
                      value={finish_end_point_latitude}
                      required={true}
                      inputProps={{
                        min: -90,
                        max: 90,
                        minLength: '1',
                        required: true,
                        step: 0.00000000000000000001,
                      }}
                    />
                    <MTCTextField
                      gridMD={6}
                      gridLG={4}
                      id="finish_end_point_longitude"
                      onChange={event => {
                        this.updateEndLine('finish_end_point_longitude', event.target.value);
                      }}
                      type="number"
                      value={finish_end_point_longitude}
                      required={true}
                      inputProps={{
                        min: -180,
                        max: 180,
                        minLength: '1',
                        required: true,
                        step: 0.00000000000000000001,
                      }}
                    />
                    <Grid container justifyContent={'center'} spacing={1}>
                      <MTCTextField
                        gridMD={12}
                        id="finish_lap_line_bearing"
                        onChange={event => {event.stopPropagation();}}
                        disabled
                        type="number"
                        value={finish_lap_line_bearing}
                      />

                      <MTCSwitch
                        id="is_finish_lap_line_bearing_reversed"
                        onChange={this.changeIsFinishLapLineBearingReversed}
                        value={is_finish_lap_line_bearing_reversed}
                      />
                    </Grid>
                  </Grid>
                  <MTCButton
                    onClick={() => this.setState({
                      drawMarkerLabel: 'finish_line_start',
                    })}
                    disabled={drawMarkerLabel != null}
                    text={t('draw_line')}
                    color={'primary'}
                  />
                </>}

                <TrackMapBox
                  isMarkerShown={true}
                  handleMarker={drawMarkerLabel
                    ? this.handleMarkerComplete
                    : null}
                  handleDragMarker={this.handleDragMarker}
                  trackMarker={latitude && longitude
                    ? {
                      latitude: Number(latitude),
                      longitude: Number(longitude),
                    }
                    : null}
                  startPointMarker={start_point_latitude && start_point_longitude
                    ? {
                      latitude: Number(start_point_latitude),
                      longitude: Number(start_point_longitude),
                    }
                    : null}
                  endPointMarker={end_point_latitude && end_point_longitude
                    ? {
                      latitude: Number(end_point_latitude),
                      longitude: Number(end_point_longitude),
                    }
                    : null}
                  finishStartPointMarker={has_finish_line
                  && finish_start_point_latitude
                  && finish_start_point_longitude
                    ? {
                      latitude: Number(finish_start_point_latitude),
                      longitude: Number(finish_start_point_longitude),
                    }
                    : null}
                  finishEndPointMarker={has_finish_line
                  && finish_end_point_latitude
                  && finish_end_point_longitude
                    ? {
                      latitude: Number(finish_end_point_latitude),
                      longitude: Number(finish_end_point_longitude),
                    }
                    : null}
                  hasFinishLine={has_finish_line}
                  sectors={sectors}
                  geojson={geojson}
                  googleMapURL={GOOGLE_MAPS_HOST + GOOGLE_MAPS_API_KEY + GOOGLE_MAPS_OPTIONS}
                  loadingElement={<div style={{ height: `100%` }} />}
                  containerElement={<div style={{
                    height: `400px`,
                    width: `100%`,
                  }} />}
                  mapElement={<div style={{ height: `100%` }} />}
                  lapLineBearing={lap_line_bearing}
                  finishLapLineBearing={finish_lap_line_bearing}
                />
              </Grid>
            </Paper>

            <Paper className={classes.paper}>
              <Grid container alignItems={'center'} direction={'column'} justifyContent={'center'}>

                <Typography
                  align="center"
                  variant="h5"
                  component="h2"
                  gutterBottom>
                  {this.props.t('sectors')}
                </Typography>


                {sectors.map(sector =>
                  <div key={`sector_${sector.number}`} className={classes.sectorBloc}>
                    <Typography
                      align="center"
                      variant="h6"
                      gutterBottom>
                      {this.props.t('sector') + ` ${sector.number}`}
                    </Typography>
                    <Grid container justifyContent={'center'}>
                      <MTCTextField
                        gridMD={6}
                        gridLG={4}
                        id={`sector_${sector.number}_line_start_point_latitude`}
                        label={t('sector_line_start_point_latitude')}
                        onChange={event => {
                          if (isValidLatitude(event.target.value)) {
                            this.setSectorPosition(
                              sector.number,
                              'line_start_point_latitude',
                              event.target.value,
                            );
                          }
                        }}
                        type="number"
                        value={sector.line_start_point_latitude}
                        required={true}
                        inputProps={{
                          min: -90,
                          max: 90,
                          minLength: '1',
                          required: true,
                          step: 0.00000000000000000001,
                        }}
                        disabled={sector.number === sectors.length}
                      />
                      <MTCTextField
                        gridMD={6}
                        gridLG={4}
                        id={`sector_${sector.number}_line_start_point_longitude`}
                        label={t('sector_line_start_point_longitude')}
                        onChange={event => {
                          if (isValidLongitude(event.target.value)) {
                            this.setSectorPosition(
                              sector.number,
                              'line_start_point_longitude',
                              event.target.value,
                            );
                          }
                        }}
                        type="number"
                        value={sector.line_start_point_longitude}
                        required={true}
                        inputProps={{
                          min: -90,
                          max: 90,
                          minLength: '1',
                          required: true,
                          step: 0.00000000000000000001,
                        }}
                        disabled={sector.number === sectors.length}
                      />
                    </Grid>
                    <Grid container justifyContent={'center'}>
                      <MTCTextField
                        gridMD={4}
                        id={`sector_${sector.number}_line_end_point_latitude`}
                        label={t('sector_line_end_point_latitude')}
                        onChange={event => {
                          if (isValidLatitude(event.target.value)) {
                            this.setSectorPosition(
                              sector.number,
                              'line_end_point_latitude',
                              event.target.value,
                            );
                          }
                        }}
                        type="number"
                        value={sector.line_end_point_latitude || 10}
                        required={true}
                        inputProps={{
                          min: -90,
                          max: 90,
                          minLength: '1',
                          required: true,
                          step: 0.00000000000000000001,
                        }}
                        disabled={sector.number === sectors.length}
                      />
                      <MTCTextField
                        gridMD={4}
                        id={`sector_${sector.number}_line_end_point_longitude`}
                        label={t('sector_line_end_point_longitude')}
                        onChange={event => {
                          if (isValidLongitude(event.target.value)) {
                            this.setSectorPosition(
                              sector.number,
                              'line_end_point_longitude',
                              event.target.value,
                            );
                          }
                        }}
                        type="number"
                        value={sector.line_end_point_longitude || 10}
                        required={true}
                        inputProps={{
                          min: -90,
                          max: 90,
                          minLength: '1',
                          required: true,
                          step: 0.00000000000000000001,
                        }}
                        disabled={sector.number === sectors.length}
                      />
                    </Grid>
                    {sector.number !== sectors.length
                    && <Grid container justifyContent={'center'} spacing={2}>
                      <Grid item>
                        <MTCButton
                          type={'button'}
                          text={t('draw_line')}
                          onClick={() => this.drawSector(sector.number)}
                          color={'primary'}
                          disabled={drawMarkerLabel != null}
                        />
                      </Grid>
                      <Grid item>
                        <MTCButton
                          type={'button'}
                          text={t('remove_sector') + ` ${sector.number}`}
                          onClick={() => this.removeSector(sector.number)}
                          color={'primary'}
                          style={{ backgroundColor: michelin_red }}
                        />
                      </Grid>
                    </Grid>}
                    <Divider className={classes.sectorDivider} />
                  </div>,
                )}

                {sectors.length < 20 &&
                <MTCButton
                  type={'button'}
                  text={t('add_sector')}
                  onClick={this.addSector}
                  color={'primary'}
                />}
              </Grid>
            </Paper>

            <MTCSwitch
              id="is_public"
              onChange={() =>
                this.setState({
                  is_public: !is_public,
                  canNotify: !is_public && !has_been_notify,
                  notify: originalNotify
                })
              }
              value={is_public}
              disableText={t('tester')}
              enableText={t('public')}
            />
            <MTCSwitch
              id="notify"
              onChange={() => this.setState({ notify: !notify })}
              value={notify}
              disabled={!canNotify}
              helperText={
                (!canNotify && notify)
                  ? t('track_already_notify')
                  : (!canNotify && !notify)
                    ? t('cant_notify_tester_track')
                    : ''
              }
            />
            <Typography variant="subtitle1" gutterBottom>
              {t('mandatory_text_field')}
            </Typography>
            <Grid
              container
              direction="column"
              justifyContent="space-evenly"
              alignItems="center">
              <MTCButton
                disabled={isInvalid}
                onClick={this.onSubmit}
                type={'button'}
                sending={this.props.sending}
                text={t(isEditing
                  ? 'edit'
                  : 'create')}
              />
              {isEditing && nextTrackToUpdate &&
              <Grid item xs={12} sm={12} md={10} className={classes.grid}>
                <MTCButton
                  disabled={isInvalid}
                  type={'button'}
                  sending={this.props.sending}
                  color={'primary'}
                  text={t('edit_and_next')}
                  onClick={() => this.onSubmit(null, true)}
                />
              </Grid>
              }
            </Grid>
          </Grid>
          <Dialog
            open={confirmDialogOpen}
            onClose={this.handleCloseCancel}
            aria-labelledby="alert-confirm-dialog-title"
            aria-describedby="alert-confirm-dialog-description"
          >
            <DialogTitle id="alert-confirm-dialog-title">
              {t('new_sectors_warning')}
            </DialogTitle>
            <DialogActions>
              <Button onClick={this.handleCloseCancel} color="primary">
                {t('cancel')}
              </Button>
              <Button onClick={() => this.onSubmitSend(true)} color="primary" autoFocus>
                {t('validate')}
              </Button>
            </DialogActions>
          </Dialog>
        </form>
      );
    }
  }
}

function mapStateToProps(state) {
  return {
    tracks: state.tracks.content,
    pending: state.tracks.pending,
    sending: state.tracks.sending,
    error: state.tracks.error,
  };
}

export default compose(
  withStyles(styles),
  withTranslation,
  connect(mapStateToProps,
    {
      putMultipartTrack,
      postTrack,
      getTracks,
      handleError,
    },
  ),
)(TrackForm);
