import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import withAuthentication from '../../../hoc/withAuthentication';
import { ROLE_ADMIN } from '../../../constants/roles';
import { setTitle } from '../../../store/title/actions';
import AddButton from '../../../components/Layout/Button/AddButton';
import { FAQ_ARTICLES_ADD } from '../../../constants/routes';
import FaqArticlesTable from '../../../components/Faq/Articles/FaqArticlesTable/FaqArticlesTable';

export const FaqArticlePage = () => {
  const { t } = useTranslation();
  const { title } = useSelector(state => state.title);
  const dispatch = useDispatch();
  if (title !== t('title:title.faq_articles_page')) {
    dispatch(setTitle(t('title:title.faq_articles_page')));
  }

  return (
    <>
      <FaqArticlesTable />
      <AddButton path={FAQ_ARTICLES_ADD} />
    </>
  )
}

export default withAuthentication(ROLE_ADMIN)(FaqArticlePage);
