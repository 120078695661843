import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Timestamp from 'react-timestamp';
import SearchIcon from '@material-ui/icons/Search';
import InputBase from '@material-ui/core/InputBase';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import { useStyles } from './LastRunsKPIBlock.style';
import KpiPaper from '../KpiPaper/KpiPaper';
import { getHomeWithSearch } from '../../../store/home/actions';

const getVehicleName = (run) => {
  const brandName = run?.rally_dimension?.rally_group?.rally_model?.rally_brand?.name;
  if (brandName) {
    const modelName = run?.rally_dimension?.rally_group?.rally_model?.name;
    const groupName = run?.rally_dimension?.rally_group?.name;
    return `${brandName} ${modelName} ${groupName}`
  }
  return '';
}

const LastRunsKPIBlock = ({ title, lastRallyRuns, defaultSearch }) => {
  const classes = useStyles();
  const { t } = useTranslation('kpi');
  const [search, setSearch] = useState(defaultSearch);
  const dispatch = useDispatch();

  lastRallyRuns.sort((a, b) => b.start_date - a.start_date);

  const onSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();
    dispatch(getHomeWithSearch(search));
  }

  return (
    <KpiPaper>
      <Grid
        container
        justifyContent="space-between"
        direction="row"
      >
        <Grid item>
      <Typography
        variant="h3"
        color="primary"
        component="p"
        className={classes.title}
      >
        {title}
      </Typography>
        </Grid>
        <Grid item>
          <Paper elevation={0} variant={"outlined"} className={classes.searchPaper}>
          <form onSubmit={onSubmit}>
            <IconButton type="submit" aria-label="search">
              <SearchIcon />
            </IconButton>
            <InputBase
              value={search}
              onChange={(event => setSearch(event.target.value))}
              id={"search"}
              name={"search"}
              placeholder="Rechercher une adresse email"
              inputProps={{ 'aria-label': 'search' }}
              className={classes.searchInput}
            />
          </form>
          </Paper>
        </Grid>
      </Grid>
      <Table>
        <TableHead className={classes.head}>
          <TableCell className={classes.tableHeadCell}>
            <Typography className={classes.tableHeadLabel} variant="body1">Email</Typography>
          </TableCell>
          <TableCell className={classes.tableHeadCell}>
            <Typography className={classes.tableHeadLabel} variant="body1">
              {t('last_rally_runs_table.date')}
            </Typography>
          </TableCell>
          <TableCell className={classes.tableHeadCell}>
            <Typography className={classes.tableHeadLabel} variant="body1">
              {t('last_rally_runs_table.duration')}
            </Typography>
          </TableCell>
          <TableCell className={classes.tableHeadCell}>
            <Typography className={classes.tableHeadLabel} variant="body1">Run ID</Typography>
          </TableCell>
          <TableCell className={classes.tableHeadCell}>
            <Typography className={classes.tableHeadLabel} variant="body1">
              {t('last_rally_runs_table.vehicle')}
            </Typography>
          </TableCell>
          <TableCell className={classes.tableHeadCell}>
            <Typography className={classes.tableHeadLabel} variant="body1">Type</Typography>
          </TableCell>
        </TableHead>
        {lastRallyRuns.map((run, index) =>
          <TableRow key={index} className={classes.row}>
            <TableCell className={classes.cell}>
              <Typography variant="body1">
                {run.email}
              </Typography>
            </TableCell>
            <TableCell className={classes.cell}>
              <Typography variant="body1">
                <Timestamp
                  format='full'
                  twentyFourHour
                  time={run.start_date}
                />
              </Typography>
            </TableCell>
            <TableCell className={classes.cell}>
              <Typography variant="body1">
                <Timestamp
                  format='time'
                  twentyFourHour
                  time={run.end_date - run.start_date}
                />
              </Typography>
            </TableCell>
            <TableCell className={classes.cell}>
              <Typography variant="body1">
                {run.id}
              </Typography>
            </TableCell>
            <TableCell className={classes.cell}>
              <Typography variant="body1">
                {getVehicleName(run)}
              </Typography>
            </TableCell>
            <TableCell className={classes.cell}>
              <Typography variant="body1">
                {run.rolling_type === 0 ? 'Road' : 'Stage'}
              </Typography>
            </TableCell>
          </TableRow>
        )}
      </Table>
    </KpiPaper>
  );
};

export default LastRunsKPIBlock;
