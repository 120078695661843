import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import withAuthentication from '../../hoc/withAuthentication';
import { ROLE_ADMIN } from '../../constants/roles';
import { setTitle } from '../../store/title/actions';
import { getCatalogsTags } from '../../store/catalogsTags/actions';
import CatalogsTagsForm from '../../components/Catalogs/Tags/CatalogsTagsForm/CatalogsTagsForm';

export const CatalogsTagsAddPage = () => {

  const { t } = useTranslation();
  const { title } = useSelector(state => state.title);
  const {
    content,
    pending,
    error,
  } = useSelector(state => state.catalogsTags);
  const dispatch = useDispatch();

  if (title !== t('title:title.catalog_tags_add_page')) {
    dispatch(setTitle(t('title:title.catalog_tags_add_page')));
  }

  if (!content && !pending && !error) {
    dispatch(getCatalogsTags());
  }

  return <CatalogsTagsForm/>;
};

export default withAuthentication(ROLE_ADMIN)(CatalogsTagsAddPage);
