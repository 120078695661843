import makeStyles from '@material-ui/core/styles/makeStyles';

export const useStyles = makeStyles(() => (
  {
    text: {
      display: 'flex',
      alignItems: "flex-start",
      justifyContent: 'space-between',
      marginTop: '15px',
      width: '100%',
      maxWidth: '500px',
      margin: 'auto',
      padding: '5px',
    },
    textLabel: {
      flexGrow: 1,
      flexShrink: 2,
      flexBasis: 0,
      float: 'left',
      textAlign: 'left',
      maxWidth: '90%',
      lineHeight: '1.25',
    },
    textValue: {
      textAlign: 'right',
      flexGrow: 1,
      flexShrink: 1,
      flexBasis: 0,
    },
  }
));
