import {
  TRACK_DOWNLOADED,
  TRACK_FILTERED,
  TRACK_FILTERED_RESET,
  TRACK_SENDING,
  TRACKS_DOWNLOADED,
  TRACKS_FAILED,
  TRACKS_PENDING,
} from './types';
import { slugify } from '../../utils/StringUtils';

export const INITIAL_STATE = {
  error: null,
  content: null,
  filter: null,
  filteredContent: null,
  pending: false,
  sending: false,
};

function translationFilter(track, filter) {
  return slugify(
    track.translations['fr'].name.trim().toLowerCase()
  ).includes(
    slugify(filter.toLowerCase())
  );
}

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case TRACK_DOWNLOADED: {
      let tracks = [];
      let trackIndex = -1;
      if (state.content) {
        tracks = state.content.slice();
        trackIndex = tracks.findIndex(track => track.id === action.payload.track.id);
      }
      if (trackIndex === -1) {
        tracks.push(action.payload.track);
      } else {
        tracks[trackIndex] = action.payload.track;
      }
      let filter = state.filter;
      if (action.payload.filter) {
        filter = action.payload.track.translations['fr'].name;
      }
      let newFilteredContent = null;
      if (filter !== null) {
        newFilteredContent = tracks.filter(c =>
          translationFilter(c, filter)
        );
      }
      return {
        error: null,
        pending: false,
        sending: false,
        content: tracks,
        filter: filter,
        filteredContent: newFilteredContent,
      };
    }
    case TRACK_SENDING:
      return {
        ...state,
        sending: true,
        error: null,
      };
    case TRACKS_DOWNLOADED:
      return {
        content: action.payload,
        filteredContent: null,
        filter: null,
        error: null,
        pending: false,
        sending: false,
      };
    case TRACKS_FAILED:
      return {
        ...state,
        error: action.payload,
        filteredContent: null,
        pending: false,
        sending: false,
      };
    case TRACKS_PENDING:
      return {
        ...state,
        pending: true,
        error: null,
      };

    case TRACK_FILTERED: {
      let filter = action.payload.trim();
      let newFilteredContent = state.content.filter(c =>
        translationFilter(c, filter)
      );
      return {
        ...state,
        filter: filter,
        filteredContent: newFilteredContent,
      };
    }
    case TRACK_FILTERED_RESET:
      return {
        ...state,
        filter: null,
        filteredContent: null,
      };
    default:
      break;
  }
  return state;
}
