import makeStyles from '@material-ui/core/styles/makeStyles';
import { inputStyle } from '../../../constants/styles/input.style';

export const useStyles = makeStyles(theme => {
  return {
    input: {
      ...inputStyle(theme),
      color: theme.palette.textColor,
      backgroundColor: theme.palette.alternateTextColor,
    },
    smallField: {
      width: 70,
    },
    bootstrapRoot: {
      padding: 0,
      'label + &': {
        marginTop: theme.spacing(3),
      },
    },
    bootstrapInput: {
      borderRadius: 4,
      backgroundColor: theme.palette.common.white,
      color: theme.palette.common.black,
      border: '1px solid #ced4da',
      fontSize: 16,
      padding: '10px 12px',
      width: 'calc(100% - 24px)',
      transition: theme.transitions.create(['border-color', 'box-shadow']),
    },
  };
});
