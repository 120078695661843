import { INITIAL_STATE } from './AuthorizedExportForm.const';

export const stateWithAuthorizedExport = (authorizedExport) => {
  return {
    ...INITIAL_STATE,
    id: authorizedExport.id,
    emails: authorizedExport.emails,
    receiver_names: authorizedExport.receiver_names,
    status: authorizedExport.status,
  };
};
