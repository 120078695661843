export const sortData = (a, b, property, order) => {
  let prop1;
  let prop2;
  if (property && property.includes('.')) {
    let properties = property.split('.');
    properties.forEach(property => {
      a = a
        ? a[property]
        : '';
      b = b
        ? b[property]
        : '';
    });
    prop1 = a;
    prop2 = b;
  } else {
    prop1 = (
      a[property] !== undefined && a[property] !== null
    )
      ? a[property]
      : -1;
    if (Array.isArray(prop1)) {
      prop1 = a[property][0] ?? -1;
    }
    prop2 = (
      b[property] !== undefined && b[property] !== null
    )
      ? b[property]
      : -1;
    if (Array.isArray(prop2)) {
      prop2 = b[property][0] ?? -1;
    }
  }
  if (order === 'asc') {
    return prop1 < prop2
      ? -1
      : 1;
  }
  return prop1 > prop2
    ? -1
    : 1;
};
