import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import Link from '@material-ui/core/Link';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { TRACK_ADD } from '../../constants/routes';
import { ROLE_ADMIN, ROLE_SUPPORT } from '../../constants/roles';
import { setTitle } from '../../store/title/actions';
import ApiService from '../../services/ApiService';
import withAuthentication from '../../hoc/withAuthentication';
import AddButton from '../../components/Layout/Button/AddButton';
import TrackCsvDropZone from '../../components/Tracks/TrackCsvDropZone';
import TracksTable from '../../components/Tracks/TracksTable';
import TrackSearch from '../../components/Tracks/TrackSearch';
import CsvFile from '../../components/icon/CsvFile';

const useStyles = makeStyles(() => {
  return {
    link: {
      padding: '5px 0',
    },
  };
});

export const TracksPage = () => {

  const classes = useStyles();
  const { t } = useTranslation();
  const { title } = useSelector(state => state.title);
  const dispatch = useDispatch();
  const role = localStorage.getItem('role');

  if (title !== t('title:title.tracks_page')) {
    dispatch(setTitle(t('title:title.tracks_page')));
  }

  return (
    <>
      {role === ROLE_ADMIN && <TrackCsvDropZone />}
      <TrackSearch />
      {role === ROLE_ADMIN && <Link
        id={'export_csv'}
        href={'#'}
        onClick={() => {
          ApiService.getExportCsv();
        }}
        className={classes.link}
      >
        <CsvFile size={'20'} style={{
          verticalAlign: 'text-bottom',
          marginRight: '5px',
        }} />
        Export CSV
      </Link>}
      <TracksTable />
      {role === ROLE_ADMIN && <AddButton
        path={TRACK_ADD}
      />}
    </>
  );
};

export default withAuthentication(ROLE_SUPPORT)(TracksPage);
