export const supportedDropFiles = [
  'text/csv',
  'application/csv',
  'application/vnd.ms-excel',
].toString();
export const csvToJson = (csv, separator) => {
  const [firstLine, ...lines] = csv.trim().split('\n');
  return lines.map(line =>
    firstLine.split(separator).reduce(
      (curr, next, index) => (
        next.trim() !== ''
          ? {
            ...curr,
            [next.trim()]: line.split(separator)[index],
          }
          : { ...curr }
      ),
      {},
    ),
  );
};
export const trackToUploadToJson = (trackToUpload) => {
  return {
    country_code: trackToUpload.country_code,
    length: Number(trackToUpload.length),
    latitude: Number(trackToUpload.latitude),
    longitude: Number(trackToUpload.longitude),
    start_point_latitude: Number(trackToUpload.start_point_latitude || 0.1),
    start_point_longitude: Number(trackToUpload.start_point_longitude || 0.1),
    end_point_latitude: Number(trackToUpload.end_point_latitude || 0.1),
    end_point_longitude: Number(trackToUpload.end_point_longitude || 0.1),
    translations: {
      de: { name: trackToUpload.name_de },
      fr: { name: trackToUpload.name_fr },
      en: { name: trackToUpload.name_en },
      us: { name: trackToUpload.name_us },
      es: { name: trackToUpload.name_es },
      it: { name: trackToUpload.name_it },
      zh: { name: trackToUpload.name_zh },
      nl: { name: trackToUpload.name_nl },
      at: { name: trackToUpload.name_at },
    },
  };
};
