import React, { useState } from 'react';
import TrackForm from '../../components/Tracks/TrackForm/TrackForm';
import withAuthentication from '../../hoc/withAuthentication';
import { ROLE_ADMIN } from '../../constants/roles';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { setTitle } from '../../store/title/actions';

export const AddTrackPage = () => {

  const { t } = useTranslation();
  const { title } = useSelector(state => state.title);
  const dispatch = useDispatch();
  let history = useHistory();

  const [duplicateValue, setDuplicateValue] = useState(null);
  const [locationStateMatch, setLocationStateMatch] = useState(true);

  if (
    history.location
    && history.location.state
    && locationStateMatch
  ) {
    setDuplicateValue(history.location.state.value);
    setLocationStateMatch(false);
  }

  if (title !== t('title:title.add_track_page') && !duplicateValue) {
    dispatch(setTitle(t('title:title.add_track_page')));
  }
  if (title !== t('title:title.duplicate_track_page') && duplicateValue) {
    dispatch(setTitle(t('title:title.duplicate_track_page')));
  }

  return (
    <TrackForm history={history} duplicateValue={duplicateValue}/>
  );
}

export default withAuthentication(ROLE_ADMIN)(AddTrackPage);
