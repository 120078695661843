import {
  RALLY_CARS_BRANDS_PENDING,
  RALLY_CARS_MODELS_PENDING,
  RALLY_CARS_GROUPS_PENDING,
  RALLY_CARS_DIMENSIONS_PENDING,
  RALLY_CARS_BOUNDARIES_PENDING,
  RALLY_CARS_BRANDS_DOWNLOADED,
  RALLY_CARS_MODELS_DOWNLOADED,
  RALLY_CARS_GROUPS_DOWNLOADED,
  RALLY_CARS_DIMENSIONS_DOWNLOADED,
  RALLY_CARS_BOUNDARIES_DOWNLOADED,
  RALLY_CAR_PENDING,
  RALLY_CAR_FAILED,
  RALLY_CAR_RESET,
  RALLY_CAR_TABLE_LIMIT_CHANGE,
  RALLY_CARS_CSV_DOWNLOADED,
  RALLY_CHANGE_SEARCH_CAR_STATE,
  RALLY_CHANGE_SEARCH_IS_PUBLIC,
} from './types';

export const INITIAL_STATE = {
  rally_brands: null,
  rally_brands_pending: false,
  selected_rally_brand: '',
  rally_models: [],
  rally_models_pending: false,
  selected_rally_model: '',
  rally_groups: [],
  rally_groups_pending: false,
  selected_rally_group: '',
  rally_dimensions: [],
  rally_dimensions_pending: false,
  selected_rally_dimension: '',
  rally_boundaries: [],
  rally_boundaries_pending: false,
  selected_is_public: null,
  selected_rally_car_state: null,
  error: null,
  sending: false,
  pending: false,
  limit: 50,
};

const compareByName = (a, b) => {
  if (a.name > b.name) return 1;
  if (b.name > a.name) return -1;
  return 0;
}

const compareDimension = (a, b) => {
  if (a.frontDimension > b.frontDimension) return 1;
  if (b.frontDimension > a.frontDimension) return -1;
  return 0;
}

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case RALLY_CARS_BRANDS_PENDING:
      return {
        ...state,
        rally_brands_pending: true
      };
    case RALLY_CARS_MODELS_PENDING:
      return {
        ...state,
        rally_models_pending: true
      };
    case RALLY_CARS_GROUPS_PENDING:
      return {
        ...state,
        rally_groups_pending: true
      };
    case RALLY_CARS_DIMENSIONS_PENDING:
      return {
        ...state,
        rally_dimensions_pending: true
      };
    case RALLY_CARS_BOUNDARIES_PENDING:
      return {
        ...state,
        rally_boundaries_pending: true
      };
    case RALLY_CARS_BRANDS_DOWNLOADED:
      return {
        ...state,
        rally_brands_pending: false,
        rally_brands: action.payload.data.sort(compareByName),
        selected_rally_brand: '',
        rally_models: [],
        selected_rally_model: '',
        rally_groups: [],
        selected_rally_group: '',
        rally_dimensions: [],
        selected_rally_dimension: '',
        rally_boundaries: [],
      };
    case RALLY_CARS_MODELS_DOWNLOADED:
      return {
        ...state,
        rally_models_pending: false,
        selected_rally_brand: action.payload.id,
        rally_models: action.payload.data.sort(compareByName),
        selected_rally_model: '',
        rally_groups: [],
        selected_rally_group: '',
        rally_dimensions: [],
        selected_rally_dimension: '',
        rally_boundaries: [],
      };
    case RALLY_CARS_GROUPS_DOWNLOADED:
      return {
        ...state,
        rally_groups_pending: false,
        selected_rally_model: action.payload.id,
        rally_groups: action.payload.data.sort(compareByName),
        selected_rally_group: '',
        rally_dimensions: [],
        selected_rally_dimension: '',
        rally_boundaries: [],
      };
    case RALLY_CARS_DIMENSIONS_DOWNLOADED:
      return {
        ...state,
        rally_dimensions_pending: false,
        selected_rally_group: action.payload.id,
        rally_dimensions: action.payload.data.sort(compareDimension),
        selected_rally_dimension: '',
        rally_boundaries: [],
      };
    case RALLY_CARS_BOUNDARIES_DOWNLOADED:
      return {
        ...state,
        rally_boundaries_pending: false,
        selected_rally_dimension: action.payload.id,
        rally_boundaries: action.payload.data,
      };
    case RALLY_CAR_FAILED:
      return {
        ...state,
        error: action.payload,
        pending: false
      };
    case RALLY_CAR_PENDING:
      return {
        ...state,
        pending: true,
        error: null,
      };
    case RALLY_CAR_RESET:
      return {
        ...state,
        rally_brands_pending: false,
        selected_rally_brand: '',
        rally_models: [],
        rally_models_pending: false,
        selected_rally_model: '',
        rally_groups: [],
        rally_groups_pending: false,
        selected_rally_group: '',
        rally_dimensions: [],
        rally_dimensions_pending: false,
        selected_rally_dimension: '',
        rally_boundaries: [],
        rally_boundaries_pending: false,
        selected_is_public: null,
        selected_rally_car_state: null,
        error: null,
        sending: false,
      }
    case RALLY_CAR_TABLE_LIMIT_CHANGE:
      return {
        ...state,
        limit: action.payload
      }
    case RALLY_CARS_CSV_DOWNLOADED:
      return {
        ...state,
        pending: false
      }
    case RALLY_CHANGE_SEARCH_IS_PUBLIC: {
      return {
        ...state,
        selected_is_public: action.payload
      };
    }
    case RALLY_CHANGE_SEARCH_CAR_STATE: {
      return {
        ...state,
        selected_rally_car_state: action.payload
      };
    }
    default:
      break;
  }
  return state;
}
