import { ERROR, ERROR_RESET, INFO, INFO_RESET } from './types';

export const INITIAL_STATE = {
  error: null,
  info: null,
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case ERROR_RESET:
      return {
        ...state,
        error: null,
      };
    case ERROR:
      return {
        ...state,
        error: action.payload,
      };
    case INFO_RESET:
      return {
        ...state,
        info: null,
      };
    case INFO:
      return {
        ...state,
        info: action.payload,
      };
    default:
      break;
  }

  return state;
}
