import makeStyles from '@material-ui/core/styles/makeStyles';

export const useStyles = makeStyles(() => {
  return {
    list: {
      marginBottom: '6em'
    },
    emptyList: {
      marginTop: '2em',
      marginBottom: '6em'
    },
  };
});
