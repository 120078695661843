import makeStyles from '@material-ui/core/styles/makeStyles';
import { michelin_black, michelin_blue_main } from '../../../constants/michelin_colors';

export const useStyles = makeStyles(theme => (
  {
    container: {
      width: '100%',
      maxWidth: '991px',
      margin: 'auto',
      [theme.breakpoints.down('sm')]: {
        marginBottom: '4em',
      },
    },
    information: {
      width: '100%',
      display: 'flex',
      flex: 'auto',
      marginBottom: '20px',
      marginTop: '20px',
      flexDirection: 'column',
      alignItems: 'center',
      textAlign: 'center',
      padding: '20px 28px',
    },
    title: {
      margin: '10px',
      color: michelin_black,
    },
    description: {
      textAlign: 'justify',
      textAlignLast: 'center',
      marginBottom: '30px',
      marginTop: '10px',
    },
    avatar: {
      width: '200px',
      height: '200px',
      margin: 'auto',
      padding: '2em',
      backgroundColor: michelin_blue_main,
    },
  }
));
