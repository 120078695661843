import { ENV_DEV, ENV_LOCAL, ENV_MOCK, ENV_PROD, PROD_API_URL } from './env';

let apiUrl = '';
let apiUser;
let apiRoot;

if (process.env.REACT_APP_ENV === ENV_MOCK) {
  apiRoot = `http://localhost:3000`;
  apiUrl = apiRoot;
} else {
  if (process.env.REACT_APP_ENV === ENV_DEV) {
    apiRoot = `https://michelin-trackconnect-api-dev.openium.fr/`; // dev
  } else if (process.env.REACT_APP_ENV === ENV_LOCAL) {
    apiRoot = `https://michelin-trackconnect-master.openium.fr/`; // vagrant
  } else if (process.env.NODE_ENV === ENV_PROD) {
    apiRoot = PROD_API_URL; // preprod - prod
  }
  apiUrl = apiRoot + `tr4/api/admin`;
}

apiUser = apiRoot + `tr4/api/v7`;

const API_URL = apiUrl;

export const API_ROOT = apiRoot;
export const API_ROOT_USER = apiUser;
export default API_URL;
