import { data_languages } from '../../../../constants/lang';

export const INITIAL_STATE = {
  id: null,
  ...data_languages.reduce((o, key) => (
    {
      ...o,
      [`name_${key}`]: '',
    }
  ), {}),
};

export const INITIAL_ERROR_STATE = data_languages.reduce((o, key) => (
  {
    ...o,
    [`name_${key}_error`]: null,
  }
), {});

export const stateWithTag = (tag) => {
  return {
    ...INITIAL_STATE,
    id: tag.id,
    ...data_languages.reduce((o, key) => (
      {
        ...o,
        [`name_${key}`]: tag.translations[key] ? tag.translations[key].name : '',
      }
    ), {}),
  };
};
