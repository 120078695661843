import {
  CATALOGS_ITEM_DOWNLOADED,
  CATALOGS_ITEMS_DOWNLOADED,
  CATALOGS_ITEMS_FAILED,
  CATALOGS_ITEMS_PENDING,
  CATALOGS_ITEMS_SENDING,
} from './types';
import { TYPE_PDF, TYPE_YOUTUBE } from '../../constants/catalogItemType';

export const INITIAL_STATE = {
  content: null,
  pdfItems: [],
  youtubeItems: [],
  pending: false,
  sending: false,
  error: null,
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case CATALOGS_ITEMS_PENDING:
      return {
        ...state,
        pending: true,
      };
    case CATALOGS_ITEMS_SENDING:
      return {
        ...state,
        sending: true,
      };
    case CATALOGS_ITEMS_FAILED:
      return {
        ...state,
        pending: false,
        sending: false,
        error: action.payload,
      };
    case CATALOGS_ITEMS_DOWNLOADED:
      return {
        ...state,
        pending: false,
        content: action.payload,
        pdfItems: action.payload.filter(item => item.type === TYPE_PDF),
        youtubeItems: action.payload.filter(item => item.type === TYPE_YOUTUBE),
      };
    case CATALOGS_ITEM_DOWNLOADED: {
      let newContent = [];
      if (state.content != null) {
        newContent = [...state.content];
      }
      let index = newContent.findIndex(item => item.id === action.payload.id);
      if (index === -1) {
        newContent.push(action.payload);
      } else {
        newContent[index] = action.payload;
      }
      return {
        ...state,
        pending: false,
        sending: false,
        content: newContent,
        pdfItems: newContent.filter(item => item.type === TYPE_PDF),
        youtubeItems: newContent.filter(item => item.type === TYPE_YOUTUBE),
      };
    }
    default:
      break;
  }
  return state;
}