export const kilometersToMiles = (t) => {
  return t * 0.6213711922;
}

export const celciusToFahrenheit = (t) => {
  return ((t * 1.8) + 32);
}

export const barToPsi = (t) => {
  return t * 14.5038;
}
