import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import withAuthentication from '../../hoc/withAuthentication';
import { ROLE_ADMIN } from '../../constants/roles';
import { setTitle } from '../../store/title/actions';
import { getCatalogsTags } from '../../store/catalogsTags/actions';
import CatalogsTagsForm from '../../components/Catalogs/Tags/CatalogsTagsForm/CatalogsTagsForm';
import MTCLoader from '../../components/Layout/MTCLoader';
import Error404 from '../Error404';

export const CatalogsTagsEditPage = () => {

  const { t } = useTranslation();
  const { title } = useSelector(state => state.title);
  const {
    content,
    pending,
    error,
  } = useSelector(state => state.catalogsTags);
  const dispatch = useDispatch();
  let { tagId } = useParams();
  const [tag, setTag] = useState(null);

  if (title !== t('title:title.catalog_tags_edit_page')) {
    dispatch(setTitle(t('title:title.catalog_tags_edit_page')));
  }

  if (!content && !pending && !error) {
    dispatch(getCatalogsTags());
  }
  if (content && tag === null) {
    const result = content.find(t => t.id == tagId);
    if (result) {
      setTag(result);
    } else {
      setTag(false);
    }
  }

  if (pending) {
    return <MTCLoader />;
  } else if (!pending && content && !tag) {
    return <Error404 />;
  } else {
    return <CatalogsTagsForm tag={tag} />;
  }
};

export default withAuthentication(ROLE_ADMIN)(CatalogsTagsEditPage);
