import makeStyles from '@material-ui/core/styles/makeStyles';
import { drawerWidth, drawerWidthMd } from './Menu.const';

export const useStyles = makeStyles(theme => (
  {
    menu: {
      overflowY: 'auto',
      height: 'auto',
      paddingRight: '2vh',
    },
    root: {
      flexGrow: 1,
    },
    drawerPaper: {
      display: 'block',
      width: drawerWidth,
      [theme.breakpoints.down('md')]: {
        width: drawerWidthMd,
      },
      [theme.breakpoints.down('sm')]: {
        width: drawerWidth,
      },
    },
  }
));
