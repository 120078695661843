import {
  TESTER_DELETED,
  TESTER_DOWNLOADED,
  TESTER_SENDING,
  TESTERS_DOWNLOADED,
  TESTERS_FAILED,
  TESTERS_PENDING,
} from './types';

export const INITIAL_STATE = {
  error: null,
  content: null,
  pending: false,
  sending: false,
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case TESTER_DOWNLOADED: {
      let testers = [];
      let testerIndex = -1;
      if (state.content) {
        testers = state.content.slice();
        testerIndex = testers.findIndex(tester => tester.id === action.payload.id);
      }
      if (testerIndex === -1) {
        testers.push(action.payload);
      } else {
        testers[testerIndex] = action.payload;
      }
      return {
        error: null,
        pending: false,
        sending: false,
        content: testers,
      };
    }
    case TESTER_SENDING:
      return {
        ...state,
        sending: true,
        error: null,
      };
    case TESTERS_DOWNLOADED:
      return {
        content: action.payload,
        error: null,
        pending: false,
        sending: false,
      };
    case TESTERS_FAILED:
      return {
        ...state,
        error: action.payload,
        pending: false,
        sending: false,
      };
    case TESTERS_PENDING:
      return {
        ...state,
        pending: true,
        error: null,
      };
    case TESTER_DELETED: {
      let email = action.payload;
      let newContent = state.content.filter((value) => {
        return value.email !== email;
      });
      return {
        content: newContent,
        error: null,
        pending: false,
        sending: false,
      };
    }
    default:
      break;
  }
  return state;
}
