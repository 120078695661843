import { HOME_DOWNLOADED, HOME_FAILED, HOME_PENDING, HOME_RESET_TIMER, HOME_SEARCH } from './types';

export const INITIAL_STATE = {
  lastUpdate: 0,
  reportedCircuitCommentTotalCount: 0,
  reportedCircuitComment: [],
  rallyUserCount: 0,
  rallyCarCount: 0,
  rallySessionCount: 0,
  lastRallyRuns: [],
  countryUserCount: [],
  maxRallySessionUsers: [],
  userMmcReceiverAverage: 0,
  pending: false,
  error: null,
  search: ''
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case HOME_RESET_TIMER:
      return {
        ...state,
        lastUpdate: INITIAL_STATE.lastUpdate,
      };
    case HOME_PENDING:
      return {
        ...state,
        pending: true,
      };
    case HOME_FAILED:
      return {
        ...state,
        lastUpdate: Date.now(),
        pending: false,
        error: action.payload,
      };
    case HOME_DOWNLOADED:
      return {
        ...state,
        pending: false,
        lastUpdate: Date.now(),
        reportedCircuitComment: action.payload.reported_circuit_comment,
        reportedCircuitCommentTotalCount: action.payload.reported_circuit_comment_total_count,
        rallyUserCount: action.payload.rally_user_count,
        rallyCarCount: action.payload.rally_car_count,
        rallySessionCount: action.payload.rally_session_count,
        lastRallyRuns: action.payload.last_rally_runs,
        countryUserCount: action.payload.country_user_count,
        maxRallySessionUsers: action.payload.max_rally_session_users,
        userMmcReceiverAverage: action.payload.user_mmc_receiver_average,
      };
    case HOME_SEARCH:
      return {
        ...state,
        search: action.payload
      }
    default:
      break;
  }
  return state;
}
