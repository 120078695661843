import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Tooltip from '@material-ui/core/Tooltip';
import useTheme from '@material-ui/core/styles/useTheme';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import Typography from '@material-ui/core/Typography';
import AddToPhotosIcon from '@material-ui/icons/AddToPhotos';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { CAR_DUPLICATE, CAR_EDIT } from '../../../constants/routes';
import { formReset } from '../../../store/formCars/actions';
import {
  editCarsBrand,
  editCarsDimension,
  editCarsEngine,
  editCarsModel,
  editCarsRange,
} from '../../../store/tableCars/actions';
import { useStyles } from './RaceCarRow.style';

const RaceCarRow = ({ value, setMenuAction }) => {

  const theme = useTheme();
  const classes = useStyles(theme);
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const [focusCell, setFocusCell] = useState(null);

  const resetFocus = () => {
    setFocusCell(null);
  }

  const handleEdit = event => {
    if (event) {
      event.stopPropagation();
    }
    dispatch(formReset());
    history.push(CAR_EDIT.replace(':dimensionId', value.dimension_id));
  };

  const handleDuplicate = event => {
    if (event) {
      event.stopPropagation();
      event.preventDefault();
    }
    if (!value.dimension_is_other) {
      history.push(CAR_DUPLICATE.replace(':dimensionId', value.dimension_id));
    }
  };

  const getClassByState = (state) => {
    switch (state) {
      case 1:
        return classes.cellDisabled;
      case 2:
        return classes.cellParentDisabled;
      case 3:
        return classes.focusCell;
      default:
        return classes.clickableCell;
    }
  };

  const brandState = focusCell === 'brand'
    ? 3
    : (value.brand_state ? 1 : 0);
  const rangeState = focusCell === 'range'
    ? 3
    : (brandState === 1 ? 2 : value.range_state ? 1 : 0);
  const modelState = focusCell === 'model'
    ? 3
    : ((rangeState >= 1 && rangeState < 3) ? 2 : value.model_state ? 1 : 0);
  const engineState = focusCell === 'engine'
    ? 3
    : ((modelState >= 1 && modelState < 3) ? 2 : value.engine_state ? 1 : 0);
  const dimensionState = focusCell === 'dimension'
    ? 3
    : ((engineState >= 1 && engineState < 3) ? 2 : value.dimension_state ? 1 : 0);

  const displayBrandContextMenu = (event) => {
    let newValue = value.brand_state >= 1 ? 0 : 1;
    setFocusCell('brand');
    setMenuAction(
      event,
      function () {dispatch(editCarsBrand(value.brand_id, {state: newValue}))},
      value.brand_state ? t('activate') : t('deactivate'),
      handleEdit,
      !value.dimension_is_other ? handleDuplicate: null,
      resetFocus
    )
  }

  const displayRangeContextMenu = (event) => {
    let newValue = value.range_state >= 1 ? 0 : 1;
    setFocusCell('range');
    setMenuAction(
      event,
      function () {dispatch(editCarsRange(value.brand_id, value.range_id, {state: newValue}))},
      value.range_state ? t('activate') : t('deactivate'),
      handleEdit,
      !value.dimension_is_other ? handleDuplicate: null,
      resetFocus
    )
  }

  const displayModelContextMenu = (event) => {
    let newValue = value.model_state >= 1 ? 0 : 1;
    setFocusCell('model');
    setMenuAction(
      event,
      function () {dispatch(editCarsModel(
        value.brand_id,
        value.range_id,
        value.model_id,
        {state: newValue}
      ))},
      value.model_state ? t('activate') : t('deactivate'),
      handleEdit,
      !value.dimension_is_other ? handleDuplicate: null,
      resetFocus
    )
  }

  const displayEngineContextMenu = (event) => {
    let newValue = value.engine_state >= 1 ? 0 : 1;
    setFocusCell('engine');
    setMenuAction(
      event,
      function () {dispatch(editCarsEngine(
        value.brand_id,
        value.range_id,
        value.model_id,
        value.engine_id,
        {state: newValue}
      ))},
      value.engine_state ? t('activate') : t('deactivate'),
      handleEdit,
      !value.dimension_is_other ? handleDuplicate: null,
      resetFocus
    )
  }

  const displayDimensionContextMenu = (event) => {
    let newValue = value.dimension_state >= 1 ? 0 : 1;
    setFocusCell('dimension');
    setMenuAction(
      event,
      function () {dispatch(editCarsDimension(
        value.brand_id,
        value.range_id,
        value.model_id,
        value.engine_id,
        value.dimension_id,
        {state: newValue}
      ))},
      value.dimension_state ? t('activate') : t('deactivate'),
      handleEdit,
      !value.dimension_is_other ? handleDuplicate: null,
      resetFocus
    )
  }

  return (
    <TableRow
      className={classes.row}
      key={'r_' + value.dimension_id}
      hover
      tabIndex={-1}
    >
      <TableCell className={getClassByState(brandState)} onClick={displayBrandContextMenu}>
        <Typography>
          {value.brand_state >= 1 ? <VisibilityOff className={classes.visibilityOffIcon}/> : ''}
          {value.brand_name}
        </Typography>
      </TableCell>
      <TableCell className={getClassByState(rangeState)} onClick={displayRangeContextMenu}>
        <Typography>
          {value.range_state >= 1 ? <VisibilityOff className={classes.visibilityOffIcon}/> : ''}
          {value.range_name}
        </Typography>
      </TableCell>
      <TableCell className={getClassByState(modelState)} onClick={displayModelContextMenu}>
        <Typography>
          {value.model_state >= 1 ? <VisibilityOff className={classes.visibilityOffIcon}/> : ''}
          {value.model_name}
        </Typography>
      </TableCell>
      <TableCell className={getClassByState(engineState)} onClick={displayEngineContextMenu}>
        <Typography>
          {value.engine_state >= 1 ? <VisibilityOff className={classes.visibilityOffIcon}/> : ''}
          {value.engine_name}
        </Typography>
      </TableCell>
      <TableCell className={getClassByState(dimensionState)} onClick={displayDimensionContextMenu}>
        <Typography>
          {value.dimension_state >= 1 ? <VisibilityOff className={classes.visibilityOffIcon}/> : ''}
          {value.dimension_is_other && t('other')}
          {
            !value.dimension_is_other &&
            value.dimension_front_dimension + ' / ' + value.dimension_rear_dimension
          }
        </Typography>
      </TableCell>
      <TableCell>
        <Typography>
          {t(value.dimension_compatible_with_receiver ? 'mode_amateur' : 'mode_free')}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography>
          {t(value.dimension_is_public ? 'public' : 'tester')}
        </Typography>
      </TableCell>

      <TableCell align={'right'}>
        <Tooltip title={t('edit')}>
          <IconButton
            id='edit'
            aria-label="update"
            onClick={handleEdit}
            className={classes.button}
          >
            <EditIcon/>
          </IconButton>
        </Tooltip>
        {!value.dimension_is_other && <Tooltip title={t('duplicate')}>
          <IconButton
            id='duplicate'
            aria-label='update'
            onClick={handleDuplicate}
            className={classes.button}
          >
            <AddToPhotosIcon/>
          </IconButton>
        </Tooltip>}
      </TableCell>
    </TableRow>
  );
}

RaceCarRow.propTypes = {
  value: PropTypes.object.isRequired,
};

export default React.memo(RaceCarRow);
