import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import useTheme from '@material-ui/core/styles/useTheme';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import Visibility from '@material-ui/icons/Visibility';
import AddToPhotos from '@material-ui/icons/AddToPhotos';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import Typography from '@material-ui/core/Typography';
import { TRACK, TRACK_ADD, TRACK_EDIT } from '../../constants/routes';
import { putTrack } from '../../store/tracks/actions';
import i18next from '../../i18n';
import { useStyles } from './TrackRow.style';
import RedChip from '../Layout/RedChip/RedChip';
import { ROLE_ADMIN } from '../../constants/roles';

const TrackRow = ({ value }) => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const lang = i18next.languages[0].split('-')[0];
  const role = localStorage.getItem('role');

  const handleClick = event => {
    event.stopPropagation();
    history.push(TRACK.replace(':trackId', value.id));
  };

  const handleEdit = event => {
    event.stopPropagation();
    history.push(TRACK_EDIT.replace(':trackId', value.id));
  };

  const handleDuplicate = event => {
    event.stopPropagation();
    history.push({
      pathname: TRACK_ADD,
      state: {
        value,
      },
    });
  };

  const handleSwitchActivate = event => {
    event.preventDefault();
    event.stopPropagation();
    dispatch(putTrack({
      ...value,
      state: (
        value.state === 1
          ? 0
          : 1
      ),
    }));
  };

  return (
    <TableRow
      className={value.state === 1
        ? classes.row_disabled
        : classes.row}
      key={value.id}
      hover
      onClick={handleClick}
      tabIndex={-1}
    >
      <TableCell>
        <Typography>
          {value.translations[lang] !== undefined
            ? value.translations[lang].name
            : ''}
          {role === ROLE_ADMIN && value.comment_report_count > 0 && (
            <>{' '}<RedChip count={value.comment_report_count} /></>
          )}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography>
          {value.country_code}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography>
          {t(value.is_public
            ? 'public'
            : 'tester')}
        </Typography>
      </TableCell>
      <TableCell align={'right'}>
        {role === ROLE_ADMIN && <>
          <Tooltip title={t('edit')}>
            <IconButton
              id="edit"
              aria-label="update"
              onClick={handleEdit}
              className={classes.button}
            >
              <EditIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title={t('duplicate')}>
            <IconButton
              id="duplicate"
              aria-label="duplicate"
              onClick={handleDuplicate}
              className={classes.button}
            >
              <AddToPhotos />
            </IconButton>
          </Tooltip>
          <Tooltip title={value.state === 0
            ? t('deactivate')
            : t('activate')}>
            <IconButton
              id="switch"
              aria-label="update"
              onClick={handleSwitchActivate}
              className={classes.button}
            >
              {value.state === 0
                ? <VisibilityOff />
                : <Visibility />}
            </IconButton>
          </Tooltip>
        </>}
      </TableCell>
    </TableRow>
  );
};

TrackRow.propTypes = {
  value: PropTypes.object.isRequired,
};

export default React.memo(TrackRow);
