export const BASE_URL = '/tr4';
/* apidoc */
export const API_DOC = BASE_URL + '/apidoc/:version';
/* auth callback */
export const CALLBACK = BASE_URL + '/callback';
/* special store page */
export const STORE = BASE_URL + '/store';
/* home */
export const HOMEPAGE = BASE_URL + '/home';
/* track */
export const TRACKS = BASE_URL + '/tracks';
export const TRACK = TRACKS + '/:trackId';
export const TRACK_ADD = TRACKS + '/add';
export const TRACK_EDIT = TRACK + '/edit';
/* cars */
export const CARS = BASE_URL + '/cars';
export const CAR_ADD = CARS + '/add';
export const CAR_EDIT = CARS + '/edit/:dimensionId';
export const CAR_DUPLICATE = CARS + '/duplicate/:dimensionId';
/* rally-cars */
export const RALLY_CARS = BASE_URL + '/rally-cars';
export const RALLY_CAR_ADD = RALLY_CARS + '/add';
export const RALLY_CAR_EDIT = RALLY_CARS + '/edit/:rallyDimensionId';
export const RALLY_CAR_DUPLICATE = RALLY_CARS + '/duplicate/:rallyDimensionId';
/* receivers */
export const RECEIVER = BASE_URL + '/receivers';
export const RECEIVER_MTC_BLE = RECEIVER + '/mtc/ble';
export const RECEIVER_MTC_RF = RECEIVER + '/mtc/rf';
export const RECEIVER_MMC_BLE = RECEIVER + '/mmc/ble';
export const RECEIVER_MMC_RF = RECEIVER + '/mmc/rf';
export const FIRMWARE_EXCLUDED_VERSIONS_ADD = RECEIVER + '/firmware-excluded-versions/add'
/* firmware groups */
export const FIRMWARE_GROUPS = RECEIVER + '/firmware-groups';
export const FIRMWARE_GROUP_ADD = FIRMWARE_GROUPS + '/add';
export const FIRMWARE_GROUP = FIRMWARE_GROUPS + '/:groupId';
export const FIRMWARE_GROUP_EDIT = FIRMWARE_GROUP + '/edit';
export const FIRMWARE_GROUP_ITEM_ADD = FIRMWARE_GROUP + '/items/add';
export const FIRMWARE_GROUP_FIRMWARE_ADD = FIRMWARE_GROUP + '/firmwares/add';
/* requests */
export const REQUESTS = BASE_URL + '/requests';
// export const REQUEST_EDIT = BASE_URL + '/requests/:requestId/edit'
export const MESSAGE_REQUEST = REQUESTS + '-message';
export const CIRCUIT_REQUEST = REQUESTS + '-circuit';
export const CAR_REQUEST = REQUESTS + '-car';
export const RALLY_CAR_REQUEST = REQUESTS + '-rally';
export const MESSAGE_REQUEST_EDIT = MESSAGE_REQUEST + '/:requestId/edit';
export const CIRCUIT_REQUEST_EDIT = CIRCUIT_REQUEST + '/:requestId/edit';
export const CAR_REQUEST_EDIT = CAR_REQUEST + '/:requestId/edit';
export const RALLY_CAR_REQUEST_EDIT = RALLY_CAR_REQUEST + '/:requestId/edit';
/* contacts */
export const CONTACTS = BASE_URL + '/contacts';
export const CONTACT_ADD = CONTACTS + '/:type/add';
export const CONTACT_EDIT = CONTACTS + '/:type/:contactId';
/* users */
export const ADMINS = BASE_URL + '/admins';
export const TESTER = BASE_URL + '/tester';
/* tires */
export const TIRES = BASE_URL + '/tires';
export const TIRE_ADD = TIRES + '/add';
export const TIRE_EDIT = TIRES + '/edit';
/* call-centers */
export const CALL_CENTERS = BASE_URL + '/call-centers';
export const CALL_CENTERS_ADD = CALL_CENTERS + '/add';
export const CALL_CENTERS_EDIT = CALL_CENTERS + '/:callCenterId/edit';
export const LANG_CALL_CENTERS = BASE_URL + '/lang-call-centers';
export const LANG_CALL_CENTERS_ADD = LANG_CALL_CENTERS + '/add';
export const LANG_CALL_CENTERS_EDIT = LANG_CALL_CENTERS + '/:callCenterLang/edit';
/* challenges */
export const CHALLENGES = BASE_URL + "/challenges"
export const CHALLENGE_ADD = CHALLENGES + "/add"
export const CHALLENGE = CHALLENGES + "/:challengeId"
export const CHALLENGE_EDIT = CHALLENGES + "/:challengeId/edit"
/* catalogs */
export const CATALOGS = BASE_URL + "/catalogs"
export const CATALOGS_TAGS = CATALOGS + "/tags"
export const CATALOGS_TAGS_ADD = CATALOGS_TAGS + "/add"
export const CATALOGS_TAGS_EDIT = CATALOGS_TAGS + "/:tagId/edit"
export const CATALOGS_ITEMS_ADD = CATALOGS + "/add"
export const CATALOGS_ITEMS_EDIT = CATALOGS + "/:itemId/edit"
/* in-app notification */
export const IN_APP_NOTIFICATIONS = BASE_URL + "/in-app-notifications"
export const IN_APP_NOTIFICATIONS_ADD = IN_APP_NOTIFICATIONS + "/add"
export const IN_APP_NOTIFICATION = IN_APP_NOTIFICATIONS + "/:notificationId"
export const IN_APP_NOTIFICATIONS_EDIT = IN_APP_NOTIFICATION + "/edit"
/* FAQ articles */
export const FAQ = BASE_URL + "/faq"
export const FAQ_ARTICLES = FAQ + "/articles"
export const FAQ_ARTICLES_ADD = FAQ + "/articles/add"
export const FAQ_ARTICLES_EDIT = FAQ + "/articles/:articleId"
export const FAQ_ELEMENTS = FAQ + "/elements"
/* Support */
export const SUPPORT = BASE_URL + "/supports";
export const AUTHORIZED_EXPORT_ADD = SUPPORT + "/authorized-exports/add";
export const AUTHORIZED_EXPORT_EDIT = SUPPORT + "/authorized-exports/:authorizedExportId/edit";

export const USER_RETROSPECTIVE_BASE = BASE_URL + '/retrospective';
export const USER_RETROSPECTIVE = BASE_URL + '/retrospective/:referralCode';

export const ALL_MENU_BASE_ROUTES = [
  HOMEPAGE,
  TRACKS,
  CARS,
  RALLY_CARS,
  RECEIVER,
  REQUESTS,
  MESSAGE_REQUEST,
  CIRCUIT_REQUEST,
  CAR_REQUEST,
  RALLY_CAR_REQUEST,
  CONTACTS,
  ADMINS,
  TESTER,
  TIRES,
  CALL_CENTERS,
  LANG_CALL_CENTERS,
  CHALLENGES,
  CATALOGS,
  IN_APP_NOTIFICATIONS,
  FAQ,
  SUPPORT
]
