import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import AdminRow from './AdminRow';
import { getUsers } from '../../store/users/actions';
import MTCTable from '../Layout/Table/MTCTable';
import MTCLoader from '../Layout/MTCLoader';
import { columns, defaultOrderBy } from './AdminsTable.const';

export const AdminsTable = () => {

  const {
    pending,
    content,
    error,
  } = useSelector(state => state.users);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  if (!content && !pending && !error) {
    dispatch(getUsers());
  }

  if (pending) {
    return <MTCLoader />;
  } else {
    return <MTCTable
      content={content}
      defaultOrderBy={defaultOrderBy}
      rowComponent={AdminRow}
      columns={columns}
      paginationLabel={t('admins_per_page')}
      emptyDataLabel={t('no_admin')}
    />;
  }
};

export default AdminsTable;
