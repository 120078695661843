import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Grid from '@material-ui/core/Grid';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import AppBar from '@material-ui/core/AppBar';
import MTCLoader from '../../Layout/MTCLoader';
import { getTrackComments } from '../../../store/trackComments/actions';
import TrackCommentsList from '../TrackCommentsList/TrackCommentsList';
import { RedChip } from '../../Layout/RedChip/RedChip';

const TrackCommentsTab = ({ id }) => {

  const dispatch = useDispatch();
  const { t } = useTranslation();
  const {
    pending,
    content,
    circuitId,
    error,
  } = useSelector(state => state.trackComments);
  const [value, setValue] = useState(0);

  const handleChange = (event, value) => {
    setValue(value);
  };

  if (circuitId != id && !pending && !error) {
    dispatch(getTrackComments(id));
  }


  if (pending || circuitId != id) {
    return (
      <MTCLoader />
    );
  } else {
    const validContent =  content.filter(comment => comment.state == 0);
    const reportedContent = content.filter(comment => comment.state == 1);
    const deletedContent = content.filter(comment => comment.state == 2);
    return (
      <>
        <AppBar position={'static'}>
          <Tabs
            value={value}
            onChange={handleChange}
            variant={'scrollable'}
            scrollButtons={'on'}
          >
            <Tab label={t('track_page.tabs.valid_comments_title')} />
            <Tab label={
              <Grid container spacing={2} alignItems={'center'}>
                <Grid item>
                  <span>{t('track_page.tabs.reported_comments_title')}</span>
                </Grid>
                {reportedContent.length > 0 && <Grid item>
                  <RedChip count={reportedContent.length} />
                </Grid>}
              </Grid>
            } />
            <Tab label={t('track_page.tabs.deleted_comments_title')} />
          </Tabs>
        </AppBar>
        {value === 0 && <TrackCommentsList comments={validContent} />}
        {value === 1 && <TrackCommentsList comments={reportedContent} />}
        {value === 2 && <TrackCommentsList comments={deletedContent} />}
      </>
    );
  }
};

export default React.memo(TrackCommentsTab);
