export const USER_TYPE_ALL = 'ALL';
export const USER_TYPE_ONLY_FREE = 'ONLY_FREE';
export const USER_TYPE_ONLY_EXPERT = 'ONLY_EXPERT';
export const USER_TYPE_ONLY_RALLY = 'ONLY_RALLY';
export const USER_TYPE_ONLY_RACE = 'ONLY_RACE';
export const USER_TYPE_AT_LEAST_ONE_FREE = 'AT_LEAST_ONE_FREE';
export const USER_TYPE_AT_LEAST_ONE_EXPERT = 'AT_LEAST_ONE_EXPERT';
export const USER_TYPE_AT_LEAST_ONE_RALLY = 'AT_LEAST_ONE_RALLY';
export const USER_TYPE_AT_LEAST_ONE_RACE = 'AT_LEAST_ONE_RACE';
export const USER_TYPES = [
  USER_TYPE_ALL,
  USER_TYPE_ONLY_FREE,
  USER_TYPE_ONLY_EXPERT,
  USER_TYPE_ONLY_RALLY,
  USER_TYPE_ONLY_RACE,
  USER_TYPE_AT_LEAST_ONE_FREE,
  USER_TYPE_AT_LEAST_ONE_EXPERT,
  USER_TYPE_AT_LEAST_ONE_RALLY,
  USER_TYPE_AT_LEAST_ONE_RACE
]
export const EXPERT_COMPATIBLE_VEHICLE = [
  USER_TYPE_ALL,
  USER_TYPE_ONLY_FREE,
  USER_TYPE_ONLY_RACE,
  USER_TYPE_AT_LEAST_ONE_FREE,
  USER_TYPE_AT_LEAST_ONE_RACE
]
