export const inputStyle = (theme) => {
  return {
    width: 400,
    maxWidth: '100%',
    [theme.breakpoints.up('md')]: {
      width: 500,
    },
    [theme.breakpoints.up('xl')]: {
      width: 600,
    },
  };
}

export const textFieldClass = (theme) => {
  return {
    textField: {
      ...inputStyle(theme),
    }
  };
}
