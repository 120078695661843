import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setBack, setTitle } from '../../../store/title/actions';
import { useHistory, useParams } from 'react-router';
import { RECEIVER } from '../../../constants/routes';
import { getFirmwareGroups } from '../../../store/firmwareGroups/actions';
import FirmwareGroupMenuButton
  from '../../../components/Receivers/FirmwareGroups/FirmwareGroupMenuButton';
import withAuthentication from '../../../hoc/withAuthentication';
import { ROLE_ADMIN } from '../../../constants/roles';
import FirmwareGroupItemsTable
  from '../../../components/Receivers/FirmwareGroups/Items/FirmwareGroupItemsTable';
import { Typography } from '@material-ui/core';
import FirmwareGroupFirmwareTable
  from '../../../components/Receivers/FirmwareGroups/Firmwares/FirmwareGroupFirmwareTable';

export const AddFirmwareGroupPage = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { title } = useSelector(state => state.title);
  const {
    content,
    pending,
    error,
  } = useSelector(state => state.firmwareGroups);
  const { groupId } = useParams();
  const [group, setGroup] = useState(null);

  if (!content && !pending && !error) {
    dispatch(getFirmwareGroups());
  }

  if (content && group === null && !pending && !error) {
    let index = -1;
    if (content.length > 0) {
      index = content.findIndex(fg => fg.id == groupId);
    }
    if (index === -1) {
      history.push(RECEIVER);
    } else {
      setGroup(content[index]);
    }
  }

  if (group && title !== group.name) {
    dispatch(setTitle(group.name));
    dispatch(setBack(RECEIVER));
  }

  if (group !== null) {
    return <>
      <Typography variant="h5">
        Firmware
      </Typography>
      <FirmwareGroupFirmwareTable content={group.firmwares} pending={pending} />
      <Typography variant="h5">
        Receivers
      </Typography>
      <FirmwareGroupItemsTable content={group.items} pending={pending} />
      <FirmwareGroupMenuButton id={groupId} />
    </>
  } else {
    return <div></div>;
  }
}

export default withAuthentication(ROLE_ADMIN)(AddFirmwareGroupPage);
