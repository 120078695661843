import makeStyles from '@material-ui/core/styles/makeStyles';
import { inputStyle } from '../../../../constants/styles/input.style';

export const useStyles = makeStyles((theme) => (
  {
    paper: {
      padding: '10px',
      margin: '10px',
      width: 'calc(100% - 30px)',
    },
    input: {
      ...inputStyle(theme),
      color: theme.palette.textColor,
      backgroundColor: theme.palette.alternateTextColor,
    },
    grid: {
      marginBottom: '2em',
    },
    media: {
      ...inputStyle(theme),
      border: '1px dashed black',
      padding: '10px',
      marginTop: '3px',
      borderRadius: '10px',
    },
    dropzoneLabel: {
      position: 'relative',
      textAlign: 'left',
      transform: 'none',
    },
    cardMedia: {
      width: '100%',
    },
  }
));
