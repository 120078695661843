import React from 'react';
import { useHistory, useParams, useRouteMatch } from 'react-router';
import TrackForm from '../../components/Tracks/TrackForm/TrackForm';
import withAuthentication from '../../hoc/withAuthentication';
import { ROLE_ADMIN } from '../../constants/roles';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { setTitle } from '../../store/title/actions';

export const EditTrackPage = () => {

  const { t } = useTranslation();
  const { title } = useSelector(state => state.title);
  const dispatch = useDispatch();
  let history = useHistory();
  let { trackId } = useParams();
  let { path } = useRouteMatch();

  if (title !== t('title:title.edit_track_page')) {
    dispatch(setTitle(t('title:title.edit_track_page')));
  }

  return (
    <TrackForm history={history} trackId={trackId} path={path}/>
  );
}

export default withAuthentication(ROLE_ADMIN)(EditTrackPage);
