import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import Chip from '@material-ui/core/Chip';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import useTheme from '@material-ui/core/styles/useTheme';
import LinkOffIcon from '@material-ui/icons/LinkOff';
import { useStyles } from './FaqArticlesRow.style';
import i18next from '../../../../i18n';
import MTCDialog from '../../../Form/MTCDialog';
import { deleteFaqArticle } from '../../../../store/faqArticles/actions';
import { FAQ_ARTICLES_EDIT } from '../../../../constants/routes';

const MAX_DISPLAYED_CHIPS = 4;

const FaqArticlesRow = ({ value }) => {
  const theme = useTheme();
  const history = useHistory();
  const classes = useStyles(theme);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { sending } = useSelector(state => state.faqArticles);
  const [open, setOpen] = React.useState(false);
  const lang = i18next.languages[0].split('-')[0];

  const handleEdit = event => {
    event.stopPropagation();
    history.push(FAQ_ARTICLES_EDIT.replace(':articleId', value.id));
  };

  const handleDialogRemove = event => {
    event.stopPropagation();
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDelete = article => {
    dispatch(deleteFaqArticle(article));
  };

  let title = value.translations[lang]
    ? value.translations[lang].title
    : '';

  let tags = value.translations[lang]
    ? value.translations[lang].tags
    : '';
  const tagsList = tags ? tags.split(',') : [];
  return (
    <>
      <TableRow
        className={value.faq_element_count > 0 ? classes.row : classes.unusedRow}
        key={value.id}
        tabIndex={-1}
      >
        <TableCell>
          <Typography className={classes.multiComponentCell}>
            {value.faq_element_count <= 0
            && <Tooltip title={t('faq_article_unused')}>
              <LinkOffIcon  className={classes.linkIcon}/>
            </Tooltip>}
            {value.faq_element_count > 0 && <div className={classes.linkIcon} />}
            {title}
          </Typography>
        </TableCell>
        <TableCell>
          {
            tags
            && tagsList.length <= MAX_DISPLAYED_CHIPS
            && tagsList.map(str => <Chip key={str} label={str} className={classes.chip}/>)
          }
          {
            tags
            && tagsList.length > MAX_DISPLAYED_CHIPS
            && <>
              {tagsList.slice(0, MAX_DISPLAYED_CHIPS).map(str =>
                <Chip key={str} label={str} className={classes.chip}/>
              )}
              <Chip
                key={'+' + (tagsList.length - MAX_DISPLAYED_CHIPS)}
                label={'+' + (tagsList.length - MAX_DISPLAYED_CHIPS)}
                className={classes.chip}
              />
            </>
          }
        </TableCell>
        <TableCell align={'right'}>
          <IconButton
            id={`edit-${value.id}`}
            aria-label="update"
            onClick={handleEdit}
            className={classes.button}
          >
            <EditIcon />
          </IconButton>
          <IconButton
            id={`remove-${value.id}`}
            aria-label="delete"
            onClick={handleDialogRemove}
            className={classes.button}
          >
            <DeleteIcon />
          </IconButton>
        </TableCell>
      </TableRow>

      <MTCDialog
        open={open}
        handleClose={handleClose}
        dialogTitle={t('remove_faq_article_title')}
        dialogText={t('remove_faq_article_text')}
        handleCloseCancel={handleClose}
        handleCloseOK={() => handleDelete(value)}
        cancelText={t('translations:cancel')}
        okText={t('translations:validate')}
        okPending={sending}
      />
    </>
  );
};
export default FaqArticlesRow;
