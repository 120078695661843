import React from 'react';
import 'core-js';
import 'regenerator-runtime/runtime';
import PropTypes, { instanceOf } from 'prop-types';
import { Cookies } from 'react-cookie';
import { useHistory } from 'react-router';
import { Route, Switch, withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { useDispatch, useSelector } from 'react-redux';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import './App.css';
import * as routes from './constants/routes';
import { HOMEPAGE, TRACKS, USER_RETROSPECTIVE, USER_RETROSPECTIVE_BASE } from './constants/routes';
import Auth from './services/Auth/Auth';
import Homepage from './containers/Homepage/Homepage';
import TracksPage from './containers/Tracks/TracksPage';
import TrackPage from './containers/Tracks/TrackPage';
import AddTrackPage from './containers/Tracks/AddTrackPage';
import EditTrackPage from './containers/Tracks/EditTrackPage';
import CarsPage from './containers/Cars/CarsPage';
import AddCarPage from './containers/Cars/Track/AddCarPage';
import EditCarPage from './containers/Cars/Track/EditCarPage';
import DuplicateCarPage from './containers/Cars/Track/DuplicateCarPage';
import AddRallyCarPage from './containers/Cars/Rally/AddRallyCarPage';
import EditRallyCarPage from './containers/Cars/Rally/EditRallyCarPage';
import DuplicateRallyCarPage from './containers/Cars/Rally/DuplicateRallyCarPage';
import ReceiverPage from './containers/Receivers/ReceiverPage';
import ReceiverMTCBLEPage from './containers/Receivers/ReceiverMTCBLEPage';
import ReceiverMTCRFPage from './containers/Receivers/ReceiverMTCRFPage';
import ReceiverMMCBLEPage from './containers/Receivers/ReceiverMMCBLEPage';
import ReceiverMMCRFPage from './containers/Receivers/ReceiverMMCRFPage';
import RequestsPage from './containers/Requests/RequestsPage';
import AdminsPage from './containers/Admins/AdminsPage';
import TestersPage from './containers/Testers/TestersPage';
import TiresPage from './containers/Tires/TiresPage';
import StorePage from './containers/Store/StorePage';
import AddTirePage from './containers/Tires/AddTirePage';
import EditTirePage from './containers/Tires/EditTirePage';
import CallCentersPage from './containers/CallCenters/CallCentersPage';
import AddCallCentersPage from './containers/CallCenters/AddCallCentersPage';
import AddLangCallCentersPage from './containers/CallCenters/AddLangCallCentersPage';
import EditCallCentersPage from './containers/CallCenters/EditCallCentersPage';
import ContactsPage from './containers/Contacts/ContactsPage';
import AddContactPage from './containers/Contacts/AddContactPage';
import EditContactPage from './containers/Contacts/EditContactPage';
import Error404 from './containers/Error404';
import MTCContainer from './components/Layout/Menu/MTCContainer';
import EditLangCallCentersPage from './containers/CallCenters/EditLangCallCentersPage';
import ApiDocPage from './containers/ApiDocPage';
import ChallengesPage from './containers/Challenges/ChallengesPage';
import ChallengePage from './containers/Challenges/ChallengePage';
import ChallengeAddPage from './containers/Challenges/ChallengeAddPage';
import ChallengeEditPage from './containers/Challenges/ChallengeEditPage';
import CatalogsPage from './containers/Catalogs/CatalogsPage';
import CatalogsAddPage from './containers/Catalogs/CatalogsAddPage';
import CatalogsTagsPage from './containers/Catalogs/CatalogsTagsPage';
import CatalogsTagsAddPage from './containers/Catalogs/CatalogsTagsAddPage';
import CatalogsTagsEditPage from './containers/Catalogs/CatalogsTagsEditPage';
import CatalogsEditPage from './containers/Catalogs/CatalogsEditPage';
import EditCircuitRequestPage from './containers/Requests/EditCircuitRequestPage';
import EditCarRequestPage from './containers/Requests/EditCarRequestPage';
import EditRallyCarRequestPage from './containers/Requests/EditRallyCarRequestPage';
import EditMessageRequestPage from './containers/Requests/EditMessageRequestPage';
import AddFirmwareGroupPage from './containers/Receivers/FirmwareGroups/AddFirmwareGroupPage';
import EditFirmwareGroupPage from './containers/Receivers/FirmwareGroups/EditFirmwareGroupPage';
import FirmwareGroupPage from './containers/Receivers/FirmwareGroups/FirmwareGroupPage';
import AddFirmwareGroupItemPage
  from './containers/Receivers/FirmwareGroups/Items/AddFirmwareGroupItemPage';
import AddFirmwareGroupFirmwarePage
  from './containers/Receivers/FirmwareGroups/Firmwares/AddFirmwareGroupFirmwarePage';
import AddExcludedVersionPage from './containers/Receivers/ExcludedVersions/AddExcludedVersionPage';
import InAppNotificationsPage from './containers/InAppNotifications/InAppNotificationsPage';
import InAppNotificationPage from './containers/InAppNotifications/InAppNotificationPage';
import AddInAppNotificationPage from './containers/InAppNotifications/AddInAppNotificationPage';
import EditInAppNotificationPage from './containers/InAppNotifications/EditInAppNotificationPage';
import { getHome } from './store/home/actions';
import FaqArticlePage from './containers/Faq/Articles/FaqArticlePage';
import FaqArticleAddPage from './containers/Faq/Articles/FaqArticleAddPage';
import FaqArticleEditPage from './containers/Faq/Articles/FaqArticleEditPage';
import FaqElementPage from './containers/Faq/Elements/FaqElementPage';
import DashboardPage from './containers/Homepage/DashboardPage';
import SupportPage from './containers/Support/SupportPage';
import { ROLE_ADMIN } from './constants/roles';
import AuthorizedExportAddPage from './containers/Support/AuthorizedExportAddPage';
import AuthorizedExportEditPage from './containers/Support/AuthorizedExportEditPage';
import CircuitEolPage from './containers/CircuitEol/CircuitEolPage';

const auth = new Auth();

export function App() {
  const history = useHistory();
  const home = useSelector(state => state.home);
  const dispatch = useDispatch();

  const handleAuthentication = async (location) => {
    if (/access_token|id_token|error/.test(location.location.hash)) {
      await auth.handleAuthentication();
      const role = localStorage.getItem('role');
      if (role === ROLE_ADMIN) {
        history.replace(HOMEPAGE);
      } else {
        // support
        history.replace(TRACKS);
      }
    } else {
      history.replace(HOMEPAGE);
    }
  };

  if (
    !history.location.pathname.startsWith(routes.STORE)
    && !history.location.pathname.startsWith('/tr4/apidoc')
    && !history.location.pathname.startsWith(routes.USER_RETROSPECTIVE_BASE)
    && !history.location.pathname.startsWith(routes.CALLBACK)
    && Auth.isAuthenticated()
    && (
      Date.now() - home.lastUpdate
    ) > 300000
    && !home.pending && !home.error
  ) {
    dispatch(getHome());
  }
  return (
    <MTCContainer>
      <Switch>
        <Route exact path={routes.STORE} component={StorePage} />
        <Route exact path={routes.USER_RETROSPECTIVE} component={CircuitEolPage} />
        <Route exact path={routes.API_DOC} component={ApiDocPage} />
        {!Auth.isAuthenticated() && (
          <Route
            exact
            path={routes.HOMEPAGE}
            render={(props) =>
              <Homepage auth={auth} {...props} />
            }
          />
        )}
        {Auth.isAuthenticated()
          && (<Route exact path={routes.HOMEPAGE} component={DashboardPage} />)
        }
        <Route exact path={routes.TRACKS} component={TracksPage} />
        <Route exact path={routes.TRACK_ADD} component={AddTrackPage} />
        <Route exact path={routes.TRACK} component={TrackPage} />
        <Route exact path={routes.TRACK_EDIT} component={EditTrackPage} />
        <Route exact path={routes.CARS} component={CarsPage} />
        <Route exact path={routes.CAR_ADD} component={AddCarPage} />
        <Route exact path={routes.CAR_EDIT} component={EditCarPage} />
        <Route exact path={routes.CAR_DUPLICATE} component={DuplicateCarPage} />
        <Route exact path={routes.RALLY_CAR_ADD} component={AddRallyCarPage} />
        <Route exact path={routes.RALLY_CAR_EDIT} component={EditRallyCarPage} />
        <Route exact path={routes.RALLY_CAR_DUPLICATE} component={DuplicateRallyCarPage} />
        <Route exact path={routes.CIRCUIT_REQUEST_EDIT} component={EditCircuitRequestPage} />
        <Route exact path={routes.CAR_REQUEST_EDIT} component={EditCarRequestPage} />
        <Route exact path={routes.RALLY_CAR_REQUEST_EDIT} component={EditRallyCarRequestPage} />
        <Route exact path={routes.MESSAGE_REQUEST_EDIT} component={EditMessageRequestPage} />
        <Route exact path={routes.REQUESTS} component={RequestsPage} />
        <Route exact path={routes.CONTACTS} component={ContactsPage} />
        <Route exact path={routes.CONTACT_ADD} component={AddContactPage} />
        <Route exact path={routes.CONTACT_EDIT} component={EditContactPage} />
        <Route exact path={routes.RECEIVER} component={ReceiverPage} />
        <Route exact path={routes.RECEIVER_MTC_BLE} component={ReceiverMTCBLEPage} />
        <Route exact path={routes.RECEIVER_MTC_RF} component={ReceiverMTCRFPage} />
        <Route exact path={routes.RECEIVER_MMC_BLE} component={ReceiverMMCBLEPage} />
        <Route exact path={routes.RECEIVER_MMC_RF} component={ReceiverMMCRFPage} />
        <Route
          exact
          path={routes.FIRMWARE_EXCLUDED_VERSIONS_ADD}
          component={AddExcludedVersionPage}
        />
        <Route exact path={routes.FIRMWARE_GROUP_ITEM_ADD} component={AddFirmwareGroupItemPage} />
        <Route
          exact
          path={routes.FIRMWARE_GROUP_FIRMWARE_ADD}
          component={AddFirmwareGroupFirmwarePage}
        />
        <Route exact path={routes.FIRMWARE_GROUP_ADD} component={AddFirmwareGroupPage} />
        <Route exact path={routes.FIRMWARE_GROUP} component={FirmwareGroupPage} />
        <Route exact path={routes.FIRMWARE_GROUP_EDIT} component={EditFirmwareGroupPage} />
        <Route exact path={routes.ADMINS} component={AdminsPage} />
        <Route exact path={routes.TESTER} component={TestersPage} />
        <Route exact path={routes.TIRES} component={TiresPage} />
        <Route exact path={routes.TIRE_ADD} component={AddTirePage} />
        <Route exact path={routes.TIRE_EDIT} component={EditTirePage} />
        <Route exact path={routes.CALL_CENTERS_ADD} component={AddCallCentersPage} />
        <Route exact path={routes.LANG_CALL_CENTERS_ADD} component={AddLangCallCentersPage} />
        <Route exact path={routes.CALL_CENTERS_EDIT} component={EditCallCentersPage} />
        <Route exact path={routes.LANG_CALL_CENTERS_EDIT} component={EditLangCallCentersPage} />
        <Route exact path={routes.CALL_CENTERS} component={CallCentersPage} />
        <Route exact path={routes.CHALLENGES} component={ChallengesPage} />
        <Route exact path={routes.CHALLENGE_ADD} component={ChallengeAddPage} />
        <Route exact path={routes.CHALLENGE} component={ChallengePage} />
        <Route exact path={routes.CHALLENGE_EDIT} component={ChallengeEditPage} />
        <Route exact path={routes.CATALOGS} component={CatalogsPage} />
        <Route exact path={routes.CATALOGS_ITEMS_ADD} component={CatalogsAddPage} />
        <Route exact path={routes.CATALOGS_ITEMS_EDIT} component={CatalogsEditPage} />
        <Route exact path={routes.CATALOGS_TAGS} component={CatalogsTagsPage} />
        <Route exact path={routes.CATALOGS_TAGS_ADD} component={CatalogsTagsAddPage} />
        <Route exact path={routes.CATALOGS_TAGS_EDIT} component={CatalogsTagsEditPage} />
        <Route exact path={routes.IN_APP_NOTIFICATIONS} component={InAppNotificationsPage} />
        <Route exact path={routes.IN_APP_NOTIFICATIONS_ADD} component={AddInAppNotificationPage} />
        <Route exact path={routes.IN_APP_NOTIFICATION} component={InAppNotificationPage} />
        <Route
          exact
          path={routes.IN_APP_NOTIFICATIONS_EDIT}
          component={EditInAppNotificationPage}
        />
        <Route exact path={routes.FAQ_ARTICLES} component={FaqArticlePage} />
        <Route exact path={routes.FAQ_ARTICLES_ADD} component={FaqArticleAddPage} />
        <Route exact path={routes.FAQ_ARTICLES_EDIT} component={FaqArticleEditPage} />
        <Route exact path={routes.FAQ_ELEMENTS} component={FaqElementPage} />
        <Route exact path={routes.SUPPORT} component={SupportPage} />
        <Route exact path={routes.AUTHORIZED_EXPORT_ADD} component={AuthorizedExportAddPage} />
        <Route exact path={routes.AUTHORIZED_EXPORT_EDIT} component={AuthorizedExportEditPage} />
        <Route
          path={routes.CALLBACK}
          render={(props) => {
            handleAuthentication(props);
            return (
              <></>
            );
          }}
        />
        <Route exact path={'/'} render={() => {
          history.replace(routes.HOMEPAGE);
        }} />
        <Route exact path={routes.BASE_URL} render={() => {
          history.replace(routes.HOMEPAGE);
        }} />
        <Route path={'/'} render={() => <Error404 withHomeButton={true} />} />
      </Switch>
    </MTCContainer>
  );
}

App.propTypes = {
  cookies: instanceOf(Cookies),
  children: PropTypes.element,
  width: PropTypes.number,
  location: PropTypes.object.isRequired,
};

export default compose(
  withRouter,
)(App);
