import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Grid from '@material-ui/core/Grid';
import { useHistory } from 'react-router';
import MTCTextField from '../../../Form/MTCTextField';
import MTCButton from '../../../Layout/Button/MTCButton';
import {
  getFirmwareGroups,
  postFirmwareGroupItems,
} from '../../../../store/firmwareGroups/actions';
import { FIRMWARE_GROUP } from '../../../../constants/routes';
import { useStyles } from './FirmwareGroupItemForm.style';

export const FirmwareGroupItemForm = (props) => {
  const { id } = props;
  const {
    content,
    sending,
    pending,
    error,
  } = useSelector(state => state.firmwareGroups);
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const classes = useStyles();
  const [name, setName] = useState('');
  const [nameError, setNameError] = useState(null);

  if (!content && !pending && !error) {
    dispatch(getFirmwareGroups());
  }

  const handleChangeName = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setNameError(null);
    setName(event.target.value)
  }

  const formSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();
    if (name !== '') {
      dispatch(postFirmwareGroupItems(id, name))
        .then(data => {
          if (data === 409) {
            setNameError(t('firmware_group_item_conflict_error'));
          } else if (data !== null) {
            history.push(FIRMWARE_GROUP.replace(':groupId', id));
          }
        });
    }
  };

  return (
    <form onSubmit={formSubmit}>
      <Grid
        container
        alignItems={'center'}
        direction={'column'}
        justifyContent={'center'}
        className={classes.grid}
      >
        <MTCTextField
          gridMD={12}
          id="name"
          onChange={handleChangeName}
          value={name}
          required={true}
          errorMessage={nameError}
        />
        <MTCButton
          type={'submit'}
          sending={sending}
          text={t('create')}
        />
      </Grid>
    </form>
  );
};

FirmwareGroupItemForm.defaultProps = { id: null };

export default FirmwareGroupItemForm;
