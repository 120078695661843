import React from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import useTheme from '@material-ui/core/styles/useTheme';
import { useStyles } from './BoundaryTextField.style';

const BoundaryTextField = (props) => {

  const { boundary, onChange, property, required } = props;
  const theme = useTheme();
  const classes = useStyles(theme);

  return (
    <Grid
      item
      xs={3}
      md={2}
    >
      <TextField
        required={required}
        className={classes.smallField}
        InputProps={{
          disableUnderline: true,
          classes: {
            root: classes.bootstrapRoot,
            input: classes.bootstrapInput,
          },
        }}
        inputProps={{
          step: '0.01',
          min: '0',
        }}
        type="number"
        margin="normal"
        onChange={event => onChange(
          boundary.is_front,
          boundary.weather_type,
          boundary.tire_type,
          property,
          event.target.value === '' ? '' : Number(event.target.value),
        )}
        value={boundary[`${property}`]}
      />
    </Grid>
  );
}

BoundaryTextField.propTypes = {
  boundary: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  property: PropTypes.string.isRequired,
  required: PropTypes.bool.isRequired
};

export default BoundaryTextField;
