import makeStyles from '@material-ui/core/styles/makeStyles';
import {
  michelin_red,
  michelin_white,
} from '../../../constants/michelin_colors';

export const useStyles = makeStyles(() => {
  return {
    chip: {
      backgroundColor: michelin_red,
      color: michelin_white
    },
  };
});
