import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { setTitle } from '../../store/title/actions';
import withAuthentication from '../../hoc/withAuthentication';
import { ROLE_ADMIN } from '../../constants/roles';
import ChallengeTabs from '../../components/Challenges/ChallengeTabs';
import AddButton from '../../components/Layout/Button/AddButton';
import { CHALLENGE_ADD } from '../../constants/routes';

export const ChallengesPage = () => {
  const { t } = useTranslation();
  const { title } = useSelector(state => state.title);
  const dispatch = useDispatch();

  if (title !== t('title:title.challenges_page')) {
    dispatch(setTitle(t('title:title.challenges_page')));
  }

  return(
  <>
    <ChallengeTabs/>
    <AddButton
      path={CHALLENGE_ADD}
    />
  </>
  );
}

export default withAuthentication(ROLE_ADMIN)(ChallengesPage);