export const columns = [
  {
    id: 'translations.fr.name',
    numeric: false,
    disablePadding: false,
  },
  {
    id: '',
    numeric: false,
    disablePadding: false,
  },
];
export const defaultOrderBy = 'translations.fr.name';
