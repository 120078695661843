import React from 'react';import withAuthentication from '../../../hoc/withAuthentication';
import { ROLE_ADMIN } from '../../../constants/roles';
import { useDispatch, useSelector } from 'react-redux';
import { setTitle } from '../../../store/title/actions';
import { useTranslation } from 'react-i18next';
import FirmwareExcludedVersionForm
  from '../../../components/Receivers/ExcludedVersions/FirmwareExcludedVersionForm';

const AddExcludedVersionPage = () => {

  const { t } = useTranslation();
  const { title } = useSelector(state => state.title);
  const dispatch = useDispatch();
  if (title !== t('title:title.excluded_version_add_page')) {
    dispatch(setTitle(t('title:title.excluded_version_add_page')));
  }

  return <FirmwareExcludedVersionForm />;
}

export default withAuthentication(ROLE_ADMIN)(AddExcludedVersionPage);
