const REQUESTS_PENDING = 'requests_pending';
const REQUESTS_FAILED = 'requests_failed';
const REQUESTS_DOWNLOADED = 'requests_downloaded';
const REQUEST_SENDING = 'request_sending';
const REQUEST_MESSAGE_DOWNLOADED = 'request_message_downloaded';
const REQUEST_TRACK_DOWNLOADED = 'request_track_downloaded';
const REQUEST_CAR_DOWNLOADED = 'request_car_downloaded';
const REQUEST_RALLY_CAR_DOWNLOADED = 'request_rally_car_downloaded';
const REQUEST_MESSAGE = 'messages';
const REQUEST_TRACK = 'circuits';
const REQUEST_CAR = 'cars';
const REQUEST_RALLY_CAR = 'rally-cars';

const REQUEST_STATE = Object.freeze({
  NEW: 0,
  IN_PROGRESS: 1,
  NOT_DONE: 2,
  DONE: 3,
  properties: [
    {
      name: 'new',
      value: 0,
    },
    {
      name: 'in_progress',
      value: 1,
    },
    {
      name: 'not_done',
      value: 2,
    },
    {
      name: 'done',
      value: 3,
    },
  ],
});
const REQUEST_SUBJECT_TYPE = Object.freeze({
  MALFUNCTION: 0,
  APP_USAGE: 1,
  IDEAS_FOR_IMPROVEMENT: 2,
  OTHER: 3,
  properties: [
    {
      name: 'malfunction',
      value: 0,
    },
    {
      name: 'app_usage',
      value: 1,
    },
    {
      name: 'ideas_for_improvement',
      value: 2,
    },
    {
      name: 'other',
      value: 3,
    },
  ],
});
export {
  REQUESTS_PENDING,
  REQUESTS_FAILED,
  REQUESTS_DOWNLOADED,
  REQUEST_SENDING,
  REQUEST_MESSAGE_DOWNLOADED,
  REQUEST_TRACK_DOWNLOADED,
  REQUEST_CAR_DOWNLOADED,
  REQUEST_RALLY_CAR_DOWNLOADED,
  REQUEST_MESSAGE,
  REQUEST_TRACK,
  REQUEST_CAR,
  REQUEST_RALLY_CAR,
  REQUEST_STATE,
  REQUEST_SUBJECT_TYPE,
};
