import {
  CATALOGS_TAG_DELETED,
  CATALOGS_TAG_DOWNLOADED,
  CATALOGS_TAGS_DOWNLOADED,
  CATALOGS_TAGS_FAILED,
  CATALOGS_TAGS_PENDING,
  CATALOGS_TAGS_SENDING,
} from './types';

export const INITIAL_STATE = {
  content: null,
  pending: false,
  sending: false,
  error: null,
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case CATALOGS_TAGS_PENDING:
      return {
        ...state,
        pending: true,
      };
    case CATALOGS_TAGS_SENDING:
      return {
        ...state,
        sending: true,
      };
    case CATALOGS_TAGS_FAILED:
      return {
        ...state,
        pending: false,
        sending: false,
        error: action.payload,
      };
    case CATALOGS_TAGS_DOWNLOADED:
      return {
        ...state,
        pending: false,
        content: action.payload,
      };
    case CATALOGS_TAG_DOWNLOADED: {
      let newContent = [];
      if (state.content != null) {
        newContent = [...state.content];
      }
      let index = newContent.findIndex(tag => tag.id === action.payload.id);
      if (index === -1) {
        newContent.push(action.payload);
      } else {
        newContent[index] = action.payload;
      }
      return {
        ...state,
        pending: false,
        sending: false,
        content: newContent,
      };
    }
    case CATALOGS_TAG_DELETED: {
      let newContent = [];
      if (state.content != null) {
        newContent = [...state.content];
      }
      let index = newContent.findIndex(tag => tag.id === action.payload);
      if (index !== -1) {
        newContent.splice(index, 1);
      }
      return {
        ...state,
        pending: false,
        sending: false,
        content: newContent,
      };
    }
    default:
      break;
  }
  return state;
}