import { HOME_DOWNLOADED, HOME_FAILED, HOME_PENDING, HOME_RESET_TIMER, HOME_SEARCH } from './types';
import { handleError } from '../infos/actions';
import ApiService from '../../services/ApiService';

export const homePendingAction = () => (
  { type: HOME_PENDING }
);

export const homeResetTimerAction = () => (
  { type: HOME_RESET_TIMER }
);

export const homeFailedAction = (error) => (
  {
    type: HOME_FAILED,
    payload: error,
  }
);

export const homeSearch = (search) => (
  {
    type: HOME_SEARCH,
    payload: search,
  }
);

export const homeDownloadedAction = (data) => (
  {
    type: HOME_DOWNLOADED,
    payload: data,
  }
);

export function getHome() {
  return (dispatch) => {
    dispatch(homePendingAction());
    return ApiService.getHome()
      .then(data => {
        dispatch(homeDownloadedAction(data));
        return data;
      })
      .catch(error => {
        dispatch(homeFailedAction(error));
        dispatch(handleError(error));
      });
  };
}

export function getHomeWithSearch(search) {
  return (dispatch) => {
    dispatch(homeSearch(search));
    return ApiService.getHome(search)
      .then(data => {
        dispatch(homeDownloadedAction(data));
        return data;
      })
      .catch(error => {
        dispatch(homeFailedAction(error));
        dispatch(handleError(error));
      });
  };
}

export function resetHome() {
  return (dispatch) => {
    return dispatch(homeResetTimerAction());
  };
}
