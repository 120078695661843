import React from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Table from '@material-ui/core/Table';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import { useStyles } from './CountryKPIBlock.style';
import Divider from '@material-ui/core/Divider';
import KpiPaper from '../KpiPaper/KpiPaper';

const countryRow = (el, i, j, classes) => {
  return <TableRow key={`${i}-${j}`} className={classes.row}>
    <TableCell className={classes.cell}>
      <Typography variant="body1">{el.country}</Typography>
    </TableCell>
    <TableCell className={classes.cell} align="right">
      <Typography variant="body1">{el.number}</Typography>
    </TableCell>
  </TableRow>;
};

const CountryKPIBlock = ({
                          title,
                          countries,
                        }) => {

  const classes = useStyles();

  let countriesObjList = [];
  Object.keys(countries).forEach(key => {
    countriesObjList.push({
      country: key,
      number: countries[key],
    });
  });
  countriesObjList.sort((a, b) => b.number - a.number);

  const listOne = [];
  const listTwo = [];
  const listThree = [];

  for (let i = 0; i < 15; i++) {
    let value = {
      country: null,
      number: null,
    };
    if (i < countriesObjList.length) {
      value = countriesObjList[i];
    }
    if (i < 5) {
      listOne.push(value);
    } else if (i < 10) {
      listTwo.push(value);
    } else {
      listThree.push(value);
    }
  }


  return (
    <KpiPaper>
      <Typography
        variant="h3"
        color="primary"
        component="p"
        className={classes.title}
      >
        {title}
      </Typography>
      <Grid
        container
        direction="row"
        spacing={1}
        wrap="nowrap"
        alignContent="flex-start"
        justifyContent="space-between"
      >
        <Grid item className={classes.table}>
          <Table>
            {listOne.map((el, index) =>
              countryRow(el,1, index, classes),
            )}
          </Table>
        </Grid>
        {countriesObjList.length > 5
        && <>
          <Divider orientation="vertical" flexItem className={classes.divider}/>
          <Grid item className={classes.table}>
            <Table>
              {listTwo.map((el, index) =>
                countryRow(el,2, index, classes),
              )}
            </Table>
          </Grid>
        </>}
        {countriesObjList.length > 10
        && <>
          <Divider orientation="vertical" flexItem className={classes.divider}/>
          <Grid item className={classes.table}>
            <Table>
              {listThree.map((el, index) =>
                countryRow(el,3, index, classes),
              )}
            </Table>
          </Grid>
        </>}
      </Grid>
    </KpiPaper>
  );
};

export default CountryKPIBlock;
