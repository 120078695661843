import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import {
  deleteInAppNotifications,
  getInAppNotifications,
} from '../../store/inAppNotifications/actions';
import { setBack, setTitle, showLangMenu } from '../../store/title/actions';
import i18next from '../../i18n';
import { IN_APP_NOTIFICATIONS } from '../../constants/routes';
import MTCLoader from '../../components/Layout/MTCLoader';
import withAuthentication from '../../hoc/withAuthentication';
import { ROLE_ADMIN } from '../../constants/roles';
import InAppNotificationMenuButton
  from '../../components/InAppNotifications/InAppNotificationMenuButton';
import MTCDialog from '../../components/Form/MTCDialog';
import InAppNotificationDetails
  from '../../components/InAppNotifications/details/InAppNotificationDetails';

export const InAppNotificationPage = () => {
  const {
    pending,
    content,
    error,
    sending
  } = useSelector(state => state.inAppNotifications);
  const {
    title,
    back,
    displayLangMenu,
  } = useSelector(state => state.title);
  const dispatch = useDispatch();
  const { notificationId } = useParams();
  const { t } = useTranslation(['inAppNotification', 'translations']);
  const lang = i18next.languages[0].split('-')[0];
  const [notification, setNotification] = useState(null);
  const [open, setOpen] = React.useState(false);
  const history = useHistory();
  if (!content && !pending && !error) {
    dispatch(getInAppNotifications());
  }
  if (
    notification
    && notification.translations
    && notification.translations[lang]
    && title !== notification.translations[lang].title
  ) {
    dispatch(setTitle(notification.translations[lang].title));
  }
  if (notification && back !== IN_APP_NOTIFICATIONS) {
    dispatch(setBack(IN_APP_NOTIFICATIONS, {}));
  }
  if (content && !notification) {
    setNotification(content.find(ch => ch.id == notificationId));
  }
  if (notification && !displayLangMenu) {
    dispatch(showLangMenu());
  }

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDelete = id => {
    dispatch(deleteInAppNotifications(id)).then(() => history.push(IN_APP_NOTIFICATIONS));
  };

  if (pending || !notification) {
    return <MTCLoader />;
  } else {
    return <>
      <InAppNotificationDetails notification={notification} />
      <InAppNotificationMenuButton notification={notification} handleDelete={handleOpen}/>
      <MTCDialog
        open={open}
        handleClose={handleClose}
        dialogTitle={t('remove_in_app_notification_title')}
        dialogText={t('remove_in_app_notification_text')}
        handleCloseCancel={handleClose}
        handleCloseOK={() => handleDelete(notification.id)}
        cancelText={t('translations:cancel')}
        okText={t('translations:validate')}
        okPending={sending}
      />
    </>;
  }
};

export default withAuthentication(ROLE_ADMIN)(InAppNotificationPage);
