export const columns = [
  {
    id: 'translations.fr.name',
    numeric: false,
    disablePadding: false,
  },
  {
    id: 'country_code',
    numeric: false,
    disablePadding: false,
  },
  {
    id: 'is_public',
    numeric: false,
    disablePadding: false,
  },
  {
    numeric: false,
    disablePadding: false,
  },
];
export const defaultOrderBy = 'translations.fr.name';
