import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Drawer from '@material-ui/core/Drawer';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import NavigationClose from '@material-ui/icons/Close';
import Toolbar from '@material-ui/core/Toolbar';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import MenuItem from '@material-ui/core/MenuItem';
import { changeContentLang } from '../../../../store/contentLang/actions';
import { data_languages } from '../../../../constants/lang';
import { useTheme } from '@material-ui/core';
import { useStyles } from './LangMenu.style';
import { resetHome } from '../../../../store/home/actions';

export const LangMenu = (props) => {

  const { lang } = useSelector(state => state.contentLang);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { drawerOpen, handleCloseDrawer } = props;
  const theme = useTheme();
  const classes = useStyles(theme);

  const changeLang = (selectedLang) => {
    dispatch(changeContentLang(selectedLang))
    dispatch(resetHome());
    handleCloseDrawer();
  }

  return (
    <div className={classes.menu}>
        <Drawer
          variant="temporary"
          open={drawerOpen}
          onClose={handleCloseDrawer}
          anchor={'right'}
          classes={{
            paper: classes.drawerPaper,
          }}
          className={classes.root}
          ModalProps={{ keepMounted: true }}
        >
          <Toolbar
            className={classes.toolbar}
          >
            <IconButton
              onClick={handleCloseDrawer}
              className={classes.closeButton}
            >
              <NavigationClose/>
            </IconButton>
            <Typography variant={'subtitle1'}>
              {t('title:menu.content_lang_menu_title')}
            </Typography>
          </Toolbar>
          <Divider/>
          {data_languages.map(lg => (
            <MenuItem
              id={`menu_item_lang_${lg}`}
              key={`menu_item_lang_${lg}`}
              component={Link}
              to={'#'}
              selected={lang === lg}
              disabled={false}
              onClick={() => changeLang(lg)}
            >
              <Typography variant={'subtitle1'}>
                {t('lang:' + lg)}
              </Typography>
            </MenuItem>
          ))}
        </Drawer>
    </div>
  );
}

export default React.memo(LangMenu);
