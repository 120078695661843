const TIRES_PENDING = 'tires_pending';
const TIRES_FAILED = 'tires_failed';
const TIRES_DOWNLOADED = 'tires_downloaded';
const TIRE_SENDING = 'tire_sending';
const TIRE_DOWNLOADED = 'tire_downloaded';

export {
  TIRES_PENDING,
  TIRES_FAILED,
  TIRES_DOWNLOADED,
  TIRE_SENDING,
  TIRE_DOWNLOADED,
};
