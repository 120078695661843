import makeStyles from '@material-ui/core/styles/makeStyles';
import { clickableRowStyle } from '../../../constants/styles/row.style';
import { inputStyle } from '../../../constants/styles/input.style';

export const useStyles = makeStyles(theme => {
  return {
    row: clickableRowStyle(theme),
    button: {
      color: 'inherit',
    },
    input: {
      ...inputStyle(theme),
      width: '100% !important',
      color: theme.palette.textColor,
      backgroundColor: theme.palette.alternateTextColor,
    },
  };
});
