import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { useDispatch } from 'react-redux';
import useTheme from '@material-ui/core/styles/useTheme';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import Visibility from '@material-ui/icons/Visibility';
import Typography from '@material-ui/core/Typography';
import { TIRE_EDIT } from '../../constants/routes';
import { putTire } from '../../store/tires/actions';
import { useStyles } from './TireRow.style';

const TireRow = ({ value }) => {

  const theme = useTheme();
  const classes = useStyles(theme);
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();

  const handleEdit = event => {
    event.stopPropagation();
    history.push(
      TIRE_EDIT,
      { tire: value },
    );
  };

  const handleSwitchActivate = event => {
    event.stopPropagation();
    dispatch(putTire({
      type: {
        ...value.type,
        state: (
          value.type.state === 1
            ? 0
            : 1
        ),
      },
    }));
    event.preventDefault();
  };

  return (
    <TableRow
      className={value.type.state
        ? classes.row_disabled
        : classes.row}
      key={value.id}
      hover
      onClick={handleEdit}
      tabIndex={-1}
    >
      <TableCell>
        <Typography>
          {value.brand.name}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography>
          {value.type.name}
        </Typography>
      </TableCell>
      <TableCell align={'right'}>
        <Tooltip title={t('edit')}>
          <IconButton
            id='edit'
            aria-label="update"
            onClick={handleEdit}
            color="primary"
            className={classes.button}
          >
            <EditIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title={value.type.state
          ? t('deactivate')
          : t('activate')}>
          <IconButton
            id='switch'
            aria-label="update"
            onClick={handleSwitchActivate}
            color="primary"
            className={classes.button}
          >
            {value.type.state
              ? <VisibilityOff />
              : <Visibility />}
          </IconButton>
        </Tooltip>
      </TableCell>
    </TableRow>
  );
};

TireRow.propTypes = {
  value: PropTypes.object.isRequired,
};

export default React.memo(TireRow);
