import React, { useEffect } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import useTheme from '@material-ui/core/styles/useTheme';
import FormControl from '@material-ui/core/FormControl';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import { DIRECTORY } from '../../FaqElementType.const';
import { useStyles } from '../FaqElementsDialog.style';
import { useTranslation } from 'react-i18next';
import FaqElementsExplorerTree from '../../FaqElementsExplorerTree/FaqElementsExplorerTree';
import i18next from '../../../../../i18n';

const FaqElementsRemoveDialog = ({
                                   openRemoveDialog,
                                   handleCloseRemoveDialog,
                                   setOpenRemoveDialog,
                                   handleDelete,
                                   fullScreen,
                                   sending,
                                   content,
                                 }) => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const { t } = useTranslation();
  const lang = i18next.languages[0].split('-')[0];

  const steps = [
    t('question_remove_faq_element_directory'),
    t('remove_faq_element_new_folder'),
  ];
  const [activeStep, setActiveStep] = React.useState(0);
  const [completed, setCompleted] = React.useState({});
  const [newParentId, setNewParentId] = React.useState(null);

  const totalSteps = 2;

  useEffect(() => {
    if (!openRemoveDialog.open) {
      setActiveStep(0);
      setNewParentId(null);
    }
  }, [openRemoveDialog]);

  const completedSteps = () => {
    return Object.keys(completed).length;
  };

  const isLastStep = () => {
    return activeStep === totalSteps - 1;
  };

  const allStepsCompleted = () => {
    return completedSteps() === totalSteps;
  };

  const handleFinish = (event) => {
    event.stopPropagation();
    event.preventDefault();
    handleDelete(newParentId);
  };

  const handleNext = () => {
    const newActiveStep =
      isLastStep() && !allStepsCompleted()
        ? // It's the last step, but not all steps have been completed,
          // find the first step that has been completed
        steps.findIndex((step, i) => !(
          i in completed
        ))
        : activeStep + 1;
    setActiveStep(newActiveStep);
  };

  const handleBack = () => {
    setNewParentId(null);
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleCloseStepper = () => {
    setActiveStep(0);
    setCompleted({});
    handleCloseRemoveDialog();
  };

  const handleClose = () => {
    if (openRemoveDialog.type === DIRECTORY) {
      handleCloseStepper();
    } else {
      handleCloseRemoveDialog();
    }
  };
  const moveToElement = content.find(el => el.id === newParentId);
  const moveToElementTitle = (
    moveToElement && moveToElement.translations[lang]
  )
    ? moveToElement.translations[lang].title
    : 'FAQ';

  return (
    <Dialog
      id="remove-dialog"
      open={openRemoveDialog.open}
      onClose={handleClose}
      fullScreen={fullScreen}
      fullWidth
      maxWidth={'md'}
    >
      <DialogTitle disableTypography>
        <Typography variant="h6">
          {t('delete')}{' '}{openRemoveDialog.title}
        </Typography>
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={handleCloseRemoveDialog}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      {openRemoveDialog.type !== DIRECTORY && <>
        <DialogContent>
          <Typography variant="body1">
            {t('remove_faq_element')}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseRemoveDialog} backgroundcolor={'secondary'}>
            {t('cancel')}
          </Button>
          <div className={classes.wrapper}>
            <Button color={'primary'} disabled={sending} type={'button'} onClick={handleFinish}>
              {t('validate')}
            </Button>
            {sending && <CircularProgress size={24} className={classes.buttonProgress} />}
          </div>
        </DialogActions>
      </>}
      {openRemoveDialog.type === DIRECTORY && <>
        <DialogContent>
          <div>
            {activeStep === 0 && <>
              <Typography variant="body1" gutterBottom>{t('remove_faq_element_folder')}</Typography>
              <Typography variant="body1">
                {t('question_remove_faq_element_directory')}
              </Typography>
              <FormControl component="fieldset">
                <RadioGroup
                  aria-label="directoryChoice"
                  name="directoryChoice"
                  value={openRemoveDialog.directoryChoice}
                  onChange={
                    (event) => setOpenRemoveDialog(
                      {
                        ...openRemoveDialog,
                        directoryChoice: event.target.value,
                      },
                    )
                  }
                >
                  <FormControlLabel
                    value="remove"
                    control={<Radio color="primary" />}
                    label={t('remove')}
                  />
                  <FormControlLabel
                    value="move"
                    control={<Radio color="primary" />}
                    label={t('move')}
                  />
                </RadioGroup>
              </FormControl>
            </>}
            {activeStep === 1
              && <>
                {openRemoveDialog.directoryChoice === 'move' && <>
                  <Typography variant="body1">{steps[1]}</Typography>
                  <FaqElementsExplorerTree
                    elements={content}
                    changeCurrent={setNewParentId}
                    setOpenExternalLinkDialog={() => {
                    }}
                    forDialog
                    hideId={openRemoveDialog.elementId}
                  />
                  {newParentId && <Typography variant="body1">
                    {t('remove_faq_element_new_folder_result')}
                    {moveToElementTitle}
                  </Typography>
                  }
                </>
                }
                {openRemoveDialog.directoryChoice === 'remove' &&
                  <Typography variant="body1">
                    {t('remove_faq_element_and_children')}
                  </Typography>
                }
              </>}
          </div>
        </DialogContent>
        <DialogActions>
          <Grid container justifyContent="space-between" alignContent="center">
            <Grid item>
              <Button
                onClick={handleCloseStepper}
                backgroundcolor={'secondary'}
                disabled={sending}
              >
                {t('cancel')}
              </Button>
            </Grid>
            <Grid item>
              <Grid container justifyContent="flex-end" alignContent="flex-start">
                <Grid item>
                  <Button onClick={handleBack} disabled={activeStep === 0}>
                    {t('back')}
                  </Button>
                </Grid>
                {!isLastStep() && <Grid item>
                  <Button
                    disabled={openRemoveDialog.directoryChoice === ''}
                    color="primary"
                    onClick={handleNext}
                  >
                    {t('next')}
                  </Button>
                </Grid>}
                {isLastStep() && <Grid item>
                  <div className={classes.wrapper}>
                    <Button
                      disabled={
                        sending
                        || (
                          openRemoveDialog.directoryChoice === 'move' && newParentId === null
                        )
                      }
                      color="primary"
                      onClick={handleFinish}
                    >
                      {t('validate')}
                    </Button>
                    {sending && <CircularProgress size={24} className={classes.buttonProgress} />}
                  </div>
                </Grid>
                }
              </Grid>
            </Grid>
          </Grid>
        </DialogActions>
      </>
      }
    </Dialog>
  );
};

export default FaqElementsRemoveDialog;
