import makeStyles from '@material-ui/core/styles/makeStyles';
import { textFieldClass } from '../../constants/styles/input.style';
import { michelin_blue_main } from '../../constants/michelin_colors';

export const useStyles = makeStyles(theme => {
  return {
    ...textFieldClass(theme),
    textFieldFull: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      width: 'calc(100% - 16px)',
    },
    input: {
      color: theme.palette.textColor,
    },
    cancelIcon: {
      '&:hover': {
        color: michelin_blue_main,
        cursor: 'pointer',
      },
    },
  };
});
