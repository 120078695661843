import React, { useState } from 'react';
import PropTypes from 'prop-types';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import useTheme from '@material-ui/core/styles/useTheme';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import { RALLY_CAR_DUPLICATE, RALLY_CAR_EDIT } from '../../../constants/routes';
import Typography from '@material-ui/core/Typography';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import AddToPhotosIcon from '@material-ui/icons/AddToPhotos';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { useDispatch } from 'react-redux';
import {
  editRallyCarsBrand,
  editRallyCarsDimension,
  editRallyCarsGroup,
  editRallyCarsModel,
} from '../../../store/tableRallyCars/actions';
import { useStyles } from './RallyCarRow.style';

const RallyCarRow = ({ value, setMenuAction }) => {

  const theme = useTheme();
  const classes = useStyles(theme);
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const [focusCell, setFocusCell] = useState(null);

  const resetFocus = () => {
    setFocusCell(null);
  }

  // Handler
  const handleEdit = event => {
    if (event) {
      event.stopPropagation();
    }
    history.push(RALLY_CAR_EDIT.replace(':rallyDimensionId', value.rally_dimension_id));
  };

  const handleDuplicate = event => {
    if (event) {
      event.stopPropagation();
      event.preventDefault();
    }
    history.push(RALLY_CAR_DUPLICATE.replace(':rallyDimensionId', value.rally_dimension_id));
  };

  const getClassByState = (state) => {
    switch (state) {
      case 1:
        return classes.cellDisabled;
      case 2:
        return classes.cellParentDisabled;
      case 3:
        return classes.focusCell;
      default:
        return classes.clickableCell;
    }
  };

  const rallyBrandState = focusCell === 'rallyBrand'
    ? 3
    : (value.rally_brand_state ? 1 : 0);
  const rallyModelState = focusCell === 'rallyModel'
    ? 3
    : (rallyBrandState === 1 ? 2 : value.rally_model_state ? 1 : 0);
  const rallyGroupState = focusCell === 'rallyGroup'
    ? 3
    : ((rallyModelState >= 1 && rallyModelState < 3) ? 2 : value.rally_group_state ? 1 : 0);
  const rallyDimensionState = focusCell === 'rallyDimension'
    ? 3
    : ((rallyGroupState >= 1 && rallyGroupState < 3) ? 2 : value.rally_dimension_state ? 1 : 0);

  const displayRallyBrandContextMenu = (event) => {
    let newValue = value.rally_brand_state >= 1 ? 0 : 1;
    setFocusCell('rallyBrand');
    setMenuAction(
      event,
      function () {dispatch(editRallyCarsBrand(value.rally_brand_id, {state: newValue}))},
      value.rally_brand_state ? t('activate') : t('deactivate') ,
      handleEdit,
      handleDuplicate,
      resetFocus
    )
  }

  const displayRallyModelContextMenu = (event) => {
    let newValue = value.rally_model_state >= 1 ? 0 : 1;
    setFocusCell('rallyModel');
    setMenuAction(
      event,
      function () {
        dispatch(
          editRallyCarsModel(value.rally_brand_id, value.rally_model_id, {state: newValue})
        )
      },
      value.rally_model_state ? t('activate') : t('deactivate') ,
      handleEdit,
      handleDuplicate,
      resetFocus
    )
  }

  const displayRallyGroupContextMenu = (event) => {
    let newValue = value.rally_group_state >= 1 ? 0 : 1;
    setFocusCell('rallyGroup');
    setMenuAction(
      event,
      function () {
        dispatch(
          editRallyCarsGroup(
            value.rally_brand_id,
            value.rally_model_id,
            value.rally_group_id,
            {state: newValue}
            )
        )
      },
      value.rally_group_state ? t('activate') : t('deactivate') ,
      handleEdit,
      handleDuplicate,
      resetFocus
    )
  }

  const displayRallyDimensionContextMenu = (event) => {
    let newValue = value.rally_dimension_state >= 1 ? 0 : 1;
    setFocusCell('rallyDimension');
    setMenuAction(
      event,
      function () {
        dispatch(
          editRallyCarsDimension(
            value.rally_brand_id,
            value.rally_model_id,
            value.rally_group_id,
            value.rally_dimension_id,
            {state: newValue}
          )
        )
      },
      value.rally_dimension_state ? t('activate') : t('deactivate') ,
      handleEdit,
      handleDuplicate,
      resetFocus
    )
  }

  return (
    <TableRow
      className={classes.row}
      key={'r_' + value.dimension_id}
      hover
      tabIndex={-1}
    >
      <TableCell
        className={getClassByState(rallyBrandState)}
        onClick={displayRallyBrandContextMenu}
      >
        <Typography>
          {value.rally_brand_state >= 1
            ? <VisibilityOff className={classes.visibilityOffIcon}/>
            : ''
          }
          {value.rally_brand_name}
        </Typography>
      </TableCell>
      <TableCell
        className={getClassByState(rallyModelState)}
        onClick={displayRallyModelContextMenu}
      >
        <Typography>
          {value.rally_model_state >= 1
            ? <VisibilityOff className={classes.visibilityOffIcon}/>
            : ''
          }
          {value.rally_model_name}
        </Typography>
      </TableCell>
      <TableCell
        className={getClassByState(rallyGroupState)}
        onClick={displayRallyGroupContextMenu}
      >
        <Typography>
          {value.rally_group_state >= 1
            ? <VisibilityOff className={classes.visibilityOffIcon}/>
            : ''
          }
          {value.rally_group_name}
        </Typography>
      </TableCell>
      <TableCell
        className={getClassByState(rallyDimensionState)}
        onClick={displayRallyDimensionContextMenu}
      >
        <Typography>
          {value.rally_dimension_state >= 1
            ? <VisibilityOff className={classes.visibilityOffIcon}/>
            : ''
          }
          {value.rally_dimension_front_dimension + ' / ' + value.rally_dimension_rear_dimension}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography>
          {t(value.rally_dimension_is_public
            ? 'public'
            : 'tester'
          )}
        </Typography>
      </TableCell>
      <TableCell align={'right'}>
        <Tooltip title={t('edit')}>
          <IconButton
            id='edit'
            aria-label="update"
            onClick={handleEdit}
            className={classes.button}
          >
            <EditIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title={t('duplicate')}>
          <IconButton
            id='duplicate'
            aria-label='duplicate'
            onClick={handleDuplicate}
            className={classes.button}
          >
            <AddToPhotosIcon />
          </IconButton>
        </Tooltip>
      </TableCell>
    </TableRow>
  );
};

RallyCarRow.propTypes = {
  value: PropTypes.object.isRequired,
};

export default React.memo(RallyCarRow);
