import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import AppBar from '@material-ui/core/AppBar';
import { getRequests } from '../../store/requests/actions';
import RequestsCarTable from './Car/RequestsCarTable';
import RequestsMessageTable from './Message/RequestsMessageTable';
import RequestsRallyCarTable from './RallyCar/RequestsRallyCarTable';
import RequestsCircuitTable from './Circuit/RequestsCircuitTable';
import MTCLoader from '../Layout/MTCLoader';
import Typography from '@material-ui/core/Typography';
import { useStyles } from './RequestTabs.style';

const RequestTabs = () => {

  const history = useHistory();
  const classes = useStyles();
  const {
    pending,
    content,
    error,
  } = useSelector(state => state.requests);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [value, setValue] = useState(0);
  const [locationStateMatch, setLocationStateMatch] = useState(true);

  if (!content && !pending && !error) {
    dispatch(getRequests());
  }

  if (
    history.location
    && history.location.state
    && locationStateMatch
  ) {
    if (history.location.state.value) {
      setValue(history.location.state.value);
    }
    setLocationStateMatch(false);
  }

  const handleChange = (event, value) => {
    setValue(value);
  };

  if (pending) {
    return <MTCLoader />;
  } else if (content) {
    return (
      <div className={classes.root}>
        <AppBar position={'static'}>
          <Tabs
            value={value}
            onChange={handleChange}
            variant={'scrollable'}
            scrollButtons={'on'}
          >
            <Tab label={t('title:title.message_requests_page')} />
            <Tab label={t('title:title.track_requests_page')} />
            <Tab label={t('title:title.car_requests_page')} />
            <Tab label={t('title:title.rally_car_requests_page')} />
          </Tabs>
        </AppBar>
        {value === 0 && <RequestsMessageTable
          users={content.users}
          requests={content.messages_requests}
        />}
        {value === 1 && <RequestsCircuitTable
          users={content.users}
          requests={content.circuit_requests}
        />}
        {value === 2 && <RequestsCarTable
          users={content.users}
          requests={content.v4_car_requests}
        />}
        {value === 3 && <RequestsRallyCarTable
          users={content.users}
          requests={content.rally_car_requests}
        />}
      </div>
    );
  } else {
    return (
      <Typography
        variant={'subtitle1'}
        className={classes.label}
      >
        {t('no_request')}
      </Typography>
    );
  }
};

export default React.memo(RequestTabs);
