import React, { useState } from 'react';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import DateFnsUtils from '@date-io/date-fns';
import { EditorState } from 'draft-js';
import Dropzone from 'react-dropzone';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import CardMedia from '@material-ui/core/CardMedia';
import Image from '@material-ui/icons/Image';
import FormHelperText from '@material-ui/core/FormHelperText';
import Grid from '@material-ui/core/Grid';
import { useTheme } from '@material-ui/core';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import enLocale from 'date-fns/locale/en-GB';
import frLocale from 'date-fns/locale/fr';
import { INITIAL_ERROR_STATE, INITIAL_STATE } from './InAppNotificationForm.const';
import { stateWithInAppNotification } from './InAppNotificationForm.func';
import { editorStateToHtml } from '../../../utils/DraftEditorUtils';
import {
  postInAppNotifications,
  putInAppNotifications,
} from '../../../store/inAppNotifications/actions';
import { handleError } from '../../../store/infos/actions';
import { IN_APP_NOTIFICATION } from '../../../constants/routes';
import i18next from '../../../i18n';
import MTCTextField from '../../Form/MTCTextField';
import { data_languages, data_languages_no_fr } from '../../../constants/lang';
import useStyles from './InAppNotificationForm.style';
import MTCSwitch from '../../Form/MTCSwitch';
import { supportedPictureDropFiles } from '../../../constants/files';
import MTCButton from '../../Layout/Button/MTCButton';
import MTCRichText from '../../Form/MTCRichText';
import { EXPERT_COMPATIBLE_VEHICLE, USER_TYPES } from '../../../constants/inAppNotification';
import { compareCoutries } from '../../CallCenters/AddCallCenterForm.const';
import { ALL_OS } from '../../../constants/operatingSystem';
import { ALL_OPERATOR } from '../../../constants/comparisonOperator';
import Chip from '@material-ui/core/Chip';

const countries = require('country-list')();

const localeMap = {
  en: enLocale,
  fr: frLocale,
};

export const InAppNotificationForm = ({ notification, duplicate }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const theme = useTheme();
  const classes = useStyles(theme);
  const [state, setState] = useState(INITIAL_STATE);
  const [errors, setErrors] = useState(INITIAL_ERROR_STATE);
  const [picture, setPicture] = useState('');
  const { sending } = useSelector(state => state.inAppNotifications);
  const isDuplicate = duplicate !== undefined ? duplicate : false;
  let sortedCountries = [...countries.getData()];
  sortedCountries.sort(compareCoutries);

  if (notification !== undefined && notification !== null && state.id === null) {
    setState(stateWithInAppNotification(notification, isDuplicate));
    setPicture(!isDuplicate ? notification.picture ?? '' : null,);
  }

  const isEditing = notification !== undefined && notification !== null && !isDuplicate;

  const handleStatePropertyChange = (property, value) => {
    let newState = { ...state };
    newState[property] = value;
    let newErrors = { ...errors };
    newErrors[property + '_error'] = null;
    if (property === 'with_app_version') {
      if (!value) {
        newState['app_version'] = '';
        newState['app_version_condition'] = '';
        newErrors['app_version_error'] = null;
        newErrors['app_version_condition_error'] = null;
      }
    }
    if (property === 'user_type') {
      if (!EXPERT_COMPATIBLE_VEHICLE.includes(value)) {
        newState['user_has_expert_compatible_free_car'] = false;
      }
    }
    if (property === 'has_call_to_action') {
      newState = {
        ...newState,
        ...data_languages.reduce((o, key) => (
          {
            ...o,
            [`call_to_action_title_${key}`]: '',
            [`call_to_action_link_${key}`]: '',
          }
        ), {}),
      };
    }
    setState(newState);
    setErrors(newErrors);
  };

  const handlePictureMediaDrop = (accepted, rejected) => {
    if (rejected.length) {
      if (rejected[0].type === '') {
        dispatch(handleError({
          message: t('error.format.image_unknown_type'),
        }));
      } else {
        dispatch(handleError({
          message: t('error.format.image') + rejected[0].type,
        }));
      }
    } else {
      setState({
        ...state,
        picture_to_upload: accepted[0],
      });
      const fileReader = new FileReader();
      fileReader.readAsDataURL(accepted[0]);
      fileReader.addEventListener("load", function () {
        setPicture(this.result);
      });
    }
  };

  const handleSubmit = event => {
    event.preventDefault();
    event.stopPropagation();
    if (!sending) {
      let errorCount = 0;
      let newErrorState = { ...INITIAL_ERROR_STATE };
      data_languages.forEach(dl => {
        const titleKey = `title_${dl}`;
        const title = state[titleKey];
        const textKey = `text_${dl}`;
        const text = state[textKey];
        if (title === null || title.trim() === '') {
          errorCount++;
          newErrorState[titleKey + '_error'] = t('error.required_error');
        }
        if (text === null || !text.getCurrentContent().hasText()) {
          errorCount++;
          newErrorState[textKey + '_error'] = t('error.required_error');
        }
        if (state.has_call_to_action) {
          const ctaTitleKey = `call_to_action_title_${dl}`;
          const ctaTitle = state[ctaTitleKey];
          const ctaLinkKey = `call_to_action_link_${dl}`;
          const ctaLink = state[ctaLinkKey];
          if (ctaTitle === null || ctaTitle.trim() === '') {
            errorCount++;
            newErrorState[ctaTitleKey + '_error'] = t('error.required_error');
          }
          if (ctaLink === null || ctaLink.trim() === '') {
            errorCount++;
            newErrorState[ctaLinkKey + '_error'] = t('error.required_error');
          }
        }
        if (state.push) {
          const pushTitleKey = `push_title_${dl}`;
          const pushTitle = state[pushTitleKey];
          if (pushTitle === null || pushTitle.trim() === '') {
            errorCount++;
            newErrorState[pushTitleKey + '_error'] = t('error.required_error');
          }
          if (state.start_date !== null) {
            if (state.start_date > Math.floor(Date.now() / 1000) && state.push_time === null) {
              newErrorState['push_time_error'] = t('error.required_push_time');
            }
          }
          if (state.push_time !== null) {
            if (state.push_time < 0 || state.push_time > 23) {
              newErrorState['push_time_error'] = t('error.push_time_error');
            }
          }
        }
      });
      if (state.start_date === null) {
        errorCount++;
        newErrorState.start_date_error = t('error.required_error');
      }
      if (state.start_date !== null && state.end_date !== null) {
        const sd = Math.trunc(state.start_date.getTime() / 1000);
        const ed = Math.trunc(state.end_date.getTime() / 1000);
        if (sd === ed) {
          errorCount++;
          newErrorState.end_date_error = t('error.date_is_equals_error');
        } else if (sd >= ed) {
          errorCount++;
          newErrorState.end_date_error = t('error.end_date_less_than_start_date_error');
        }
      }
      if (state.with_app_version) {
        const versionPattern = /^([0-9]{1,2})\.([0-9]{1,2})$/;
        if (state.app_version === null || state.app_version === '') {
          errorCount++;
          newErrorState.app_version_error = t('error.required_error');
        } else if (!state.app_version.match(versionPattern)) {
          errorCount++;
          newErrorState.app_version_error = t('error.app_version_pattern');
        }
        if (state.app_version_condition === null || state.app_version_condition === '') {
          errorCount++;
          newErrorState.app_version_condition_error = t('error.required_error');
        }
      }
      if (errorCount === 0) {
        const formData = new FormData();
        if (state.picture_to_upload) {
          formData.append('picture', state.picture_to_upload);
        }
        formData.append('json', JSON.stringify({
          start_date: state.start_date
            ? Math.trunc(state.start_date.getTime() / 1000)
            : null,
          end_date: state.end_date
            ? Math.trunc(state.end_date.getTime() / 1000)
            : null,
          is_activated: state.is_activated === true,
          has_call_to_action: state.has_call_to_action,
          push: state.push,
          push_time: state.push_time !== '' ? parseInt(state.push_time) : state.push_time,
          user_type: state.user_type,
          country_codes: (state.country_codes.length > 0)
            ? state.country_codes.join(',').toUpperCase()
            : null,
          device_os: ALL_OS.includes(state.device_os)
            ? state.device_os
            : null,
          app_version: state.with_app_version ? state.app_version: null,
          app_version_condition: state.with_app_version ? state.app_version_condition: null,
          translations: data_languages.reduce((o, key) => (
            {
              ...o,
              [key]: {
                title: state[`title_${key}`],
                text: editorStateToHtml(state[`text_${key}`]),
                call_to_action_title: state[`call_to_action_title_${key}`],
                call_to_action_link: state[`call_to_action_link_${key}`],
                push_title: state[`push_title_${key}`],
                push_body: state[`push_body_${key}`],
              },
            }
          ), {}),
        }));
        if (isEditing) {
          dispatch(putInAppNotifications(notification.id, formData))
            .then(data => {
              if (data && data.id) {
                history.push(IN_APP_NOTIFICATION.replace(':notificationId', data.id));
              }
            });
        } else {
          dispatch(postInAppNotifications(formData))
            .then(data => {
              if (data && data.id) {
                history.push(IN_APP_NOTIFICATION.replace(':notificationId', data.id));
              }
            });
        }
      } else {
        setErrors(newErrorState);
      }
    }
  };

  const disabledExpertCompatibleVehicleSwitch = !EXPERT_COMPATIBLE_VEHICLE.includes(
    state.user_type
  )

  return (
    <MuiPickersUtilsProvider
      utils={DateFnsUtils}
      locale={localeMap[i18next.languages[0].split('-')[0]]}
    >
      <form>
        <Grid
          container
          align="center"
          className={classes.grid}
          direction={'column'}
          justifyContent={'center'}
        >
          <Grid item xs={12}>
            <Paper className={classes.paper}>
              <Grid container>
                <Grid item xs={12}>
                  <Typography
                    align="center"
                    variant="h5"
                    component="h2"
                    gutterBottom>
                    {t('general_infos')}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <KeyboardDatePicker
                    margin="normal"
                    id="start_date"
                    label={t('start_date') + ' *'}
                    format="dd/MM/yyyy"
                    value={state.start_date}
                    onChange={date => handleStatePropertyChange(`start_date`, date)}
                    KeyboardButtonProps={{
                      'aria-label': 'change start date',
                    }}
                    className={classes.input}
                    helperText={errors.start_date_error}
                    error={errors.start_date_error !== null}
                    disabled={isEditing}
                  />
                </Grid>
                <Grid item xs={12}>
                  <KeyboardDatePicker
                    margin="normal"
                    id="end_date"
                    label={t('end_date')}
                    format="dd/MM/yyyy"
                    value={state.end_date}
                    onChange={date => handleStatePropertyChange(`end_date`, date)}
                    KeyboardButtonProps={{
                      'aria-label': 'change end date',
                    }}
                    className={classes.input}
                    helperText={errors.end_date_error}
                    error={errors.end_date_error !== null}
                    disabled={isEditing}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormControl
                    margin={'normal'}
                    error={errors['picture_error'] !== null}
                  >
                    <InputLabel
                      id="type_label"
                      className={classes.dropzoneLabel}
                    >
                      {t('challenge_picture')}
                    </InputLabel>
                    <Dropzone
                      className={classes.media}
                      accept={supportedPictureDropFiles}
                      onDrop={handlePictureMediaDrop}
                    >
                      {picture
                        ? <CardMedia
                          component="img"
                          image={picture}
                          title={t('picture')}
                          className={classes.cardMedia}
                        />
                        : <div>
                          <Typography variant="subtitle1">{t('image_drop')}</Typography>
                          <br />
                          <Image />
                        </div>
                      }
                    </Dropzone>
                  </FormControl>
                </Grid>
                {errors['picture_error']
                && <Grid item xs={12}>
                  <FormHelperText error={true}>{errors['picture_error']}</FormHelperText>
                </Grid>
                }
                <MTCSwitch
                  id={'has_call_to_action'}
                  value={state.has_call_to_action}
                  onChange={() => {
                    handleStatePropertyChange(
                      'has_call_to_action',
                      !state.has_call_to_action,
                    );
                  }}
                  disabled={isEditing}
                />
                <MTCSwitch
                  id={'is_activated'}
                  value={state.is_activated}
                  onChange={() => {
                    handleStatePropertyChange(
                      'is_activated',
                      !state.is_activated,
                    );
                  }}
                />
              </Grid>
            </Paper>
          </Grid>
          <Grid item xs={12}>
            <Paper className={classes.paper}>
              <Grid container>
                <Grid item xs={12}>
                  <Typography
                    align="center"
                    variant="h5"
                    component="h2"
                    gutterBottom>
                    {t('inAppNotification:triggers')}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <FormControl>
                    <InputLabel
                      id="userType-label"
                      disabled={isEditing}
                      required={true}
                    >
                      {t('inAppNotification:user_type')}
                    </InputLabel>
                    <Select
                      className={classes.select}
                      labelId="userType-label"
                      id="userType"
                      multiple={false}
                      value={state.user_type}
                      disabled={isEditing}
                      onChange={event => handleStatePropertyChange('user_type', event.target.value)}
                      renderValue={(value) => (
                        <div key={'userType_selected_' + value}>
                          {t('inAppNotification:user_types.' + value)}
                        </div>
                      )}
                    >
                      {USER_TYPES.map(userType => (
                        <MenuItem key={'userType_' + userType} value={userType}>
                          {t('inAppNotification:user_types.' + userType)}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>

                <MTCSwitch
                  id={'user_has_expert_compatible_free_car'}
                  value={state.user_has_expert_compatible_free_car}
                  onChange={() => {
                    handleStatePropertyChange(
                      'user_has_expert_compatible_free_car',
                      !state.user_has_expert_compatible_free_car,
                    );
                  }}
                  disabled={isEditing || disabledExpertCompatibleVehicleSwitch}
                  label={t('inAppNotification:user_has_expert_compatible_free_car')}
                  helperText={
                    !isEditing
                    && disabledExpertCompatibleVehicleSwitch
                    && t('inAppNotification:disabled_user_has_expert_compatible_free_car')
                  }
                />
                <Grid item xs={12}>
                  <FormControl>
                    <InputLabel id="countryCodes-label" disabled={isEditing}>
                      {t('country_codes')}
                    </InputLabel>
                    <Select
                      className={classes.select}
                      labelId="countryCodes-label"
                      id="countryCodes"
                      multiple={true}
                      value={state.country_codes}
                      disabled={isEditing}
                      onChange={event => handleStatePropertyChange('country_codes',
                        event.target.value,
                      )}
                      renderValue={(selected) => (
                        selected.map((value) => (
                          <Chip key={value} label={value} />
                        ))
                      )}
                    >
                      {sortedCountries.map((country) => (
                        <MenuItem key={country.code} value={country.code}>
                          {country.code} - {country.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={12}>
                  <FormControl>
                    <InputLabel id="deviceOs-label" disabled={isEditing}>
                      {t('inAppNotification:device_os')}
                    </InputLabel>
                    <Select
                      className={classes.select}
                      labelId="deviceOs-label"
                      id="deviceOs"
                      multiple={false}
                      value={state.device_os}
                      disabled={isEditing}
                      onChange={event => handleStatePropertyChange('device_os',
                        event.target.value,
                      )}
                      renderValue={(value) => (
                        <div key={'deviceOs_selected_' + value}>
                          {value === 'all' ? t('inAppNotification:os_all') : value}
                        </div>
                      )}
                    >
                      <MenuItem key={'os_all'} value={'all'}>
                        {t('inAppNotification:os_all')}
                      </MenuItem>
                      {ALL_OS.map((os) => (
                        <MenuItem key={os} value={os}>
                          {os}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>

                <MTCSwitch
                  id={'with_app_version'}
                  label={t('inAppNotification:with_app_version')}
                  value={state.with_app_version}
                  onChange={() => {
                    handleStatePropertyChange(
                      'with_app_version',
                      !state.with_app_version,
                    );
                  }}
                  disabled={isEditing}
                />
                {state.with_app_version
                && <>
                <Grid item xs={12}>
                  <FormControl>
                    <InputLabel
                      id="appVersionCondition-label"
                      disabled={isEditing}
                      error={errors[`app_version_condition_error`] != null}
                      required={true}
                    >
                      {t('inAppNotification:app_version_condition')}
                    </InputLabel>
                    <Select
                      className={classes.select}
                      labelId="appVersionCondition-label"
                      id="appVersionCondition"
                      multiple={false}
                      value={state.app_version_condition}
                      disabled={isEditing || !state.with_app_version}
                      onChange={event => handleStatePropertyChange('app_version_condition',
                        event.target.value,
                      )}
                      renderValue={(value) => (
                        <div key={'appVersionCondition_selected_' + value}>
                          {t(`inAppNotification:app_version_conditions.${value}`)}
                        </div>
                      )}
                      error={errors.app_version_condition_error != null}
                    >
                      {ALL_OPERATOR.map((op) => (
                        <MenuItem key={'op_' + op} value={op}>
                          {t(`inAppNotification:app_version_conditions.${op}`)}
                        </MenuItem>
                      ))}
                    </Select>
                    {errors.app_version_condition_error
                    && <FormHelperText error>
                      {errors.app_version_condition_error}
                    </FormHelperText>}
                  </FormControl>
                </Grid>
                  <MTCTextField
                    gridMD={12}
                    id={`inAppNotification:app_version`}
                    key={`app_version`}
                    disabled={isEditing || !state.with_app_version}
                    onChange={event => handleStatePropertyChange(`app_version`,
                      event.target.value,
                    )}
                    value={state.app_version}
                    required={true}
                    errorMessage={errors.app_version_error}
                  />
                </>}
              </Grid>
            </Paper>
          </Grid>
          <Grid item xs={12}>
            <Paper className={classes.paper}>
              <Grid container>
                <Grid item xs={12}>
                  <Typography
                    align="center"
                    variant="h5"
                    component="h2"
                    gutterBottom>
                    {t('inAppNotification:push')}
                  </Typography>
                </Grid>
                <MTCSwitch
                  id={'push'}
                  value={state.push}
                  onChange={() => {
                    handleStatePropertyChange(
                      'push',
                      !state.push,
                    );
                  }}
                  disabled={!state.can_push}
                />
                <Grid item xs={12}>
                  <FormControl className={classes.select}>
                    <InputLabel
                      id="push_time_label"
                      error={errors.push_time_error !== null}
                      disabled={!state.can_push || !state.push}
                    >
                      {t('push_time')}
                    </InputLabel>
                    <Select
                      id={'push_time_select'}
                      labelId={'push_time_select_label'}
                      onChange={event => {
                        handleStatePropertyChange(
                          'push_time',
                          event.target.value,
                        );
                      }}
                      value={state.push_time ? state.push_time.toString() : ''}
                      disabled={!state.can_push || !state.push}
                      error={errors.push_time_error !== null}
                    >
                      {Array.from(Array(24).keys()).map((number) =>
                        <MenuItem key={number} value={number.toString()}>
                          {t(`${number}h`)}
                        </MenuItem>,
                      )}
                    </Select>
                    <FormHelperText
                      error={errors.push_time_error != null}
                      className={classes.input}
                    >
                      {errors.push_time_error != null && errors.push_time_error}
                      {errors.push_time_error == null && t('push_time_info')}
                    </FormHelperText>
                  </FormControl>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
          <Grid item xs={12}>
            <Paper className={classes.paper}>
              <Grid container>
                <Grid item xs={12}>
                  <Typography
                    align="center"
                    variant="h5"
                    component="h2"
                    gutterBottom>
                    {t('title')}
                  </Typography>
                </Grid>
                <MTCTextField
                  gridMD={12}
                  id={`title_fr`}
                  key={`title_fr`}
                  onChange={event => handleStatePropertyChange(`title_fr`, event.target.value)}
                  value={state[`title_fr`]}
                  required={true}
                  errorMessage={errors[`title_fr_error`]}
                  onBlur={event => setState({
                    ...state,
                    ...data_languages.reduce((o, key) => (
                      {
                        ...o,
                        [`title_${key}`]: state[`title_${key}`] || event.target.value,
                      }
                    ), {}),
                  })}
                />

                {data_languages_no_fr.map(lg => (
                  <MTCTextField
                    gridMD={12}
                    id={`title_${lg}`}
                    key={`title_${lg}`}
                    onChange={event => handleStatePropertyChange(`title_${lg}`,
                      event.target.value,
                    )}
                    value={state[`title_${lg}`]}
                    required={true}
                    errorMessage={errors[`title_${lg}_error`]}
                  />
                ))}

              </Grid>
            </Paper>
          </Grid>
          <Grid item xs={12}>
            <Paper className={classes.paper}>
              <Grid container>
                <Grid item xs={12}>
                  <Typography
                    align="center"
                    variant="h5"
                    component="h2"
                    gutterBottom>
                    {t('text')}
                  </Typography>
                </Grid>

                <MTCRichText
                  id={'text_fr'}
                  required={true}
                  errorMessage={errors.text_fr_error}
                  handleChange={handleStatePropertyChange}
                  value={state.text_fr}
                  onBlur={(newText) => {
                    let newState = { ...state };
                    data_languages.forEach(lg => {
                      if (!state[`text_${lg}`].getCurrentContent().hasText()) {
                        newState[`text_${lg}`] = EditorState.createWithContent(
                          newText.getCurrentContent(),
                        );
                      }
                    });
                    setState(newState);
                  }}
                />
                {data_languages_no_fr.map(lg => (
                  <MTCRichText
                    id={`text_${lg}`}
                    key={`text_${lg}`}
                    required={true}
                    errorMessage={errors[`text_${lg}_error`]}
                    handleChange={handleStatePropertyChange}
                    value={state[`text_${lg}`]}
                  />
                ))}
              </Grid>
            </Paper>
          </Grid>
          {state.has_call_to_action
          && <Grid item xs={12}>
            <Paper className={classes.paper}>
              <Grid container>
                <Grid item xs={12}>
                  <Typography
                    align="center"
                    variant="h5"
                    component="h2"
                    gutterBottom>
                    {t('call_to_action_title')}
                  </Typography>
                </Grid>
                <MTCTextField
                  gridMD={12}
                  id={`call_to_action_title_fr`}
                  key={`call_to_action_title_fr`}
                  onChange={event => handleStatePropertyChange(`call_to_action_title_fr`,
                    event.target.value,
                  )}
                  value={state[`call_to_action_title_fr`]}
                  required={true}
                  errorMessage={errors[`call_to_action_title_fr_error`]}
                  onBlur={event => setState({
                    ...state,
                    ...data_languages.reduce((o, key) => (
                      {
                        ...o,
                        [`call_to_action_title_${key}`]: state[`call_to_action_title_${key}`]
                        || event.target.value,
                      }
                    ), {}),
                  })}
                />
                {data_languages_no_fr.map(lg => (
                  <MTCTextField
                    gridMD={12}
                    id={`call_to_action_title_${lg}`}
                    key={`call_to_action_title_${lg}`}
                    onChange={event => handleStatePropertyChange(`call_to_action_title_${lg}`,
                      event.target.value,
                    )}
                    value={state[`call_to_action_title_${lg}`]}
                    required={true}
                    errorMessage={errors[`call_to_action_title_${lg}_error`]}
                  />
                ))}
              </Grid>
            </Paper>
          </Grid>}
          {state.has_call_to_action
          && <Grid item xs={12}>
            <Paper className={classes.paper}>
              <Grid container>
                <Grid item xs={12}>
                  <Typography
                    align="center"
                    variant="h5"
                    component="h2"
                    gutterBottom>
                    {t('call_to_action_link')}
                  </Typography>
                </Grid>
                <MTCTextField
                  gridMD={12}
                  id={`call_to_action_link_fr`}
                  key={`call_to_action_link_fr`}
                  onChange={event => handleStatePropertyChange(`call_to_action_link_fr`,
                    event.target.value,
                  )}
                  value={state[`call_to_action_link_fr`]}
                  required={true}
                  errorMessage={errors[`call_to_action_link_fr_error`]}
                  onBlur={event => setState({
                    ...state,
                    ...data_languages.reduce((o, key) => (
                      {
                        ...o,
                        [`call_to_action_link_${key}`]: state[`call_to_action_link_${key}`]
                        || event.target.value,
                      }
                    ), {}),
                  })}
                />
                {data_languages_no_fr.map(lg => (
                  <MTCTextField
                    gridMD={12}
                    id={`call_to_action_link_${lg}`}
                    key={`call_to_action_link_${lg}`}
                    onChange={event => handleStatePropertyChange(`call_to_action_link_${lg}`,
                      event.target.value,
                    )}
                    value={state[`call_to_action_link_${lg}`]}
                    required={true}
                    errorMessage={errors[`call_to_action_link_${lg}_error`]}
                  />
                ))}
              </Grid>
            </Paper>
          </Grid>}
          {state.push
          && <Grid item xs={12}>
            <Paper className={classes.paper}>
              <Grid container>
                <Grid item xs={12}>
                  <Typography
                    align="center"
                    variant="h5"
                    component="h2"
                    gutterBottom>
                    {t('push_title')}
                  </Typography>
                </Grid>
                <MTCTextField
                  gridMD={12}
                  id={`push_title_fr`}
                  key={`push_title_fr`}
                  onChange={event => handleStatePropertyChange(`push_title_fr`,
                    event.target.value,
                  )}
                  value={state[`push_title_fr`]}
                  required={true}
                  errorMessage={errors[`push_title_fr_error`]}
                  onBlur={event => setState({
                    ...state,
                    ...data_languages.reduce((o, key) => (
                      {
                        ...o,
                        [`push_title_${key}`]: state[`push_title_${key}`]
                        || event.target.value,
                      }
                    ), {}),
                  })}
                />
                {data_languages_no_fr.map(lg => (
                  <MTCTextField
                    gridMD={12}
                    id={`push_title_${lg}`}
                    key={`push_title_${lg}`}
                    onChange={event => handleStatePropertyChange(`push_title_${lg}`,
                      event.target.value,
                    )}
                    value={state[`push_title_${lg}`]}
                    required={true}
                    errorMessage={errors[`push_title_${lg}_error`]}
                  />
                ))}
              </Grid>
            </Paper>
          </Grid>}
          {state.push
          && <Grid item xs={12}>
            <Paper className={classes.paper}>
              <Grid container>
                <Grid item xs={12}>
                  <Typography
                    align="center"
                    variant="h5"
                    component="h2"
                    gutterBottom>
                    {t('push_body')}
                  </Typography>
                </Grid>
                <MTCTextField
                  gridMD={12}
                  id={`push_body_fr`}
                  key={`push_body_fr`}
                  onChange={event => handleStatePropertyChange(`push_body_fr`,
                    event.target.value,
                  )}
                  value={state[`push_body_fr`]}
                  required={false}
                  errorMessage={errors[`push_body_fr_error`]}
                  onBlur={event => setState({
                    ...state,
                    ...data_languages.reduce((o, key) => (
                      {
                        ...o,
                        [`push_body_${key}`]: state[`push_body_${key}`]
                        || event.target.value,
                      }
                    ), {}),
                  })}
                />
                {data_languages_no_fr.map(lg => (
                  <MTCTextField
                    gridMD={12}
                    id={`push_body_${lg}`}
                    key={`push_body_${lg}`}
                    onChange={event => handleStatePropertyChange(`push_body_${lg}`,
                      event.target.value,
                    )}
                    value={state[`push_body_${lg}`]}
                    required={false}
                    errorMessage={errors[`push_body_${lg}_error`]}
                  />
                ))}
              </Grid>
            </Paper>
          </Grid>}
          <Grid item>
            <MTCButton
              disabled={false}
              onClick={handleSubmit}
              type={'button'}
              sending={sending}
              text={isEditing
                ? t('edit')
                : t('create')}
            />
          </Grid>
        </Grid>
      </form>
    </MuiPickersUtilsProvider>
  );
};

export default InAppNotificationForm;
