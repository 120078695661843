import { data_languages } from '../../../constants/lang';

export const INITIAL_STATE = {
  id: null,
  state: 0,
  type: 0,
  obtaining_circuit: '',
  obtaining_circuit_id: '',
  retroactive: false,
  country_codes: [],
  obtaining_type: 0,
  obtaining_needed_point: 1,
  obtaining_percent: 50,
  obtaining_max_winner: null,
  obtaining_country_codes: [],
  obtaining_environments: [],
  display_rule: 0,
  start_date: null,
  end_date: null,
  picture_to_upload: null,
  ...data_languages.reduce((o, key) => (
    {
      ...o,
      [`name_${key}`]: '',
      [`description_${key}`]: '',
    }
  ), {}),
  unlock_free_dry_boundaries: false,
};

export const INITIAL_ERROR_STATE = {
  start_date_error: null,
  end_date_error: null,
  obtaining_max_winner_error: null,
  obtaining_needed_point_error: null,
  obtaining_circuit_id_error: null,
  obtaining_percent_error: null,
  picture_error: null,
  ...data_languages.reduce((o, key) => (
    {
      ...o,
      [`name_${key}_error`]: null,
      [`description_${key}_error`]: null,
    }
  ), {}),
};

export const stateWithChallenge = (challenge) => {
  return {
    ...INITIAL_STATE,
    id: challenge.id,
    state: challenge.state,
    type: challenge.type,
    obtaining_circuit_id: challenge.obtaining_circuit_id,
    country_codes: challenge.country_codes
      ? challenge.country_codes.split(',')
      : [],
    obtaining_type: challenge.obtaining_type,
    obtaining_needed_point: challenge.obtaining_needed_point,
    obtaining_max_winner: challenge.obtaining_max_winner,
    obtaining_percent: challenge.obtaining_percent,
    obtaining_country_codes: challenge.obtaining_country_codes
      ? challenge.obtaining_country_codes.split(',')
      : [],
    obtaining_environments: challenge.obtaining_environments
      ? challenge.obtaining_environments.split(',')
      : [],
    display_rule: challenge.display_rule,
    start_date: challenge.start_date
      ? new Date(challenge.start_date * 1000)
      : null,
    end_date: challenge.end_date
      ? new Date(challenge.end_date * 1000)
      : null,
    picture_to_upload: null,
    ...data_languages.reduce((o, key) => (
      {
        ...o,
        [`name_${key}`]: (
          challenge.translations[key] && challenge.translations[key].name
        ) || '',
        [`description_${key}`]: (
          challenge.translations[key] && challenge.translations[key].description
        ) || '',
      }
    ), {}),
    unlock_free_dry_boundaries: challenge.unlock_free_dry_boundaries,
  };
};
