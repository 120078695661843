export const columns = [
  {
    id: 'translations.fr.title',
    numeric: false,
    disablePadding: false,
    label: 'title',
  },
  {
    id: 'start_date',
    numeric: false,
    disablePadding: false,
  },
  {
    id: 'end_date',
    numeric: false,
    disablePadding: false,
  },
  {
    id: 'state',
    numeric: false,
    disablePadding: false,
  },
  {
    numeric: false,
    disablePadding: false,
  },
];
export const defaultOrderBy = 'translations.fr.title';
