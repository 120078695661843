import {
  DEFAULT_CONTACTS_SENDING,
  DEFAULT_CONTACTS_DOWNLOADED,
  DEFAULT_CONTACTS_FAILED,
  DEFAULT_CONTACTS_PENDING,
} from './types';
import ApiService from '../../services/ApiService';
import { handleError } from '../infos/actions';

export const defaultContactsDownloadedAction = contacts => (
  {
    type: DEFAULT_CONTACTS_DOWNLOADED,
    payload: contacts,
  }
);

export const defaultContactsFailedAction = error => (
  {
    type: DEFAULT_CONTACTS_FAILED,
    payload: error,
  }
);

export const defaultContactsPendingAction = () => (
  {
    type: DEFAULT_CONTACTS_PENDING,
  }
);

export const defaultContactSendingAction = () => (
  {
    type: DEFAULT_CONTACTS_SENDING,
  }
);

export function getDefaultContacts() {
  return (dispatch) => {
    dispatch(defaultContactsPendingAction());
    return ApiService
      .getDefaultContacts()
      .then((data) => {
        dispatch(defaultContactsDownloadedAction(data));
      })
      .catch((error) => {
        dispatch(defaultContactsFailedAction(error));
        dispatch(handleError(error));
      });
  };
}

export function putDefaultContacts(contacts) {
  return (dispatch) => {
    dispatch(defaultContactSendingAction());
    return ApiService
      .putDefaultContacts(contacts)
      .then((data) => {
        dispatch(defaultContactsDownloadedAction(data));
      })
      .catch((error) => {
        dispatch(defaultContactsFailedAction(error));
        dispatch(handleError(error));
      });
  };
}