import React, { useState } from 'react';
import { useHistory } from 'react-router';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import AppBar from '@material-ui/core/AppBar';
import CatalogsItemsTable from './CatalogsItemsTable';
import { useStyles } from './CatalogsItemsTabs.style';

export const CatalogsItemsTabs = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const classes = useStyles();
  const [value, setValue] = useState(0);
  const [locationStateMatch, setLocationStateMatch] = useState(true);
  const {
    pdfItems,
    youtubeItems,
    pending
  } = useSelector(state => state.catalogsItems);
  if (
    history.location
    && history.location.state
    && locationStateMatch
  ) {
    if (history.location.state.value) {
      setValue(history.location.state.value);
    }
    setLocationStateMatch(false);
  }

  const handleChange = (event, value) => {
    setValue(value);
  };

  return (
    <div className={classes.root}>
      <AppBar position={'static'}>
        <Tabs
          value={value}
          onChange={handleChange}
          variant={'scrollable'}
          scrollButtons={'on'}
        >
          <Tab label={t('catalog_item_types.1')} />
          <Tab label={t('catalog_item_types.0')} />
        </Tabs>
      </AppBar>
      {value === 0 && <CatalogsItemsTable content={youtubeItems} pending={pending} />}
      {value === 1 && <CatalogsItemsTable content={pdfItems} pending={pending} />}
    </div>
  );
};

export default React.memo(CatalogsItemsTabs);
