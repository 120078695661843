import React from 'react';
import { useTheme } from '@material-ui/core';
import { useSelector } from 'react-redux';
import Drawer from '@material-ui/core/Drawer';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import NavigationClose from '@material-ui/icons/Close';
import Toolbar from '@material-ui/core/Toolbar';
import { Link, useHistory } from 'react-router-dom';
import MenuItem from '@material-ui/core/MenuItem';
import { useStyles } from './CircuitAlertMenu.style';
import { TRACK } from '../../../../constants/routes';
import { RedChip } from '../../RedChip/RedChip';

export const CircuitAlertMenu = (props) => {
  const { drawerOpen, handleCloseDrawer } = props;
  const history = useHistory();
  const theme = useTheme();
  const classes = useStyles(theme);
  const home = useSelector(state => state.home);

  const handleClick = (id) => {
    history.push(TRACK.replace(':trackId', id));
    handleCloseDrawer();
  }

  return (
    <div className={classes.menu}>
        <Drawer
          variant="temporary"
          open={drawerOpen}
          onClose={handleCloseDrawer}
          anchor={'right'}
          classes={{
            paper: classes.drawerPaper,
          }}
          className={classes.root}
          ModalProps={{ keepMounted: true }}
        >
          <Toolbar
            className={classes.toolbar}
          >
            <IconButton
              onClick={handleCloseDrawer}
              className={classes.closeButton}
            >
              <NavigationClose/>
            </IconButton>
            <Typography variant={'subtitle1'}>
              Notification
            </Typography>
          </Toolbar>
          <Divider/>
          {home.reportedCircuitComment.map(item => (
            <MenuItem
              id={`menu_item_circuit_alert_${item.id}`}
              key={`menu_item_circuit_alert_${item.id}`}
              component={Link}
              to={'#'}
              selected={false}
              disabled={false}
              onClick={() => handleClick(item.id)}
            >
              <Typography variant={'subtitle1'}>
                {item.name} {item.count > 0 && (<>{' '}<RedChip count={item.count} /></>)}
              </Typography>
            </MenuItem>
          ))}
        </Drawer>
    </div>
  );
}

export default React.memo(CircuitAlertMenu);
