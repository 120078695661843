import makeStyles from '@material-ui/core/styles/makeStyles';
import { michelin_black } from '../../../constants/michelin_colors';
import { inputStyle } from '../../../constants/styles/input.style';

export const useStyles = makeStyles((theme) => {
  return {
    container: {
      width: '100%',
      maxWidth: '991px',
      margin: 'auto',
      [theme.breakpoints.down('sm')]: {
        marginBottom: '4em',
      },
      [theme.breakpoints.up('xl')]: {
        maxWidth: '1500px',
      }
    },
    information: {
      //padding: '5px',
      width: '100%',
      display: 'flex',
      flex: 'auto',
      marginBottom: '20px',
      marginTop: '20px',
      flexDirection: 'column',
      alignItems: 'center',
      textAlign: 'center',
      padding: '20px 28px',
    },
    text: {
      marginTop: '10px',
      textAlign: 'right',
      width: '100%',
      maxWidth: '500px',
      margin: 'auto',
      padding: '5px',
      whiteSpace: 'break-spaces',
    },
    message: {
      marginTop: '10px',
      textAlign: 'left',
      width: '100%',
      margin: 'auto',
      padding: '5px',
    },
    textLabel: {
      float: 'left',
    },
    title: {
      margin: '10px',
      color: michelin_black,
    },
    button: {
      borderRadius: '2em',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      marginTop: '5px',
      marginBottom: '5px',
    },
    a: {
      textDecoration: 'none',
      color: 'inherit',
    },
    input: {
      ...inputStyle(theme),
      color: theme.palette.textColor,
      backgroundColor: theme.palette.alternateTextColor,
      marginTop: '0 !important',
    },
  };
});
