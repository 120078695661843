const CARS_BRANDS_PENDING = 'search_cars_brands_pending';
const CARS_RANGES_PENDING = 'search_cars_ranges_pending';
const CARS_MODELS_PENDING = 'search_cars_models_pending';
const CARS_ENGINES_PENDING = 'search_cars_engines_pending';
const CARS_DIMENSIONS_PENDING = 'search_cars_dimensions_pending';
const CARS_BOUNDARIES_PENDING = 'search_cars_boundaries_pending';
const CARS_BRANDS_DOWNLOADED = 'search_cars_brands_downloaded';
const CARS_RANGES_DOWNLOADED = 'search_cars_ranges_downloaded';
const CARS_MODELS_DOWNLOADED = 'search_cars_models_downloaded';
const CARS_ENGINES_DOWNLOADED = 'search_cars_engines_downloaded';
const CARS_DIMENSIONS_DOWNLOADED = 'search_cars_dimensions_downloaded';
const CARS_BOUNDARIES_DOWNLOADED = 'search_cars_boundaries_downloaded';
const CARS_CSV_DOWNLOADED = 'search_cars_csv_downloaded';
const CAR_PENDING = 'search_car_pending';
const CAR_FAILED = 'search_car_failed';
const CAR_RESET = 'search_car_reset';
const CAR_TABLE_LIMIT_CHANGE = 'search_car_table_limit_change';
const CHANGE_SEARCH_MODE = 'change_search_mode';
const CHANGE_SEARCH_TIRE_TYPE = 'change_search_tire_type';
const CHANGE_SEARCH_IS_PUBLIC = 'change_search_is_public';
const CHANGE_SEARCH_CAR_STATE = 'change_search_car_state';

export {
  CARS_BRANDS_PENDING,
  CARS_RANGES_PENDING,
  CARS_MODELS_PENDING,
  CARS_ENGINES_PENDING,
  CARS_DIMENSIONS_PENDING,
  CARS_BOUNDARIES_PENDING,
  CARS_BRANDS_DOWNLOADED,
  CARS_RANGES_DOWNLOADED,
  CARS_MODELS_DOWNLOADED,
  CARS_ENGINES_DOWNLOADED,
  CARS_DIMENSIONS_DOWNLOADED,
  CARS_BOUNDARIES_DOWNLOADED,
  CARS_CSV_DOWNLOADED,
  CAR_PENDING,
  CAR_FAILED,
  CAR_RESET,
  CAR_TABLE_LIMIT_CHANGE,
  CHANGE_SEARCH_MODE,
  CHANGE_SEARCH_TIRE_TYPE,
  CHANGE_SEARCH_IS_PUBLIC,
  CHANGE_SEARCH_CAR_STATE
};
