import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import Grid from '@material-ui/core/Grid';
import { CALL_CENTERS } from '../../constants/routes';
import { putCallCenter } from '../../store/callCenters/actions';
import MTCTextField from '../Form/MTCTextField';
import MTCButton from '../Layout/Button/MTCButton';
import { useStyles } from './EditCallCenterForm.style';
import { ERROR_STATE, INITIAL_STATE, language } from './EditCallCenterForm.const';

const countries = require('country-list')();

const EditCallCenterForm = ({ callcenter }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const [state, setState] = useState(INITIAL_STATE);
  const [errors, setErrors] = useState(ERROR_STATE);

  if (callcenter && state.id === '' && callcenter.id !== state.id) {
    setState(callcenter);
  }

  const onSubmit = () => {
    if (state.phone.length < 1 || state.phone.length > 30) {
      setErrors({ phoneError: t('error.phone_error') });
    } else {
      dispatch(putCallCenter(state)).then(() => {
          history.push(CALL_CENTERS);
        },
      );
    }
  };

  const onChangePhone = (event) => {
    if (event.target.value.length < 31) {
      let newState = { ...state };
      newState.phone = event.target.value;
      setState(newState);
      setErrors({ phoneError: null });
    }
  };

  const onChangeCountryCode = (event) => {
    let newState = { ...state };
    newState.country_code = event.target.value;
    setState(newState);
  };

  const onChangeLang = (event) => {
    let newState = { ...state };
    newState.lang = event.target.value;
    setState(newState);
  };

  const onChangeOpening = (event) => {
    let newState = { ...state };
    newState.opening = event.target.value;
    setState(newState);
  };

  return (
    <form>

      <Grid container align="center" spacing={2}>
        <Grid item xs={12} className={classes.grid}>
          <TextField
            className={classes.textField}
            id="country_code"
            onChange={onChangeCountryCode}
            InputProps={{
              className: classes.input,
            }}
            label={t('country_code')}
            placeholder={t('country_code')}
            SelectProps={{
              MenuProps: {
                className: classes.menu,
              },
            }}
            value={state.country_code}
            select={true}
            disabled={true}
            required={true}
            InputLabelProps={{
              className: classes.selectLabel,
            }}
          >
            {countries.getData().map(country => (
              <MenuItem key={country.code} value={country.code}>
                {country.code} - {country.name}
              </MenuItem>
            ))}
          </TextField>
        </Grid>

        <Grid item xs={12} className={classes.grid}>
          <TextField
            className={classes.textField}
            id="lang"
            onChange={onChangeLang}
            InputProps={{
              className: classes.input,
            }}
            label={t('lang')}
            placeholder={t('lang')}
            SelectProps={{
              MenuProps: {
                className: classes.menu,
              },
            }}
            value={state.lang}
            select
            required={true}
            InputLabelProps={{
              className: classes.selectLabel,
            }}
          >
            {language.map(l => (
              <MenuItem key={l} value={l}>
                {l} - {t('lang:' + l)}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={12} className={classes.grid}>
          <MTCTextField
            id={'phone'}
            onChange={onChangePhone}
            value={state.phone}
            required={true}
            errorMessage={errors.phoneError}
          />
        </Grid>

        <Grid item xs={12} className={classes.grid}>
          <TextField
            className={classes.textField}
            id="opening"
            onChange={onChangeOpening}
            InputProps={{
              className: classes.input,
            }}
            label={t('opening')}
            placeholder={t('opening')}
            SelectProps={{
              MenuProps: {
                className: classes.menu,
              },
            }}
            value={state.opening}
            select
            required={true}
            InputLabelProps={{
              className: classes.selectLabel,
            }}
          >
            {[0, 1, 2].map(value => (
              <MenuItem key={value} value={value}>
                {t('opening-' + value)}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={12} className={classes.grid}>
          <MTCButton
            text={t('edit')}
            type={'button'}
            onClick={onSubmit}
          />
        </Grid>
      </Grid>
    </form>
  );
};

export default EditCallCenterForm;
