import makeStyles from '@material-ui/core/styles/makeStyles';
import { clickableRowStyle } from '../../../constants/styles/row.style';

export const useStyles = makeStyles(theme => {
  return {
    row: clickableRowStyle(theme),
    tooltip: {
      width: 300,
    },
    button: {
      color: 'inherit',
    },
  };
});
