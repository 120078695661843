import React from 'react';
import { useTranslation } from 'react-i18next';
import Timestamp from 'react-timestamp';
import { useSelector } from 'react-redux';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import useTheme from '@material-ui/core/styles/useTheme';
import MTCChip from '../../Layout/MTCChip';
import { useStyles } from './ChallengeDetails.style';
import MTCDetailLabel from '../../Layout/MTCDetailLabel';

const ChallengeDetails = ({ challenge, circuit }) => {

  const theme = useTheme();
  const { t } = useTranslation();
  const classes = useStyles(theme);
  const { lang } = useSelector(state => state.contentLang);

  const expired = challenge.type == 1 && challenge.end_date < Math.floor(Date.now() / 1000);

  const tr = challenge.translations[lang];
  return (
    <Grid
      container
      direction={'column'}
      spacing={0}
      className={classes.container}
    >
      <Grid item xs={12}>
        <Avatar alt={'challenge picture'} src={challenge.picture} className={classes.avatar} />
      </Grid>
      <Grid item xs={12}>
        <Typography
          component="p"
          variant="h3"
          className={classes.title}
        >
          {tr && tr.name}
          {' '}
          <MTCChip
            text={
              expired
                ? t('challenge_state.expired')
                : t(`challenge_state.${challenge.state}`)
            }
            error={challenge.state === 2 || expired}
            success={challenge.state === 1 && !expired}
          />
        </Typography>
        <Typography
          component="p"
          variant="subtitle1"
          className={classes.description}
        >
          {tr && tr.description}
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <Grid container spacing={2} alignItems={'flex-start'} justifyContent={'center'}>
          <Grid item xs={12} md={6}>
            <Paper className={classes.information}>
              <Typography
                component="p"
                variant="h4"
                color={'primary'}
                className={classes.title}
              >
                {t('general_infos')}
              </Typography>
              <MTCDetailLabel label={'Type'} value={t('challenge_types.' + challenge.type)} />
              <MTCDetailLabel
                label={t('obtaining_type')}
                value={t('challenge_obtaining_types.' + challenge.obtaining_type)} />
              <MTCDetailLabel
                label={t('obtaining_environments')}
                value={
                  challenge.obtaining_environments === null
                    ? t('all_mode')
                    : challenge.obtaining_environments.split(',').map(oe => {
                      return t(`challenge_environments.${oe}`) + ' ';
                    })}
              />
              {challenge.obtaining_circuit_id
              && <MTCDetailLabel
                label={t('obtaining_circuit_id')}
                value={
                  circuit
                    ? circuit.translations[lang].name
                    : t('loading')
                }
              />}
              <MTCDetailLabel
                label={t('obtaining_needed_point')}
                value={challenge.obtaining_needed_point} />
              {challenge.obtaining_type === 10
              && <MTCDetailLabel
                label={t('obtaining_percent')}
                value={challenge.obtaining_percent} />
              }
              <MTCDetailLabel
                label={t('obtaining_country_codes')}
                value={challenge.obtaining_country_codes || t('all_mode')} />
              <MTCDetailLabel
                label={t('obtaining_max_winner')}
                value={challenge.obtaining_max_winner !== null
                  ? challenge.obtaining_max_winner
                  : t('no')} />
              <MTCDetailLabel
                label={t('display_rule')}
                value={t(`challenge_display_rule.${challenge.display_rule}`)} />
              {(
                challenge.obtaining_type === 0 || challenge.obtaining_type === 3
              )
              && <MTCDetailLabel
                label={t('unlock_free_dry_boundaries')}
                value={challenge.unlock_free_dry_boundaries
                  ? t('yes')
                  : t('no')} />
              }
            </Paper>
          </Grid>
          {challenge.type !== 0
          && <Grid item xs={12} md={6}>
            <Paper className={classes.information}>
              <Typography
                component="p"
                variant="h4"
                color={'primary'}
                className={classes.title}
              >
                Date
              </Typography>
              <MTCDetailLabel
                label={t('start_date')}
                value={
                  challenge.start_date !== null
                    ? <Timestamp
                      format="full"
                      twentyFourHour
                      time={challenge.start_date}
                    />
                    : '-'
                } />
              <MTCDetailLabel
                label={t('end_date')}
                value={
                  challenge.end_date !== null
                    ? <Timestamp
                      format="full"
                      twentyFourHour
                      time={challenge.end_date}
                    />
                    : '-'
                } />

            </Paper>
          </Grid>}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ChallengeDetails;
