import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputAdornment from '@material-ui/core/InputAdornment';
import Tooltip from '@material-ui/core/Tooltip';
import List from '@material-ui/icons/List';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Paper from '@material-ui/core/Paper';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import DialogActions from '@material-ui/core/DialogActions';
import {
  formReset,
  getBrandsCars,
  getEnginesCars,
  getModelsCars,
  getRangesCars,
  postCar,
} from '../../../store/formCars/actions';
import {
  getBrandsCars as getTableBrandsCars,
  getDimensionsCars as getTableDimensionsCars,
  getEnginesCars as getTableEnginesCars,
  getModelsCars as getTableModelsCars,
  getRangesCars as getTableRangesCars,
} from '../../../store/searchCars/actions';
import { ORIGIN, WEATHER_TYPE } from '../../../store/formCars/types';
import CarFormatter from '../../../services/CarFormatter';
import MTCSwitch from '../../Form/MTCSwitch';
import MTCTextField from '../../Form/MTCTextField';
import MTCButton from '../../Layout/Button/MTCButton';
import BoundaryForm from './BoundaryForm';
import BoundaryRangeIndicator from './BoundaryRangeIndicator';
import { CARS } from '../../../constants/routes';
import { getTableCars } from '../../../store/tableCars/actions';
import MTCLoader from '../../Layout/MTCLoader';
import { PSC2, PSC2R } from '../../../constants/raceTireType';
import {
  emptyBoundaries,
  emptyDimension,
  expertDimensionWithoutBoundary,
  hasEmptyBoundaries,
  hasLessThanBoundaries,
  isTheSame,
} from './CarFormUtils';
import { handleError } from '../../../store/infos/actions';
import { useStyles } from './AddCarForm.style';
import { INITIAL_STATE, ORDERS, stateToApiFormat } from './AddCarForm.const';

const AddCarForm = ({ duplicatedCar }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const history = useHistory();

  const [tireTypeTab, setTireTypeTab] = useState(0);
  const [state, setState] = useState({
    ...INITIAL_STATE,
    dimension: emptyDimension(),
    boundaries: emptyBoundaries(WEATHER_TYPE, [PSC2, PSC2R]),
  });

  const {
    brands,
    brands_pending,
    ranges,
    ranges_pending,
    models,
    models_pending,
    engines,
    engines_pending,
    error,
    sending,
  } = useSelector(state => state.formCars);
  const dispatch = useDispatch();

  if (duplicatedCar && !state.considerDuplicatedCar) {
    const availableTireTypes = duplicatedCar.dimension.available_tire_types.split(',');
    let newBoundaries = duplicatedCar.boundaries.length > 0
      ? duplicatedCar.boundaries.map(b => {
        return {
          red_orange: b.red_orange,
          orange_green: b.orange_green,
          green_orange: b.green_orange,
          orange_red: b.orange_red,
          is_front: b.is_front,
          weather_type: b.weather_type,
          tire_type: b.tire_type,
        };
      })
      : emptyBoundaries(WEATHER_TYPE, [PSC2, PSC2R]);
    if (newBoundaries.length < 20 && !availableTireTypes.includes(PSC2)) {
      newBoundaries = newBoundaries.concat(emptyBoundaries(WEATHER_TYPE, [PSC2]));
    }
    if (newBoundaries.length < 20 && !availableTireTypes.includes(PSC2R)) {
      newBoundaries = newBoundaries.concat(emptyBoundaries(WEATHER_TYPE, [PSC2R]));
    }
    setState({
      ...state,
      withDimension: true,
      brandId: duplicatedCar.brand.id,
      newBrand: false,
      rangeId: duplicatedCar.range.id,
      newRange: false,
      waitingRange: false,
      modelId: duplicatedCar.range_model.id,
      newModel: false,
      waitingModel: false,
      engineId: duplicatedCar.engine.id,
      newEngine: false,
      waitingEngine: false,
      waitingDimension: false,
      dimension: {
        ...emptyDimension(),
        front_dimension: duplicatedCar.dimension.front_dimension,
        front_pressure: duplicatedCar.dimension.front_pressure,
        rear_dimension: duplicatedCar.dimension.rear_dimension,
        rear_pressure: duplicatedCar.dimension.rear_pressure,
        compatible_with_receiver: duplicatedCar.dimension.compatible_with_receiver,
        is_public: duplicatedCar.dimension.is_public,
        origin: duplicatedCar.dimension.origin,
        data_version: duplicatedCar.dimension.data_version,
        available_tire_types: availableTireTypes,
        has_psc2: availableTireTypes.includes(PSC2),
        has_psc2r: availableTireTypes.includes(PSC2R),
      },
      boundaries: newBoundaries,
      considerDuplicatedCar: true,
    });
  }

  if (!duplicatedCar && !brands && !brands_pending && !error) {
    dispatch(getBrandsCars());
  }

  const callApi = () => {
    dispatch(postCar(stateToApiFormat(state))).then(async (data) => {
      await dispatch(getTableBrandsCars());
      await dispatch(getTableRangesCars(data.brand.id));
      await dispatch(getTableModelsCars(data.brand.id, data.range.id));
      await dispatch(getTableEnginesCars(data.brand.id, data.range.id, data.range_model.id));
      await dispatch(getTableDimensionsCars(data.brand.id,
        data.range.id,
        data.range_model.id,
        data.engine.id,
      ));
      // eslint-disable-next-line max-len
      let params = `brandId=${data.brand.id}&rangeId=${data.range.id}&modelId=${data.range_model.id}&engineId=${data.engine.id}&limit=50&page=0&order=ASC&orderBy=0`;
      await dispatch(getTableCars(params));
      await dispatch(formReset());
      history.push({
        pathname: CARS,
        state: {
          value: 0,
        },
      });
    });
  };

  const onSubmit = (event) => {
    event.preventDefault();
    if (
      hasLessThanBoundaries(state)
      || isTheSame(state, duplicatedCar)
      || expertDimensionWithoutBoundary(state)
    ) {
      setState({
        ...state,
        confirmDialogOpen: true,
      });
    } else {
      const missingBoundaries = hasEmptyBoundaries(state);
      if (state.withDimension && missingBoundaries.length > 0) {
       if (missingBoundaries.includes(PSC2)) {
         setTireTypeTab(0)
       } else {
         if (state.dimension.has_psc2) {
           setTireTypeTab(1);
         } else {
           setTireTypeTab(0);
         }
       }
        const element = document.getElementById('tire-type-tabs');
        if (element) {
          element.scrollIntoView();
        }
        dispatch(handleError({message:t("missing_boundary_values")}))
      } else {
        callApi();
      }
    }
  };

  const onChange = (event, stateKey) => {
    const newStateId = event.target.value;
    const currentOrder = ORDERS.indexOf(stateKey);
    if (newStateId === 'new') {
      setState({
        ...state,
        withDimension: false,
        newBrand: (
          currentOrder === 0
            ? true
            : state.newBrand
        ),
        waitingRange: currentOrder < 1,
        newRange: (
          currentOrder <= 1
            ? true
            : state.newBrand || state.newRange
        ),
        waitingModel: currentOrder < 2,
        newModel: (
          currentOrder <= 2
            ? true
            : state.newBrand || state.newRange || state.newModel
        ),
        waitingEngine: currentOrder < 3,
        newEngine: (
          currentOrder <= 3
            ? true
            : state.newBrand || state.newRange || state.newModel || state.newEngine
        ),
      });
    } else if (stateKey === 'brand') {
      setState({
        ...state,
        brandId: event.target.value,
        newBrand: false,
        rangeId: '',
        modelId: '',
        engineId: '',
        rangeValue: '',
        modelValue: '',
        engineValue: '',
        waitingRange: false,
        waitingModel: true,
        waitingEngine: true,
        waitingDimension: true,
      });
      dispatch(getRangesCars(event.target.value));
    } else if (stateKey === 'range') {
      setState({
        ...state,
        rangeId: event.target.value,
        newRange: false,
        modelId: '',
        engineId: '',
        modelValue: '',
        engineValue: '',
        waitingModel: false,
        waitingEngine: true,
        waitingDimension: true,
      });
      dispatch(getModelsCars(state.brandId, event.target.value));
    } else if (stateKey === 'model') {
      setState({
        ...state,
        modelId: event.target.value,
        newModel: false,
        engineId: '',
        engineValue: '',
        waitingEngine: false,
        waitingDimension: true,
      });
      dispatch(getEnginesCars(state.brandId, state.rangeId, event.target.value));
    } else if (stateKey === 'engine') {
      setState({
        ...state,
        engineId: event.target.value,
        newEngine: false,
        engineValue: '',
        waitingDimension: false,
      });
    }
  };

  const handleBrandListClick = (event) => {
    event.stopPropagation();
    setState({
      ...state,
      ...INITIAL_STATE,
      considerDuplicatedCar: state.considerDuplicatedCar,
    });
  };

  const handleRangeListClick = (event) => {
    event.stopPropagation();
    setState({
      ...state,
      ...INITIAL_STATE,
      considerDuplicatedCar: state.considerDuplicatedCar,
      brandId: state.brandId,
      brandValue: state.brandValue,
      newBrand: state.newBrand,
      waitingRange: false,
    });
  };

  const handleModelListClick = (event) => {
    event.stopPropagation();
    setState({
      ...state,
      ...INITIAL_STATE,
      considerDuplicatedCar: state.considerDuplicatedCar,
      brandId: state.brandId,
      brandValue: state.brandValue,
      newBrand: state.newBrand,
      rangeId: state.rangeId,
      rangeValue: state.rangeValue,
      newRange: state.newRange,
      waitingRange: state.waitingRange,
      waitingModel: false,
    });
  };

  const handleEngineListClick = (event) => {
    event.stopPropagation();
    setState({
      ...state,
      ...INITIAL_STATE,
      considerDuplicatedCar: state.considerDuplicatedCar,
      brandId: state.brandId,
      brandValue: state.brandValue,
      newBrand: state.newBrand,
      rangeId: state.rangeId,
      rangeValue: state.rangeValue,
      newRange: state.newRange,
      waitingRange: state.waitingRange,
      modelId: state.modelId,
      modelValue: state.modelValue,
      newModel: state.newModel,
      waitingModel: state.waitingModel,
      waitingEngine: false,
    });
  };

  const handleCloseCancel = () => {
    setState({
      ...state,
      confirmDialogOpen: false,
    });
  };

  const handleCloseConfirm = () => {
    setState({
      ...state,
      confirmDialogOpen: false,
    });
    const missingBoundaries = hasEmptyBoundaries(state);
    if (missingBoundaries.length > 0) {
      if (missingBoundaries.includes(PSC2)) {
        setTireTypeTab(0)
      } else {
        if (state.dimension.has_psc2) {
          setTireTypeTab(1);
        } else {
          setTireTypeTab(0);
        }
      }
      const element = document.getElementById('tire-type-tabs');
      if (element) {
        element.scrollIntoView();
      }
      dispatch(handleError({message:t("missing_boundary_values")}))
    } else {
      callApi();
    }

  };

  const handleTireTypeTabChange = (event, newValue) => {
    event.stopPropagation();
    setTireTypeTab(newValue);
  };

  const onBoundaryValueChange = (isFront, weatherType, tireType, key, value) => {
    let boundaries = state.boundaries;
    if (!boundaries) {
      boundaries = emptyBoundaries(WEATHER_TYPE, [PSC2, PSC2R]);
    }
    let boundary = boundaries.find(stateBoundary => (
      stateBoundary.weather_type === weatherType
      && stateBoundary.is_front === isFront
      && stateBoundary.tire_type === tireType
    ));
    if (boundary) {
      setState({
          ...state,
          boundaries:
            [
              ...boundaries.filter(stateBoundary => (
                stateBoundary.weather_type !== boundary.weather_type
                || stateBoundary.is_front !== boundary.is_front
                || stateBoundary.tire_type !== boundary.tire_type
              )),
              {
                ...boundary,
                [key]: value,
              },
            ],
        },
      );
    }
  };

  const isInvalid = (
      state.withDimension && (
        (
          !state.brandId && !state.brandValue
        )
        || (
          !state.rangeId && !state.rangeValue
        )
        || (
          !state.modelId && !state.modelValue
        )
        || (
          !state.engineId && !state.engineValue
        )
      )
    )
    || sending
    || state.waitingEngine
    || (
      !state.withDimension && (
        state.engineValue == null || state.engineValue.trim() == ''
      )
    );

  if (duplicatedCar && !state.considerDuplicatedCar) {
    return <MTCLoader />;
  } else {
    return (
      <form onSubmit={onSubmit} className={classes.form}>
        <Grid
          container
          direction={'column'}
          justifyContent={'center'}
          alignItems={'center'}
          spacing={2}
        >

          {/*  BRAND  */}
          <Grid item xs={12} sm={12}>
            <Typography
              color="primary"
              variant="subtitle1"
              gutterBottom>
              {t('brand.name')}
            </Typography>
            {!state.newBrand &&
            <Select
              id={'brand.name'}
              value={brands
                ? state.brandId
                : ''}
              onChange={event => onChange(event, 'brand')}
              className={classes.input}
            >
              <MenuItem value="" className={classes.emptyOption} />
              <MenuItem value="new">
                <em>{t('add_new')}</em>
              </MenuItem>
              {!brands_pending && brands &&
              brands.map(item => (
                <MenuItem
                  key={item.id}
                  value={item.id}
                >
                  {item['name']}
                </MenuItem>
              ))
              }
            </Select>}
            {state.newBrand &&
            <MTCTextField
              id={'brand.name'}
              onChange={event => setState({
                ...state,
                brandValue: CarFormatter.formatValue('name', event.target.value),
                waitingRange: event.target.value.trim() === '',
                waitingModel: state.rangeValue.trim() === '' || event.target.value.trim() === '',
                waitingEngine: state.modelValue.trim() === ''
                  || state.rangeValue.trim() === ''
                  || event.target.value.trim() === '',
              })}
              value={state.brandValue}
              type={'text'}
              noLabel
              require={true}
              InputProps={{
                className: classes.input,
                startAdornment: (
                  <InputAdornment
                    position="start"
                    onClick={handleBrandListClick}
                  >
                    <Tooltip title={t('show_list_items')}>
                      <List />
                    </Tooltip>
                  </InputAdornment>
                ),
              }}
            />
            }
          </Grid>

          {/*  RANGE  */}
          <Grid item xs={12} sm={12}>
            <Typography
              color="primary"
              variant="subtitle1"
              gutterBottom>
              {t('range.name')}
            </Typography>
            {state.waitingRange &&
            <Typography variant="subtitle1" gutterBottom>
              {t('select_field')} : {t('brand_id')}
            </Typography>
            }
            {!state.newRange && !state.waitingRange &&
            <Select
              id={'range.name'}
              value={ranges
                ? state.rangeId
                : ''}
              onChange={event => onChange(event, 'range')}
              className={classes.input}
            >
              <MenuItem value="" className={classes.emptyOption} />
              <MenuItem value="new">
                <em>{t('add_new')}</em>
              </MenuItem>
              {!ranges_pending && ranges &&
              ranges.map(item => (
                <MenuItem
                  key={item.id}
                  value={item.id}
                >
                  {item['name']}
                </MenuItem>
              ))
              }
            </Select>}
            {state.newRange && !state.waitingRange &&
            <MTCTextField
              id={'range.name'}
              onChange={event => setState({
                ...state,
                rangeValue: CarFormatter.formatValue('name', event.target.value),
                waitingModel: event.target.value.trim() === '',
                waitingEngine: state.modelValue.trim() === '' || event.target.value.trim() === '',
              })}
              value={state.rangeValue}
              type={'text'}
              noLabel
              require={true}
              InputProps={{
                className: classes.input,
                startAdornment: !state.newBrand &&
                  <InputAdornment
                    position="start"
                    onClick={handleRangeListClick}
                  >
                    <Tooltip title={t('show_list_items')}>
                      <List />
                    </Tooltip>
                  </InputAdornment>,
              }}
            />
            }
          </Grid>

          {/*  MODEL  */}
          <Grid item xs={12} sm={12}>
            <Typography
              color="primary"
              variant="subtitle1"
              gutterBottom>
              {t('model.name')}
            </Typography>
            {state.waitingModel &&
            <Typography variant="subtitle1" gutterBottom>
              {t('select_field')} : {t('range_id')}
            </Typography>
            }
            {!state.newModel && !state.waitingModel &&
            <Select
              id={'model.name'}
              value={models
                ? state.modelId
                : ''}
              onChange={event => onChange(event, 'model')}
              className={classes.input}
            >
              <MenuItem value="" className={classes.emptyOption} />
              <MenuItem value="new">
                <em>{t('add_new')}</em>
              </MenuItem>
              {!models_pending && models &&
              models.map(item => (
                <MenuItem
                  key={item.id}
                  value={item.id}
                >
                  {item['name']}
                </MenuItem>
              ))
              }
            </Select>}
            {state.newModel && !state.waitingModel &&
            <MTCTextField
              id={'model.name'}
              onChange={event => setState({
                ...state,
                modelValue: CarFormatter.formatValue('name', event.target.value),
                waitingEngine: event.target.value.trim() === '',
              })}
              value={state.modelValue}
              type={'text'}
              noLabel
              require={true}
              InputProps={{
                className: classes.input,
                startAdornment:
                  !state.newRange && <InputAdornment
                    position="start"
                    onClick={handleModelListClick}
                  >
                    <Tooltip title={t('show_list_items')}>
                      <List />
                    </Tooltip>
                  </InputAdornment>,
              }}
            />}
          </Grid>

          {/*  ENGINE  */}
          <Grid item xs={12} sm={12}>
            <Typography
              color="primary"
              variant="subtitle1"
              gutterBottom>
              {t('engine.name')}
            </Typography>
            {state.waitingEngine &&
            <Typography variant="subtitle1" gutterBottom>
              {t('select_field')} : {t('range_model_id')}
            </Typography>
            }
            {!state.newEngine && !state.waitingEngine
            && <Select
              id={'range.name'}
              value={engines
                ? state.engineId
                : ''}
              onChange={event => onChange(event, 'engine')}
              className={classes.input}
            >
              <MenuItem value="" className={classes.emptyOption} />
              <MenuItem value="new">
                <em>{t('add_new')}</em>
              </MenuItem>
              {!engines_pending && engines &&
              engines.map(item => (
                <MenuItem
                  key={item.id}
                  value={item.id}
                >
                  {item['name']}
                </MenuItem>
              ))
              }
            </Select>}
            {state.newEngine && !state.waitingEngine
            && <MTCTextField
              id={'engine.name'}
              onChange={event => setState({
                ...state,
                engineValue: CarFormatter.formatValue('name', event.target.value),
                waitingDimension: event.target.value.trim() === '',
              })}
              value={state.engineValue}
              type={'text'}
              noLabel
              require={true}
              InputProps={{
                className: classes.input,
                startAdornment:
                  !state.newModel
                  && <InputAdornment
                    position="start"
                    onClick={handleEngineListClick}
                  >
                    <Tooltip title={t('show_list_items')}>
                      <List />
                    </Tooltip>
                  </InputAdornment>,
              }}
            />}
          </Grid>

          {state.newEngine
          && <MTCSwitch
            id="with_dimension"
            onChange={() => {
              setState({
                ...state,
                withDimension: !state.withDimension,
              });
            }}
            enableText={'with_dimension_enable'}
            disableText={'with_dimension_disable'}
            value={state.withDimension}
          />}

          {(
            state.engineId || state.engineValue
          )
          && state.withDimension
          && <>
            {/*  DIMENSION  */}
            <Paper className={classes.paper}>
              <Typography
                align="center"
                variant="h5"
                component="h2"
                gutterBottom
              >
                {t('dimension_front_dimension')}
              </Typography>

              <Grid container>
                <Grid item xs={12} sm={12} md={12}>
                  <Typography
                    align="center"
                    variant="h6"
                    gutterBottom
                    className={classes.paperSubtitle}
                  >
                    {t('front')}
                  </Typography>
                </Grid>
                <MTCTextField
                  gridMD={12}
                  id={'front_dimension'}
                  onChange={(event) => setState({
                    ...state,
                    dimension: {
                      ...state.dimension,
                      front_dimension: event.target.value,
                    },
                  })}
                  value={state.dimension.front_dimension}
                />
                <MTCTextField
                  gridMD={12}
                  id={'front_pressure'}
                  onChange={(event) => setState({
                    ...state,
                    dimension: {
                      ...state.dimension,
                      front_pressure: event.target.value,
                    },
                  })}
                  type="number"
                  value={state.dimension.front_pressure}
                  inputProps={{
                    step: 0.01,
                    min: 0,
                    minLength: '1',
                    required: true,
                  }}
                />
                <Grid item xs={12} sm={12} md={12}>
                  <Typography
                    align="center"
                    variant="h6"
                    gutterBottom
                    className={classes.paperSubtitle}
                  >
                    {t('rear')}
                  </Typography>
                </Grid>
                <MTCTextField
                  gridMD={12}
                  id={'rear_dimension'}
                  onChange={(event) => setState({
                    ...state,
                    dimension: {
                      ...state.dimension,
                      rear_dimension: event.target.value,
                    },
                  })}
                  value={state.dimension.rear_dimension}
                />
                <MTCTextField
                  gridMD={12}
                  id={'rear_pressure'}
                  onChange={(event) => setState({
                    ...state,
                    dimension: {
                      ...state.dimension,
                      rear_pressure: event.target.value,
                    },
                  })}
                  type="number"
                  value={state.dimension.rear_pressure}
                  inputProps={{
                    step: 0.01,
                    min: 0,
                    minLength: '1',
                    required: true,
                  }}
                />

                <MTCSwitch
                  id={'has_psc2'}
                  value={state.dimension.has_psc2}
                  onChange={() => {
                    let newAvailableTireType = [...state.dimension.available_tire_types];
                    if (!state.dimension.has_psc2) {
                      newAvailableTireType.push(PSC2);
                    } else {
                      const index = newAvailableTireType.indexOf(PSC2);
                      if (index > -1) {
                        newAvailableTireType.splice(index, 1);
                      }
                    }
                    setState({
                      ...state,
                      dimension: {
                        ...state.dimension,
                        has_psc2: !state.dimension.has_psc2,
                        available_tire_types: newAvailableTireType,
                      },
                    });
                  }}
                />
                <MTCSwitch
                  id={'has_psc2r'}
                  value={state.dimension.has_psc2r}
                  onChange={() => {
                    let newAvailableTireType = [...state.dimension.available_tire_types];
                    if (!state.dimension.has_psc2r) {
                      newAvailableTireType.push(PSC2R);
                    } else {
                      const index = newAvailableTireType.indexOf(PSC2R);
                      if (index > -1) {
                        newAvailableTireType.splice(index, 1);
                      }
                    }
                    setState({
                      ...state,
                      dimension: {
                        ...state.dimension,
                        has_psc2r: !state.dimension.has_psc2r,
                        available_tire_types: newAvailableTireType,
                      },
                    });
                  }}
                />
              </Grid>
            </Paper>

            {/*  BOUNDARIES  */}
            {(
              state.dimension.has_psc2 || state.dimension.has_psc2r
            )
            && <Paper className={classes.paper}>
              <Typography
                align="center"
                variant="h5"
                component="h2"
                gutterBottom
              >
                {t('dimensions')}
              </Typography>
              <AppBar position={'static'}>
                <Tabs id="tire-type-tabs" value={tireTypeTab} onChange={handleTireTypeTabChange}>
                  {state.dimension.has_psc2 && <Tab label={PSC2} />}
                  {state.dimension.has_psc2r && <Tab label={PSC2R} />}
                </Tabs>
              </AppBar>
              {state.dimension.has_psc2 && tireTypeTab === 0
              &&
              <Grid container>
                <Grid item xs={12} sm={12} md={12}>
                  <Typography
                    align="center"
                    variant="h6"
                    gutterBottom
                    className={classes.paperSubtitle}
                  >
                    {t('front')}
                  </Typography>
                  <Typography
                    color="primary"
                    variant="subtitle1"
                    align="center"
                    gutterBottom
                  >
                    {t('boundaries')}
                  </Typography>
                  <BoundaryRangeIndicator />
                  {
                    WEATHER_TYPE.properties.map(property => {
                      const boundary = state.boundaries.find(boundary => (
                        boundary.weather_type === property.value
                        && boundary.is_front === true
                        && boundary.tire_type === PSC2
                      ));
                      return (
                        <BoundaryForm
                          boundary={boundary}
                          onChange={onBoundaryValueChange}
                          key={'boundary'
                          + boundary.tire_type
                          + boundary.is_front
                          + boundary.weather_type}
                          isRally={false}
                        />
                      );
                    })
                  }
                </Grid>

                <Grid item xs={12} sm={12} md={12}>
                  <Typography
                    align="center"
                    variant="h6"
                    gutterBottom
                    className={classes.paperSubtitle}
                  >
                    {t('rear')}
                  </Typography>
                  <Typography
                    color="primary"
                    variant="subtitle1"
                    align="center"
                    gutterBottom
                  >
                    {t('boundaries')}
                  </Typography>
                  <BoundaryRangeIndicator />
                  {
                    WEATHER_TYPE.properties.map(property => {
                      const boundary = state.boundaries.find(boundary => (
                        boundary.weather_type === property.value
                        && boundary.is_front === false
                        && boundary.tire_type === PSC2
                      ));
                      return (
                        <BoundaryForm
                          boundary={boundary}
                          onChange={onBoundaryValueChange}
                          key={'boundary'
                          + boundary.tire_type
                          + boundary.is_front
                          + boundary.weather_type}
                          isRally={false}
                        />
                      );
                    })
                  }
                </Grid>
              </Grid>}
              {state.dimension.has_psc2r
              && (
                tireTypeTab === 1
                || (
                  !state.dimension.has_psc2 && tireTypeTab === 0
                )
              )
              && <Grid container>
                <Grid item xs={12} sm={12} md={12}>
                  <Typography
                    align="center"
                    variant="h6"
                    gutterBottom
                    className={classes.paperSubtitle}
                  >
                    {t('front')}
                  </Typography>
                  <Typography
                    color="primary"
                    variant="subtitle1"
                    align="center"
                    gutterBottom
                  >
                    {t('boundaries')}
                  </Typography>
                  <BoundaryRangeIndicator />
                  {
                    WEATHER_TYPE.properties.map(property => {
                      const boundary = state.boundaries.find(boundary => (
                        boundary.weather_type === property.value
                        && boundary.is_front === true
                        && boundary.tire_type === PSC2R
                      ));
                      if (boundary) {
                        return (
                          <BoundaryForm
                            boundary={boundary}
                            onChange={onBoundaryValueChange}
                            key={'boundary'
                            + boundary.tire_type
                            + boundary.is_front
                            + boundary.weather_type}
                            isRally={false}
                          />
                        );
                      }
                      return (<></>);
                    })
                  }
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                  <Typography
                    align="center"
                    variant="h6"
                    gutterBottom
                    className={classes.paperSubtitle}
                  >
                    {t('rear')}
                  </Typography>
                  <Typography
                    color="primary"
                    variant="subtitle1"
                    align="center"
                    gutterBottom
                  >
                    {t('boundaries')}
                  </Typography>
                  <BoundaryRangeIndicator />
                  {
                    WEATHER_TYPE.properties.map(property => {
                      const boundary = state.boundaries.find(boundary => (
                        boundary.weather_type === property.value
                        && boundary.is_front === false
                        && boundary.tire_type === PSC2R
                      ));
                      return (
                        <BoundaryForm
                          boundary={boundary}
                          onChange={onBoundaryValueChange}
                          key={'boundary'
                          + boundary.tire_type
                          + boundary.is_front
                          + boundary.weather_type}
                          isRally={false}
                        />
                      );
                    })
                  }
                </Grid>
              </Grid>}

            </Paper>}

            <MTCTextField
              id={'data_version'}
              onChange={(event) => {
                let dimension = state.dimension;
                dimension.data_version = event.target.value;
                setState({
                  ...state,
                  dimension,
                });
              }}
              value={state.dimension.data_version}
            />
            <Grid item xs={12} sm={12}>
              <FormControl margin={'normal'}>
                <InputLabel id="select-origin-label">{t('origin')}</InputLabel>
                <Select
                  id="select-origin"
                  value={state.dimension.origin}
                  onChange={(event) => {
                    let dimension = state.dimension;
                    dimension.origin = event.target.value;
                    setState({
                      ...state,
                      dimension,
                    });
                  }}
                  className={classes.input}
                >
                  {
                    ORIGIN.properties.map(property => (
                      <MenuItem
                        key={property.value}
                        value={property.value}
                      >
                        {t(property.name)}
                      </MenuItem>
                    ))
                  }
                </Select>
              </FormControl>
            </Grid>
            <MTCSwitch
              id="is_public"
              onChange={() => {
                setState({
                  ...state,
                  dimension: {
                    ...state.dimension,
                    is_public: !state.dimension.is_public,
                  },
                });
              }}
              value={state.dimension.is_public}
              disableText={t('tester')}
              enableText={t('public')}
            />
            <MTCSwitch
              id={'dimension_state'}
              onChange={() => {
                setState({
                  ...state,
                  dimension: {
                    ...state.dimension,
                    state: !state.dimension.state,
                  },
                });
              }}
              value={state.dimension.state}
            />
          </>}
          <Grid item>
            <MTCButton
              disabled={isInvalid}
              sending={sending}
              text={duplicatedCar
                ? t('duplicate')
                : t('create')}
              type={'submit'}
            />
          </Grid>
        </Grid>
        <Dialog
          open={state.confirmDialogOpen}
          onClose={handleCloseCancel}
          aria-labelledby="alert-confirm-dialog-title"
          aria-describedby="alert-confirm-dialog-description"
        >
          {hasLessThanBoundaries(state)
          && <DialogTitle id="alert-confirm-dialog-title">
            {t('car_pression_warning')}
          </DialogTitle>}
          {isTheSame(state, duplicatedCar)
          && <DialogTitle id="alert-confirm-dialog-title">
            {t('car_duplicate_warning')}
          </DialogTitle>}
          {expertDimensionWithoutBoundary(state)
          && <DialogTitle id="alert-confirm-dialog-title">
            {t('dimension_without_boundary')}
          </DialogTitle>}
          <DialogActions>
            <Button onClick={handleCloseCancel} color="primary">
              {t('cancel')}
            </Button>
            <Button onClick={handleCloseConfirm} color="primary" autoFocus>
              {t('validate')}
            </Button>
          </DialogActions>
        </Dialog>
      </form>
    );
  }
};

AddCarForm.defaultProps = {
  duplicatedCar: null,
};

AddCarForm.propTypes = {
  duplicatedCar: PropTypes.object,
};

export default AddCarForm;
