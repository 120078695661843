import makeStyles from '@material-ui/core/styles/makeStyles';

export const useStyles = makeStyles({
  button: {
    position: 'fixed',
    display: 'flex',
    justifyContent: 'center',
    bottom: '3vh',
    right: '3vh',
    zIndex: 10
  },
});
