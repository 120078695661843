import React from 'react';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import useTheme from '@material-ui/core/styles/useTheme';
import i18next from '../../../../i18n';
import {
  getFaqElementTypeColor, getFaqElementTypeIconComponent,
  ROOT,
} from '../FaqElementType.const';
import { useStyles } from './FaqElementBreadcrumbsItem.style';

const FaqElementBreadcrumbsItem = (
  {
    element,
    handleClickBreadcrumbs,
    current,
  },
) => {
  const lang = i18next.languages[0].split('-')[0];

  const theme = useTheme();
  const classes = useStyles(theme);

  let title = '';
  if (element.type === ROOT) {
    title = 'FAQ';
  } else {
    title = element.translations[lang]
      ? element.translations[lang].title
      : '';
  }
  const icon = getFaqElementTypeIconComponent(
    element.type,
    classes.icon,
    { color: getFaqElementTypeColor(element.type) }
  );

  if (current) {
    return (
      <Typography
        color="textPrimary"
        className={classes.link}>
        {icon}
        {title}
      </Typography>
    );
  }
  return (
    <Link
      className={classes.link}
      color="inherit"
      href="#"
      onClick={() => handleClickBreadcrumbs(element.id)}
    >
      {icon}
      {title}
    </Link>
  );
};

export default FaqElementBreadcrumbsItem;
