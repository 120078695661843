import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import useTheme from '@material-ui/core/styles/useTheme';
import Container from '@material-ui/core/Container';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useStyles } from './FaqElementsExplorer.style';
import { ARTICLE, EXTERNAL_LINK, ROOT } from '../FaqElementType.const';
import {
  deleteFaqElements,
  getFaqElements,
  putFaqElements,
} from '../../../../store/faqElements/actions';
import { handleError } from '../../../../store/infos/actions';
import MTCLoader from '../../../Layout/MTCLoader';
import FaqElementsExplorerMenu from '../FaqElementsExplorerMenu/FaqElementsExplorerMenu';
import FaqElementExplorerButton from '../FaqElementExplorerButton/FaqElementExplorerButton';
import FaqElementBreadcrumbs from '../FaqElementBreadcrumbs/FaqElementBreadcrumbs';
import FaqElementsExplorerTree from '../FaqElementsExplorerTree/FaqElementsExplorerTree';
import FaqElementsExplorerList from '../FaqElementsExplorerList/FaqElementsExplorerList';
import FaqElementsDialog from '../FaqElementsDialog/FaqElementsDialog';
import { getFaqArticles } from '../../../../store/faqArticles/actions';
import FaqElementsExternalLinkDialog
  from '../FaqElementsDialog/FaqElementsExternalLinkDialog/FaqElementsExternalLinkDialog';
import FaqElementsMoveDialog
  from '../FaqElementsDialog/FaqElementsMoveDialog/FaqElementsMoveDialog';
import FaqElementsRemoveDialog
  from '../FaqElementsDialog/FaqElementsRemoveDialog/FaqElementsRemoveDialog';

const FaqElementsExplorer = ({ defaultId }) => {

  const {
    sending,
    pending,
    content,
    error,
  } = useSelector(state => state.faqElements);
  const articleStore = useSelector(state => state.faqArticles);
  const { t } = useTranslation();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const classes = useStyles(theme);
  const dispatch = useDispatch();
  const [current, setCurrent] = useState(null);
  const [history, setHistory] = useState([]);
  const [dialogType, setDialogType] = React.useState(null);
  const [dialogElement, setDialogElement] = React.useState(null);
  const [openExternalLinkDialog, setOpenExternalLinkDialog] = useState(
    { open: false, title: null, link: null }
  );
  const [openRemoveDialog, setOpenRemoveDialog] = useState(
    {
      open: false,
      title: null,
      elementId: null,
      type: null,
      directoryChoice: '',
      newParentId: null
    }
  );
  const [openMoveDialog, setOpenMoveDialog] = useState(
    { open: false, title: null, elementId: null, parentId: null }
  );

  const handleCloseMoveDialog = () => {
      setOpenMoveDialog({
        ...openMoveDialog,
        open: false,
        elementId: null,
        parentId: null,
        directoryChoice: '',
        newParentId: null
      });
  };

  const selectParent = (id) => {
    setOpenMoveDialog({ ...openMoveDialog, parentId: id });
  }

  const handleMove = (event) => {
    event.stopPropagation();
    event.preventDefault();
    if (openMoveDialog.parentId !== null) {
      const jsonData = {
        id: openMoveDialog.elementId,
        parent_id: openMoveDialog.parentId
      }
      dispatch(putFaqElements(jsonData)).then(() => {
        handleCloseMoveDialog();
      });
    }
  }

  const handleCloseRemoveDialog = () => {
    setOpenRemoveDialog({
      ...openRemoveDialog,
      open: false,
      elementId: null,
      type: null,
      directoryChoice: '',
      newParentId: null
    });
  };

  const handleDelete = (newParentId) => {
    const parentId = current.parent_id;
    dispatch(deleteFaqElements(openRemoveDialog.elementId, newParentId))
      .then(() => {
        if (openRemoveDialog.elementId === current.id) {
          setCurrent(content.find(element => element.id === parentId));
        }
        handleCloseRemoveDialog()
    });
  };


  const handleCloseExternalLinkDialog = () => {
    setOpenExternalLinkDialog({ ...openExternalLinkDialog, open: false });
  };

  const handleOpenElementDialog = (type, id) => {
    if (id) {
      setDialogElement(content.find(element => element.id === id));
    } else {
      setDialogElement(null);
    }
    setDialogType(type);
  }

  const handleOpenArticleDialog = (id) => {
    if (id) {
      setDialogElement(content.find(element => element.id === id));
    } else {
      setDialogElement(null);
    }
    setDialogType(ARTICLE);
  }

  const handleOpenExternalLinkDialog = (id) => {
    if (id) {
      setDialogElement(content.find(element => element.id === id));
    } else {
      setDialogElement(null);
    }
    setDialogType(EXTERNAL_LINK);
  }

  const handleCloseDialog = () => {
    setDialogType(null);
  }

  if (!content && !pending && !error) {
    dispatch(getFaqElements());
  }
  if (!articleStore.content && !articleStore.pending && !articleStore.error) {
    dispatch(getFaqArticles());
  }

  if (content != null && current == null) {
    let root
    if (defaultId) {
      root = content.find(element => element.id === defaultId);
    } else {
      root = content.find(element => element.type === ROOT);
    }
    if (!root) {
      setCurrent('error');
      dispatch(handleError({ message: 'No root element' }));
    } else {
      setCurrent(root);
    }
  }

  const changeCurrent = (id) => {
    const element = content.find(element => element.id === id);
    if (element) {
      history.push(current.id);
      setHistory(history);
      setCurrent(element);
    }
  };

  const goBack = () => {
    const lastId = history.pop()
    const newCurrent = content.find(element => element.id === lastId);
    setHistory(history);
    setCurrent(newCurrent);
  }

  if (current === 'root') {
    return <p>No root element</p>;
  }
  if (!content || pending) {
    return <MTCLoader />;
  }

  return (
    <Container className={classes.container} maxWidth="xl">
      <div className={classes.header}>
        <div className={classes.path}>
          <FaqElementExplorerButton
            action={goBack}
            icon={<ArrowBackIcon/>}
            title={t('back')}
            disabled={history.length === 0}
            secondary
          />
          <FaqElementBreadcrumbs
            elements={content}
            element={current}
            changeCurrent={changeCurrent}
          />
          <FaqElementsExplorerMenu
            element={current}
            handleOpenElementDialog={handleOpenElementDialog}
            handleOpenArticleDialog={handleOpenArticleDialog}
            handleOpenExternalLinkDialog={handleOpenExternalLinkDialog}
            setOpenRemoveDialog={setOpenRemoveDialog}
          />
        </div>
      </div>
      <div className={classes.body}>
        <FaqElementsExplorerTree
          elements={content}
          changeCurrent={changeCurrent}
          setOpenExternalLinkDialog={setOpenExternalLinkDialog}
        />
        <FaqElementsExplorerList
          elements={content}
          element={current}
          changeCurrent={changeCurrent}
          handleOpenElementDialog={handleOpenElementDialog}
          handleOpenArticleDialog={handleOpenArticleDialog}
          handleOpenExternalLinkDialog={handleOpenExternalLinkDialog}
          setOpenExternalLinkDialog={setOpenExternalLinkDialog}
          setOpenRemoveDialog={setOpenRemoveDialog}
          setOpenMoveDialog={setOpenMoveDialog}
        />
      </div>

      <FaqElementsDialog
        open={dialogType !== null}
        handleClose={handleCloseDialog}
        parent={current}
        type={dialogType}
        defaultElement={dialogElement}
      />

      <FaqElementsExternalLinkDialog
        openExternalLinkDialog={openExternalLinkDialog}
        handleCloseExternalLinkDialog={handleCloseExternalLinkDialog}
        fullScreen={fullScreen}
      />

      <FaqElementsMoveDialog
        fullScreen={fullScreen}
        openMoveDialog={openMoveDialog}
        handleCloseMoveDialog={handleCloseMoveDialog}
        selectParent={selectParent}
        handleMove={handleMove}
        sending={sending}
        content={content}
      />

      <FaqElementsRemoveDialog
        openRemoveDialog={openRemoveDialog}
        handleCloseRemoveDialog={handleCloseRemoveDialog}
        setOpenRemoveDialog={setOpenRemoveDialog}
        handleDelete={handleDelete}
        fullScreen={fullScreen}
        sending={sending}
        content={content}
      />

    </Container>
  );
};

export default FaqElementsExplorer;
