export const INITIAL_STATE = {
  id: '',
  country_codes: [],
  lang: '',
  phone: '',
  opening: 1,
};

export const ERROR_STATE = {
  countryCodesError: null,
  langError: null,
  phoneError: null,
};

export const compareCoutries = (a, b) => {
  if (a.code < b.code) {
    return -1;
  } else {
    return 1;
  }
}
