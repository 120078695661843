import React from 'react';
import FolderIcon from '@material-ui/icons/Folder';
import DescriptionIcon from '@material-ui/icons/Description';
import LinkIcon from '@material-ui/icons/Link';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import ContactSupportIcon from '@material-ui/icons/ContactSupport';
import HomeIcon from '@material-ui/icons/Home';
import {
  michelin_blue_light,
  michelin_green,
  michelin_orange,
  michelin_red,
  michelin_text_grey_dark,
} from '../../../constants/michelin_colors';

const ROOT = 'ROOT';
const DIRECTORY = 'DIRECTORY';
const ARTICLE = 'ARTICLE';
const EXTERNAL_LINK = 'EXTERNAL_LINK';
const ANY_CAR_FORM = 'ANY_CAR_FORM';
const CIRCUIT_CAR_FORM = 'CIRCUIT_CAR_FORM';
const RALLY_CAR_FORM = 'RALLY_CAR_FORM';
const CIRCUIT_FORM = 'CIRCUIT_FORM';
const SUGGESTION_FORM = 'SUGGESTION_FORM';

export {
  ROOT,
  DIRECTORY,
  ARTICLE,
  EXTERNAL_LINK,
  ANY_CAR_FORM,
  CIRCUIT_CAR_FORM,
  RALLY_CAR_FORM,
  CIRCUIT_FORM,
  SUGGESTION_FORM,
};

export const getFaqElementTypeColor = (type) => {
  switch (type) {
    case ROOT:
      return michelin_blue_light;
    case DIRECTORY:
      return michelin_blue_light;
    case ARTICLE:
      return michelin_orange;
    case EXTERNAL_LINK:
      return michelin_green;
    case ANY_CAR_FORM:
      return michelin_red;
    case CIRCUIT_CAR_FORM:
      return michelin_red;
    case RALLY_CAR_FORM:
      return michelin_red;
    case CIRCUIT_FORM:
      return michelin_red;
    case SUGGESTION_FORM:
      return michelin_red;
    default:
      return michelin_text_grey_dark;
  }
};

export const getFaqElementTypeIconComponent = (type, className, style) => {
  const Icon = getFaqElementTypeIcon(type);
  return <Icon style={style} className={className} />
}

export const getFaqElementTypeIcon = (type) => {
  switch (type) {
    case ROOT:
      return HomeIcon;
    case DIRECTORY:
      return FolderIcon;
    case ARTICLE:
      return DescriptionIcon;
    case EXTERNAL_LINK:
      return LinkIcon;
    case ANY_CAR_FORM:
      return ContactSupportIcon;
    case CIRCUIT_CAR_FORM:
      return ContactSupportIcon;
    case RALLY_CAR_FORM:
      return ContactSupportIcon;
    case CIRCUIT_FORM:
      return ContactSupportIcon;
    case SUGGESTION_FORM:
      return ContactSupportIcon;
    default:
      return HelpOutlineIcon;
  }
};
