import makeStyles from '@material-ui/core/styles/makeStyles';
import { michelin_orange } from '../../../constants/michelin_colors';

export const useStyles = makeStyles((theme) => {
  return {

    boundaries: {
      height: '3px',
      width: '80%',
      marginLeft: '10%',
      [theme.breakpoints.down('sm')]: {
        width: '100%',
        marginLeft: 0,
      },
    },
    redBoundary: {
      backgroundColor: 'red',
      width: '20%',
      height: '100%',
      [theme.breakpoints.down('sm')]: {
        width: '13%',
      },
    },
    orangeBoundary: {
      backgroundColor: michelin_orange,
      width: '20%',
      height: '100%',
      [theme.breakpoints.down('sm')]: {
        width: '25%',
      },
    },
    greenBoundary: {
      backgroundColor: 'green',
      width: '20%',
      height: '100%',
      [theme.breakpoints.down('sm')]: {
        width: '24%',
      },
    },
  };
});
