import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import useTheme from '@material-ui/core/styles/useTheme';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Grid from '@material-ui/core/Grid';
import FormHelperText from '@material-ui/core/FormHelperText';
import { useStyles } from './MTCSwitch.style';

const MTCSwitch = props => {

  const {
    id,
    onChange,
    value,
    gridMD,
    gridSM,
    gridLG,
    label,
    enableText,
    disableText,
    disabled,
    helperText,
  } = props;
  const theme = useTheme();
  const classes = useStyles(theme);
  const { t } = useTranslation();

  const finalLabel =
    (
      label || t(id)
    )
    + ' : ' +
    t(value
      ? (
        enableText || 'yes'
      )
      : (
        disableText || 'no'
      ),
    );

  return (
    <Grid item xs={12} sm={gridSM || 12} md={gridMD || 12} lg={gridLG || gridMD || 12} key="switch">
      <FormControl className={classes.formControl}>
        <FormControlLabel
          key={'fcl' + id}
          disabled={disabled || false}
          className={classes.formControlLabel}
          control={
            <Switch
              placeholder={t(id)}
              id={id}
              value={id}
              onChange={onChange}
              checked={value}
              margin={'normal'}
              color={'primary'}
            />}
          label={finalLabel}

        />
        {helperText
        && <FormHelperText className={classes.formControlLabel}>
          {helperText}
        </FormHelperText>
        }
      </FormControl>
    </Grid>
  );
};

MTCSwitch.propTypes = {
  id: PropTypes.string.isRequired,
  value: PropTypes.any.isRequired,
  onChange: PropTypes.func.isRequired,
  gridMD: PropTypes.number,
  gridSM: PropTypes.number,
  gridLG: PropTypes.number,
  enableText: PropTypes.string,
  disableText: PropTypes.string,
  label: PropTypes.string,
  disabled: PropTypes.bool,
};

export default React.memo(MTCSwitch);
