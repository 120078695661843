import React from 'react';
import ReactDOM from 'react-dom';
import { CookiesProvider } from 'react-cookie';
import { I18nextProvider } from 'react-i18next';
import { BrowserRouter as Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import './index.css';
import 'overlayscrollbars/css/OverlayScrollbars.css';
import App from './App';
import store from './store';
import i18n from './i18n';
import history from './history';
import * as serviceWorker from './serviceWorker';
import { ThemeProvider } from '@material-ui/core';
import { theme } from './theme/theme';

ReactDOM.render(
  <Provider store={store}>
    <I18nextProvider i18n={i18n}>
      <Router history={history}>
        <ThemeProvider theme={theme}>
          <CookiesProvider>
            <App/>
          </CookiesProvider>
        </ThemeProvider>
      </Router>
    </I18nextProvider>
  </Provider>,
  document.getElementById('root'),
);

serviceWorker.unregister();
