export const columns = [
  {
    id: 'brand.name',
    numeric: false,
    disablePadding: false,
  },
  {
    id: 'type.name',
    numeric: false,
    disablePadding: false,
  },
  {
    numeric: false,
    disablePadding: false,
  },
];
export const defaultOrderBy = 'brand.name';
export const createDataFromTires = tires => {
  const brands = tires.brands;
  const types = tires.types;
  let data = [];
  let dataId = 0;
  types.forEach(type => {
    const matchedTireBrand = brands.find(brand => brand.id === type.tire_brand_id);
    data.push({
      id: dataId,
      brand: matchedTireBrand,
      type: type,
    });
    dataId++;
  });
  return data;
};
