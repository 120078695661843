import makeStyles from '@material-ui/core/styles/makeStyles';
import { drawerWidth } from '../Menu/Menu.const';

export const useStyles = makeStyles(theme => (
  {
    menu: {
      overflowY: 'auto',
      height: 'auto',
      paddingRight: '2vh',
    },
    root: {
      flexGrow: 1,
    },
    drawerPaper: {
      width: drawerWidth,
    },
    toolbar: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
    closeButton: {
      right: 12,
      color: theme.palette.primary.contrastText,
      position: 'absolute',
    },
  }
));
