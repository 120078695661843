import React from 'react';
import PropTypes from 'prop-types';
import Timestamp from 'react-timestamp';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import useTheme from '@material-ui/core/styles/useTheme';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import { CIRCUIT_REQUEST_EDIT } from '../../../constants/routes';
import { REQUEST_STATE, REQUEST_TRACK } from '../../../store/requests/types';
import Typography from '@material-ui/core/Typography';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { useStyles } from './RequestCircuitRow.style';

const RequestCircuitRow = ({ value }) => {

  const theme = useTheme();
  const classes = useStyles(theme);
  const { t } = useTranslation();
  const history = useHistory();

  const handleEdit = event => {
    event.stopPropagation();
    history.push(
      CIRCUIT_REQUEST_EDIT.replace(':requestId', value.id),
      { requestType: REQUEST_TRACK },
    );
  };

  return (
    <TableRow
      className={classes.row}
      key={value.id}
      hover
      onClick={handleEdit}
      tabIndex={-1}
    >
      <TableCell>
        <Typography>
          <Timestamp
            format='full'
            twentyFourHour
            time={value.created_at}
          />
        </Typography>
      </TableCell>
      <TableCell>
        <Typography>
          {value.name}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography>
          {value.country_code}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography>
          {value.firstname}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography>
          {value.lastname}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography>
          {t(REQUEST_STATE.properties[value.request_state].name)}
        </Typography>
      </TableCell>
      <TableCell align={'right'}>
        <Tooltip title={t('edit')}>
          <IconButton
            aria-label="update"
            onClick={handleEdit}
            color="primary"
            className={classes.button}
          >
            <EditIcon />
          </IconButton>
        </Tooltip>
      </TableCell>
    </TableRow>
  );
};

RequestCircuitRow.propTypes = {
  value: PropTypes.object.isRequired,
};

export default React.memo(RequestCircuitRow);
