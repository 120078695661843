import React from 'react';
import Table from '@material-ui/core/Table';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Typography from '@material-ui/core/Typography';
import TableHead from '@material-ui/core/TableHead';
import KpiPaper from '../KpiPaper/KpiPaper';
import { useStyles } from './TopUserKPIBlock.style';

const TopUserKpiBlock = ({title, maxRallyRunUsers }) => {
  const classes = useStyles();

  let maxRallyRunUsersObjList = [];

  Object.keys(maxRallyRunUsers).forEach(key => {
    maxRallyRunUsersObjList.push({
      email: key,
      number: maxRallyRunUsers[key],
    });
  });

  maxRallyRunUsersObjList.sort((a, b) => b.number - a.number);

  return (
    <KpiPaper>
      <Typography
        variant="h3"
        color="primary"
        component="p"
        className={classes.title}
      >
        {title}
      </Typography>
      <Table>
        <TableHead className={classes.head}>
          <TableCell className={classes.tableHeadCell}>
            <Typography className={classes.tableHeadLabel} variant="body1">Email</Typography>
          </TableCell>
          <TableCell align="right" className={classes.tableHeadCell}>
          <Typography className={classes.tableHeadLabel} variant="body1">Sessions</Typography>
          </TableCell>
        </TableHead>
        {maxRallyRunUsersObjList.slice(0, 4).map((el, index) =>
          <TableRow key={index} className={classes.row}>
            <TableCell className={classes.cell}>
              <Typography variant="body1">{el.email}</Typography>
            </TableCell>
            <TableCell align="right" className={classes.cell}>
              <Typography variant="body1">{el.number}</Typography>
            </TableCell>
          </TableRow>
        )}
      </Table>
    </KpiPaper>
  );
};

export default TopUserKpiBlock;
