import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import InputAdornment from '@material-ui/core/InputAdornment';
import Tooltip from '@material-ui/core/Tooltip';
import List from '@material-ui/icons/List';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Grid from '@material-ui/core/Grid';
import { TIRES } from '../../constants/routes';
import MTCTextField from '../Form/MTCTextField';
import StringService from '../../services/StringService';
import MTCLoader from '../Layout/MTCLoader';
import MTCDialog from '../Form/MTCDialog';
import withTranslation from '../../hoc/withTranslation';
import { getTires, postTire, putTire } from '../../store/tires/actions';
import MTCButton from '../Layout/Button/MTCButton';
import { styles } from './TireForm.style';
import { INITIAL_STATE, stateWithTire } from './TireForm.const';

export class TireForm extends Component {
  static propTypes = {
    tire: PropTypes.object,
    classes: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  }

  static getDerivedStateFromProps(props, state) {
    if (props.tire && state.id === INITIAL_STATE.id) {
      return stateWithTire(props.tire);
    }

    return null;
  }

  componentDidMount() {
    if (!this.props.tires) {
      this.props.getTires();
    }
    if (this.props.tire) {
      this.setState(stateWithTire(this.props.tire));
    }
  }

  // Handler
  onSubmit = (event) => {
    if (this.props.tire) {
      this.props.putTire(this.state).then(() => this.props.history.push(TIRES));
    } else {
      this.props.postTire({
        brand: this.state.brand,
        type: this.state.type,
      }).then(() => this.props.history.push(TIRES));
    }
    event.preventDefault();
  };

  onChange = (event, stateKey, listItem, itemKey) => {
    const newStateId = event.target.value;
    if (!newStateId) {
      this.setState({
        dialog: {
          openDialog: true,
          dialogItem: stateKey,
          newItemKey: itemKey,
          dialogTitle: this.props.t('add_new_title') + ' '
            + this.props.t(stateKey + '.' + itemKey) + ' ?',
          dialogText: this.props.t('add_new_content'),
          dialogHandleOK: this.handleAddNewItem,
        },
      });
    } else if (!this.state[stateKey] || this.state[stateKey].id !== newStateId) {
      this.setState({ [stateKey]: listItem.find(item => item.id === newStateId) });
      this.deleteChildren(stateKey);
    }
  };

  handleClose = () => {
    this.setState({
      dialog: INITIAL_STATE.dialog,
    });
  };

  handleCloseConfirm = () => {
    this.setState({ confirmDialogOpen: false });
    this.callApi();
  };

  handleCloseCancel = () => {

    this.setState({ confirmDialogOpen: false });
  };

  handleAddNewItem = () => {
    this.setState({
      [this.state.dialog.dialogItem]:
        { [this.state.dialog.newItemKey]: this.state.dialog.newItem },
    });
    this.deleteChildren(this.state.dialog.dialogItem);
    this.handleClose();
  };

  handleListClick = (event, stateKey) => {
    this.setState({ [stateKey]: null });
    this.deleteChildren(stateKey);
  };

  deleteChildren = (stateKey) => {
    const currentOrder = this.state.orders.find(order => order.name === stateKey).order;
    this.state.orders.filter(order => order.order > currentOrder).forEach(order => {
      this.setState({ [order.name]: null });
    });
  };

  renderSelectOrAdd = (
    item,
    listItem,
    itemKey,
    stateKey,
    parentItem = 'none',
    parentIdKey,
  ) => {
    let body;
    if (this.props.tire || stateKey === 'type') {//If editing
      if (stateKey === 'type') { // Only TireType can be edited
        body =
          <MTCTextField
            id={stateKey + '.' + itemKey}
            onChange={event => this.setState({
              [stateKey]: {
                ...item,
                [itemKey]: event.target.value,
              },
            })}
            value={item ? item[itemKey] : ''}
            type={'text'}
            noLabel
          />;
      } else {
        body =
          <Typography variant="subtitle1" gutterBottom>
            {item[itemKey]}
          </Typography>;
      }
    } else {
      if (!parentItem) { // If parentItem is missing, show text indicating that previous field should be completed first
        body =
          <Typography variant="subtitle1" gutterBottom>
            {this.props.t('select_field')} : {this.props.t(parentIdKey)}
          </Typography>;
      } else if ((item && !item.id) || // If item has been created, only show it as text and give option to go back to list
        (parentItem !== 'none' && !parentItem.id)) { // There is a parentItem but without an id. So it is a new item and all childrens will be new as well
        body =
          <MTCTextField
            id={stateKey + '.' + itemKey}
            onChange={event => this.setState({
              [stateKey]: {
                [itemKey]: event.target.value,
              },
            })}
            value={item ? item[itemKey] : ''}
            type={'text'}
            noLabel
            InputProps={(parentItem !== 'none' && !parentItem.id) ? null : {
              className: this.props.classes.input,
              startAdornment: (
                <InputAdornment
                  position="start"
                  onClick={event => this.handleListClick(event, stateKey)}
                >
                  <Tooltip title={this.props.t('show_list_items')}>
                    <List/>
                  </Tooltip>
                </InputAdornment>
              ),
            }}
          />;
      } else { // Else show the matching list item in order to select one ar add a new item
        let matchingList = listItem;
        if (parentItem && parentItem !== 'none') {
          matchingList = listItem.filter(item => item[parentIdKey] === parentItem.id);
        }
        let itemList = matchingList.map(item => (
          <MenuItem
            key={item.id}
            value={item.id}
          >
            {item[itemKey]}
          </MenuItem>
        ));

        body =
          <Select
            id={stateKey + '.' + itemKey}
            value={item ? item.id : ''}
            onChange={event => this.onChange(event, stateKey, listItem, itemKey)}
            className={this.props.classes.input}
          >
            <MenuItem value="">
              <em>{this.props.t('add_new')}</em>
            </MenuItem>
            {
              itemList
            }
          </Select>;
      }
    }
    return (
      <Grid item xs={12} sm={12} md={6}>
        <Typography
          color="primary"
          variant="subtitle1"
          gutterBottom>
          {this.props.t(stateKey + '.' + itemKey)}
        </Typography>
        {body}
      </Grid>
    );
  };

  render() {
    const {
      brand,
      type,
      dialog,
    } = this.state;

    const { classes, pending, t, tires } = this.props;
    const isEditing = this.props.tire;
    const isInvalid =
      StringService.isEmpty(brand) ||
      StringService.isEmpty(type) ||
      this.props.sending;
    if (pending || !tires) {
      return <MTCLoader/>;
    } else {
      return (
        <form
          onSubmit={this.onSubmit}
          className={classes.container}
        >
          <Grid container spacing={2} direction={'column'} alignItems={'center'}>
            {this.renderSelectOrAdd(brand, tires.brands, 'name', 'brand')}
            {this.renderSelectOrAdd(type, tires.types, 'name', 'type', brand, 'brand_id')}
          </Grid> <br/>

          <MTCButton
            disabled={isInvalid}
            sending={this.props.sending}
            text={t(isEditing ? 'edit' : 'create')}
            type={'submit'}
          />

          <MTCDialog
            open={dialog.openDialog}
            handleClose={this.handleClose}
            dialogTitle={dialog.dialogTitle}
            dialogText={dialog.dialogText}
            handleCloseCancel={this.handleClose}
            handleCloseOK={dialog.dialogHandleOK}
            cancelText={t('cancel')}
            okText={t('validate')}
            textField={
              <MTCTextField
                id={dialog.dialogItem + '.' + dialog.newItemKey}
                value={dialog.newItem}
                onChange={event => this.setState(
                  {
                    dialog: {
                      ...dialog,
                      newItem: event.target.value,
                    },
                  },
                )}
              />
            }
          />
        </form>
      );
    }
  }
}

function mapStateToProps(state) {
  return {
    tires: state.tires.content,
    pending: state.tires.pending,
    sending: state.tires.sending,
    error: state.tires.error,
  };
}

export default compose(
  withStyles(styles),
  withTranslation,
  connect(mapStateToProps, { putTire, postTire, getTires }),
)(TireForm);
