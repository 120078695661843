import React, { useState } from 'react';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import ClassicChallengeTable from './Table/ClassicChallengeTable';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import makeStyles from '@material-ui/core/styles/makeStyles';
import TemporaryChallengeTable from './Table/TemporaryChallengeTable';

const useStyles = makeStyles(
  () => {
    return {
      root: {
        flexGrow: 1,
      },
    };
  });

const ChallengeTabs = () => {
  const history = useHistory();
  const classes = useStyles();
  const { t } = useTranslation();
  const [value, setValue] = useState(0);
  const [locationStateMatch, setLocationStateMatch] = useState(true);

  if (
    history.location
    && history.location.state
    && locationStateMatch
  ) {
    if (history.location.state.value) {
      setValue(history.location.state.value);
    }
    setLocationStateMatch(false);
  }

  const handleChange = (event, value) => {
    setValue(value);
  };

  return (
    <div className={classes.root}>
      <AppBar position={'static'}>
        <Tabs
          value={value}
          onChange={handleChange}
          variant={'scrollable'}
          scrollButtons={'on'}
        >
          <Tab label={t('challenges_page.tabs.classic')} />
          <Tab label={t('challenges_page.tabs.temporary')} />
        </Tabs>
      </AppBar>
      {value === 0 && <ClassicChallengeTable />}
      {value === 1 && <TemporaryChallengeTable />}
    </div>
  );
};

export default ChallengeTabs;
