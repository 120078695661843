import {IN_APP_NOTIFICATIONS_PENDING,
  IN_APP_NOTIFICATIONS_FAILED,
  IN_APP_NOTIFICATIONS_DOWNLOADED,
  IN_APP_NOTIFICATION_DOWNLOADED,
  IN_APP_NOTIFICATION_SENDING,
  IN_APP_NOTIFICATION_DELETED} from './types';

export const INITIAL_STATE = {
  content: null,
  pending: false,
  sending: false,
  error: null,
};


export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case IN_APP_NOTIFICATIONS_PENDING:
      return {
        ...state,
        pending: true,
      };
    case IN_APP_NOTIFICATION_SENDING:
      return {
        ...state,
        sending: true,
      };
    case IN_APP_NOTIFICATIONS_FAILED:
      return {
        ...state,
        pending: false,
        sending: false,
        error: action.payload
      };
    case IN_APP_NOTIFICATIONS_DOWNLOADED:
      return {
        ...state,
        pending: false,
        error: null,
        content: action.payload
      };
    case IN_APP_NOTIFICATION_DOWNLOADED: {
      let newContent = [];
      if (state.content != null) {
        newContent = [...state.content];
      }
      let index = newContent.findIndex(ian => ian.id === action.payload.id);
      if (index === -1) {
        newContent.push(action.payload);
      } else {
        newContent[index] = action.payload;
      }
      return {
        ...state,
        sending: false,
        error: null,
        content: newContent,
      };
    }
    case IN_APP_NOTIFICATION_DELETED: {
      let newContent = [];
      if (state.content != null) {
        newContent = [...state.content];
      }
      let index = newContent.findIndex(ian => ian.id === action.payload);
      if (index !== -1) {
        newContent.splice(index, 1);
      }
      return {
        ...state,
        sending: false,
        error: null,
        content: newContent,
      };
    }
    default:
      break;
  }
  return state;
}
