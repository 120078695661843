export const columns = [
  {
    id: 'type',
    numeric: false,
    disablePadding: false,
    label: 'type.name',
  },
  {
    id: 'version',
    numeric: false,
    disablePadding: false,
  },
  {
    id: '',
    numeric: false,
    disablePadding: false,
  },
];
export const defaultOrderBy = 'type';
