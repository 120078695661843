import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import Timestamp from 'react-timestamp';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import makeStyles from '@material-ui/core/styles/makeStyles';
import useTheme from '@material-ui/core/styles/useTheme';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import { deleteReceiverMmcBle } from '../../store/receiversMmcBle/actions';
import { deleteReceiverMmcRf } from '../../store/receiversMmcRf/actions';
import { deleteReceiverMtcBle } from '../../store/receiversMtcBle/actions';
import { deleteReceiverMtcRf } from '../../store/receiversMtcRf/actions';
import MTCDialog from '../Form/MTCDialog';
import { simpleRowStyle } from '../../constants/styles/row.style';

const useStyles = makeStyles(theme => ({ row: simpleRowStyle(theme)}));

const ReceiverRow = ({ value, identifier }) => {

  const { t } = useTranslation();
  const theme = useTheme();
  const classes = useStyles(theme);
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDelete = (id) => {
    switch (identifier) {
      case 'mmcBle':
        dispatch(deleteReceiverMmcBle(id));
        break;
      case 'mmcRf':
        dispatch(deleteReceiverMmcRf(id));
        break;
      case 'mtcBle':
        dispatch(deleteReceiverMtcBle(id));
        break;
      case 'mtcRf':
        dispatch(deleteReceiverMtcRf(id));
        break;
      default:
        console.error('Wrong identifier: ' + identifier)
        break;
    }
  };

  return (
    <>
      <TableRow
        className={classes.row}
        key={value.id}
        tabIndex={-1}
      >
        <TableCell>
          <Timestamp
            format='full'
            twentyFourHour
            time={value.created_at}
          />
        </TableCell>
        <TableCell>{value.version}</TableCell>
        <TableCell>
          <IconButton
            id='remove'
            aria-label="remove"
            onClick={handleOpen}
            className={classes.button}
          >
            <DeleteIcon />
          </IconButton>
        </TableCell>
      </TableRow>
      <MTCDialog
        open={open}
        handleClose={handleClose}
        dialogTitle={t(`remove_firmware_${identifier}_title`)}
        dialogText={t(`remove_firmware_${identifier}_text`)}
        handleCloseCancel={handleClose}
        handleCloseOK={() => handleDelete(value.id)}
        cancelText={t('cancel')}
        okText={t('validate')}
      />
    </>
  );
};

ReceiverRow.propTypes = {
  value: PropTypes.object.isRequired,
  identifier: PropTypes.string.isRequired,
};

export default React.memo(ReceiverRow);
