import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Grid from '@material-ui/core/Grid';
import { useHistory } from 'react-router';
import MTCTextField from '../../Form/MTCTextField';
import MTCButton from '../../Layout/Button/MTCButton';
import {
  getFirmwareGroups,
  postFirmwareGroups,
  putFirmwareGroups,
} from '../../../store/firmwareGroups/actions';
import { FIRMWARE_GROUP, RECEIVER } from '../../../constants/routes';
import { useStyles } from './FirmwareGroupForm.style';

export const FirmwareGroupForm = (props) => {
  const { id } = props;
  const {
    content,
    sending,
    pending,
    error,
  } = useSelector(state => state.firmwareGroups);
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const classes = useStyles();
  const [name, setName] = useState('');

  if (!content && !pending && !error) {
    dispatch(getFirmwareGroups());
  }
  const isEditing = id !== null;
  if (isEditing && content && name === '' && !pending && !error) {
    let index = -1;
    if (content.length > 0) {
     index = content.findIndex(fg => fg.id == id);
    }
    if (index === -1) {
      history.push(RECEIVER);
    } else {
      setName(content[index].name);
    }
  }

  const formSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();
    if (name !== '') {
      if (isEditing) {
        dispatch(putFirmwareGroups(id, name))
          .then(data => {
            if (data !== null) {
              history.push(FIRMWARE_GROUP.replace(':groupId', data.id));
            }
          });
      } else {
        dispatch(postFirmwareGroups(name))
          .then(data => {
            if (data !== null) {
              history.push(FIRMWARE_GROUP.replace(':groupId', data.id));
            }
          });
      }
    }
  };

  return (
    <form onSubmit={formSubmit}>
      <Grid
        container
        alignItems={'center'}
        direction={'column'}
        justifyContent={'center'}
        className={classes.grid}
      >
        <MTCTextField
          gridMD={12}
          id="name"
          onChange={event => setName(event.target.value)}
          value={name}
          required={true}
        />
        <MTCButton
          type={'submit'}
          sending={sending}
          text={t(isEditing
            ? 'edit'
            : 'create')}
        />
      </Grid>
    </form>
  );
};

FirmwareGroupForm.defaultProps = { id: null };

export default FirmwareGroupForm;
