const TABLE_CARS_PENDING = 'table_cars_pending';
const TABLE_CARS_FAILED = 'table_cars_failed';
const TABLE_CARS_DOWNLOADED = 'table_cars_downloaded';
const TABLE_CARS_RESET = 'table_cars_reset';
const TABLE_CARS_BRAND_DOWNLOADED = 'table_cars_brand_downloaded';
const TABLE_CARS_RANGE_DOWNLOADED = 'table_cars_range_downloaded';
const TABLE_CARS_MODEL_DOWNLOADED = 'table_cars_model_downloaded';
const TABLE_CARS_ENGINE_DOWNLOADED = 'table_cars_engine_downloaded';
const TABLE_CARS_DIMENSION_DOWNLOADED = 'table_cars_dimension_downloaded';

export {
  TABLE_CARS_PENDING,
  TABLE_CARS_FAILED,
  TABLE_CARS_DOWNLOADED,
  TABLE_CARS_RESET,
  TABLE_CARS_BRAND_DOWNLOADED,
  TABLE_CARS_RANGE_DOWNLOADED,
  TABLE_CARS_MODEL_DOWNLOADED,
  TABLE_CARS_ENGINE_DOWNLOADED,
  TABLE_CARS_DIMENSION_DOWNLOADED
};
