import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import useTheme from '@material-ui/core/styles/useTheme';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import MenuItem from '@material-ui/core/MenuItem';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import FormHelperText from '@material-ui/core/FormHelperText';
import { INITIAL_ERROR_STATE, INITIAL_STATE } from './FaqElementsDialogArticle.const';
import { stateWithElement } from './FaqElementsDialogArticle.func';
import { useStyles } from '../FaqElementsDialog.style';
import { ARTICLE } from '../../FaqElementType.const';
import { postFaqElements, putFaqElements } from '../../../../../store/faqElements/actions';
import i18next from '../../../../../i18n';
import { useHistory } from 'react-router';
import { FAQ_ARTICLES_ADD, FAQ_ARTICLES_EDIT, FAQ_ELEMENTS } from '../../../../../constants/routes';

const FaqElementsDialogArticle = ({
                                    handleClose,
                                    parentId,
                                    defaultElement,
                                  }) => {

  const { t } = useTranslation();
  const theme = useTheme();
  const dispatch = useDispatch();
  const lang = i18next.languages[0].split('-')[0];
  const classes = useStyles(theme);
  const history = useHistory();
  const { content } = useSelector(state => state.faqArticles);
  const sending = false;
  let title;
  if (defaultElement) {
    title = t('title:title.edit_faq_elements_article');
  } else {
    title = t('title:title.add_faq_elements_article');
  }

  const [state, setState] = useState(defaultElement
    ? stateWithElement(defaultElement)
    : INITIAL_STATE,
  );
  const [errors, setErrors] = useState(INITIAL_ERROR_STATE);

  const handleSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();
    if (!sending) {
      let errorCount = 0;
      let newErrorState = { ...INITIAL_ERROR_STATE };
      if (state.article_id === null || state.article_id < 0) {
        errorCount++;
        newErrorState.article_id_error = t('error.required_error');
      }
      if (errorCount === 0) {
        const jsonData = {
          id: defaultElement
            ? defaultElement.id
            : null,
          parent_id: parentId,
          article_id: state.article_id,
          type: ARTICLE,
        };
        if (defaultElement) {
          dispatch(putFaqElements(jsonData)).then(() => {
            handleClose();
          });
        } else {
          dispatch(postFaqElements(jsonData)).then(() => {
            handleClose();
          });
        }
      } else {
        setErrors(newErrorState);
      }
    }
  };

  const createArticle = () => {
    history.push({
      pathname: FAQ_ARTICLES_ADD,
      state: {
        from: FAQ_ELEMENTS,
        faqElementParentId: parentId,
        elementId: parentId,
      },
    });
  };

  const modifyArticle = () => {
    history.push({
      pathname: FAQ_ARTICLES_EDIT.replace(':articleId', defaultElement.article_id),
      state: {
        from: FAQ_ELEMENTS,
        elementId: parentId,
      },
    });
  };

  const handleStatePropertyChange = (property, value) => {
    let newState = { ...state };
    newState[property] = value;
    let newErrors = { ...errors };
    newErrors[property + '_error'] = null;
    setState(newState);
    setErrors(newErrors);
  };

  return (
    <>
      <DialogTitle disableTypography id={'folder-dialog-title'}>
        <Typography variant="h6">
          {title}
        </Typography>
        <IconButton aria-label="close" className={classes.closeButton} onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers={true}>
        <Grid
          container
          align="center"
          direction={'column'}
          justifyContent={'center'}
        >
          <Grid item xs={12}>
            <FormControl
              error={errors.article_id_error !== undefined && errors.article_id_error !== null}
              className={classes.formControl}
            >
              <InputLabel
                id="article-label"
                required
                error={errors.article_id_error !== undefined && errors.article_id_error !== null}
              >
                {t('select_article')}
              </InputLabel>
              <Select
                className={classes.select}
                labelId="article_id-label"
                id="article_id"
                error={errors.article_id_error !== undefined && errors.article_id_error !== null}
                value={state.article_id}
                onChange={(
                  event => handleStatePropertyChange('article_id', event.target.value)
                )}
              >
                {content.map(art => <MenuItem value={art.id} key={`select-item-${art.id}`}>
                  {art.translations[lang]
                    ? art.translations[lang].title
                    : ''}
                </MenuItem>)}
              </Select>
              {errors.article_id_error &&
              <FormHelperText>{errors.article_id_error}</FormHelperText>}
            </FormControl>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Grid container justifyContent="space-between" alignContent="center">
          <Grid item>
            <Button
              onClick={defaultElement
                ? modifyArticle
                : createArticle}
              backgroundcolor={'secondary'}
              disabled={sending || (
                defaultElement && defaultElement.article_id !== state.article_id
              )}
            >
              {t(defaultElement
                ? 'modify_article'
                : 'write_article')}
            </Button>
          </Grid>
          <Grid item>
            <Grid container justifyContent="flex-end" alignContent="flex-start">
              <Grid item>
                <Button onClick={handleClose} backgroundcolor={'secondary'} disabled={sending}>
                  {t('cancel')}
                </Button>
              </Grid>
              <Grid item>
                <div className={classes.wrapper}>
                  <Button
                    color={'primary'}
                    disabled={sending}
                    type={'button'}
                    onClick={handleSubmit}
                  >
                    {t('validate')}
                  </Button>
                  {sending && <CircularProgress size={24} className={classes.buttonProgress} />}
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </DialogActions>
    </>
  );
};

export default FaqElementsDialogArticle;

