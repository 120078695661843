import React from 'react';
import withAuthentication from '../../hoc/withAuthentication';
import { ROLE_ADMIN } from '../../constants/roles';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { setTitle } from '../../store/title/actions';
import { useParams } from 'react-router';
import ContactFrom from '../../components/Contacts/ContactForm';
import { getContacts } from '../../store/contacts/actions';
import MTCSpinner from '../../components/Layout/MTCSpinner';

export const AddContactPage = () => {
  const { t } = useTranslation();
  const { title } = useSelector(state => state.title);
  const dispatch = useDispatch();
  const { type } = useParams();
  const { pending, content, error } = useSelector(state => state.contacts);

  if (!content && !pending && !error) {
    dispatch(getContacts());
  }
  if (title !== (t('title:title.add_contact_email'))) {
    dispatch(setTitle(t('title:title.add_contact_email')));
  }
  if (pending) {
    return (
      <MTCSpinner />
    );
  } else {
    return (
      <ContactFrom type={type} />
    );
  }
};

export default withAuthentication(ROLE_ADMIN)(AddContactPage);