import {
  AUTHORIZED_EXPORTS_PENDING,
  AUTHORIZED_EXPORTS_FAILED,
  AUTHORIZED_EXPORTS_DOWNLOADED,
  AUTHORIZED_EXPORT_DOWNLOADED,
  AUTHORIZED_EXPORT_SENDING,
  AUTHORIZED_EXPORT_DELETED,
  AUTHORIZED_EXPORT_EXPORTED, AUTHORIZED_EXPORT_STARTED,
} from './types';
import ApiService from '../../services/ApiService';
import { handleError } from '../infos/actions';

export const authorizedExportsPendingAction = () => (
  {
    type: AUTHORIZED_EXPORTS_PENDING
  }
);
export const authorizedExportSendingAction = () => (
  {
    type: AUTHORIZED_EXPORT_SENDING
  }
);
export const authorizedExportsFailedAction = (error) => (
  {
    type: AUTHORIZED_EXPORTS_FAILED,
    payload: error,
  }
);
export const authorizedExportsDownloadedAction = (authorizedExports) => (
  {
    type: AUTHORIZED_EXPORTS_DOWNLOADED,
    payload: authorizedExports,
  }
);
export const authorizedExportDownloadedAction = (authorizedExport) => (
  {
    type: AUTHORIZED_EXPORT_DOWNLOADED,
    payload: authorizedExport,
  }
);
export const authorizedExportDeletedAction = (id) => (
  {
    type: AUTHORIZED_EXPORT_DELETED,
    payload: id,
  }
);
export const authorizedExportExportedAction = (authorizedExport) => (
  {
    type: AUTHORIZED_EXPORT_EXPORTED,
    payload: authorizedExport,
  }
);
export const authorizedExportStartedAction = () => (
  {
    type: AUTHORIZED_EXPORT_STARTED
  }
);

export function getAuthorizedExports() {
  return (dispatch) => {
    dispatch(authorizedExportsPendingAction());
    return ApiService
      .getAuthorizedExports()
      .then(data => {
        dispatch(authorizedExportsDownloadedAction(data));
        return data;
      })
      .catch(error => {
        dispatch(authorizedExportsFailedAction(error));
        dispatch(handleError(error));
      });
  }
}

export function postAuthorizedExport(authorizedExport) {
  return (dispatch) => {
    dispatch(authorizedExportSendingAction());
    return ApiService
      .postAuthorizedExports(authorizedExport)
      .then(data => {
        dispatch(authorizedExportDownloadedAction(data));
        return data;
      })
      .catch(error => {
        dispatch(authorizedExportsFailedAction(error));
        dispatch(handleError(error));
      });
  }
}

export function putAuthorizedExport(id, authorizedExport) {
  return (dispatch) => {
    dispatch(authorizedExportSendingAction());
    return ApiService
      .putAuthorizedExports(id, authorizedExport)
      .then(data => {
        dispatch(authorizedExportDownloadedAction(data));
        return data;
      })
      .catch(error => {
        dispatch(authorizedExportsFailedAction(error));
        dispatch(handleError(error));
      });
  }
}

export function deleteAuthorizedExport(id) {
  return (dispatch) => {
    dispatch(authorizedExportSendingAction());
    return ApiService
      .deleteAuthorizedExports(id)
      .then(data => {
        dispatch(authorizedExportDeletedAction(id));
        return data;
      })
      .catch(error => {
        dispatch(authorizedExportsFailedAction(error));
        dispatch(handleError(error));
      });
  }
}

export function startAuthorizedExport() {
  return (dispatch) => {
    dispatch(authorizedExportStartedAction())
  }
}

export function increaseAuthorizedExportCount(authorizedExport) {
  return (dispatch) => {
    dispatch(authorizedExportExportedAction(authorizedExport));
  }
}
