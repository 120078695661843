import React from 'react';
import { setTitle } from '../../store/title/actions';
import withAuthentication from '../../hoc/withAuthentication';
import { ROLE_ADMIN } from '../../constants/roles';
import CarTabs from '../../components/Cars/CarTabs';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

export const CarsPage = () => {
  const { t } = useTranslation();
  const { title } = useSelector(state => state.title);
  const dispatch = useDispatch();
  if (title !== t('title:title.cars_page')) {
    dispatch(setTitle(t('title:title.cars_page')));
  }
  return <CarTabs/>;
}

export default withAuthentication(ROLE_ADMIN)(CarsPage);
