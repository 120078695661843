export const columns = [
  {
    id: 'created_at',
    numeric: false,
    disablePadding: false,
  },
  {
    id: 'version',
    numeric: false,
    disablePadding: false,
  },
  {
    id: '',
    numeric: false,
    disablePadding: false,
  },
];
export const defaultOrderBy = 'created_at';
