import makeStyles from '@material-ui/core/styles/makeStyles';

export const useStyles = makeStyles((theme) => {
  return {
    dragIcon: {
      minWidth: 40,
      cursor: 'grab'
    },
    typeIcon: {
      minWidth: 32
    },
  }
});
