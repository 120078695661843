import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import withAuthentication from '../../hoc/withAuthentication';
import { ROLE_ADMIN } from '../../constants/roles';
import { setTitle } from '../../store/title/actions';
import { getLangCallCenters } from '../../store/langCallCenters/actions';
import MTCLoader from '../../components/Layout/MTCLoader';
import { useParams } from 'react-router';
import EditLangCallCenterForm from '../../components/CallCenters/EditLangCallCenterForm';

const EditLangCallCentersPage = () => {
  const dispatch = useDispatch();
  const { title } = useSelector(state => state.title);
  const { pending, content, error } = useSelector(state => state.langCallCenters);
  const { t } = useTranslation();
  const [callCenter, setCallCenter] = useState(null);

  const { callCenterLang } = useParams();

  if (!content && !pending && !error) {
    dispatch(getLangCallCenters());
  }

  if (content && callCenter === null) {
    const result = content.find((elem) => {
      return elem.lang.toString() === callCenterLang;
    });
    setCallCenter(result);
  }

  if (title !== t('title:title.edit_lang_call_centers_page')) {
    dispatch(setTitle(t('title:title.edit_lang_call_centers_page')));
  }

  if (pending) {
    return <MTCLoader />;
  } else if (callCenter) {
    return <EditLangCallCenterForm callcenter={callCenter} />;
  } else {
    return '';
  }
};

export default withAuthentication(ROLE_ADMIN)(EditLangCallCentersPage);