const TRACKS_PENDING = 'tracks_pending';
const TRACKS_FAILED = 'tracks_failed';
const TRACKS_DOWNLOADED = 'tracks_downloaded';
const TRACK_SENDING = 'track_sending';
const TRACK_DOWNLOADED = 'track_downloaded';
const TRACK_FILTERED = 'track_filtered';
const TRACK_FILTERED_RESET = 'track_filtered_reset';

export {
  TRACKS_PENDING,
  TRACKS_FAILED,
  TRACKS_DOWNLOADED,
  TRACK_SENDING,
  TRACK_DOWNLOADED,
  TRACK_FILTERED,
  TRACK_FILTERED_RESET
};
