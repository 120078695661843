import React from 'react';
import { useTranslation } from 'react-i18next';
import MTCTable from '../../../Layout/Table/MTCTable';
import MTCLoader from '../../../Layout/MTCLoader';
import FirmwareGroupItemRow from './FirmwareGroupItemRow';
import { columns, defaultOrderBy } from './FirmwareGroupItemsTable.const';

const FirmwareGroupItemsTable = ({ pending, content }) => {

  const { t } = useTranslation();

  if (pending) {
    return <MTCLoader />;
  } else {
    return <MTCTable
      content={content}
      defaultOrderBy={defaultOrderBy}
      defaultOrder={'asc'}
      rowComponent={FirmwareGroupItemRow}
      columns={columns}
      paginationLabel={t('firmware_group_items_per_page')}
      emptyDataLabel={t('no_firmware_group_item')}
    />;
  }
};

export default FirmwareGroupItemsTable;
