import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import TestersTable from '../../components/Testers/TestersTable';
import TesterForm from '../../components/Testers/TesterForm';
import withAuthentication from '../../hoc/withAuthentication';
import { ROLE_ADMIN } from '../../constants/roles';
import { setTitle } from '../../store/title/actions';

const TestersPage = () => {

  const { t } = useTranslation();
  const { title } = useSelector(state => state.title);
  const dispatch = useDispatch();

  if (title !== t('title:title.testers_page')) {
    dispatch(setTitle(t('title:title.testers_page')));
  }

  return (
    <div>
      <TesterForm/>
      <TestersTable/>
    </div>
  );
}

export default withAuthentication(ROLE_ADMIN)(TestersPage);
