import React, { useState } from 'react';
import PropTypes from 'prop-types';
import hash from 'object-hash';
import { useTranslation } from 'react-i18next';
import RequestMessageRow from './RequestMessageRow';
import MTCLoader from '../../Layout/MTCLoader';
import MTCTable from '../../Layout/Table/MTCTable';
import {
  columns,
  createDataFromRequestAndUser,
  defaultOrderBy,
  defaultOrder
} from './RequestsMessageTable.const';

const RequestsMessageTable = ({ users, requests }) => {

  const { t } = useTranslation();
  const [requestsHash, setRequestsHash] = useState(null);
  const [data, setData] = useState(null);

  if (data === null || requestsHash !== hash(requests)) {
    setData(createDataFromRequestAndUser(requests, users));
    setRequestsHash(hash(requests));
  }
  if (data === null) {
    return <MTCLoader />;
  } else {
    return <MTCTable
      content={data}
      defaultOrderBy={defaultOrderBy}
      defaultOrder={defaultOrder}
      rowComponent={RequestMessageRow}
      columns={columns}
      paginationLabel={t('requests_per_page')}
      emptyDataLabel={t('no_request')}
    />;
  }
};

RequestsMessageTable.propTypes = {
  users: PropTypes.array.isRequired,
  requests: PropTypes.array.isRequired,
};

export default RequestsMessageTable;
