import React from 'react';
import withAuthentication from '../../../hoc/withAuthentication';
import { ROLE_ADMIN } from '../../../constants/roles';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { setTitle } from '../../../store/title/actions';
import FirmwareGroupForm from '../../../components/Receivers/FirmwareGroups/FirmwareGroupForm';

export const AddFirmwareGroupPage = () => {
  const { t } = useTranslation();
  const { title } = useSelector(state => state.title);
  const dispatch = useDispatch();

  if (title !== (t('title:title.firmware_group_add_page'))) {
    dispatch(setTitle(t('title:title.firmware_group_add_page')));
  }

  return <FirmwareGroupForm/>;
}

export default withAuthentication(ROLE_ADMIN)(AddFirmwareGroupPage);
