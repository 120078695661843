const RECEIVERS_MTC_RF_PENDING = 'receivers_mtc_rf_pending';
const RECEIVER_MTC_RF_FAILED = 'receiver_mtc_rf_failed';
const RECEIVER_MTC_RF_DOWNLOADED = 'receiver_mtc_rf_downloaded';
const RECEIVER_MTC_RF_SENDING = 'receivers_mtc_rf_sending';
const RECEIVERS_MTC_RF_DOWNLOADED = 'receivers_mtc_rf_downloaded';
const RECEIVERS_MTC_RF_DELETED = 'receivers_mtc_rf_deleted';

export {
  RECEIVERS_MTC_RF_PENDING,
  RECEIVER_MTC_RF_FAILED,
  RECEIVER_MTC_RF_DOWNLOADED,
  RECEIVER_MTC_RF_SENDING,
  RECEIVERS_MTC_RF_DOWNLOADED,
  RECEIVERS_MTC_RF_DELETED
};
