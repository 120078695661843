import {
  RECEIVER_MTC_RF_DOWNLOADED,
  RECEIVER_MTC_RF_FAILED,
  RECEIVER_MTC_RF_SENDING, RECEIVERS_MTC_RF_DELETED,
  RECEIVERS_MTC_RF_DOWNLOADED,
  RECEIVERS_MTC_RF_PENDING,
} from './types';
import ApiService from '../../services/ApiService';
import { handleError } from '../infos/actions';

export const receiverMtcRfSendingAction = () => ({
  type: RECEIVER_MTC_RF_SENDING,
});

export const receiverMtcRfDownloadedAction = receiver => ({
  type: RECEIVER_MTC_RF_DOWNLOADED,
  payload: receiver,
});

export const receiversMtcRfDownloadedAction = receivers => ({
  type: RECEIVERS_MTC_RF_DOWNLOADED,
  payload: receivers,
});

export const receiverMtcRfDeletedAction = id => ({
  type: RECEIVERS_MTC_RF_DELETED,
  payload: id,
});

export const receiverMtcRfFailedAction = error => ({
  type: RECEIVER_MTC_RF_FAILED,
  payload: error,
});

export const receiversMtcRfPendingAction = () => ({
  type: RECEIVERS_MTC_RF_PENDING,
});

export function getReceiversMtcRf() {
  return (dispatch) => {
    dispatch(receiversMtcRfPendingAction());
    return ApiService
      .getMtcReceiversRf()
      .then((data) => {
        dispatch(receiversMtcRfDownloadedAction(data));
      })
      .catch((error) => {
        dispatch(receiverMtcRfFailedAction(error));
        dispatch(handleError(error));
      });
  };
}

export function postReceiverMtcRf(receiver) {
  return (dispatch) => {
    dispatch(receiverMtcRfSendingAction());
    return ApiService
      .postMtcReceiverRf(receiver)
      .then((data) => {
        dispatch(receiverMtcRfDownloadedAction(data));
        return data;
      })
      .catch((error) => {
        dispatch(receiverMtcRfFailedAction(error));
        if (error.response && error.response.status === 409) {
          error.response.statusText = 'Conflict: File already exists on server';
        }
        dispatch(handleError(error));
      });
  };
}

export function putReceiverMtcRf(receiver) {
  return (dispatch) => {
    dispatch(receiverMtcRfSendingAction());
    return ApiService
      .putMtcReceiverRf(receiver)
      .then((data) => {
        dispatch(receiverMtcRfDownloadedAction(data));
      })
      .catch((error) => {
        dispatch(receiverMtcRfFailedAction(error));
        dispatch(handleError(error));
      });
  };
}

export function deleteReceiverMtcRf(id) {
  return (dispatch) => {
    dispatch(receiverMtcRfSendingAction());
    return ApiService
      .deleteMtcReceiverRf(id)
      .then(() => {
        dispatch(receiverMtcRfDeletedAction(id));
      })
      .catch((error) => {
        dispatch(receiverMtcRfFailedAction(error));
        dispatch(handleError(error));
      });
  };
}
