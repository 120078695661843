const FAQ_ELEMENTS_DOWNLOADED = "faq_elements_downloaded";
const FAQ_ELEMENT_DOWNLOADED = "faq_element_downloaded";
const FAQ_ELEMENTS_UPDATED = "faq_elements_updated";
const FAQ_ELEMENTS_DELETED = "faq_elements_deleted";
const FAQ_ELEMENTS_FAILED = "faq_elements_failed";
const FAQ_ELEMENTS_PENDING = "faq_elements_pending";
const FAQ_ELEMENTS_SENDING = "faq_elements_sending";

export {
  FAQ_ELEMENTS_DOWNLOADED,
  FAQ_ELEMENT_DOWNLOADED,
  FAQ_ELEMENTS_FAILED,
  FAQ_ELEMENTS_PENDING,
  FAQ_ELEMENTS_SENDING,
  FAQ_ELEMENTS_UPDATED,
  FAQ_ELEMENTS_DELETED
};
