const FORM_CARS_BRANDS_PENDING = 'form_cars_brands_pending';
const FORM_CARS_RANGES_PENDING = 'form_cars_ranges_pending';
const FORM_CARS_MODELS_PENDING = 'form_cars_models_pending';
const FORM_CARS_ENGINES_PENDING = 'form_cars_engines_pending';
const FORM_CARS_DIMENSIONS_PENDING = 'form_cars_dimensions_pending';
const FORM_CARS_BOUNDARIES_PENDING = 'form_cars_boundaries_pending';
const FORM_CARS_BRANDS_DOWNLOADED = 'form_cars_brands_downloaded';
const FORM_CARS_RANGES_DOWNLOADED = 'form_cars_ranges_downloaded';
const FORM_CARS_MODELS_DOWNLOADED = 'form_cars_models_downloaded';
const FORM_CARS_ENGINES_DOWNLOADED = 'form_cars_engines_downloaded';
const FORM_CARS_DIMENSIONS_DOWNLOADED = 'form_cars_dimensions_downloaded';
const FORM_CARS_BOUNDARIES_DOWNLOADED = 'form_cars_boundaries_downloaded';
const FORM_CARS_DIMENSION_PENDING = 'form_cars_dimension_pending';
const FORM_CARS_DIMENSION_DOWNLOADED = 'form_cars_dimension_downloaded';
const FORM_CAR_FAILED = 'form_car_failed';
const FORM_CAR_RESET = 'form_car_reset';
const FORM_CAR_SENDING = 'form_car_sending';
const FORM_CAR_UPLOADED = 'form_car_uploaded';

const WEATHER_TYPE = Object.freeze({
  NONE: 0,
  DRY: 1,
  HUMID_LESS_15: 2,
  HUMID_MORE_15: 4,
  WET: 3,
  properties: [
    {
      name: 'weather_none',
      value: 0,
    },
    {
      name: 'weather_dry',
      value: 1,
    },
    {
      name: 'weather_humid_less_15',
      value: 2,
    },
    {
      name: 'weather_humid_more_15',
      value: 4,
    },
    {
      name: 'weather_wet',
      value: 3,
    },
  ],
});

const ORIGIN = Object.freeze({
  EXPERT: 0,
  TOOL: 1,
  properties: [
    {
      name: 'origin_expert',
      value: 0,
    },
    {
      name: 'origin_tool',
      value: 1,
    },
  ],
});

export {
  FORM_CARS_BRANDS_PENDING,
  FORM_CARS_RANGES_PENDING,
  FORM_CARS_MODELS_PENDING,
  FORM_CARS_ENGINES_PENDING,
  FORM_CARS_DIMENSIONS_PENDING,
  FORM_CARS_DIMENSION_PENDING,
  FORM_CARS_BOUNDARIES_PENDING,
  FORM_CARS_BRANDS_DOWNLOADED,
  FORM_CARS_RANGES_DOWNLOADED,
  FORM_CARS_MODELS_DOWNLOADED,
  FORM_CARS_ENGINES_DOWNLOADED,
  FORM_CARS_DIMENSIONS_DOWNLOADED,
  FORM_CARS_DIMENSION_DOWNLOADED,
  FORM_CARS_BOUNDARIES_DOWNLOADED,
  FORM_CAR_FAILED,
  FORM_CAR_RESET,
  FORM_CAR_SENDING,
  FORM_CAR_UPLOADED,
  WEATHER_TYPE,
  ORIGIN,
};