import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import withAuthentication from '../../../hoc/withAuthentication';
import { ROLE_ADMIN } from '../../../constants/roles';
import { setTitle } from '../../../store/title/actions';
import FaqArticlesForm from '../../../components/Faq/Articles/FaqArticlesForm/FaqArticlesForm';
import { useParams } from 'react-router';
import { getFaqArticles } from '../../../store/faqArticles/actions';
import MTCLoader from '../../../components/Layout/MTCLoader';
import Error404 from '../../Error404';

export const FaqArticleEditPage = () => {
  const { t } = useTranslation();
  const { title } = useSelector(state => state.title);
  const { content, pending, error } = useSelector(state => state.faqArticles);
  const dispatch = useDispatch();
  let { articleId } = useParams();
  const [article, setArticle] = useState(null)

  if (title !== t('title:title.edit_faq_articles_page')) {
    dispatch(setTitle(t('title:title.edit_faq_articles_page')));
  }

  if (!content && !pending && !error) {
    dispatch(getFaqArticles());
  }

  if (content && article === null) {
    const result = content.find(ar => ar.id == articleId);
    if (result) {
      setArticle(result);
    } else {
      setArticle(false);
    }
  }

  if (pending) {
    return <MTCLoader />;
  } else if (content && article) {
    return <FaqArticlesForm article={article} />;
  } else {
    return <Error404 />;
  }
}

export default withAuthentication(ROLE_ADMIN)(FaqArticleEditPage);
