import makeStyles from '@material-ui/core/styles/makeStyles';
import {
  michelin_white,
} from '../../../constants/michelin_colors';

export const useStyles = makeStyles((theme) => {
  return {
    paper: {
      backgroundColor: michelin_white,
      textAlign: "left",
      padding: "16px 32px",
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      justifyContent: "flex-start",
      height: "100%",
      width: "100%"
    }
  }
});
