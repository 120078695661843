import React from 'react';
import Chip from '@material-ui/core/Chip';
import { useStyles } from './MTCChip.style';

export const MTCChip = props => {
  const { text, success, error } = props;
  const classes = useStyles();
  let className = '';
  if (success) {
    className = classes.success;
  } else if (error) {
    className = classes.error;
  }
  return <Chip label={text} className={className} color={'primary'}/>;
};

MTCChip.defaultProps = {
  success: false,
  error: false,
};

export default React.memo(MTCChip);
