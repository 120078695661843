import {
  TRACK_COMMENT_DOWNLOADED,
  TRACK_COMMENT_SENDING,
  TRACK_COMMENTS_DOWNLOADED,
  TRACK_COMMENTS_FAILED,
  TRACK_COMMENTS_PENDING,
} from './types';

export const INITIAL_STATE = {
  error: null,
  circuitId: null,
  content: null,
  pending: false,
  sending: false,
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case TRACK_COMMENTS_PENDING:
      return {
        ...state,
        pending: true,
        error: null,
      };
    case TRACK_COMMENTS_FAILED:
      return {
        ...state,
        pending: false,
        error: action.payload,
      };
    case TRACK_COMMENTS_DOWNLOADED:
      return {
        ...state,
        pending: false,
        circuitId: action.payload.circuitId,
        content: action.payload.comments,
      };
    case TRACK_COMMENT_SENDING:
      return {
        ...state,
        sending: true,
      };
    case TRACK_COMMENT_DOWNLOADED: {
      let newContent = [];
      if (state.content != null) {
        newContent = [...state.content];
      }
      let index = newContent.findIndex(co => co.id === action.payload.id);
      if (index !== -1) {
        newContent[index] = action.payload;
      }
      return {
        ...state,
        sending: false,
        content: newContent,
      };
    }
    default:
      break;
  }
  return state;
}
