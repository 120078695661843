import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import useTheme from '@material-ui/core/styles/useTheme';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import Grid from '@material-ui/core/Grid';
import CloseIcon from '@material-ui/icons/Close';
import { useStyles } from './MTCTextField.style';

const MTCTextField = props => {

  const {
    id,
    onChange,
    value,
    type,
    gridMD,
    gridSM,
    gridLG,
    InputProps,
    inputProps,
    noLabel,
    onBlur,
    errorMessage,
    required,
    disabled,
    label,
    handleCancel,
    fullWidth,
    multiline,
    rowsMax,
    helperText,
    textEndAdornment,
    textStartAdornment
  } = props;
  const theme = useTheme();
  const classes = useStyles(theme);
  const { t } = useTranslation();

  let startAdornment = null
  if (textStartAdornment) {
    startAdornment = textStartAdornment
  }
  let endAdornment = null
  if (textEndAdornment) {
    endAdornment = textEndAdornment
  } else if (handleCancel) {
    endAdornment = <InputAdornment
      position="end"
      onClick={handleCancel}
      className={classes.cancelIcon}
    >
      <CloseIcon />
    </InputAdornment>
  }

  return (
    <Grid item xs={12} sm={gridSM || 12} md={gridMD || 12} lg={gridLG || gridMD || 12}>
      <TextField
        className={fullWidth
          ? classes.textFieldFull
          : classes.textField}
        InputProps={InputProps
          ? {
            ...InputProps,
            endAdornment: endAdornment,
            startAdornment: startAdornment
          }
          : {
            className: classes.input,
            endAdornment: endAdornment,
            startAdornment: startAdornment
          }
        }
        label={noLabel
          ? null
          : (
            label
              ? label
              : t(id)
          )}
        placeholder={t(`${id}`)}
        id={id}
        type={type
          ? type
          : 'text'}
        onChange={onChange}
        onBlur={onBlur}
        margin={'normal'}
        value={value !== null
          ? value
          : ''}
        inputProps={inputProps
          ? inputProps
          : {}}
        error={errorMessage !== undefined && errorMessage !== null}
        helperText={errorMessage || helperText}
        required={required || false}
        disabled={disabled || false}
        multiline={multiline || false}
        maxRows={multiline
          ? rowsMax || 3
          : 1}
      />
    </Grid>
  );
};

MTCTextField.propTypes = {
  id: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.any,
  type: PropTypes.string,
  fullWidth: PropTypes.bool,
  gridMD: PropTypes.number,
  gridSM: PropTypes.number,
  gridLG: PropTypes.number,
  InputProps: PropTypes.object,
  inputProps: PropTypes.object,
  noLabel: PropTypes.bool,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  onBlur: PropTypes.func,
  handleCancel: PropTypes.func,
  errorMessage: PropTypes.string,
  label: PropTypes.string,
  helperText: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  multiline: PropTypes.bool,
  rowsMax: PropTypes.number,
  textEndAdornment: PropTypes.string,
  textStartAdornment: PropTypes.string
};

export default React.memo(MTCTextField);
