import React from 'react';
import PropTypes from 'prop-types';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import NavigationClose from '@material-ui/icons/Close';
import Toolbar from '@material-ui/core/Toolbar';
import useTheme from '@material-ui/core/styles/useTheme';
import logo from '../../../resources/pictures/logo_michelin_splash.svg';
import { useStyles } from './MenuToolbar.style';

const MenuToolbar = ({ handleCloseDrawer }) => {

  const theme = useTheme();
  const classes = useStyles(theme);

  return (
    <Toolbar className={classes.toolbar}>
      <Hidden mdUp>
        <IconButton
          onClick={handleCloseDrawer}
          className={classes.closeButton}
        >
          <NavigationClose />
        </IconButton>
      </Hidden>
      <div className={classes.logoContainer}>
        <img className={classes.logo} src={logo} alt="MTC" />
      </div>
    </Toolbar>
  );
};

MenuToolbar.propTypes = {
  handleCloseDrawer: PropTypes.func.isRequired,
};

export default React.memo(MenuToolbar);
