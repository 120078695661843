export const columns = [
  {
    id: 'lastname',
    numeric: false,
    disablePadding: false,
  },
  {
    id: 'firstname',
    numeric: false,
    disablePadding: false,
  },
  {
    id: 'email',
    numeric: false,
    disablePadding: false,
  },
  {
    id: 'roles',
    numeric: false,
    disablePadding: false,
    label: 'role'
  },
];

export const defaultOrderBy = 'lastname';
