import { CONTENT_LANG_CHANGE } from './types';

export const contentLangChangeAction = lang => ({
  type: CONTENT_LANG_CHANGE,
  payload: lang
});

export function changeContentLang(lang) {
  return (dispatch) => {
    dispatch(contentLangChangeAction(lang));
  }
}