import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Select from '@material-ui/core/Select';
import {
  getBoundariesRallyCars,
  getBrandsRallyCars,
  getDimensionsRallyCars,
  getGroupsRallyCars,
  getModelsRallyCars,
  getRallyCarsCsv,
  reset,
  setRallySelectedCarState,
  setRallySelectedIsPublic,
} from '../../../store/searchRallyCars/actions';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import MTCButton from '../../Layout/Button/MTCButton';
import Link from '@material-ui/core/Link';
import CsvFile from '../../icon/CsvFile';
import { useStyles } from './RallyCarSearch.style';
import { INITIAL_STATE } from './RallyCarSearch.const';

const RallyCarSearch = ({ handleSearch, handleReset }) => {
  const classes = useStyles();
  const {
    rally_brands,
    rally_brands_pending,
    selected_rally_brand,
    rally_models,
    rally_models_pending,
    selected_rally_model,
    rally_groups,
    rally_groups_pending,
    selected_rally_group,
    rally_dimensions,
    rally_dimensions_pending,
    selected_rally_dimension,
    selected_is_public,
    selected_rally_car_state,
    error,
  } = useSelector(state => state.searchRallyCars);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [state, setState] = useState(INITIAL_STATE);

  if (!rally_brands && !rally_brands_pending && !error) {
    dispatch(getBrandsRallyCars());
  }


  if (selected_is_public !== null && state.is_public === INITIAL_STATE.is_public) {
    setState({...state, is_public: selected_is_public});
  }
  if (
    selected_rally_car_state !== null
    && state.rally_car_state === INITIAL_STATE.rally_car_state
  ) {
    setState({...state, rally_car_state: selected_rally_car_state});
  }
  if (selected_rally_brand !== state.rallyBrand) {
    setState({
      ...state,
      rallyBrand: selected_rally_brand,
    });
  }
  if (selected_rally_model !== state.rallyModel) {
    setState({
      ...state,
      rallyModel: selected_rally_model,
    });
  }
  if (selected_rally_group !== state.rallyGroup) {
    setState({
      ...state,
      rallyGroup: selected_rally_group,
    });
  }
  if (selected_rally_dimension !== state.rallyDimension) {
    setState({
      ...state,
      rallyDimension: selected_rally_dimension,
    });
  }

  const onRallyBrandChange = (event) => {
    setState({
      ...state,
      rallyBrand: event.target.value,
      rallyModel: '',
      rallyGroup: '',
      rallyDimension: '',
    });
    dispatch(getModelsRallyCars(event.target.value));
    event.stopPropagation();
  };

  const onRallyModelChange = (event) => {
    setState({
      ...state,
      rallyModel: event.target.value,
      rallyGroup: '',
      rallyDimension: '',
    });
    dispatch(
      getGroupsRallyCars(state.rallyBrand, event.target.value),
    );
    event.stopPropagation();
  };

  const onRallyGroupChange = (event) => {
    setState({
      ...state,
      rallyGroup: event.target.value,
      rallyDimension: '',
    });
    dispatch(
      getDimensionsRallyCars(state.rallyBrand, state.rallyModel, event.target.value),
    );
    event.stopPropagation();
  };

  const onRallyDimensionChange = (event) => {
    setState({
      ...state,
      rallyDimension: event.target.value,
    });
    dispatch(
      getBoundariesRallyCars(state.rallyBrand,
        state.rallyModel,
        state.rallyGroup,
        event.target.value,
      ),
    );
    event.stopPropagation();
  };

  const onIsPublicChange = (event) => {
    event.stopPropagation();
    setState({
      ...state,
      is_public: event.target.value,
    });
    dispatch(
      setRallySelectedIsPublic(event.target.value === INITIAL_STATE.is_public
        ? null
        : event.target.value
      )
    );
  }

  const onRallyCarStateChange = (event) => {
    event.stopPropagation();
    setState({
      ...state,
      rally_car_state: event.target.value,
    });
    dispatch(
      setRallySelectedCarState(event.target.value === INITIAL_STATE.rally_car_state
        ? null
        : event.target.value
      )
    );
  }

  const handleClickReset = (event) => {
    setState(INITIAL_STATE);
    dispatch(reset());
    handleReset();
    event.stopPropagation();
  };

  const handleClickSearch = (event) => {
    let search = '';
    if (state.rallyBrand !== '') {
      search = `brandId=${state.rallyBrand}`;
    }
    if (state.rallyModel !== '') {
      if (search === '') {
        search = `modelId=${state.rallyModel}`;
      } else {
        search = search + `&modelId=${state.rallyModel}`;
      }
    }
    if (state.rallyGroup !== '') {
      if (search === '') {
        search = `groupId=${state.rallyGroup}`;
      } else {
        search = search + `&groupId=${state.rallyGroup}`;
      }
    }
    if (state.rallyDimension !== '') {
      if (search === '') {
        search = `dimensionId=${state.rallyDimension}`;
      } else {
        search = search + `&dimensionId=${state.rallyDimension}`;
      }
    }
    if (state.is_public !== '') {
      if (search === '') {
        search = `isPublic=${state.is_public}`;
      } else {
        search = search + `&isPublic=${state.is_public}`;
      }
    }
    if (state.rally_car_state !== '') {
      if (search === '') {
        search = `carState=${state.rally_car_state}`;
      } else {
        search = search + `&carState=${state.rally_car_state}`;
      }
    }
    handleSearch(search);
    if (event) {
      event.stopPropagation();
    }
  };

  const exportCsv = (event) => {
    event.preventDefault();
    event.stopPropagation();
    dispatch(
      getRallyCarsCsv(
        state.rallyBrand,
        state.rallyModel,
        state.rallyGroup,
        state.rallyDimension,
        state.is_public,
        state.rally_car_state
      ),
    );
  };
  return (
    <Paper className={classes.paper}>
      <Grid
        container
        spacing={2}
        justifyContent="flex-start"
        alignItems="stretch"
        className={classes.form}
      >
        <Grid item>
          <FormControl className={classes.select}>
            <InputLabel id="rally_brands_select_label">
              {t('rally_brand_id')}
            </InputLabel>
            <Select
              id={'rally_brands_select'}
              labelId={'rally_brands_select_label'}
              onChange={onRallyBrandChange}
              value={state.rallyBrand}
            >
              {rally_brands
              && rally_brands.map((b) =>
                <MenuItem key={b.id} value={b.id}>
                  {b.name}
                </MenuItem>,
              )}
            </Select>
          </FormControl>
        </Grid>

        <Grid item>
          <FormControl className={classes.select}>
            <InputLabel id="rally_models_select_label">
              {t('rally_model_id')}
            </InputLabel>
            <Select
              id={'rally_models_select'}
              labelId={'rally_models_select_label'}
              onChange={onRallyModelChange}
              value={state.rallyModel}
            >
              {!rally_models_pending
              && rally_models
              && rally_models.map((m) =>
                <MenuItem key={m.id} value={m.id}>
                  {m.name}
                </MenuItem>,
              )}
            </Select>
          </FormControl>
        </Grid>

        <Grid item>
          <FormControl className={classes.select}>
            <InputLabel id="rally_groups_select_label">
              {t('rally_group_id')}
            </InputLabel>
            <Select
              id={'rally_groups_select'}
              labelId={'rally_groups_select_label'}
              onChange={onRallyGroupChange}
              value={state.rallyGroup}
            >
              {!rally_groups_pending
              && rally_groups
              && rally_groups.map((r) =>
                <MenuItem key={r.id} value={r.id}>
                  {r.name}
                </MenuItem>,
              )}
            </Select>
          </FormControl>
        </Grid>

        <Grid item>
          <FormControl className={classes.select}>
            <InputLabel id="rally_dimensions_select_label">
              {t('rally_dimension_front_dimension')}
            </InputLabel>
            <Select
              id={'rally_dimensions_select'}
              labelId={'rally_dimensions_select_label'}
              onChange={onRallyDimensionChange}
              value={state.rallyDimension}
            >
              {!rally_dimensions_pending
              && rally_dimensions
              && rally_dimensions.map((d) =>
                <MenuItem key={d.id} value={d.id}>
                  {d.frontDimension} / {d.rearDimension}
                </MenuItem>,
              )}
            </Select>
          </FormControl>
        </Grid>

        <Grid item>
          <FormControl className={classes.select}>
            <InputLabel shrink={true} id="rally_is_public_select_label">
              {t('is_public')}
            </InputLabel>
            <Select
              id={'rally_is_public_select'}
              labelId={'rally_is_public_select_label'}
              onChange={onIsPublicChange}
              value={state.is_public}
              displayEmpty={true}
            >
              <MenuItem key={'rally_allStatus'} value={''}>
                <em>{t('all_mode')}</em>
              </MenuItem>
              <MenuItem key={'rally_is_public_yes'} value={'1'}>
                {t('public')}
              </MenuItem>
              <MenuItem key={'rally_is_public_no'} value={'0'}>
                {t('tester')}
              </MenuItem>
            </Select>
          </FormControl>
        </Grid>

        <Grid item>
          <FormControl className={classes.select}>
            <InputLabel shrink={true} id="rally_car_state_select_label">{t('display')}</InputLabel>
            <Select
              id={'rally_car_state_select'}
              labelId={'rally_car_state_select_label'}
              onChange={onRallyCarStateChange}
              value={state.rally_car_state}
              displayEmpty={true}
            >
              <MenuItem key={'allStates'} value={''}>
                <em>{t('all_mode')}</em>
              </MenuItem>
              <MenuItem key={'state_visible'} value={'0'}>
                {t('all_visible')}
              </MenuItem>
              <MenuItem key={'state_hide'} value={'1'}>
                {t('all_hidden')}
              </MenuItem>
            </Select>
          </FormControl>
        </Grid>
      </Grid>
      <Grid container justifyContent={'center'} alignItems={'center'} spacing={2}>
        <Grid item>
          <MTCButton
            id={'rally_search_button'}
            text={t('search')}
            onClick={handleClickSearch}
            style={{ width: 200 }}
          />
        </Grid>
        <Grid item>
          <MTCButton
            id={'reset_button'}
            text={t('reset')}
            onClick={handleClickReset}
            color={'primary'}
            style={{ width: 200 }}
          />
        </Grid>
      </Grid>

      <Grid container justifyContent={'center'} alignItems={'center'} spacing={2}>
        <Link href={'#'} onClick={exportCsv} className={classes.link}>
          <CsvFile size={'20'} style={{ verticalAlign: 'text-bottom', marginRight: '5px' }}/>
          {t('search_csv_cars_export')}
        </Link>
      </Grid>
    </Paper>
  );
};

export default RallyCarSearch;
