import React, { useState } from 'react';
import { Avatar, Grid, ListItem, ListItemText, Typography } from '@material-ui/core';
import Timestamp from 'react-timestamp';
import { Rating } from '@material-ui/lab';
import { useStyles } from './TrackCommentsListItem.style';
import { useTranslation } from 'react-i18next';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { useDispatch } from 'react-redux';
import { putTrackComments } from '../../../store/trackComments/actions';
import HighlightOffRoundedIcon from '@material-ui/icons/HighlightOffRounded';
import CheckCircleOutlineRoundedIcon from '@material-ui/icons/CheckCircleOutlineRounded';
import MTCButton from '../../Layout/Button/MTCButton';
import { michelin_green, michelin_red, michelin_white } from '../../../constants/michelin_colors';
import { resetHome } from '../../../store/home/actions';

const TrackCommentsListItem = ({ comment }) => {

  const dispatch = useDispatch();
  const classes = useStyles();
  const { t } = useTranslation();

  const [full, setFull] = useState(false);

  const handleClick = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setFull(!full);
  };

  const handleDelete = (event) => {
    event.preventDefault();
    event.stopPropagation();
    dispatch(putTrackComments(comment.id, { state: 2 })).then(
      () => {
        dispatch(resetHome());
      }
    );
  };

  const handleIsSafe = (event) => {
    event.preventDefault();
    event.stopPropagation();
    dispatch(putTrackComments(comment.id, { state: 0 })).then(
      () => {
        dispatch(resetHome());
      }
    );
  };

  return (
    <>
      <ListItem alignItems="flex-start" className={classes.item}>
        <ListItemText
          primary={
            <Grid container alignItems={'center'} spacing={2}>
              <Grid item>
                <Avatar className={classes.avatar}>
                  {comment.nickname.charAt(0).toUpperCase()}
                </Avatar>
              </Grid>
              <Grid item>
                <Typography
                  component="span"
                  variant="subtitle1"
                  color="textPrimary"
                >
                  {comment.nickname}
                </Typography>
              </Grid>
              {comment.state != 0 && comment.report_count > 0 &&
              <Grid item>
                <Typography
                  component="span"
                  variant="body1"
                  color="textPrimary"
                  className={classes.reportCount}
                >
                  Signalé par {comment.report_count} utilisateurs
                </Typography>
              </Grid>
              }
            </Grid>
          }
          secondary={
            <div className={classes.commentContainer}>
              <Grid container alignItems="flex-start" wrap={'nowrap'}>
                <Grid item>
                  <Grid
                    container
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                  >
                  </Grid>
                </Grid>
                <Grid item>
                  <Grid container alignItems={'center'} spacing={2}>
                    <Grid item>
                      <Rating name="read-only" value={comment.global_rating} readOnly />
                    </Grid>
                    <Grid item>
                      <Typography
                        component="span"
                        variant="caption"
                        color="textPrimary"
                      >
                        <Timestamp
                          format="date"
                          time={comment.created_at}
                        />
                      </Typography>
                    </Grid>
                  </Grid>
                  <Typography
                    component="p"
                    variant="h6"
                    color="textPrimary"
                    className={classes.title}
                  >
                    {comment.title}
                  </Typography>
                  {comment.comment}

                </Grid>
              </Grid>
              {!full
              && <div className={classes.arrow} onClick={handleClick}>
                <Typography variant={'caption'} component="p" className={classes.arrowText}>
                  {t('seeMore')}
                </Typography>
                <KeyboardArrowDownIcon fontSize="small" color={'primary'} />
              </div>
              }
              {full
              && <Grid
                container
                spacing={2}
                className={classes.ratingContainer}
                alignItems={'center'}
                justifyContent={'center'}
              >
                <Grid item>
                  <Typography variant={'body1'}>{t('security_rating')}</Typography>
                  <Rating name="read-only" value={comment.security_rating} readOnly />
                </Grid>
                <Grid item>
                  <Typography variant={'body1'}>{t('technicality_rating')}</Typography>
                  <Rating name="read-only" value={comment.technicality_rating} readOnly />
                </Grid>
                <Grid item>
                  <Typography variant={'body1'}>{t('speed_rating')}</Typography>
                  <Rating name="read-only" value={comment.speed_rating} readOnly />
                </Grid>
                <Grid item>
                  <Typography variant={'body1'}>{t('surfacing_rating')}</Typography>
                  <Rating name="read-only" value={comment.surfacing_rating} readOnly />
                </Grid>
                <Grid item>
                  <Typography variant={'body1'}>{t('infrastructure_rating')}</Typography>
                  <Rating name="read-only" value={comment.infrastructure_rating} readOnly />
                </Grid>
                <Grid item>
                  <Typography variant={'body1'}>{t('welcome_rating')}</Typography>
                  <Rating name="read-only" value={comment.welcome_rating} readOnly />
                </Grid>
              </Grid>
              }

              {full
              && <div className={classes.arrow} onClick={handleClick}>
                <Typography variant={'caption'} component="p" className={classes.arrowText}>
                  {t('seeLess')}
                </Typography>
                <KeyboardArrowUpIcon fontSize="small" color={'primary'} />
              </div>
              }

              <Grid container justifyContent={'flex-end'} spacing={2}>
                {comment.state > 0
                && <Grid item>
                  <MTCButton
                    onClick={handleIsSafe}
                    style={{
                      backgroundColor: michelin_green,
                      color: michelin_white,
                    }}
                    startIcon={<CheckCircleOutlineRoundedIcon />}
                    text={t('restore')}
                  />
                </Grid>
                }
                {comment.state < 2
                && <Grid item>
                  <MTCButton
                    onClick={handleDelete}
                    style={{
                      backgroundColor: michelin_red,
                      color: michelin_white,
                    }}
                    startIcon={<HighlightOffRoundedIcon />}
                    text={t('delete')}
                  />
                </Grid>
                }
              </Grid>
            </div>
          }
        />
      </ListItem>
    </>
  );
};

export default TrackCommentsListItem;
