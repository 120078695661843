import makeStyles from '@material-ui/core/styles/makeStyles';

export const useStyles = makeStyles(() => {
  return {
    root: {
      flexGrow: 1,
    },
    label: {
      padding: '8px 16px',
    },
  };
});
