import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import withAuthentication from '../../../hoc/withAuthentication';
import { ROLE_ADMIN } from '../../../constants/roles';
import { setTitle } from '../../../store/title/actions';
import FaqArticlesForm from '../../../components/Faq/Articles/FaqArticlesForm/FaqArticlesForm';

export const FaqArticleAddPage = () => {
  const { t } = useTranslation();
  const { title } = useSelector(state => state.title);
  const dispatch = useDispatch();
  if (title !== t('title:title.add_faq_articles_page')) {
    dispatch(setTitle(t('title:title.add_faq_articles_page')));
  }

  return <FaqArticlesForm/>;
}

export default withAuthentication(ROLE_ADMIN)(FaqArticleAddPage);
