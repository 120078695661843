export const simpleRowStyle = (theme) => (
  {
    '&:nth-of-type(odd)': {
      backgroundColor: '#FFFFFF',
      color: theme.palette.secondary.contrastText,
    },
  }
);

export const clickableRowStyle = (theme) => (
  {
      ...simpleRowStyle(theme),
      cursor: 'pointer',
  }
);
