import makeStyles from '@material-ui/core/styles/makeStyles';

export const useStyles = makeStyles(theme => {
  return {
    head: {
      color: theme.palette.secondary.contrastText,
    },
    sortLabel: {
      color: theme.palette.secondary.contrastText,
    },
    noSortLabel: {
      color: theme.palette.secondary.contrastText,
      cursor: 'default',
    },
    headerRow: {
      borderBottom: '3px solid ' + theme.palette.primary.main,
    },
  };
});
