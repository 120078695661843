const LANG_CALL_CENTERS_PENDING = 'lang_call_centers_pending';
const LANG_CALL_CENTERS_FAILED = 'lang_call_centers_failed';
const LANG_CALL_CENTERS_DOWNLOADED = 'lang_call_centers_downloaded';
const LANG_CALL_CENTER_SENDING = 'lang_call_center_sending';
const LANG_CALL_CENTER_DOWNLOADED = 'lang_call_center_downloaded';
const LANG_CALL_CENTER_DELETED = 'lang_call_center_deleted';

export {
  LANG_CALL_CENTERS_PENDING,
  LANG_CALL_CENTERS_FAILED,
  LANG_CALL_CENTERS_DOWNLOADED,
  LANG_CALL_CENTER_SENDING,
  LANG_CALL_CENTER_DOWNLOADED,
  LANG_CALL_CENTER_DELETED
};
