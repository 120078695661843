import {
  RALLY_CAR_FAILED,
  RALLY_CAR_PENDING,
  RALLY_CARS_BOUNDARIES_DOWNLOADED,
  RALLY_CARS_BOUNDARIES_PENDING,
  RALLY_CARS_BRANDS_DOWNLOADED,
  RALLY_CARS_BRANDS_PENDING,
  RALLY_CARS_DIMENSIONS_DOWNLOADED,
  RALLY_CARS_DIMENSIONS_PENDING,
  RALLY_CARS_GROUPS_DOWNLOADED,
  RALLY_CARS_GROUPS_PENDING,
  RALLY_CARS_MODELS_DOWNLOADED,
  RALLY_CARS_MODELS_PENDING,
  RALLY_CAR_RESET,
  RALLY_CAR_TABLE_LIMIT_CHANGE,
  RALLY_CARS_CSV_DOWNLOADED,
  RALLY_CHANGE_SEARCH_CAR_STATE,
  RALLY_CHANGE_SEARCH_IS_PUBLIC,
} from './types';
import ApiService from '../../services/ApiService';
import { handleError } from '../infos/actions';

export const rallyCarPendingAction = () => (
  { type: RALLY_CAR_PENDING }
);
export const rallyCarsCsvDownloadedAction = () => (
  { type: RALLY_CARS_CSV_DOWNLOADED }
);
export const rallyCarsBrandsPendingAction = () => (
  { type: RALLY_CARS_BRANDS_PENDING }
);
export const rallyCarsModelsPendingAction = () => (
  { type: RALLY_CARS_MODELS_PENDING }
);
export const rallyCarsGroupsPendingAction = () => (
  { type: RALLY_CARS_GROUPS_PENDING }
);
export const rallyCarsDimensionsPendingAction = () => (
  { type: RALLY_CARS_DIMENSIONS_PENDING }
);
export const rallyCarsBoundariesPendingAction = () => (
  { type: RALLY_CARS_BOUNDARIES_PENDING }
);
export const rallyCarsBrandsDownloadedAction = (data) => (
  {
    type: RALLY_CARS_BRANDS_DOWNLOADED,
    payload: { data },
  }
);
export const ChangeRallySelectedIsPublicAction = (isPublic) => (
  {
    type: RALLY_CHANGE_SEARCH_IS_PUBLIC,
    payload: isPublic,
  }
);
export const ChangeRallySelectedCarStateAction = (carState) => (
  {
    type: RALLY_CHANGE_SEARCH_CAR_STATE,
    payload: carState,
  }
);

export const rallyCarTableLimitChangeAction = (data) => (
  {
    type: RALLY_CAR_TABLE_LIMIT_CHANGE,
    payload: data,
  }
);
export const rallyCarsModelsDownloadedAction = (data, id) => (
  { type: RALLY_CARS_MODELS_DOWNLOADED,
    payload: {
      data,
      id,
    },
  }
);
export const rallyCarsGroupsDownloadedAction = (data, id) => (
  { type: RALLY_CARS_GROUPS_DOWNLOADED,
    payload: {
      data,
      id,
    },
  }
);
export const rallyCarsDimensionsDownloadedAction = (data, id) => (
  { type: RALLY_CARS_DIMENSIONS_DOWNLOADED,
    payload: {
      data,
      id,
    },
  }
);
export const rallyCarsBoundariesDownloadedAction = (data, id) => (
  { type: RALLY_CARS_BOUNDARIES_DOWNLOADED,
    payload: {
      data,
      id,
    },
  }
);
export const rallyCarFailedAction = error => (
  {
    type: RALLY_CAR_FAILED,
    payload: error,
  }
);
export const rallyCarsResetAction = () => (
  {type: RALLY_CAR_RESET}
);

export function changeLimit(limit) {
  return (dispatch) => {
    dispatch(rallyCarTableLimitChangeAction(limit));
  }
}
export function reset() {
  return (dispatch) => {
    dispatch(rallyCarsResetAction());
  }
}

export function setRallySelectedIsPublic(isPublic) {
  return (dispatch) => {
    dispatch(ChangeRallySelectedIsPublicAction(isPublic));
  };
}

export function setRallySelectedCarState(carState) {
  return (dispatch) => {
    dispatch(ChangeRallySelectedCarStateAction(carState));
  };
}

export function getBrandsRallyCars() {
  return (dispatch) => {
    dispatch(rallyCarsBrandsPendingAction());
    return ApiService
      .getRallyCarsBrands()
      .then((data) => {
        dispatch(rallyCarsBrandsDownloadedAction(data));
        if (data.length === 1) {
          dispatch(getModelsRallyCars(data[0].id));
        }
      })
      .catch((error) => {
        dispatch(rallyCarFailedAction(error));
        dispatch(handleError(error));
      });
  };
}

export function getModelsRallyCars(brandId) {
  return (dispatch) => {
    dispatch(rallyCarsModelsPendingAction());
    return ApiService
      .getRallyCarsModels(brandId)
      .then((data) => {
        dispatch(rallyCarsModelsDownloadedAction(data, brandId));
        if (data.length === 1) {
          dispatch(getGroupsRallyCars(brandId, data[0].id));
        }
      })
      .catch((error) => {
        dispatch(rallyCarFailedAction(error));
        dispatch(handleError(error));
      });
  };
}

export function getGroupsRallyCars(brandId, modelId) {
  return (dispatch) => {
    dispatch(rallyCarsGroupsPendingAction());
    return ApiService
      .getRallyCarsGroups(brandId, modelId)
      .then((data) => {
        dispatch(rallyCarsGroupsDownloadedAction(data, modelId));
        if (data.length === 1) {
          dispatch(getDimensionsRallyCars(brandId, modelId, data[0].id));
        }
      })
      .catch((error) => {
        dispatch(rallyCarFailedAction(error));
        dispatch(handleError(error));
      });
  };
}

export function getDimensionsRallyCars(brandId, modelId, groupId) {
  return (dispatch) => {
    dispatch(rallyCarsDimensionsPendingAction());
    return ApiService
      .getRallyCarsDimensions(brandId, modelId, groupId)
      .then((data) => {
        dispatch(rallyCarsDimensionsDownloadedAction(data, groupId));
        if (data.length === 1) {
          dispatch(getBoundariesRallyCars(brandId, modelId, groupId, data[0].id));
        }
      })
      .catch((error) => {
        dispatch(rallyCarFailedAction(error));
        dispatch(handleError(error));
      });
  };
}

export function getBoundariesRallyCars(brandId, modelId, groupId, dimensionId) {
  return (dispatch) => {
    dispatch(rallyCarsBoundariesPendingAction());
    return ApiService
      .getRallyCarsBoundaries(brandId, modelId, groupId, dimensionId)
      .then((data) => {
        dispatch(rallyCarsBoundariesDownloadedAction(data, dimensionId));
      })
      .catch((error) => {
        dispatch(rallyCarFailedAction(error));
        dispatch(handleError(error));
      });
  };
}

export function getRallyCarsCsv(brandId, modelId, groupId, dimensionId, isPublic, carState) {
  return (dispatch) => {
    dispatch(rallyCarPendingAction());
    return ApiService
      .getRallyCarsCsv(brandId, modelId, groupId, dimensionId, isPublic, carState)
      .then(() => {
        dispatch(rallyCarsCsvDownloadedAction());
      })
      .catch((error) => {
        dispatch(rallyCarFailedAction(error));
        dispatch(handleError(error));
      });
  };
}