import makeStyles from '@material-ui/core/styles/makeStyles';

export const useStyles = makeStyles(() => {
  return {
    container: {
      backgroundColor: "#FFF",
      textAlign: "left",
      marginTop: '10px',
      marginBottom: '10px',
      height: "calc(100% - 20px)",
    },
    title: {
      marginTop: "30px",
      marginBottom: "50px"
    }
  };
});
