import {
  DEFAULT_CALL_CENTER_PENDING,
  DEFAULT_CALL_CENTER_FAILED,
  DEFAULT_CALL_CENTER_DOWNLOADED,
  DEFAULT_CALL_CENTER_SENDING
} from './types';
import ApiService from '../../services/ApiService';
import { handleError } from '../infos/actions';

export const defaultCallCenterSendingAction = () => ({
  type: DEFAULT_CALL_CENTER_SENDING,
});

export const defaultCallCenterDownloadedAction = defaultCallCenter => ({
  type: DEFAULT_CALL_CENTER_DOWNLOADED,
  payload: defaultCallCenter,
});

export const defaultCallCentersFailedAction = error => ({
  type: DEFAULT_CALL_CENTER_FAILED,
  payload: error,
});

export const defaultCallCentersPendingAction = () => ({
  type: DEFAULT_CALL_CENTER_PENDING,
});

export function getDefaultCallCenters() {
  return (dispatch) => {
    dispatch(defaultCallCentersPendingAction());
    return ApiService
      .getDefaultCallCenters()
      .then((data) => {
        dispatch(defaultCallCenterDownloadedAction(data));
      })
      .catch((error) => {
        dispatch(defaultCallCentersFailedAction(error));
        dispatch(handleError(error));
      });
  };
}

export function putDefaultCallCenter(defaultCallCenter) {
  return (dispatch) => {
    dispatch(defaultCallCenterSendingAction());
    return ApiService
      .putDefaultCallCenter(defaultCallCenter)
      .then((data) => {
        dispatch(defaultCallCenterDownloadedAction(data));
      })
      .catch((error) => {
        dispatch(defaultCallCentersFailedAction(error));
        dispatch(handleError(error));
      });
  };
}