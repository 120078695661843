import {
  TIRE_DOWNLOADED,
  TIRE_SENDING,
  TIRES_DOWNLOADED,
  TIRES_FAILED,
  TIRES_PENDING,
} from './types';

export const INITIAL_STATE = {
  error: null,
  content: null,
  pending: false,
  sending: false,
};

const emptyTires = {
  brands: [],
  types: [],
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case TIRE_DOWNLOADED: {
      let tires = state.content ? JSON.parse(JSON.stringify(state.content)) : emptyTires;

      //For each payload keys (with special case of boundaries)
      let tireKeys = Object.keys(action.payload);
      let keyIndex = -1;
      tireKeys.forEach(key => {
        const keysForCarsJSON = key + 's';
        const tireItemForKey = action.payload[key];

        //Retrieve matching list

        let itemsWithKey = tires[keysForCarsJSON].slice();
        //FindIndex of current key with id
        keyIndex = itemsWithKey.findIndex(item => item.id === tireItemForKey.id);
        if (keyIndex === -1) {
          tires[keysForCarsJSON].push(tireItemForKey);
        } else {
          tires[keysForCarsJSON][keyIndex] = tireItemForKey;
        }
      });
      return {
        error: null,
        pending: false,
        sending: false,
        content: tires,
      };
    }
    case TIRE_SENDING:
      return {
        ...state,
        sending: true,
        error: null,
      };
    case TIRES_DOWNLOADED:
      return {
        content: action.payload,
        error: null,
        pending: false,
        sending: false,
      };
    case TIRES_FAILED:
      return {
        ...state,
        error: action.payload,
        pending: false,
        sending: false,
      };
    case TIRES_PENDING:
      return {
        ...state,
        pending: true,
        error: null,
      };
    default:
      break;
  }
  return state;
}
