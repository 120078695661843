import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import useTheme from '@material-ui/core/styles/useTheme';
import makeStyles from '@material-ui/core/styles/makeStyles';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import TableCell from '@material-ui/core/TableCell';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';
import { deleteCallCenters } from '../../store/callCenters/actions';
import MTCDialog from '../Form/MTCDialog';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { CALL_CENTERS_EDIT } from '../../constants/routes';
import { clickableRowStyle } from '../../constants/styles/row.style';

const countries = require('country-list')();

const useStyles = makeStyles(theme => {
  return {
    row: clickableRowStyle(theme),
    button: {
      color: 'inherit',
    },
  };
});

const CallCenterRow = ({ value }) => {

  const theme = useTheme();
  const history = useHistory();
  const classes = useStyles(theme);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [openDialog, setOpenDialog] = useState(false);

  const handleEdit = event => {
    event.stopPropagation();
    history.push(CALL_CENTERS_EDIT.replace(':callCenterId', value.id));
  };

  const handleDialogRemove = event => {
    setOpenDialog(true);
    event.stopPropagation();
  }

  const handleCloseDialog =  event => {
    event.stopPropagation();
    setOpenDialog(false);
  }

  const handleRemove = event => {
    event.stopPropagation();
    dispatch(deleteCallCenters(value));
    setOpenDialog(false);
  };

  return (
    <TableRow
      className={classes.row}
      key={value.id}
      hover
      onClick={handleEdit}
      tabIndex={-1}
    >
      <TableCell>
        <Typography>
          {value.country_code} - {countries.getName(value.country_code)}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography>
          {value.lang} - {t('lang:' + value.lang.toLowerCase())}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography>
          {value.phone}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography>
          {t('opening-' + value.opening)}
        </Typography>
      </TableCell>
      <TableCell align={'right'}>
        <IconButton
          id={`edit-${value.id}`}
          aria-label="update"
          onClick={handleEdit}
          className={classes.button}
        >
          <EditIcon />
        </IconButton>
        <IconButton
          id={`remove-${value.id}`}
          aria-label="delete"
          onClick={handleDialogRemove}
          className={classes.button}
        >
          <DeleteIcon />
        </IconButton>
      </TableCell>
        <MTCDialog
          open={openDialog}
          handleClose={handleCloseDialog}
          dialogTitle={t('call_center_delete_dialog_title')}
          dialogText={t('call_center_delete_dialog_text')}
          handleCloseCancel={handleCloseDialog}
          handleCloseOK={handleRemove}
          cancelText={t('no')}
          okText={t('yes')}
        />
    </TableRow>
  );
}

export default React.memo(CallCenterRow);
