import React from 'react';
import CatalogsTagRow from './CatalogsTagRow';
import MTCTable from '../../Layout/Table/MTCTable';
import { useTranslation } from 'react-i18next';
import MTCLoader from '../../Layout/MTCLoader';
import { columns, defaultOrderBy } from './CatalogsTagsTable.const';

export const CatalogsTagsTable = ({pending, content}) => {
  const { t } = useTranslation();
  if (pending) {
    return <MTCLoader />;
  } else {
    return <MTCTable
      content={content}
      defaultOrderBy={defaultOrderBy}
      defaultOrder={'asc'}
      rowComponent={CatalogsTagRow}
      columns={columns}
      paginationLabel={t('catalogs_tags_per_page')}
      emptyDataLabel={t('no_catalogs_tags')}
    />;
  }
}

export default CatalogsTagsTable;
