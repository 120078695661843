import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import MTCButton from '../../Layout/Button/MTCButton';
import { CAR_DUPLICATE, CARS } from '../../../constants/routes';
import Error404 from '../../../containers/Error404';
import { formReset, getDimension, putCar } from '../../../store/formCars/actions';
import Typography from '@material-ui/core/Typography';
import { ORIGIN, WEATHER_TYPE } from '../../../store/formCars/types';
import MTCLoader from '../../Layout/MTCLoader';
import {
  getBrandsCars as getTableBrandsCars,
  getDimensionsCars as getTableDimensionsCars,
  getEnginesCars as getTableEnginesCars,
  getModelsCars as getTableModelsCars,
  getRangesCars as getTableRangesCars,
} from '../../../store/searchCars/actions';
import { getTableCars } from '../../../store/tableCars/actions';
import MTCSwitch from '../../Form/MTCSwitch';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import MTCTextField from '../../Form/MTCTextField';
import Paper from '@material-ui/core/Paper';
import BoundaryRangeIndicator from './BoundaryRangeIndicator';
import BoundaryForm from './BoundaryForm';
import EditIcon from '@material-ui/icons/Edit';
import { PSC2, PSC2R } from '../../../constants/raceTireType';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import AppBar from '@material-ui/core/AppBar';
import {
  emptyBoundaries,
  expertDimensionWithoutBoundary,
  hasEmptyBoundaries,
  hasLessThanBoundaries,
} from './CarFormUtils';
import { handleError } from '../../../store/infos/actions';
import { useStyles } from './EditCarForm.style';
import { INITIAL_STATE, stateToApiFormat } from './EditCarForm.const';

const EditCarForm = ({ dimensionId }) => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [state, setState] = useState(INITIAL_STATE);
  const [editBrand, setEditBrand] = useState(false);
  const [editRange, setEditRange] = useState(false);
  const [editRangeModel, setEditRangeModel] = useState(false);
  const [editEngine, setEditEngine] = useState(false);
  const [tireTypeTab, setTireTypeTab] = useState(0);
  const [canEditPsc2Switch, setCanEditPsc2Switch] = useState(true);
  const [canEditPsc2rSwitch, setCanEditPsc2rSwitch] = useState(true);

  const {
    dimension,
    dimension_pending,
    sending,
    error,
  } = useSelector(state => state.formCars);

  if ((
    !dimension || dimension.dimension.id != dimensionId
  ) && !dimension_pending && !error) {
    dispatch(getDimension(dimensionId));
  }

  if (dimension && state.dimension === null) {
    const availableTireTypes = dimension.dimension.available_tire_types.split(',');
    setCanEditPsc2Switch(!availableTireTypes.includes(PSC2));
    setCanEditPsc2rSwitch(!availableTireTypes.includes(PSC2R));
    let newBoundaries = dimension.boundaries.length > 0
      ? dimension.boundaries.map(b => {
        return {
          red_orange: b.red_orange,
          orange_green: b.orange_green,
          green_orange: b.green_orange,
          orange_red: b.orange_red,
          is_front: b.is_front,
          weather_type: b.weather_type,
          tire_type: b.tire_type,
          dimension_id: b.dimension_id,
          id: b.id,
          state: b.state
        };
      })
      : emptyBoundaries(WEATHER_TYPE, [PSC2, PSC2R]);
    if (newBoundaries.length < 20 && !availableTireTypes.includes(PSC2)) {
      newBoundaries = newBoundaries.concat(emptyBoundaries(WEATHER_TYPE, [PSC2]));
    }
    if (newBoundaries.length < 20 && !availableTireTypes.includes(PSC2R)) {
      newBoundaries = newBoundaries.concat(emptyBoundaries(WEATHER_TYPE, [PSC2R]));
    }
    setState({
      ...state,
      brand: dimension.brand,
      range: dimension.range,
      model: dimension.range_model,
      engine: dimension.engine,
      dimension: {
        ...dimension.dimension,
        state: dimension.dimension.state == 0,
        available_tire_types: availableTireTypes,
        has_psc2: availableTireTypes.includes(PSC2),
        has_psc2r: availableTireTypes.includes(PSC2R),
      },
      boundaries: newBoundaries,
    });
  }

  const handleDuplicate = (event) => {
    event.stopPropagation();
    event.preventDefault();
    dispatch(formReset());
    history.push(CAR_DUPLICATE.replace(':dimensionId', dimensionId));
  };

  const callApi = () => {
    dispatch(putCar(stateToApiFormat(state, dimensionId))).then(async (data) => {
      await dispatch(getTableBrandsCars());
      await dispatch(getTableRangesCars(data.brand.id));
      await dispatch(getTableModelsCars(data.brand.id, data.range.id));
      await dispatch(getTableEnginesCars(data.brand.id, data.range.id, data.range_model.id));
      await dispatch(getTableDimensionsCars(data.brand.id,
        data.range.id,
        data.range_model.id,
        data.engine.id,
      ));
      // eslint-disable-next-line max-len
      let params = `brandId=${data.brand.id}&rangeId=${data.range.id}&modelId=${data.range_model.id}&engineId=${data.engine.id}&limit=50&page=0&order=ASC&orderBy=0`;
      await dispatch(getTableCars(params));
      history.push({
        pathname: CARS,
        state: {
          value: 0,
        },
      });
    });
  };

  const onSubmit = (event) => {
    event.preventDefault();
    if (
      hasLessThanBoundaries(state)
      || expertDimensionWithoutBoundary(state)
      || editBrand
      || editRange
      || editRangeModel
      || editEngine
    ) {
      setState({
        ...state,
        confirmDialogOpen: true,
      });
    } else {
      const missingBoundaries = hasEmptyBoundaries(state);
      if (missingBoundaries.length > 0) {
        if (missingBoundaries.includes(PSC2)) {
          setTireTypeTab(0)
        } else {
          if (state.dimension.has_psc2) {
            setTireTypeTab(1);
          } else {
            setTireTypeTab(0);
          }
        }
        const element = document.getElementById('tire-type-tabs');
        if (element) {
          element.scrollIntoView();
        }
        dispatch(handleError({message:t("missing_boundary_values")}))
      } else {
        callApi();
      }
    }
  };

  const handleCloseCancel = () => {
    setState({
      ...state,
      confirmDialogOpen: false,
    });
  };

  const handleCloseConfirm = () => {
    setState({
      ...state,
      confirmDialogOpen: false,
    });
    const missingBoundaries = hasEmptyBoundaries(state);
    if (missingBoundaries.length > 0) {
      if (missingBoundaries.includes(PSC2)) {
        setTireTypeTab(0)
      } else {
        if (state.dimension.has_psc2) {
          setTireTypeTab(1);
        } else {
          setTireTypeTab(0);
        }
      }
      const element = document.getElementById('tire-type-tabs');
      if (element) {
        element.scrollIntoView();
      }
      dispatch(handleError({message:t("missing_boundary_values")}))
    } else {
      callApi();
    }
  };

  const handleBrandEdit = () => {
    setState({
      ...state,
      brand: {
        ...state.brand,
        oldName: state.brand.name,
      },
    });
    setEditBrand(true);
  };
  const handleBrandStopEdit = () => {
    setState({
      ...state,
      brand: {
        ...state.brand,
        name: state.brand.oldName,
      },
    });
    setEditBrand(false);
  };

  const handleRangeEdit = () => {
    setState({
      ...state,
      range: {
        ...state.range,
        oldName: state.range.name,
      },
    });
    setEditRange(true);
  };
  const handleRangeStopEdit = () => {
    setState({
      ...state,
      range: {
        ...state.range,
        name: state.range.oldName,
      },
    });
    setEditRange(false);
  };

  const handleRangeModelEdit = () => {
    setState({
      ...state,
      model: {
        ...state.model,
        oldName: state.model.name,
      },
    });
    setEditRangeModel(true);
  };
  const handleRangeModelStopEdit = () => {
    setState({
      ...state,
      model: {
        ...state.model,
        name: state.model.oldName,
      },
    });
    setEditRangeModel(false);
  };

  const handleEngineEdit = () => {
    setState({
      ...state,
      engine: {
        ...state.engine,
        oldName: state.engine.name,
      },
    });
    setEditEngine(true);
  };
  const handleEngineStopEdit = () => {
    setState({
      ...state,
      engine: {
        ...state.engine,
        name: state.engine.oldName,
      },
    });
    setEditEngine(false);
  };

  const handleTireTypeTabChange = (event, newValue) => {
    event.stopPropagation();
    setTireTypeTab(newValue);
  };

  const onBoundaryValueChange = (isFront, weatherType, tireType, key, value) => {
    let boundaries = state.boundaries;
    if (!boundaries) {
      boundaries = emptyBoundaries(WEATHER_TYPE, [PSC2, PSC2R]);
    }
    let boundary = boundaries.find(stateBoundary => (
      stateBoundary.weather_type === weatherType
      && stateBoundary.is_front === isFront
      && stateBoundary.tire_type === tireType
    ));
    if (boundary) {
      setState(
        {
          ...state,
          boundaries:
            [
              ...boundaries.filter(stateBoundary => (
                  stateBoundary.weather_type !== boundary.weather_type
                  || stateBoundary.is_front !== boundary.is_front
                  || stateBoundary.tire_type !== boundary.tire_type
                ),
              ),
              {
                ...boundary,
                [key]: value,
              },
            ],
        },
      );
    }
  };

  if (error || (!dimension_pending && !state.dimension)) {
    return <Error404 />;
  } else if (dimension_pending || !state.dimension) {
    return <MTCLoader />;
  } else {
    return (
      <form onSubmit={onSubmit} className={classes.form}>
        <Grid
          container
          direction={'column'}
          justifyContent={'center'}
          alignItems={'center'}
          spacing={2}
        >
          {/*  BRAND  */}
          <Grid item xs={12} sm={12} className={classes.topPartText}>
            {editBrand
            && <MTCTextField
              gridMD={12}
              gridLG={12}
              id={'brand_name'}
              onChange={(event) => setState({
                ...state,
                brand: {
                  ...state.brand,
                  name: event.target.value,
                },
              })}
              value={state.brand.name}
              inputProps={{
                required: true,
              }}
              handleCancel={handleBrandStopEdit}
              fullWidth={true}
            />}
            {!editBrand && <div className={classes.topPartTextContainer}>
              <Typography
                color="primary"
                variant="subtitle1"
                gutterBottom
              >
                {t('brand.name')}
              </Typography>
              <Typography variant="subtitle1" gutterBottom className={classes.topPartValue}>
                {state.brand.name}
                <EditIcon className={classes.editIcon} onClick={handleBrandEdit} />
              </Typography>
            </div>}

          </Grid>

          {/*  RANGE  */}
          <Grid item xs={12} sm={12} className={classes.topPartText}>
            {editRange
            && <MTCTextField
              gridMD={12}
              gridLG={12}
              id={'range_name'}
              onChange={(event) => setState({
                ...state,
                range: {
                  ...state.range,
                  name: event.target.value,
                },
              })}
              value={state.range.name}
              inputProps={{
                required: true,
              }}
              handleCancel={handleRangeStopEdit}
              fullWidth={true}
            />}
            {!editRange && <div className={classes.topPartTextContainer}>
              <Typography
                color="primary"
                variant="subtitle1"
                gutterBottom>
                {t('range.name')}
              </Typography>
              <Typography variant="subtitle1" gutterBottom className={classes.topPartValue}>
                {state.range.name}
                <EditIcon className={classes.editIcon} onClick={handleRangeEdit} />
              </Typography>
            </div>}

          </Grid>

          {/*  MODEL  */}
          <Grid item xs={12} sm={12} className={classes.topPartText}>
            {editRangeModel
            && <MTCTextField
              gridMD={12}
              gridLG={12}
              id={'model_name'}
              onChange={(event) => setState({
                ...state,
                model: {
                  ...state.model,
                  name: event.target.value,
                },
              })}
              value={state.model.name}
              inputProps={{
                required: true,
              }}
              handleCancel={handleRangeModelStopEdit}
              fullWidth={true}
            />}
            {!editRangeModel && <div className={classes.topPartTextContainer}>
              <Typography
                color="primary"
                variant="subtitle1"
                gutterBottom>
                {t('model.name')}
              </Typography>
              <Typography variant="subtitle1" gutterBottom className={classes.topPartValue}>
                {state.model.name}
                <EditIcon className={classes.editIcon} onClick={handleRangeModelEdit} />
              </Typography>
            </div>}

          </Grid>

          {/*  ENGINE  */}
          <Grid item xs={12} sm={12} className={classes.topPartText}>
            {editEngine
            && <MTCTextField
              gridMD={12}
              gridLG={12}
              id={'engine_name'}
              onChange={(event) => setState({
                ...state,
                engine: {
                  ...state.engine,
                  name: event.target.value,
                },
              })}
              value={state.engine.name}
              inputProps={{
                required: true,
              }}
              handleCancel={handleEngineStopEdit}
              fullWidth={true}
            />}
            {!editEngine && <div className={classes.topPartTextContainer}>
              <Typography
                color="primary"
                variant="subtitle1"
                gutterBottom>
                {t('engine.name')}
              </Typography>
              <Typography variant="subtitle1" gutterBottom className={classes.topPartValue}>
                {state.engine.name}
                <EditIcon className={classes.editIcon} onClick={handleEngineEdit} />
              </Typography>
            </div>}

          </Grid>

          {state.dimension.compatible_with_receiver
          && <>
            {/*  DIMENSION  */}
            <Paper className={classes.paper}>
              <Typography
                align="center"
                variant="h5"
                component="h2"
                gutterBottom
              >
                {t('dimension_front_dimension')}
              </Typography>
              <Grid container>
                <Grid item xs={12} sm={12} md={12}>
                  <Typography
                    align="center"
                    variant="h6"
                    gutterBottom
                    className={classes.paperSubtitle}
                  >
                    {t('front')}
                  </Typography>
                </Grid>
                <MTCTextField
                  gridMD={12}
                  id={'front_dimension'}
                  onChange={(event) => setState({
                    ...state,
                    dimension: {
                      ...state.dimension,
                      front_dimension: event.target.value,
                    },
                  })}
                  value={state.dimension.front_dimension}
                />
                <MTCTextField
                  gridMD={12}
                  id={'front_pressure'}
                  onChange={(event) => setState({
                    ...state,
                    dimension: {
                      ...state.dimension,
                      front_pressure: event.target.value,
                    },
                  })}
                  type="number"
                  value={state.dimension.front_pressure}
                  inputProps={{
                    step: 0.01,
                    min: 0,
                    minLength: '1',
                    required: true,
                  }}
                />
                <Grid item xs={12} sm={12} md={12}>
                  <Typography
                    align="center"
                    variant="h6"
                    gutterBottom
                    className={classes.paperSubtitle}
                  >
                    {t('rear')}
                  </Typography>
                </Grid>
                <MTCTextField
                  gridMD={12}
                  id={'rear_dimension'}
                  onChange={(event) => setState({
                    ...state,
                    dimension: {
                      ...state.dimension,
                      rear_dimension: event.target.value,
                    },
                  })}
                  value={state.dimension.rear_dimension}
                />
                <MTCTextField
                  gridMD={12}
                  id={'rear_pressure'}
                  onChange={(event) => setState({
                    ...state,
                    dimension: {
                      ...state.dimension,
                      rear_pressure: event.target.value,
                    },
                  })}
                  type="number"
                  value={state.dimension.rear_pressure}
                  inputProps={{
                    step: 0.01,
                    min: 0,
                    minLength: '1',
                    required: true,
                  }}
                />
                <MTCSwitch
                  id={'has_psc2'}
                  value={state.dimension.has_psc2}
                  disabled={!canEditPsc2Switch}
                  onChange={() => {
                    let newAvailableTireType = [...state.dimension.available_tire_types];
                    if (!state.dimension.has_psc2) {
                      newAvailableTireType.push(PSC2);
                    } else {
                      const index = newAvailableTireType.indexOf(PSC2);
                      if (index > -1) {
                        newAvailableTireType.splice(index, 1);
                      }
                    }
                    setState({
                      ...state,
                      dimension: {
                        ...state.dimension,
                        has_psc2: !state.dimension.has_psc2,
                        available_tire_types: newAvailableTireType,
                      },
                    });
                  }}
                />
                <MTCSwitch
                  id={'has_psc2r'}
                  value={state.dimension.has_psc2r}
                  disabled={!canEditPsc2rSwitch}
                  onChange={() => {
                    let newAvailableTireType = [...state.dimension.available_tire_types];
                    if (!state.dimension.has_psc2r) {
                      newAvailableTireType.push(PSC2R);
                    } else {
                      const index = newAvailableTireType.indexOf(PSC2R);
                      if (index > -1) {
                        newAvailableTireType.splice(index, 1);
                      }
                    }
                    setState({
                      ...state,
                      dimension: {
                        ...state.dimension,
                        has_psc2r: !state.dimension.has_psc2r,
                        available_tire_types: newAvailableTireType,
                      },
                    });
                  }}
                />
              </Grid>
            </Paper>

            {/*  BOUNDARIES  */}
            {(
              state.dimension.has_psc2 || state.dimension.has_psc2r
            )
            && <Paper className={classes.paper}>
              <Typography
                align="center"
                variant="h5"
                component="h2"
                gutterBottom
              >
                {t('dimensions')}
              </Typography>
              <AppBar position={'static'}>
                <Tabs value={tireTypeTab} onChange={handleTireTypeTabChange}>
                  {state.dimension.has_psc2 && <Tab label={PSC2} />}
                  {state.dimension.has_psc2r && <Tab label={PSC2R} />}
                </Tabs>
              </AppBar>
              {state.dimension.has_psc2 && tireTypeTab === 0
              && <Grid container>
                <Grid item xs={12} sm={12} md={12}>
                  <Typography
                    align="center"
                    variant="h6"
                    gutterBottom
                    className={classes.paperSubtitle}
                  >
                    {t('front')}
                  </Typography>
                  <Typography
                    color="primary"
                    variant="subtitle1"
                    align="center"
                    gutterBottom
                  >
                    {t('boundaries')}
                  </Typography>

                  <BoundaryRangeIndicator />
                  {WEATHER_TYPE.properties.map(property => {
                    const boundary = state.boundaries.find(boundary => (
                      boundary.weather_type === property.value
                      && boundary.is_front === true
                      && boundary.tire_type === PSC2
                    ));
                    return (
                      <BoundaryForm
                        boundary={boundary}
                        onChange={onBoundaryValueChange}
                        key={'boundary'
                        + boundary.tire_type
                        + boundary.is_front
                        + boundary.weather_type}
                        isRally={false}
                      />
                    );
                  })
                  }
                </Grid>

                <Grid item xs={12} sm={12} md={12}>
                  <Typography
                    align="center"
                    variant="h6"
                    gutterBottom
                    className={classes.paperSubtitle}
                  >
                    {t('rear')}
                  </Typography>
                  <Typography
                    color="primary"
                    variant="subtitle1"
                    align="center"
                    gutterBottom
                  >
                    {t('boundaries')}
                  </Typography>
                  <BoundaryRangeIndicator />
                  {WEATHER_TYPE.properties.map(property => {
                    const boundary = state.boundaries.find(boundary => (
                      boundary.weather_type === property.value
                      && boundary.is_front === false
                      && boundary.tire_type === PSC2
                    ));
                    return (
                      <BoundaryForm
                        boundary={boundary}
                        onChange={onBoundaryValueChange}
                        key={'boundary'
                        + boundary.tire_type
                        + boundary.is_front
                        + boundary.weather_type}
                        isRally={false}
                      />
                    );
                  })
                  }
                </Grid>
              </Grid>}
              {state.dimension.has_psc2r
              && (
                tireTypeTab === 1
                || (
                  !state.dimension.has_psc2 && tireTypeTab === 0
                )
              )
              && <Grid container>
                <Grid item xs={12} sm={12} md={12}>
                  <Typography
                    align="center"
                    variant="h6"
                    gutterBottom
                    className={classes.paperSubtitle}
                  >
                    {t('front')}
                  </Typography>
                  <Typography
                    color="primary"
                    variant="subtitle1"
                    align="center"
                    gutterBottom
                  >
                    {t('boundaries')}
                  </Typography>

                  <BoundaryRangeIndicator />
                  {WEATHER_TYPE.properties.map(property => {
                    const boundary = state.boundaries.find(boundary => (
                      boundary.weather_type === property.value
                      && boundary.is_front === true
                      && boundary.tire_type === PSC2R
                    ));
                    return (
                      <BoundaryForm
                        boundary={boundary}
                        onChange={onBoundaryValueChange}
                        key={'boundary'
                        + boundary.tire_type
                        + boundary.is_front
                        + boundary.weather_type}
                        isRally={false}
                      />
                    );
                  })
                  }
                </Grid>

                <Grid item xs={12} sm={12} md={12}>
                  <Typography
                    align="center"
                    variant="h6"
                    gutterBottom
                    className={classes.paperSubtitle}
                  >
                    {t('rear')}
                  </Typography>
                  <Typography
                    color="primary"
                    variant="subtitle1"
                    align="center"
                    gutterBottom
                  >
                    {t('boundaries')}
                  </Typography>
                  <BoundaryRangeIndicator />
                  {WEATHER_TYPE.properties.map(property => {
                    const boundary = state.boundaries.find(boundary => (
                      boundary.weather_type === property.value
                      && boundary.is_front === false
                      && boundary.tire_type === PSC2R
                    ));
                    return (
                      <BoundaryForm
                        boundary={boundary}
                        onChange={onBoundaryValueChange}
                        key={'boundary'
                        + boundary.tire_type
                        + boundary.is_front
                        + boundary.weather_type}
                        isRally={false}
                      />
                    );
                  })
                  }
                </Grid>
              </Grid>}
            </Paper>}

            <MTCTextField
              id={'data_version'}
              onChange={(event) => {
                let dimension = state.dimension;
                dimension.data_version = event.target.value;
                setState({
                  ...state,
                  dimension,
                });
              }}
              value={state.dimension.data_version}
            />
            <Grid item xs={12} sm={12}>
              <FormControl margin={'normal'}>
                <InputLabel id="select-origin-label">{t('origin')}</InputLabel>
                <Select
                  id="select-origin"
                  value={state.dimension.origin}
                  onChange={(event) => {
                    let dimension = state.dimension;
                    dimension.origin = event.target.value;
                    setState({
                      ...state,
                      dimension,
                    });
                  }}
                  className={classes.input}
                >
                  {
                    ORIGIN.properties.map(property => (
                      <MenuItem
                        key={property.value}
                        value={property.value}
                      >
                        {t(property.name)}
                      </MenuItem>
                    ))
                  }
                </Select>
              </FormControl>
            </Grid>
            <MTCSwitch
              id="is_public"
              onChange={() => {
                setState({
                  ...state,
                  dimension: {
                    ...state.dimension,
                    is_public: !state.dimension.is_public,
                  },
                });
              }}
              value={state.dimension.is_public}
              disableText={t('tester')}
              enableText={t('public')}
            />
            <MTCSwitch
              id={'dimension_state'}
              onChange={() => {
                setState({
                  ...state,
                  dimension: {
                    ...state.dimension,
                    state: !state.dimension.state,
                  },
                });
              }}
              value={state.dimension.state}
            />
          </>}
          <Grid item>
            <Grid
              container
              direction={'row'}
              justifyContent={'center'}
              alignItems={'center'}
              spacing={2}
            >
              <Grid item>
                <MTCButton
                  sending={sending}
                  text={t('edit')}
                  type={'submit'}
                />
              </Grid>
              {state.dimension.compatible_with_receiver && <Grid item>
                <MTCButton
                  disabled={sending}
                  text={t('duplicate')}
                  onClick={handleDuplicate}
                  color={'primary'}
                />
              </Grid>}
            </Grid>
          </Grid>
        </Grid>

        <Dialog
          open={state.confirmDialogOpen}
          onClose={handleCloseCancel}
          aria-labelledby="alert-confirm-dialog-title"
          aria-describedby="alert-confirm-dialog-description"
        >
          {hasLessThanBoundaries(state)
          && <DialogTitle id="alert-confirm-dialog-title">
            {t('car_pression_warning')}
          </DialogTitle>}
          {(
            editBrand || editRange || editRangeModel || editEngine
          )
          && <DialogTitle id="alert-confirm-edit-tree">
            {t('car_edit_tree_warning')}
          </DialogTitle>}
          {expertDimensionWithoutBoundary(state)
          && <DialogTitle id="alert-confirm-dialog-title">
            {t('dimension_without_boundary')}
          </DialogTitle>}
          <DialogActions>
            <Button onClick={handleCloseCancel} color="primary">
              {t('cancel')}
            </Button>
            <Button onClick={handleCloseConfirm} color="primary" autoFocus>
              {t('validate')}
            </Button>
          </DialogActions>
        </Dialog>
      </form>
    );
  }
};

EditCarForm.propTypes = {
  dimensionId: PropTypes.string,
};

export default EditCarForm;
