const CONTACTS_PENDING = 'contacts_pending';
const CONTACTS_FAILED = 'contacts_failed';
const CONTACTS_DOWNLOADED = 'contacts_downloaded';
const CONTACT_DOWNLOADED = 'contact_downloaded';
const CONTACT_DELETED = 'contact_deleted';
const CONTACT_SENDING = 'contact_sending';

export {
  CONTACTS_PENDING,
  CONTACTS_FAILED,
  CONTACTS_DOWNLOADED,
  CONTACT_DOWNLOADED,
  CONTACT_DELETED,
  CONTACT_SENDING
};
