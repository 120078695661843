import makeStyles from '@material-ui/core/styles/makeStyles';

export const useStyles = makeStyles({
  tableW: {
    overflowX: 'auto',
    scrollbarWidth: 'none',
  },
  container: {
    paddingBottom: '5em',
  },
});
