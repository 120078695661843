import makeStyles from '@material-ui/core/styles/makeStyles';
import { michelin_blue_main } from '../../../../constants/michelin_colors';
import { inputStyle } from '../../../../constants/styles/input.style';

export const useStyles = makeStyles((theme) => {
  return {
    wrapper: {
      position: 'relative',
    },
    buttonProgress: {
      color: michelin_blue_main,
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: -12,
      marginLeft: -12,
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
    select: {
      ...inputStyle(theme),
    },
    formControl: {
      maxWidth: '100%'
    }
  };
});
