import {
  CAR_FAILED,
  CAR_PENDING,
  CARS_BOUNDARIES_DOWNLOADED,
  CARS_BOUNDARIES_PENDING,
  CARS_BRANDS_DOWNLOADED,
  CARS_BRANDS_PENDING,
  CARS_DIMENSIONS_DOWNLOADED,
  CARS_DIMENSIONS_PENDING,
  CARS_ENGINES_DOWNLOADED,
  CARS_ENGINES_PENDING,
  CARS_MODELS_DOWNLOADED,
  CARS_MODELS_PENDING,
  CARS_RANGES_DOWNLOADED,
  CARS_RANGES_PENDING,
  CAR_RESET,
  CAR_TABLE_LIMIT_CHANGE,
  CARS_CSV_DOWNLOADED,
  CHANGE_SEARCH_MODE,
  CHANGE_SEARCH_TIRE_TYPE, CHANGE_SEARCH_IS_PUBLIC, CHANGE_SEARCH_CAR_STATE,
} from './types';

const INITIAL_STATE = {
  brands: null,
  brands_pending: false,
  selected_brand: '',
  ranges: [],
  ranges_pending: false,
  selected_range: '',
  models: [],
  models_pending: false,
  selected_model: '',
  engines: [],
  engines_pending: false,
  selected_engine: '',
  dimensions: [],
  dimensions_pending: false,
  selected_dimension: '',
  boundaries: [],
  boundaries_pending: false,
  selected_mode: null,
  selected_tire_type: null,
  selected_is_public: null,
  selected_car_state: null,
  pending: false,
  error: null,
  limit: 50,
};

const compareByName = (a, b) => {
  if (a.name > b.name) return 1;
  if (b.name > a.name) return -1;
  return 0;
}
const compareDimension = (a, b) => {
  if (a.frontDimension > b.frontDimension) return 1;
  if (b.frontDimension > a.frontDimension) return -1;
  return 0;
}

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case CARS_BRANDS_PENDING:
      return {
        ...state,
        brands_pending: true,
      };
    case CARS_RANGES_PENDING:
      return {
        ...state,
        ranges_pending: true,
      };
    case CARS_MODELS_PENDING:
      return {
        ...state,
        models_pending: true,
      };
    case CARS_ENGINES_PENDING:
      return {
        ...state,
        engines_pending: true,
      };
    case CARS_DIMENSIONS_PENDING:
      return {
        ...state,
        dimensions_pending: true,
      };
    case CARS_BOUNDARIES_PENDING:
      return {
        ...state,
        boundaries_pending: true,
      };
    case CARS_BRANDS_DOWNLOADED:
      return {
        ...state,
        brands: action.payload.data.sort(compareByName),
        brands_pending: false,
        selected_brand: '',
        ranges: [],
        selected_range: '',
        models: [],
        selected_model: '',
        engines: [],
        selected_engine: '',
        dimensions: [],
        selected_dimension: '',
        boundaries: [],
      };
    case CARS_RANGES_DOWNLOADED:
      return {
        ...state,
        selected_brand: action.payload.id,
        ranges: action.payload.data.sort(compareByName),
        ranges_pending: false,
        selected_range: '',
        models: [],
        selected_model: '',
        engines: [],
        selected_engine: '',
        dimensions: [],
        selected_dimension: '',
        boundaries: [],
      };
    case CARS_MODELS_DOWNLOADED:
      return {
        ...state,
        selected_range: action.payload.id,
        models: action.payload.data.sort(compareByName),
        models_pending: false,
        selected_model: '',
        engines: [],
        selected_engine: '',
        dimensions: [],
        selected_dimension: '',
        boundaries: [],
      };
    case CARS_ENGINES_DOWNLOADED:
      return {
        ...state,
        selected_model: action.payload.id,
        engines: action.payload.data.sort(compareByName),
        engines_pending: false,
        selected_engine: '',
        dimensions: [],
        selected_dimension: '',
        boundaries: [],
      };
    case CARS_DIMENSIONS_DOWNLOADED:
      return {
        ...state,
        selected_engine: action.payload.id,
        dimensions: action.payload.data.sort(compareDimension),
        dimensions_pending: false,
        selected_dimension: '',
        boundaries: [],
      };
    case CARS_BOUNDARIES_DOWNLOADED:
      return {
        ...state,
        selected_dimension: action.payload.id,
        boundaries: action.payload.data,
        boundaries_pending: false,
      };
    case CAR_PENDING:
      return {
        ...state,
        pending: true,
        error: null,
      };
    case CAR_FAILED:
      return {
        ...state,
        error: action.payload,
        brands_pending: false,
        ranges_pending: false,
        models_pending: false,
        engines_pending: false,
        dimensions_pending: false,
        boundaries_pending: false,
        pending: false,
      };
    case CAR_RESET:
      return {
        ...state,
        selected_brand: '',
        ranges: [],
        ranges_pending: false,
        selected_range: '',
        models: [],
        models_pending: false,
        selected_model: '',
        engines: [],
        engines_pending: false,
        selected_engine: '',
        dimensions: [],
        dimensions_pending: false,
        selected_dimension: '',
        boundaries: [],
        boundaries_pending: false,
        selected_mode: null,
        selected_tire_type: null,
        selected_is_public: null,
        selected_car_state: null,
        pending: false,
        error: null,
      };
    case CAR_TABLE_LIMIT_CHANGE:
      return {
        ...state,
        limit: action.payload
      };
    case CARS_CSV_DOWNLOADED:
      return {
        ...state,
        pending: false
      };

    case CHANGE_SEARCH_MODE: {
      return {
        ...state,
        selected_mode: action.payload
      };
    }
    case CHANGE_SEARCH_TIRE_TYPE: {
      return {
        ...state,
        selected_tire_type: action.payload
      };
    }
    case CHANGE_SEARCH_IS_PUBLIC: {
      return {
        ...state,
        selected_is_public: action.payload
      };
    }
    case CHANGE_SEARCH_CAR_STATE: {
      return {
        ...state,
        selected_car_state: action.payload
      };
    }
    default:
      break;
  }
  return state;
}
