import makeStyles from '@material-ui/core/styles/makeStyles';
import { michelin_blue_light, michelin_red } from '../../../../constants/michelin_colors';

export const useStyles = makeStyles((theme) => {
  return {
    fab: {
      margin: theme.spacing(0.5),
      backgroundColor: "#FFF",
      color: michelin_blue_light
    },
    iconButton: {
      color: "#FFF",
      width: "48px",
      height: "48px",
    },
    disabled: {
        color: michelin_blue_light + '!important'
    },
    alert: {
      '&:hover': {
        color: "#FFF",
        backgroundColor: michelin_red
      }
    }
  };
});
