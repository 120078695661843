import makeStyles from '@material-ui/core/styles/makeStyles';

export const useStyles = makeStyles(theme => {
  return {
    container: {
      display: 'flex'
    },
    button: {
      padding: 0,
      width: 32,
      height: 32,
      minWidth: 32
    },
    icon: {
      width: 20,
      height: 20,
    },
  }
});
