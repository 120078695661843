import makeStyles from '@material-ui/core/styles/makeStyles';
import { inputStyle } from '../../constants/styles/input.style';

export const useStyles = makeStyles(theme => (
  {
    draftEditorRoot: {
      top: '25px',
    },
    draftEditorEditorContainer: {
      backgroundColor: '#F5F5F5',
      borderLeft: '.1px solid #9B9B9B',
      position: 'relative',
      width: '100%',
      zIndex: 1,
    },
    draftEditorToolbar :{
      borderRadius: '2px 2px 0 0',
      border: '1px solid #F1F1F1',
      borderBottom: 0,
      marginBottom: 0
    },
    formControl: {
      ...inputStyle(theme),
      height: 'auto',
      position: 'relative',
      //top: '-20px',
      marginBottom: '20px',
    },
    formControlInputLabel: {
      position: 'relative',
      textAlign: 'left',
      width: '100%',
      left: '-12px',
      top: '-24px'
    },
  }
));
