import React, { useState } from 'react';
import { EditorState } from 'draft-js';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { Grid, useTheme } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import { postFaqArticle, putFaqArticle } from '../../../../store/faqArticles/actions';
import { INITIAL_ERROR_STATE, INITIAL_STATE } from './FaqArticlesForm.const';
import { stateWithArticle } from './FaqArticlesForm.func';
import { data_languages, data_languages_no_fr } from '../../../../constants/lang';
import { FAQ_ARTICLES } from '../../../../constants/routes';
import useStyles from './FaqArticlesForm.style';
import MTCTextField from '../../../Form/MTCTextField';
import MTCButton from '../../../Layout/Button/MTCButton';
import MTCRichText from '../../../Form/MTCRichText';
import { editorStateToHtml } from '../../../../utils/DraftEditorUtils';
import MTCSwitch from '../../../Form/MTCSwitch';
import { getFaqElements } from '../../../../store/faqElements/actions';

const FaqArticlesForm = ({ article }) => {

  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const theme = useTheme();
  const classes = useStyles(theme);
  const [state, setState] = useState(INITIAL_STATE);
  const [errors, setErrors] = useState(INITIAL_ERROR_STATE);
  const { sending } = useSelector(state => state.faqArticles);

  if (article !== undefined && article !== null && state.id === null) {
    setState(stateWithArticle(article));
  }

  const isEditing = article !== undefined && article !== null;

  const handleStatePropertyChange = (property, value) => {
    let newState = { ...state };
    newState[property] = value;
    let newErrors = { ...errors };
    newErrors[property + '_error'] = null;
    setState(newState);
    setErrors(newErrors);
  }

  const redirectAfterSubmit = () => {
    dispatch(getFaqElements());
    if (
      history.location
      && history.location.state
      && history.location.state.from
    ) {
      history.push({
        pathname: history.location.state.from,
        state :{
          elementId: history.location.state.elementId || null
        }
      });
    } else {
      history.push(FAQ_ARTICLES);
    }
  }

  const handleSubmit = event => {
    event.preventDefault();
    event.stopPropagation();
    if (!sending) {
      let errorCount = 0;
      let newErrorState = { ...INITIAL_ERROR_STATE };
      data_languages.forEach(dl => {
        const titleKey = `title_${dl}`;
        const title = state[titleKey];
        const contentKey = `content_${dl}`;
        const content = state[contentKey];
        if (title === null || title.trim() === '') {
          errorCount++;
          newErrorState[titleKey + '_error'] = t('error.required_error');
        }
        if (content === null || !content.getCurrentContent().hasText()) {
          errorCount++;
          newErrorState[contentKey + '_error'] = t('error.required_error');
        }
      });
      if (errorCount === 0) {
        const jsonData = {
          faq_element_parent_id: (
            history.location.state && history.location.state.faqElementParentId
          ) ? history.location.state.faqElementParentId
            : null,
          has_support_contact_button: state.has_support_contact_button,
          translations: data_languages.reduce((o, key) => (
            {
              ...o,
              [key]: {
                title: state[`title_${key}`],
                content: editorStateToHtml(state[`content_${key}`]),
                tags: state[`tags_${key}`],
              },
            }
          ), {}),
        }
        if (isEditing) {
          dispatch(putFaqArticle(article.id, jsonData))
            .then(data => {
              if (data && data.id) {
                redirectAfterSubmit();
              }
            });
        } else {
          dispatch(postFaqArticle(jsonData))
            .then(data => {
              if (data && data.id) {
                redirectAfterSubmit();
              }
            });
        }
      } else {
        setErrors(newErrorState);
      }
    }
  };

  return (<form>
    <Grid
      container
      align="center"
      className={classes.grid}
      direction={'column'}
      justifyContent={'center'}
    >
      <Grid item xs={12}>
        <Paper className={classes.paper}>
          <Grid container>
            <Grid item xs={12}>
              <Typography
                align="center"
                variant="h5"
                component="h2"
                gutterBottom>
                {t('general_infos')}
              </Typography>
            </Grid>
            <MTCSwitch
              id={'has_support_contact_button'}
              value={state.has_support_contact_button}
              onChange={() => {
                handleStatePropertyChange(
                  'has_support_contact_button',
                  !state.has_support_contact_button,
                );
              }}
              enableText={t('has_support_contact_button_enable')}
              disableText={t('has_support_contact_button_disable')}
            />
          </Grid>
        </Paper>
      </Grid>
      <Grid item xs={12}>
        <Paper className={classes.paper}>
          <Grid container>
            <Grid item xs={12}>
              <Typography
                align="center"
                variant="h5"
                component="h2"
                gutterBottom>
                {t('title')}
              </Typography>
            </Grid>
            <MTCTextField
              gridMD={12}
              id={`title_fr`}
              key={`title_fr`}
              onChange={event => handleStatePropertyChange(`title_fr`, event.target.value)}
              value={state[`title_fr`]}
              required={true}
              errorMessage={errors[`title_fr_error`]}
              onBlur={event => setState({
                ...state,
                ...data_languages.reduce((o, key) => (
                  {
                    ...o,
                    [`title_${key}`]: state[`title_${key}`] || event.target.value,
                  }
                ), {}),
              })}
            />

            {data_languages_no_fr.map(lg => (
              <MTCTextField
                gridMD={12}
                id={`title_${lg}`}
                key={`title_${lg}`}
                onChange={event => handleStatePropertyChange(
                  `title_${lg}`,
                  event.target.value,
                )}
                value={state[`title_${lg}`]}
                required={true}
                errorMessage={errors[`title_${lg}_error`]}
              />
            ))}

          </Grid>
        </Paper>
      </Grid>
      <Grid item xs={12}>
        <Paper className={classes.paper}>
          <Grid container>
            <Grid item xs={12}>
              <Typography
                align="center"
                variant="h5"
                component="h2"
                gutterBottom>
                {t('content')}
              </Typography>
            </Grid>

            <MTCRichText
              id={'content_fr'}
              required={true}
              errorMessage={errors.content_fr_error}
              handleChange={handleStatePropertyChange}
              value={state.content_fr}
              onBlur={(newText) => {
                let newState = { ...state };
                data_languages.forEach(lg => {
                  if (!state[`content_${lg}`].getCurrentContent().hasText()) {
                    newState[`content_${lg}`] = EditorState.createWithContent(
                      newText.getCurrentContent(),
                    );
                  }
                });
                setState(newState);
              }}
            />
            {data_languages_no_fr.map(lg => (
              <MTCRichText
                id={`content_${lg}`}
                key={`content_${lg}`}
                required={true}
                errorMessage={errors[`content_${lg}_error`]}
                handleChange={handleStatePropertyChange}
                value={state[`content_${lg}`]}
              />
            ))}
          </Grid>
        </Paper>
      </Grid>
      <Grid item xs={12}>
        <Paper className={classes.paper}>
          <Grid container>
            <Grid item xs={12}>
              <Typography
                align="center"
                variant="h5"
                component="h2"
                gutterBottom>
                {t('tags')}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography
                align="center"
                variant="body1"
                component="p"
                gutterBottom
                className={classes.helperText}
              >
                {t('faq_article_tags_helper_text')}
              </Typography>
            </Grid>
            <MTCTextField
              gridMD={12}
              id={`tags_fr`}
              key={`tags_fr`}
              onChange={event => handleStatePropertyChange(`tags_fr`, event.target.value)}
              value={state[`tags_fr`]}
              required={true}
              errorMessage={errors[`tags_fr_error`]}
              onBlur={event => setState({
                ...state,
                ...data_languages.reduce((o, key) => (
                  {
                    ...o,
                    [`tags_${key}`]: state[`tags_${key}`] || event.target.value,
                  }
                ), {}),
              })}
            />

            {data_languages_no_fr.map(lg => (
              <MTCTextField
                gridMD={12}
                id={`tags_${lg}`}
                key={`tags_${lg}`}
                onChange={event => handleStatePropertyChange(`tags_${lg}`,
                  event.target.value,
                )}
                value={state[`tags_${lg}`]}
                required={true}
                errorMessage={errors[`tags_${lg}_error`]}
              />
            ))}

          </Grid>
        </Paper>
      </Grid>
      <Grid item>
        <MTCButton
          disabled={false}
          onClick={handleSubmit}
          type={'button'}
          sending={sending}
          text={isEditing
            ? t('edit')
            : t('create')}
        />
      </Grid>
    </Grid>
  </form>);
};

export default FaqArticlesForm;
