import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import DialogContent from '@material-ui/core/DialogContent';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import useTheme from '@material-ui/core/styles/useTheme';
import { useStyles } from '../FaqElementsDialog.style';
import { useTranslation } from 'react-i18next';

const FaqElementsExternalLinkDialog = ({
                                         fullScreen,
                                         openExternalLinkDialog,
                                         handleCloseExternalLinkDialog,
                                       }) => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const { t } = useTranslation();
  return (
    <Dialog
      fullWidt
      fullWidth
      open={openExternalLinkDialog.open}
      onClose={handleCloseExternalLinkDialog}
      scroll="paper"
      fullScreen={fullScreen}
    >
      <DialogTitle disableTypography>
        <Typography variant="h6">
          {openExternalLinkDialog.title}
        </Typography>
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={handleCloseExternalLinkDialog}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Grid container direction={'column'} justifyContent={'center'} alignItems={'center'}>
          <Grid item>
            <Typography variant="h5">
              <Link
                href={openExternalLinkDialog.link}
                target={'_blank'}
              >
                {openExternalLinkDialog.link}
              </Link>
            </Typography>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCloseExternalLinkDialog} backgroundcolor={'secondary'}>
          {t('close')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default FaqElementsExternalLinkDialog;
