const RECEIVERS_MMC_RF_PENDING = 'receivers_mmc_rf_pending';
const RECEIVER_MMC_RF_FAILED = 'receiver_mmc_rf_failed';
const RECEIVER_MMC_RF_DOWNLOADED = 'receiver_mmc_rf_downloaded';
const RECEIVER_MMC_RF_SENDING = 'receivers_mmc_rf_sending';
const RECEIVERS_MMC_RF_DOWNLOADED = 'receivers_mmc_rf_downloaded';
const RECEIVERS_MMC_RF_DELETED = 'receivers_mmc_rf_deleted';

export {
  RECEIVERS_MMC_RF_PENDING,
  RECEIVER_MMC_RF_FAILED,
  RECEIVER_MMC_RF_DOWNLOADED,
  RECEIVER_MMC_RF_SENDING,
  RECEIVERS_MMC_RF_DOWNLOADED,
  RECEIVERS_MMC_RF_DELETED
};
