import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import AppBar from '@material-ui/core/AppBar';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { useDispatch, useSelector } from 'react-redux';
import { getAuthorizedExports } from '../../../store/authorizedExports/actions';
import MTCLoader from '../../Layout/MTCLoader';
import AuthorizedExportTable from '../AuthorizedExportList/AuthorizedExportTable';

const useStyles = makeStyles(
  () => {
    return {
      root: {
        flexGrow: 1,
      },
    };
  });

export const AuthorizedExportTabs = () => {

  const history = useHistory();
  const classes = useStyles();
  const { t } = useTranslation();
  const [value, setValue] = useState(0);
  const [locationStateMatch, setLocationStateMatch] = useState(true);
  const dispatch = useDispatch();
  const {
    pending,
    content,
    error,
  } = useSelector(state => state.authorizedExports);

  if (!content && !pending && !error) {
    dispatch(getAuthorizedExports());
  }
  if (
    history.location
    && history.location.state
    && locationStateMatch
  ) {
    if (history.location.state.value) {
      setValue(history.location.state.value);
    }
    setLocationStateMatch(false);
  }

  const handleChange = (event, value) => {
    setValue(value);
  };
  if (pending || !content) {
    return <MTCLoader />;
  }
  if (error) {
    return <p>{error}</p>;
  }
  if (!error) {
    return (
      <div className={classes.root}>
        <AppBar position={'static'}>
          <Tabs
            value={value}
            onChange={handleChange}
            variant={'scrollable'}
            scrollButtons={'on'}
          >
            <Tab label={t('support_page.tabs.open_title')} />
            <Tab label={t('support_page.tabs.close_title')} />
          </Tabs>
        </AppBar>
        {value === 0 && (
          <AuthorizedExportTable values={content.filter((e) => e.status === 'OPEN')} />
        )}
        {value === 1 && (
          <AuthorizedExportTable values={content.filter((e) => e.status === 'CLOSE')} />
        )}
      </div>
    );
  }
};

export default React.memo(AuthorizedExportTabs);
