import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import Grid from '@material-ui/core/Grid';
import { useTranslation } from 'react-i18next';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import Chip from '@material-ui/core/Chip';
import Input from '@material-ui/core/Input';
import FormHelperText from '@material-ui/core/FormHelperText';
import MTCTextField from '../Form/MTCTextField';
import MTCButton from '../Layout/Button/MTCButton';
import { postCallCenter } from '../../store/callCenters/actions';
import { postLangCallCenter } from '../../store/langCallCenters/actions';
import { CALL_CENTERS } from '../../constants/routes';
import languages from '../../constants/lang';
import { compareCoutries, ERROR_STATE, INITIAL_STATE } from './AddCallCenterForm.const';
import { useStyles } from './AddCallCenterForm.style';

const countries = require('country-list')();

const AddCallCenterForm = ({ langCallCenter, unavailableLang }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const [state, setState] = useState(INITIAL_STATE);
  const [unavailableStateLang, setUnavailableStateLang] = useState([]);
  const [errors, setErrors] = useState(ERROR_STATE);

  const withCountries = langCallCenter === undefined || !langCallCenter;

  if (!withCountries && unavailableStateLang !== unavailableLang) {
    setUnavailableStateLang(unavailableLang);
  }
  let availableLang = [];
  if (!withCountries && unavailableStateLang && unavailableStateLang.length > 0) {
    availableLang = languages.filter(l => !unavailableStateLang.includes(l));
  } else {
    availableLang = languages;
  }
  availableLang.sort();

  let sortedCountries = [...countries.getData()]
  sortedCountries.sort(compareCoutries);

  const onSubmit = () => {
    let newErrors = { ...errors };
    let errorCount = 0;
    if (state.phone.length < 1 || state.phone.length > 30) {
      newErrors.phoneError = t('error.phone_error');
      errorCount++;
    }
    if (withCountries && state.country_codes.length < 1) {
      newErrors.countryCodesError = t('error.country_codes_error');
      errorCount++;
    }
    if (state.lang === '') {
      newErrors.langError = t('error.lang_error');
      errorCount++;
    }
    setErrors(newErrors);
    if (errorCount === 0) {
      if (withCountries) {
        dispatch(postCallCenter(state)).then(() => {
            history.push(CALL_CENTERS);
          },
        );
      } else {
        dispatch(postLangCallCenter(state)).then(() => {
            history.push(CALL_CENTERS);
          },
        );
      }
    }
  };

  const onChangePhone = (event) => {
    if (event.target.value.length < 31) {
      let newState = { ...state };
      newState.phone = event.target.value;
      setState(newState);
    }
    setErrors({
      ...errors,
      phoneError: null,
    });
  };

  const onChangeCountryCode = (event) => {
    let newState = { ...state };
    newState.country_codes = event.target.value;
    setState(newState);
    setErrors({
      ...errors,
      countryCodesError: null,
    });
  };

  const onChangeLang = (event) => {
    let newState = { ...state };
    newState.lang = event.target.value;
    setState(newState);

    setErrors({
      ...errors,
      langError: null,
    });
  };

  const onChangeOpening = (event) => {
    let newState = { ...state };
    newState.opening = event.target.value;
    setState(newState);
  };

  return (
    <form>

      <Grid container align="center" spacing={2}>
        {withCountries && <Grid item xs={12} className={classes.grid}>

          <FormControl
            error={errors.countryCodesError !== undefined && errors.countryCodesError !== null}
          >
            <InputLabel id="country_codes-label" required>{t('country_code')}</InputLabel>
            <Select
              className={classes.select}
              labelId="country_codes-label"
              id="country_codes"
              multiple
              value={state.country_codes}
              onChange={onChangeCountryCode}
              input={<Input id="select_country_codes" />}
              renderValue={(selected) => (
                <div className={classes.chips}>
                  {selected.map((value) => (
                    <Chip key={value} label={value} />
                  ))}
                </div>
              )}
              MenuProps={{
                className: classes.menu,
              }}
              SelectProps={{
                MenuProps: {
                  className: classes.menu,
                },
              }}
            >
              {sortedCountries.map((country) => (
                <MenuItem key={country.code} value={country.code}>
                  {country.code} - {country.name}
                </MenuItem>
              ))}
            </Select>
            {errors.countryCodesError &&
            <FormHelperText>{errors.countryCodesError}</FormHelperText>}
          </FormControl>
        </Grid>}

        <Grid item xs={12} className={classes.grid}>
          <TextField
            className={classes.textField}
            id="lang"
            onChange={onChangeLang}
            InputProps={{
              className: classes.input,
            }}
            label={t('lang')}
            placeholder={t('lang')}
            SelectProps={{
              MenuProps: {
                className: classes.menu,
              },
            }}
            value={state.lang}
            select
            required={true}
            InputLabelProps={{
              className: classes.selectLabel,
            }}
            error={errors.langError !== undefined && errors.langError !== null}
            helperText={errors.langError}
          >
            {availableLang.map(l => (
              <MenuItem key={l} value={l}>
                {l} - {t('lang:' + l)}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={12} className={classes.grid}>
          <MTCTextField
            id={'phone'}
            onChange={onChangePhone}
            value={state.phone}
            required={true}
            errorMessage={errors.phoneError}
          />
        </Grid>

        <Grid item xs={12} className={classes.grid}>
          <TextField
            className={classes.textField}
            id="opening"
            onChange={onChangeOpening}
            InputProps={{
              className: classes.input,
            }}
            label={t('opening')}
            placeholder={t('opening')}
            SelectProps={{
              MenuProps: {
                className: classes.menu,
              },
            }}
            value={state.opening}
            select
            required={true}
            InputLabelProps={{
              className: classes.selectLabel,
            }}
          >
            {[0, 1, 2].map(value => (
              <MenuItem key={value} value={value}>
                {t('opening-' + value)}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={12} className={classes.grid}>
          <MTCButton
            text={t('create')}
            type={'button'}
            onClick={onSubmit}
          />
        </Grid>
      </Grid>
    </form>
  );
};

export default AddCallCenterForm;
