import {
  CHALLENGE_DOWNLOADED,
  CHALLENGE_SENDING,
  CHALLENGES_DOWNLOADED,
  CHALLENGES_FAILED,
  CHALLENGES_PENDING,
} from './types';
import ApiService from '../../services/ApiService';
import { handleError } from '../infos/actions';

export const challengesPendingAction = () => (
  {
    type: CHALLENGES_PENDING,
  }
);

export const challengesSendingAction = () => (
  {
    type: CHALLENGE_SENDING,
  }
);

export const challengesFailedAction = error => (
  {
    type: CHALLENGES_FAILED,
    payload: error,
  }
);

export const challengesDownloadedAction = challenges => (
  {
    type: CHALLENGES_DOWNLOADED,
    payload: challenges,
  }
);

export const challengeDownloadedAction = challenge => (
  {
    type: CHALLENGE_DOWNLOADED,
    payload: challenge,
  }
);

export function getChallenges() {
  return (dispatch) => {
    dispatch(challengesPendingAction());
    return ApiService
      .getChallenges()
      .then(data => {
        dispatch(challengesDownloadedAction(data));
      })
      .catch(error => {
        dispatch(challengesFailedAction(error));
        dispatch(handleError(error));
      });
  }
}

export function postChallenge(challenge) {
  return (dispatch) => {
    dispatch(challengesSendingAction());
    return ApiService
      .postChallenge(challenge)
      .then(data => {
        dispatch(challengeDownloadedAction(data));
        return data;
      })
      .catch(error => {
        dispatch(challengesFailedAction(error));
        dispatch(handleError(error));
      });
  }
}

export function putChallenge(challengeId, challenge) {
  return (dispatch) => {
    dispatch(challengesSendingAction());
    return ApiService
      .putChallenge(challengeId, challenge)
      .then(data => {
        dispatch(challengeDownloadedAction(data));
        return data;
      })
      .catch(error => {
        dispatch(challengesFailedAction(error));
        dispatch(handleError(error));
      });
  }
}
export function putChallengeState(challengeId, state) {
  return (dispatch) => {
    dispatch(challengesSendingAction());
    return ApiService
      .putChallengeState(challengeId, state)
      .then(data => {
        dispatch(challengeDownloadedAction(data));
        return data;
      })
      .catch(error => {
        dispatch(challengesFailedAction(error));
        dispatch(handleError(error));
      });
  }
}
