import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { withStyles } from '@material-ui/core/styles/index';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputAdornment from '@material-ui/core/InputAdornment';
import Tooltip from '@material-ui/core/Tooltip';
import List from '@material-ui/icons/List';
import Paper from '@material-ui/core/Paper';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import EditIcon from '@material-ui/icons/Edit';
import { CARS, RALLY_CAR_DUPLICATE } from '../../../constants/routes';
import { getRallyCars, postRallyCar, putRallyCar } from '../../../store/formRallyCars/actions';
import { resetTableRallyCars } from '../../../store/tableRallyCars/actions';
import {
  getBrandsRallyCars as getSearchBrandsRallyCars,
  getDimensionsRallyCars as getSearchDimensionsRallyCars,
  getGroupsRallyCars as getSearchGroupsRallyCars,
  getModelsRallyCars as getSearchModelsRallyCars,
  reset as searchReset,
} from '../../../store/searchRallyCars/actions';
import CarFormatter from '../../../services/CarFormatter';
import StringService from '../../../services/StringService';
import ArrayService from '../../../services/ArrayService';
import withTranslation from '../../../hoc/withTranslation';
import Error404 from '../../../containers/Error404';
import MTCLoader from '../../Layout/MTCLoader';
import MTCButton from '../../Layout/Button/MTCButton';
import MTCTextField from '../../Form/MTCTextField';
import MTCDialog from '../../Form/MTCDialog';
import MTCSwitch from '../../Form/MTCSwitch';
import BoundaryRangeIndicator from './BoundaryRangeIndicator';
import BoundaryForm from './BoundaryForm';
import { styles } from './RallyCarForm.style';
import {
  createEmptyRallyBoundary,
  emptyRallyDimension,
  INITIAL_STATE,
  stateWithDimensionId,
} from './RallyCarForm.const';

export class RallyCarForm extends Component {

  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  }

  // Lifecycle

  static getDerivedStateFromProps(props, state) {
    if (
      props.cars
      && props.rallyDimensionId
      && props.weatherTypes
      && state.id === INITIAL_STATE.id
    ) {
      const result = stateWithDimensionId(
        props.cars,
        props.rallyDimensionId,
        props.weatherTypes,
        props.isDuplicate,
        state.rallyDimension,
      );
      if (result !== false) {
        return result;
      }
    }
    return null;
  }

  static stateToApiFormat = state => {
    const result = {
        rally_brand: state.rallyBrand,
        rally_model: state.rallyModel,
        rally_group: state.rallyGroup,
        rally_dimension: {
          ...state.rallyDimension,
          state: state.rallyDimension.state
            ? 1
            : 0,
        },
        rally_boundaries: state.rallyBoundaries === null
          ? []
          : state.rallyBoundaries.map(elem => {
            if (elem.red_orange !== null
              && elem.orange_green !== null
              && elem.green_orange !== null
              && elem.orange_red !== null) {
              return elem;
            } else {
              return null;
            }
          }).filter(elem => elem !== null),
      };
    return result;
  };

  emptyBoundaries = (weatherTypes) => {
    let result = [];
    weatherTypes.properties.forEach(weatherType => {
      result.push(createEmptyRallyBoundary(true, weatherType.value));
      result.push(createEmptyRallyBoundary(false, weatherType.value));
    });
    return result;
  };

  componentDidMount() {
    if (!this.props.cars) {
      this.props.getRallyCars();
    } else {
      const result = stateWithDimensionId(this.props.cars,
        this.props.rallyDimensionId,
        this.props.weatherTypes,
        this.props.isDuplicate,
        this.state.rallyDimension,
      );
      if (result !== false) {
        this.setState(result);
      }
    }
  }

  callApi = () => {
    const postData = RallyCarForm.stateToApiFormat(this.state);
    if (this.props.rallyDimensionId && !this.props.isDuplicate) {
      this.props.putRallyCar(postData)
        .then(async (data) => {
          await this.props.resetTableRallyCars();
          await this.props.resetTableRallyCars();
          await this.props.searchReset();
          await this.props.getSearchBrandsRallyCars();
          await this.props.getSearchModelsRallyCars(data.rally_brand.id);
          await this.props.getSearchGroupsRallyCars(data.rally_brand.id, data.rally_model.id);
          await this.props.getSearchDimensionsRallyCars(data.rally_brand.id,
            data.rally_model.id,
            data.rally_group.id,
          );
          this.props.history.push({
            pathname: CARS,
            state: {
              value: 1,
            },
          });
        });
    } else {
      this.props.postRallyCar(postData)
        .then(async (data) => {
          await this.props.resetTableRallyCars();
          await this.props.searchReset();
          await this.props.getSearchBrandsRallyCars();
          await this.props.getSearchModelsRallyCars(data.rally_brand.id);
          await this.props.getSearchGroupsRallyCars(data.rally_brand.id, data.rally_model.id);
          await this.props.getSearchDimensionsRallyCars(data.rally_brand.id,
            data.rally_model.id,
            data.rally_group.id,
          );
          this.props.history.push({
            pathname: CARS,
            state: {
              value: 1,
            },
          });
        });
    }
  };

  isTheSame = () => {
    let result = false;
    if (this.props.isDuplicate) {
      let resultBoundaries = ArrayService.isArrayEquals(this.state.initRallyBoundaries,
        this.state.rallyBoundaries,
      );
      if (
        (
          this.state.initRallyBrand === this.state.rallyBrand
        ) && (
          this.state.initRallyModel === this.state.rallyModel
        ) && (
          this.state.initRallyGroup === this.state.rallyGroup
        ) && (
          this.state.initRallyDimension === this.state.rallyDimension
        )
        && resultBoundaries
      ) {
        result = true;
      }
    }
    return result;
  };

  // Handler
  onSubmit = (event) => {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }
    if (this.isTheSame() || this.state.editBrand || this.state.editModel || this.state.editGroup) {
      this.setState({ confirmDialogOpen: true });
    } else {
      this.callApi();
    }
  };

  onChange = (event, stateKey, listItem) => {
    const newStateId = event.target.value;
    if (!newStateId) {
      this.setState({
        dialog: {
          openDialog: true,
          dialogItem: stateKey,
          newItemKey: 'name',
          dialogTitle: this.props.t('add_new_title') + ' '
            + this.props.t(stateKey + '.name') + ' ?',
          dialogText: this.props.t('add_new_content'),
          dialogHandleOK: this.handleAddNewItem,
        },
      });
    } else if (!this.state[stateKey] || this.state[stateKey].id !== newStateId) {
      this.setState({
        [stateKey]: listItem.find(item => item.id === newStateId),
      });
      this.deleteChildren(stateKey);
    }
  };

  onChangeRallyDimension = (event, rallyDimension, dimensionKey) => {
    let newRallyDimension = { ...rallyDimension };
    newRallyDimension[dimensionKey] = CarFormatter.formatValue(dimensionKey, event.target.value);
    this.setState({ rallyDimension: newRallyDimension });
  };

  handleClose = () => {
    this.setState({
      dialog: INITIAL_STATE.dialog,
    });
  };

  handleCloseConfirm = () => {
    this.setState({ confirmDialogOpen: false });
    this.callApi();
  };

  handleCloseCancel = () => {
    this.setState({ confirmDialogOpen: false });
  };

  handleAddNewItem = () => {
    this.setState({
      [this.state.dialog.dialogItem]:
        { [this.state.dialog.newItemKey]: this.state.dialog.newItem },
    });
    this.deleteChildren(this.state.dialog.dialogItem);
    this.handleClose();
  };

  handleListClick = (event, stateKey) => {
    this.setState({ [stateKey]: null });
    this.deleteChildren(stateKey);
  };

  handleDuplicate = event => {
    event.stopPropagation();
    event.preventDefault();
    window.scrollTo(0, 0);
    this.props.history.push(RALLY_CAR_DUPLICATE.replace(':rallyDimensionId',
      this.props.rallyDimensionId,
    ));
  };

  // Computation

  deleteChildren = (stateKey) => {
    const currentOrder = this.state.orders.find(order => order.name === stateKey).order;
    this.state.orders.filter(order => order.order > currentOrder).forEach(order => {

      if (this.state[order.name]) {
        if (
          this.state[order.name].id !== undefined
          && this.state[order.name].id !== null
          && this.state[order.name].id !== false
        ) {
          this.setState({ [order.name]: null });
        } else if (order.name === 'rallyBoundaries') {
          let nb = [];
          [...this.state[order.name]].forEach(elem => {
            if (elem.id === undefined || elem.id === null || elem.id === false) {
              nb.push(elem);
            }
          });
          this.setState({ [order.name]: nb });
        }
      }
    });
  };

  // Renderer

  showEditingInput = (stateKey) => {
    switch (stateKey) {
      case 'rallyBrand':
        return this.state.editBrand;
      case 'rallyModel':
        return this.state.editModel;
      case 'rallyGroup':
        return this.state.editGroup
      default:
        return null;
    }
  };

  handleShowEditInput = (stateKey) => {
    switch (stateKey) {
      case 'rallyBrand':
        this.setState({
          editBrand: true,
          rallyBrand: {
            ...this.state.rallyBrand,
            oldName: this.state.rallyBrand.name,
          },
        });
        break;
      case 'rallyModel':
        this.setState({
          editModel: true,
          rallyModel: {
            ...this.state.rallyModel,
            oldName: this.state.rallyModel.name,
          },
        });

        break;
      case 'rallyGroup':
        this.setState({
          editGroup: true,
          rallyGroup: {
            ...this.state.rallyGroup,
            oldName: this.state.rallyGroup.name,
          },
        });
        break;
      default:
        console.error("Wrong state key : " + stateKey);
        break;
    }
  };
  handleHideEditInput = (stateKey) => {
    switch (stateKey) {
      case 'rallyBrand':
        this.setState({
          editBrand: false,
          rallyBrand: {
            ...this.state.rallyBrand,
            name: this.state.rallyBrand.oldName,
          },
        });
        break;
      case 'rallyModel':
        this.setState({
          editModel: false,
          rallyModel: {
            ...this.state.rallyModel,
            name: this.state.rallyModel.oldName,
          },
        });
        break;
      case 'rallyGroup':
        this.setState({
          editGroup: false,
          rallyGroup: {
            ...this.state.rallyGroup,
            name: this.state.rallyGroup.oldName,
          },
        });
        break;
      default:
        console.error("Wrong state key : " + stateKey);
        break;
    }
  };

  renderSelectOrAdd = (
    item,
    listItem,
    stateKey,
    parentItem = 'none',
    parentIdKey,
  ) => {
    let body;
    if (this.props.rallyDimensionId && !this.props.isDuplicate) {//If editing
      if (this.showEditingInput(stateKey)) {
        body =
          <MTCTextField
            id={stateKey + '.name'}
            onChange={event => this.setState({
              [stateKey]: {
                ...item,
                name: CarFormatter.formatValue('name', event.target.value),
              },
            })}
            value={item
              ? item.name
              : ''}
            gridMD={12}
            gridLG={12}
            handleCancel={() => this.handleHideEditInput(stateKey)}
            fullWidth={true}
            inputProps={{
              required: true,
            }}
          />;
      } else {
        body =
          <div className={this.props.classes.topPartTextContainer}>
            <Typography variant="subtitle1" gutterBottom
                        className={this.props.classes.topPartValue}>
              {item.name}
              <EditIcon className={this.props.classes.editIcon}
                        onClick={() => this.handleShowEditInput(stateKey)} />
            </Typography>
          </div>;
      }
    } else {
      if (!parentItem) {
        // If parentItem is missing, show text indicating that
        // previous field should be completed first
        body =
          <Typography variant="subtitle1" gutterBottom>
            {this.props.t('select_field')} : {this.props.t(parentIdKey)}
          </Typography>;
      } else if ((
          item && !item.id
        ) || // If item has been created, only show it as text and give option to go back to list
        (
          parentItem !== 'none' && !parentItem.id
        )) {
        // There is a parentItem but without an id. So it is a new
        // item and all childrens will be new as well
        body =
          <MTCTextField
            id={stateKey + '.name'}
            onChange={event => this.setState({
              [stateKey]: {
                name: CarFormatter.formatValue('name', event.target.value),
              },
            })}
            value={item
              ? item.name
              : ''}
            type={'text'}
            noLabel
            InputProps={(
              parentItem !== 'none' && !parentItem.id
            )
              ? null
              : {
                className: this.props.classes.input,
                startAdornment: (
                  <InputAdornment
                    position="start"
                    onClick={event => this.handleListClick(event, stateKey)}
                  >
                    <Tooltip title={this.props.t('show_list_items')}>
                      <List />
                    </Tooltip>
                  </InputAdornment>
                ),
              }}
          />;
      } else { // Else show the matching list item in order to select one ar add a new item
        let matchingList = listItem;
        if (parentItem && parentItem !== 'none') {
          matchingList = listItem.filter(item => item[parentIdKey] === parentItem.id);
        }
        let itemList = matchingList.map(item => (
          <MenuItem
            key={item.id}
            value={item.id}
          >
            {item.name}
          </MenuItem>
        ));

        body =
          <Select
            id={stateKey + '.name'}
            value={item
              ? item.id
              : 'default'}
            onChange={event => this.onChange(event, stateKey, listItem)}
            className={this.props.classes.input}
          >
            <MenuItem value="">
              <em>{this.props.t('add_new')}</em>
            </MenuItem>
            {itemList}
          </Select>;
      }
    }
    return (
      <Grid item xs={12} sm={12} className={this.props.classes.topPartText}>
        {!this.showEditingInput(stateKey) &&
        <Typography
          color={'primary'}
          variant={'subtitle1'}
          gutterBottom>
          {this.props.t(stateKey + '.name')}
        </Typography>
        }
        {body}
      </Grid>
    );
  };

  renderTire = (isFront, rallyDimension) => {
    let rallyBoundaries = this.state.rallyBoundaries;
    rallyBoundaries = rallyBoundaries
      ? rallyBoundaries
      : this.props.cars.rally_boundaries
        .filter(rallyBoundary => rallyBoundary.rally_dimension_id === rallyDimension.id);
    rallyBoundaries = rallyBoundaries.length
      ?
      rallyBoundaries
      :
      this.emptyBoundaries(this.props.weatherTypes);
    const { classes, t, weatherTypes } = this.props;
    return (
      <Grid item xs={12} sm={12} md={12}>
        <Paper className={classes.tirePaper}>
          <Grid container>
            <Grid item xs={12} sm={12} md={12}>
              <Typography
                align={'left'}
                variant={'h6'}
                gutterBottom>
                {t(isFront
                  ? 'front'
                  : 'rear')}
              </Typography>
            </Grid>
            <MTCTextField
              gridMD={12}
              id={isFront
                ? 'front_dimension'
                : 'rear_dimension'}
              onChange={event => this.onChangeRallyDimension(
                event,
                rallyDimension,
                isFront
                  ? 'front_dimension'
                  : 'rear_dimension',
              )}
              value={isFront
                ? rallyDimension.front_dimension
                : rallyDimension.rear_dimension}
            />
            <MTCTextField
              gridMD={12}
              id={isFront
                ? 'front_pressure'
                : 'rear_pressure'}
              onChange={event => this.onChangeRallyDimension(
                event,
                rallyDimension,
                isFront
                  ? 'front_pressure'
                  : 'rear_pressure',
              )}
              type="number"
              value={isFront
                ? rallyDimension.front_pressure
                : rallyDimension.rear_pressure}
              inputProps={{
                step: 0.01,
                min: 0,
                minLength: '1',
                required: true,
              }}
            />
            <MTCTextField
              gridMD={12}
              type="number"
              id={isFront
                ? 'front_min_cold_pressure'
                : 'rear_min_cold_pressure'}
              onChange={event => this.onChangeRallyDimension(
                event,
                rallyDimension,
                isFront
                  ? 'front_min_cold_pressure'
                  : 'rear_min_cold_pressure',
              )}
              value={isFront
                ?
                rallyDimension.front_min_cold_pressure
                : rallyDimension.rear_min_cold_pressure
              }
              inputProps={{
                step: 0.01,
                min: 0,
                minLength: '1',
                required: true,
              }}
            />


            <Grid item xs={12} sm={12} md={12}>
              <Grid item xs={12} sm={12} md={12}>
                <Typography
                  style={{ marginTop: '20px' }}
                  color={'primary'}
                  variant={'subtitle1'}
                  gutterBottom>
                  {this.props.t('boundaries')}
                </Typography>
              </Grid>
              <BoundaryRangeIndicator />
              {
                weatherTypes.properties.map(property => {
                  const rallyBoundary = rallyBoundaries.find(rallyBoundary => (
                    rallyBoundary.weather_type === property.value
                    && rallyBoundary.is_front === isFront
                  ));
                  return (
                    <BoundaryForm
                      boundary={rallyBoundary}
                      onChange={this.onBoundaryValueChange}
                      key={'boundary' + rallyBoundary.is_front + rallyBoundary.weather_type}
                      isRally={true}
                    />
                  );
                })
              }
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    );
  };

  renderDimensions = rallyGroup => {
    let rallyDimension = this.state.rallyDimension;
    //Only search dimension when Editing, if adding use empty one
    if (this.props.car) {
      rallyDimension = rallyDimension
        ? rallyDimension
        : this.props.cars.rally_dimensions
          .find(rallyDimension => rallyDimension.rally_group_id === rallyGroup.id);
    }
    rallyDimension = rallyDimension
      ? rallyDimension
      : emptyRallyDimension;
    return (
      <Grid container align="center" spacing={2}>
        <Grid item xs={12} sm={12} md={12}>
          <Typography
            style={{ marginTop: '20px' }}
            color={'primary'}
            variant={'h5'}
            align={'left'}
            gutterBottom>
            {this.props.t('dimensions')} :
          </Typography>
        </Grid>
        {this.renderTire(true, rallyDimension)}
        <br />
        {this.renderTire(false, rallyDimension)}
      </Grid>
    );
  };

  onBoundaryValueChange = (isFront, weatherType, tireType, key, value) => {
    let rallyBoundaries = this.state.rallyBoundaries;
    if (!rallyBoundaries) {
      rallyBoundaries = this.emptyBoundaries(this.props.weatherTypes);
    }
    let rallyBoundary = rallyBoundaries.find(stateRallyBoundary => (
      stateRallyBoundary.weather_type === weatherType
      && stateRallyBoundary.is_front === isFront
    ));
    if (rallyBoundary) {
      this.setState(
        {
          rallyBoundaries:
            [
              ...rallyBoundaries.filter(stateRallyBoundary => (
                  stateRallyBoundary.weather_type !== rallyBoundary.weather_type
                  || stateRallyBoundary.is_front !== rallyBoundary.is_front
                ),
              ),
              {
                ...rallyBoundary,
                [key]: Number(value),
              },
            ],
        },
      );
    }
  };

  render() {
    const {
      rallyBrand,
      rallyModel,
      rallyGroup,
      rallyDimension,
      rallyBoundaries,
      dialog,
    } = this.state;

    const { classes, pending, t, cars } = this.props;

    const isEditing = this.props.rallyDimensionId && !this.props.isDuplicate;

    const isInvalid = (
        StringService.isEmpty(rallyBrand) ||
        StringService.isEmpty(rallyModel) ||
        StringService.isEmpty(rallyGroup) ||
        StringService.isEmpty(rallyDimension) ||
        StringService.isEmpty(rallyBoundaries)
      )
      || this.props.sending;

    if (pending || !cars) {
      return <MTCLoader />;
    } else if (isEditing && !this.state.id) {
      return <Error404 />;
    } else {
      return (
        <form
          onSubmit={this.onSubmit}
          className={classes.container}
        >
          <Grid
            container
            spacing={2}
            direction={'column'}
            justifyContent={'center'}
            alignItems={'center'}
          >
            {this.renderSelectOrAdd(
              rallyBrand,
              cars.rally_brands,
              'rallyBrand',
            )}
            {this.renderSelectOrAdd(
              rallyModel,
              cars.rally_models,
              'rallyModel',
              rallyBrand,
              'rally_brand_id',
            )}
            {this.renderSelectOrAdd(
              rallyGroup,
              cars.rally_groups,
              'rallyGroup',
              rallyModel,
              'rally_model_id',
            )}
          </Grid>
          <br />
          {rallyGroup && this.renderDimensions(rallyGroup)}
          <br />
          {rallyGroup &&
          <MTCSwitch
            gridMD={4}
            id={'is_public'}
            onChange={() => {
              let rallyDimension = this.state.rallyDimension
                ? this.state.rallyDimension
                : emptyRallyDimension;
              rallyDimension.is_public = !rallyDimension.is_public;
              this.setState({ rallyDimension });
            }}
            value={this.state.rallyDimension
              ? this.state.rallyDimension.is_public
              : false}
            disableText={t('tester')}
            enableText={t('public')}
          />}
          {rallyGroup &&
          <MTCSwitch
            gridMD={4}
            id={'dimension_state'}
            onChange={() => {
              let rallyDimension = this.state.rallyDimension
                ? this.state.rallyDimension
                : emptyRallyDimension;
              rallyDimension.state = rallyDimension.state == 0
                ? 1
                : 0;
              this.setState({ rallyDimension });
            }}
            value={this.state.rallyDimension
              ? (
                this.state.rallyDimension.state == 0
              )
              : false}
          />}
          <br />
          <Grid container justifyContent={'center'} alignItems={'center'} spacing={2}>
            <Grid item>
              <MTCButton
                disabled={isInvalid}
                sending={this.props.sending}
                text={t(isEditing
                  ? 'edit'
                  : 'create')}
                type={'submit'}
              />
            </Grid>
            {isEditing
            && <Grid item>
              <MTCButton
                disabled={isInvalid}
                text={t('duplicate')}
                onClick={this.handleDuplicate}
                color={'primary'}
              />
            </Grid>}
          </Grid>
          <br />

          <Dialog
            open={this.state.confirmDialogOpen}
            onClose={this.handleCloseCancel}
            aria-labelledby="alert-confirm-dialog-title"
            aria-describedby="alert-confirm-dialog-description"
          >
            {this.isTheSame() && !isEditing
            && <DialogTitle id="alert-confirm-dialog-title">
              {t('car_duplicate_warning')}
            </DialogTitle>}
            {isEditing && (
              this.state.editBrand || this.state.editModel || this.state.editGroup
            )
            && <DialogTitle id="alert-confirm-edit-tree">
              {t('rally_car_edit_tree_warning')}
            </DialogTitle>}
            <DialogActions>
              <Button onClick={this.handleCloseCancel} color="primary">
                {t('cancel')}
              </Button>
              <Button onClick={this.handleCloseConfirm} color="primary" autoFocus>
                {t('validate')}
              </Button>
            </DialogActions>
          </Dialog>

          <MTCDialog
            open={dialog.openDialog}
            handleClose={this.handleClose}
            dialogTitle={dialog.dialogTitle}
            dialogText={dialog.dialogText}
            handleCloseCancel={this.handleClose}
            handleCloseOK={dialog.dialogHandleOK}
            cancelText={t('cancel')}
            okText={t('validate')}
            textField={<MTCTextField
              id={dialog.dialogItem + '.' + dialog.newItemKey}
              value={dialog.newItem}
              onChange={event => this.setState(
                {
                  dialog: {
                    ...dialog,
                    newItem: event.target.value,
                  },
                },
              )}
            />}
          />
        </form>
      );
    }
  }
}

RallyCarForm.propTypes = {
  classes: PropTypes.object.isRequired,
  weatherTypes: PropTypes.object.isRequired,
  isDuplicate: PropTypes.bool.isRequired,
  rallyDimensionId: PropTypes.string,
};

function mapStateToProps(state) {
  return {
    cars: state.formRallyCars.content,
    pending: state.formRallyCars.pending,
    sending: state.formRallyCars.sending,
    error: state.formRallyCars.error,
  };
}

export default compose(
  withRouter,
  withStyles(styles),
  withTranslation,
  connect(mapStateToProps,
    {
      getRallyCars,
      postRallyCar,
      putRallyCar,
      resetTableRallyCars,
      searchReset,
      getSearchBrandsRallyCars,
      getSearchModelsRallyCars,
      getSearchGroupsRallyCars,
      getSearchDimensionsRallyCars,
    },
  ),
)(RallyCarForm);
