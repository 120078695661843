import { createTheme } from '@material-ui/core/styles';
import {
  michelin_black,
  michelin_blue_dark,
  michelin_blue_light,
  michelin_blue_main, michelin_text_grey_dark, michelin_text_grey_light,
  michelin_white,
  michelin_yellow_dark,
  michelin_yellow_light,
  michelin_yellow_main,
} from '../constants/michelin_colors';

export const theme = createTheme({
    palette: {
      primary: {
        light: michelin_blue_light,
        main: michelin_blue_main,
        dark: michelin_blue_dark,
        contrastText: michelin_white,
      },
      secondary: {
        light: michelin_yellow_light,
        main: michelin_yellow_main,
        dark: michelin_yellow_dark,
        contrastText: michelin_black,
      },
      text: {
        primary: michelin_black,
        secondary: michelin_text_grey_dark,
        disabled: michelin_text_grey_light,
        hint: michelin_black,
      },
      /*
          action: {
            selected: michelin_blue_michelin,
            hover: michelin_blue_michelin,
            disabled: michelin_fake_white,
          },
          text: {
            primary: michelin_fake_white,
            secondary: michelin_yellow,
            disabled: michelin_text_grey_light,
            hint: michelin_yellow,
          },
          primary1Color: michelin_yellow,
          primary2Color: michelin_orange,
          primary3Color: michelin_text_grey_light,
          accent1Color: michelin_blue_light_text,
          accent2Color: michelin_text_grey_light,
          accent3Color: michelin_text_grey_dark,
          textColor: michelin_fake_white,
          alternateTextColor: michelin_blue_background,
          canvasColor: michelin_blue_shadow,
          borderColor: michelin_text_grey_light,
          disabledColor: michelin_transparent_grey_shadow,
          pickerHeaderColor: michelin_yellow,
          clockCircleColor: michelin_text_grey_dark,
          shadowColor: michelin_text_grey_dark,
          greenColor: michelin_green,
          blackColor: michelin_black,
       */
    },

    overrides: {
      MuiContainer: {
        root: {
          overflowX: 'hidden',
        }
      },
      MuiInput: {
        underline: {
          '&:before': { //underline color when textfield is inactive
            backgroundColor: michelin_text_grey_dark,
            height: 1,
          },
          '&:hover:not($disabled):before': { //underline color when hovered
            backgroundColor: michelin_white,
            height: 1,
          },
        },
      },
      MuiCard: {
        root: {
          backgroundColor: '#FFF',
          color: michelin_black,
        },
      },
      MuiFormLabel: {
        root: {
          fontSize: 18,
          color: michelin_blue_main,
        },
      },
      MuiSelect: {
        icon: {
          color: michelin_blue_main,
        },
      },
      MuiSnackbarContent: {
        root: {
          backgroundColor: michelin_blue_main,
        },
      },
      MuiIconButton: {
        root: {
          color: michelin_black,
        },
      },
      MuiTypography: {
        h1: {
          fontSize: '3rem',
          fontWeight: 600,
          lineHeight: 1.167,
        },
        h2: {
          fontSize: '2.5rem',
          fontWeight: 400,
          lineHeight: 1.167,
        },
        body1: {
          '@media (min-width: 1427px) and (max-width: 1920px)': {
            fontSize: '0.9rem'
          },
          '@media (min-width: 1280px) and (max-width: 1427px)': {
            fontSize: '0.85rem'
          },
          '@media (min-width: 960px) and (max-width: 1280px)': {
            fontSize: '0.8rem'
          },
          '@media (max-width: 960px)': {
            fontSize: '0.75rem'
          }
        }
      },
      MuiDivider: {
        root: {
          backgroundColor: michelin_blue_main,
          height: 1,
        },
      },
      MuiTableCell: {
        root: {
          whiteSpace: 'nowrap',
          padding: '3px 16px',
          height: '48px',
          '@media (max-width: 960px)': {
            padding: '3px 5px',
            fontSize: '0.75rem'
          },
          '@media (min-width: 960px) and (max-width: 1280px)': {
            padding: '3px 7px',
            fontSize: '0.8rem'
          },
          '@media (min-width: 1280px) and (max-width: 1427px)': {
            padding: '3px 8px',
            fontSize: '0.85rem'
          },
          '@media (min-width: 1427px) and (max-width: 1920px)': {
            padding: '3px 9px',
            fontSize: '0.9rem'
          },
        },
        body: {
          color: 'inherit',
        },
      },
      MuiTableRow: {
        hover: {
          '&$hover:hover': {
            backgroundColor: michelin_blue_light,
            color: michelin_white,
          },
        },
      },
      MuiCardMedia: {
        media: {
          width: 'auto',
          margin: '10px auto',
        },
      },
      MuiDialogContentText: {
        root: {
          color: michelin_black
        }
      },
      MuiTableSortLabel: {
        root: {
          color: michelin_blue_main,
          '&:hover': {
            color: michelin_blue_dark,
            '&& $icon': {
              opacity: 1,
            },
          },
          '&$active': {
            color: michelin_blue_main,
            '&& $icon': {
              color: michelin_blue_main,
              opacity: 1,
            },
          },
        },
      },
      MuiBreadcrumbs: {
        ol: {
          flexWrap: "nowrap"
        }
      }
    },
  },
);
