import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router';
import { Fab } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import { useStyles } from './AddButton.style';

export const AddButton = (props) => {

  const { path, edit } = props;
  const classes = useStyles();
  const history = useHistory();

  return (
    <div>
      <Fab
        className={classes.button}
        color="secondary"
        aria-label="add"
        aria-haspopup={'false'}
        path={path}
        onClick={() => history.push(path)}
      >
        {!edit && <AddIcon/>}
        {edit && <EditIcon/>}
      </Fab>
    </div>
  );
}

export default AddButton;

AddButton.defaultProps = {
  edit: false
}

AddButton.propTypes = {
  path: PropTypes.string.isRequired
};
