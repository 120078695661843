import { inputStyle, textFieldClass } from '../../../constants/styles/input.style';

export const styles = theme => (
  {
    ...textFieldClass(theme),
    menu: {
      width: 100,
    },
    paper: {
      padding: '10px',
      margin: '10px',
      width: 'calc(100% - 30px)',
    },
    input: {
      ...inputStyle(theme),
      color: theme.palette.textColor,
      backgroundColor: theme.palette.alternateTextColor,
    },
    selectLabel: {
      zIndex: 2,
    },
    media: {
      border: '1px dashed black',
      padding: '10px',
      marginLeft: '15px',
      marginRight: '15px',
      borderRadius: '10px',
    },
    grid: {
      marginBottom: '2em',
    },
    sectorDivider: {
      margin: '5px 15%',
      width: '70%',
    },
    link: {
      padding: '5px 0',
    },
    sectorBloc: {
      width: '100%',
    },
  }
);
