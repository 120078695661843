import {
  FIRMWARE_GROUP_DELETED,
  FIRMWARE_GROUP_DOWNLOADED,
  FIRMWARE_GROUP_FIRMWARE_DOWNLOADED,
  FIRMWARE_GROUP_ITEM_DOWNLOADED,
  FIRMWARE_GROUP_SENDING,
  FIRMWARE_GROUPS_DOWNLOADED,
  FIRMWARE_GROUPS_FAILED,
  FIRMWARE_GROUPS_PENDING,
  FIRMWARE_GROUP_ITEM_DELETED, FIRMWARE_GROUP_FIRMWARE_DELETED,
} from './types';
import ApiService from '../../services/ApiService';
import { handleError } from '../infos/actions';

export const firmwareGroupsPendingAction = () => (
  {
    type: FIRMWARE_GROUPS_PENDING,
  }
);

export const firmwareGroupSendingAction = () => (
  {
    type: FIRMWARE_GROUP_SENDING,
  }
);

export const firmwareGroupDeletedAction = (id) => (
  {
    type: FIRMWARE_GROUP_DELETED,
    payload: id,
  }
);

export const firmwareGroupsFailedAction = (error) => (
  {
    type: FIRMWARE_GROUPS_FAILED,
    payload: error,
  }
);

export const firmwareGroupsDownloadedAction = (groups) => (
  {
    type: FIRMWARE_GROUPS_DOWNLOADED,
    payload: groups,
  }
);

export const firmwareGroupDownloadedAction = (group) => (
  {
    type: FIRMWARE_GROUP_DOWNLOADED,
    payload: group,
  }
);

export const firmwareGroupItemDownloadedAction = (id, item) => (
  {
    type: FIRMWARE_GROUP_ITEM_DOWNLOADED,
    payload: { id, item },
  }
);

export const firmwareGroupItemDeletedAction = (id, itemId) => (
  {
    type: FIRMWARE_GROUP_ITEM_DELETED,
    payload: { id, itemId },
  }
);

export const firmwareGroupFirmwareDownloadedAction = (id, firmware) => (
  {
    type: FIRMWARE_GROUP_FIRMWARE_DOWNLOADED,
    payload: { id, firmware },
  }
);

export const firmwareGroupFirmwareDeletedAction = (id, firmwareId) => (
  {
    type: FIRMWARE_GROUP_FIRMWARE_DELETED,
    payload: { id, firmwareId },
  }
);

export function getFirmwareGroups() {
  return (dispatch) => {
    dispatch(firmwareGroupsPendingAction());
    return ApiService.getFirmwareGroups()
      .then(data => {
        dispatch(firmwareGroupsDownloadedAction(data));
        return data;
      }).catch(error => {
        dispatch(firmwareGroupsFailedAction(error));
        dispatch(handleError(error));
        return null;
      });
  };
}

export function postFirmwareGroups(name) {
  return (dispatch) => {
    dispatch(firmwareGroupSendingAction());
    return ApiService.postFirmwareGroups(name)
      .then(data => {
        dispatch(firmwareGroupDownloadedAction(data));
        return data;
      }).catch(error => {
        dispatch(firmwareGroupsFailedAction(error));
        dispatch(handleError(error));
        return null;
      });
  };
}

export function putFirmwareGroups(id, name) {
  return (dispatch) => {
    dispatch(firmwareGroupSendingAction());
    return ApiService.putFirmwareGroups(id, name)
      .then(data => {
        dispatch(firmwareGroupDownloadedAction(data));
        return data;
      }).catch(error => {
        dispatch(firmwareGroupsFailedAction(error));
        dispatch(handleError(error));
        return null;
      });
  };
}

export function deleteFirmwareGroups(id) {
  return (dispatch) => {
    dispatch(firmwareGroupSendingAction());
    return ApiService.deleteFirmwareGroups(id)
      .then(() => {
        dispatch(firmwareGroupDeletedAction(id));
      }).catch(error => {
        dispatch(firmwareGroupsFailedAction(error));
        dispatch(handleError(error));
      });
  };
}

export function postFirmwareGroupItems(id, name) {
  return (dispatch) => {
    dispatch(firmwareGroupSendingAction());
    return ApiService.postFirmwareGroupItems(id, name)
      .then((data) => {
        dispatch(firmwareGroupItemDownloadedAction(id, data));
        return data;
      }).catch(error => {
        if (error.response && error.response.status === 409) {
          dispatch(firmwareGroupsFailedAction(error));
          return 409;
        } else {
          dispatch(firmwareGroupsFailedAction(error));
          dispatch(handleError(error));
          return null;
        }
      });
  };
}

export function deleteFirmwareGroupItems(id, itemId) {
  return (dispatch) => {
    dispatch(firmwareGroupSendingAction());
    return ApiService.deleteFirmwareGroupItems(id, itemId)
      .then(() => {
        dispatch(firmwareGroupItemDeletedAction(id, itemId));
      }).catch(error => {
        dispatch(firmwareGroupsFailedAction(error));
        dispatch(handleError(error));
      });
  };
}

export function postFirmwareGroupFirmwares(id, formData) {
  return (dispatch) => {
    dispatch(firmwareGroupSendingAction());
    return ApiService.postFirmwareGroupFirmware(id, formData)
      .then((data) => {
        dispatch(firmwareGroupFirmwareDownloadedAction(id, data));
        return data;
      }).catch(error => {
        dispatch(firmwareGroupsFailedAction(error));
        dispatch(handleError(error));
        return null;
      });
  };
}

export function deleteFirmwareGroupFirmware(id, firmwareId) {
  return (dispatch) => {
    dispatch(firmwareGroupSendingAction());
    return ApiService.deleteFirmwareGroupFirmware(id, firmwareId)
      .then(() => {
        dispatch(firmwareGroupFirmwareDeletedAction(id, firmwareId));
      }).catch(error => {
        dispatch(firmwareGroupsFailedAction(error));
        dispatch(handleError(error));
      });
  };
}
