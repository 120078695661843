import {
  RECEIVER_MMC_BLE_DOWNLOADED,
  RECEIVER_MMC_BLE_FAILED,
  RECEIVER_MMC_BLE_SENDING,
  RECEIVERS_MMC_BLE_DELETED,
  RECEIVERS_MMC_BLE_DOWNLOADED,
  RECEIVERS_MMC_BLE_PENDING,
} from './types';
import ApiService from '../../services/ApiService';
import { handleError } from '../infos/actions';

export const receiverMmcBleSendingAction = () => ({
  type: RECEIVER_MMC_BLE_SENDING,
});

export const receiverMmcBleDownloadedAction = receiver => ({
  type: RECEIVER_MMC_BLE_DOWNLOADED,
  payload: receiver,
});

export const receiversMmcBleDownloadedAction = receivers => ({
  type: RECEIVERS_MMC_BLE_DOWNLOADED,
  payload: receivers,
});

export const receiverMmcBleDeletedAction = id => ({
  type: RECEIVERS_MMC_BLE_DELETED,
  payload: id,
});

export const receiverMmcBleFailedAction = error => ({
  type: RECEIVER_MMC_BLE_FAILED,
  payload: error,
});

export const receiversMmcBlePendingAction = () => ({
  type: RECEIVERS_MMC_BLE_PENDING,
});

export function getReceiversMmcBle() {
  return (dispatch) => {
    dispatch(receiversMmcBlePendingAction());
    return ApiService
      .getMmcReceiversBle()
      .then((data) => {
        dispatch(receiversMmcBleDownloadedAction(data));
      })
      .catch((error) => {
        dispatch(receiverMmcBleFailedAction(error));
        dispatch(handleError(error));
      });
  };
}

export function postReceiverMmcBle(receiver) {
  return (dispatch) => {
    dispatch(receiverMmcBleSendingAction());
    return ApiService
      .postMmcReceiverBle(receiver)
      .then((data) => {
        dispatch(receiverMmcBleDownloadedAction(data));
        return data;
      })
      .catch((error) => {
        dispatch(receiverMmcBleFailedAction(error));
        if (error.response && error.response.status === 409) {
          error.response.statusText = 'Conflict: File already exists on server';
        }
        dispatch(handleError(error));
      });
  };
}

export function putReceiverMmcBle(receiver) {
  return (dispatch) => {
    dispatch(receiverMmcBleSendingAction());
    return ApiService
      .putMmcReceiverBle(receiver)
      .then((data) => {
        dispatch(receiverMmcBleDownloadedAction(data));
      })
      .catch((error) => {
        dispatch(receiverMmcBleFailedAction(error));
        dispatch(handleError(error));
      });
  };
}

export function deleteReceiverMmcBle(id) {
  return (dispatch) => {
    dispatch(receiverMmcBleSendingAction());
    return ApiService
      .deleteMmcReceiverBle(id)
      .then(() => {
        dispatch(receiverMmcBleDeletedAction(id));
      })
      .catch((error) => {
        dispatch(receiverMmcBleFailedAction(error));
        dispatch(handleError(error));
      });
  };
}
