import {
  LANG_CALL_CENTER_DOWNLOADED,
  LANG_CALL_CENTER_SENDING,
  LANG_CALL_CENTERS_DOWNLOADED,
  LANG_CALL_CENTERS_FAILED,
  LANG_CALL_CENTERS_PENDING,
  LANG_CALL_CENTER_DELETED
} from './types';
import ApiService from '../../services/ApiService';
import { handleError } from '../infos/actions';

export const langCallCenterSendingAction = () => ({
  type: LANG_CALL_CENTER_SENDING,
});

export const langCallCenterDownloadedAction = langCallCenter => ({
  type: LANG_CALL_CENTER_DOWNLOADED,
  payload: langCallCenter,
});

export const langCallCentersDownloadedAction = langCallCenters => ({
  type: LANG_CALL_CENTERS_DOWNLOADED,
  payload: langCallCenters,
});

export const langCallCentersFailedAction = error => ({
  type: LANG_CALL_CENTERS_FAILED,
  payload: error,
});

export const langCallCentersPendingAction = () => ({
  type: LANG_CALL_CENTERS_PENDING,
});

export const langCallCenterDeleteAction = langCallCenter => ({
  type: LANG_CALL_CENTER_DELETED,
  payload: {
    id: langCallCenter.id,
    lang: langCallCenter.systemlanguage,
  },
});

export function getLangCallCenters() {
  return (dispatch) => {
    dispatch(langCallCentersPendingAction());
    return ApiService
      .getLangCallCenters()
      .then((data) => {
        dispatch(langCallCentersDownloadedAction(data));
      })
      .catch((error) => {
        dispatch(langCallCentersFailedAction(error));
        dispatch(handleError(error));
      });
  };
}

export function deleteLangCallCenters(langCallCenter) {
  return (dispatch) => {
    dispatch(langCallCenterSendingAction());
    return ApiService
      .deleteLangCallCenters(langCallCenter)
      .then((data) => {
        dispatch(langCallCenterDeleteAction(langCallCenter));
      })
      .catch((error) => {
        dispatch(langCallCentersFailedAction(error));
        dispatch(handleError(error));
      });
  };
}

export function postLangCallCenter(langCallCenter) {
  return (dispatch) => {
    dispatch(langCallCenterSendingAction());
    return ApiService
      .postLangCallCenter(langCallCenter)
      .then((data) => {
        dispatch(langCallCenterDownloadedAction(data));
        return data.id;
      })
      .catch((error) => {
        dispatch(langCallCentersFailedAction(error));
        dispatch(handleError(error));
      });
  };
}

export function putLangCallCenter(langCallCenter) {
  return (dispatch) => {
    dispatch(langCallCenterSendingAction());
    return ApiService
      .putLangCallCenter(langCallCenter)
      .then((data) => {
        dispatch(langCallCenterDownloadedAction(data));
      })
      .catch((error) => {
        dispatch(langCallCentersFailedAction(error));
        dispatch(handleError(error));
      });
  };
}
