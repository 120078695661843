import React from 'react';
import withAuthentication from '../../../../hoc/withAuthentication';
import { ROLE_ADMIN } from '../../../../constants/roles';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { setTitle } from '../../../../store/title/actions';
import { useParams } from 'react-router';
import FirmwareGroupItemForm
  from '../../../../components/Receivers/FirmwareGroups/Items/FirmwareGroupItemForm';

export const AddFirmwareGroupItemPage = () => {
  const { t } = useTranslation();
  const { title } = useSelector(state => state.title);
  const dispatch = useDispatch();
  const { groupId } = useParams();

  if (title !== (t('title:title.firmware_group_item_add_page'))) {
    dispatch(setTitle(t('title:title.firmware_group_item_add_page')));
  }

  return <FirmwareGroupItemForm id={groupId} />;
}

export default withAuthentication(ROLE_ADMIN)(AddFirmwareGroupItemPage);
