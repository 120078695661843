import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import RallyCarForm from '../../../components/Cars/Form/RallyCarForm';
import { ROLE_ADMIN } from '../../../constants/roles';
import withAuthentication from '../../../hoc/withAuthentication';
import { RALLY_WEATHER_TYPE } from '../../../store/formRallyCars/types';
import { setTitle } from '../../../store/title/actions';

export const AddRallyCarPage = () => {

  const { t } = useTranslation();
  const { title } = useSelector(state => state.title);
  const dispatch = useDispatch();
  let history = useHistory();

  if (title !== t('title:title.add_rally_car_page')) {
    dispatch(setTitle(t('title:title.add_rally_car_page')));
  }

  return (
    <RallyCarForm
      history={history}
      isDuplicate={false}
      weatherTypes={RALLY_WEATHER_TYPE}
    />
  );
}

export default withAuthentication(ROLE_ADMIN)(AddRallyCarPage);
