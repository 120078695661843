import React from 'react';
import { useHistory, useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import RallyCarForm from '../../../components/Cars/Form/RallyCarForm';
import { ROLE_ADMIN } from '../../../constants/roles';
import withAuthentication from '../../../hoc/withAuthentication';
import { RALLY_WEATHER_TYPE } from '../../../store/formRallyCars/types';
import { setTitle } from '../../../store/title/actions';

export const DuplicateRallyCarPage = () => {

  const { t } = useTranslation();
  const { title } = useSelector(state => state.title);
  const dispatch = useDispatch();
  let history = useHistory();
  let { rallyDimensionId } = useParams();


  if (title !== t('title:title.add_rally_car_page')) {
    dispatch(setTitle(t('title:title.add_rally_car_page')));
  }
    return (
      <RallyCarForm
        history={history}
        rallyDimensionId={rallyDimensionId}
        isDuplicate={true}
        weatherTypes={RALLY_WEATHER_TYPE}
      />
    );
}

export default withAuthentication(ROLE_ADMIN)(DuplicateRallyCarPage);
