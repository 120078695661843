export const columns = [
  {
    id: 'country_code',
    numeric: false,
    disablePadding: false,
  },
  {
    id: 'lang',
    numeric: false,
    disablePadding: false,
  },
  {
    id: 'phone',
    numeric: false,
    disablePadding: false,
  },
  {
    id: 'opening',
    numeric: false,
    disablePadding: false,
  },
  {
    numeric: false,
    disablePadding: false,
  },
];
export const defaultOrderBy = 'country_code';
