export const EQUAL = '==';
export const NOT_EQUAL = '!=';
export const LESS = '<';
export const MORE = '>';
export const LESS_OR_EQUAL = '<=';
export const MORE_OR_EQUAL = '>=';
export const ALL_OPERATOR = [
  EQUAL,
  NOT_EQUAL,
  LESS,
  MORE,
  LESS_OR_EQUAL,
  MORE_OR_EQUAL
];
