export const stateWithDimensionId = (cars, dimensionId, weatherTypes, isDuplicate, actualDim) => {
  if (!isDuplicate && !dimensionId) {
    return {};
  }
  let dim = cars.rally_dimensions.find(d => d.id == dimensionId);
  if (dim && !actualDim) {
    const grp = cars.rally_groups.find(g => g.id == dim.rally_group_id);
    const mdl = cars.rally_models.find(m => m.id == grp.rally_model_id);
    const brd = cars.rally_brands.find(b => b.id == mdl.rally_brand_id);
    let bndrs = cars.rally_boundaries.filter(b => {
      return b.rally_dimension_id == dim.id;
    });
    if (bndrs.length < (
      2 * weatherTypes.properties.length
    )) {
      [false, true].forEach(isFront => {
        weatherTypes.properties.map(elem => elem.value).forEach(weatherType => {
          let boundary = bndrs.find(elem =>
            elem.is_front == isFront && elem.weather_type == weatherType,
          );
          if (!boundary) {
            bndrs.push(createEmptyRallyBoundary(isFront, weatherType));
          }
        });
      });
    }
    if (isDuplicate) {
      dim.id = null;
      bndrs.forEach(bndr => {
        delete bndr.id;
      });
    }
    return {
      id: !isDuplicate
        ? dim.id
        : null,
      rallyBrand: brd,
      rallyModel: mdl,
      rallyGroup: grp,
      rallyDimension: dim,
      rallyBoundaries: bndrs,
      initRallyBrand: brd,
      initRallyModel: mdl,
      initRallyGroup: grp,
      initRallyDimension: dim,
      initRallyBoundaries: bndrs,
    };
  } else {
    return false;
  }
};

export function createEmptyRallyBoundary(isFront, weatherType) {
  return {
    red_orange: null,
    orange_green: null,
    green_orange: null,
    orange_red: null,
    is_front: isFront,
    weather_type: weatherType,
  };
}

export const emptyRallyDimension = {
  boundary_ids: [],
  front_dimension: '',
  front_pressure: null,
  rear_dimension: '',
  rear_pressure: null,
  is_public: false,
  front_min_cold_pressure: null,
  rear_min_cold_pressure: null,
  compatible_with_receiver: true,
  state: 0,
};
export const INITIAL_STATE = {
  id: null,
  rallyBrand: null,
  rallyModel: null,
  rallyGroup: null,
  rallyDimension: null,
  rallyBoundaries: null,
  initRallyBrand: null,
  initRallyModel: null,
  initRallyGroup: null,
  initWithDimension: true,
  initRallyDimension: null,
  initRallyBoundaries: null,
  orders: [
    {
      name: 'rallyBrand',
      order: 0,
    },
    {
      name: 'rallyModel',
      order: 1,
    },
    {
      name: 'rallyGroup',
      order: 2,
    },
    {
      name: 'rallyDimension',
      order: 3,
    },
    {
      name: 'rallyBoundaries',
      order: 4,
    },
  ],
  dialog: {
    openDialog: false,
    dialogItem: '',
    dialogTitle: '',
    dialogText: '',
    dialogHandleOK: () => {
    },
    newItemKey: '',
    newItem: '',
  },
  confirmDialogOpen: false,
  editBrand: false,
  editModel: false,
  editGroup: false,
};
