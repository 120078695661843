import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import withAuthentication from '../../hoc/withAuthentication';
import { ROLE_ADMIN } from '../../constants/roles';
import { setTitle } from '../../store/title/actions';
import { useTranslation } from 'react-i18next';
import NumberKPIBloc from '../../components/Dashboard/NumberKPIBlock/NumberKPIBlock';
import Error404 from '../Error404';
import MTCLoader from '../../components/Layout/MTCLoader';
import CountryKPIBloc from '../../components/Dashboard/CountryKPIBlock/CountryKPIBlock';
import { useStyles } from './DashboardPage.style';
import TopUserKpiBloc from '../../components/Dashboard/TopUserKpiBlock/TopUserKpiBlock';
import LastRunsKPIBlock from '../../components/Dashboard/LastRunsKPIBlock/LastRunsKPIBlock';

const DashboardPage = () => {

  const { t } = useTranslation();
  const { title } = useSelector(state => state.title);
  const home = useSelector(state => state.home);
  const dispatch = useDispatch();
  const classes = useStyles();

  if (title !== t('title:title.dashboard')) {
    dispatch(setTitle(t('title:title.dashboard')));
  }
  if (home.pending) {
    return <MTCLoader />;
  } else if (!home.error) {
    return (
      <Grid container direction="row" spacing={1}>
        <Grid item xl={3} md={4} sm={6} sx={12} className={classes.item}>
          <NumberKPIBloc
            number={home.rallyUserCount}
            title={t('kpi:rally_user_count_title')}
            description={t('kpi:rally_user_count_description')}
          />
        </Grid>
        <Grid item  xl={3} md={4} sm={6} sx={12} className={classes.item}>
          <NumberKPIBloc
            number={home.rallyCarCount}
            title={t('kpi:rally_car_count_title')}
            description={t('kpi:rally_car_count_description')}
          />
        </Grid>
        <Grid item  xl={3} md={4} sm={6} sx={12} className={classes.item}>
          <CountryKPIBloc
            title={t('kpi:country_user_count_title')}
            countries={home.countryUserCount}
          />
        </Grid>
        <Grid item  xl={3} md={4} sm={6} sx={12} className={classes.item}>
          <NumberKPIBloc
            number={home.rallySessionCount}
            title={t('kpi:rally_session_count_title')}
            description={t('kpi:rally_session_count_description')}
          />
        </Grid>
        <Grid item  xl={3} md={4} sm={6} sx={12} className={classes.item}>
          <NumberKPIBloc
            number={home.userMmcReceiverAverage}
            title={t('kpi:user_mmc_receiver_average_title')}
            description={t('kpi:user_mmc_receiver_average_description')}
            isInt={false}
          />
        </Grid>
        <Grid item  xl={3} md={4} sm={6} sx={12} className={classes.item}>
          <TopUserKpiBloc
            title={t('kpi:max_rally_session_users_title')}
            maxRallyRunUsers={home.maxRallySessionUsers}
          />
        </Grid>
        <Grid item xs={12} className={classes.item}>
          <LastRunsKPIBlock
            title={t('kpi:last_rally_runs_title')}
            lastRallyRuns={home.lastRallyRuns}
            defaultSearch={home.search}
          />
        </Grid>
      </Grid>
    );
  } else {
    return <Error404 />;
  }
};

export default withAuthentication(ROLE_ADMIN)(DashboardPage);
