import makeStyles from '@material-ui/core/styles/makeStyles';

export const useStyles = makeStyles((theme) => {
  return {
    tableW: {
      overflowX: 'auto',
    },
    container: {
      paddingBottom: '5em',
    },
    head: {
      color: theme.palette.secondary.contrastText,
    },
    sortLabel: {
      color: theme.palette.secondary.contrastText,
    },
    headerRow: {
      borderBottom: '3px solid ' + theme.palette.primary.main,
    },
    fab: {
      position: 'fixed',
      display: 'flex',
      justifyContent: 'center',
      bottom: '3vh',
      right: '3vh',
    },
  };
});
