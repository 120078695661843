import { michelin_blue_light, michelin_yellow_main } from '../../constants/michelin_colors';
import makeStyles from '@material-ui/core/styles/makeStyles';

const roundSize = '18px';
const childStyle = {
  width: roundSize,
  height: roundSize,
  backgroundColor: michelin_blue_light,
  margin: '0.2em 0.5em 0.5em 0.5em',
  borderRadius: '100%',
  display: 'inline-block',
  '-webkit-animation': 'sk-bouncedelay 1.9s infinite ease-in-out both',
  animation: 'sk-bouncedelay 1.9s infinite ease-in-out both',
};
export const useStyles = makeStyles({
  parent: {
    margin: '0 auto',
    height: '24px',
  },
  child1: {
    ...childStyle,
    '-webkit-animation-delay': '-0.32s',
    animationDelay: '-0.32s',
  },
  child2: {
    ...childStyle,
    '-webkit-animation-delay': '-0.16s',
    animationDelay: '-0.16s',
  },
  child3: {
    ...childStyle,
  },
  secondary: {
    backgroundColor: michelin_yellow_main,
  }
});
