import React from 'react';
import withAuthentication from '../../hoc/withAuthentication';
import { ROLE_ADMIN } from '../../constants/roles';
import { useTranslation } from 'react-i18next';
import { setTitle } from '../../store/title/actions';
import { useDispatch, useSelector } from 'react-redux';
import { getCatalogsTags } from '../../store/catalogsTags/actions';
import CatalogsItemsForm from '../../components/Catalogs/Items/CatalogsItemsForm/CatalogsItemsForm';

export const CatalogsAddPage = () => {
  const { t } = useTranslation();

  const tags = useSelector(state => state.catalogsTags);
  const { title } = useSelector(state => state.title);
  const dispatch = useDispatch();

  if (title !== t('title:title.add_catalogs_page')) {
    dispatch(setTitle(t('title:title.add_catalogs_page')));
  }

  if (!tags.content && !tags.pending && !tags.error) {
    dispatch(getCatalogsTags());
  }

  return <CatalogsItemsForm tags={tags.content || []}/>
}

export default withAuthentication(ROLE_ADMIN)(CatalogsAddPage);
