const RALLY_CARS_PENDING = 'from_rally_cars_pending';
const RALLY_CARS_FAILED = 'from_rally_cars_failed';
const RALLY_CARS_DOWNLOADED = 'from_rally_cars_downloaded';
const RALLY_CARS_RESET = 'from_rally_cars_reset';
const RALLY_CAR_SENDING = 'from_rally_car_sending';
const RALLY_CAR_DOWNLOADED = 'from_rally_car_downloaded';
const RALLY_WEATHER_TYPE = Object.freeze({
  DRY: 1,
  WET: 3,
  properties: [
    { name: 'weather_dry', value: 1 },
    { name: 'weather_wet', value: 3 },
  ],
});
export {
  RALLY_CARS_PENDING,
  RALLY_CARS_FAILED,
  RALLY_CARS_DOWNLOADED,
  RALLY_CAR_SENDING,
  RALLY_CAR_DOWNLOADED,
  RALLY_WEATHER_TYPE,
  RALLY_CARS_RESET
};