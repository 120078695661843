import { inputStyle } from '../../../constants/styles/input.style';
import { michelin_blue_main } from '../../../constants/michelin_colors';

export const styles = theme => (
  {
    container: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    },
    tirePaper: {
      padding: '10px',
      margin: '15px',
      width: 'calc(100% - 30px)',
      borderRadius: '4px',
    },
    input: {
      ...inputStyle(theme),
      color: theme.palette.textColor,
      backgroundColor: theme.palette.alternateTextColor,
    },
    topPartText: {
      width: '30%',
      textAlign: 'left',
      [theme.breakpoints.down('md')]: {
        width: '50%',
      },
      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
    },
    topPartTextContainer: {
      marginLeft: '8px',
      marginRight: '8px',
    },
    topPartValue: {
      borderBottom: '1px solid ' + michelin_blue_main,
      display: 'flex',
      justifyContent: 'space-between',
    },
    editIcon: {
      verticalAlign: 'text-bottom',
      fontSize: '1.3em',
      '&:hover': {
        color: michelin_blue_main,
        cursor: 'pointer',
      },
    },
  }
);
