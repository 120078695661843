import auth0 from 'auth0-js';
import AUTH_CONFIG from './auth0-variables';
import { ROLE_ADMIN, ROLE_SUPPORT } from '../../constants/roles';
import { AUTH0_ID_TOKEN_PAYLOAD } from '../../constants/env';

export default class Auth {

  isAuth = null

  auth0 = new auth0.WebAuth({
    domain: AUTH_CONFIG.domain,
    clientID: AUTH_CONFIG.clientId,
    redirectUri: AUTH_CONFIG.callbackUrl,
    audience: AUTH_CONFIG.audience,
    grantType: AUTH_CONFIG.grantType,
    responseType: 'token id_token',
    scope: 'openid profile',
  });

  constructor() {
    if (!Auth.instance) {
      this.login = this.login.bind(this);
      this.handleAuthentication = this.handleAuthentication.bind(this);
      Auth.instance = this;
    }
    return Auth.instance;
  }

  static setSession(authResult) {
    // Set the time that the access token will expire at
    let expiresAt = JSON.stringify((
      authResult.expiresIn * 1000
    ) + new Date().getTime());
    localStorage.setItem('access_token', authResult.accessToken);
    localStorage.setItem('id_token', authResult.idToken);
    localStorage.setItem('expires_at', expiresAt);
    let idTokenPayload = authResult.idTokenPayload;
    localStorage.setItem('nickname', idTokenPayload['nickname']);
    localStorage.setItem('email', idTokenPayload['name']);
    localStorage.setItem('role', idTokenPayload[AUTH0_ID_TOKEN_PAYLOAD]);
    localStorage.setItem('in_db', 'inDB');
  }

  static logout() {
    // Clear access token and ID token from local storage
    localStorage.removeItem('access_token');
    localStorage.removeItem('id_token');
    localStorage.removeItem('expires_at');
    localStorage.removeItem('role');
    localStorage.removeItem('nickname');
    localStorage.removeItem('email');
    localStorage.removeItem('in_db');
  }

  static isAuthenticated() {
    // Check whether the current time is past the
    // access token's expiry time
    const expiresAtItem = localStorage.getItem('expires_at');
    if (!expiresAtItem) {
      return false;
    }
    const expiresAt = JSON.parse(expiresAtItem);
    const isInDatabase = localStorage.getItem('in_db') === 'inDB';
    const role = localStorage.getItem('role');
    // TODO Refresh token
    return new Date().getTime() < expiresAt && isInDatabase && (
      role === ROLE_ADMIN || role === ROLE_SUPPORT
    );
  }

  // Set isAuth after auth0 parseHash
  authAfterParse = (err, authResult) => {
    if (err) {
      throw err;
    }
    if (authResult && authResult.accessToken && authResult.idToken) {
      this.isAuth = Auth.setSession(authResult);
    } else {
      this.isAuth = false;
    }
  };

  login() {
    this.auth0.authorize();
  }

  // sleep method, only use when waiting auth0.parseHash
  timeout = (ms) => {
    return new Promise((resolve) => setTimeout(resolve, ms));
  };

  // use auth0 to parse auth information
  handleAuthentication = async (recursive = false) => {
    if (!recursive) {
      await this.auth0.parseHash(this.authAfterParse);
    }
    // recursive to wait the end of auth0.parseHash
    if (this.isAuth !== null) {
      return this.isAuth;
    }
    await this.timeout(100);
    return this.handleAuthentication(true);
  };
}
