import React, { useState } from 'react';
import { useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { setBack, setTitle } from '../../store/title/actions';
import Error404 from '../Error404';
import MTCLoader from '../../components/Layout/MTCLoader';
import { getRequests } from '../../store/requests/actions';
import EditMessageRequest from '../../components/Requests/Message/EditMessageRequest';
import withAuthentication from '../../hoc/withAuthentication';
import { ROLE_ADMIN } from '../../constants/roles';
import { REQUESTS } from '../../constants/routes';

export const EditMessageRequestPage = () => {

  const { t } = useTranslation();
  const { title } = useSelector(state => state.title);
  const {
    content,
    error,
    pending,
  } = useSelector(state => state.requests);
  const dispatch = useDispatch();
  const { requestId } = useParams();
  const [request, setRequest] = useState(null);

  if (!content && !error && !pending) {
    dispatch(getRequests());
  }
  if (content && request === null) {
    const result = content['messages_requests'].find(e => e.id == requestId);
    if (result) {
      setRequest(result);
    } else {
      setRequest(false);
    }
  }

  if (title !== t('title:title.edit_request_page')) {
    dispatch(setTitle(t('title:title.edit_request_page')));
    dispatch(setBack(REQUESTS, { value: 0 }));
  }

  if (pending) {
    return <MTCLoader />;
  } else if (content && request) {
    return <EditMessageRequest request={request} />;
  } else {
    return <Error404 />;
  }
};

export default withAuthentication(ROLE_ADMIN)(EditMessageRequestPage);
