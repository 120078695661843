import { PSC2, PSC2R } from '../../../constants/raceTireType';

export const INITIAL_STATE = {
  withDimension: true,
  brandId: '',
  brandValue: '',
  newBrand: false,
  rangeId: '',
  rangeValue: '',
  newRange: false,
  waitingRange: true,
  modelId: '',
  modelValue: '',
  newModel: false,
  waitingModel: true,
  engineId: '',
  engineValue: '',
  newEngine: false,
  waitingEngine: true,
  waitingDimension: true,
  confirmDialogOpen: false,
  considerDuplicatedCar: false,
};
export const ORDERS = ['brand', 'range', 'model', 'engine', 'dimension', 'boundaries'];

export function stateToApiFormat(state) {
  let apiBoundaries = [];
  if (state.dimension.compatible_with_receiver) {
    if (state.dimension.has_psc2 && state.dimension.has_psc2r) {
      apiBoundaries = state.boundaries;
    } else if (state.dimension.has_psc2) {
      apiBoundaries = state.boundaries.filter(b => b.tire_type === PSC2);
    } else if (state.dimension.has_psc2r) {
      apiBoundaries = state.boundaries.filter(b => b.tire_type === PSC2R);
    }
  }

  return {
    'brand': {
      'id': state.newBrand
        ? null
        : state.brandId,
      'name': state.newBrand
        ? state.brandValue.trim()
        : null,
    },
    'range': {
      'id': state.newRange
        ? null
        : state.rangeId,
      'name': state.newRange
        ? state.rangeValue.trim()
        : null,
    },
    'range_model': {
      'id': state.newModel
        ? null
        : state.modelId,
      'name': state.newModel
        ? state.modelValue.trim()
        : null,
    },
    'engine': {
      'id': state.newEngine
        ? null
        : state.engineId,
      'name': state.newEngine
        ? state.engineValue.trim()
        : null,
    },
    'with_dimension': state.withDimension,
    'dimension': {
      ...state.dimension,
      front_pressure: parseFloat(state.dimension.front_pressure),
      rear_pressure: parseFloat(state.dimension.rear_pressure),
      available_tire_types: state.dimension.available_tire_types.join(','),
      state: state.dimension.state
        ? 0
        : 1,
    },
    'boundaries': apiBoundaries,
  };
}
