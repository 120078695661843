import makeStyles from '@material-ui/core/styles/makeStyles';
import { inputStyle, textFieldClass } from '../../constants/styles/input.style';

export const useStyles = makeStyles(theme => {
  return {
    ...textFieldClass(theme),
    select: {
      ...inputStyle(theme),
    },
    menu: {
      width: 100,
    },
    selectLabel: {
      zIndex: 2,
    },
    grid: {},
  };
});
