import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import Dropzone from 'react-dropzone';
import { useTheme } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import i18next from '../../../../i18n';
import { data_languages, data_languages_no_fr } from '../../../../constants/lang';
import { CATALOGS } from '../../../../constants/routes';
import { TYPE_PDF, TYPE_YOUTUBE } from '../../../../constants/catalogItemType';
import { supportedPdfDropFiles, supportedPictureDropFiles } from '../../../../constants/files';
import { postCatalogsItem, putCatalogsItem } from '../../../../store/catalogsItems/actions';
import { handleError } from '../../../../store/infos/actions';
import MTCButton from '../../../Layout/Button/MTCButton';
import MTCTextField from '../../../Form/MTCTextField';
import CardMedia from '@material-ui/core/CardMedia';
import Image from '@material-ui/icons/Image';
import { INITIAL_ERROR_STATE, INITIAL_STATE } from './CatalogsItemsForm.const';
import { useStyles } from './CatalogsItemsForm.style';
import { stateWithItem, errorStateWithItem } from './CatalogsItemsForm.func';

export const CatalogsItemsForm = ({ item, tags }) => {
  const [state, setState] = useState(INITIAL_STATE);
  const [errors, setErrors] = useState(INITIAL_ERROR_STATE);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const theme = useTheme();
  const classes = useStyles(theme);
  const { sending } = useSelector(state => state.catalogsItems);
  const lang = i18next.languages[0].split('-')[0];
  const [thumbnail, setThumbnail] = useState('');

  if (item !== undefined && item !== null && state.id === null) {
    setState(stateWithItem(item));
    setErrors(errorStateWithItem(item));
    setThumbnail(item.thumbnail ?? '');
  }

  const isEditing = item !== undefined && item !== null;

  const handleStatePropertyChange = (property, value) => {
    let newState = { ...state };
    newState[property] = value;
    let newErrors = { ...errors };
    newErrors[property + '_error'] = null;
    if (property === 'type' && state[property] !== value) {
      newState.link_youtube = null;
      newErrors.link_youtube_error = null;
    }
    setState(newState);
    setErrors(newErrors);
  };

  const handleStateFileChange = (property, accepted, rejected) => {
    if (rejected.length) {
      if (rejected[0].type === '') {
        dispatch(handleError({
          message: t('error.format.pdf_unknown_type'),
        }));
      } else {
        dispatch(handleError({
          message: t('error.format.pdf') + rejected[0].type,
        }));
      }
    } else {
      let newState = {...state};
      newState[property] = accepted[0].name;
      newState[property + '_to_upload'] = accepted[0];
      setState(newState);
    }
  };

  const handleStateThumbnailChange = (accepted, rejected) => {
    if (rejected.length) {
      if (rejected[0].type === '') {
        dispatch(handleError({
          message: t('error.format.image_unknown_type'),
        }));
      } else {
        dispatch(handleError({
          message: t('error.format.image') + rejected[0].type,
        }));
      }
    } else {
      setState({
        ...state,
        thumbnail_to_upload: accepted[0]
      });
      const fileReader = new FileReader();
      fileReader.readAsDataURL(accepted[0]);
      fileReader.addEventListener("load", function () {
        setThumbnail(this.result);
      });
    }
  }

  const handleSubmit = event => {
    event.preventDefault();
    event.stopPropagation();
    if (!sending) {
      let errorCount = 0;
      let newErrorState = { ...INITIAL_ERROR_STATE };
      data_languages.forEach(dl => {
        const nameKey = `name_${dl}`;
        const name = state[nameKey];
        const descKey = `description_${dl}`;
        const desc = state[descKey];
        const pdfKey = `pdf_${dl}`;
        const pdf = state[pdfKey + '_to_upload'];
        if (name === null || name.trim() === '') {
          errorCount++;
          newErrorState[nameKey + '_error'] = t('error.required_error');
        }
        if (desc === null || desc.trim() === '') {
          errorCount++;
          newErrorState[descKey + '_error'] = t('error.required_error');
        }
        if (state.type === TYPE_PDF && !isEditing && !pdf) {
          errorCount++;
          newErrorState[pdfKey + '_error'] = t('error.required_error');
        }
        if (state.type === TYPE_PDF && isEditing && !pdf && !item.translations[dl].link) {
          errorCount++;
          newErrorState[pdfKey + '_error'] = t('error.required_error');
        }
      });
      if (state.type === null || ![TYPE_PDF, TYPE_YOUTUBE].includes(state.type)) {
        errorCount++;
        newErrorState.type_error = t('error.required_error');
      }
      if (state.type === TYPE_YOUTUBE) {
        if(
          state.link_youtube === undefined
          || state.link_youtube === null
          || state.link_youtube === ''
        ) {
          errorCount++;
          newErrorState.link_youtube_error = t('error.required_error');
        }
        if (
            state.link_youtube !== undefined
            && state.link_youtube !== null
            && state.link_youtube.length !== 11
        ) {
          errorCount++;
          newErrorState.link_youtube_error = t('error.link_youtube_error');
        }
      }

      if (errorCount === 0) {
        const formData = new FormData();
        formData.append('json', JSON.stringify({
          type: state.type,
          tags: state.tags,
          translations: data_languages.reduce((o, key) => (
            {
              ...o,
              [`${key}`]: {
                name: state[`name_${key}`],
                description: state[`description_${key}`],
                link: state.link_youtube,
              },
            }
          ), {}),
        }));
        data_languages.forEach(dl => {
          const pdf = state[`pdf_${dl}_to_upload`];
          if (pdf) {
            const pdfApiKey = `pdf-${dl}`;
            formData.append(pdfApiKey, pdf);
          }
        });
        if (state.thumbnail_to_upload) {
          formData.append('thumbnail', state.thumbnail_to_upload);
        }
        if (isEditing) {
          dispatch(putCatalogsItem(state.id, formData))
            .then(() => {
              history.push({
                pathname: CATALOGS,
                state: {
                  value: (
                    state.type + 1
                  ) % 2,
                },
              });
            });
        } else {
          dispatch(postCatalogsItem(formData))
            .then(() => {
              history.push({
                pathname: CATALOGS,
                state: {
                  value: (
                    state.type + 1
                  ) % 2,
                },
              });
            });
        }
      } else {
        setErrors(newErrorState);
      }
    }
  };

  return <form>
    <Grid
      container
      align="center"
      className={classes.grid}
      direction={'column'}
      justifyContent={'center'}
    >
      <Grid item xs={12}>
        <Paper className={classes.paper}>
          <Grid container>
            <Grid item xs={12}>
              <Typography
                align="center"
                variant="h5"
                component="h2"
                gutterBottom>
                {t('general_infos')}
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <FormControl margin={'normal'}>
                <InputLabel id="type_label" required={true} error={errors.type_error !== null}>
                  {'Type'}
                </InputLabel>
                <Select
                  id={'type'}
                  onChange={event => handleStatePropertyChange(
                    'type',
                    event.target.value,
                  )}
                  value={state.type}
                  className={classes.input}
                  disabled={isEditing}
                  error={errors.type_error !== null}
                >
                  {[TYPE_YOUTUBE, TYPE_PDF].map(type => (
                    <MenuItem value={type} key={`catalog_item_types.${type}`}>
                      {t(`catalog_item_types.${type}`)}
                    </MenuItem>
                  ))}
                </Select>
                {errors.type_error &&
                <FormHelperText
                  error={true}
                  className={classes.input}
                >
                  {errors.type_error}
                </FormHelperText>}
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              <FormControl margin={'normal'}>
                <InputLabel id="type_label">
                  {'Tags'}
                </InputLabel>
                <Select
                  id={'tags'}
                  onChange={event => handleStatePropertyChange(
                    'tags',
                    event.target.value,
                  )}
                  value={state.tags}
                  className={classes.input}
                  multiple={true}
                >
                  {tags.map(tag => (
                    <MenuItem value={tag.id} key={`catalog_item_tags.${tag.id}`}>
                      {tag.translations[lang].name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            {state.type === TYPE_YOUTUBE && <MTCTextField
              gridMD={12}
              id={'link_youtube'}
              key={'link_youtube'}
              onChange={event => handleStatePropertyChange('link_youtube',
                event.target.value,
              )}
              value={state.link_youtube}
              required={state.type === TYPE_YOUTUBE}
              errorMessage={errors.link_youtube_error}
              helperText={t('link_youtube_helper')}
            />}
            {state.type === TYPE_YOUTUBE &&
            <Grid item xs={12} key={`thumbGrig`}>
              <FormControl margin={'normal'}>
                <InputLabel
                  id="thumbnail_label"
                  className={classes.dropzoneLabel}
                  error={errors.thumbnail_error !== null}
                >
                  {t('upload_thumbnail')}
                </InputLabel>
                <Dropzone
                  id={'thumbnail'}
                  className={classes.media}
                  accept={supportedPictureDropFiles}
                  onDrop={handleStateThumbnailChange}
                >
                  {thumbnail
                    ? <CardMedia
                      component="img"
                      image={thumbnail}
                      title={t('thumbnail')}
                      className={classes.cardMedia}
                    />
                    : <div>
                      <Typography variant="subtitle1">{t('image_drop')}</Typography>
                      <br />
                      <Image />
                    </div>
                  }
                </Dropzone>
                {errors.thumbnail_error !== null &&
                <FormHelperText
                  error={true}
                  className={classes.input}
                >
                  {errors.thumbnail_error}
                </FormHelperText>}
              </FormControl>
            </Grid>}

          </Grid>
        </Paper>
      </Grid>
      <Grid item xs={12}>
        <Paper className={classes.paper}>
          <Grid container>
            <Grid item xs={12}>
              <Typography
                align="center"
                variant="h5"
                component="h2"
                gutterBottom>
                {t('name')}
              </Typography>
            </Grid>
            <MTCTextField
              gridMD={12}
              id={`name_fr`}
              key={`name_fr`}
              onChange={event => handleStatePropertyChange(`name_fr`, event.target.value)}
              value={state[`name_fr`]}
              required={true}
              errorMessage={errors[`name_fr_error`]}
              onBlur={event => setState({
                ...state,
                ...data_languages.reduce((o, key) => (
                  {
                    ...o,
                    [`name_${key}`]: state[`name_${key}`] || event.target.value,
                  }
                ), {}),
              })}
            />
            {data_languages_no_fr.map(lg => (
              <MTCTextField
                gridMD={12}
                id={`name_${lg}`}
                key={`name_${lg}`}
                onChange={event => handleStatePropertyChange(`name_${lg}`,
                  event.target.value,
                )}
                value={state[`name_${lg}`]}
                required={true}
                errorMessage={errors[`name_${lg}_error`]}
              />
            ))}
          </Grid>
        </Paper>
      </Grid>
      <Grid item xs={12}>
        <Paper className={classes.paper}>
          <Grid container>
            <Grid item xs={12}>
              <Typography
                align="center"
                variant="h5"
                component="h2"
                gutterBottom>
                {t('description')}
              </Typography>
            </Grid>
            <MTCTextField
              gridMD={12}
              id={`description_fr`}
              key={`description_fr`}
              onChange={event => handleStatePropertyChange(`description_fr`,
                event.target.value,
              )}
              value={state[`description_fr`]}
              required={true}
              multiline={true}
              errorMessage={errors[`description_fr_error`]}
              onBlur={event => setState({
                ...state,
                ...data_languages.reduce((o, key) => (
                  {
                    ...o,
                    [`description_${key}`]: state[`description_${key}`] || event.target.value,
                  }
                ), {}),
              })}
            />
            {data_languages_no_fr.map(lg => (
              <MTCTextField
                gridMD={12}
                id={`description_${lg}`}
                key={`description_${lg}`}
                onChange={event => handleStatePropertyChange(`description_${lg}`,
                  event.target.value,
                )}
                value={state[`description_${lg}`]}
                required={true}
                multiline={true}
                errorMessage={errors[`description_${lg}_error`]}
              />
            ))}
          </Grid>
        </Paper>
      </Grid>

      {state.type === TYPE_PDF && <Grid item>
        <Paper className={classes.paper}>
          <Grid container>
            <Grid item xs={12}>
              <Typography
                align="center"
                variant="h5"
                component="h2"
                gutterBottom>
                {t('pdf')}
              </Typography>
              {isEditing
              && <Typography
                align="center"
                variant="subtitle1"
                component="p"
                gutterBottom>
                {t('editing_pdf_helper_text')}
              </Typography>}
            </Grid>
            {data_languages.map(lg => {
              return <Grid item xs={12} key={`pdf_${lg}`}>

                <FormControl margin={'normal'}>
                  <InputLabel
                    id="type_label"
                    required={!isEditing}
                    className={classes.dropzoneLabel}
                    error={errors[`pdf_${lg}_error`] !== null}
                  >
                    {t(`pdf_${lg}`)}
                  </InputLabel>
                  <Dropzone
                    id={`pdf_${lg}`}
                    className={classes.media}
                    accept={supportedPdfDropFiles}
                    onDrop={(accepted, rejected) =>
                      handleStateFileChange(`pdf_${lg}`, accepted, rejected)
                    }
                  >
                    {!state[`pdf_${lg}`]
                    && <Typography variant="subtitle1">{t('pdf_drop')}</Typography>
                    }
                    {state[`pdf_${lg}`]
                    && <Typography variant="subtitle1">{state[`pdf_${lg}`]}</Typography>
                    }
                  </Dropzone>
                  {errors[`pdf_${lg}_error`] !== null &&
                  <FormHelperText
                    error={true}
                    className={classes.input}
                  >
                    {errors[`pdf_${lg}_error`]}
                  </FormHelperText>}
                </FormControl>
              </Grid>;
            })}
          </Grid>
        </Paper>
      </Grid>}
      <Grid item>
        <MTCButton
          disabled={false}
          onClick={handleSubmit}
          type={'button'}
          sending={sending}
          text={isEditing
            ? t('edit')
            : t('create')}
        />
      </Grid>
    </Grid>
  </form>;
};

export default CatalogsItemsForm;
