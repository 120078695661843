import { michelin_blue_main } from '../../../constants/michelin_colors';
import makeStyles from '@material-ui/core/styles/makeStyles';

const mapHeight = '550px';
const imgHeight = 150;

export const useStyles = makeStyles((theme) => (
  {
    mapContainer: {
      position: 'relative',
      marginBottom: theme.spacing(1)
    },
    pictureBloc: {
      width: `${imgHeight}px`,
      backgroundColor: michelin_blue_main,
      position: 'absolute',
      maxWidth: '150px',
      top: 0,
      zIndex: 1,
      right: 0,
      borderBottom: '2px solid #FFF',
      borderLeft: '2px solid #FFF',
      borderBottomLeftRadius: '24px',
    },
    centerImg: {
      height: '100%',
      display: 'flex',
      flexDirection: "column",
      justifyContent: 'center',
      margin: 'auto',
      padding: '10px',
      position: 'relative',
      maxWidth: '100%',
    },
    img: {
      maxHeight: '100%',
      minWidth: '100%',
    },
    mapBloc: {
      height: mapHeight,
      width: '100%'
    }
  }
));
