import Typography from '@material-ui/core/Typography';
import React from 'react';
import { Container } from '@material-ui/core';
import { useSelector } from 'react-redux';
import i18next from '../../../../i18n';
import MTCLoader from '../../../Layout/MTCLoader';
import { useStyles } from './FaqElementsExplorerArticle.style';

const FaqElementsExplorerArticle = ({ element }) => {
  const { content, pending } = useSelector(state => state.faqArticles);
  const classes = useStyles();
  const lang = i18next.languages[0].split('-')[0];

  let title = element.translations[lang]
    ? element.translations[lang].title
    : '';

  let article = (
    content && element.article_id
  )
    ? content.find(art => art.id === element.article_id)
    : null;

  let articleContent = article && article.translations[lang]
    ? article.translations[lang].content
    : '';
  let articleTags = article && article.translations[lang]
    ? article.translations[lang].tags
    : null;

  return (
    <Container maxWidth="sm" className={classes.container}>
      <Typography variant="h2" className={classes.title}>{title}</Typography>
      {(!content || pending)
      && <MTCLoader />}

      {articleTags && <Typography variant="subtitle2" component="p">
        Tags : {articleTags.replaceAll(',', ', ')}
      </Typography>}
      {(content && article)
      && <Typography component="div" variant="body1">
        <div dangerouslySetInnerHTML={{ __html: articleContent }}/>
      </Typography>}
    </Container>
  );
};

export default FaqElementsExplorerArticle;
