import makeStyles from '@material-ui/core/styles/makeStyles';

export const useStyles = makeStyles((theme) => {
  return {
    paper: {
      display: 'flex',
      alignItems: "center",
      justifyItems: "center",
      width: "100%",
      margin: theme.spacing(0.5),
      overflowX: 'auto',
      overflowY: 'hidden',
      padding: '0 15px'
    },
    breadcrumbs: {
      width: '100%',
      flexWrap: "nowrap"
    },
  };
});
