import React from 'react';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import { Editor } from 'react-draft-wysiwyg';
import FormHelperText from '@material-ui/core/FormHelperText';
import { Grid, useTheme } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useStyles } from './MTCRichText.style';
import {
  michelin_blue_dark,
  michelin_blue_light,
  michelin_blue_main,
  michelin_green, michelin_orange,
  michelin_red, michelin_red_orange,
  michelin_text_grey_dark,
  michelin_text_grey_light,
  michelin_white, michelin_yellow_dark,
  michelin_yellow_light,
  michelin_yellow_main,
} from '../../constants/michelin_colors';

const MTCRichText = (props) => {
  const {
    id,
    required,
    gridSM,
    gridMD,
    gridLG,
    errorMessage,
    label,
    handleChange,
    value,
    onBlur
  } = props;
  const { t } = useTranslation();
  const theme = useTheme();
  const classes = useStyles(theme);



  return (
    <Grid item xs={12} sm={gridSM || 12} md={gridMD || 12} lg={gridLG || gridMD || 12}>
      <FormControl
        error={errorMessage !== null}
        required={required}
        className={classes.formControl}
      >
        <InputLabel
          id={`${id}_label`}
          required={required}
          className={classes.formControlInputLabel}
          variant={'filled'}
          disableAnimation
        >
          {label ? t(label) : t(id)}
        </InputLabel>
        <Editor
          id={id}
          key={id}
          onEditorStateChange={newValue => handleChange(id, newValue)}
          editorState={value}
          onBlur={(event, editorState) => {
            if (onBlur !== undefined) {
              onBlur(editorState);
            }
          }}
          toolbar={{
            options: [
              'inline',
              'blockType',
              'fontSize',
              'fontFamily',
              'list',
              'textAlign',
              'colorPicker',
              'link',
              'remove',
              'history'
            ],
            inline: { inDropdown: true },
            list: { inDropdown: true },
            textAlign: { inDropdown: true },
            colorPicker: {
              colors: [
                michelin_blue_light,
                michelin_blue_main,
                michelin_blue_dark,
                michelin_yellow_light,
                michelin_yellow_main,
                michelin_yellow_dark,
                'rgb(255,255,255)',
                michelin_white,
                michelin_text_grey_light,
                michelin_text_grey_dark,
                'rgb(0,0,0)',
                michelin_green,
                michelin_orange,
                michelin_red_orange,
                michelin_red,
              ],
            }
          }}
          toolbarClassName={classes.draftEditorToolbar}
          wrapperClassName={classes.draftEditorRoot}
          editorClassName={classes.draftEditorEditorContainer}
        />
        {errorMessage && <FormHelperText>{errorMessage}</FormHelperText>}
      </FormControl>
    </Grid>
  )
}

export default React.memo(MTCRichText);
