import React, { useState, useEffect } from 'react';
import List from '@material-ui/core/List';
import { useStyles } from './FaqElementsExplorerList.style';
import useTheme from '@material-ui/core/styles/useTheme';
import { useTranslation } from 'react-i18next';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { ARTICLE, DIRECTORY, ROOT } from '../FaqElementType.const';
import FaqElementsExplorerItem from '../FaqElementsExplorerItem/FaqElementsExplorerItem';
import FaqElementsExplorerArticle from '../FaqElementsExplorerArticle/FaqElementsExplorerArticle';
import Typography from '@material-ui/core/Typography';
import { useDispatch } from 'react-redux';
import { putFaqElements } from '../../../../store/faqElements/actions';

const reorder =  (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const FaqElementsExplorerList = ({
                                   elements,
                                   element,
                                   changeCurrent,
                                   handleOpenElementDialog,
                                   handleOpenArticleDialog,
                                   handleOpenExternalLinkDialog,
                                   setOpenExternalLinkDialog,
                                   setOpenRemoveDialog,
                                   setOpenMoveDialog
                                 }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const classes = useStyles(theme);
  const dispatch = useDispatch();
  const [children, setChildren] = useState([]);
  useEffect(() => {
    if (element) {
      setChildren(
        elements.filter(el => el.parent_id === element.id).sort((a, b) => {
          return a.element_order - b.element_order;
        })
      );
    }
  }, [element, elements]);

  const onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const elementToMove = children[result.source.index];

    const items = reorder(
      children,
      result.source.index,
      result.destination.index
    );
    setChildren(items);
    elementToMove.element_order = result.destination.index;
    dispatch(putFaqElements(elementToMove));
  };

  return (
    <div className={classes.list}>
      {element && (
        element.type === ROOT || element.type === DIRECTORY
      ) && children.length > 0
      &&
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable">
          {(provided, snapshot) => (
            <List
              {...provided.droppableProps}
              component="nav"
              ref={provided.innerRef}
            >
              {children.map((c, index) =>
                <Draggable
                  key={`${c.id}`}
                  draggableId={`${c.id}`}
                  index={index}
                >
                  {(provided, snapshot) => (
                    <div>
                      <div
                        ref={provided.innerRef}
                        {...provided.dragHandleProps}
                        {...provided.draggableProps}
                      >
                        <FaqElementsExplorerItem
                          element={c}
                          key={`item-${c.id}`}
                          changeCurrent={changeCurrent}
                          handleOpenElementDialog={handleOpenElementDialog}
                          handleOpenArticleDialog={handleOpenArticleDialog}
                          handleOpenExternalLinkDialog={handleOpenExternalLinkDialog}
                          setOpenExternalLinkDialog={setOpenExternalLinkDialog}
                          setOpenRemoveDialog={setOpenRemoveDialog}
                          setOpenMoveDialog={setOpenMoveDialog}
                        />
                      </div>
                      {provided.placeholder}
                    </div>
                  )}
                </Draggable>
              )}
              {provided.placeholder}
            </List>
          )}
        </Droppable>
      </DragDropContext>
      }
      {element && (
        element.type === ROOT || element.type === DIRECTORY
      ) && children.length === 0
      && <Typography variant="body1">
        {t('empty_folder')}
      </Typography>}
      {element && element.type === ARTICLE
      && <FaqElementsExplorerArticle element={element} />}
    </div>
  );
};

export default FaqElementsExplorerList;
