import React from 'react';
import SwaggerUI from "swagger-ui-react";
import "swagger-ui-react/swagger-ui.css";
import { useParams } from 'react-router';
import Error404 from './Error404';
import { API_ROOT } from '../constants/api';
import { ENV_PROD } from '../constants/env';

const ApiDocPage = () => {
  const { version } = useParams();
  if (process.env.REACT_APP_ENV !== ENV_PROD) {
    switch (version) {
      case 'v4':
        return <SwaggerUI url={API_ROOT + "tr4/api/doc/v4"} />
      case 'v5':
        return <SwaggerUI url={API_ROOT + "tr4/api/doc/v5"} />
      case 'v6':
        return <SwaggerUI url={API_ROOT + "tr4/api/doc/v6"} />
      case 'v7':
        return <SwaggerUI url={API_ROOT + "tr4/api/doc/v7"} />
      case 'portal':
        return <SwaggerUI url={API_ROOT + "tr4/api/doc/portal/v1"} />
      default:
        return <Error404 />;
    }
  } else {
    return <Error404 />;
  }
}

export default ApiDocPage;
