export const columns = [
  {
    id: 'created_at',
    numeric: false,
    disablePadding: false,
  },
  {
    id: 'name',
    numeric: false,
    disablePadding: false,
  },
  {
    id: 'country_code',
    numeric: false,
    disablePadding: false,
  },
  {
    id: 'firstname',
    numeric: false,
    disablePadding: false,
  },
  {
    id: 'lastname',
    numeric: false,
    disablePadding: false,
  },
  {
    id: 'request_state',
    numeric: false,
    disablePadding: false,
  },
  {
    numeric: false,
    disablePadding: false,
  },
];
export const defaultOrderBy = 'created_at';
export const defaultOrder = 'desc';
export const createDataFromRequestAndUser = (requests, users) => {
  let data = [];
  requests.forEach(request => {
    const user = users.find(user => user.id === request.user_id);
    data.push({
      ...request,
      firstname: (
        user && user.firstname
      ) || '',
      lastname: (
        user && user.lastname
      ) || '',
    });
  });
  return data;
};
