import {
  CATALOGS_TAG_DELETED,
  CATALOGS_TAG_DOWNLOADED,
  CATALOGS_TAGS_DOWNLOADED,
  CATALOGS_TAGS_FAILED,
  CATALOGS_TAGS_PENDING,
  CATALOGS_TAGS_SENDING,
} from './types';
import ApiService from '../../services/ApiService';
import { handleError } from '../infos/actions';
import i18n from 'i18next';

export const catalogsTagsPendingAction = () => (
  {
    type: CATALOGS_TAGS_PENDING,
  }
);
export const catalogsTagsSendingAction = () => (
  {
    type: CATALOGS_TAGS_SENDING,
  }
);

export const catalogsTagsFailedAction = (error) => (
  {
    type: CATALOGS_TAGS_FAILED,
    payload: error,
  }
);

export const catalogsTagsDownloadedAction = (tags) => (
  {
    type: CATALOGS_TAGS_DOWNLOADED,
    payload: tags,
  }
);
export const catalogsTagDownloadedAction = (tag) => (
  {
    type: CATALOGS_TAG_DOWNLOADED,
    payload: tag,
  }
);
export const catalogsTagDeleteAction = (id) =>(
  {
    type: CATALOGS_TAG_DELETED,
    payload: id,
  }
);

export function getCatalogsTags() {
  return (dispatch) => {
    dispatch(catalogsTagsPendingAction());
    return ApiService.getCatalogsTags()
      .then(data => {
        dispatch(catalogsTagsDownloadedAction(data));
        return data;
      })
      .catch(error => {
        dispatch(catalogsTagsFailedAction(error));
        dispatch(handleError(error));
      });
  };
}
export function postCatalogsTags(json) {
  return (dispatch) => {
    dispatch(catalogsTagsSendingAction());
    return ApiService.postCatalogsTags(json)
      .then(data => {
        dispatch(catalogsTagDownloadedAction(data));
        return data;
      })
      .catch(error => {
        dispatch(catalogsTagsFailedAction(error));
        dispatch(handleError(error));
      });
  };
}
export function putCatalogsTags(id, json) {
  return (dispatch) => {
    dispatch(catalogsTagsSendingAction());
    return ApiService.putCatalogsTags(id, json)
      .then(data => {
        dispatch(catalogsTagDownloadedAction(data));
        return data;
      })
      .catch(error => {
        dispatch(catalogsTagsFailedAction(error));
        dispatch(handleError(error));
      });
  };
}
export function deleteCatalogsTags(id) {
  return (dispatch) => {
    dispatch(catalogsTagsSendingAction());
    return ApiService.deleteCatalogsTags(id)
      .then(() => {
        dispatch(catalogsTagDeleteAction(id));
      })
      .catch(error => {
        if (
          error.response
          && error.response.data.message == "Can't remove tag because it's use by one or more items"
        ) {
          const txt = i18n.t("error.catalogs_tags_used");
          dispatch(catalogsTagsFailedAction({message: txt}));
          dispatch(handleError({message: txt}));
        } else {
          dispatch(catalogsTagsFailedAction(error));
          dispatch(handleError(error));
        }
      });
  };
}

