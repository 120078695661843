import {
  CALL_CENTER_DELETED,
  CALL_CENTER_DOWNLOADED,
  CALL_CENTER_SENDING,
  CALL_CENTERS_DOWNLOADED,
  CALL_CENTERS_FAILED,
  CALL_CENTERS_PENDING,
  CALL_CENTERS_UPLOADED,
} from './types';

export const INITIAL_STATE = {
  error: null,
  content: null,
  pending: false,
  sending: false,
};

export default function (state = INITIAL_STATE, action) {
  let newContent = [];
  switch (action.type) {
    case CALL_CENTER_DOWNLOADED: {
      let callCenters = [];
      let callCenterIndex = -1;
      if (state.content) {
        callCenters = state.content.slice();
        callCenterIndex = callCenters.findIndex(callCenter => callCenter.id === action.payload.id);
      }
      if (callCenterIndex === -1) {
        callCenters.push(action.payload);
      } else {
        callCenters[callCenterIndex] = action.payload;
      }
      return {
        error: null,
        pending: false,
        sending: false,
        content: callCenters,
      };
    }
    case CALL_CENTER_DELETED: {
      let callCenters = [];
      let callCenterIndex = -1;
      if (state.content) {
        callCenters = state.content.slice();
        callCenterIndex = callCenters.findIndex(callCenter => callCenter.id === action.payload.id);
      }
      if (callCenterIndex !== -1) {
        callCenters.splice(callCenterIndex, 1);
      }
      return {
        error: null,
        pending: false,
        sending: false,
        content: callCenters,
      };
    }
    case CALL_CENTER_SENDING:
      return {
        ...state,
        sending: true,
        error: null,
      };
    case CALL_CENTERS_DOWNLOADED:
      return {
        content: action.payload,
        error: null,
        pending: false,
        sending: false,
      };
    case CALL_CENTERS_UPLOADED:
      if (state.content) {
        newContent = action.payload.concat(state.content)
      } else {
        newContent = action.payload
      }

      return {
        content: newContent,
        error: null,
        pending: false,
        sending: false,
      };
    case CALL_CENTERS_FAILED:
      return {
        ...state,
        error: action.payload,
        pending: false,
        sending: false,
      };
    case CALL_CENTERS_PENDING:
      return {
        ...state,
        pending: true,
        error: null,
      };
    default:
      break;
  }
  return state;
}
