import {
  TIRE_DOWNLOADED,
  TIRE_SENDING,
  TIRES_DOWNLOADED,
  TIRES_FAILED,
  TIRES_PENDING,
} from './types';
import ApiService from '../../services/ApiService';
import { handleError } from '../infos/actions';

export const tireSendingAction = () => ({
  type: TIRE_SENDING,
});

export const tireDownloadedAction = tire => ({
  type: TIRE_DOWNLOADED,
  payload: tire,
});

export const tiresDownloadedAction = tires => ({
  type: TIRES_DOWNLOADED,
  payload: tires,
});

export const tiresFailedAction = error => ({
  type: TIRES_FAILED,
  payload: error,
});

export const tiresPendingAction = () => ({
  type: TIRES_PENDING,
});

export function getTires() {
  return (dispatch) => {
    dispatch(tiresPendingAction());
    return ApiService
      .getTires()
      .then((data) => {
        dispatch(tiresDownloadedAction(data));
      })
      .catch((error) => {
        dispatch(tiresFailedAction(error));
        dispatch(handleError(error));
      });
  };
}

export function postTire(tire) {
  return (dispatch) => {
    dispatch(tireSendingAction());
    return ApiService
      .postTire(tire)
      .then((data) => {
        dispatch(tireDownloadedAction(data));
      })
      .catch((error) => {
        dispatch(tiresFailedAction(error));
        dispatch(handleError(error));
      });
  };
}

export function putTire(tire) {
  return (dispatch) => {
    dispatch(tireSendingAction());
    return ApiService
      .putTire(tire)
      .then((data) => {
        dispatch(tireDownloadedAction(data));
      })
      .catch((error) => {
        dispatch(tiresFailedAction(error));
        dispatch(handleError(error));
      });
  };
}