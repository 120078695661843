import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import CircularProgress from '@material-ui/core/CircularProgress';
import PropTypes from 'prop-types';
import { useStyles } from './MTCDialog.style';

const MTCDialog = props => {

  const {
    open,
    handleClose,
    dialogTitle,
    dialogText,
    handleCloseCancel,
    handleCloseOK,
    cancelText,
    okText,
    okPending,
    textField,
  } = props;

  const classes = useStyles();

  return (
    <Dialog
      open={open}
      keepMounted
      onClose={handleClose}
      aria-labelledby={'alert-dialog-slide-title'}
      aria-describedby={'alert-dialog-slide-description'}
    >
      <DialogTitle id={'alert-dialog-slide-title'}>
        {dialogTitle}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id={'alert-dialog-slide-description'}>
          {dialogText}
        </DialogContentText>
        {textField}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCloseCancel} backgroundcolor={'secondary'} disabled={okPending}>
          {cancelText}
        </Button>
        <div className={classes.wrapper}>
          <Button onClick={handleCloseOK} color={'primary'} disabled={okPending}>
            {okText}
          </Button>
          {okPending && <CircularProgress size={24} className={classes.buttonProgress} />}
        </div>
      </DialogActions>
    </Dialog>
  );
};

MTCDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  dialogTitle: PropTypes.string.isRequired,
  dialogText: PropTypes.string.isRequired,
  handleCloseCancel: PropTypes.func.isRequired,
  handleCloseOK: PropTypes.func.isRequired,
  cancelText: PropTypes.string.isRequired,
  okText: PropTypes.string.isRequired,
  textField: PropTypes.oneOfType([
    PropTypes.string.isRequired,
    PropTypes.object.isRequired,
    PropTypes.node.isRequired
  ]),
  okPending: PropTypes.bool
};

MTCDialog.defaultProps = {
  okPending: false
};

export default React.memo(MTCDialog);
