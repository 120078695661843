import {
  MESSAGE_REQUEST_PENDING,
  MESSAGE_REQUEST_FAILED,
  MESSAGE_REQUEST_DOWNLOADED,
  MESSAGE_REQUEST_RESET
} from './types';

export const INITIAL_STATE = {
  error: null,
  content: null,
  pending: false,
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case MESSAGE_REQUEST_DOWNLOADED:
      return {
        ...state,
        content: action.payload,
        error: null,
        pending: false,
      }
    case MESSAGE_REQUEST_FAILED:
      return {
        ...state,
        error: action.payload,
        pending: false,
      };
    case MESSAGE_REQUEST_PENDING:
      return {
        ...state,
        pending: true,
        error: null,
      };
    case MESSAGE_REQUEST_RESET:
      return {
        ...INITIAL_STATE
      }
    default:
      break;
  }
  return state;
}
