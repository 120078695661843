import {
  RALLY_CAR_DOWNLOADED,
  RALLY_CAR_SENDING,
  RALLY_CARS_DOWNLOADED,
  RALLY_CARS_FAILED,
  RALLY_CARS_PENDING, RALLY_CARS_RESET,
} from './types';
export const INITIAL_STATE = {
  error: null,
  content: null,
  pending: false,
  sending: false,
};
const emptyCars = {
  rallyBrands: [],
  rallyModels: [],
  rallyGroups: [],
  rallyDimensions: [],
  rallyBoundaries: [],
};

/*
This is a old store for rally cars
If le number of rally cars have to increase
this store have to change to something like formCars store
And form have to be change too
 */

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case RALLY_CAR_DOWNLOADED: {
      let rallyCars = state.content ? JSON.parse(JSON.stringify(state.content)) : emptyCars;
      //For each payload keys (with special case of rally_boundaries)
      let rallyCarKeys = Object.keys(action.payload);
      rallyCarKeys = rallyCarKeys.filter(key => key !== 'rally_boundaries');
      let keyIndex = -1;
      rallyCarKeys.forEach(key => {
        const keysForCarsJSON = key + 's';
        const carItemForKey = action.payload[key];
        // Check for dimension == null
        if (carItemForKey != null) {
          //Retrieve matching list
          let itemsWithKey = rallyCars[keysForCarsJSON].slice();
          //FindIndex of current key with id
          keyIndex = itemsWithKey.findIndex(item => item.id === carItemForKey.id);
          if (keyIndex === -1) {
            rallyCars[keysForCarsJSON].push(carItemForKey);
          } else {
            rallyCars[keysForCarsJSON][keyIndex] = carItemForKey;
          }
        }
      });
      // handle rallyBoundaries
      if (action.payload.rally_boundaries) {
        const rallyBoundaries = action.payload.rally_boundaries;
        let rallyBoundaryIndex = -1;
        rallyBoundaries.forEach(rallyBoundary => {
          const currentBoundaries = rallyCars.rally_boundaries.slice();
          rallyBoundaryIndex = currentBoundaries
            .findIndex(currentRallyBoundary => currentRallyBoundary.id === rallyBoundary.id);
          if (rallyBoundaryIndex === -1) {
            rallyCars.rally_boundaries.push(rallyBoundary);
          } else {
            rallyCars.rally_boundaries[rallyBoundaryIndex] = rallyBoundary;
          }
        });
      }
      return {
        error: null,
        pending: false,
        sending: false,
        content: rallyCars,
      };
    }
    case RALLY_CAR_SENDING:
      return {
        ...state,
        sending: true,
        error: null,
      };
    case RALLY_CARS_DOWNLOADED:
      return {
        content: action.payload,
        error: null,
        pending: false,
        sending: false,
      };
    case RALLY_CARS_FAILED:
      return {
        ...state,
        error: action.payload,
        pending: false,
        sending: false,
      };
    case RALLY_CARS_PENDING:
      return {
        ...state,
        pending: true,
        error: null,
      };
    case RALLY_CARS_RESET:
      return {...INITIAL_STATE};
    default:
      break;
  }
  return state;
}
