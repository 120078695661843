import makeStyles from '@material-ui/core/styles/makeStyles';
import { simpleRowStyle } from '../../../../constants/styles/row.style';
import { michelin_text_grey_light } from '../../../../constants/michelin_colors';

export const useStyles = makeStyles(theme => {
  return {
    row: simpleRowStyle(theme),
    button: {
      color: 'inherit',
    },
    unusedRow: {
      ...simpleRowStyle(theme),
      backgroundColor: michelin_text_grey_light + '!important',
    },
    multiComponentCell: {
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
      marginBottom: '-1px',
      height: '56px',
    },
    linkIcon: {
      width: '24px',
      paddingRight: "2px",
      marginRight: "12px"
    },
    chip: {
      marginRight: "5px"
    },
  };
});
