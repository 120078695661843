import makeStyle from '@material-ui/core/styles/makeStyles';

export const useStyles = makeStyle({
  button: {
    borderRadius: '2em',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '5px',
    marginBottom: '5px',
  },
  text: {
    verticalAlign: 'center',
  },
});
