import {
  FAQ_ELEMENT_DOWNLOADED,
  FAQ_ELEMENTS_DOWNLOADED,
  FAQ_ELEMENTS_FAILED,
  FAQ_ELEMENTS_PENDING,
  FAQ_ELEMENTS_SENDING,
  FAQ_ELEMENTS_UPDATED,
  FAQ_ELEMENTS_DELETED
} from './types';

export const INITIAL_STATE = {
  error: null,
  content: null,
  pending: false,
  sending: false,
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case FAQ_ELEMENTS_PENDING:
      return {
        ...state,
        pending: true,
      };
    case FAQ_ELEMENTS_SENDING:
      return {
        ...state,
        sending: true,
      };
    case FAQ_ELEMENTS_DOWNLOADED:
      return {
        ...state,
        pending: false,
        content: action.payload,
        error: null,
      };
    case FAQ_ELEMENT_DOWNLOADED: {
      let newContent = [];
      if (state.content != null) {
        newContent = [...state.content];
      }
      let index = newContent.findIndex(ar => ar.id === action.payload.id);
      if (index === -1) {
        newContent.push(action.payload);
      } else {
        newContent[index] = action.payload;
      }
      return {
        ...state,
        content: newContent,
        sending: false,
        error: null,
      }
    }
    case FAQ_ELEMENTS_UPDATED: {
      let newContent = [];
      if (state.content != null) {
        newContent = [...state.content];
      }
      action.payload.forEach(el => {
        let index = newContent.findIndex(ar => ar.id === el.id);
        if (index === -1) {
          newContent.push(el);
        } else {
          newContent[index] = el;
        }
      });
      return {
        ...state,
        content: newContent,
        sending: false,
        error: null,
      }
    }
    case FAQ_ELEMENTS_DELETED: {
      let newContent = [];
      if (state.content != null) {
        newContent = [...state.content];
      }
      let removeIndex = newContent.findIndex(ar => ar.id === action.payload.deleteId);
      if (removeIndex !== -1) {
        newContent.splice(removeIndex, 1);
      }
      action.payload.updated.forEach(el => {
        let index = newContent.findIndex(ar => ar.id === el.id);
        if (index === -1) {
          newContent.push(el);
        } else {
          newContent[index] = el;
        }
      });
      return {
        ...state,
        content: newContent,
        sending: false,
        error: null,
      }
    }
    case FAQ_ELEMENTS_FAILED:
      return {
        ...state,
        pending: false,
        error: action.payload,
      };
    default:
      break;
  }

  return { ...state };
}
