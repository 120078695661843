import React from 'react';
import PropTypes from 'prop-types';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Tooltip from '@material-ui/core/Tooltip';
import useTheme from '@material-ui/core/styles/useTheme';
import { useTranslation } from 'react-i18next';
import { Typography } from '@material-ui/core';
import { useStyles } from './MTCTableHead.style';

const MTCTableHead = (props) => {

  const {
    order,
    orderBy,
    columnData,
    onRequestSort,
  } = props;

  const theme = useTheme();
  const classes = useStyles(theme);
  const { t } = useTranslation();

  return (
    <TableHead>
      <TableRow className={classes.headerRow}>
        {columnData.map(column => {
          if (column.id) {
            const noSort = column.disableSorting || false;
            return (
              <TableCell
                className={classes.head}
                key={column.id}
                align={column.align
                  ? column.align
                  : column.numeric
                    ? 'right'
                    : 'left'}
                padding={column.disablePadding
                  ? 'none'
                  : 'normal'}
                sortDirection={orderBy === column.id
                  ? order
                  : false}>
                {!noSort && <Tooltip
                  title={t('sort_table')}
                  placement={column.numeric
                    ? 'bottom-end'
                    : 'bottom-start'}
                  enterDelay={300}>
                  <TableSortLabel
                    className={classes.sortLabel}
                    active={orderBy === column.id}
                    direction={order === 'asc'
                      ? 'desc'
                      : 'asc'}
                    onClick={() => onRequestSort(column.id)}>
                    {column.label !== undefined ? t(`${column.label}`) : t(`${column.id}`)}
                  </TableSortLabel>

                </Tooltip>
                }
                {noSort && <Typography
                  variant="body1"
                  className={classes.noSortLabel}
                >
                  {column.label !== undefined ? t(`${column.label}`) : t(`${column.id}`)}
                </Typography>
                }

              </TableCell>
            );
          } else {
            return <TableCell key="empty" />;
          }
        })
        }
      </TableRow>
    </TableHead>
  );
}

MTCTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  columnData: PropTypes.array.isRequired,
  column: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      numeric: PropTypes.bool,
      disablePadding: PropTypes.bool,
    }),
  ),
};

export default React.memo(MTCTableHead);
