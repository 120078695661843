import React, { useState } from 'react';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import Typography from '@material-ui/core/Typography';
import { Fab } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { CAR_ADD } from '../../../constants/routes';
import { getTableCars } from '../../../store/tableCars/actions';
import RaceCarRow from './RaceCarRow';
import RaceCarSearch from '../Search/RaceCarSearch';
import MTCSpinner from '../../Layout/MTCSpinner';
import MTCTableHead from '../../Layout/Table/MTCTableHead';
import MTCTablePagination from '../../Layout/Table/MTCTablePagination';
import { changeLimit } from '../../../store/searchCars/actions';
import { formReset } from '../../../store/formCars/actions';
import CarContextMenu from '../CarContextMenu';
import { useStyles } from './RaceCarsTable.style';
import { columns, CONTEXT_MENU_STATE, getApiOrderBy, INITIAL_STATE } from './RaceCarsTable.const';

const RaceCarsTable = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const classes = useStyles();
  const history = useHistory();
  const [state, setState] = useState(INITIAL_STATE);
  const [contextMenu, setContextMenu] = useState(CONTEXT_MENU_STATE);
  const {
    data,
    count,
    error,
    pending,
  } = useSelector(state => state.tableCars);
  const { dimension } = useSelector(state => state.formCars);
  const { limit } = useSelector(state => state.searchCars);

  if (data === null && !error && !pending) {
    // eslint-disable-next-line max-len
    dispatch(getTableCars(`carState=0&limit=${limit}&page=${state.page}&order=${state.order}&orderBy=${state.orderBy}`));
  }

  if (dimension) {
    dispatch(formReset());
  }

  const handleSearch = (search) => {
    setState({
      ...state,
      page: 0,
      search,
    });
    let params = search + (
      search !== ''
        ? '&'
        : ''
    );
    params = params + `limit=${limit}&page=0&order=${state.order}&orderBy=${state.orderBy}`;
    dispatch(getTableCars(params));
  };

  const handleReset = () => {
    setState({
      ...state,
      search: '',
    });
    // eslint-disable-next-line max-len
    dispatch(getTableCars(`carState=0&limit=${limit}&page=0&order=${state.order}&orderBy=${state.orderBy}`));
  };

  const handleCloseContextMenu = () => {
    if (contextMenu.onCloseCallback) {
      contextMenu.onCloseCallback();
    }
    setContextMenu(CONTEXT_MENU_STATE);
  }

  const handleRequestSort = (newOrderBy, newOrder = 'asc') => {
    if (state.tableOrderBy === newOrderBy && state.tableOrder === newOrder) {
      newOrder = 'desc';
    }
    setState({
      ...state,
      tableOrder: newOrder,
      tableOrderBy: newOrderBy,
      order: newOrder,
      orderBy: getApiOrderBy(newOrderBy),
    });

    let params = state.search + (
      state.search !== ''
        ? '&'
        : ''
    );
    // eslint-disable-next-line max-len
    params = params + `limit=${limit}&page=${state.page}&order=${newOrder}&orderBy=${getApiOrderBy(
      newOrderBy)}`;
    dispatch(getTableCars(params));
  };

  const handleChangePage = (event, page) => {
    setState({
      ...state,
      page,
    });
    let params = state.search + (
      state.search !== ''
        ? '&'
        : ''
    );
    // eslint-disable-next-line max-len
    params = params + `limit=${limit}&page=${page}&order=${state.order}&orderBy=${state.orderBy}`;
    dispatch(getTableCars(params));
  };

  const handleChangeRowsPerPage = (event) => {
    dispatch(changeLimit(event.target.value));
    setState({
      ...state,
      limit: event.target.value,
    });
    let params = state.search + (
      state.search !== ''
        ? '&'
        : ''
    );
    // eslint-disable-next-line max-len
    params = params + `limit=${event.target.value}&page=${state.page}&order=${state.order}&orderBy=${state.orderBy}`;
    dispatch(getTableCars(params));
  };

  const handleAddClick = (event) => {
    event.stopPropagation();
    history.push(CAR_ADD);
  };

  const setMenuAction = (
    event,
    mainAction,
    mainText,
    editAction,
    duplicateAction,
    onCloseCallback
  ) => {
    setContextMenu({
      x: event.clientX,
      y: event.clientY,
      mainAction,
      editAction,
      duplicateAction,
      mainText,
      onCloseCallback
    });
  }

  return <div>
    <RaceCarSearch handleSearch={handleSearch} handleReset={handleReset} />
    {pending && data === null && <MTCSpinner />}
    {data !== null
    && <div className={classes.container}>
      <div className={classes.tableW}>
        <Table>
          <MTCTableHead
            onRequestSort={handleRequestSort}
            order={state.tableOrder}
            orderBy={state.tableOrderBy}
            rowCount={count}
            columnData={columns}
          />
          <TableBody>
            {data.length > 0 &&
            data.map(d => {
              return <RaceCarRow value={d} key={d.dimension_id} setMenuAction={setMenuAction}/>;
            })
            }
            {data.length <= 0 &&
            <tr>
              <td colSpan={columns.length}>
                <Typography variant="subtitle1">
                  {t('no_car')}
                </Typography>
              </td>
            </tr>
            }
          </TableBody>
        </Table>
      </div>
      <MTCTablePagination
        numberOfItems={count}
        rowsPerPage={limit}
        label={t('cars_per_page')}
        page={state.page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </div>}
    <Fab
      className={classes.fab}
      color="secondary"
      onClick={handleAddClick}
    >
      <AddIcon />
    </Fab>
    <CarContextMenu {...contextMenu} handleClose={handleCloseContextMenu}/>
  </div>;
};

export default RaceCarsTable;
