import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import i18next from '../../i18n';
import { CHALLENGE_EDIT, CHALLENGES } from '../../constants/routes';
import { ROLE_ADMIN } from '../../constants/roles';
import { setBack, setTitle, showLangMenu } from '../../store/title/actions';
import { getChallenges } from '../../store/challenges/actions';
import { getTracks } from '../../store/tracks/actions';
import withAuthentication from '../../hoc/withAuthentication';
import MTCLoader from '../../components/Layout/MTCLoader';
import ChallengeDetails from '../../components/Challenges/details/ChallengeDetails';
import AddButton from '../../components/Layout/Button/AddButton';
import Error404 from '../Error404';

export const ChallengePage = () => {
  const {
    content,
    pending,
    error,
  } = useSelector(state => state.challenges);
  const tracks = useSelector(state => state.tracks);
  const {
    title,
    back,
    displayLangMenu,
  } = useSelector(state => state.title);
  const { challengeId } = useParams();
  const dispatch = useDispatch();
  const [challenge, setChallenge] = useState(null);
  const [circuit, setCircuit] = useState(null);
  const lang = i18next.languages[0].split('-')[0];

  if (challenge) {
    if (challenge.obtaining_circuit_id && !tracks.content && !tracks.error && !tracks.pending) {
      dispatch(getTracks());
    } else if (
      challenge.obtaining_circuit_id
      && !circuit
      && !tracks.pending
      && !tracks.error
    ) {
      setCircuit(tracks.content.find(circuit => circuit.id == challenge.obtaining_circuit_id));
    }
  }

  if (!content && !pending && !error) {
    dispatch(getChallenges());
  }

  if (content && challenge === null) {
    const findChallenge = content.find(ch => ch.id == challengeId);
    if (findChallenge) {
      setChallenge(findChallenge)
    } else {
      setChallenge(false);
    }
  }

  if (
    challenge
    && challenge.translations
    && challenge.translations[lang]
    && title !== challenge.translations[lang].name
  ) {
    dispatch(setTitle(challenge.translations[lang].name));
  }
  if (challenge && back !== CHALLENGES) {
    dispatch(setBack(CHALLENGES, { value: challenge.type }));
  }
  if (challenge && !displayLangMenu) {
    dispatch(showLangMenu());
  }
  if (pending || !content) {
    return <MTCLoader />;
  } else if (content && !challenge) {
    return (<Error404 />);
  } else {
    return (
      <>
        <ChallengeDetails challenge={challenge} circuit={circuit} />
        <AddButton
          path={CHALLENGE_EDIT.replace(':challengeId', challengeId)}
          edit={true}
        />
      </>
    );
  }
};
export default withAuthentication(ROLE_ADMIN)(ChallengePage);
