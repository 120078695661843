import makeStyles from '@material-ui/core/styles/makeStyles';
import { inputStyle } from '../../constants/styles/input.style';

export const useStyles = makeStyles(theme => {
  return {
    formControl: {
      ...inputStyle(theme),
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      marginTop: '13px',//'calc(25px - 12px)',
      marginBottom: '13px',//'calc(25px - 12px)',
    },
    formControlLabel: {
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  };
});
