import {
  FAQ_ELEMENT_DOWNLOADED, FAQ_ELEMENTS_DELETED,
  FAQ_ELEMENTS_DOWNLOADED,
  FAQ_ELEMENTS_FAILED,
  FAQ_ELEMENTS_PENDING,
  FAQ_ELEMENTS_SENDING, FAQ_ELEMENTS_UPDATED,
} from './types';
import ApiService from '../../services/ApiService';
import { handleError } from '../infos/actions';


export const faqElementsPendingAction = () => (
  {
    type: FAQ_ELEMENTS_PENDING,
  }
);

export const faqElementsSendingAction = () => (
  {
    type: FAQ_ELEMENTS_SENDING,
  }
);

export const faqElementsDownloadedAction = (elements) => (
  {
    type: FAQ_ELEMENTS_DOWNLOADED,
    payload: elements
  }
);

export const faqElementsUpdatedAction = (elements) => (
  {
    type: FAQ_ELEMENTS_UPDATED,
    payload: elements
  }
);

export const faqElementsDeletedAction = (id, elements) => (
  {
    type: FAQ_ELEMENTS_DELETED,
    payload: {
      deleteId: id,
      updated: elements
    }
  }
);

export const faqElementDownloadedAction = (element) => (
  {
    type: FAQ_ELEMENT_DOWNLOADED,
    payload: element
  }
);

export const faqElementsFailedAction = (error) => (
  {
    type: FAQ_ELEMENTS_FAILED,
    payload: error
  }
);

export function getFaqElements() {
  return (dispatch) => {
    dispatch(faqElementsPendingAction());
    return ApiService.getFaqElements()
      .then((data) => {
        dispatch(faqElementsDownloadedAction(data));
        return data;
      }).catch((error) => {
        dispatch(faqElementsFailedAction(error));
        dispatch(handleError(error));
      });
  };
}

export function postFaqElements(data) {
  return (dispatch) => {
    dispatch(faqElementsSendingAction());
    return ApiService.postFaqElements(data)
      .then((data) => {
        dispatch(faqElementDownloadedAction(data));
        return data;
      }).catch((error) => {
        dispatch(faqElementsFailedAction(error));
        dispatch(handleError(error));
      });
  };
}

export function putFaqElements(data) {
  return (dispatch) => {
    dispatch(faqElementsSendingAction());
    return ApiService.putFaqElements(data.id, data)
      .then((data) => {
        dispatch(faqElementsUpdatedAction(data));
        // caution, PUT return a list
        return data;
      }).catch((error) => {
        dispatch(faqElementsFailedAction(error));
        dispatch(handleError(error));
      });
  };
}

export function deleteFaqElements(id, newParentId) {
  return (dispatch) => {
    dispatch(faqElementsSendingAction());
    return ApiService.deleteFaqElements(id, newParentId)
      .then((data) => {
        dispatch(faqElementsDeletedAction(id, data));
        return data;
      }).catch((error) => {
        dispatch(faqElementsFailedAction(error));
        dispatch(handleError(error));
      });
  }
}
