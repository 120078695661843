export const INITIAL_STATE = {
  search: '',
  page: 0,
  order: 'asc',
  orderBy: 0,
  tableOrder: 'asc',
  tableOrderBy: 'rally_brand_name',
};
export const CONTEXT_MENU_STATE = {
  mainAction: null,
  editAction: null,
  duplicateAction: null,
  mainText: null,
  x: null,
  y: null,
  onCloseCallback: null,
};
export const columns = [
  {
    id: 'rally_brand_name',
    numeric: false,
    disablePadding: false,
  },
  {
    id: 'rally_model_name',
    numeric: false,
    disablePadding: false,
  },
  {
    id: 'rally_group_name',
    numeric: false,
    disablePadding: false,
  },
  {
    id: 'rally_dimension_front_dimension',
    numeric: false,
    disablePadding: false,
  },
  {
    id: 'rally_dimension_is_public',
    numeric: false,
    disablePadding: false,
  },
  {
    numeric: false,
    disablePadding: false,
  },
];

export function getApiOrderBy(column) {
  switch (column) {
    case 'rally_brand_name':
      return 0;
    case 'rally_model_name':
      return 1;
    case 'rally_group_name':
      return 2;
    case 'rally_dimension_front_dimension':
      return 3;
    case 'rally_dimension_is_public':
      return 4;
    default:
      return 0;
  }
}
