import {
  AUTH0_AUDIENCE,
  AUTH0_CLIENTID,
  AUTH0_DOMAIN,
  AUTH0_PROD_CALLBACK_URL,
  ENV_DEV,
  ENV_LOCAL,
} from '../../constants/env';
import { CALLBACK } from '../../constants/routes';

let callbackUrl = AUTH0_PROD_CALLBACK_URL;// prod
if (process.env.REACT_APP_ENV === ENV_DEV) {
  callbackUrl = 'https://michelin-trackconnect-front-dev.openium.fr'; // dev
} else if (process.env.REACT_APP_ENV === ENV_LOCAL) {
  callbackUrl = 'http://localhost:3000';// 'http://127.0.0.1:3000'; // local
}

// CHANGE : auth0 configuration
const AUTH_CONFIG = {
  domain: AUTH0_DOMAIN,
  audience: AUTH0_AUDIENCE,
  clientId: AUTH0_CLIENTID,
  callbackUrl: callbackUrl + CALLBACK,
};

export default AUTH_CONFIG;
