import makeStyles from '@material-ui/core/styles/makeStyles';
import {
  michelin_dusk_blue,
  michelin_text_grey_extra_light
} from '../../../constants/michelin_colors';

export const useStyles = makeStyles((theme) => {
  return {
    title: {
      fontSize: "24px",
      fontWeight: 600,
      marginTop: '0.7em',
      marginBottom: '0.5em',
    },
    row: {
      height: '30px',
      '&:nth-of-type(2n)': {
        backgroundColor: michelin_dusk_blue,
        color: theme.palette.secondary.contrastText,
      },
    },
    head: {
      height: '40px',
      borderBottom: `2px solid ${michelin_text_grey_extra_light}`,
    },
    cell: {
      border: 'none',
      height: '30px'
    },
    tableHeadCell: {
      height: '40px',
    },
    tableHeadLabel: {
      fontWeight: '500',
    }
  }
});
