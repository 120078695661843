import React from 'react';
import PropTypes from 'prop-types';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import makeStyles from '@material-ui/core/styles/makeStyles';
import useTheme from '@material-ui/core/styles/useTheme';
import Typography from '@material-ui/core/Typography';
import { simpleRowStyle } from '../../constants/styles/row.style';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({ row: simpleRowStyle(theme)}));

const AdminRow = ({ value }) => {

  const { t } = useTranslation();
  const theme = useTheme();
  const classes = useStyles(theme);

  return (
    <TableRow
      className={classes.row}
      key={value.id}
      tabIndex={-1}
    >
      <TableCell>
        <Typography>
          {value.lastname}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography>
          {value.firstname}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography>
          {value.email}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography>
          {t('roles.' + value.roles[0].split('_')[1].toLowerCase())}
        </Typography>
      </TableCell>
    </TableRow>
  );
}

AdminRow.propTypes = {
  value: PropTypes.object.isRequired,
};

export default React.memo(AdminRow);
