import React from 'react';
import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { resetInfo } from '../../../store/infos/actions';

const InfoSnackbar = () => {

  const { info } = useSelector(state => state.info);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const handleReset = () => {
    dispatch(resetInfo());
  };

  return (
    <Snackbar
      open={info !== null}
      message={info || ''}
      autoHideDuration={4000}
      onClose={handleReset}
      action={[
        <Button
          id="info"
          key="ok"
          color="secondary"
          size="small"
          onClick={handleReset}
        >
          {t('snackbar_ok')}
        </Button>,
      ]}
    />
  );
};

export default React.memo(InfoSnackbar);
