import React, { useState } from 'react';
import { useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import withAuthentication from '../../hoc/withAuthentication';
import { ROLE_ADMIN, ROLE_SUPPORT } from '../../constants/roles';
import { setBack, setTitle, showLangMenu } from '../../store/title/actions';
import { TRACK_EDIT, TRACKS } from '../../constants/routes';
import MTCLoader from '../../components/Layout/MTCLoader';
import { getTracks } from '../../store/tracks/actions';
import AddButton from '../../components/Layout/Button/AddButton';
import TrackDetails from '../../components/Tracks/TrackDetails/TrackDetails';
import Error404 from '../Error404';

const TrackPage = () => {

  const {
    title,
    back,
    displayLangMenu,
  } = useSelector(state => state.title);
  const {
    content,
    pending,
    error,
  } = useSelector(state => state.tracks);
  const { trackId } = useParams();
  const dispatch = useDispatch();
  const [circuit, setCircuit] = useState(null);
  const role = localStorage.getItem('role');

  const { lang } = useSelector(state => state.contentLang);
  if (
    circuit
    && circuit.translations
    && circuit.translations[lang]
    && title !== circuit.translations[lang].name
  ) {
    dispatch(setTitle(circuit.translations[lang].name));
  }

  if (!content && !pending && !error) {
    dispatch(getTracks());
  }

  if (content && (
    circuit === null || (
      circuit && circuit.id != trackId
    )
  )) {
    const result = content.find(tr => tr.id == trackId);
    if (result) {
      setCircuit(result);
    } else {
      setCircuit(false);
    }
  }
  if (circuit && back !== TRACKS) {
    dispatch(setBack(TRACKS, {}));
  }
  if (circuit && !displayLangMenu) {
    dispatch(showLangMenu());
  }

  if (pending) {
    return <MTCLoader />;
  } else if (content && circuit) {
    return (
      <>
        <TrackDetails circuit={circuit} />
        {role === ROLE_ADMIN && <AddButton
          path={TRACK_EDIT.replace(':trackId', trackId)}
          edit={true}
        />}
      </>
    );
  } else {
    return <Error404 />;
  }
};

export default withAuthentication(ROLE_SUPPORT)(TrackPage);
