export const MTCBLE = 'MTCBLE';
export const MTCRF = 'MTCRF';
export const MMCBLE = 'MMCBLE';
export const MMCRF = 'MMCRF';
export const ALL_FIRMWARE_TYPES = [
  MTCBLE,
  MTCRF,
  MMCBLE,
  MMCRF,
];
