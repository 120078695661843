/*
 * 0: Classic
 * 1: Temporary
 */
export const TYPES = [
  0, 1
];

/*
    0: Faire un run
    1: Ajouter une voiture
    2: Terminer le tutoriel
    3: Faire un run sur un circuit
    4: Faire partie d'une équipe
    5: Faire un nouveau record
    6: Partager un run
    7: Équiper 4 pneus Michelin
    8: Inviter à rejoindre son team
    9: rouler X km
    10: faire un run avec un taux de x% dans la zone verte
    11: Parrainage terminé par un run en mode expert (circuit expert + rallye)
    12: Parrainage terminé par un run en mode loisir (circuit loisir uniquement)
    13: Ajout de voiture appartenant à des marques spécifiques
 */
export const OBTAINING_TYPES = [
  0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13
]
