export const INITIAL_STATE = {
  brand: '',
  range: '',
  model: '',
  engine: '',
  dimension: '',
  mode: '',
  tire_type: '',
  is_public: '',
  car_state: '0',
};
