const DEFAULT_CONTACTS_SENDING = 'default_contacts_sending';
const DEFAULT_CONTACTS_DOWNLOADED = 'default_contacts_downloaded';
const DEFAULT_CONTACTS_FAILED = 'default_contacts_failed';
const DEFAULT_CONTACTS_PENDING = 'default_contacts_pending';

export {
  DEFAULT_CONTACTS_SENDING,
  DEFAULT_CONTACTS_DOWNLOADED,
  DEFAULT_CONTACTS_FAILED,
  DEFAULT_CONTACTS_PENDING
};
