import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import Grid from '@material-ui/core/Grid';
import MTCButton from '../Layout/Button/MTCButton';
import MTCTextField from '../Form/MTCTextField';
import { postContacts, putContacts } from '../../store/contacts/actions';
import { CONTACTS } from '../../constants/routes';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import Input from '@material-ui/core/Input';
import Chip from '@material-ui/core/Chip';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import { useStyles } from './ContactForm.style';
import { ERROR_STATE, INITIAL_STATE } from './ContactForm.const';

const countries = require('country-list')();

const ContactForm = (props) => {
  const { type, contact } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const [state, setState] = useState({
    ...INITIAL_STATE,
    type,
  });
  const [errors, setErrors] = useState(ERROR_STATE);

  const withCountryCodes = type === 'message';
  const isEditing = contact !== undefined;
  const availableCountryCodes = countries.getData().sort();

  if (isEditing && state.id !== contact.id) {
    if (withCountryCodes) {
      setState({
        ...state, ...contact,
        countryCodes: contact.country_codes.split(','),
      });
    } else {
      setState({ ...state, ...contact });
    }
  }

  const onSubmit = () => {
    let newErrors = { ...errors };
    let errorCount = 0;
    if (state.email.length < 1 || state.email.length > 320) {
      newErrors.emailError = t('error.email_length_error');
      errorCount++;
    }
    if (withCountryCodes && state.countryCodes.length < 1) {
      newErrors.countryCodesError = t('error.country_codes_error');
      errorCount++;
    }

    setErrors(newErrors);
    if (errorCount === 0) {
      let data = { ...state };
      if (withCountryCodes) {
        data.country_codes = data.countryCodes.join(',');
      }
      let tab = -1;
      switch (type) {
        case 'message':
          tab = 0;
          break;
        case 'circuit':
          tab = 1;
          break;
        case 'car':
          tab = 2;
          break;
        case 'rally-car':
          tab = 3;
          break;
        default:
          tab = 0;
          break;
      }

      if (isEditing) {
        dispatch(putContacts(data)).then(() => {
          history.push({
            pathname: CONTACTS,
            state: {
              value: tab,
            },
          });
        });
      } else {
        dispatch(postContacts(data)).then(() => {
          history.push({
            pathname: CONTACTS,
            state: {
              value: tab,
            },
          });
        });
      }
    }
  };

  const onChangeLanguages = (event) => {
    let newState = { ...state };
    newState.countryCodes = event.target.value;
    setState(newState);
    setErrors({
      ...errors,
      countryCodesError: null,
    });
  };

  const onChangeEmail = (event) => {
    let newState = { ...state };
    newState.email = event.target.value;
    setState(newState);
  };

  return (
    <form onSubmit={onSubmit}>
      <Grid container align="center" spacing={2}>
        {withCountryCodes && <Grid item xs={12} className={classes.grid}>

          <FormControl
            error={errors.countryCodesError !== undefined && errors.countryCodesError !== null}
          >
            <InputLabel id="countryCodes-label">{t('country_codes')} *</InputLabel>
            <Select
              className={classes.select}
              labelId="countryCodes-label"
              id="countryCodes"
              multiple
              value={state.countryCodes}
              onChange={onChangeLanguages}
              input={<Input id="select_countryCodes" />}
              renderValue={(selected) => (
                <div className={classes.chips}>
                  {selected.map((value) => (
                    <Chip key={value} label={value} />
                  ))}
                </div>
              )}
              MenuProps={{
                className: classes.menu,
              }}
              SelectProps={{
                MenuProps: {
                  className: classes.menu,
                },
              }}
            >
              {availableCountryCodes.map(country => (
                <MenuItem key={country.code} value={country.code}>
                  {country.code} - {country.name}
                </MenuItem>
              ))}
            </Select>
            {errors.countryCodesError &&
            <FormHelperText>{errors.countryCodesError}</FormHelperText>}
          </FormControl>
        </Grid>}
        <Grid item xs={12} className={classes.grid}>
          <MTCTextField
            id={'email'}
            onChange={onChangeEmail}
            value={state.email}
            required={true}
            errorMessage={errors.emailError}
          />
        </Grid>
        <Grid item xs={12} className={classes.grid}>
          <MTCButton
            text={isEditing
              ? t('edit')
              : t('create')}
            type={'submit'}
          />
        </Grid>
      </Grid>
    </form>
  );
};

export default ContactForm;
