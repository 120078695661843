import makeStyles from '@material-ui/core/styles/makeStyles';
import { clickableRowStyle } from '../../constants/styles/row.style';

export const useStyles = makeStyles(theme => {
  return {
    row: clickableRowStyle(theme),
    row_disabled: {
      cursor: 'pointer',
      backgroundColor: theme.palette.text.disabled,
    },
    button: {
      color: 'inherit',
    },
  };
});
