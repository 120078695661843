export const INITIAL_STATE = {
  search: '',
  page: 0,
  order: 'asc',
  orderBy: 0,
  tableOrder: 'asc',
  tableOrderBy: 'brand_name',
};
export const CONTEXT_MENU_STATE = {
  mainAction: null,
  editAction: null,
  duplicateAction: null,
  mainText: null,
  x: null,
  y: null,
  onCloseCallback: null,
};
export const columns = [
  {
    id: 'brand_name',
    numeric: false,
    disablePadding: false,
  },
  {
    id: 'range_name',
    numeric: false,
    disablePadding: false,
  },
  {
    id: 'model_name',
    numeric: false,
    disablePadding: false,
  },
  {
    id: 'engine_name',
    numeric: false,
    disablePadding: false,
  },
  {
    id: 'dimension_front_dimension',
    numeric: false,
    disablePadding: false,
  },
  {
    id: 'dimension_compatible_with_receiver',
    numeric: false,
    disablePadding: false,
  },
  {
    id: 'dimension_is_public',
    numeric: false,
    disablePadding: false,
  },
  {
    numeric: false,
    disablePadding: false,
  },
];

export function getApiOrderBy(column) {
  switch (column) {
    case 'brand_name':
      return 0;
    case 'range_name':
      return 1;
    case 'model_name':
      return 2;
    case 'engine_name':
      return 3;
    case 'dimension_front_dimension':
      return 4;
    case 'dimension_is_public':
      return 6;
    case 'dimension_compatible_with_receiver':
      return 5;
    default:
      return 0;
  }
}
