export const columns = [
  {
    id: 'name',
    numeric: false,
    disablePadding: false,
  },
  {
    id: '',
    numeric: false,
    disablePadding: false,
  },
];
export const defaultOrderBy = 'name';
