const CALL_CENTERS_PENDING = 'call_centers_pending';
const CALL_CENTERS_FAILED = 'call_centers_failed';
const CALL_CENTERS_DOWNLOADED = 'call_centers_downloaded';
const CALL_CENTERS_UPLOADED = 'call_centers_uploaded';
const CALL_CENTER_SENDING = 'call_center_sending';
const CALL_CENTER_DOWNLOADED = 'call_center_downloaded';
const CALL_CENTER_DELETED = 'call_center_deleted';

export {
  CALL_CENTERS_PENDING,
  CALL_CENTERS_FAILED,
  CALL_CENTERS_DOWNLOADED,
  CALL_CENTER_SENDING,
  CALL_CENTER_DOWNLOADED,
  CALL_CENTER_DELETED,
  CALL_CENTERS_UPLOADED
};
