const DEFAULT_CALL_CENTER_PENDING = 'default_call_center_pending';
const DEFAULT_CALL_CENTER_FAILED = 'default_call_center_failed';
const DEFAULT_CALL_CENTER_DOWNLOADED = 'default_call_center_downloaded';
const DEFAULT_CALL_CENTER_SENDING = 'default_call_center_sending';

export {
  DEFAULT_CALL_CENTER_PENDING,
  DEFAULT_CALL_CENTER_FAILED,
  DEFAULT_CALL_CENTER_DOWNLOADED,
  DEFAULT_CALL_CENTER_SENDING
};
