const RECEIVERS_MTC_BLE_PENDING = 'receivers_mtc_ble_pending';
const RECEIVER_MTC_BLE_FAILED = 'receiver_mtc_ble_failed';
const RECEIVER_MTC_BLE_DOWNLOADED = 'receiver_mtc_ble_downloaded';
const RECEIVER_MTC_BLE_SENDING = 'receiver_mtc_ble_sending';
const RECEIVERS_MTC_BLE_DOWNLOADED = 'receivers_mtc_ble_downloaded';
const RECEIVERS_MTC_BLE_DELETED = 'receivers_mtc_ble_deleted';

export {
  RECEIVERS_MTC_BLE_PENDING,
  RECEIVER_MTC_BLE_FAILED,
  RECEIVER_MTC_BLE_DOWNLOADED,
  RECEIVER_MTC_BLE_SENDING,
  RECEIVERS_MTC_BLE_DOWNLOADED,
  RECEIVERS_MTC_BLE_DELETED
};
