import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import MTCButton from '../Layout/Button/MTCButton';
import MTCTextField from '../Form/MTCTextField';
import { tracksFilteredAction, tracksFilteredResetAction } from '../../store/tracks/actions';
import { useStyles } from './TrackSearch.style';

const TrackSearch = () => {
  const classes = useStyles();
  const { filter } = useSelector(state => state.tracks);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [state, setState] = useState({
    name: '',
    loaded: false,
  });

  if (filter !== null && filter !== '' && state.name === '' && !state.loaded) {
    // if first load, try to set filter to redux state filter
    setState({
      ...state,
      name: filter,
      loaded: true
    });
  } else if (!state.loaded) {
    // if first load and no defined filter,
    // stop trying to set filter to redux state filter
    setState({
      ...state,
      loaded: true
    });
  }

  const onNameChange = (event) => {
    setState({
      ...state,
      name: event.target.value,
    });
    event.stopPropagation();
  };

  const handleClickReset = (event) => {
    dispatch(tracksFilteredResetAction());
    setState({
      ...state,
      name: '',
    });
    event.stopPropagation();
  };

  const handleClickSearch = (event) => {
    dispatch(tracksFilteredAction(state.name));
    event.stopPropagation();
  };

  return (
    <Paper className={classes.paper}>
      <Grid
        container
        spacing={2}
        justifyContent="flex-start"
        alignItems="stretch"
        className={classes.form}
      >
        <Grid item>
          <MTCTextField
            id={'name'}
            onChange={onNameChange}
            value={state.name}
          />
        </Grid>
      </Grid>
      <Grid container justifyContent={'center'} alignItems={'center'} spacing={2}>
        <Grid item>
          <MTCButton
            id={'search_button'}
            text={t('search')}
            onClick={handleClickSearch}
            style={{ width: 200 }}
          />
        </Grid>
        <Grid item>
          <MTCButton
            id={'reset_button'}
            text={t('reset')}
            onClick={handleClickReset}
            color={'primary'}
            style={{ width: 200 }}
          />
        </Grid>
      </Grid>
    </Paper>
  );
};

export default TrackSearch;
