import makeStyles from '@material-ui/core/styles/makeStyles';

export const useStyles = makeStyles(theme => {
  return {
    toolbar: {
      backgroundColor: theme.palette.primary.main,
    },
    closeButton: {
      marginLeft: -12,
      marginRight: 20,
      color: theme.palette.primary.contrastText,
      position: 'absolute',
    },
    logo: {
      height: '5em',
      margin: '0 auto',
    },
    logoContainer: {
      height: '5.5em',
      textAlign: 'center',
      width: '100%',
      paddingTop: '0.25em',
    },
  };
});
