import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import {
  getDefaultCallCenters,
  putDefaultCallCenter,
} from '../../store/defaultCallCenters/actions';
import { dispatchInfo } from '../../store/infos/actions';
import MTCButton from '../Layout/Button/MTCButton';
import MTCTextField from '../Form/MTCTextField';
import Typography from '@material-ui/core/Typography';
import { ERROR_STATE, INITIAL_STATE } from './DefaultCallCenterForm.const';
import { useStyles } from './DefaultCallCenterForm.style';

const DefaultCallCenterForm = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { pending, content, sending, error } = useSelector(state => state.defaultCallCenters);
  const dispatch = useDispatch();
  const [state, setState] = useState(INITIAL_STATE);
  const [errors, setErrors] = useState(ERROR_STATE);
  if(!content && !pending && !error) {
    dispatch(getDefaultCallCenters());
  } else if (content && !pending && state.set === false) {
    setState({...INITIAL_STATE, ...content, set: true});
  }
  const onSubmitEdit = () => {
    if (state.phone.length < 1 || state.phone.length > 30) {
      setErrors({phoneError: t('error.phone_error')})
    } else {
      dispatch(putDefaultCallCenter(state)).then(() => {
          dispatch(dispatchInfo(t('success')));
        },
      );
    }
  };

  const onChangeOpening = (event) => {
    let newState = { ...state };
    newState.opening = event.target.value;
    setState(newState);
  };

  const onChangePhone = (event) => {
    if (event.target.value.length < 31) {
      let newState = { ...state };
      newState.phone = event.target.value;
      setState(newState);
    }
    setErrors({phoneError: null});
  };

  return (
    <form className={classes.form}>
      <Typography
        gutterBottom
        align="center"
        variant="h5"
        component="h2"
      >
        {t('default_call_center_subtitle')}
      </Typography>
      <Grid container align="center" spacing={2}>
        <Grid item xs={12} sm={6} lg={4} className={classes.grid}>
          <MTCTextField
            id={'phone'}
            onChange={onChangePhone}
            value={state.phone}
            required={true}
            errorMessage={errors.phoneError}
          />
        </Grid>
        <Grid item xs={12} sm={6} lg={4} className={classes.grid}>
          <TextField
            className={classes.textField}
            id="opening"
            onChange={onChangeOpening}
            InputProps={{
              className: classes.input,
            }}
            label={t('opening')}
            placeholder={t('opening')}
            SelectProps={{
              MenuProps: {
                className: classes.menu,
              },
            }}
            value={state.opening}
            select
            required={true}
            InputLabelProps={{
              className: classes.selectLabel,
            }}
          >
            {[0, 1, 2].map(value => (
              <MenuItem key={value} value={value}>
                {t('opening-' + value)}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={12} lg={4} className={classes.grid}>
          <MTCButton
            text={t('edit')}
            type={'button'}
            onClick={onSubmitEdit}
            disabled={sending||pending}
          />
        </Grid>
      </Grid>
    </form>
  );
};

export default DefaultCallCenterForm;
