import makeStyles from '@material-ui/core/styles/makeStyles';
import { clickableRowStyle } from '../../../constants/styles/row.style';
import { michelin_blue_main } from '../../../constants/michelin_colors';

export const useStyles = makeStyles(theme => {
  return {
    row: clickableRowStyle(theme),
    row_disabled: {
      cursor: 'pointer',
      backgroundColor: theme.palette.text.disabled,
    },
    button: {
      color: 'inherit',
    },
    multiComponentCell: {
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
      marginBottom: '-1px',
      height: '56px',
    },
    avatar: {
      margin: '5px',
      padding: '5px',
      backgroundColor: michelin_blue_main,
    },
  };
});
