import React from 'react';
import MTCTable from '../../Layout/Table/MTCTable';
import { columns, defaultOrderBy } from './AuthorizedExportTable.const';
import AuthorizedExportRow from './AuthorizedExportRow';
import { useTranslation } from 'react-i18next';

const AuthorizedExportTable = ({values}) => {
  const { t } = useTranslation(['support']);
  return (<MTCTable
    content={values}
    columns={columns}
    defaultOrderBy={defaultOrderBy}
    rowComponent={AuthorizedExportRow}
    paginationLabel={t('table.item_per_page')}
    emptyDataLabel={t('table.no_item')}
  />)
}

export default AuthorizedExportTable;
