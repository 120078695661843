import makeStyles from '@material-ui/core/styles/makeStyles';
import { simpleRowStyle } from '../../../constants/styles/row.style';

export const useStyles = makeStyles(theme => (
  {
    row: simpleRowStyle(theme),
    button: {
      color: 'inherit',
    },
  }
));
