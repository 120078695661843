import React from 'react';
import PropTypes from 'prop-types';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import Divider from '@material-ui/core/Divider';
import { useTheme } from '@material-ui/core';
import MenuToolbar from '../MenuToolbar';
import MenuItems from '../MenuItems';
import { useStyles } from './Menu.style';

export const Menu = (props) => {

  const { auth, drawerOpen, handleCloseDrawer } = props;
  const theme = useTheme();
  const classes = useStyles(theme);

  return (
    <div className={classes.menu}>
      <Hidden mdUp>
        <Drawer
          variant="temporary"
          open={drawerOpen}
          onClose={handleCloseDrawer}
          classes={{
            paper: classes.drawerPaper,
          }}
          className={classes.root}
          ModalProps={{ keepMounted: true }}
        >
          <MenuToolbar handleCloseDrawer={handleCloseDrawer}/>
          <Divider/>
          <MenuItems
            login={auth.login}
            handleCloseDrawer={handleCloseDrawer}
          />
        </Drawer>
      </Hidden>
      <Hidden smDown>
        <Drawer
          variant="permanent"
          open
          classes={{
            paper: classes.drawerPaper,
          }}
          className={classes.root}
        >
          <MenuToolbar handleCloseDrawer={handleCloseDrawer}/>
          <Divider/>
          <MenuItems login={auth.login} handleCloseDrawer={() => {}} />
        </Drawer>
      </Hidden>
    </div>
  );
}

Menu.propTypes = {
  drawerOpen: PropTypes.bool,
  handleCloseDrawer: PropTypes.func,
};

export default Menu;
