import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import TrackRow from './TrackRow';
import MTCTable from '../Layout/Table/MTCTable';
import { getTracks } from '../../store/tracks/actions';
import MTCLoader from '../Layout/MTCLoader';
import { columns, defaultOrderBy } from './TracksTable.const';

const TracksTable = () => {

  const {
    pending,
    content,
    filteredContent,
    error,
  } = useSelector(state => state.tracks);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  if (!content && !pending && !error) {
    dispatch(getTracks());
  }

  if (pending) {
    return <MTCLoader />;
  } else {
    return <MTCTable
      content={filteredContent === null
        ? content
        : filteredContent}
      defaultOrderBy={defaultOrderBy}
      rowComponent={TrackRow}
      columns={columns}
      paginationLabel={t('tracks_per_page')}
      emptyDataLabel={t('no_track')}
    />;
  }
};

export default TracksTable;
