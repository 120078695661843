import {
  TABLE_RALLY_CARS_BRAND_DOWNLOADED,
  TABLE_RALLY_CARS_DIMENSION_DOWNLOADED,
  TABLE_RALLY_CARS_DOWNLOADED,
  TABLE_RALLY_CARS_FAILED,
  TABLE_RALLY_CARS_GROUP_DOWNLOADED,
  TABLE_RALLY_CARS_MODEL_DOWNLOADED,
  TABLE_RALLY_CARS_PENDING,
  TABLE_RALLY_CARS_RESET,
} from './types';
import ApiService from '../../services/ApiService';
import { handleError } from '../infos/actions';

export const tableRallyCarsDownloadedAction = cars => (
  {
    type: TABLE_RALLY_CARS_DOWNLOADED,
    payload: cars,
  }
);

export const tableRallyCarsFailedAction = error => (
  {
    type: TABLE_RALLY_CARS_FAILED,
    payload: error,
  }
);

export const tableRallyCarsBrandDownloadedAction = brand => (
  {
    type: TABLE_RALLY_CARS_BRAND_DOWNLOADED,
    payload: brand,
  }
);

export const tableRallyCarsModelDownloadedAction = model => (
  {
    type: TABLE_RALLY_CARS_MODEL_DOWNLOADED,
    payload: model,
  }
);

export const tableRallyCarsGroupDownloadedAction = group => (
  {
    type: TABLE_RALLY_CARS_GROUP_DOWNLOADED,
    payload: group,
  }
);

export const tableRallyCarsDimensionDownloadedAction = dim => (
  {
    type: TABLE_RALLY_CARS_DIMENSION_DOWNLOADED,
    payload: dim,
  }
);

export const tableRallyCarsPendingAction = () => (
  {
    type: TABLE_RALLY_CARS_PENDING,
  }
);

export const tableRallyCarsResetAction = () => (
  {
    type: TABLE_RALLY_CARS_RESET,
  }
);

export function getTableRallyCars(params) {
  return (dispatch) => {
    dispatch(tableRallyCarsPendingAction());
    return ApiService
      .getTableRallyCars(params)
      .then((data) => {
        dispatch(tableRallyCarsDownloadedAction(data));
      })
      .catch((error) => {
        dispatch(tableRallyCarsFailedAction(error));
        dispatch(handleError(error));
      });
  };
}

export function editRallyCarsBrand(brandId, data) {
  return (dispatch) => {
    dispatch(tableRallyCarsPendingAction());
    return ApiService
      .putRallyCarsBrandState(brandId, data)
      .then((data) => {
        dispatch(tableRallyCarsBrandDownloadedAction(data));
      })
      .catch((error) => {
        dispatch(tableRallyCarsFailedAction(error));
        dispatch(handleError(error));
      });
  };
}

export function editRallyCarsModel(brandId, modelId, data) {
  return (dispatch) => {
    dispatch(tableRallyCarsPendingAction());
    return ApiService
      .putRallyCarsModelState(brandId, modelId, data)
      .then((data) => {
        dispatch(tableRallyCarsModelDownloadedAction(data));
      })
      .catch((error) => {
        dispatch(tableRallyCarsFailedAction(error));
        dispatch(handleError(error));
      });
  };
}

export function editRallyCarsGroup(brandId, modelId, groupId, data) {
  return (dispatch) => {
    dispatch(tableRallyCarsPendingAction());
    return ApiService
      .putRallyCarsGroupState(brandId, modelId, groupId, data)
      .then((data) => {
        dispatch(tableRallyCarsGroupDownloadedAction(data));
      })
      .catch((error) => {
        dispatch(tableRallyCarsFailedAction(error));
        dispatch(handleError(error));
      });
  };
}

export function editRallyCarsDimension(brandId, modelId, groupId, dimensionId, data) {
  return (dispatch) => {
    dispatch(tableRallyCarsPendingAction());
    return ApiService
      .putRallyCarsDimensionState(brandId, modelId, groupId, dimensionId, data)
      .then((data) => {
        dispatch(tableRallyCarsDimensionDownloadedAction(data));
      })
      .catch((error) => {
        dispatch(tableRallyCarsFailedAction(error));
        dispatch(handleError(error));
      });
  };
}

export function resetTableRallyCars() {
  return (dispatch) => {
    dispatch(tableRallyCarsResetAction());
    return true;
  };
}
