import {
  REQUEST_CAR,
  REQUEST_CAR_DOWNLOADED,
  REQUEST_MESSAGE,
  REQUEST_MESSAGE_DOWNLOADED,
  REQUEST_RALLY_CAR,
  REQUEST_RALLY_CAR_DOWNLOADED,
  REQUEST_SENDING,
  REQUEST_TRACK,
  REQUEST_TRACK_DOWNLOADED,
  REQUESTS_DOWNLOADED,
  REQUESTS_FAILED,
  REQUESTS_PENDING,
} from './types';
import ApiService from '../../services/ApiService';
import { handleError } from '../infos/actions';

export const requestSendingAction = () => ({
  type: REQUEST_SENDING,
});

export const requestMessageDownloadedAction = request => ({
  type: REQUEST_MESSAGE_DOWNLOADED,
  payload: request,
});

export const requestTrackDownloadedAction = request => ({
  type: REQUEST_TRACK_DOWNLOADED,
  payload: request,
});

export const requestCarDownloadedAction = request => ({
  type: REQUEST_CAR_DOWNLOADED,
  payload: request,
});

export const requestRallyCarDownloadedAction = request => ({
  type: REQUEST_RALLY_CAR_DOWNLOADED,
  payload: request,
});

export const requestsDownloadedAction = tracks => ({
  type: REQUESTS_DOWNLOADED,
  payload: tracks,
});

export const requestsFailedAction = error => ({
  type: REQUESTS_FAILED,
  payload: error,
});

export const requestsPendingAction = () => ({
  type: REQUESTS_PENDING,
});

export function getRequests() {
  return (dispatch) => {
    dispatch(requestsPendingAction());
    return ApiService
      .getRequests()
      .then((data) => {
        dispatch(requestsDownloadedAction(data));
      })
      .catch((error) => {
        dispatch(requestsFailedAction(error));
        dispatch(handleError(error));
      });
  };
}

export function putRequest(request, type) {
  return (dispatch) => {
    dispatch(requestSendingAction());
    return ApiService
      .putRequest(request, type)
      .then((data) => {
        switch (type) {
          case REQUEST_MESSAGE:
            dispatch(requestMessageDownloadedAction(data));
            break;
          case REQUEST_TRACK:
            dispatch(requestTrackDownloadedAction(data));
            break;
          case REQUEST_CAR:
            dispatch(requestCarDownloadedAction(data));
            break;
          case REQUEST_RALLY_CAR:
            dispatch(requestRallyCarDownloadedAction(data));
            break;
          default:
            dispatch(requestsFailedAction('Wrong Request Type'));
            dispatch(handleError('Wrong Request Type'));
            break;
        }
      })
      .catch((error) => {
        dispatch(requestsFailedAction(error));
        dispatch(handleError(error));
      });
  };
}
