import React from 'react';
import { useTheme } from '@material-ui/core';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import Paper from '@material-ui/core/Paper';
import FaqElementBreadcrumbsItem from './FaqElementBreadcrumbsItem';
import { useStyles } from './FaqElementBreadcrumbs.style';

const FaqElementBreadcrumbs = ({elements, element, changeCurrent}) => {
  const theme= useTheme();
  const classes = useStyles(theme);
  let parents = [];
  let currentParentId = element
    ? element.parent_id
    : null;
  while (currentParentId != null) {
    const parent = elements.find(element => element.id === currentParentId);
    if (parent) {
      parents.unshift(parent);
      currentParentId = parent.parent_id;
    }
  }
  return (
    <Paper className={classes.paper}>
      <Breadcrumbs
        aria-label="breadcrumb"
        separator={<NavigateNextIcon fontSize="small" />}
        className={classes.breadcrumbs}
      >
        {parents.map(p =>
          <FaqElementBreadcrumbsItem
            key={`parent-${p.id}`}
            element={p}
            handleClickBreadcrumbs={changeCurrent}
          />,
        )}
        <FaqElementBreadcrumbsItem
          key={'current'}
          element={element}
          handleClickBreadcrumbs={changeCurrent}
          current
        />
      </Breadcrumbs>
    </Paper>
  );
}
export default FaqElementBreadcrumbs;
