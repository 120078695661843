import {
  USER_DOWNLOADED,
  USER_SENDING,
  USERS_DOWNLOADED,
  USERS_FAILED,
  USERS_PENDING,
} from './types';

export const INITIAL_STATE = {
  error: null,
  content: null,
  user: null,
  pending: false,
  sending: false,
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case USER_DOWNLOADED: {
      let users = [];
      let userIndex = -1;
      if (state.content) {
        users = state.content.slice();
        userIndex = users.findIndex(user => user.id === action.payload.id);
      }
      if (userIndex === -1) {
        users.push(action.payload);
      } else {
        users[userIndex] = action.payload;
      }
      return {
        ...state,
        error: null,
        pending: false,
        sending: false,
        content: users,
      };
    }
    case USER_SENDING:
      return {
        ...state,
        sending: true,
        error: null,
      };
    case USERS_DOWNLOADED:
      return {
        ...state,
        content: action.payload,
        error: null,
        pending: false,
        sending: false,
      };
    case USERS_FAILED:
      return {
        ...state,
        error: action.payload,
        pending: false,
        sending: false,
      };
    case USERS_PENDING:
      return {
        ...state,
        pending: true,
        error: null,
      };
    default:
      break;
  }
  return state;
}
