import { data_languages } from '../../../../constants/lang';
import { INITIAL_STATE, INITIAL_ERROR_STATE } from './CatalogsItemsForm.const';

export const stateWithItem = (item) => {
  let result = {
    ...INITIAL_STATE,
    id: item.id,
    tags: item.catalogs_tags,
    type: item.type,
  };
  if (
    item.translations !== undefined
    && item.translations !== null
    && item.translations['fr'] !== undefined
    && item.translations['fr'] !== null
  ) {
    result = {
      ...result,
      link_youtube: item.translations['fr'].link,
      ...data_languages.reduce((o, key) => (
        {
          ...o,
          [`name_${key}`]: item.translations[key].name,
          [`description_${key}`]: item.translations[key].description,
        }
      ), {}),
    };
  }
  return result;
};
export const errorStateWithItem = (item) => {
  let errors = {
    ...INITIAL_ERROR_STATE,
  };
  data_languages.forEach( lang => {
    if (item.translations[lang] && !item.translations[lang].link) {
      errors[`pdf_${lang}_error`] = ''
    }
  });
  return errors;
};
