import {
  FAQ_ARTICLE_DELETED,
  FAQ_ARTICLE_DOWNLOADED,
  FAQ_ARTICLE_SENDING,
  FAQ_ARTICLES_DOWNLOADED,
  FAQ_ARTICLES_FAILED,
  FAQ_ARTICLES_PENDING
} from './types';

export const INITIAL_STATE = {
  error: null,
  content: null,
  pending: false,
  sending: false,
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case FAQ_ARTICLES_PENDING:
      return {
        ...state,
        pending: true,
      };
    case FAQ_ARTICLES_FAILED:
      return {
        ...state,
        error: action.payload,
        pending: false,
        sending: false,
      };
    case FAQ_ARTICLES_DOWNLOADED:
      return {
        ...state,
        content: action.payload,
        pending: false,
        error: null,
      };
    case FAQ_ARTICLE_SENDING:
      return {
        ...state,
        sending: true,
      };
    case FAQ_ARTICLE_DOWNLOADED: {
      let newContent = [];
      if (state.content != null) {
        newContent = [...state.content];
      }
      let index = newContent.findIndex(ar => ar.id === action.payload.id);
      if (index === -1) {
        newContent.push(action.payload);
      } else {
        newContent[index] = action.payload;
      }
      return {
        ...state,
        content: newContent,
        sending: false,
        error: null,
      };
    }
    case FAQ_ARTICLE_DELETED: {
      let newContent = [];
      if (state.content != null) {
        newContent = [...state.content];
      }
      if (newContent.length > 0) {
        let articleIndex = -1;
        if (Object.prototype.toString.call(action.payload) === '[object Object]') {
          articleIndex = newContent.findIndex(article => article.id === action.payload.id);
        } else {
          articleIndex = newContent.findIndex(article => article.id === action.payload);
        }

        if (articleIndex > -1) {
          newContent.splice(articleIndex, 1);
        }
      }
      return {
        ...state,
        content: newContent,
        sending: false,
        error: null,
      };
    }
    default:
      break;
  }
  return { ...state };
}
