import React from 'react';
import PropTypes from 'prop-types';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import makeStyles from '@material-ui/core/styles/makeStyles';
import useTheme from '@material-ui/core/styles/useTheme';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { deleteFirmwareExcludedVersions } from '../../../store/firmwareExcludedVersions/actions';
import { clickableRowStyle } from '../../../constants/styles/row.style';

const useStyles = makeStyles(theme => ({ row: clickableRowStyle(theme) }));

const ExcludedVersionRow = ({ value }) => {

  const theme = useTheme();
  const classes = useStyles(theme);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const handleDelete = (event) => {
    event.preventDefault();
    event.stopPropagation();
    dispatch(deleteFirmwareExcludedVersions(value.id));
  };

  return (
    <TableRow
      className={classes.row}
      key={value.id}
      tabIndex={-1}
      hover
    >
      <TableCell>{value.ble_version}</TableCell>
      <TableCell>{value.rf_version}</TableCell>
      <TableCell align={'right'}>
        <Tooltip title={t('remove')}>
          <IconButton
            id="remove"
            aria-label="delete"
            onClick={handleDelete}
          >
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      </TableCell>
    </TableRow>
  );
};

ExcludedVersionRow.propTypes = {
  value: PropTypes.object.isRequired,
};

export default ExcludedVersionRow;
