import { BACK_CHANGE, SHOW_LANG_MENU, TITLE_CHANGE } from './types';
import {
  CARS,
  CATALOGS,
  CATALOGS_TAGS,
  CHALLENGES,
  FAQ_ARTICLES,
  IN_APP_NOTIFICATIONS,
  RECEIVER,
  REQUESTS,
} from '../../constants/routes';
import i18n from '../../i18n';

export const INITIAL_STATE = {
  title: '',
  back: null,
  backParams: {},
  displayLangMenu: false,
};

export default function (state = INITIAL_STATE, action) {

  if (action.type === TITLE_CHANGE) {
    let newBack = null;
    let newBackParams = {};
    if (action.payload === i18n.t('title:title.contact_email')) {
      newBack = REQUESTS;
      newBackParams = {
        value: 0,
      };
    }
    if (
      action.payload === i18n.t('title:title.add_rally_car_page')
      || action.payload === i18n.t('title:title.edit_rally_car_page')
    ) {
      newBack = CARS;
      newBackParams = {
        value: 1,
      };
    }
    if (
      action.payload === i18n.t('title:title.add_car_page')
      || action.payload === i18n.t('title:title.edit_car_page')
      || action.payload === i18n.t('title:title.duplicate_car_page')
    ) {
      newBack = CARS;
      newBackParams = {
        value: 0,
      };
    }
    if (action.payload === i18n.t('title:title.add_challenge_page')) {
      newBack = CHALLENGES;
      newBackParams = {
        value: 0,
      };
    }
    if (
      action.payload === i18n.t('title:title.receiver_mmc_ble_page')
      || action.payload === i18n.t('title:title.receiver_mmc_rf_page')
      || action.payload === i18n.t('title:title.receiver_mtc_ble_page')
      || action.payload === i18n.t('title:title.receiver_mtc_rf_page')
      || action.payload === i18n.t('title:title.excluded_version_add_page')
      || action.payload === i18n.t('title:title.firmware_group_add_page')
    ) {
      newBack = RECEIVER;
      newBackParams = {};
    }
    if (action.payload === i18n.t('title:title.catalog_tags_page')) {
      newBack = CATALOGS;
      newBackParams = {};
    }
    if (
      action.payload === i18n.t('title:title.catalog_tags_add_page')
      || action.payload === i18n.t('title:title.catalog_tags_edit_page')
    ) {
      newBack = CATALOGS_TAGS;
      newBackParams = {};
    }
    if (
      action.payload === i18n.t('title:title.add_catalogs_page')
      || action.payload === i18n.t('title:title.edit_catalogs_page')
    ) {
      newBack = CATALOGS;
      newBackParams = {};
    }
    if (action.payload === i18n.t('title:title.in_app_notification_add_page')) {
      newBack = IN_APP_NOTIFICATIONS;
      newBackParams = {};
    }
    if (action.payload === i18n.t('title:title.edit_faq_articles_page')) {
      newBack = FAQ_ARTICLES;
      newBackParams = {};
    }
    return {
      title: action.payload,
      back: newBack,
      backParams: newBackParams,
      displayLangMenu: false,
    };
  } else if (action.type === BACK_CHANGE) {
    return {
      ...state,
      back: action.payload.path,
      backParams: action.payload.params,
    };
  } else if (action.type === SHOW_LANG_MENU) {
    return {
      ...state,
      displayLangMenu: true,
    };
  }
  return state;
}
