import React from 'react';
import { ROLE_ADMIN } from '../../constants/roles';
import withAuthentication from '../../hoc/withAuthentication';
import RequestTabs from '../../components/Requests/RequestTabs';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { setTitle } from '../../store/title/actions';
import MTCButton from '../../components/Layout/Button/MTCButton';
import { useHistory } from 'react-router';
import { CONTACTS } from '../../constants/routes';

export const RequestsPage = () => {

  const { t } = useTranslation();
  const { title } = useSelector(state => state.title);
  const dispatch = useDispatch();
  const history = useHistory();

  if (title !== (t('title:title.requests_page'))) {
    dispatch(setTitle(t('title:title.requests_page')));
  }

  const handleClickAddMailAddressContact = () => {
    history.push(CONTACTS);
  }

  return (
    <>
      <MTCButton
        text={t('title:title.contact_email')}
        onClick={handleClickAddMailAddressContact}
        style={{float: 'right'}}
      />
    <RequestTabs/>
    </>
  );
}

export default withAuthentication(ROLE_ADMIN)(RequestsPage);
