import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { setTitle } from '../../store/title/actions';
import ContactsTabs from '../../components/Contacts/ContactsTabs';
import withAuthentication from '../../hoc/withAuthentication';
import { ROLE_ADMIN } from '../../constants/roles';
import AddContactButton from '../../components/Contacts/AddContactButton';
import DefaultContactForm from '../../components/Contacts/DefaultContactsForm';

export const ContactsPage = () => {
  const { t } = useTranslation();
  const { title } = useSelector(state => state.title);
  const dispatch = useDispatch();

  if (title !== (t('title:title.contact_email'))) {
    dispatch(setTitle(t('title:title.contact_email')));
  }

  return (
    <div>
      <DefaultContactForm />
      <ContactsTabs />
      <AddContactButton />
    </div>
  );
};

export default withAuthentication(ROLE_ADMIN)(ContactsPage);