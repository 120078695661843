export const columns = [
  {
    id: 'email',
    numeric: false,
    disablePadding: false,
  },
  {
    id: 'lastname',
    numeric: false,
    disablePadding: false,
  },
  {
    id: 'firstname',
    numeric: false,
    disablePadding: false,
  },
  {
    numeric: false,
    disablePadding: false,
  },
];
export const defaultOrderBy = 'email';
