import makeStyles from '@material-ui/core/styles/makeStyles';
import { textFieldClass } from '../../constants/styles/input.style';

export const useStyles = makeStyles(theme => {
  return {
    ...textFieldClass(theme),
    menu: {
      width: 100,
    },
    selectLabel: {
      zIndex: 2,
    },
    grid: {},
  };
});
