import makeStyles from '@material-ui/core/styles/makeStyles';
import { michelin_green, michelin_red } from '../../constants/michelin_colors';

export const useStyles = makeStyles(() => (
  {
    error: {
      backgroundColor: michelin_red,
    },
    success: {
      backgroundColor: michelin_green,
    },
  }
));
