const FIRMWARE_GROUPS_PENDING = 'firmware_groups_pending';
const FIRMWARE_GROUPS_FAILED = 'firmware_groups_failed';
const FIRMWARE_GROUPS_DOWNLOADED = 'firmware_groups_downloaded';
const FIRMWARE_GROUP_DOWNLOADED = 'firmware_group_downloaded';
const FIRMWARE_GROUP_SENDING = 'firmware_group_sending';
const FIRMWARE_GROUP_DELETED = 'firmware_group_deleted';
const FIRMWARE_GROUP_ITEM_DOWNLOADED = 'firmware_group_item_downloaded';
const FIRMWARE_GROUP_ITEM_DELETED = 'firmware_group_item_deleted';
const FIRMWARE_GROUP_FIRMWARE_DOWNLOADED = 'firmware_group_firmware_downloaded';
const FIRMWARE_GROUP_FIRMWARE_DELETED = 'firmware_group_firmware_deleted';

export {
  FIRMWARE_GROUPS_PENDING,
  FIRMWARE_GROUPS_FAILED,
  FIRMWARE_GROUPS_DOWNLOADED,
  FIRMWARE_GROUP_DOWNLOADED,
  FIRMWARE_GROUP_SENDING,
  FIRMWARE_GROUP_DELETED,
  FIRMWARE_GROUP_ITEM_DOWNLOADED,
  FIRMWARE_GROUP_FIRMWARE_DOWNLOADED,
  FIRMWARE_GROUP_ITEM_DELETED,
  FIRMWARE_GROUP_FIRMWARE_DELETED
};
