import React, { useState } from 'react';
import { Fab, Menu, MenuItem } from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { IN_APP_NOTIFICATIONS_ADD, IN_APP_NOTIFICATIONS_EDIT } from '../../constants/routes';
import { useStyles } from './InAppNotificationMenuButton.style';

const InAppNotificationMenuButton = ({
                                       notification,
                                       handleDelete,
                                     }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const history = useHistory();

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDeleteClick = (event) => {
    event.preventDefault();
    event.stopPropagation();
    handleDelete();
    setAnchorEl(null);
  }

  return (
    <div>
      <Fab
        className={classes.button}
        color="secondary"
        aria-owns={anchorEl
          ? 'simple-menu'
          : undefined}pending
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon />
      </Fab>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={() =>
          history.push({
            pathname: IN_APP_NOTIFICATIONS_EDIT.replace(':notificationId', notification.id),
            state: { fromDetails: true },
          })
        }>
          {t('edit')}
        </MenuItem>
        <MenuItem onClick={() =>
          history.push({
            pathname: IN_APP_NOTIFICATIONS_ADD,
            state: {
              notification,
              fromDetails: true,
            },
          })
        }>
          {t('duplicate')}
        </MenuItem>
        <MenuItem onClick={handleDeleteClick}>
          {t('remove')}
        </MenuItem>
      </Menu>
    </div>
  );
};

export default React.memo(InAppNotificationMenuButton);
