import { data_languages } from '../../../../constants/lang';
import { INITIAL_STATE } from './FaqArticlesForm.const';
import { htmlToEditorState } from '../../../../utils/DraftEditorUtils'

export const stateWithArticle = (article) => {
  return {
    ...INITIAL_STATE,
    id: article.id,
    has_support_contact_button: article.has_support_contact_button,
    ...data_languages.reduce((o, key) => (
      {
        ...o,
        [`title_${key}`]: article.translations[key]
          ? article.translations[key].title
          : '',
        [`content_${key}`]: htmlToEditorState(
          article.translations[key]
            ? article.translations[key].content
            : '',
        ),
        [`tags_${key}`]: article.translations[key]
          ? article.translations[key].tags
          : '',
      }
    ), {}),
  };
};

