const CATALOGS_ITEMS_PENDING = 'catalogs_items_pending';
const CATALOGS_ITEMS_SENDING = 'catalogs_items_sending';
const CATALOGS_ITEMS_FAILED = 'catalogs_items_failed';
const CATALOGS_ITEMS_DOWNLOADED = 'catalogs_items_downloaded';
const CATALOGS_ITEM_DOWNLOADED = 'catalogs_item_downloaded';

export {
  CATALOGS_ITEMS_PENDING,
  CATALOGS_ITEMS_SENDING,
  CATALOGS_ITEMS_FAILED,
  CATALOGS_ITEMS_DOWNLOADED,
  CATALOGS_ITEM_DOWNLOADED,
};