import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import withAuthentication from '../../hoc/withAuthentication';
import { ROLE_ADMIN } from '../../constants/roles';
import { setTitle } from '../../store/title/actions';
import CallCentersTable from '../../components/CallCenters/CallCentersTable';
import DefaultCallCenterForm from '../../components/CallCenters/DefaultCallCenterForm';
import LangCallCentersTable from '../../components/CallCenters/LangCallCentersTable';
import AddCallCenterButton from '../../components/CallCenters/AddCallCenterButton';

const CallCentersPage = () => {

  const { t } = useTranslation();
  const { title } = useSelector(state => state.title);
  const dispatch = useDispatch();

  if (title !== t('title:title.call_centers_page')) {
    dispatch(setTitle(t('title:title.call_centers_page')));
  }

  return (
    <div>
      <DefaultCallCenterForm />
      <LangCallCentersTable />
      <CallCentersTable />
      <AddCallCenterButton />
    </div>
  );
};

export default withAuthentication(ROLE_ADMIN)(CallCentersPage);
