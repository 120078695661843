import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { getDefaultContacts, putDefaultContacts } from '../../store/defaultContacts/actions';
import MTCLoader from '../Layout/MTCLoader';
import Grid from '@material-ui/core/Grid';
import MTCTextField from '../Form/MTCTextField';
import MTCButton from '../Layout/Button/MTCButton';
import { INITIAL_STATE } from './DefaultContactsForm.const';
import { useStyles } from './DefaultContactsForm.style';

const DefaultContactForm = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const { pending, sending, content, error } = useSelector(state => state.defaultContacts);
  const [state, setState] = useState(INITIAL_STATE);

  if (!pending && !sending && !content && !error) {
    dispatch(getDefaultContacts());
  }

  if (content && !state.set) {
    setState({
      ...INITIAL_STATE, ...content,
      set: true,
    });
  }

  const onSubmitEdit = () => {
    dispatch(putDefaultContacts(state));
  };

  const onEditImprovementMail = (event) => {
    setState({
      ...state,
      improvement_mail: event.target.value,
    });
  };

  const onEditUsMail = (event) => {
    setState({
      ...state,
      us_mail: event.target.value,
    });
  };

  const onEditEuropeMail = (event) => {
    setState({
      ...state,
      europe_mail: event.target.value,
    });
  };

  if (pending) {
    return <MTCLoader />;
  }
  if (error) {
    return <p>{error}</p>;
  }
  return (
    <form className={classes.form}>
      <h2>{t('default_contacts_subtitle')}</h2>
      <Grid container align={'center'} justifyContent={'center'} spacing={2}>
        <Grid item xs={12} sm={6} lg={4}>
          <MTCTextField
            id={'improvement_mail'}
            onChange={onEditImprovementMail}
            value={state.improvement_mail}
          />
        </Grid>
        <Grid item xs={12} sm={6} lg={4}>
          <MTCTextField
            id={'us_mail'}
            onChange={onEditUsMail}
            value={state.us_mail}
          />
        </Grid>
        <Grid item xs={12} sm={6} lg={4}>
          <MTCTextField
            id={'europe_mail'}
            onChange={onEditEuropeMail}
            value={state.europe_mail}
          />
        </Grid>
        <Grid item>
          <MTCButton
            text={t('edit')}
            type={'button'}
            onClick={onSubmitEdit}
            disabled={sending || pending}
          />
        </Grid>
      </Grid>
    </form>
  );
};

export default DefaultContactForm;
