import React from 'react';
import Typography from '@material-ui/core/Typography';
import { useTranslation } from 'react-i18next';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { useHistory } from 'react-router';
import { HOMEPAGE, TRACKS } from '../constants/routes';
import errorFace from '../resources/pictures/error-face.svg'
import { ROLE_ADMIN, ROLE_SUPPORT } from '../constants/roles';

const Error404 = ({ withHomeButton }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const role = localStorage.getItem('role');
  const marginContainerStyle = {marginTop: '3em'};
  const marginTitleStyle = {marginTop: '0.5em'};
  const marginTextStyle = {marginTop: '1em'};
  return (
    <div className="App" style={marginContainerStyle}>
      <Grid
        container
        alignContent="center"
        direction="column"
        alignItems="center"
        justifyContent="space-around"
      >
        <img src={errorFace} alt="" width="168" height="168"/>
        <Typography variant={'h2'} style={marginTitleStyle}>
          {t('error404')}
        </Typography>
        <Typography variant={'h4'} style={marginTextStyle}>
          {t('notFount')}
        </Typography>
        {withHomeButton && <Button
          variant="contained"
          onClick={() => {history.replace(role === ROLE_SUPPORT ? TRACKS : HOMEPAGE)}}
          color="primary"
          style={marginContainerStyle}
        >
          {t('returnToHome')}
        </Button>}
        {!withHomeButton && <Button
          variant="contained"
          onClick={() => {history.goBack()}}
          color="primary"
          style={marginContainerStyle}
        >
          {t('back')}
        </Button>}
      </Grid>
    </div>
  );
}

Error404.defaultProps = {
  withHomeButton: false
}

export default Error404;
