import {
  TRACK_DOWNLOADED,
  TRACK_SENDING,
  TRACKS_DOWNLOADED,
  TRACKS_FAILED,
  TRACKS_PENDING,
  TRACK_FILTERED,
  TRACK_FILTERED_RESET
} from './types';
import ApiService from '../../services/ApiService';
import { handleError } from '../infos/actions';

export const trackSendingAction = () => ({
  type: TRACK_SENDING,
});

export const trackDownloadedAction = (track, filter) => ({
  type: TRACK_DOWNLOADED,
  payload: {track, filter}
});

export const tracksDownloadedAction = tracks => ({
  type: TRACKS_DOWNLOADED,
  payload: tracks,
});

export const tracksFailedAction = error => ({
  type: TRACKS_FAILED,
  payload: error,
});

export const tracksPendingAction = () => ({
  type: TRACKS_PENDING,
});

export const tracksFilteredAction = filter => ({
  type: TRACK_FILTERED,
  payload: filter
});

export const tracksFilteredResetAction = () => ({
  type: TRACK_FILTERED_RESET,
});

export function getTracks() {
  return (dispatch) => {
    dispatch(tracksPendingAction());
    return ApiService
      .getTracks()
      .then((data) => {
        dispatch(tracksDownloadedAction(data));
        return data;
      })
      .catch((error) => {
        dispatch(tracksFailedAction(error));
        dispatch(handleError(error));
      });
  };
}

export function postTrack(track) {
  return (dispatch) => {
    dispatch(trackSendingAction());
    return ApiService
      .postTrack(track)
      .then((data) => {
        dispatch(trackDownloadedAction(data, true));
        return data.id;
      })
      .catch((error) => {
        dispatch(tracksFailedAction(error));
        dispatch(handleError(error));
      });
  };
}

export function importTrack(track) {
  return (dispatch) => {
    dispatch(trackSendingAction());
    return ApiService
      .postTrack(track)
      .then((data) => {
        dispatch(trackDownloadedAction(data, false));
        return data.id;
      });
  };
}

export function postTrackPicture(trackId, picture, onUploadProgress = null) {
  return (dispatch) => {
    dispatch(trackSendingAction());
    return ApiService
      .postTrackPicture(trackId, picture, onUploadProgress)
      .then((data) => {
        dispatch(trackDownloadedAction(data, false));
      })
      .catch((error) => {
        dispatch(tracksFailedAction(error));
        dispatch(handleError(error));
      });
  };
}

export function putMultipartTrack(id, track) {
  return (dispatch) => {
    dispatch(trackSendingAction());
    return ApiService
      .putMultipartTrack(id, track)
      .then((data) => {
        dispatch(trackDownloadedAction(data, true));
      })
      .catch((error) => {
        dispatch(tracksFailedAction(error));
        dispatch(handleError(error));
      });
  };
}

export function putTrack(track) {
  return (dispatch) => {
    dispatch(trackSendingAction());
    return ApiService
      .putTrack(track)
      .then((data) => {
        dispatch(trackDownloadedAction(data, false));
      })
      .catch((error) => {
        dispatch(tracksFailedAction(error));
        dispatch(handleError(error));
      });
  };
}

export function setContentFilter(filter) {
  return (dispatch) => {
    dispatch(tracksFilteredAction(filter));
  }
}

export function resetContentFilter() {
  return (dispatch) => {
    dispatch(tracksFilteredResetAction());
  }
}
