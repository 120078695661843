import {
  LANG_CALL_CENTER_DELETED,
  LANG_CALL_CENTER_DOWNLOADED,
  LANG_CALL_CENTER_SENDING,
  LANG_CALL_CENTERS_DOWNLOADED,
  LANG_CALL_CENTERS_FAILED,
  LANG_CALL_CENTERS_PENDING,
} from './types';

export const INITIAL_STATE = {
  error: null,
  content: null,
  unavailableLang: [],
  pending: false,
  sending: false,
};

export default function (state = INITIAL_STATE, action) {
  let newUnavailableLang = [];
  switch (action.type) {
    case LANG_CALL_CENTER_DOWNLOADED: {
      let callCenters = [];
      let callCenterIndex = -1;
      newUnavailableLang = state.unavailableLang.slice();
      if (state.content) {
        callCenters = state.content.slice();
        callCenterIndex = callCenters.findIndex(callCenter => callCenter.id === action.payload.id);
      }
      if (callCenterIndex === -1) {
        callCenters.push(action.payload);
        newUnavailableLang.push(action.payload.lang);
      } else {
        callCenters[callCenterIndex] = action.payload;
      }
      return {
        error: null,
        pending: false,
        sending: false,
        content: callCenters,
        unavailableLang: newUnavailableLang,
      };
    }
    case LANG_CALL_CENTER_DELETED: {
      let callCenters = [];
      let callCenterIndex = -1;
      newUnavailableLang = state.unavailableLang.slice();
      if (state.content) {
        callCenters = state.content.slice();
        callCenterIndex = callCenters.findIndex(callCenter => callCenter.id === action.payload.id);
      }
      if (callCenterIndex !== -1) {
        callCenters.splice(callCenterIndex, 1);
      }
      const langIndex = newUnavailableLang.findIndex(l => l === action.payload.lang);
      if (langIndex !== -1) {
        newUnavailableLang.splice(langIndex, 1);
      }
      return {
        error: null,
        pending: false,
        sending: false,
        content: callCenters,
        unavailableLang: newUnavailableLang,
      };
    }
    case LANG_CALL_CENTER_SENDING:
      return {
        ...state,
        sending: true,
        error: null,
      };
    case LANG_CALL_CENTERS_DOWNLOADED:
      action.payload.forEach(lcc => {
        newUnavailableLang.push(lcc.lang);
      });
      return {
        content: action.payload,
        error: null,
        pending: false,
        sending: false,
        unavailableLang: newUnavailableLang,
      };
    case LANG_CALL_CENTERS_FAILED:
      return {
        ...state,
        error: action.payload,
        pending: false,
        sending: false,
      };
    case LANG_CALL_CENTERS_PENDING:
      return {
        ...state,
        pending: true,
        error: null,
      };
    default:
      break;
  }
  return state;
}
