import makeStyles from '@material-ui/core/styles/makeStyles';
import { clickableRowStyle } from '../../../constants/styles/row.style';
import {
  michelin_blue_light,
  michelin_text_grey_extra_light,
  michelin_white,
} from '../../../constants/michelin_colors';

export const useStyles = makeStyles(theme => {
  return {
    row: clickableRowStyle(theme),
    button: {
      color: 'inherit',
    },
    clickableCell: {
      '&:hover': {
        backgroundColor: theme.palette.primary.dark,
        cursor: 'pointer',
      },
    },
    cellDisabled: {
      cursor: 'pointer',
      backgroundColor: theme.palette.text.disabled,
      color: theme.palette.text.primary,
      '&:hover': {
        backgroundColor: theme.palette.text.secondary,
        color: 'inherit',
      },
    },
    cellParentDisabled: {
      cursor: 'pointer',
      backgroundColor: michelin_text_grey_extra_light,
      color: theme.palette.text.primary,
      '&:hover': {
        backgroundColor: theme.palette.text.disabled,
        color: 'inherit',
      },
    },
    visibilityOffIcon: {
      fontSize: '1.25em',
      paddingRight: '3px',
      verticalAlign: 'middle',
    },
    focusCell: {
      cursor: 'default',
      backgroundColor: michelin_blue_light,
      color: michelin_white,
    },
  };
});
