import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import useTheme from '@material-ui/core/styles/useTheme';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import { Grid } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { INITIAL_ERROR_STATE, INITIAL_STATE } from './FaqElementsDialogExternalLink.const';
import { stateWithElement } from './FaqElementsDialogExternalLink.func';
import { useStyles } from '../FaqElementsDialog.style';
import { EXTERNAL_LINK } from '../../FaqElementType.const';
import MTCTextField from '../../../../Form/MTCTextField';
import { data_languages, data_languages_no_fr } from '../../../../../constants/lang';
import { postFaqElements, putFaqElements } from '../../../../../store/faqElements/actions';

const FaqElementsDialogExternalLink = ({ handleClose, parentId, defaultElement }) => {

  const { t } = useTranslation();
  const theme = useTheme();
  const dispatch = useDispatch();
  const classes = useStyles(theme);
  const sending = false;
  let title
  if (defaultElement) {
    title = t('title:title.edit_faq_elements_external_link');
  } else {
    title = t('title:title.add_faq_elements_external_link');
  }

  const [state, setState] = useState(defaultElement
    ? stateWithElement(defaultElement)
    : INITIAL_STATE
  );
  const [errors, setErrors] = useState(INITIAL_ERROR_STATE);

  const handleSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();
    if (!sending) {
      let errorCount = 0;
      let newErrorState = { ...INITIAL_ERROR_STATE };
      data_languages.forEach(dl => {
        const titleKey = `title_${dl}`;
        const title = state[titleKey];
        if (title === null || title.trim() === '') {
          errorCount++;
          newErrorState[titleKey + '_error'] = t('error.required_error');
        }
        const externalLinkKey = `external_link_${dl}`;
        const externalLink = state[externalLinkKey];
        if (externalLink === null || externalLink.trim() === '') {
          errorCount++;
          newErrorState[externalLinkKey + '_error'] = t('error.required_error');
        }
      });
      if (errorCount === 0) {
        const jsonData = {
          id: defaultElement ? defaultElement.id : null,
          parent_id: parentId,
          type: EXTERNAL_LINK,
          translations: data_languages.reduce((o, key) => (
            {
              ...o,
              [key]: {
                title: state[`title_${key}`],
                external_link: state[`external_link_${key}`],
                tags: state[`tags_${key}`],
              },
            }
          ), {}),
        };
        if (defaultElement) {
          dispatch(putFaqElements(jsonData)).then(() => {
            handleClose();
          });
        } else {
          dispatch(postFaqElements(jsonData)).then(() => {
            handleClose();
          });
        }
      } else {
        setErrors(newErrorState);
      }
    }
  };

  const handleStatePropertyChange = (property, value) => {
    let newState = { ...state };
    newState[property] = value;
    let newErrors = { ...errors };
    newErrors[property + '_error'] = null;
    setState(newState);
    setErrors(newErrors);
  };

  return (
    <>
      <DialogTitle disableTypography id={'folder-dialog-title'}>
        <Typography variant="h6">
          {title}
        </Typography>
        <IconButton aria-label="close" className={classes.closeButton} onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers={true}>
        <Grid
          container
          align="center"
          direction={'column'}
          justifyContent={'center'}
        >
          <Grid item xs={12}>
            <Typography
              align="center"
              variant="h5"
              component="h2"
              gutterBottom>
              {t('title')}
            </Typography>
          </Grid>
          <MTCTextField
            gridMD={12}
            id={`title_fr`}
            key={`title_fr`}
            onChange={event => handleStatePropertyChange(`title_fr`, event.target.value)}
            value={state[`title_fr`]}
            required={true}
            errorMessage={errors[`title_fr_error`]}
            onBlur={event => {
              setState({
                ...state,
                ...data_languages.reduce((o, key) => (
                  {
                    ...o,
                    [`title_${key}`]: state[`title_${key}`] || event.target.value,
                  }
                ), {}),
              });
            }}
          />

          {data_languages_no_fr.map(lg => (
            <MTCTextField
              gridMD={12}
              id={`title_${lg}`}
              key={`title_${lg}`}
              onChange={event => handleStatePropertyChange(`title_${lg}`,
                event.target.value,
              )}
              value={state[`title_${lg}`]}
              required={true}
              errorMessage={errors[`title_${lg}_error`]}
            />
          ))}
          <Grid item xs={12}>
            <Typography
              align="center"
              variant="h5"
              component="h2"
              gutterBottom>
              {t('external_link')}
            </Typography>
          </Grid>
          <MTCTextField
            gridMD={12}
            id={`external_link_fr`}
            key={`external_link_fr`}
            onChange={event => handleStatePropertyChange(`external_link_fr`, event.target.value)}
            value={state[`external_link_fr`]}
            required={true}
            errorMessage={errors[`external_link_fr_error`]}
            onBlur={event => {
              setState({
                ...state,
                ...data_languages.reduce((o, key) => (
                  {
                    ...o,
                    [`external_link_${key}`]: state[`external_link_${key}`] || event.target.value,
                  }
                ), {}),
              });
            }}
          />
          {data_languages_no_fr.map(lg => (
            <MTCTextField
              gridMD={12}
              id={`external_link_${lg}`}
              key={`external_link_${lg}`}
              onChange={event => handleStatePropertyChange(`external_link_${lg}`,
                event.target.value,
              )}
              value={state[`external_link_${lg}`]}
              required={true}
              errorMessage={errors[`external_link_${lg}_error`]}
            />
          ))}
          <Grid item xs={12}>
            <Typography
              align="center"
              variant="h5"
              component="h2"
              gutterBottom>
              {t('tags')}
            </Typography>
          </Grid>
          <MTCTextField
            gridMD={12}
            id={`tags_fr`}
            key={`tags_fr`}
            onChange={event => handleStatePropertyChange(`tags_fr`, event.target.value)}
            value={state[`tags_fr`]}
            required={false}
            errorMessage={errors[`tags_fr_error`]}
            onBlur={event => setState({
              ...state,
              ...data_languages.reduce((o, key) => (
                {
                  ...o,
                  [`tags_${key}`]: state[`tags_${key}`] || event.target.value,
                }
              ), {}),
            })}
          />

          {data_languages_no_fr.map(lg => (
            <MTCTextField
              gridMD={12}
              id={`tags_${lg}`}
              key={`tags_${lg}`}
              onChange={event => handleStatePropertyChange(`tags_${lg}`,
                event.target.value,
              )}
              value={state[`tags_${lg}`]}
              required={false}
              errorMessage={errors[`tags_${lg}_error`]}
            />
          ))}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} backgroundcolor={'secondary'} disabled={sending}>
          {t('cancel')}
        </Button>
        <div className={classes.wrapper}>
          <Button color={'primary'} disabled={sending} type={'button'} onClick={handleSubmit}>
            {t('validate')}
          </Button>
          {sending && <CircularProgress size={24} className={classes.buttonProgress} />}
        </div>
      </DialogActions>
    </>
  );
};

export default FaqElementsDialogExternalLink;

