export const INITIAL_STATE = {
  id: null,
  brand: null,
  type: null,
  orders: [
    {
      name: 'brand',
      order: 0,
    },
    {
      name: 'type',
      order: 1,
    },
  ],
  dialog: {
    openDialog: false,
    dialogItem: '',
    dialogTitle: '',
    dialogText: '',
    dialogHandleOK: () => {
    },
    newItemKey: '',
    newItem: '',
  },
  confirmDialogOpen: false,
};
export const stateWithTire = tire => (
  {
    id: tire.id,
    brand: tire.brand,
    type: tire.type,
  }
);
