const MESSAGE_REQUEST_PENDING = 'message_request_pending';
const MESSAGE_REQUEST_FAILED = 'message_request_failed';
const MESSAGE_REQUEST_DOWNLOADED = 'message_request_downloaded';
const MESSAGE_REQUEST_RESET = 'message_request_reset';


export {
  MESSAGE_REQUEST_PENDING,
  MESSAGE_REQUEST_FAILED,
  MESSAGE_REQUEST_DOWNLOADED,
  MESSAGE_REQUEST_RESET
};
