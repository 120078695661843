import {
  CONTACT_DELETED,
  CONTACT_DOWNLOADED,
  CONTACT_SENDING,
  CONTACTS_DOWNLOADED,
  CONTACTS_FAILED,
  CONTACTS_PENDING,
} from './types';

export const INITIAL_STATE = {
  error: null,
  content: null,
  pending: false,
  sending: false,
};

export default function (state = INITIAL_STATE, action) {
  let index = -1;
  let newContent = { ...state.content };
  switch (action.type) {
    case CONTACTS_DOWNLOADED:
      return {
        content: action.payload,
        error: null,
        pending: false,
        sending: false,
      };
    case CONTACT_DOWNLOADED:
      switch (action.payload.type) {
        case 'car':
          index = newContent
            .car_request_contact
            .findIndex(elem => elem.id === action.payload.id);
          if (index >= 0) {
            newContent.car_request_contact[index] = action.payload;
          } else {
            newContent.car_request_contact.push(action.payload);
          }
          break;
        case 'message':
          index = newContent
            .message_request_contact
            .findIndex(elem => elem.id === action.payload.id);
          if (index >= 0) {
            newContent.message_request_contact[index] = action.payload;
          } else {
            newContent.message_request_contact.push(action.payload);
          }
          break;
        case 'circuit':
          index = newContent
            .circuit_request_contact
            .findIndex(elem => elem.id === action.payload.id);
          if (index >= 0) {
            newContent.circuit_request_contact[index] = action.payload;
          } else {
            newContent.circuit_request_contact.push(action.payload);
          }
          break;
        case 'rally-car':
          index = newContent
            .rally_car_request_contact
            .findIndex(elem => elem.id === action.payload.id);
          if (index >= 0) {
            newContent.rally_car_request_contact[index] = action.payload;
          } else {
            newContent.rally_car_request_contact.push(action.payload);
          }
          break;
        default:
          break;
      }
      return {
        content: newContent,
        error: null,
        pending: false,
        sending: false,
      };
    case CONTACTS_FAILED:
      return {
        ...state,
        error: action.payload,
        pending: false,
        sending: false,
      };
    case CONTACTS_PENDING:
      return {
        ...state,
        pending: true,
        error: null,
      };
    case CONTACT_SENDING:
      return {
        ...state,
        sending: true,
        error: null,
      };
    case CONTACT_DELETED:
      switch (action.payload.type) {
        case 'car':
          index = newContent
            .car_request_contact
            .findIndex(elem => elem.id === action.payload.id);
          if (index >= 0) {
            newContent.car_request_contact.splice(index, 1);
          }
          break;
        case 'message':
          index = newContent
            .message_request_contact
            .findIndex(elem => elem.id
            === action.payload.id);
          if (index >= 0) {
            newContent.message_request_contact.splice(index, 1);
          }
          break;
        case 'circuit':
          index = newContent
            .circuit_request_contact
            .findIndex(elem => elem.id
            === action.payload.id);
          if (index >= 0) {
            newContent.circuit_request_contact.splice(index, 1);
          }
          break;
        case 'rally-car':
          index = newContent
            .rally_car_request_contact
            .findIndex(elem => elem.id
            === action.payload.id);
          if (index >= 0) {
            newContent.rally_car_request_contact.splice(index, 1);
          }
          break;
        default:
          break;
      }
      return {
        content: newContent,
        error: null,
        pending: false,
        sending: false,
      };

    default:
      break;
  }
  return state;
}
