import React from 'react';
import PropTypes from 'prop-types';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Typography from '@material-ui/core/Typography';
import Avatar from '@material-ui/core/Avatar';
import useTheme from '@material-ui/core/styles/useTheme';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { CHALLENGE } from '../../../constants/routes';
import IconButton from '@material-ui/core/IconButton';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import Visibility from '@material-ui/icons/Visibility';
import Tooltip from '@material-ui/core/Tooltip';
import { useDispatch } from 'react-redux';
import { putChallengeState } from '../../../store/challenges/actions';
import { useStyles } from './ClassicChallengeRow.style';

const ClassicChallengeRow = ({ value }) => {

  const { t } = useTranslation();
  const theme = useTheme();
  const history = useHistory();
  const classes = useStyles(theme);
  const dispatch = useDispatch();
  let name = value.translations.fr
    ? value.translations.fr.name
    : '';

  const handleClick = event => {
    event.preventDefault();
    event.stopPropagation();
    history.push(CHALLENGE.replace(':challengeId', value.id));
  };

  const handleVisibilitySwitch = event => {
    event.preventDefault();
    event.stopPropagation();
    let newState = (
      value.state === 0 || value.state === 1
    )
      ? 2
      : value.previous_state;
    dispatch(putChallengeState(value.id, newState));
  };

  return (
    <TableRow
      className={value.state === 2
        ? classes.row_disabled
        : classes.row}
      key={value.id}
      tabIndex={-1}
      onClick={handleClick}
      hover
    >
      <TableCell className={classes.multiComponentCell}>
        {value.picture &&
        <Avatar alt={name} src={value.picture} className={classes.avatar} />}
        <Typography>
          {name}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography>
          {t(`challenge_obtaining_types.${value.obtaining_type}`)}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography>
          {value.obtaining_needed_point}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography>
          {value.obtaining_environments && value.obtaining_environments.split(',').map(oe => {
            return t(`challenge_environments.${oe}`) + ' ';
          })}
          {!value.obtaining_environments && t('all_mode')}
        </Typography>
      </TableCell>
      <TableCell>
        <Tooltip title={value.state === 1
          ? t('deactivate')
          : t('activate')}>
          <IconButton
            id="switch"
            aria-label="update"
            onClick={handleVisibilitySwitch}
            className={classes.button}
          >
            {value.state === 2
              ? <VisibilityOff />
              : <Visibility />}
          </IconButton>
        </Tooltip>
      </TableCell>
    </TableRow>
  );
};

ClassicChallengeRow.propTypes = {
  value: PropTypes.object.isRequired,
};

export default ClassicChallengeRow;
