import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { postTester } from '../../store/testers/actions';
import StringService from '../../services/StringService';
import MTCTextField from '../Form/MTCTextField';
import MTCButton from '../Layout/Button/MTCButton';
import { useStyles } from './TesterForm.style';

const TesterForm = () => {

  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const {
    pending,
    sending,
  } = useSelector(state => state.testers);
  const [email, setEmail] = useState('');

  const onSubmit = (event) => {
    dispatch(postTester({ email }));
    event.preventDefault();
  };

  const handleChangeEmail = event => {
    setEmail(event.target.value);
  };

  const isInvalid = StringService.isEmpty(email) || sending || pending;

  return (
    <div>
      <Grid
        container
        className={classes.container}
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <Grid item xs={12} sm={12} md={3}>
          <Typography variant="h6" className={classes.title}>
            {t('add_tester')}
          </Typography>
        </Grid>
        <MTCTextField
          id="email"
          gridMD={3}
          gridSM={6}
          value={email}
          onChange={handleChangeEmail}
        />
      </Grid>
      <Grid
        container
        className={classes.container}
        direction="column"
        justifyContent="center"
        alignItems="center"
      >
        <Grid item xs={12}>
          <MTCButton
            disabled={isInvalid}
            onClick={onSubmit}
            sending={sending}
            text={t('add')}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default TesterForm;
