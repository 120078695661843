import makeStyles from '@material-ui/core/styles/makeStyles';
import {
  michelin_blue_light,
  michelin_blue_main,
  michelin_white,
} from '../../../../constants/michelin_colors';

export const useStyles = makeStyles((theme) => {
  return {
    container: {
      height: "100%",
      backgroundColor: michelin_white,
      paddingLeft: 0,
      paddingRight: 0,
      overflow: "hidden"
    },
    header: {
      backgroundColor: michelin_blue_light,
      color: "#FFF",
      borderTopLeftRadius: '4px',
      borderTopRightRadius: '4px',
      [theme.breakpoints.down('sm')]: {
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,
      }
    },
    path: {
      display: 'flex',
    },
    body: {
      display: 'flex'
    },

    // to remove ?
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
    wrapper: {
      position: 'relative',
    },
    buttonProgress: {
      color: michelin_blue_main,
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: -12,
      marginLeft: -12,
    },
  };
});
