import {
  MESSAGE_REQUEST_PENDING,
  MESSAGE_REQUEST_FAILED,
  MESSAGE_REQUEST_DOWNLOADED,
  MESSAGE_REQUEST_RESET
} from './types';

import ApiService from '../../services/ApiService';
import { handleError } from '../infos/actions';

export const messageRequestDownloadedAction = request => ({
  type: MESSAGE_REQUEST_DOWNLOADED,
  payload: request,
});

export const messageRequestsFailedAction = error => ({
  type: MESSAGE_REQUEST_FAILED,
  payload: error,
});

export const messageRequestsPendingAction = () => ({
  type: MESSAGE_REQUEST_PENDING,
});

export const messageRequestsResetAction = () => ({
  type: MESSAGE_REQUEST_RESET,
});

export function getMessageRequest(id) {
  return (dispatch) => {
    dispatch(messageRequestsPendingAction());
    return ApiService
      .getMessageRequest(id)
      .then((data) => {
        dispatch(messageRequestDownloadedAction(data));
        return data;
      })
      .catch((error) => {
        dispatch(messageRequestsFailedAction(error));
        dispatch(handleError(error));
      });
  };
}
