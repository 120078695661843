import React from 'react';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { useTranslation } from 'react-i18next';

export const RequestUserCard = ({request, classes, mailSubject}) => {
  const { t } = useTranslation();
  return (
    <Grid item xs={12} md={6} xl={4}>
      <Paper className={classes.information}>
        <Typography
          component="p"
          variant="h4"
          color={'primary'}
          className={classes.title}
        >
          {t('user')}
        </Typography>

        <p className={classes.text}>
          <Typography
            component="span"
            variant="subtitle1"
            color={'primary'}
            className={classes.textLabel}
          >
            {t('lastname')}
          </Typography>
          {request.user.lastname}
        </p>
        <p className={classes.text}>
          <Typography
            component="span"
            variant="subtitle1"
            color={'primary'}
            className={classes.textLabel}
          >
            {t('firstname')}
          </Typography>
          {request.user.firstname}
        </p>
        <p className={classes.text}>
          <Typography
            component="span"
            variant="subtitle1"
            color={'primary'}
            className={classes.textLabel}
          >
            {t('country_code')}
          </Typography>
          {request.user.country}
        </p>
        <p className={classes.text}>
          <Typography
            component="span"
            variant="subtitle1"
            color={'primary'}
            className={classes.textLabel}
          >
            {t('mtc_kit')}
          </Typography>
          {request.user.mtc_kit ? t('yes') : t('no')}
        </p>
        {request.user.receiver_ids.length > 0 &&
        <p className={classes.text}>
          <Typography
            component="span"
            variant="subtitle1"
            color={'primary'}
            className={classes.textLabel}
          >
            {t('receiver_ids')}
          </Typography>
          {request.user.receiver_ids.join("\n")}
        </p>
        }
        {request.user.email &&
        <Button
          className={classes.button}
          variant="contained"
          color={'primary'}
          type={'button'}
        >
          <a
            href={'mailto:' + request.user.email + '?subject=' + mailSubject}
            target="_top"
            className={classes.a}
          >
            {t('contact_user')}
          </a>
        </Button>
        }
      </Paper>
    </Grid>
  )
}

export default React.memo(RequestUserCard);
