import { EditorState } from 'draft-js';
import { data_languages } from '../../../constants/lang';
import { USER_TYPE_ALL } from '../../../constants/inAppNotification';

export const INITIAL_STATE = {
  id: null,
  start_date: null,
  end_date: null,
  picture_to_upload: null,
  has_call_to_action: false,
  is_activated: true,
  push: false,
  push_time: null,
  device_os: 'all',
  has_been_pushed: false,
  has_scheduled_pushed: false,
  can_push: true,
  user_type: USER_TYPE_ALL,
  country_codes: [],
  user_has_expert_compatible_free_car: false,
  with_app_version: false,
  app_version: '',
  app_version_condition: '',
  ...data_languages.reduce((o, key) => (
    {
      ...o,
      [`title_${key}`]: '',
      [`text_${key}`]: EditorState.createEmpty(),
      [`call_to_action_title_${key}`]: '',
      [`call_to_action_link_${key}`]: '',
      [`push_title_${key}`]: '',
      [`push_body_${key}`]: '',
    }
  ), {}),
};

export const INITIAL_ERROR_STATE = {
  start_date_error: null,
  end_date_error: null,
  picture_error: null,
  push_time_error: null,
  app_version_error: null,
  app_version_condition_error: null,
  ...data_languages.reduce((o, key) => (
    {
      ...o,
      [`title_${key}_error`]: null,
      [`text_${key}_error`]: null,
      [`call_to_action_title_${key}_error`]: null,
      [`call_to_action_link_${key}_error`]: null,
      [`push_title_${key}_error`]: null,
      [`push_body_${key}_error`]: null,
    }
  ), {}),
};
