import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import Timestamp from 'react-timestamp';
import { TableCell, TableRow, Typography } from '@material-ui/core';
import useTheme from '@material-ui/core/styles/useTheme';
import Tooltip from '@material-ui/core/Tooltip';
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import {
  IN_APP_NOTIFICATION,
  IN_APP_NOTIFICATIONS_ADD,
  IN_APP_NOTIFICATIONS_EDIT,
} from '../../../constants/routes';
import { useStyles } from './InAppNotificationRow.style';
import MTCDialog from '../../Form/MTCDialog';
import { useDispatch, useSelector } from 'react-redux';
import { deleteInAppNotifications } from '../../../store/inAppNotifications/actions';
import AddToPhotosIcon from '@material-ui/icons/AddToPhotos';

const InAppNotificationRow = ({ value }) => {
  const { t } = useTranslation(['inAppNotification', 'translations']);
  const history = useHistory();
  const theme = useTheme();
  const classes = useStyles(theme);
  const dispatch = useDispatch();
  const { sending } = useSelector(state => state.inAppNotifications);
  let title = value.translations.fr
    ? value.translations.fr.title
    : '';

  const [open, setOpen] = React.useState(false);

  const handleOpen = event => {
    event.preventDefault();
    event.stopPropagation();
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleClick = event => {
    event.preventDefault();
    event.stopPropagation();
    history.push(IN_APP_NOTIFICATION.replace(':notificationId', value.id));
  };

  const handleEdit = event => {
    event.preventDefault();
    event.stopPropagation();
    history.push(IN_APP_NOTIFICATIONS_EDIT.replace(':notificationId', value.id));
  };

  const handleDuplicate = event => {
    event.preventDefault();
    event.stopPropagation();
    history.push({
      pathname: IN_APP_NOTIFICATIONS_ADD,
      state: {notification: value}
    })
  }

  const handleDelete = id => {
    dispatch(deleteInAppNotifications(id));
  };

  return (
    <>
      <TableRow
        className={classes.row}
        key={value.id}
        tabIndex={-1}
        onClick={handleClick}
        hover
      >
        <TableCell>
          <Typography>
            {title}
          </Typography>
        </TableCell>
        <TableCell>
          <Timestamp
            format="full"
            twentyFourHour
            time={value.start_date}
          />
        </TableCell>
        <TableCell>
          {
            !value.end_date && <Typography>-</Typography>
          }
          {
            value.end_date
            && <Timestamp
              format="full"
              twentyFourHour
              time={value.end_date}
            />
          }
        </TableCell>
        <TableCell>{t(`state.${value.state}`)}</TableCell>
        <TableCell align={'right'}>
          <Tooltip title={t('edit')}>
            <IconButton
              id="edit"
              aria-label="update"
              onClick={handleEdit}
              color="primary"
              className={classes.button}
            >
              <EditIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title={t('duplicate')}>
            <IconButton
              id='duplicate'
              aria-label='duplicate'
              onClick={handleDuplicate}
              className={classes.button}
            >
              <AddToPhotosIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title={t('remove')}>
            <IconButton
              id="remove"
              aria-label="remove"
              onClick={handleOpen}
              color="primary"
              className={classes.button}
            >
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </TableCell>
      </TableRow>

      <MTCDialog
        open={open}
        handleClose={handleClose}
        dialogTitle={t('remove_in_app_notification_title')}
        dialogText={t('remove_in_app_notification_text')}
        handleCloseCancel={handleClose}
        handleCloseOK={() => handleDelete(value.id)}
        cancelText={t('translations:cancel')}
        okText={t('translations:validate')}
        okPending={sending}
      />
    </>
  );
};

export default React.memo(InAppNotificationRow);
